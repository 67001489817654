import React from 'react';
import { filter, groupBy, sortBy, isEmpty } from 'lodash';
import Section from './Section';
import SpinnerContainer from '../containers/SpinnerContainer';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import UrlConstants from '../constants/UrlConstants';
import { Button } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

class RuralStructuresPage extends React.Component {

    componentWillMount() {
        const { onLoad,updateCurrentTab, updateRiskDetail, currentTab,isEditEstimate } = this.props;
        onLoad(); 
        if(!isEditEstimate && currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail(); 
        }
        updateCurrentTab(UrlConstants.RURAL_STRUCTURES);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { productId, questions, validateMandatoryFields } = this.props;

        if (questions !== prevProps.questions && (prevProps.questions !== null && questions !== null &&
                questions.data !== prevProps.questions.data) && questions.data.length > 0) {
            validateMandatoryFields(productId, questions);
        }
    }

    scrollToFirstError = () => {
        const { errors } = this.props;
        if (errors && errors.length > 0) {
            let errorIds = errors.map(error => error.questionId);
            let elements = document.getElementsByClassName("questionLayout");
            let firstElement = [...elements].filter(element => errorIds.includes(parseInt(element.id.split("-")[1], 10)))[0];
            if (firstElement) {
                firstElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        }
    };

    handleClose = () => {
        const { handleChange } = this.props;
        handleChange(0);
    };

    render() {
        const { styles, text, handleChange, questions, isEmailDownload, isCurrentTabError } = this.props;
        let ruralStructures;

        const ruralQuestions = filter(this.props.questions.data, (question) => {
            return (question.section.name.toLowerCase().indexOf('Rural Structures'.toLowerCase()) >= 0) && question.visible;
        });

        const ruralQuestionsSortedBySection = sortBy(ruralQuestions, (question) => {
            return question.section.lineNo;
        });

        const groupedRuralQuestions = groupBy(ruralQuestionsSortedBySection, (param) => {
            return param.section.name;
        });
        const handleNext = (event, newValue) => {
            handleChange(UrlConstants.EXTERNAL_FEATURES);
        };
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.BUILDING_DETAILS);
        }
        const errorMsgStyle = { fontFamily: styles.globalFontFamily };


        return (
            <div className='cceruralstructures'>
                <div className="questions" style={{
                    fontFamily: styles.globalFontFamily,
                }}>
                    <SpinnerContainer />
                    <div className="cce-questions-wrapper">
                        {
                            Object.keys(groupedRuralQuestions).map((section, i) => {
                                ruralStructures = <Section key={i} styles={styles} section={{
                                    name: section,
                                    questions: groupedRuralQuestions[section],
                                    sectionTileColour: styles.sectionTileColour,
                                }} onHelpInformationDialogOpen={this.props.openHelpInformationDialog} text={text} />;
                                return null;
                            })
                        }
                        {ruralStructures}
                        {this.props.errors !== null && this.props.errors !== undefined
                            && this.props.errors.length > 0 &&
                            <FormHelperText autofocus style={errorMsgStyle} className="cce-estimate-error">Please fill in all mandatory fields marked with an asterisk (*)
              with valid values</FormHelperText>}
                        <div className="action-nav">
                            <div className="nav-previous rural-previous"><Button className="action_previous" variant="outlined" id="tab-2"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={isEmpty(questions.data) || isEmailDownload ||
                                    (this.props.errors !== null && this.props.errors !== undefined
                                        && this.props.errors.length > 0 && !isCurrentTabError(questions, this.props.errors)) ? true : false}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handlePrevious}
                            > &larr; Building Details
                        </Button></div>
                            <div className="nav-next"><Button className="action_next" variant="outlined" id="tab-3"
                                rounded="true"
                                tabIndex={0}
                                size="large"
                                disabled={this.props.errors !== null && this.props.errors !== undefined
                                    && this.props.errors.length > 0 ? true : false}
                                disableTouchRipple={true}
                                onClick={handleNext}
                                disableFocusRipple={false}
                            > External Features &rarr;
                        </Button></div>
                        </div>
                    </div>
                    <HelpInformationDialogContainer />
                    <LetUsKnowDialogContainer />
                </div>
            </div>
        );
    }
}

RuralStructuresPage.propTypes = {
    questions: PropTypes.object.isRequired,
    displayRefreshWarning: PropTypes.bool.isRequired,
    addressChosen: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    text: PropTypes.object,
    debug: PropTypes.bool,
    styles: PropTypes.object,
    onLoad: PropTypes.func.isRequired,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,
    openHelpInformationDialog: PropTypes.func,
    attributesCheck: PropTypes.bool.isRequired,


};

export default RuralStructuresPage;