import CustomFlatButton from '../components/CustomFlatButton';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


const mapStateToProps = (storeProps) => {
    return {
        profileStyles: storeProps.profile.styles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

const mergeProps = (storeProps, dispatchProps, ownProps) => {
    return {
        label: ownProps.label,
        type: ownProps.type,
        className: ownProps.className,
        onPress: ownProps.onPress,
        profileStyles: storeProps.profileStyles,
        style: ownProps.style,
        id: ownProps.id,
        display: ownProps.disabled,
        keyboardFocus: ownProps.keyboardFocus,
        onKeyPressCapture: ownProps.onPress,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CustomFlatButton);