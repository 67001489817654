import ExternalFeaturesPage from '../components/ExternalFeaturesPage';
import {connect} from 'react-redux';
import {
    setHelpInformationOpen,
    setLetUsKnowDialogOpen,
    updateRiskInNewEstimate
} from '../actions/questionsActionCreator';
import {bindActionCreators} from 'redux';
import {updateCurrentTab} from '../actions/searchActionCreator';

const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        displayRefreshWarning: state.displayRefreshWarning,
        addressChosen: state.addressChosen,
        questions: state.questions,
        result: state.result,
        productId: defaultProps.productId,
        profileId: defaultProps.profileId,
        debug: defaultProps.debug === 'true',
        styles: state.profile.styles,
        text: state.profile.text,
        helpContent: state.helper.helpInformationContent,
        helpDialogOpen: state.helper.helpInformationOpen,
        attributesCheck: state.profile.display.attributesCheck,
        showResult: state.showResult,
        partner: defaultProps.partner,
        errors: state.errors,
        handleChange: defaultProps.handleChange,
        isEmailDownload: state.isEmailDownload,
        riskDetail: state.riskDetail,
        riskId: state.riskId,
        currentTab: state.currentTab,
        isEditEstimate: state.isEditEstimate,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        openHelpInformationDialog: setHelpInformationOpen,
        openLetUsKnowDialog: setLetUsKnowDialogOpen,
        updateCurrentTab:  updateCurrentTab,
        updateRiskDetail: updateRiskInNewEstimate,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        openHelpInformationDialog: (open, content) => {
            dispatchProps.openHelpInformationDialog(open, content);
        },
        openLetUsKnowDialog: (open, address, propertyId) => {
            dispatchProps.openLetUsKnowDialog(open, address, propertyId);
        },
        partnerId: stateProps.partner,
        updateRiskDetail: () => {
            dispatchProps.updateRiskDetail(stateProps.productId, stateProps.profileId, stateProps.riskId, stateProps.riskDetail);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ExternalFeaturesPage);
