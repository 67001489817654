import React from 'react';
import QuestionGroup from './QuestionGroup';
import SectionHeader from './SectionHeader';
import { Card, CardText } from 'material-ui/Card';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import CardHeader from '@material-ui/core/CardHeader';
import { groupByDependantFlag } from '../components/Section';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import SpinnerContainer from '../containers/SpinnerContainer';



const CCESection = ({ section, styles, postcode, onHelpInformationDialogOpen, text, buildingGrp, onAddBuilding, handleExpandClick, expanded, onRemoveBuilding, expandedIndex }) => {
    const questionsSorted = sortBy(section.questions, (question) => {
        return question.lineNo;
    });

    const groups = groupByDependantFlag(questionsSorted);
    return (section.name === 'Building Details' ? buildingGrp.map((grp, buildingIndex) => {
        return <Card className="building-card"
            key={buildingIndex}
            containerStyle={{ paddingBottom: 0, fontFamily: styles.globalFontFamily }} style={{
                marginTop: buildingIndex > 0 ? '1rem' : 'none'
            }}>
            <CardHeader className="building-detail-header" classes={{action: 'building-action'}}
                        style={{
                            backgroundColor: styles.sectionTileColour,
                            minHeight: '3.12rem',
                            fontFamily: styles.globalFontFamily,
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        }}
                component={'div'}
                title={`BUILDING ${buildingIndex + 1}`}
                action={
                    <div className="cce-section-header">
                        <SpinnerContainer />
                        <div title="Add Building" className="cce-section-header-add"

                            onClick={() => {
                                if (buildingGrp !== undefined) {
                                    let isValidation = true;
                                    buildingGrp.forEach(bGrp => {
                                        bGrp.forEach(group => {
                                            group.questions.forEach(question => {
                                                if (question.id === 302875 && (question.answer === 0 || question.answer === '' || question.answer === "0")) {
                                                    isValidation = false;
                                                }
                                            });

                                        });
                                    });
                                    if (isValidation) {
                                        onAddBuilding();
                                    }
                                }

                            }

                            }>
                            <AddIcon className="add-icon" />
                        </div>
                        {buildingIndex >= 1 && <div title="Remove Building" className="cce-section-header-remove" onClick={() => {
                            if (buildingGrp !== undefined) {
                                onRemoveBuilding(buildingIndex)
                            }
                        }}>
                            <RemoveIcon className="add-icon" />
                        </div>}
                        <div className="shevron-button-base">
                            <IconButton style={{ transform: (expandedIndex === buildingIndex) && expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                className='shevron-button'
                                onClick={() => {
                                    if (buildingGrp !== undefined) {
                                        let isValidation = true;
                                        let bIndex = 0;
                                        buildingGrp.forEach(handlebGrp => {
                                            bIndex++;
                                            handlebGrp.forEach(group => {
                                                group.questions.forEach(question => {
                                                    if (expanded &&  (expandedIndex === buildingIndex) &&
                                                        bIndex === (buildingIndex + 1) && 
                                                    question.id === 302875 && (question.answer === 0 || question.answer === '' || question.answer === "0")) {
                                                        isValidation = false;
                                                        }
                                                });

                                            });
                                        });
                                        if (isValidation) {
                                            handleExpandClick(buildingIndex, expandedIndex)
                                        }
                                    }
                                }}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </div>

                }>

            </CardHeader>
            <Collapse in={(expandedIndex === buildingIndex) && expanded} timeout="auto" unmountOnExit>
                <CardText style={{ padding: '0' }}>
                    {
                        grp.map((group, index) => {
                            return <QuestionGroup key={index} index={buildingIndex} questions={group.questions}
                                dependant={group.dependant} styles={styles}
                                onHelpInformationDialogOpen={onHelpInformationDialogOpen} text={text} sectionName={section.name} />;
                        })
                    }
                </CardText>
            </Collapse>
        </Card >
    }) :
        <Card className="card" containerStyle={{ paddingBottom: 0, fontFamily: styles.globalFontFamily }}>
            <SectionHeader name={section.name} color={section.sectionTileColour} styles={styles} />
            <CardText style={{ padding: '0' }}>
                {
                    groups.map((group, index) => {
                        return <QuestionGroup key={index + 1} questions={group.questions}
                            dependant={group.dependant} styles={styles}
                            onHelpInformationDialogOpen={onHelpInformationDialogOpen} text={text}
                            sectionName={section.name} postcode={postcode} />;
                    })
                }
            </CardText>
        </Card>
    );
};

CCESection.propTypes = {
    section: PropTypes.object.isRequired,
    styles: PropTypes.object,
    text: PropTypes.object,
};

export default CCESection;