import React from 'react';

const onMouseOver = () => {
    document.getElementById("letusknow").style.fontWeight = 'bold';
};

const onMouseLeave = () => {
    document.getElementById("letusknow").style.fontWeight = '';
};

const ErrorMessage = ({propertyId, address, onLetUsKnowDialogOpen, styles, message}) => {
    if(message) {
        return (
            <span style={{fontFamily: styles.globalFontFamily}}>{message}, to report this, please click&nbsp;
                <span id='letusknow' onClick={() => onLetUsKnowDialogOpen(true, address, propertyId)} className="helpLink"
                   onMouseOver={() => onMouseOver()}
                   onMouseLeave={() => onMouseLeave()}>here</span>.
            </span>
        );
    }
    return (
        <span style={{fontFamily: styles.globalFontFamily}}>We are having difficulty generating your estimate, to report this, please click&nbsp;
            <span id='letusknow' onClick={() => onLetUsKnowDialogOpen(true, address, propertyId)} className="helpLink"
               onMouseOver={() => onMouseOver()}
               onMouseLeave={() => onMouseLeave()}>here</span>.
        </span>
    );
};

export default ErrorMessage;
