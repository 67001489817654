export const BASE_URL = window.location.origin;
export default {
    BASE_ORIGIN_URL : window.location.origin,
    CLAUD_LOGIN_URL: (BASE_URL.indexOf('https://sumsure.corelogic.co.nz') === 0 ||
        BASE_URL.indexOf('https://sumsure.cordell.com.au') === 0 ||
        BASE_URL.indexOf('https://cce.corelogic.co.nz') === 0 ) ?
        `https://auth.corelogic.asia/as/authorization.oauth2?response_type=code&client_id=AxRTgVosuvXqH8sFTm2g6ctOVII4XEGG&redirect_uri=${BASE_URL}&scope=openid` :
        `https://auth-uat.corelogic.asia/as/authorization.oauth2?response_type=code&client_id=AxRTgVosuvXqH8sFTm2g6ctOVII4XEGG&redirect_uri=${BASE_URL}&scope=openid`,
   DEV_BASE_URL : "http://sumsure-dev-web.apps.ad.corelogic.asia",
    CLAUD_LOGOUT_URL: (BASE_URL.indexOf('https://sumsure.corelogic.co.nz') === 0 ||
        BASE_URL.indexOf('https://sumsure.cordell.com.au') === 0 ||
        BASE_URL.indexOf('https://cce.corelogic.co.nz') === 0) ?
        'https://auth.corelogic.asia/ext/logout' : 'https://auth-uat.corelogic.asia/idp/startSLO.ping?LogoutType=SyncOnly&TargetResource=https://back-to-app',
};
