import React from 'react';
import {Card} from 'material-ui';
import SectionHeader from './SectionHeader';
import Disclaimer from './Disclaimer';
import PropTypes from 'prop-types';

const DisclaimerSection = ({state, contentBefore, contentAfter, styles, productId, showDisclaimerAtBottom}) => {
    let sectionHeader = showDisclaimerAtBottom
        ? <SectionHeader name="Disclaimer" color={styles.sectionTileColour} styles={styles}/>
        : <SectionHeader name="" color={styles.sectionTileColour} styles={styles}/>;
    return (
        <Card style={{marginTop: '15px', paddingBottom: '1px'}}>
            {sectionHeader}
           <Disclaimer state={state} contentBefore={contentBefore} contentAfter={contentAfter} styles={styles} productId={productId}/>
        </Card>
    );
};

DisclaimerSection.propTypes = {
    state: PropTypes.string,
    contentBefore: PropTypes.string,
    contentAfter: PropTypes.string,
    styles: PropTypes.object,
    productId: PropTypes.string,
};

export default DisclaimerSection;