import React from 'react';

const HomePageNotes = ({text, styles}) => {
    if (!text) {
        return null;
    }
    return (
        <div
            className="homepage-notes"
            dangerouslySetInnerHTML={{__html: text}}
            style={{
                maxWidth: '64rem',
                textAlign: styles.landingPageNotesAlignment || 'left',
                backgroundColor: styles.landingPageNotesBackgroundColor || 'white',
                fontSize: styles.landingPageNotesFontSize || '0.8rem',
                color: styles.landingPageNotesFontColor || 'rgb(151, 151, 151)',
                border: '1px solid #bebebe',
                borderRadius: '2px',
                padding: '1rem 2rem',
                marginBottom: '1rem',
            }}
        />
    )
};

export default HomePageNotes;