import React from 'react';
import {getEmailTemplate} from '../actions/actionCreator';
import queryString from "query-string";
import { isEmpty } from 'lodash';
import NotFoundPage from './NotFoundPage';

class ViewEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {html: "", 
        render: false}
    }

    componentDidMount() {
        const queryParams = queryString.parse(this.props.location.search);
        let url = queryParams.partner
            ? `${this.props.location.pathname}?partner=${queryParams.partner}&reportUrl=${queryParams.reportUrl}`
            : `${this.props.location.pathname}?reportUrl=${queryParams.reportUrl}`;

        setTimeout(function () {
            this.setState({ render: true })
        }.bind(this), 1000);
        getEmailTemplate(url)
            .then(response => {
             return response.json();
            })
            .then((responseJson) => {
               this.setState({html: responseJson.html});
            });
    }

    render() {
        let renderContainer = false 
        if(this.state.render ) {
            renderContainer = <div> { !isEmpty(this.state.html) ? 
                <div dangerouslySetInnerHTML={{__html: this.state.html}}/> : <NotFoundPage /> } </div>  
        }
        return (
          renderContainer  
        ) 
    }
}

export default ViewEmail;