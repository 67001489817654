import React from 'react';
import InputFieldContainer from '../containers/InputFieldContainer';
import PropTypes from 'prop-types';

class SpecialQuestion extends React.Component {

    render() {
        const {question, styles} = this.props;
        const {questionLabelFontColour, questionLabelFontSize} = styles;

        let htmlText;
        let specialItem;

        if (question.htmlText) {
            htmlText = <div id={`question-${question.id}`}
                             className='questionLayout'
                             dangerouslySetInnerHTML={{__html: question.htmlText.replace(/href='www./g, "href='https://www.")}}/>
        } else {
            specialItem = <div className="special-item">
                            <div className="label">
                                <label htmlFor={question.id.toString()} id={`question-${question.id}`}
                                    className={'questionLayout ' + (question.mandatory ? 'required' : '')}
                                    style={{
                                        fontWeight: question.dependant ? 'normal' : 'bold',
                                        color: questionLabelFontColour,
                                        fontSize: questionLabelFontSize,
                                    }}>
                                    {question.text}
                                </label>
                            </div>
                            <div className="input">
                                <InputFieldContainer question={question} styles={styles}/>
                            </div>
                        </div>
        }

        return (
            <div className={question.htmlText ? "question htmlText" : "question"}>
                {htmlText}
                {specialItem}
            </div>
        );
    }
}

SpecialQuestion.propTypes = {
    dependant: PropTypes.bool,
    styles: PropTypes.object,
};

export default SpecialQuestion;