import React from "react";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';
import apiBaseUrl from '../apiBaseUrl';

const Layout = ({ input, previews, submitButton, dropzoneProps, files }) => {
  return (
    <div>
      <div {...dropzoneProps}>
        {input}
      </div>
    </div>
  )
}
const ImageUploads = ({profileId, productId, estimateId, styles, fetchImages, cceImages, updateImagesDescription}) => {
   const getUploadParams = ({ file, meta }, files) => {
   if(shouldUpload){
         const body = new FormData();
         body.append('file', file);
         const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/uploadImage?estimateId=${estimateId}`;
         return { url: url, body }
      }else{
            totalFile = 0;
            return { url: '' }
      }
    };

   let count = 0;
   let totalFile = 0;
   let shouldUpload = true;
   let errorCount = 0;
   let maxFilesError = false;
   const handleChangeStatus = ({ meta, remove }, status, files, fileWithMeta) => {
        if(status === "preparing"){
            totalFile = totalFile + 1;
            shouldUpload = true;
        }
        if(status === 'rejected_file_type' || status === 'error_file_size' ){
           shouldUpload = false;
           let className = 'cce-error-msg';
           files.forEach(f => {
                f.remove();
                totalFile = totalFile - 1;
            });
           msg(`Please upload a file under 1MB in the following format - jpg, gif, bmp, png, tif.`, className);
        }
        if(status === 'rejected_max_files' ){
            shouldUpload = false;
            maxFilesError = true;
            let className = 'cce-error-msg';
            msg(`Please Upload 10 files only.`, className);
       }
       if (status === 'aborted' || status === 'error_upload' || status === 'exception_upload' || status === 'error_upload_params') {
            let className = 'cce-error-msg';
            files.forEach(f => f.remove());
            errorCount = errorCount + 1;
            if(estimateId === null){
                msg(`Estimate not created`, className);
            }else{
                if (!maxFilesError) {
                    msg(`Please upload a file under 1MB in the following format - jpg, gif, bmp, png, tif.`, className);
                }
           }
           if(errorCount === 10){
                fetchImages();
           }
       }
       if(status === 'done'){
            count = count + 1;
            updateAndFetchImage({totalFile, count, msg, cceImages, toImages, updateImagesDescription}, productId, profileId, estimateId, fetchImages, files);
   }
  };
    const toImages = (cceImage) => {
      return { id: cceImage.id, description: cceImage.imageDescription};
    };

   const msg = (innerHTML, className) => {
      const el = document.getElementById('msg');
      el.innerHTML = innerHTML;
      el.className = className;
  };

    let message = <div style={{marginTop: '2em', textAlign:'center'}}><span style={{fontWeight: 'bold'}}><br/>Upload Images</span><br/><span>Drag and Drop images here </span><br/><br/><span>Or</span><br/><br/><span>Click here to Choose Images</span><br/><span>From File Manager</span><br/><br/><br/></div>;
    let inputMessage = <div style={{marginTop: '2em', marginLeft: '5em', marginRight: '5em',marginBottom: '2em', textAlign:'center', backgroundColor: '', color: 'black'}}><span style={{fontWeight: 'bold'}}><br/>Upload Images</span><br/><span>Drag and Drop images here </span><br/><br/><span>Or</span><br/><br/><span>Click here to Choose Images</span><br/><span>From File Manager</span><br/><br/><br/></div>;
    return (
        <React.Fragment>
        <div backgroundcolor="grey">
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                accept="image/jpg,image/gif,image/bmp,image/png,image/tif,image/jpeg,image/tiff"
                maxFiles={10}
                minSizeBytes={0}
                maxSizeBytes={1048576}
                timeout={30000000}
                inputWithFilesContent={inputMessage}
                LayoutComponent={Layout}
                inputContent={message}
                styles={{
                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                    dropzone: { minHeight: '400', maxHeight: '400' },

                }}
            />
            <div id="msg"  style={{textAlign: 'center', fontWeight: 'bold'}}></div>
        </div>
        </React.Fragment>
    )
}

ImageUploads.propTypes = {
    styles: PropTypes.object,
    fetchImages: PropTypes.func,
    onError: PropTypes.func,
    fileSizeError: PropTypes.func,
    uploadException: PropTypes.func,
    updateImagesDescription: PropTypes.func,

};
export default ImageUploads;

function updateAndFetchImage({totalFile, count, msg, cceImages, toImages, updateImagesDescription}, productId, profileId, estimateId, fetchImages, files) {
  if (totalFile === count) {
    msg(`Successfully uploaded!`, 'cce-successful-msg');
    const descriptions = cceImages.data !== null ? cceImages.data.map((cceImage) => toImages(cceImage)) : [];
    if (descriptions) {
      updateImagesDescription(productId, profileId, descriptions, estimateId);
    }
    fetchImages();
  }
  files.forEach(f => f.remove());
}
