import {connect} from 'react-redux';
import Result from '../components/Result';
import {bindActionCreators} from 'redux';
import {fetchProfile} from '../actions/actionCreator';

export const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        result: state.result,
        debug: state.debug,
        disclaimerText: state.profile.text.disclaimerCalculator,
        display: {
            userName: state.profile.display.userName,
            email: state.profile.display.email,
            downloadReport: state.profile.display.downloadReport,
            contentInsurance: state.profile.display.contentInsurance,
            displayContactUs: state.profile.display.displayContactUs,
            displayCTAButton1: state.profile.display.displayCTAButton1,
            includeEmailAddress: state.profile.display.includeEmailAddress,
            restrictDownloadReport: state.profile.display.restrictDownloadReport
        },
        callToActionText: state.profile.text.callToAction,
        styles: state.profile.styles,
        rebuildCostHeader: state.profile.text.rebuildCostHeader,
        rebuildBreakupSectionHeader: state.profile.text.rebuildBreakupSectionHeader,
        rebuildCostNote: state.profile.text.rebuildCostNote,
        rebuildCostFooterNote: state.profile.text.rebuildCostFooterNote,
        contactUsUrl: state.profile.contactUsUrl,
        showNote: state.showNote,
        addressChosen: state.addressChosen.suggestion,
        onLetUsKnowDialogOpen: defaultProps.onLetUsKnowDialogOpen,
        callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate === null ?
            "Default" : state.profile.text.callToActionSectionTemplate,
        downloadPdfReportBodyText: state.profile.text.downloadPdfReportBodyText,
        ctaButton1Url: state.profile.ctaButton1Url,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProfile,
    }, dispatch);
};

const ResultContainer = connect(mapStateToProps, mapDispatchToProps)(Result);
export default ResultContainer;