import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';
import { Search, Form } from 'semantic-ui-react';

const SearchSuggestions = ({ searchText, suggestions, onChange, onSearchTermChanged, onNewRequest, styles, productId, showSearchIcon, name, newValue, results,
    profileId, fontFamilyName, onResultSelectionChange,onBlur, isEditRisk, resultData }) => {

    let hintText = productId === UrlConstants.PRODUCT_ID_8 ? "Street number, name, town, district" : "Street number, name, suburb, state";
    const isCommercial = productId === UrlConstants.PRODUCT_ID_9 || productId === UrlConstants.PRODUCT_ID_10;
    const isEditAllowed = !isCommercial || (!isEditRisk && (resultData.data === null || resultData.data.estimateId === null )) ? true : false;
    const noResult = !isEditAllowed ? "Address cannot be modified as this would impact the rebuild cost generated." : "Can't find the address? Please click here to proceed and enter your details manually.";

    let searchIcon = showSearchIcon ? "search" : "";
    const searchResults = suggestions.data ? suggestions.data.map((property) => {
        return {
            key: property.suggestion,
            title: property.suggestion,
        };
    }) : [];

    const findPropertyExactMatch = (suggestions1, address) => {
        return _.find(suggestions1.data, (s) => s.suggestion === address);
    };
   

    const findPropertyCaseInsensitiveStartsWith = (suggestions2, address) => {
        return _.find(suggestions2.data, (s) => s.suggestion.toLowerCase().startsWith(address.toLowerCase()));
    };

    const handleSearchChange = (e, { value }) => {
        if (isEditAllowed) {
            onChange(value);
            const trimmedValue = value.trim();
            if (window.NodeList && !NodeList.prototype.forEach) {
                NodeList.prototype.forEach = Array.prototype.forEach;
            }
            document.querySelectorAll('.title').forEach(ele => {
                if (ele != null &&
                    ele.innerHTML != null
                    && ele.parentNode != null && ele.parentNode !== undefined
                    && ele.parentNode.parentNode != null && ele.parentNode.parentNode !== undefined
                    && !ele.parentNode.parentNode.className.includes("result-none")
                ) {
                    ele.parentNode.parentNode.className += " result-none";
                }
            });
            if (trimmedValue.length <= 3) {
                return;
            } 
            onSearchTermChanged(trimmedValue);
        }
    };

    const handleResultSelect = (e, { result }) => {
        onChange(result.title);
        if (result.title && result.title.length > -1) {
            onNewRequest(findPropertyExactMatch(suggestions, result.title));
        }
    };

    const handleSelectionChange = (e, { result }) => {
        if (UrlConstants.PROFILE_ID_IAG_AU.includes(profileId)) {
            let message = '';
            if (result !== undefined) {
                message = result.title;
            } else {
                message = "Can't find the address? Please click here to proceed and enter your details manually.";
            }
            onResultSelectionChange(message);
        }
    };

    const handleSearchIconClick = () => {
        if (searchText.length > 0 && (searchResults.length === 0 || searchResults.length === 1) && isEditAllowed) {
            if (suggestions.data && suggestions.data.length === 1 && searchResults.length === 1) {
                onChange(suggestions.data["0"].suggestion);
                const property = findPropertyCaseInsensitiveStartsWith(suggestions, suggestions.data["0"].suggestion);
                if (property) {
                    onNewRequest(property);
                }
            } else {
                onNewRequest({ propertyId: null, suggestion: searchResults.title, isApplicable: false });
            }
        }
    };

    return (
        <Form.Field style={{ fontFamily: fontFamilyName }}>
            <Search
                className="address"
                fluid
                input={{ fluid: true }}
                loading={suggestions.loading}
                placeholder={hintText}
                onSearchChange={_.debounce(handleSearchChange.bind(), 500, {
                    leading: true,
                })}
                onBlur={onBlur}
                onResultSelect={handleResultSelect.bind()}
                results={searchResults}
                value={newValue}
                id={name}
                icon={{ className: searchIcon }}
                onClick={handleSearchIconClick.bind()}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        handleSearchIconClick();
                    }
                }}
                onSelectionChange={handleSelectionChange.bind()}
                noResultsMessage={noResult}
            />
        </Form.Field>
    );
};

SearchSuggestions.propTypes = {
    searchText: PropTypes.string,
    suggestions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearchTermChanged: PropTypes.func.isRequired,
    onNewRequest: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
};

export default SearchSuggestions;
