import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AnalyticsPage from '../util/AnalyticsPage';
import { trackSegmentPageView } from "../actions/segmentActionsCreator";
import CookiesConsentBox from '../components/CookiesConsentBox';
import { saveCookieConsentDetails } from '../actions/actionCreator';

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        trackSegmentPageView: trackSegmentPageView,
        saveCookieConsentDetails: saveCookieConsentDetails,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...ownProps,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(ownProps.productId, ownProps.profileId, 'Cookies Consent Dismiss Clicked', ownProps.location.pathname, stateProps.sessionTrackId, "Cookies Consent Dismiss Clicked", stateProps.partner);
            dispatchProps.trackSegmentPageView("Cookies Consent Dismiss Clicked", pageDetails);
        },
        saveCookieConsentDetails: dispatchProps.saveCookieConsentDetails,
    };
};

export default connect(null, mapDispatchToProps, mergeProps)(CookiesConsentBox);
