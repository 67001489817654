import QuestionsPage from '../components/QuestionsPage';
import {connect} from 'react-redux';
import {fetchPropertyImages, searchAgain, showResultSection, onBrowserWidthChange} from '../actions/actionCreator';
import {
    fetchQuestions,
    setHelpInformationOpen,
    setLetUsKnowDialogOpen
} from '../actions/questionsActionCreator';
import {bindActionCreators} from 'redux';
import {performCalculation} from '../actions/calculationActionCreator';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";
import AnalyticsPage from '../util/AnalyticsPage';
import queryString from "query-string";

const mapStateToProps = (state, defaultProps) => {
    const queryParams = queryString.parse(defaultProps.location.search);
    return {
        displayRefreshWarning: state.displayRefreshWarning,
        addressChosen: state.addressChosen,
        questions: state.questions,
        result: state.result,
        productId: defaultProps.match.params.productId,
        profileId: defaultProps.match.params.profileId,
        aerialImage: state.aerialImage,
        propertyImage: state.propertyImage,
        debug: queryParams.debug === 'true',
        images: state.profile.images,
        styles: state.profile.styles,
        text: state.profile.text,
        helpContent: state.helper.helpInformationContent,
        helpDialogOpen: state.helper.helpInformationOpen,
        token: queryParams.token,
        segmentTrackingCode: state.profile.segmentTrackingCode,
        segmentAnalytics: state.segmentAnalytics,
        returnToQuote: state.returnToQuote,
        attributesCheck: state.profile.display.attributesCheck,
        showResult: state.showResult,
        partner: queryParams.partner,
        returnUrl: queryParams.returnUrl,
        quoteId: queryParams.quoteId,
        contentInsurance: state.profile.display.contentInsurance,
        errors: state.errors,
        showDisclaimerAtBottom: state.profile.display.questionsPageDisclaimerShowAtBottom,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        rebuildCostSectionHeader: state.profile.text.rebuildCostHeader,
        showNote: queryParams.showNote,
        answers: state.answers,
        userCapturedDetail: state.userCapturedDetail,
        profile: state.profile, 
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onSearchAgain: searchAgain,
        onFetch: fetchQuestions,
        calculate: performCalculation,
        propertyImages: fetchPropertyImages,
        trackSegmentPageView: trackSegmentPageView,
        openHelpInformationDialog: setHelpInformationOpen,
        showResultSection: showResultSection,
        openLetUsKnowDialog: setLetUsKnowDialogOpen,
        handleBrowserWidthChanges: onBrowserWidthChange,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Questions Page', ownProps.location.pathname, stateProps.sessionTrackId, "QuestionsPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Questions Page", pageDetails);
        },
        openHelpInformationDialog: (open, content) => {
            dispatchProps.openHelpInformationDialog(open, content);
        },
        showResultAfterConfirmation: () => {
            dispatchProps.showResultSection(true);
        },
        openLetUsKnowDialog: (open, address, propertyId) => {
            dispatchProps.openLetUsKnowDialog(open, address, propertyId);
        },
        partnerId: stateProps.partner,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(QuestionsPage);
