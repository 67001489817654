import React from 'react';
import {Divider} from 'material-ui';
import ResultLine from './ResultLine';
import PropTypes from 'prop-types';

const ResultList = ({variables, styles, currencySymbol=true}) => {
    const length = variables.length;
    const totalVariable = variables.filter((variable) => variable.title.startsWith('TOTAL'))[0];
    return (
        <div>
            {
                variables.map((variable, index) => {
                    return <div tabIndex={0} key={index}>
                        {index === 0 && <Divider />}
                        <ResultLine variable={variable} totalLineNo={totalVariable ? totalVariable.lineNo : 0} styles={styles} currencySymbol={currencySymbol}/>
                        {index < length - 1 && <Divider />}
                    </div>;
                })
            }
        </div>
    );
};

ResultList.propTypes = {
    variables: PropTypes.array.isRequired,
    styles: PropTypes.object,
};

export default ResultList;
