import { getQuestions } from '../api/getQuestions';
import ServerError from './ServerError';
import history from '../util/history';
import { debounce, find, findIndex, isUndefined, filter, groupBy, sortBy } from 'lodash';
import DataRefreshError from './DataRefreshError';
import { trackSegmentEvent,initTrackSegmentEvent,updateSegmentEvent} from './segmentActionsCreator';
import { SegmentTrackingEventTypes } from '../constants/SegmentTrackingEventTypes';
import AnalyticsError from '../util/AnalyticsError';
import AnalyticsAnswer from '../util/AnalyticsAnswer';
import DefaultEstimate from '../util/DefaultEstimate';
import { sendSupportEmailApi } from "../api/supportEmailApi";
import importEstimateApi  from "../api/importEstimateApi";
import UserReportedError from "../util/UserReportedError";
import { validateAnswerApi } from "../api/validateAnswerApi";
import UrlConstants from '../constants/UrlConstants';
import WestpacGTagScript from "../util/WestpacGTagScript";
import WestpacGTagDLEnd from "../util/WestpacGTagDLEnd";
import WestpacGenericGTagDLEnd from "../util/WestpacGenericGTagDLEnd";
import { groupByDependantFlag } from '../components/Section';
import saveRiskDetailApi from '../api/saveRiskDetailApi';
import updateRiskApi from '../api/updateRiskApi';
import { validate } from './calculationActionCreator';
import AnalyticsEvent from '../util/AnalyticsEvent';
import usageReportingApi  from "../api/usageReportingApi";


const DEBOUNCE_WAIT = 500;

const handleJSONResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

export const serverError = (error) => {
    if (error.status === 503) {
        history.push('/unavailable');
        return { type: 'SERVER_UNAVAILABLE', error: error.message };
    } else if (error.status === 404) {
        history.push('/not_found');
        return { type: 'SERVER_ERROR', error: error.message };
    } else {
        return { type: 'SERVER_ERROR', error: error.message };
    }
};

const toAnswer = (question) => {
    return {
        question: question.id,
        answerSource: question.answerSource,
        answer: question.answer,
        visible: question.visible
    };
};

const attemptFetchQuestions = () => {
    return { type: 'ATTEMPT_FETCH_QUESTIONS' };
};

export const receiveQuestions = (questionsResponse, productId, profileId, propertyId, partnerId) => {
    return (dispatch, getState) => {
        const { questions, calculation, content } = questionsResponse;

        if (Array.isArray(calculation.variables) && calculation.variables.length > 0) {
            const { addressChosenTime } = getState();
            let timeTakenToCalculate = 0;
            if (addressChosenTime) {
                timeTakenToCalculate = (new Date().getTime() - addressChosenTime) / 1000;
            }
            let obj4 = {
                Estimate: calculation,
                TimeTakenToCalculate: timeTakenToCalculate,
                PartnerId: partnerId
            };
            let analyticsEstimate = new DefaultEstimate(productId, profileId, propertyId, "", "SUCCESS",
                "Default Calculation", obj4);
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DEFAULT_CALCULATION, analyticsEstimate));
            dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: null });
            if (calculation.estimateId !== null) {
                dispatch({ type: 'DEFAULT_CALCULATION', defaultCalculation: true });
            }
            if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || partnerId)) {
                WestpacGTagScript();
                if (partnerId === undefined) {
                    WestpacGenericGTagDLEnd();
                } else {
                    WestpacGTagDLEnd();
                }
            }
        }
         const { postcode } = getState();
               const index = findIndex(questions, (question) => {
                      return question.id === 302860;
               });
               questions[index] = { ...questions[index], answer: postcode, visible: false , answerSource: "grid", helpEnabled: true };
        return dispatch({
            type: 'RECEIVE_QUESTIONS',
            questions,
            calculation,
            content,
        });
    };
};

export const getDependentQuestionsApi = (dispatch, getState, answers, {propertyId, address},
       partnerId, buildingGrpIndex, sectionName) => {
        const { userCapturedDetail, productId, profileId, currentDataPartition, postcode } = getState();
        dispatch(attemptFetchQuestions());
        return getQuestions({productId, profileId, propertyId, address}, answers,
            currentDataPartition, partnerId, userCapturedDetail)
            .then((response) => {
                if (response.ok) {
                    if ((productId !== UrlConstants.PRODUCT_ID_10) && (productId !== UrlConstants.PRODUCT_ID_9)) {

                        if (currentDataPartition !== "" && currentDataPartition !== response.headers.get('currentDataPartition')) {
                            throw new DataRefreshError();
                        }
                    }
                }
                return response;
            })
            .then((response) => handleJSONResponse(response))
            .then((questionsResponse) => {
                if ((productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) && sectionName === 'Building Details') {
                    const groups = updateGroupForMultipleBuilding(questionsResponse.questions, postcode);
                    dispatch({ type: 'BUILDING_GRP_UPDATED_DEPENDENT', buildingData: { index: buildingGrpIndex, group: groups } });
                    dispatch(validateBuildingDetailsFields());
                    dispatch(updateErrorForDependentQuestions(buildingGrpIndex));
                }
                dispatch(receiveQuestions(questionsResponse, productId, profileId, propertyId, partnerId));
            })
            .catch((error) => {
                if (error instanceof DataRefreshError) {
                    dispatch({ type: 'UPDATE_REFRESH_WARNING', data: true });
                    dispatch({ type: 'LOADING_COMPLETED' });
                } else {
                    dispatch(serverError(error));
                }
            });
    };

const debouncedFetchQuestions = debounce(getDependentQuestionsApi, DEBOUNCE_WAIT);

export const fetchQuestions = ({productId, profileId}, propertyId, address, answers, debug, contentInsurance, partnerId) => {
    answers = answers === undefined ? [] : answers;
    return (dispatch, getState) => {
        dispatch(attemptFetchQuestions());
        if (propertyId === null || isUndefined(propertyId)) {
            let obj5 = {
                ProductId: productId,
                ProfileId: profileId,
                PropertyId: propertyId
            };
            let addressNotFoundError = new AnalyticsError(obj5, "", "FAILURE",
                "Address " + address + " not found", {
                "error": "Address not found",
                "address": address
            },
                partnerId);
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADDRESS_NOT_FOUND, addressNotFoundError));
            dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: new Date().getTime() });
            dispatch({ type: 'DEFAULT_CALCULATION', defaultCalculation: false });
        }
        const {userCapturedDetail} = getState();
        return getQuestions({productId, profileId, propertyId, address}, answers, null, 
            partnerId, userCapturedDetail, debug, contentInsurance)
            .then((response) => {
                if (response.ok) {
                    dispatch({ type: 'UPDATE_CURRENT_PARTITION', data: response.headers.get('currentDataPartition') });
                }
                return response;
            })
            .then((response) => handleJSONResponse(response))
            .then((questionResponse) => dispatch(receiveQuestions(questionResponse, productId, profileId, propertyId, partnerId)))
            .catch((error) => dispatch(serverError(error)));
    };
};

export const editAnswer = (productId,
    profileId,
    questions,
    answer,
    addressChosenValue,
    shouldTriggerReload,
    partnerId, fetchDependentQuestions = debouncedFetchQuestions) => {
    let shouldTriggerReloadVal = shouldTriggerReload;
    shouldTriggerReloadVal = true;
    const index = findIndex(questions.data, (selectedQuestion) => selectedQuestion.id === answer.question);
    const question = questions.data[index] = { ...questions.data[index], isInvalid: false, answer: answer.answer };
    return (dispatch, getState) => {
        dispatch({ type: 'ANSWER_UPDATED', questions: questions.data });
        dispatch({ type: 'SHOW_RESULT_SECTION', showResult: false });
        if (question.needsReload === true && shouldTriggerReloadVal) {
            const answerValue = question.inputType === "text" ? answer.answer : question.options.find((option) => option.id === parseInt(answer.answer)).label;
            const answerId = question.inputType === "text" ? null : answer.answer;
            let obj9 = {
                ProductId: productId,
                ProfileId: profileId,
                PropertyId: addressChosenValue.propertyId
            };
            let updatedAnswer = new AnalyticsAnswer(obj9, "", "SUCCESS",
                "Answer Updated", answerId, answerValue, partnerId);
            let {updatedFinalMap} = getState();
            if (updatedFinalMap === null) {
                dispatch(initTrackSegmentEvent());
            }            
            dispatch(updateSegmentEvent(question.text, updatedAnswer));
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ANSWER_UPDATED, updatedAnswer));
            const answers = questions.data.map((q) => toAnswer(q));
            return fetchDependentQuestions(dispatch, getState, answers, {propertyId : addressChosenValue.propertyId, address : addressChosenValue.suggestion},
                partnerId);
        }
    };
};

export const cceEditAnswer = (sectionName, buildingGrpIndex, productId,
    profileId,
    questions,
    answer,
    addressChosenValue,
    shouldTriggerReload,
    partnerId, fetchDependentQuestions = debouncedFetchQuestions) => {
    let shouldTriggerReloadVal1 = shouldTriggerReload;
    shouldTriggerReloadVal1 = true;

    let questionDataIndex = {
        grpIndex: 0,
        questionIndex: 0
    }
    questions.map((value, i) => value.questions.map((questionSelected, indexQuestion) => {

        if (questionSelected.id === answer.question) {
            questionDataIndex = { grpIndex: i, questionIndex: indexQuestion }
        }
        return value;
    }));

    const question = questions[questionDataIndex.grpIndex].questions[questionDataIndex.questionIndex] = {
        ...questions[questionDataIndex.grpIndex].questions[questionDataIndex.questionIndex], isInvalid: false, answer: answer.answer
    };

    return (dispatch, getState) => {
        const state = getState();
        const questionData = state.questions.data;
        const index = findIndex(questionData, (selectedQuestion) => selectedQuestion.id === answer.question);
        questionData[index] = { ...questionData[index], isInvalid: false, answer: answer.answer };

        dispatch({ type: 'BUILDING_GRP_UPDATED', buildingDataUpdate: { index: buildingGrpIndex, group: questions, questions: questionData } });
        dispatch({ type: 'SHOW_RESULT_SECTION', showResult: false });
        if (question.needsReload === true && shouldTriggerReloadVal1) {
            const answerValue = question.inputType === "text" ? answer.answer : find(question.options, (option) => option.id === answer.answer).label;
            const answerId = question.inputType === "text" ? null : answer.answer;
            let obj10 = {
                ProductId: productId,
                ProfileId: profileId,
                PropertyId: addressChosenValue.propertyId
            };
            let updatedAnswer = new AnalyticsAnswer(obj10, "", "SUCCESS",
                "Answer Updated", answerId, answerValue, partnerId);
            let {updatedFinalMap} = getState();
            if (updatedFinalMap === null) {
                dispatch(initTrackSegmentEvent());
            }    
            dispatch(updateSegmentEvent(question.text, updatedAnswer));
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ANSWER_UPDATED, updatedAnswer));
            const answers = questions.map((q) => q.questions.map((data) => toAnswer(data))).reduce((q, y) => q.concat(y), []);
            let newAnswer = questionData.map((q) => toAnswer(q));
            let finalAnswers = newAnswer.filter(function (o1) {
                return !answers.some(function (o2) {
                    return o1.question === o2.question;
                });
            });
            finalAnswers.push(...answers);
            return fetchDependentQuestions(dispatch, getState, finalAnswers, {propertyId : addressChosenValue.propertyId, address : addressChosenValue.suggestion},
                partnerId, buildingGrpIndex, sectionName);
        } else if (sectionName === 'Building Details') {
            dispatch(validateBuildingDetailsFields());
        }
    };
};


export const setHelpInformationOpen = (open, question) => {
    return {
        type: 'OPEN_HELP_INFORMATION_DIALOG', "help": { "open": open, "question": question }
    };
};

export const setLetUsKnowDialogOpen = (open, address, propertyId) => {
    return {
        type: 'OPEN_LET_US_KNOW_DIALOG',
        "letusknow": {
            "open": open,
            "address": address,
            "propertyId": propertyId,
        }
    };
};


export const sendSupportEmail = ({open, productId, profileId}, propertyId, address, name, email, mobile, partnerId) => {
    return (dispatch) => {
        dispatch({ type: 'ATTEMPT_LET_US_KNOW_EMAIL' });
        sendSupportEmailApi(productId, profileId, propertyId, address, name, email, mobile)
            .then((response) => {
                let updateEmail = email;
                let updateName = name;
                let updateMobile = mobile;
                    if (UrlConstants.PROFILE_ID_ELDERS.includes(profileId && partnerId)) {
                    updateEmail = "";
                    updateName = "";
                    updateMobile = "";
                  }
                        if (response.ok) {
                    let obj1 = {
                        Error : 'Calculation Error',
                        Name : updateName,
                        Email : updateEmail,
                        Mobile : updateMobile
                    };        
                    let userError = new UserReportedError(productId, profileId, propertyId, '', 'SUCCESS',
                        'User reported error emailed successfully', obj1, partnerId);
                    dispatch(trackSegmentEvent(SegmentTrackingEventTypes.REPORT_ERROR, userError));
                    setTimeout(function () {
                        dispatch({
                            type: 'OPEN_LET_US_KNOW_DIALOG',
                            letusknow: {
                                "open": false,
                                "address": '',
                                "propertyId": null,
                                "buttonActive": false,
                            }
                        });
                        dispatch({ type: 'CLOSE_LET_US_KNOW_EMAIL' });
                    }, 2000);
                }
                else {
                    let obj2 = {
                        Error : 'Calculation Error',
                        Name : name,
                        Email : updateEmail,
                        Mobile : mobile
                    };   
                    let userError = new UserReportedError(productId, profileId, propertyId, '', 'ERROR',
                        'Emailing user reported error failed', obj2, partnerId);
                    dispatch(trackSegmentEvent(SegmentTrackingEventTypes.REPORT_ERROR, userError));
                    dispatch({
                        type: 'OPEN_LET_US_KNOW_DIALOG',
                        letusknow: {
                            "open": false,
                            "address": '',
                            "propertyId": null,
                        }
                    });
                    dispatch({ type: 'CLOSE_LET_US_KNOW_EMAIL' });
                }
            })
            .catch((error) => {
                let updateEmail = email;
                let updateName = name;
                let updateMobile = mobile;
                 if (UrlConstants.PROFILE_ID_ELDERS.includes(profileId && partnerId)) {
                    updateEmail = "";
                    updateName = "";
                    updateMobile = "";
                }
                let obj3 = {
                    Error : error,
                    Name : updateName,
                    Email : updateEmail,
                    Mobile : updateMobile
                }; 
                let userError = new UserReportedError(productId, profileId, propertyId, '', 'ERROR',
                    'Emailing user reported error failed', obj3, partnerId);
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.REPORT_ERROR, userError));
                dispatch({
                    type: 'OPEN_LET_US_KNOW_DIALOG',
                    letusknow: {
                        "open": false,
                        "address": '',
                        "propertyId": null,
                    }
                });
                dispatch({ type: 'CLOSE_LET_US_KNOW_EMAIL' });
            });
    };
};

export const updateErrors = (isError, errorMsg, questionId) => {
    return (dispatch, getState) => {
        let { errors } = getState();
        errors = errors.filter(e => e.questionId !== questionId);
        if (isError) {
            let newError = {
                questionId,
                errorMsg
            };
            errors.push(newError);
        }
        dispatch({ type: 'VALIDATION_ERROR', errors: errors });
    };
};

export const validateUserAnswer = (productId, attributeName, value, questionId) => {
    return (dispatch) => {
        validateAnswerApi(productId, attributeName, value).then((response) => {
            if (response.status === 200) {
                return;
            }
            if (attributeName.toUpperCase() === "POSTCODE") {
                dispatch(updateErrors(true, "Please enter a valid postcode", questionId));
            }
        }).catch((error) => {
            if (attributeName.toUpperCase() === "POSTCODE") {
                dispatch(updateErrors(true, "Please enter a valid postcode", questionId));
            }
        });
    }
};

export const addMultipleBuilding = (productId, profileId, propertyId, address, buildingGrp) => {
    return (dispatch, getState) => {
        const { currentDataPartition, postcode, isEditEstimate, userCapturedDetail } = getState();
        let answers = [];
        if (postcode !== null && postcode !== undefined) {
            answers = [{ question: 302860, answer: postcode, visible: false, answerSource: "grid", helpEnabled: true }];
        }
        return getQuestions({productId, profileId, propertyId, address}, answers, 
            currentDataPartition, null, userCapturedDetail, false, false)
            .then((response) => {
                return response;
            }).then((response) => handleJSONResponse(response))
            .then((questionDataResponse) => {
                dispatch(addBuilding(questionDataResponse, postcode));
                if (isEditEstimate) {
                    dispatch(validateFields(productId, questionDataResponse.questions));
                }
            })
            .catch((error) => dispatch(serverError(error)));
    }
};

export const addBuilding = (questionData, postcode) => {
    return (dispatch, getState) => {
        const state = getState();
        const updQuestionData = updateNonBuildingDetails(state.questions.data, questionData);
        const questions = addDefaultIndemnityValuesToBuilding(updQuestionData.questions);
        dispatch({ type: 'UPDATE_FETCH_QUESTIONS', data: questions });
        const grp = updateGroupForMultipleBuilding(questions, postcode);

        dispatch({ type: 'NEW_BUILDING_ADDED', data: grp });
        dispatch({ type: 'NEW_BUILDING_ADDED_ERROR', errors: [] });
    }
}

export const removeMultipleBuilding = (indexValue) => {
    return (dispatch) => {
        dispatch({ type: 'BUILDING_REMOVED', data: indexValue });
        dispatch({ type: 'BUILDING_REMOVED_ERROR', errors: [] });
    }
};

export const addPostcodeToBuilding = (questions, postcode) => {
   const index = findIndex(questions, (question) => {
         return question.id === 302860;
   });
   questions[index] = { ...questions[index], answer: postcode, visible: false };
   return questions;
};

export const addDefaultIndemnityValuesToBuilding = (questions) => {
    const indexYearBuilt = findIndex(questions, (question) => {
          return question.id === 303545;
    });
    const indexYearOfBuilding = findIndex(questions, (question) => {
          return question.id === 303546;
    });
    if( indexYearBuilt > 0) {
        questions[indexYearBuilt] = { ...questions[indexYearBuilt], answer: null };
    }
    if( indexYearOfBuilding > 0) {
        questions[indexYearOfBuilding] = { ...questions[indexYearOfBuilding], answer: null };
    }
    return questions;
 };

export const updateNonBuildingDetails = (questions, questionData) => { 
    const nonBuildingQuestions = filter(questions, (question) => {
        return (question.section.name.toLowerCase() !== 'building details') && question.visible;
    }); 
    nonBuildingQuestions.forEach((nonBuildingQuestion) => {
        let questionIndex = findIndex(questionData.questions, (question) => {
            return question.id === nonBuildingQuestion.id;
        });
        if (questionIndex > 0) {
            questionData.questions[questionIndex] = {
                ...questionData.questions[questionIndex],
                answer: nonBuildingQuestion.answer,
                visible: nonBuildingQuestion.visible,
            };
        }
    });
    return questionData;
};

export const updateDefaultIndemnityValuesByBuilding = (buildingIndex) => {
    return (dispatch, getState) => {
        const state = getState();
        const questions = state.questions.data;
        const indexYearOfBuilding = findIndex(questions, (question) => {
            return question.id === 303546;
        });
        const indexYearBuilt = findIndex(questions, (question) => {
            return question.id === 303545;
        });
        let indemnityQuesDoesNotExists = false;
        if (state.buildingGrp.length > 0) {
            const lastBuildingGrp = state.buildingGrp[buildingIndex];
            lastBuildingGrp.forEach(group => {
                group.questions.forEach(question => {
                    if (question.id === 303544 && question.answer === "25942") {
                        indemnityQuesDoesNotExists = true;
                    }
                });
            });
        }

        if (indemnityQuesDoesNotExists) {
            if (indexYearOfBuilding > 0) {
                questions[indexYearOfBuilding] = { ...questions[indexYearOfBuilding], answer: null };
            }
            if (indexYearBuilt > 0) {
                questions[indexYearBuilt] = { ...questions[indexYearBuilt], answer: null };
            }
            dispatch({ type: 'UPDATE_FETCH_QUESTIONS', data: questions });
        }
        return questions;
    }
};

export const updateGroupForMultipleBuilding = (questionData, postcode) => {

    const grpWithPostcode = addPostcodeToBuilding(questionData, postcode);
    const buildingQuestions = filter(grpWithPostcode, (question) => {
        return (question.section.name.toLowerCase().indexOf('Building Details'.toLowerCase()) >= 0) && question.visible;
    });

    const questionsSortedBySection = sortBy(buildingQuestions, (question) => {
        return question.section.lineNo;
    });

    const groupedBuildingQuestions = groupBy(questionsSortedBySection, (param) => {
        return param.section.name;
    });

    const section = Object.keys(groupedBuildingQuestions).map((sections, i) => {
        const sectionNew = {
            questions: groupedBuildingQuestions[sections],
        }
        return sectionNew;
    });

    const questionsSorted = sortBy(section[0].questions, (question) => {
        return question.lineNo;
    });

    return groupByDependantFlag(questionsSorted);
};

export const onSaveRiskDetailApi = (productId, profileId, riskdetail, address, propertyId, postcode) => {
    return (dispatch) => saveRiskDetailApi(productId, profileId, riskdetail, address, propertyId, postcode)
        .then((response) => handleJSONResponse(response))
        .then((responseJson) => {
            return dispatch(updateRiskID(responseJson.riskId));
        });
};

export const updateRiskID = (riskId) => {
    return { type: 'RISKID_UPDATED', riskId: riskId };

};

export const updateExpandedIndex = (expandedIndex, secondIndex) => {
    return (dispatch) => {
    dispatch(updateDefaultIndemnityValuesByBuilding(expandedIndex));
    return dispatch({ type: 'COLLAPSE_PANEL_UPDATED', expandedIndex: expandedIndex, secondIndex: secondIndex });
    }
};

export const updateRisk = (productId, profileId, riskId, riskDetail) => {
    return (dispatch) => updateRiskApi(productId, profileId, riskId, riskDetail)
        .then(() => {
            history.push(`/products/${productId}/profiles/${profileId}/manage`);
            return dispatch({ type: 'RISK_UPDATED' });
        });
};

export const cancelEdit = (productId, profileId) => {
    return (dispatch, getState) => {
        const { answerUpdated, returnToEstimate } = getState();
        if (!answerUpdated && !returnToEstimate) {
            history.push(`/products/${productId}/profiles/${profileId}/manage`);
            return dispatch({ type: 'CANCELLED_EDIT' });
        } else {
            return dispatch({ type: 'RETURN_TO_ESTIMATE_DIALOG', open: true, cancel: true });
        }
    }
}
export const closeReturnToEstimateDialog = () => {
    return { type: 'RETURN_TO_ESTIMATE_DIALOG', open: false, returnToEstimate: true, cancel: false };
}

export const showReturnToEstimate = () => {
    return { type: 'RETURN_TO_ESTIMATE_DIALOG', open: true, cancel: false };
}

export const cancelAnswerUpdate = (pageName) => {
    return (dispatch, getState) => {
        const { productId, profileId, isCancelEstimate } = getState();
        if (isCancelEstimate) {
            history.push(`/products/${productId}/profiles/${profileId}` + UrlConstants.MANAGE);
        } else if (pageName === UrlConstants.MANAGE || pageName === UrlConstants.ADMIN) {
            history.push(`/products/${productId}/profiles/${profileId}` + pageName);
        }
        return dispatch({ type: 'CANCEL_ANSWER_UPDATE' });
    }
}

export const handleNextEstimate = (productId, profileId) => {
 return (dispatch) => {
        history.push(`/products/${productId}/profiles/${profileId}/estimate`);
        const event = new AnalyticsEvent("Estimate New", "Click New Estimate Link", "New Estimate Page", "New Estimate");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADD_ESTIMATE, event));
        return { type: 'NEW_ESTIMATE_CLICKED' };
    }
}

export const onTabChange = () => {
    return { type: 'TAB_CHANGE' };
}
export const onTabUserChange = () => {
    return { type: 'USER_TAB_CHANGE' };
}

export const onTabImportRisksChange = () => {
    return (dispatch, getState) => { 
        const {loggedInUserDetail} = getState();
        dispatch({type: 'IMPORT_RISKS_CHANGE', loggedInUserDetail: loggedInUserDetail});
       
}}

export const selectTableExport = (selectedTableExport) => {
    return { type: 'SELECT_TABLE_EXPORT', selectedTableExport: selectedTableExport };

};

export const onTabReportsChange = () => {
    return (dispatch, getState) => { 
        const {loggedInUserDetail} = getState();
        dispatch({type: 'REPORTS_CHANGE', loggedInUserDetail: loggedInUserDetail});
       
}}

export const selectReportCategoryType = (selectedCategoryType) => {
    return { type: 'SELECT_CATEGORY_TYPE', selectedCategoryType: selectedCategoryType };
};

export const selectCompanyUsage = (selectedCompanyUsage) => {
    return { type: 'SELECT_COMPANY_USAGE', selectedCompanyUsage: selectedCompanyUsage };
};

export const selectDivisionUsage = (selectedDivisionUsage) => {
    return { type: 'SELECT_DIVISION_USAGE', selectedDivisionUsage: selectedDivisionUsage };
};

export const selectTeamUsage = (selectedTeamUsage) => {
    return { type: 'SELECT_TEAM_USAGE', selectedTeamUsage: selectedTeamUsage };
};

export const selectStartDateUsage = (usageStartDate) => {
    return { type: 'SELECT_USAGE_START_DATE', usageStartDate: usageStartDate };
};

export const selectEndDateUsage = (usageEndDate) => {
    return { type: 'SELECT_USAGE_END_DATE', usageEndDate: usageEndDate };
};

export const resetUsageValues = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET_USAGE_VALUES' });
    };
};


export const downloadUsageReport = (productId, profileId, selectedReportCategoryType, selectedCompanyUsage, selectedDivisionUsage, selectedTeamUsage, usageStartDate, usageEndDate) => {
    return (dispatch) => {
        const fileDownload = require('react-file-download');
        usageReportingApi(productId, profileId, selectedReportCategoryType, selectedCompanyUsage, selectedDivisionUsage, selectedTeamUsage, formatDate(usageStartDate), formatDate(usageEndDate))
        .then(response => response.blob())
        .then(blob => {
            fileDownload(blob, 'UsageReport.csv');})
        .catch((error) => dispatch(this.serverError(error)));
    }
};

function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
export const updateRiskAndReturnNewEstimate = (productId, profileId, riskId, riskDetail) => {
    return (dispatch) => updateRiskApi(productId, profileId, riskId, riskDetail)
        .then(() => {
            return dispatch({ type: 'RISK_UPDATED' });
        });
};
export const updateRiskInNewEstimate= (productId, profileId, riskId, riskDetail) => {
    return (dispatch) => updateRiskApi(productId, profileId, riskId, riskDetail)
        .then(() => {
            return dispatch({ type: 'RISK_ADD_UPDATED', riskId });
        });
};

export const validateBuildingDetailsFields = () => { 
    return (dispatch, getState) => {
    const state = getState(); 
    const errors = []; 
    if (state.buildingGrp !== undefined) { 
        state.buildingGrp.forEach(grp => {
            grp.forEach(group => {
                group.questions.forEach(question => {
                    if ((question.answer === null || question.answer === "" || question.answer === undefined) &&
                       question.dependant && (question.id === 303545 || question.id === 303546)) { 
                        errors.push('Please set value for "' + question.text + '"');
                    }

                });
            });
        });
    }    
    dispatch({ type: 'VALIDATION_BUILDING_ERROR', errors: errors }); 
}
}; 

export const updateErrorForDependentQuestions = (buildingIndex) => { 
    return (dispatch, getState) => {
    const state = getState(); 
    let indemnityQuesDoesNotExists = false;
    if (state.buildingGrp !== undefined) { 
        const buildingDetail = state.buildingGrp[buildingIndex];
            buildingDetail.forEach(group => {
                group.questions.forEach(question => {
                    if (question.id === 303545 || question.id === 303546) { 
                        indemnityQuesDoesNotExists = true;
                    }
                });
            }); 
            if(!indemnityQuesDoesNotExists && 
                state.errors.filter(error => error.questionId === 303545 || error.questionId === 303546 ).length > 0) {
                const errors = state.errors.filter(error => (error.questionId !== 303545 && error.questionId !== 303546 )); 
                return dispatch({ type: 'VALIDATION_ERROR', errors: errors });
            }
    }    
}
};

export const updatePostcodeQuestions = (fetchDependentQuestions = debouncedFetchQuestions) => {
    return (dispatch, getState) => {
        const { productId, profileId, postcode, riskDetail, questions, currentDataPartition, partnerId } = getState();
        if (questions !== null && questions.data !== null) {
            const index = findIndex(questions.data, (question) => {
                return question.id === 302860;
            });
            if (index >= 0) {
                questions.data[index] = { ...questions.data[index], answer: postcode, visible: false, isInvalid: false, answerSource: "grid", helpEnabled: true };
                const answers = questions.data ? questions.data.map((question) => toAnswer(question)) : [];
                const { userCapturedDetail } = getState();
                return getQuestions({productId, profileId, propertyId : riskDetail.propertyId, address : riskDetail.address},
                    answers, currentDataPartition, partnerId, userCapturedDetail)
                    .then((response) => handleJSONResponse(response))
                    .then((questionsResponse) => {
                        dispatch(receiveQuestions(questionsResponse, productId, profileId, riskDetail.propertyId, partnerId));
                    })
                    .catch((error) => {
                        dispatch(serverError(error));
                    });
            }
        }
    };
};

export const validateFields = (productId, questions) => {
    return async (dispatch) => {
        const validationErrors = await validate(productId, questions);
        dispatch({ type: 'VALIDATION_ERROR', errors: validationErrors });
    }
};

export const uploadExportTable = (productId, profileId, file, emailAddress, loggedInUserId) => {
    return (dispatch) => {
        importEstimateApi(productId, profileId, file, emailAddress, loggedInUserId).then((response) => {
            if (response.status === 200) {
                return;
            }
        }).catch((error) => {
            dispatch(serverError(error));
        });
    }
};