import apiBaseUrl from '../apiBaseUrl';

const getUserDetailByUserGuidApi = (productId, profileId, userGuid) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getUsersByUserGuid/${userGuid}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Cache-control': 'no-store',
        'Pragma': 'no-cache',
        'Expires': 0,
    };
    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getUserDetailByUserGuidApi;