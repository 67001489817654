import AnalyticsAttributes from './AnalyticsAttributes';

class AnalyticsError extends AnalyticsAttributes {
    constructor({ProductId, ProfileId, PropertyId}, SessionId, Status, Description, Error, PartnerId) {
        super(ProductId, ProfileId, PropertyId, SessionId, Status, Description, PartnerId);
        this.Error = Error;
    }
}

export default AnalyticsError;

