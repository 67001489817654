import React from 'react';
import {TextField} from 'material-ui';
import {inputStyle, titleStyle} from '../style/styles';
import PropTypes from 'prop-types';

const UserFirstLastName = ({userNameText, recipient, onFirstNameChange, onLastNameChange, styles}) => {
    return (
        <div className="user-name-container">
            <div className="user-name-label">
                <div style={{
                    fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold' }} dangerouslySetInnerHTML={{__html: userNameText}}/>
            </div>
            <div className="user-name-field">
                <TextField
                    floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                    floatingLabelText="First Name"
                    floatingLabelFixed={true}
                    className="user-first-name-field required-field"
                    inputStyle={{...inputStyle, fontFamily: styles.globalFontFamily}}
                    underlineShow={false}
                    value={recipient.firstName}
                    onChange={(event, newValue) => {
                        onFirstNameChange(newValue);
                    }}
                />
                <TextField
                    floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                    floatingLabelText="Last Name"
                    floatingLabelFixed={true}
                    className="user-last-name-field required-field"
                    inputStyle={{...inputStyle, fontFamily: styles.globalFontFamily}}
                    underlineShow={false}
                    value={recipient.lastName}
                    onChange={(event, newValue) => {
                        onLastNameChange(newValue);
                    }}
                />
            </div>
        </div>
    );
};

UserFirstLastName.propTypes = {
    userNameText: PropTypes.string.isRequired,
    recipient: PropTypes.object.isRequired,
    onFirstNameChange: PropTypes.func.isRequired,
    onLastNameChange: PropTypes.func.isRequired,
    styles: PropTypes.object,
};

export default UserFirstLastName;