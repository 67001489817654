import React from 'react';
import { Card, CardText } from 'material-ui/Card';
import SectionHeader from './SectionHeader';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import FormControl from '@material-ui/core/FormControl';


class ImportRisks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            errorMsg: null,
            successMsg: null,
        };
        this.fileInput = React.createRef();
    }

    handleTableChange = (event) => {
        const { selectTableExport } = this.props;
        selectTableExport(event.target.value);
        this.setState({
            selectedFile: null,
            errorMsg: null
        });
    }


    handleFileInput = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
        const { uploadExportTable, productId, profileId, loggedInUserDetail } = this.props;
        if (event.target.files[0] !== null && event.target.files[0] !== undefined &&
            (event.target.files[0].type === ""
                || event.target.files[0].type === null ||
                (!"text/csv".includes(event.target.files[0].type) &&
                !"application/vnd.ms-excel".includes(event.target.files[0].type)))) {
            this.setState({
                selectedFile: null, successMsg: null,
                errorMsg: "Please upload only csv format file."
            });
        } else if (event.target.files[0] !== null && event.target.files[0] !== undefined) {
            this.setState({ errorMsg: null });
            uploadExportTable(productId, profileId, event.target.files[0], loggedInUserDetail.emailId, loggedInUserDetail.id);
            this.setState({ successMsg: "File Uploaded, once processing completes the status shall be mailed to registered email address", errorMsg: null });
        }
    }
    render() {
        const { styles, uploadExportTableType } = this.props;
        let fontFamilyName = styles.globalFontFamily;
        const success = this.state.successMsg !== null ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'black', fontWeight: 'bold' }}> {this.state.successMsg}</div> : null;
        const error = this.state.errorMsg !== null ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red' }}> *{this.state.errorMsg}</div> : null;
        return (
            <div className='user-group-management'>
                <Card className='group-card'>
                    <SectionHeader name="Import/Export" color={styles.sectionTileColour} styles={styles} />
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">Select Report </label>
                        <div className='fields  est-fields flex' style={{ padding: '0.3rem 0rem' }}>
                            <FormControl>
                                <Select id="tables-select" className="select-box est-select-box user-select-box"
                                    style={{ fontFamily: fontFamilyName }}
                                    value="riskEstimateTable"
                                    onChange={(event, newValue) => {
                                        this.handleTableChange(event);
                                    }}
                                    tabIndex={1}>
                                    <MenuItem value="riskEstimateTable">Risks and Estimates Table</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </CardText>
                    {(uploadExportTableType === "riskEstimateTable") && <div>
                        <CardText className="group-detail-form" style={{ display: 'flex' }}>
                            <label id="logo" className="group-detail-label">Select File to Import </label>
                            <div className="group-detail-field">
                                <input onChange={this.handleFileInput}
                                    accept=".csv"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    type="file"
                                />
                                <label htmlFor="raised-button-file" style={{ padding: '0.3rem 0rem' }}>
                                    <Button variant="contained" component="span" onClick={e =>
                                        this.fileInput.current && this.fileInput.current.click()}>
                                        Import
                                    </Button>
                                </label>

                            </div>
                        </CardText>
                        <CardText className="group-detail-form" style={{ display: 'flex' }}>
                            <div className="error" style={{ clearBoth: 'both', marginTop: '0em', textAlign: 'left', color: 'black' }}>
                                Note: The uploaded file must not contain more than 1500 records.
                            </div>
                        </CardText>

                        <CardText className="group-detail-form" style={{ display: 'flex' }}>
                            {error}
                            {this.state.errorMsg === null ? success : ""}
                        </CardText>
                    </div>

                    }
                </Card>
            </div>)


    }



}
export default ImportRisks;