import React from 'react';
import { Card, CardText } from 'material-ui/Card';
import ImageGallery from 'react-image-gallery';
import { isEmpty } from 'lodash';



class ToggleBox extends React.Component {

    toImages = (cceImage) => {
        return { original: cceImage.imageUrl, thumbnail: cceImage.imageUrl};
    };


	constructor(props) {
		super(props);
		this.state = {
			opened: false,
		};
		this.toggleBox = this.toggleBox.bind(this);
	}

	toggleBox() {
		const { opened } = this.state;
		this.setState({
			opened: !opened,
		});
	}


	render() {
      const {cceImages, noImageFile} = this.props;
              let tileData = !isEmpty(cceImages.data) ? cceImages.data.map((cceImage) => this.toImages(cceImage)) : [];

                    let  defaultData = [
                          {
                              original: noImageFile,
                              thumbnail: noImageFile,
                          },
                      ] ;


		const { opened } = this.state;

		return (
            <div className={opened  ?  "forimage" : "forimage forimageExpand" }>
			{opened && (
			<div className="box">
                <div className="main">
				<div className="boxTitle" onClick={this.toggleBox}>{ " << "  }</div>
					 <Card className="image-card-Toggle" style={{'overflow': 'hidden' ,  'width':'98%' , 'height':'450px'}}>
                           <CardText className="image-card-text">
                              <ImageGallery
                                additionalClass="image-preview"
                                items={isEmpty(tileData) ? defaultData : tileData}
                                showPlayButton={false}
                                useBrowserFullscreen={false}
                                showFullscreenButton={false}
                                thumbnailPosition="left"
                               />
                            </CardText>
                     </Card>
				</div>
				</div>
				)}
				{!opened && (
			<div className="box">
                   <div className="small">
					<div class="boxContent"  onClick={this.toggleBox}>{ " >> "}</div>
                           <Card className="image-card-Toggle"  style={{'width':'99%'}}>
                                          <CardText className="image-card-text">
                                              <ImageGallery
                                                  additionalClass="image-preview"
                                                  items={isEmpty(tileData) ? defaultData : tileData}
                                                  showPlayButton={false}
                                                  useBrowserFullscreen={false}
                                              />
                              </CardText>
                            </Card>
					</div>
            </div>
				)} 
            </div>
		);
	}
}

export default ToggleBox;