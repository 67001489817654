import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {sendSupportEmail, setLetUsKnowDialogOpen} from '../actions/questionsActionCreator';
import LetUsKnowDialog from "../components/LetUsKnowDialog";

export const mapStateToProps = (state) => {
    return {
        open: state.letusknow.dialogOpen,
        address: state.letusknow.address,
        productId: state.productId,
        profileId: state.profileId,
        propertyId: state.letusknow.propertyId,
        name: state.letusknow.name,
        email: state.letusknow.email,
        mobile: state.letusknow.mobile,
        styles: state.profile.styles,
        partnerId: state.partnerId,
    }
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onLetUsKnowDialogClose: (open, address, peropertyId) => dispatch(setLetUsKnowDialogOpen(open, address, peropertyId)),
        onLetUsKnow: ({open, productId, profileId}, propertyId, address, name, email, mobile, partnerId) =>
            sendSupportEmail({open, productId, profileId}, propertyId, address, name, email, mobile, partnerId),
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps) => {
    return {
        ...stateProps,
        handleClose: () => dispatchProps.onLetUsKnowDialogClose(false, stateProps.address, stateProps.propertyId),
        handleLetUsKnow: ({open, productId, profileId}, propertyId, address, name, email, mobile, partnerId) => {
            return dispatchProps.onLetUsKnow({open, productId, profileId}, propertyId, address, name, email, mobile, partnerId);
        },
    }
};

const LetUsKnowDialogContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(LetUsKnowDialog);

export default LetUsKnowDialogContainer;