import AnalyticsAttributes from './AnalyticsAttributes';

class AnalyticsAnswer extends AnalyticsAttributes {
    constructor({ProductId, ProfileId, PropertyId}, SessionId, Status, Description, AnswerId, NewAnswer, PartnerId) {
        super(ProductId, ProfileId, PropertyId, SessionId, Status, Description, PartnerId);
        this.AnswerId = AnswerId;
        this.NewAnswer = NewAnswer;
    }
}

export default AnalyticsAnswer;