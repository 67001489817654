import { connect } from 'react-redux';
import ToggleBox from '../components/ToggleBox';
import {bindActionCreators} from 'redux';


const mapStateToProps = (state) => {
    return {
        images: state.profile.images,
        styles: state.profile.styles,
        cceImages: state.cceImages,
        noImageFile: state.noImageFile,
        opened:state.opened,

    };
};

const mapDispatchToProps = (dispatch) => {
     return bindActionCreators({  }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        getNoImageFile: dispatchProps.fetchNoImageFileUrl,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ToggleBox);