export const SegmentTrackingEventTypes = {
    ANSWER_UPDATED : 'Update Answer',
    FINAL_ANSWER_UPDATED : 'Final Updated Answer',
    DEFAULT_CALCULATION : 'Calculate By Default',
    UPDATED_CALCULATION : 'Click Calculate Button',
    CALCULATION_DATA_VALIDATION_ERROR : 'Validate Calculator Data',
    DATA_REFRESH_ERROR : 'Refresh Data',
    ADDRESS_NOT_FOUND : 'Searched Address Not Found',
    EMAIL_REBUILD_COST_REPORT : 'Email Rebuild Cost Report',
    DOWNLOAD_REBUILD_COST_REPORT : 'Download Rebuild Cost Report',
    CLICK_SEARCH_AGAIN: 'Click Search Again Button',
    ADDRESS_CHOSEN: 'Choose Address from Suggestions',
    RETURN_TO_QUOTE: 'Click Return to Quote',
    TOTAL_OVER_2M : 'Click Calculate Button Over $2M Total',
    TOTAL_OVER_2_5M : 'Click Calculate Button Over $2.5M Total',
    CALL_TO_ACTION: 'Click a link in Call to Action',
    REPORT_ERROR: 'Error Reported by User',
    CONTACT_US: 'Click Contact us Button',
    CALL_TO_ACTION_BUTTON: 'Click Call to Action Button',
    GET_A_QUOTE: 'Get a Quote',
    ADD_USER: 'Click Add User Button',
    ADD_USER_GROUP: 'Click Add User Group Button',
    ADD_ESTIMATE: 'Click Add Estimate Button',
    EDIT_USER: 'Click Edit User Button',
    EDIT_USER_GROUP: 'Click Edit User Group Button',
    EDIT_RISK: 'Click Edit Risk Button',
    EDIT_ESTIMATE: 'Click Edit Estimate Button',
    DELETE_USER: 'Click Delete User Button',
    DELETE_USER_GROUP: 'Click Delete User Group Button',
    DELETE_RISK: 'Click Delete Risk Button',
    DELETE_ESTIMATE: 'Click Delete Estimate Button',
    ADDED_USER: 'Added User',
    ADDED_USER_GROUP: 'Added/Editted User Group',
    EDITED_USER: 'Updated User',
    EDITED_USER_GROUP: 'Updated User Group',
    DELETED_USER: 'Deleted User',
    DELETED_USER_GROUP: 'Deleted User Group',
    DELETED_RISK: 'Deleted Risk',
    DELETED_ESTIMATE: 'Deleted Estimate',
    REPRICE_ESTIMATE: 'Reprice Estimate',
    SEARCH_ESTIMATE: 'Search Estimate',
    SEARCH_USER_GROUP: 'Search User Group',
    SEARCH_USER: 'Search User',
    REPRICE_EXPORT: 'Reprice and Export Estimate',
    EXPORT: 'Export Estimate',
};