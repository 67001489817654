import React from 'react';
import ResultLine from './ResultLine';
import PropTypes from 'prop-types';

const CCEResultList = ({ variables, styles, currencySymbol = true, isIndemnity =  false }) => {
    const totalVariable = variables.filter((variable) => variable.title.startsWith('TOTAL'))[0];
    return (
        <div>
            {
                variables.map((variable, index) => {
                    return <div key={index}>
                        <ResultLine variable={variable} totalLineNo={totalVariable ? totalVariable.lineNo : 0} styles={styles} currencySymbol={currencySymbol} cceProduct = {true} isIndemnity ={isIndemnity}/>
                    </div>;
                })
            }
        </div>
    );
};

CCEResultList.propTypes = {
    variables: PropTypes.array.isRequired,
    styles: PropTypes.object,
};

export default CCEResultList;
