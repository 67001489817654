import UrlConstants from '../constants/UrlConstants';

export const validateTextQuestion = (question, value, productId) => {
    if (question.mandatory && value === '') {
        return 'Please provide an answer for this required field';
    }

    if ((productId === UrlConstants.PRODUCT_ID_7) || (productId === UrlConstants.PRODUCT_ID_8 || productId === undefined)) {
        if (value) {
           return getMessage(value, question)
        }
        else {
            return getMessageWhenValueisEmpty()
        }
    }
    return null;
};

function getMessage(value, question) {
    if (question.range !== null) {
        if (value < question.range.from || value > question.range.to) {
            return `Your answer should be in the range {from: ${question.range.from}, to: ${question.range.to}}`;
        }
    }
    return null;
}

function getMessageWhenValueisEmpty(value, question) {
    if (value.trim() === '' || value.trim() === 0 || value.trim() === '0') {
        if (question.range !== null) {
            if (value < question.range.from || value > question.range.to) {
                return `Your answer should be in the range {from: ${question.range.from}, to: ${question.range.to}}`;
            }
        }
    }
    return null;
}