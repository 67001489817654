import React from 'react';
import browser from 'detect-browser';
import AddressNotFoundDialog from './AddressNotFoundDialog';
import BrowserWarning from './BrowserWarning';
import Header from './Header';
import SearchSuggestions from './SearchSuggestions';
import { textOnImage } from '../style/styles';
import HomePageTermsOfUseDialog from './HomePageTermsOfUseDialog';
import PropTypes from 'prop-types';
import { printTermsAndConditions } from '../util/PrintUtil';
import UrlConstants from '../constants/UrlConstants';
import { FlatButton } from 'material-ui';
import { fetchPropertyAddress } from '../api/fetchPropertyAddress';
import HomePageNotes from "./HomePageNotes";
import history from '../util/history';
import UrlUtil from "../util/UrlUtil";
import AnalyticsCustomScript from "../util/AnalyticsCustomScript";
import WestpacCustomTagScript from "../util/WestpacCustomTagScript";
import AnalyticsConstant from '../constants/AnalyticsConstant';
import HeadMetaData from '../util/HeadMetaData';
import CookiesConsentBoxContainer from '../containers/CookiesConsentBoxContainer';

class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            property: null,
            dialogOpen: false,
            termsAndConditionsOpen: false,
            searchText: '',
            value: '',
            results: [],
            dataSource: [],
            acceptPropertyId: false,
            propertyNotFound: false,
            ariaMessage: 'Enter your Street number, name, suburb, state',
            resultFirstRecord: '',
        };
    }

    componentWillMount() {

        if (UrlConstants.PROFILE_ID_WESTPAC.includes(this.props.profileId || this.props.partner)) {
            WestpacCustomTagScript();
            AnalyticsCustomScript('../WbcScript.js');
        }
        HeadMetaData(this.props.productId, this.props.profileId, this.props.partner);

        if (!this.validateUrl()) {
            history.push("/not_found");
        } else {
            const { validateProfile, setValidateProfile, debug, setDebug, setShowNote, onLoad, segmentTrackingCode } = this.props;
            validateProfile();
            setValidateProfile();
            if (debug === true) {
                setDebug()
            }
            setShowNote();
            if (segmentTrackingCode && UrlConstants.PROFILE_ID_WESTPAC.includes(this.props.profileId || this.props.partner)) {
                onLoad(segmentTrackingCode, true);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { acceptPropertyId, propertyId, productId } = this.props;
        if (acceptPropertyId && ((acceptPropertyId !== prevProps.acceptPropertyId && propertyId) || propertyId !== prevProps.propertyId)) {
            this.fetchAddress(productId, propertyId);
        }

        const { returnUrl, quoteId, returnUrls, updateReturnData, enableReturnMode, redirectUrl } = this.props;
        this.updateReturnDataReturnModeEnabled(enableReturnMode, returnUrls, returnUrl, updateReturnData, quoteId, redirectUrl);

        const { profileId, partner, validateProfile, setValidateProfile } = this.props;
        if (productId !== prevProps.productId || profileId !== prevProps.profileId || partner !== prevProps.partner) {
            validateProfile();
            setValidateProfile();
        }

        const { segmentTrackingCode, onLoad } = this.props;
        if (segmentTrackingCode && segmentTrackingCode !== prevProps.segmentTrackingCode) {
            onLoad(segmentTrackingCode, false);
        }

        const { debug, setDebug } = this.props;
        if (debug !== prevProps.debug) {
            setDebug();
        }

        const { showNote, setShowNote } = this.props;
        if (showNote !== prevProps.showNote) {
            setShowNote();
        }

        const { suggestions } = this.props;
        const { searchText } = this.state;
        if (suggestions !== prevProps.suggestions && UrlConstants.PROFILE_ID_IAG_AU.includes(profileId)) {
            this.newMethod43(suggestions, searchText);
            
        }
    }

    validateUrl = () => {
        const { productId, requestUrl } = this.props;
        return (!((requestUrl.includes(UrlConstants.COM_AU) && productId === UrlConstants.PRODUCT_ID_8) || (requestUrl.includes(UrlConstants.CO_NZ) &&
            (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_9))));
    };

    fetchAddress = async (productId, propertyId) => {
        const response = await fetchPropertyAddress(productId, propertyId);
        const property = await response.json();
        if (property.isApplicable) {
            if (property.suggestion) {
                this.setState({
                    searchText: property.suggestion,
                    value: property.suggestion,
                    results: property.suggestion,
                    property: property,
                    propertyNotFound: false,
                });
            } else {
                this.setState({
                    searchText: "",
                    value: "",
                    results: [],
                    property: null,
                    propertyNotFound: false,
                });
            }
        } else if (!this.state.propertyNotFound) {
            this.setState({
                searchText: "",
                value: "",
                results: [],
                property: null,
                propertyNotFound: true,
            });
        }
    };

    handleClose = () => {
        this.setState({ dialogOpen: false, searchText: '', value: '', results: [] });
    };

    handleProceed = (property) => {
        const { productId, profileId, onAddressPicked, token, debug, partner, returnUrl, quoteId, showNote } = this.props;
        onAddressPicked(property);
        let url = `/products/${productId}/profiles/${profileId}/questions`;
        url = UrlUtil.addParams({ baseUrl: url, token, partner, debug, returnUrl, quoteId, showNote });
        history.push(url);
        if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || partner)) {
            window.pageDetails.pageName = AnalyticsConstant.QUESTION_PAGE;
            window.pageDetails.ordinal = new Date().getTime();
            window.pageDetails.pageURL = window.location.href;
            window.pageDetails.eventKey = `${window.pageDetails.siteBrand}:${window.pageDetails.siteExperience}_${window.pageDetails.pageType}_${window.pageDetails.pageName}`;
            AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
        }
    };

    handleCloseAddressNotFoundDialog = (property) => {
        if (this.props.bypassTc) {
            this.setState({ dialogOpen: false, termsAndConditionsOpen: false, property: property });
            this.handleProceed(property);
        } else {
            if (UrlConstants.PROFILE_ID_WESTPAC.includes(this.props.profileId || this.props.partner)) {
                window.pageDetails.pageName = AnalyticsConstant.AGREE;
                window.pageDetails.ordinal = new Date().getTime();
                window.pageDetails.eventKey = `${window.pageDetails.siteBrand}:${window.pageDetails.siteExperience}_${window.pageDetails.pageType}_${window.pageDetails.pageName}`;
                AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
            }
            this.setState({ dialogOpen: false, termsAndConditionsOpen: true, property: property });
        }
    };

    handleRejectTCs = () => {
        this.setState({ termsAndConditionsOpen: false, property: null, dataSource: [], searchText: '', value: '', results: [] });
    };

    onNewRequest = (property) => {
        if (property.isApplicable) {
            if (this.props.bypassTc) {
                this.handleProceed(property);
            } else {
                if (UrlConstants.PROFILE_ID_WESTPAC.includes(this.props.profileId || this.props.partner)) {
                    window.pageDetails.pageName = AnalyticsConstant.AGREE;
                    window.pageDetails.ordinal = new Date().getTime();
                    window.pageDetails.eventKey = `${window.pageDetails.siteBrand}:${window.pageDetails.siteExperience}_${window.pageDetails.pageType}_${window.pageDetails.pageName}`;
                    AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
                }
                this.setState({ termsAndConditionsOpen: true, property: property });
            }
        } else {
            this.setState({ dialogOpen: true });
        }
    };

    handlePrintTC = (productId) => {
        let sourceElement = null;
        if (productId === UrlConstants.PRODUCT_ID_8) {
            sourceElement = 'nzTermAndCondition';
        } else if (productId === UrlConstants.PRODUCT_ID_7) {
            sourceElement = 'auTermAndCondition';
        } else if (productId === UrlConstants.PRODUCT_ID_10) {
            sourceElement = 'cceNzTermAndCondition';
        } else if (productId === UrlConstants.PRODUCT_ID_9) {
            sourceElement = 'cceAuTermAndCondition';
        }
        printTermsAndConditions(sourceElement);
        return true;
    };

    onChange = (value) => {
        if (UrlConstants.PROFILE_ID_IAG_AU.includes(this.props.profileId)) {
            this.setState({ ariaMessage: value, resultFirstRecord: '' });
        }
        if (this.props.productId === UrlConstants.PRODUCT_ID_8) {
            this.setState({ searchText: value, value: value });
        } else {
            this.setState({ searchText: value.replace(/,/g, ''), value: value.replace(/,/g, '') });
        }
    };

    onResultSelectionChange = (value) => {
        const { suggestions } = this.props;
        this.setState({ ariaMessage: value });
        if (browser.name === 'firefox' && suggestions.data.length > 0 && value === suggestions.data[0].suggestion) {
            this.setState({ resultFirstRecord: value });
        } else {
            this.setState({ resultFirstRecord: '' })
        }
    };

    newMethod43(suggestions, searchText) {
        let propertyAddress = "";
        if (suggestions.data && suggestions.data !== null && suggestions.data.length > 0) {
            suggestions.data.forEach(property => propertyAddress = propertyAddress + property.suggestion + " ");
        } else {
            propertyAddress = "Can't find the address? Please click here to proceed and enter your details manually.";
        }
        propertyAddress = searchText.length === 0 ? 'Enter your Street number, name, suburb, state' : propertyAddress;
        this.setState({ ariaMessage: propertyAddress });
    }


    updateReturnDataReturnModeEnabled(enableReturnMode, returnUrls, returnUrl, updateReturnData, quoteId, redirectUrl) {
        if (enableReturnMode && returnUrls && returnUrl) {
            let isValidReturnUrl = false;
            let returnUrlArr = returnUrls.split(",");
            returnUrlArr.forEach(url => {
                if (returnUrl.indexOf(url.trim()) !== -1 && !isValidReturnUrl) {
                    updateReturnData({ "returnUrl": returnUrl, "quoteId": quoteId });
                    isValidReturnUrl = true;
                }
            });

        } else if (enableReturnMode && returnUrls && redirectUrl) {
             updateReturnData({ "returnUrl": returnUrls + redirectUrl, "quoteId": quoteId });
        } else {
            updateReturnData(null);
        }
    }

    render() {
        const { images, styles, text, acceptPropertyId, propertyId, disclaimerText, productId, logoLeftUrl, debug, logoRightUrl, suggestions, profileId, result } = this.props;
        const autofillAddress = acceptPropertyId && propertyId && !this.state.propertyNotFound;
        let proceedButton = autofillAddress ?
            <div className="search-again-button">
                <FlatButton
                    className="input"
                    label="Proceed"
                    onKeyPress={event => {
                        this.whenEventKeyEnter(event);
                    }}
                    onClick={() => this.onNewRequest(this.state.property)}
                    labelStyle={setLabelStyles(styles)}
                    style={setStyle(styles)}
                />
            </div>
            : null;

        let homepageStyle = { fontFamily: styles.globalFontFamily, top: 0, bottom: 0, webkitBackgroundSize: 'cover' }
        this.setHomePageBackground(styles, homepageStyle, images);

        let searchBoxStyle = {
            textAlign: 'center',
            verticalAlign: 'middle',
            display: 'table-cell',
            padding: '4px 10px 4px 10px',
            boxSizing: 'initial'
        };

        if (styles.landingPageSearchBoxBackgroundColor) {
            searchBoxStyle.backgroundColor = styles.landingPageSearchBoxBackgroundColor;
        }

        if (styles.landingPageSearchBoxBackgroundTransparency) {
            searchBoxStyle.opacity = styles.landingPageSearchBoxBackgroundTransparency;
        }

        if (styles.landingPageSearchBoxWidth) {
            searchBoxStyle.width = styles.landingPageSearchBoxWidth;
            searchBoxStyle.maxWidth = '64rem';
        }

        if (styles.landingPageSearchBoxHeight) {
            searchBoxStyle.height = styles.landingPageSearchBoxHeight;
        }

        let fontFamilyName = styles.globalFontFamily;
        if (profileId === '118' && ((window.screen.width >= 320 && window.screen.width <= 480)
            && (navigator.userAgent.match(/Android/i)))) {
            fontFamilyName = "Arial";
        }
        let isProfileEligibleForCookiesConsentBox = false;
        if (productId === '7' && (profileId === '141' || profileId === '142')) {
            isProfileEligibleForCookiesConsentBox = true;
        } 
        return (
            <div className="homepage" style={homepageStyle}>
                <HomePageTermsOfUseDialog
                    tabIndex={ 1 }
                    open={this.state.termsAndConditionsOpen}
                    onAgree={() => this.handleProceed(this.state.property)}
                    onCancel={() => this.handleRejectTCs()}
                    styles={styles}
                    productId={productId}
                    profileId={profileId}
                    onPrint={() => this.handlePrintTC(productId)}
                />
                <Header debug={debug} images={images} styles={styles} text={text}
                    logoRightUrl={logoRightUrl} logoLeftUrl={logoLeftUrl} />
                <div className="home-page-container" style={{ color: textOnImage.color }}>
                    <div className="search-container"
                        style={{
                            fontFamily: fontFamilyName,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <BrowserWarning name={browser ? browser.name : 'phantomjs'}
                            version={browser ? browser.version : '0.0'} />
                        <div style={searchBoxStyle} className='search-box'>

                            <div style={{
                                display: 'inline-block', alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <h1 className="header-text" style={{
                                    fontFamily: fontFamilyName,
                                    fontSize: styles.homePageHeaderFontSize,
                                    color: styles.homePageHeaderFontColour,
                                    fontWeight: styles.headerTitleFontWeight,
                                }} dangerouslySetInnerHTML={{ __html: text.homePageHeader }}></h1>

                                <label htmlFor="addressInput" className="above-text" style={{
                                    fontSize: styles.aboveAddressBarFontSize,
                                    color: styles.aboveAddressBarFontColour,
                                    marginLeft: '1rem',
                                    marginRight: '1rem'
                                }} dangerouslySetInnerHTML={{ __html: text.aboveAddressBar }} ></label>
                                <div style={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                }}>
                                    <div className="search-address-form search-box" style={{
                                        fontFamily: fontFamilyName,
                                        paddingTop: '2rem',
                                        width: '95%',
                                    }}>
                                        <div id="addressSelected" className="address-selected" aria-live="assertive">{this.state.ariaMessage}</div>
                                        <div className="address" name="address" style={{ width: '100%' }}>
                                            <SearchSuggestions
                                                searchText={this.state.searchText}
                                                newValue={this.state.value}
                                                results={this.state.results}
                                                suggestions={suggestions}
                                                onNewRequest={this.onNewRequest}
                                                onChange={this.onChange}
                                                onSearchTermChanged={this.props.onSearchTermChanged}
                                                styles={styles}
                                                productId={productId}
                                                showSearchIcon={!autofillAddress}
                                                name="addressInput"
                                                profileId={profileId}
                                                fontFamilyName={fontFamilyName}
                                                onResultSelectionChange={this.onResultSelectionChange}
                                                resultData={result}
                                            />
                                        </div>
                                        <div id="address1" className="address-selected" aria-live="assertive">{this.state.resultFirstRecord}</div>
                                        {proceedButton}
                                    </div>
                                </div>
                                <AddressNotFoundDialog
                                    dialogOpen={this.state.dialogOpen}
                                    styles={styles}
                                    handleClose={this.handleClose}
                                    handleProceed={() => this.handleCloseAddressNotFoundDialog({
                                        propertyId: null,
                                        suggestion: this.state.searchText,
                                    })}
                                />
                                <div style={{ paddingTop: '2rem', paddingBottom: '1.7rem' }}>
                                    <span className="below-text" style={{
                                        fontSize: styles.belowAddressBarFontSize,
                                        color: styles.belowAddressBarFontColour
                                    }}>
                                        {text.belowAddressBar}</span>
                                </div>

                            </div>
                        </div>
                        <div style={{ paddingTop: '1rem' }}>
                            <HomePageNotes text={text.landingPageNotesText} styles={styles} />
                        </div>
                    </div>
                    <div style={{
                        textAlign: 'center', width: '100%',
                        backgroundColor: styles.landingPageFooterBannerBackgroundColor,

                    }}>
                        <div className="disclaimer" style={{
                            flexShrink: 0,
                            display: 'inline-block',

                        }}>
                            <div style={{
                                maxWidth: styles.landingPageFooterDisclaimerMaxWidth || '64rem',
                                textAlign: styles.landingPageFooterDisclaimerAlignment || 'justify',
                            }}>
                                <div style={{
                                    fontSize: styles.footerFontSizeLandingPage,
                                    color: styles.footerFontColourLandingPage,
                                    paddingTop: '1rem',
                                    paddingBottom: '1rem',
                                    verticalAlign: 'middle',
                                    lineHeight: 'normal'
                                }}
                                    dangerouslySetInnerHTML={{
                                        __html: disclaimerText ? disclaimerText :
                                            '<p><strong>DISCLAIMER:</strong>The Cordell Sum Sure calculator takes the answers you input ' +
                                            '(or confirm, as applicable) and analyses them against construction industry data collated by ' +
                                            'CoreLogic to generate an estimated rebuild cost of the improvements on your property. ' +
                                            'Use of the Cordell Sum Sure calculator and any estimated rebuild cost provided by it may not be ' +
                                            'appropriate for you and you will need to assess the suitability of this estimate given your ' +
                                            'knowledge of your property. A number of qualifications apply to the estimated rebuild cost, ' +
                                            'which are set out in the full set of terms and conditions accepted by you prior to your use ' +
                                            'of the Sum Sure Calculator.</p>',
                                    }} />
                            </div>
                        </div>
                    </div>
                    {isProfileEligibleForCookiesConsentBox && <CookiesConsentBoxContainer productId={productId} profileId={profileId} />}
                </div>
            </div>);
    }

    setHomePageBackground(styles, homepageStyle, images) {
        if (styles.homePageBackgroundColour) {
            homepageStyle.backgroundColor = styles.homePageBackgroundColour;
        } else {
            homepageStyle.background = ' url(' + (images.homePageBackgroundImageUrl ? images.homePageBackgroundImageUrl : '') + ') no-repeat ' + styles.homePageBackgroundFocus;
        }
    }

    whenEventKeyEnter(event) {
        if (event.key === 'Enter') {
            this.onNewRequest(this.state.property);
        }
    }
}

HomePage.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    debug: PropTypes.bool.isRequired,
    suggestions: PropTypes.object.isRequired,
    disclaimerText: PropTypes.string,
    images: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    text: PropTypes.object,
    onAddressPicked: PropTypes.func.isRequired,
    validateProfile: PropTypes.func.isRequired,
    setDebug: PropTypes.func.isRequired,
    setValidateProfile: PropTypes.func.isRequired,
    onSearchTermChanged: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    logoLeftUrl: PropTypes.string,
    logoRightUrl: PropTypes.string,
};

export default HomePage;

function setStyle(styles) {
    return {
        display: "",
        backgroundColor: styles.searchAgainButtonColour,
        borderRadius: styles.buttonRadius,
        border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : "",
        maxHeight: "48px",
        height: "100%",
        boxSizing: "border-box",
    };
}

function setLabelStyles(styles) {
    return {
        textTransform: styles.buttonTextCase ? styles.buttonTextCase : "uppercase",
        fontSize: styles.primaryButtonFontSize,
        fontFamily: styles.globalFontFamily,
        color: styles.searchAgainButtonFontColour,
    };
}
