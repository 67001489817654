import {connect} from 'react-redux';
import LandingPage from '../components/LandingPage';

const mapStateToProps = (state, defaultProps) => {
    return {
        productId: defaultProps.match.params.productId,
        profileId: defaultProps.match.params.profileId,
        requestUrl: window.location.href,
    };
};

export default connect(mapStateToProps)(LandingPage);