import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'material-ui/Card';
import { Button } from '@material-ui/core'; 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const EstimatePreview = ({ productId, profileId, fontFamilyName, searchResult, handleRepriceEstimate, handleEstimateDetailDeleteDialog, editEstimate,
    handleEmailDownloadReport, handleActiveEstimate, activeEstimateIndex, handlePopUpEstimate, activePopUpIndex, popupExpanded, isMobile, userList }) => {
        const formatDatewithTimeZone = newFunction1();
    return (searchResult.isOpen && searchResult.data.length > 0 ?
        searchResult.estimateResult.map((estimateDetail, index) => {
            const price = newFunction2(estimateDetail);

            const isInCompleteEstimate = newFunction3(estimateDetail);
            return <Card className={isMobile ? 'estimate-preview mobile' : 'estimate-preview'}>
                {!isMobile && <div className='estimate-card'>
                    <div className={activeEstimateIndex === index ? 'estimate estimate-active' : 'estimate'} onClick={() => handleActiveEstimate(index)}>
                        <Table aria-label="Estimate Listing" style={{ fontFamily: fontFamilyName }} className="risk-tbl-preview">
                            <TableBody key={estimateDetail.estimateID} >
                                <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head risk-tbl-cell-est" style={{ textAlign: 'right', width: '14.2%' }}>ESTIMATED PRICE: </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold risk-tbl-cell-est" style={{ textAlign: 'left', width: '20.8%' }}>  {price} </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ width: '8%' }}> GENERATED BY:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" style={{ width: '17.1%' }}> {estimateDetail.estimateGeneratedBy} </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ width: '9%' }}> ESTIMATED DATE:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" style={{ width: '16.7%' }}>
                                        {estimateDetail.estimateDate !== null
                                            && estimateDetail.estimateDate !== undefined ? formatDatewithTimeZone(estimateDetail.estimateDate, estimateDetail.zoneShortName) : estimateDetail.estimateDate}
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell" style={{ width: '4%', height: '3rem' }}> <span class="risk-tbl-btn"
                                        style={{ fontFamily: fontFamilyName, height: '3rem', display: 'contents' }}
                                        tabIndex={1}
                                        onClick={() => editEstimate(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}
                                    >Edit/View Estimates Details</span></TableCell>
                                    <TableCell className="risk-tbl-cell" style={{ width: '4%' }}>  <Button
                                        style={{ fontFamily: fontFamilyName }}
                                        className="est-delete"
                                        tabIndex={1}
                                        onClick={() => handleEstimateDetailDeleteDialog(true, estimateDetail, estimateDetail.estimatePrice)}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false}
                                    >  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg></Button>
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell est-dot-btn"> <Button
                                        style={{ fontFamily: fontFamilyName }}
                                        disableTouchRipple={true}
                                        className="est-dots"
                                        disableFocusRipple={false}
                                        tabIndex={1} onClick={() => handlePopUpEstimate(index)}><div class=
                                            {activePopUpIndex === index && activeEstimateIndex === index && popupExpanded ?
                                                "risk-dots dots-select-popup" : "risk-dots"}></div></Button>
                                        {activePopUpIndex === index && activeEstimateIndex === index && popupExpanded &&
                                            <div className="est-popup-container">
                                                <div className="est-popup-box">
                                                    <div className="est-trigger">
                                                        <div className="est-popup">
                                                            <div className="est-popup-inner">
                                                                <Button
                                                                    style={{ fontFamily: fontFamilyName, textAlign: 'left', width: '100%', marginRight: '0rem', padding: '0.5rem' }}
                                                                    tabIndex={1}
                                                                    className="est-email-download"
                                                                    disabled={isInCompleteEstimate ? true : false}
                                                                    onClick={() => handleRepriceEstimate(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}
                                                                    disableTouchRipple={true}
                                                                    disableFocusRipple={false}
                                                                ><div>Reprice</div></Button> <Button
                                                                    style={{ fontFamily: fontFamilyName, textAlign: 'left', width: '100%', marginRight: '0rem', padding: '0.5rem' }}
                                                                    tabIndex={1}
                                                                    className="est-email-download"
                                                                    disabled={isInCompleteEstimate ? true : false}
                                                                    onClick={() => handleEmailDownloadReport(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}
                                                                    disableTouchRipple={true}
                                                                    disableFocusRipple={false}
                                                                >Email /Download</Button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
                }
                {isMobile && <div className='estimate-card'>
                    <div className={activeEstimateIndex === index ? 'estimate estimate-active' : 'estimate'} onClick={() => handleActiveEstimate(index)}>
                        <Table aria-label="Estimate Listing" style={{ fontFamily: fontFamilyName }} className="risk-tbl-preview">
                            <TableBody key={estimateDetail.estimateID} >
                            <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head risk-tbl-bold risk-mob-price" style={{ height: '22px', width: '32%' , textAlign: 'left'  }}>ESTIMATED PRICE: </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold  risk-tbl-bolder" style={{ textAlign: 'left' }} 
                                    colspan="3">  {price} </TableCell>
                                  </TableRow>
                                  <TableRow>
                                  <TableCell className="risk-tbl-cell risk-tbl-cell-head"style={{ height: '22px'}}> GENERATED BY:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold"  colspan="3"> {estimateDetail.estimateGeneratedBy} </TableCell>
                                  </TableRow>
                                  <TableRow> 
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ height: '22px'}}> ESTIMATED DATE:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" colspan="3">
                                        {estimateDetail.estimateDate !== null
                                            && estimateDetail.estimateDate !== undefined ? formatDatewithTimeZone(estimateDetail.estimateDate, estimateDetail.zoneShortName) : estimateDetail.estimateDate}
                                    </TableCell>
                                    </TableRow>
                                  <TableRow className={ activeEstimateIndex !== index ?  "mob-row-border" : "mob-no-border"}>
                                      
                                    <TableCell  colspan="2" className="risk-tbl-cell"
                                     style={{ textAlign: 'right'  ,height: '3rem',fontFamily: fontFamilyName }}> 
                                     <span class="risk-tbl-btn"
                                        onClick={() => editEstimate(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}                                        
                                        tabIndex={1} style={{ display: 'contents' }}
                                    >Edit/View Estimates Details</span></TableCell>
                                    <TableCell className="risk-tbl-cell" style={{width: '3%'}}>  
                                    <Button  className="est-delete"
                                        style={{ fontFamily: fontFamilyName}}                                        
                                        onClick={() => handleEstimateDetailDeleteDialog(true, estimateDetail, estimateDetail.estimatePrice)}
                                        disableTouchRipple={true}
                                        tabIndex={1}
                                        disableFocusRipple={false}
                                    >  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg></Button>
                                    </TableCell>
                                    <TableCell  style={{ width :'3%'}} className="risk-tbl-cell est-dot-btn"> <Button
                                        style={{ fontFamily: fontFamilyName }}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false}
                                        className="est-dots"
                                        tabIndex={1} onClick={() => handlePopUpEstimate(index)}><div class=
                                            {activePopUpIndex === index && activeEstimateIndex === index && popupExpanded ?
                                                "risk-dots dots-select-popup" : "risk-dots"}></div></Button>
                                        {activePopUpIndex === index && activeEstimateIndex === index && popupExpanded &&
                                            <div className="est-popup-container">
                                                <div className="est-popup-box mob-popup-box">
                                                    <div className="est-trigger mob-est-trigger">
                                                        <div className="est-popup mob-est-popup">
                                                            <div className="est-popup-inner mob-popup-inner">
                                                                <Button
                                                                    style={{ fontFamily: fontFamilyName, textAlign: 'left', width: '100%', marginRight: '0rem', padding: '0.5rem' }}
                                                                    tabIndex={1}
                                                                    disabled={isInCompleteEstimate ? true : false}
                                                                    className="est-email-download"
                                                                    onClick={() => handleRepriceEstimate(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}
                                                                     disableFocusRipple={false} disableTouchRipple={true}
                                                                ><div>Reprice</div></Button> <Button
                                                                    style={{ textAlign: 'left', width: '100%', fontFamily: fontFamilyName, marginRight: '0rem', padding: '0.5rem' }}
                                                                    tabIndex={1}
                                                                    disabled={isInCompleteEstimate ? true : false}  className="est-email-download"                                                                   
                                                                    onClick={() => handleEmailDownloadReport(estimateDetail.riskId, estimateDetail.estimateId, estimateDetail.estimatePrice)}
                                                                    disableTouchRipple={true}  disableFocusRipple={false}                                                                   
                                                                >Email /Download</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
                }
            </Card>
        }) : ""
    );
};

EstimatePreview.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
    editEstimate: PropTypes.func.isRequired,
    handleEstimateDetailDeleteDialog: PropTypes.func.isRequired,
    handleEmailDownloadReport: PropTypes.func.isRequired,
};

export default EstimatePreview;

function newFunction3(estimateDetail) {
    return estimateDetail.estimatePrice === "In Progress" ? true : false;
}

function newFunction2(estimateDetail) {
    return estimateDetail.estimatePrice === "In Progress" ? "In Progress" : "$" +
        newFunction4(estimateDetail);
}

function newFunction4(estimateDetail) {
    return estimateDetail.estimatePrice !== null ? Number(estimateDetail.estimatePrice).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : estimateDetail.estimatePrice;
}

function newFunction1() {
    return (date, zoneShortName) => {
        if (date !== undefined && date !== "") {
            var myDate = new Date(date);
            var month = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ][myDate.getMonth()];
            var hours = myDate.getHours();
            hours = getHours(hours, date);
            var minutes = myDate.getMinutes();
            if (Number.isNaN(minutes) && date.length > 15) {
                minutes = Number(date.substr(15, 2).trim());
            }
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            var str = myDate.getDate() + " " + month + " " + myDate.getFullYear() + " "
                + strTime + " " + zoneShortName;
            return str.indexOf("undefined") >= 0 ? (newFunction5(date) + " " +
                strTime + " " + zoneShortName) : str;
        }
        return "";
    };

    function getHours(hours, date) {
        if (Number.isNaN(hours) && date.length > 12) {
            hours = date.substr(12, 2);
        }
        return hours;
    }
}
function newFunction5(date) {
    return date.length > 12 ? date.substr(0, 12) : date;
}

