import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FilterUser from '../components/FilterUser';
import { searchUserResults, setUserSearchBy, setUserSearchText,
     onClearUserFilter, onAddUser, updateUserActivePageNo, sortUsers, getUserDetail ,userDeleteDialog ,
     deleteUserrecord, isAdminUserRole } from '../actions/searchActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";


const mapStateToProps = (state, defaultProps) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        productId: (state.productId == null || typeof (state.productId) === 'undefined') ?
        defaultProps.productId : state.productId,
        profileId: (state.profileId == null || typeof (state.profileId) === 'undefined') ?
            defaultProps.profileId : state.profileId,
        text: state.profile.text,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        images: state.profile.images,
        open: state.open,
        userSearchParams: state.userSearchParams,
        userSearchResult: state.userSearchResult,
        userSortParams: state.userSortParams,
        userPagination: state.userPagination,
        userActiveIndex: state.userActiveIndex,
        userId: state.userId,
        isEditUserEnabled: state.isEditUserEnabled,
        deleteUser: state.deleteUser,
        id: state.id,
        isAdminUser: state.isAdminUser,
        userGroupId: state.loggedInUserDetail.userGroupId,
        loggedInUserDetail: state.loggedInUserDetail,
        deleteError: state.deleteError,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchUserResults,
        setUserSearchBy,
        setUserSearchText,
        onClearUserFilter,
        onAddUser,
        updateUserActivePageNo: updateUserActivePageNo,
        sortUsers,
        getUserDetail,
        userDeleteDialog: userDeleteDialog,
        deleteUserrecord: deleteUserrecord,
        isAdminUserRole,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        searchUserResults: () => {
            dispatchProps.searchUserResults(stateProps.productId, stateProps.profileId, stateProps.loggedInUserDetail.id,
                stateProps.userSearchParams.searchBy, stateProps.userSearchParams.searchText,
                stateProps.userSortParams, stateProps.userPagination.activePage);
        },
        setUserSearchBy: dispatchProps.setUserSearchBy,
        setUserSearchText: dispatchProps.setUserSearchText,
        onClearUserFilter: dispatchProps.onClearUserFilter,
        onAddUser: dispatchProps.onAddUser,
        updateUserActivePageNo: dispatchProps.updateUserActivePageNo,
        sortUsers: dispatchProps.sortUsers,
        userDeleteDialog: (open, userDetail) => dispatchProps.userDeleteDialog(open, userDetail),
        deleteUserrecord: (productId, profileId, id) => {
            dispatchProps.deleteUserrecord(productId, profileId, id);
        },
        getUserDetail: dispatchProps.getUserDetail,
        isAdminUserRole: dispatchProps.isAdminUserRole,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'User List Page', ownProps.location.pathname, stateProps.sessionTrackId, "UserListPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("User List Page", pageDetails);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FilterUser);