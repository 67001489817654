import React from 'react';
import PropTypes from 'prop-types';
import QuestionContainer from "../containers/QuestionContainer";

const QuestionGroup = ({ questions, dependant, styles, onHelpInformationDialogOpen, text, index, sectionName, postcode }) => {
    return (
        <div style={{ padding: '1rem 2rem' }} className={dependant ? 'dependant-questions' : ''}>
            {
                questions.map((question, i) => {
                    if (question.text === "Construction Style" || question.text === "Construction") {
                        return (<div><span style={{ color: 'rgb(244, 67, 54)', fontWeight: 'bold', fontSize: '14px' }}>*</span>
                            <span style={{ color: 'rgb(151, 151, 151)', fontSize: '14px' }}> Mandatory Questions</span>
                            <QuestionContainer key={i} question={question} index={index} dependant={dependant} styles={styles}
                                onHelpInformationDialogOpen={onHelpInformationDialogOpen} text={text} postcode={postcode} /></div>);
                    } else {
                        return <QuestionContainer key={i} question={question} dependant={dependant} index={index} styles={styles}
                            onHelpInformationDialogOpen={onHelpInformationDialogOpen} text={text} sectionName={sectionName} />;
                    }
                })
            }
        </div>
    );
};

QuestionGroup.propTypes = {
    questions: PropTypes.array.isRequired,
    dependant: PropTypes.bool.isRequired,
    styles: PropTypes.object,
    text: PropTypes.object,
};
export default QuestionGroup;