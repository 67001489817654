import React from 'react';
import {FlatButton} from 'material-ui';
import PropTypes from 'prop-types';
import {textOnImage} from "../style/styles";
import PropertyImage from "./PropertyImage";

const QuestionsPageBanner = ({images, styles, aerialImage, propertyImage, suggestion, text, handleClose}) => {

    const bannerStyle = {color: textOnImage.color, maxHeight: '26rem'};
    if (styles.questionPageBackgroundColour) {
        bannerStyle.backgroundColor = styles.questionPageBackgroundColour;
    } else {
        bannerStyle.background = ' url(' + (images.questionPageBackgroundImageUrl ? images.questionPageBackgroundImageUrl : '') + ') ' + styles.questionPageBackgroundFocus;
    }

    let searchBoxStyle = {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'column'
    };
    if(styles.landingPageSearchBoxBackgroundColor) {
        searchBoxStyle.backgroundColor = styles.landingPageSearchBoxBackgroundColor;
    }
    if(styles.landingPageFooterBannerTransparency) {
        searchBoxStyle.opacity = styles.landingPageFooterBannerTransparency;
    }
    if(styles.questionPageSearchBoxWidth) {
        searchBoxStyle.width = styles.questionPageSearchBoxWidth;
        searchBoxStyle.maxWidth = '100%';
    }

    let keyBoardEvent = event => {
            if(event.key === 'Enter')
                handleClose.call();
        }
        
    return (
        <div className="banner" style={bannerStyle}>
            <div style={searchBoxStyle}>
                <div className='search-box'>
                    <h2 style={{
                        color: styles.questionPageTitleFontColour,
                        fontSize: styles.questionPageTitleFontSize,
                        textTransform: styles.questionPageTitleTextCase ? styles.questionPageTitleTextCase : 'uppercase',
                        fontWeight: styles.questionPageTitleFontWeight,
                    }}>{text.questionPageTitle}</h2>
                    <div className="search-address-form">
                        <div id="search-again-address" className="address" style={{fontSize: styles.questionPageAddressFontSize, backgroundColor: styles.questionPageAddressBackgroundColor}}>{suggestion}</div>
                        <div className="search-again-button" style={{opacity: 10}}><FlatButton
                            className="input"
                            label="Search again"
                            aria-label="search-again-address"
                            onClick={handleClose}
                            onKeyPress={keyBoardEvent}
                            labelStyle={{
                                textTransform: styles.buttonTextCase ? styles.buttonTextCase : "uppercase",
                                fontSize: styles.primaryButtonFontSize,
                                fontFamily: styles.globalFontFamily,
                                color: styles.searchAgainButtonFontColour,
                            }}
                            style={{
                                padding: '0 1rem',
                                maxWidth: '100%',
                                minWidth: '150px',
                                height: 'auto',
                                opacity: 10,
                                backgroundColor: styles.searchAgainButtonColour,
                                borderRadius: styles.buttonRadius,
                                border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
                            }}
                        />
                        </div>
                    </div>
                </div>
                <PropertyImage
                    aerialImage={aerialImage}
                    propertyImage={propertyImage}
                />
            </div>

        </div>
    )
};


QuestionsPageBanner.propTypes = {
    images: PropTypes.object,
    styles: PropTypes.object,
    aerialImage: PropTypes.string,
    propertyImage: PropTypes.string,
    suggestion: PropTypes.string,
    text: PropTypes.object,
    handleClose: PropTypes.func,
};

export default QuestionsPageBanner;