import React from 'react';
import CalculateButtonContainer from '../containers/CalculateButtonContainer';
import SectionHeader from './SectionHeader';
import PropTypes from 'prop-types';
import ErrorDialog from './ErrorDialog';
import ErrorMessage from './ErrorMessage';
import {Card} from "material-ui";
import UrlConstants from '../constants/UrlConstants';

const CalculateResult = ({
                             result, styles, handleClose, openDialog, attributesCheck, showResult,
                             productId, profileId, propertyId, address, onLetUsKnowDialogOpen, scrollToFirstError, partnerId
                         }) => {
    let calculateButton;
    let errorMessage = null;
    if (result.error) {
        if (!isJson(result.error)) {
            errorMessage =
                <div className="error">
                    <ErrorMessage productId={productId} profileId={profileId} propertyId={propertyId} address={address}
                                  onLetUsKnowDialogOpen={onLetUsKnowDialogOpen} styles={styles} message={result.error}/>
                </div>
        } else {
            const gatewayErrorObj = JSON.parse(result.error);
            if (gatewayErrorObj.type === "GATEWAY_ERROR") {
                errorMessage = <div className="error">
                    <ErrorDialog handleClose={handleClose} styles={styles} open={openDialog} result={result}/>
                </div>
            }
        }
    }

    if (!result.data || result.data.variables.length === 0) {
        calculateButton =
            <CalculateButtonContainer styles={styles} attributesCheck={attributesCheck} showResult={showResult}
                                      scrollToFirstError={scrollToFirstError} partnerId={partnerId}/>;
    }

    let headerName;
    if (productId !== UrlConstants.PRODUCT_ID_7 && productId !== UrlConstants.PRODUCT_ID_8) {
        headerName = "Estimated cost to rebuild your property";
    } else {
        headerName = "Estimated cost to rebuild your home";
    }

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <div>
            <Card className="card">
            <SectionHeader name={headerName} styles={styles} color={styles.sectionTileColour}/>
            {errorMessage}
            {calculateButton}
            </Card>
        </div>
    );
};

CalculateResult.propTypes = {
    result: PropTypes.object.isRequired,
    openDialog: PropTypes.bool.isRequired,
    styles: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    attributesCheck: PropTypes.bool.isRequired,
    showResult: PropTypes.bool.isRequired,
};

export default CalculateResult;