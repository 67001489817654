import React from 'react';
import { Card, CardText } from 'material-ui/Card';
import SectionHeader from './SectionHeader';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { filter, sortBy } from 'lodash';
import FormControl from '@material-ui/core/FormControl';
import UrlConstants from '../constants/UrlConstants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
    widthChange: window.innerWidth,
    errorMsg: null,
    successMsg: null,
    errorCategoryMsg: null,
    errorCompanyNameMsg: null,
    errorDivisionNameMsg: null,
    errorTeamNameMsg: null,
    errorUsageStartDateMsg: null,
    errorUsageEndDateMsg: null,
};

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleCategoryChange = (event) => {
        const { selectReportCategoryType } = this.props;
        this.setState({
            selectedFile: null,
            errorMsg: null
        });
        selectReportCategoryType(event.target.value);
    }

    componentWillMount() {
        const { getUserGroups, profileId, productId, onLoad } = this.props;
        onLoad();
        getUserGroups(productId, profileId);
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
    }

    onCompanySelectionUsageChange = (event) => {
        const { selectCompanyUsage, selectDivisionUsage, selectTeamUsage} = this.props;
        selectCompanyUsage(event.target.value);
        this.setState(initialState);
        selectDivisionUsage(null);
        selectTeamUsage(null);
    }

    onDivisionSelectionUsageChange = (event) => {
        const { selectDivisionUsage, selectTeamUsage } = this.props;
        selectDivisionUsage(event.target.value);
        this.setState(initialState);
        selectTeamUsage(null);
    }

    onTeamSelectionUsageChange = (event) => {
        const { selectTeamUsage } = this.props;
        selectTeamUsage(event.target.value);
        this.setState(initialState);
    }

    onSelectStartDateUsageChange = (event) => {
        const { selectStartDateUsage } = this.props;
        selectStartDateUsage(event);
        this.setState(initialState);
    }

    onSelectEndDateUsageChange = (event) => {
        const { selectEndDateUsage } = this.props;
        selectEndDateUsage(event);
        this.setState(initialState);
    }

    getFilteredGroupValues() {
        const companyUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID);
        });
        const companyUserGroupsByCompanyname = sortBy(companyUserGroups, (userGroup) => {
            return userGroup.companyName.toLowerCase();
        });

        let companyid = this.props.selectedCompanyUsage != null ? this.props.selectedCompanyUsage.split('+') : '';
        const divisionUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return(userGroup.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID
                && this.props.selectedCompanyUsage != null && userGroup.parentUserGroupId === parseInt(companyid[0]));
        });
        const divisionUserGroupsBydivisionname = sortBy(divisionUserGroups, (userGroup) => {
            return userGroup.divisionName.toLowerCase();
        });
        let actualDivisionChose = divisionUserGroupsBydivisionname.filter(item => {
            return item.divisionName ===  this.props.selectedDivisionUsage;
        });
        let divisionUsage = this.props.selectedDivisionUsage != null ? actualDivisionChose[0].id  : '';
        const teamUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.TEAM_ROLE_TYPE_ID
                && divisionUsage != null && userGroup.parentUserGroupId === divisionUsage);
        });

        const teamUserGroupsByteamname = sortBy(teamUserGroups, (userGroup) => {
            return userGroup.teamName.toLowerCase();
        });
        return { companyUserGroupsByCompanyname, divisionUserGroupsBydivisionname, teamUserGroupsByteamname };
    }

    handleFileDownload = (event) => {
        const { downloadUsageReport, productId, profileId, selectedReportCategoryType, selectedCompanyUsage, selectedDivisionUsage, selectedTeamUsage, usageStartDate, usageEndDate } = this.props;
        let haserror = 0;
        if (selectedReportCategoryType === null || selectedReportCategoryType === "select") {
            this.setState({ errorCategoryMsg: 'Please Select a Report type' });
            haserror = 1;
        } else if (selectedReportCategoryType === "Company-total" || selectedReportCategoryType === "Company-breakup") {
            if (selectedCompanyUsage === null || selectedCompanyUsage === "select") {
                this.setState({ errorCompanyNameMsg: 'Please Select a Company Name' });
                haserror = 1;
            }
        } else if (selectedReportCategoryType === "Division-total" || selectedReportCategoryType === "Division-breakup") {
            if (selectedCompanyUsage === null || selectedCompanyUsage === "select") {
                this.setState({ errorCompanyNameMsg: 'Please Select a Company Name' });
                haserror = 1;
            }
            if (selectedDivisionUsage === null || selectedDivisionUsage === "select") {
                this.setState({ errorDivisionNameMsg: 'Please Select a Division Name' });
                haserror = 1;
            }
        } else if (selectedReportCategoryType === "Team-total" || selectedReportCategoryType === "Team-breakup") {
            if (selectedCompanyUsage === null || selectedCompanyUsage === "select") {
                this.setState({ errorCompanyNameMsg: 'Please Select a Company Name' });
                haserror = 1;
            }
            if (selectedDivisionUsage === null || selectedDivisionUsage === "select") {
                this.setState({ errorDivisionNameMsg: 'Please Select a Division Name' });
                haserror = 1;
            }
            if (selectedTeamUsage === null || selectedTeamUsage === "select") {
                this.setState({ errorTeamNameMsg: 'Please Select a Team Name' });
                haserror = 1;
            }
        }
        if (usageStartDate == null) {
            this.setState({ errorUsageStartDateMsg: 'Please select Start Date' });
            haserror = 1;
        }
        if (usageEndDate == null) {
            this.setState({ errorUsageEndDateMsg: 'Please select End Date' });
            haserror = 1;
        }
        if (haserror === 0) {
            downloadUsageReport(productId, profileId, selectedReportCategoryType, selectedCompanyUsage, selectedDivisionUsage, selectedTeamUsage, usageStartDate, usageEndDate);
        }
    }

    resetValues = () => {
        const { resetUsageValues } = this.props;
        this.setState(initialState);
        resetUsageValues();
    };

    render() {
        const { styles } = this.props;
        let fontFamilyName = styles.globalFontFamily;
        const {companyUserGroupsByCompanyname, divisionUserGroupsBydivisionname, teamUserGroupsByteamname} = this.getFilteredGroupValues();
        return (
            <div className='user-group-management'>
                <Card className='group-card' style={{ paddingBottom: '10px' }}>
                    <SectionHeader name="Usage Report" color={styles.sectionTileColour} styles={styles} />
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">Select Report<p className="usr-mandat">*</p></label>
                        <div className='fields  est-fields flex' style={{ padding: '0.3rem 0rem' }}>
                            <FormControl>
                                <Select id="tables-select" className="select-box est-select-box user-select-box"
                                    style={{ fontFamily: fontFamilyName }}
                                    value={this.props.selectedReportCategoryType !== null ? this.props.selectedReportCategoryType : "select"}
                                    onChange={(event, newValue) => {
                                        this.handleCategoryChange(event);
                                    }}
                                    tabIndex={1}>
                                    <MenuItem value="select">Select Report Type</MenuItem>
                                    <MenuItem value="Company-total">Company Total</MenuItem>
                                    <MenuItem value="Company-breakup">Company Breakup</MenuItem>
                                    <MenuItem value="Division-total">Division Total</MenuItem>
                                    <MenuItem value="Division-breakup">Division Breakup</MenuItem>
                                    <MenuItem value="Team-total">Team Total</MenuItem>
                                    <MenuItem value="Team-breakup">Team Breakup</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </CardText>
                    <div className="user-validation"> {this.state.errorCategoryMsg}</div>
                    {(this.props.selectedReportCategoryType !== null && this.props.selectedReportCategoryType !== "select") && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="company" className="group-detail-label">Company<p className="usr-mandat">*</p></label>
                        <FormControl>
                            <Select id="company-select" className="select-box"
                                style={{ fontFamily: fontFamilyName }}
                                value={this.props.selectedCompanyUsage !== null
                                    ? this.props.selectedCompanyUsage : "select"}
                                tabIndex={1}
                                onChange={(event) => {
                                    this.onCompanySelectionUsageChange(event);
                                }}
                            >
                                <MenuItem value="select">Select Company</MenuItem>
                                {companyUserGroupsByCompanyname.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                                    <MenuItem
                                        key={option.id}
                                        value={option.id +'+' + option.companyName}
                                        >
                                        {option.companyName}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </CardText>}
                    <div className="user-validation"> {this.state.errorCompanyNameMsg}</div>
                    {(this.props.selectedReportCategoryType !== null && this.props.selectedReportCategoryType !== "select" && this.props.selectedReportCategoryType !== "Company-total" && this.props.selectedReportCategoryType !== "Company-breakup") &&
                        <CardText className="group-detail-form" style={{ display: 'flex' }}>
                            <label id="division" className="group-detail-label">Division<p className="usr-mandat">*</p></label>
                            <FormControl>
                                <Select id="division-select" className="select-box"
                                    style={{ fontFamily: fontFamilyName }}
                                    value={this.props.selectedDivisionUsage !== null ? this.props.selectedDivisionUsage : "select"}
                                    tabIndex={1}
                                    onChange={(event) => {
                                        this.onDivisionSelectionUsageChange(event);
                                    }}
                                >
                                    <MenuItem value="select">Select Division</MenuItem>
                                    {this.props.selectedCompany !== null && divisionUserGroupsBydivisionname.map((option) =>
                                        (option.label === "[Blank]" || option.label === "[blank]") ? <MenuItem value="select">Select Division</MenuItem> :
                                        <MenuItem key={option.id}
                                            value={option.divisionName}>
                                            {option.divisionName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </CardText>}
                        <div className="user-validation"> {this.state.errorDivisionNameMsg}</div>
                    {(this.props.selectedReportCategoryType !== null && this.props.selectedReportCategoryType !== "select" && this.props.selectedReportCategoryType !== "Company-total" && this.props.selectedReportCategoryType !== "Company-breakup" && this.props.selectedReportCategoryType !== "Division-total" && this.props.selectedReportCategoryType !== "Division-breakup") && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="teamName" className="group-detail-label">Team<p className="usr-mandat">*</p></label>
                        <FormControl>
                            <Select id="team-select" className="select-box"
                                style={{ fontFamily: fontFamilyName }}
                                value={this.props.selectedTeamUsage !== null ? this.props.selectedTeamUsage : "select"}
                                tabIndex={1}
                                onChange={(event) => {
                                    this.onTeamSelectionUsageChange(event);
                                }}
                            >
                                <MenuItem value="select">Select Team</MenuItem>
                                {this.props.selectedDivisionUsage != null && teamUserGroupsByteamname.map((option) => <MenuItem key={option.id}
                                    value={option.teamName}>
                                    {option.teamName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </CardText>}
                    <div className="user-validation"> {this.state.errorTeamNameMsg}</div>
                    {/* <!--<div className='fields est-fields' style={{ height: '100%', paddingRight: '0px', marginRight: '0rem', display: 'inherit' }}> */}
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        {/* <div className='est-date'><FormControl> */}
                        <label id="Date Range" className="group-detail-label">Date Range<p className="usr-mandat">*</p></label>
                        {/* </FormControl>  </div> */}
                        <div className="cce-search-datePicker cce-usage-datepicker" style={{ fontFamily: fontFamilyName, paddingRight: '13px' }} >
                            <div class="ui fluid ">
                                <div class="ui fluid icon input">
                                    <DatePicker className="date"
                                        style={{ fontFamily: fontFamilyName }}
                                        placeholderText="From Date"
                                        selected={this.props.usageStartDate  !== null ? new Date(this.props.usageStartDate) : null}
                                        onChange={(event) => {
                                            this.onSelectStartDateUsageChange(event);
                                        }}
                                        showMonthDropdown
                                        showYearDropdown
                                        useShortMonthInDropdown
                                        yearDropdownItemNumber={10}
                                        icon={{ className: "search" }}
                                        scrollableYearDropdown
                                    />
                                    <i id="from-date-icon" aria-hidden="true" class="icon calendar alternate outline"></i>
                                </div>
                            </div>
                        </div>
                        <div className="user-validation"> {this.state.errorUsageStartDateMsg}</div>
                        <div className="cce-search-datePicker cce-usage-datepicker" style={{ fontFamily: fontFamilyName }} >
                            <div class="ui fluid icon input">
                                <DatePicker className="date date-picker"
                                    style={{ fontFamily: fontFamilyName }}
                                    placeholderText="To Date"
                                    selected={this.props.usageEndDate !== null ? new Date(this.props.usageEndDate) : null}
                                    onChange={(event) => {
                                        this.onSelectEndDateUsageChange(event);
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    useShortMonthInDropdown
                                    yearDropdownItemNumber={10}
                                    scrollableYearDropdown />
                                <i id="to-date-icon" aria-hidden="true" class="icon calendar alternate outline"></i>
                            </div>
                        </div>
                    <div className="user-validation"> {this.state.errorUsageEndDateMsg}</div>
                    </CardText>
                    <div style={{ paddingRight: '1px', paddingLeft: '16px' }}>
                        <Button id="create" className="action usage-action" variant="outlined"
                            rounded="true"
                            size="large"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={(event) => {
                                this.handleFileDownload(event);
                            }}
                            style={{ width: 'auto' }}
                        >{"Download Report"}
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button id="create" className="action" variant="outlined"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={() => {
                                this.resetValues();
                            }}
                            style={{ width: '100%' }}
                        >{"Reset"}
                        </Button></div>
                </Card>
            </div>)
    }
}
export default Reports;