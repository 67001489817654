import apiBaseUrl from '../apiBaseUrl';

const downloadReportApi = ({productId, profileId, propertyId, body, currentDataPartition, brokerId}) => {
    const url = brokerId === null || brokerId === undefined ? `${apiBaseUrl()}products/${productId}/profiles/${profileId}/download` :
        `${apiBaseUrl()}products/${productId}/profiles/${profileId}/download?brokerId=${brokerId}`;

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
    };
    if (propertyId) {
        headers['propertyId'] = propertyId;
    }
    if (currentDataPartition) {
        headers.currentDataPartition = currentDataPartition;
    }
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        }
    );
};

export default downloadReportApi;