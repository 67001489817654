import apiBaseUrl from '../apiBaseUrl';

const importEstimateApi = (productId, profileId, file, emailAddress, loggedInUserId) => {

    var formData = new FormData();
    formData.append("file", file);
    formData.append("loggedInUserEmail", emailAddress);
    formData.append("loggedInUserId", loggedInUserId);
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/importEstimates`;
    return fetch(url,
        {
            method: 'POST',
            body: formData,
        }
    );
};

export default importEstimateApi;