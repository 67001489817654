import apiBaseUrl from '../apiBaseUrl';

const exportEstimateDetailsApi = ({productId, profileId, risks, userId}, emailId, selectedAllRisk,
     searchParams, sortParams, totalRecords, excludedRisks) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/export`; 
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }; 
    return fetch(url,
        {
            method: 'POST',
            body: JSON.stringify({ userId: userId, emailId: emailId, risks: risks, selectedAllRisk: selectedAllRisk, searchBy: searchParams.searchBy,
                searchText: searchParams.searchText, fromDate: searchParams.fromDate, toDate: searchParams.toDate, searchDateOption: searchParams.searchDateOption,
                sortBy: sortParams.dateChange === null ? "descending" : sortParams.dateChange,
                sortDirection: sortParams.sortOrder === null ? "lastUpdatedDate" : sortParams.sortOrder, pageNo: 0, pageSize: totalRecords,
                excludedRisks: excludedRisks }),
      
            headers,
        }
    );
};

export default exportEstimateDetailsApi;