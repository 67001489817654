import apiBaseUrl from '../apiBaseUrl';

const deleteEstimateApi = (productId, profileId, id, estimatePrice) => {

    let estimateId = parseInt(id);
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/deleteEstimateById/${estimateId}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    headers.isIncompleteEstimate = estimatePrice === "In Progress" ? true : false;
    return fetch(url,
        {
            method: 'DELETE',
            headers,
        }
    );
};

export default deleteEstimateApi;