import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ExistingUserDialog = ({ styles, existingUser, existingUserDialog, cancelExistingUser, isUserNameSearch }) => {

    let message = isUserNameSearch ? "User Name entered is an existing CoreLogic Single-Sign-on User" : "Email entered is an existing CoreLogic Single-Sign-on User's Email";
    return (
        <Dialog
            className="dialog terms-dialog"
            open={existingUser}
            maxWidth={'sm'}
            onClose={() => cancelExistingUser()}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="existing-user-row">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {message}<br></br><br></br>
                                    <span style={{ color: 'black' }}>Do you want to give the User access to Cordell Commercial Estimator application?</span><br></br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Yes" onPress={() => existingUserDialog()}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => cancelExistingUser()}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>)
};

ExistingUserDialog.propTypes = {
    existingUser: PropTypes.object,
    styles: PropTypes.object.isRequired,
    existingUserDialog: PropTypes.func.isRequired,
    cancelExistingUser: PropTypes.func.isRequired,
};

export default ExistingUserDialog;