import React from 'react';
import PropTypes from 'prop-types';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';

const ConfirmationMessage = ({styles, showResultSection}) => {
    return (
            <div style={{padding: '1em 2em', fontWeight: 'bold'}}>
                <p style={{fontFamily: styles.globalFontFamily, fontSize: styles.questionHelpFontSize}}>Have you checked all pre-populated information, updated or corrected any information as
                    required and is all information in respect of the property complete and accurate?</p>
                <CustomFlatButtonContainer label="Yes" type="primary" keyboardFocus={false}
                                           style={{
                                               lineHeight: '0px',
                                               padding: '0px 0px',
                                               minHeight: '36px',
                                               fontFamily: styles.globalFontFamily,
                                               borderRadius: styles.buttonRadius || "",
                                               border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
                                           }}
                                           onPress={showResultSection}
                />
            </div>
    );
};

ConfirmationMessage.propTypes = {
    styles: PropTypes.object,
    showResultSection: PropTypes.func.isRequired,
};
export default ConfirmationMessage;
