import { connect } from 'react-redux';
import ImagePanel from '../components/ImagePanel';
import {bindActionCreators} from 'redux';
import {
    fetchNoImageFileUrl,
} from '../actions/actionCreator';


const mapStateToProps = (state) => {
    return {
        images: state.profile.images,
        styles: state.profile.styles,
        cceImages: state.cceImages,
        noImageFile: state.noImageFile,
        profileId: state.profileId,
        productId: state.productId,
    };
};

const mapDispatchToProps = (dispatch) => {
     return bindActionCreators({ fetchNoImageFileUrl, }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        getNoImageFile: dispatchProps.fetchNoImageFileUrl,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImagePanel);