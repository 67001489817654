import React from 'react';
import PropTypes from 'prop-types';
import SpecialQuestion from "../components/SpecialQuestion";

const SpecialQuestionNumberGroup = ({questions, styles}) => {

    return (
        <div className="group">
        {
            questions.map((question, index) => {
                return  <SpecialQuestion key={index} id={index} question={question} styles={styles} />;
            })
        }
        </div>
    );
};

SpecialQuestionNumberGroup.propTypes = {
    questions: PropTypes.array.isRequired,
    styles: PropTypes.object,
};
export default SpecialQuestionNumberGroup;