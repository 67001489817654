import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';
import HomePage from '../components/HomePage';
import {
    addressChosen,
    fetchProfile,
    fetchSuggestions,
    setDebug,
    setShowNote,
    validateProfileAccessControls
} from '../actions/actionCreator';
import {updateReturnData} from '../actions/calculationActionCreator';
import {initializeAnalytics, sessionTracker, trackSegmentPageView} from '../actions/segmentActionsCreator';
import AnalyticsPage from '../util/AnalyticsPage';

const mapStateToProps = (state, defaultProps) => {
    const queryParams = queryString.parse(defaultProps.location.search);
    return {
        profileId: defaultProps.match.params.profileId,
        productId: defaultProps.match.params.productId,
        debug: queryParams.debug === 'true',
        propertyId: queryParams.propertyId,
        token: queryParams.token,
        returnUrl: queryParams.returnUrl,
        redirectUrl: queryParams.redirectUrl,
        quoteId: queryParams.quoteId ? queryParams.quoteId : null,
        partner: queryParams.partner,
        returnUrls: state.profile.returnUrls,
        suggestions: state.suggestions,
        config: state.config,
        disclaimerText: state.profile.text.disclaimer,
        images: state.profile.images,
        styles: state.profile.styles,
        enableReturnMode: state.profile.display.returnToQuote,
        text: state.profile.text,
        mode: state.profile.profileAccessControl.accessMode,
        key: `${defaultProps.match.params.profileId}-${defaultProps.match.params.productId}`,
        segmentTrackingCode: state.profile.segmentTrackingCode,
        acceptPropertyId: state.profile.display.acceptPropertyId,
        requestUrl: window.location.href,
        bypassTc: state.profile.bypassTc,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        showNote: queryParams.showNote === undefined ? null : queryParams.showNote,
        result: state.result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addressChosen,
        fetchProfile,
        setDebug,
        setShowNote,
        fetchSuggestions,
        trackSegmentPageView,
        sessionTracker,
        validateProfileAccessControls,
        initializeAnalytics,
        updateReturnData,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onAddressPicked: dispatchProps.addressChosen,
        fetchAddressApi: dispatchProps.fetchAddressApi,
        validateProfile: () => {
            dispatchProps.fetchProfile(stateProps.productId, stateProps.profileId, stateProps.token, stateProps.partner);
        },
        setDebug: () => {
            dispatchProps.setDebug(stateProps.debug);
        },
        setShowNote: () => {
            dispatchProps.setShowNote(stateProps.showNote);
        },
        setValidateProfile: () => {
            dispatchProps.validateProfileAccessControls(stateProps.mode);
        },
        onSearchTermChanged: (term) => {
            dispatchProps.fetchSuggestions(stateProps.productId, term);
        },
        onLoad: (segmentTrackingCode, isLoaded) => {
            if(!isLoaded) {
                dispatchProps.initializeAnalytics(segmentTrackingCode);
            }
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Sum Sure', ownProps.location.pathname, stateProps.sessionTrackId, "HomePage", stateProps.partner);
            dispatchProps.trackSegmentPageView('Home Page', pageDetails);
        },
        startTrackSession: () => {
            dispatchProps.sessionTracker((new Date()).getTime());
        },
        updateReturnData: (returnUrl, quoteId) => {
            dispatchProps.updateReturnData(returnUrl, quoteId);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HomePage);