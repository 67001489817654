import React from 'react';
import UserFirstLastNameContainer from '../containers/UserFirstLastNameContainer';
import EmailContainer from '../containers/EmailContainer';
import {Card} from 'material-ui';
import PropTypes from 'prop-types';
import SectionHeader from './SectionHeader';
import DownloadPdfReportContainer from '../containers/DownloadPdfReportContainer';
import ReturnUrlButtonContainer from '../containers/ReturnUrlButtonContainer';
import ContactUsButtonContainer from '../containers/ContactUsButtonContainer';

const WhatNext = ({display, downloadPdfReportText, text, styles, returnToQuote, address, profileId, result, callToActionClicked, contactUsUrl, showNote}) => {
    const {userName: userNameDisplay, email: emailDisplay, downloadReport, displayContactUs} = display;
    const {whatNextSectionTitle: whatNextSection, callToAction: callToActionText, whatNextSectionText} = text;
    let rebuildCost = result ? result.variables[result.variables.length - 1].price : null;
    let returnUrlData = returnToQuote != null ? returnToQuote.returnUrl : null;
    let displayReturnToQuote = returnUrlData != null ? true : false;
    return (
        <Card className="card" containerStyle={{fontFamily: styles.globalFontFamily}}>
            <SectionHeader name={whatNextSection} color={styles.sectionTileColour} styles={styles}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1em 2em',
            }}>
                {whatNextSectionText &&
                <div className="what-next-section-text" style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    fontFamily: styles.globalFontFamily,
                }} dangerouslySetInnerHTML={{__html: whatNextSectionText}}/>
                }
                {displayReturnToQuote && <ReturnUrlButtonContainer rebuildCost={rebuildCost}  address={address}/>}
                {userNameDisplay && <UserFirstLastNameContainer/>}
                {emailDisplay && <EmailContainer/>}
                {
                    downloadReport && downloadPdfReportText
                    ? <div className="download-report" style={{
                            fontFamily: styles.globalFontFamily, fontSize: '1rem', padding: '0.6rem 0rem', fontWeight: 'bold' }} dangerouslySetInnerHTML={{__html: downloadPdfReportText}}/>
                    : <div style={{height: '1rem'}} />
                }
                {downloadReport && <DownloadPdfReportContainer/>}
                {returnToQuote && <div className="download-report"/>}
                {displayContactUs && contactUsUrl !== null && contactUsUrl !== '' && <div style={{height: '1rem'}}/>}
                {displayContactUs && contactUsUrl !== null && contactUsUrl !== '' &&  <ContactUsButtonContainer contactUsUrl={contactUsUrl}/>}
                {
                    (callToActionText !== null && callToActionText !== '' && (showNote !== 'no'))
                    && <div onClick={(e)=>callToActionClicked(e)} className="call-to-action"
                        style={{
                            padding: '1em',
                            margin: '1em 0',
                            backgroundColor: styles.callToActionBackgroundColour,
                            fontSize: styles.callToActionFontSize,
                            color: styles.callToActionFontColour,
                        }} dangerouslySetInnerHTML={{__html: callToActionText}}/>
                }

            </div>

        </Card>
    );
};

WhatNext.propTypes = {
    display: PropTypes.object.isRequired,
    downloadPdfReportText: PropTypes.string,
    text: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    callToActionClicked: PropTypes.func.isRequired,
    contactUsUrl: PropTypes.string,
};

export default WhatNext;