import apiBaseUrl from '../apiBaseUrl';

const updateDescriptionApi = (productId, profileId, descriptions) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/updateImages`;
    const headers = {
        'Accept': 'application/json, text/plain, *',
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'PUT',
            headers,
            body: JSON.stringify({descriptions}),
        }
    );
};

export default updateDescriptionApi;