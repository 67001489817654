import apiBaseUrl from '../apiBaseUrl';

const getRoleTypeApi = (productId, profileId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getRoleType`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getRoleTypeApi;