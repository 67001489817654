import React from 'react';
import {Dialog, GridList, GridTile} from 'material-ui';
import PropTypes from 'prop-types';
import CustomFlatButtonContainer from "../containers/CustomFlatButtonContainer";

const ErrorDialog = ({handleClose, styles, open}) => {
    return (
        <Dialog
            title="We encountered a connection error, please try again"
            className="dialog error-dialog"
            contentClassName="dialog-content"
            bodyClassName="dialog-body"
            modal={true}
            open={open}>
            <GridList cols={10} cellHeight={'auto'}
                      style={{
                          paddingTop: '1em',
                          paddingBottom: '1em',
                      }}>
                <GridTile cols={10}>
                    <CustomFlatButtonContainer
                        className="small secondary search-again"
                        style={{fontFamily: styles.globalFontFamily}}
                        label="SEARCH AGAIN"
                        onPress={handleClose}
                        type="primary"
                    />
                </GridTile>
            </GridList>
        </Dialog>
    );
};

ErrorDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ErrorDialog;
