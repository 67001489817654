class AnalyticsAttributes {
    constructor(ProductId, ProfileId, PropertyId, SessionId, Status, Description, PartnerId) {
        this.ProductId = ProductId;
        this.ProfileId = ProfileId;
        this.PropertyId = PropertyId;
        this.SessionId = SessionId;
        this.Status = Status;
        this.Description = Description;
        this.PartnerId = PartnerId;
    }
}

export default AnalyticsAttributes;