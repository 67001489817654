import React from 'react';
import { findIndex } from 'lodash';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import CCEDisclaimerSection from './CCEDisclaimerSection';
import ConfirmationMessage from './ConfirmationMessage';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import SectionHeader from "./SectionHeader";
import { Card } from "material-ui";
import CCEResultContainer from '../containers/CCEResultContainer';
import SpinnerContainer from '../containers/SpinnerContainer';
import ErrorMessage from './ErrorMessage';
import UrlConstants from '../constants/UrlConstants';
import { Button } from '@material-ui/core';
import history from "../util/history";


class CCERebuildCostPage extends React.Component {

    componentWillMount() {
        const { onLoad, calculate, result, debug, isEmailDownload,updateCurrentTab, updateRiskDetail,currentTab,isEditEstimate, questions, postcode } = this.props;
        onLoad();
        if (!debug && !isEmailDownload && (result.data === null || result.data.estimateId === null)) {
            const index = findIndex(questions.data, (question) => {
                return question.id === 302860;
            });
            if (index >= 0) {
                questions.data[index] = { ...questions.data[index], answer: postcode, visible: false, isInvalid: false, answerSource: "grid", helpEnabled: true };
            }
            calculate();
        }
        if(!isEditEstimate && !isEmailDownload && currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail();
        }
        updateCurrentTab(UrlConstants.REBUILD_COST);
    }

    handleClose = () => {
        //nothing
    };

    scrollToFirstError = () => {
        const { errors } = this.props;
        if (errors && errors.length > 0) {
            let errorIds = errors.map(error => error.questionId);
            let elements = document.getElementsByClassName("questionLayout");
            let firstElement = [...elements].filter(element => errorIds.includes(parseInt(element.id.split("-")[1], 10)))[0];
            if (firstElement) {
                firstElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        }
    };

    render() {
        const { styles, text, handleChange, isEditEstimate ,isEmailDownload, companyDisclaimer, divisionDisclaimer } = this.props;
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.IMAGE_MANAGEMENT);
        }
        const handleNext = () => {
            history.push(this.props.homePath);
        }
        let cceResult;
        let disclaimerSection;
        let disclaimerSectionTop;
        if (this.props.showDisclaimerAtBottom === false) {
            disclaimerSection = "";
            disclaimerSectionTop =
                <CCEDisclaimerSection state={this.props.addressChosen.state}
                    contentBefore={text.disclaimerCalculator1}
                    contentAfter={text.disclaimerCalculator2} styles={styles}
                    productId={this.props.productId}
                    companyDisclaimer={companyDisclaimer}
                    divisionDisclaimer={divisionDisclaimer}
                    showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
        }
        if (this.props.debug) {
            cceResult = <CCEResultContainer
                productId={this.props.productId}
                profileId={this.props.profileId}
                propertyId={this.props.addressChosen.propertyId}
                addressChosen={this.props.addressChosen.suggestion}
                onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
            />;

        } else if (this.props.result.data && this.props.result.data.variables.length > 0) {
            if (this.props.attributesCheck && !this.props.showResult) {
                cceResult =
                    <Card className="card">
                        <SectionHeader name={this.props.rebuildCostSectionHeader}
                            color={this.props.styles.sectionTileColour} styles={this.props.styles} />
                        <ConfirmationMessage styles={this.props.styles}
                            showResultSection={() => this.props.showResultAfterConfirmation(true)} /></Card>;

            } else {
                if (this.props.showDisclaimerAtBottom === true) {
                    disclaimerSectionTop = "";
                    disclaimerSection =
                        <CCEDisclaimerSection state={this.props.addressChosen.state}
                            contentBefore={text.disclaimerCalculator1}
                            contentAfter={text.disclaimerCalculator2} styles={styles}
                            productId={this.props.productId}
                            companyDisclaimer={companyDisclaimer}
                            divisionDisclaimer={divisionDisclaimer}
                            showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
                }
                cceResult = <CCEResultContainer
                    productId={this.props.productId}
                    profileId={this.props.profileId}
                    propertyId={this.props.addressChosen.propertyId}
                    addressChosen={this.props.addressChosen.suggestion}
                    onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
                />;
            }
        } else {
            if (this.props.result.error) {
                cceResult = <Card className="card">
                    <SectionHeader name={this.props.rebuildCostSectionHeader}
                        color={this.props.styles.sectionTileColour} styles={this.props.styles} />
                    <div className="error">
                        <ErrorMessage productId={this.props.productId} profileId={this.props.profileId} propertyId={this.props.addressChosen.propertyId}
                            address={this.props.address} onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog} styles={this.props.styles} message={this.props.result.error} />
                    </div>
                </Card>;
            }

        }

        return (
            <div className="questions-wrapper rebuild-cost">
                <SpinnerContainer />
                {disclaimerSectionTop}
                <div>
                    {cceResult}
                    {disclaimerSection}
                </div>
                <div className="action-nav">
                    <div className="nav-previous">
                        <Button className="action_previous" variant="outlined" id="tab-2"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={this.isDisabledStatus(isEditEstimate, isEmailDownload)}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handlePrevious}
                        > &larr; Image Management
                        </Button>
                    </div>
                    <div className="nav-next">
                        <Button className="action_next" variant="outlined" id="tab-5"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={this.isDisabledStatus(isEditEstimate, isEmailDownload)}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handleNext}
                        > Go Back To Dashboard Menu &rarr;
                        </Button>
                    </div>
                </div>
                <HelpInformationDialogContainer />
                <LetUsKnowDialogContainer />
            </div>
        );
    }

    isDisabledStatus(isEditEstimate, isEmailDownload) {
        return isEditEstimate || isEmailDownload ? true : false;
    }
}

CCERebuildCostPage.propTypes = {
    displayRefreshWarning: PropTypes.bool.isRequired,
    addressChosen: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    styles: PropTypes.object,
    text: PropTypes.object,
    attributesCheck: PropTypes.bool.isRequired,
    showResultAfterConfirmation: PropTypes.func.isRequired,
    showDisclaimerAtBottom: PropTypes.bool.isRequired,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,
    onLoad: PropTypes.func.isRequired,
    calculate: PropTypes.func.isRequired,
};

export default CCERebuildCostPage;