import Unavailable from '../components/Unavailable';
import {connect} from 'react-redux'; 

export const mapStateToProps = (state) => {
    return { 
        productId: state.productId, 
   };
};

export const mapDispatchToProps = (dispatch) => {
    return { 
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unavailable);
