import apiBaseUrl from '../apiBaseUrl';

const calculateApi = ({productId, profileId, propertyId, address, answers, currentDataPartition, estimateId, riskId, buildingList}, partnerId,
    estimatePrice, isEditEstimate, userId, fullName, emailAddress) => {


    let newEstimateId = estimateId === null || estimateId === undefined || (isEditEstimate && estimatePrice !== "In Progress") ? 0 : estimateId;
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/calculate`;
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'address': address,
    };
    if (propertyId) {
        headers['propertyId'] = propertyId;
    }
    if (currentDataPartition) {
        headers.currentDataPartition = currentDataPartition;
    }

    headers.contentInsurance = false;
    partnerId = partnerId === null || partnerId === undefined ? 0 : partnerId;
    headers.isIncompleteEstimate = estimatePrice === "In Progress" ? true : false;
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ answers: answers, buildingList: buildingList, estimateId: newEstimateId,
                riskId: riskId === null || riskId === undefined ? 0 : riskId, partnerId: partnerId, userId: userId, 
                fullName: fullName, emailAddress: emailAddress}),
        }
    );
};

export default calculateApi;