import AnalyticsAttributes from './AnalyticsAttributes';

class AnalyticsEstimate extends AnalyticsAttributes {
    constructor(ProductId, ProfileId, PropertyId, SessionId, Status, Description, {Estimate, TimeTakenToCalculate, CalculateButtonClicked, PartnerId}) {
        super(ProductId, ProfileId, PropertyId, SessionId, Status, Description, PartnerId);
        this.Estimate = Estimate;
        this.TimeTakenToEstimate = TimeTakenToCalculate;
        this.CalculateButtonClicked= CalculateButtonClicked;
    }
}

export default AnalyticsEstimate;