import React from 'react';
import UserFirstLastNameContainer from '../containers/UserFirstLastNameContainer';
import CCEEmailContainer from '../containers/CCEEmailContainer';
import {Card} from 'material-ui';
import PropTypes from 'prop-types';
import SectionHeader from './SectionHeader';

const CCEWhatNext = ({display, downloadPdfReportText, text, styles, returnToQuote, address, profileId, result, showNote}) => {
    const {userName: userNameDisplay} = display;
    const {whatNextSectionTitle: whatNextSection, whatNextSectionText} = text;

    return (

        <Card className="card" containerStyle={{fontFamily: styles.globalFontFamily}}>
            <SectionHeader name={whatNextSection} color={styles.sectionTileColour} styles={styles}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1em 2em',
            }}>
                {
                <div className="what-next-section-text" style={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    fontFamily: styles.globalFontFamily,
                }} dangerouslySetInnerHTML={{__html: whatNextSectionText}}/>
                }
                {userNameDisplay && <UserFirstLastNameContainer/>}
                {<CCEEmailContainer/>}
            </div>

        </Card>
    );
};

CCEWhatNext.propTypes = {
    display: PropTypes.object.isRequired,
    downloadPdfReportText: PropTypes.string,
    text: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
};

export default CCEWhatNext;