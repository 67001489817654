import apiBaseUrl from '../apiBaseUrl';

const filterUserGroupsApi = (productId, profileId, userGroupId, searchBy, searchText, sortParams, pageNumber) => {
    let pageNo = parseInt(pageNumber) - 1;
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getUserGroupsByFilter/${userGroupId}?pageNo=${pageNo}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                searchBy: searchBy,
                searchText: searchText,
                sortBy: sortParams.sortBy === null ? "roleTypeId" : sortParams.sortBy,
                sortDirection: sortParams.sortOrder === null ? "descending" : sortParams.sortOrder,
            }),
        }
    );
};

export default filterUserGroupsApi;