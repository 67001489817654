import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ExportDialog = ({ styles, isExport, exportDialog  }) => {

    return (
        <Dialog
            className="dialog terms-dialog"
            open={isExport}
            maxWidth={'sm'}
            onClose={() => exportDialog()}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="reprice-export-dialog">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    The "Export" process has commenced for the selected estimates. You will receive notification once the
                                    action has completed. <br></br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="OK" onPress={() => exportDialog()}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>)
};

ExportDialog.propTypes = {
    styles: PropTypes.object.isRequired,
    handleReturnToEstimate: PropTypes.func.isRequired,
};

export default ExportDialog;