import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setHelpInformationOpen} from '../actions/questionsActionCreator';
import HelpInformationDialog from "../components/HelpInformationDialog";

export const mapStateToProps = (state) => {
    return {
        open: state.helper.helpInformationOpen,
        question: state.helper.question,
        styles: state.profile.styles,
    }
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onHelpInformationDialogClose: () => dispatch(setHelpInformationOpen(false, {})),
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps) => {
    return {
        ...stateProps,
        onHelpInformationDialogClose: () => dispatchProps.onHelpInformationDialogClose(),
    }
};

const HelpInformationDialogContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(HelpInformationDialog);

export default HelpInformationDialogContainer;