import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { inputStyle } from '../style/styles';
import SectionHeader from './SectionHeader';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'; 
import { filter, sortBy } from 'lodash';
import SpinnerContainer from '../containers/SpinnerContainer';
import UrlConstants from '../constants/UrlConstants';

class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            errorUserNameMsg: null,
            errorFullNameMsg: null,
            errorEmailIdMsg: null,
            errorRoleMsg: null,
            errorCompanyMsg: null,
            errorDivisionMsg: null,
            errorTeamMsg: null,
        };
    }

    componentWillMount() {
        const { onLoad } = this.props;
        onLoad();
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
        const { getUserGroups, profileId, productId } = this.props;
        getUserGroups(productId, profileId);
    }

    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    onChangeEditUserName = (event) => {
        const { onChangeEditUserName } = this.props;
        onChangeEditUserName(event.target.value);
    }

    onChangeEditUserStatus = (event) => {
        const { onChangeEditUserStatus } = this.props;
        onChangeEditUserStatus(event.target.value);
    }

    onChangeEditFullName = (event) => {
        const { onChangeEditFullName } = this.props;
        onChangeEditFullName(event.target.value);
    }
    onChangeEditEmailId = (event) => {
        const { onChangeEditEmailId } = this.props;
        onChangeEditEmailId(event.target.value);
    }
    handleUserGroupInEditUser = (event) => {
        const { onChangeEditUserRoleType } = this.props;
        this.setState({ errorCompanyMsg: null });
        this.setState({ errorDivisionMsg: null });
        this.setState({ errorTeamMsg: null }); 
        onChangeEditUserRoleType(event.target.value);
    }
    onCompanySelectionChange = (event) => {
        const { onChangeEditCompanySelection } = this.props;
        onChangeEditCompanySelection(event.target.value);
    }

    onDivisionSelectionChange = (event) => {
        const { onChangeEditDivisionSelection } = this.props;
        onChangeEditDivisionSelection(event.target.value);
    }
    onTeamSelectionChange = (event) => {
        const { onChangeEditTeamSelection } = this.props;
        onChangeEditTeamSelection(event.target.value);
    }
    onBackToUserList = () => {
        const { onBackToUserList } = this.props;
        onBackToUserList();
    }
    onUpdateUserRecord = () => {
        const { editCompany, editDivision, editTeam, editUserDetail, productId, profileId, onUpdateUser } = this.props;
        let hasError = 0;
        hasError = this.newMethod1(editUserDetail, hasError);
        hasError = this.newMethod2(editUserDetail, hasError);
        if (editUserDetail != null && editUserDetail.emailId === "") {
            this.setState({ errorEmailIdMsg: "Please enter User Email." });
            hasError = 1;
        } else {
            this.setState({ errorEmailIdMsg: null });

            hasError = this.newMethod5(editUserDetail, hasError);
        }
        hasError = this.newMethod6(editUserDetail, hasError);
        hasError = this.newMethod7(editUserDetail, editCompany, hasError);
        hasError = this.newMethod8(editUserDetail, editDivision, hasError);
        hasError = this.newMethod9(editUserDetail, editTeam, hasError);
        if (hasError === 0 && editUserDetail != null) {
            this.newMethod10(editUserDetail, onUpdateUser, productId, profileId);
        }
    }
    newMethod10(editUserDetail, onUpdateUser, productId, profileId) {
        if (editUserDetail.roleTypeId === 2 || editUserDetail.roleTypeId === "2") {
            editUserDetail.userGroupId = this.props.editCompany;
        } else if (editUserDetail.roleTypeId === 3 || editUserDetail.roleTypeId === "3") {
            editUserDetail.userGroupId = this.props.editDivision;
        } else if (editUserDetail.roleTypeId === 4 || editUserDetail.roleTypeId === "4" ||
            editUserDetail.roleTypeId === 5 || editUserDetail.roleTypeId === "5") {
            editUserDetail.userGroupId = this.props.editTeam;
        }
        onUpdateUser(productId, profileId, editUserDetail);
    }

    newMethod9(editUserDetail, editTeam, hasError) {
        if (editUserDetail != null && editUserDetail.roleTypeId > 3 &&
            editTeam === 0) {
            this.setState({ errorTeamMsg: "Please select a Team." });
            hasError = 1;
        } else {
            this.setState({ errorTeamMsg: null });
        }
        return hasError;
    }

    newMethod8(editUserDetail, editDivision, hasError) {
        if (editUserDetail != null && editUserDetail.roleTypeId > 2 &&
            editDivision === 0) {
            this.setState({ errorDivisionMsg: "Please select a Division." });
            hasError = 1;
        } else {
            this.setState({ errorDivisionMsg: null });
        }
        return hasError;
    }

    newMethod7(editUserDetail, editCompany, hasError) {
        if (editUserDetail != null && editUserDetail.roleTypeId > 1 &&
            editCompany === 0) {
            this.setState({ errorCompanyMsg: "Please select a Company." });
            hasError = 1;
        } else {
            this.setState({ errorCompanyMsg: null });
        }
        return hasError;
    }

    newMethod6(editUserDetail, hasError) {
        if (editUserDetail != null && editUserDetail.roleTypeId <= 0) {
            this.setState({ errorRoleMsg: "Please select an User level." });
            hasError = 1;
        } else {
            this.setState({ errorRoleMsg: null });
        }
        return hasError;
    }

    newMethod5(editUserDetail, hasError) {
        if (editUserDetail != null && editUserDetail.emailId !== null
            && editUserDetail.emailId.trim() !== "") {
            hasError = this.newMethod12(editUserDetail, hasError);
        } else {
            this.setState({ errorEmailIdMsg: "Please enter User Email." });
            hasError = 1;
        }
        return hasError;
    }

    newMethod12(editUserDetail, hasError) {
        if (editUserDetail.emailId.length <= 2 || editUserDetail.emailId.indexOf("@") === -1 ||
            editUserDetail.emailId.indexOf(".") === -1 || editUserDetail.emailId.slice(-1) === "."
            || editUserDetail.emailId.indexOf(".@") !== -1 || editUserDetail.emailId.indexOf("@.") !== -1
            || editUserDetail.emailId.indexOf("..") !== -1 || editUserDetail.emailId.indexOf("._.") !== -1
            || editUserDetail.emailId.charAt(0) === "." || editUserDetail.emailId.charAt(0) === "_"
            || editUserDetail.emailId.charAt(0) === "-" || editUserDetail.emailId.trim().indexOf(' ') !== -1) {
            this.setState({ errorEmailIdMsg: "Please enter a Valid Email." });
            hasError = 1;
        } else {
            let index;
            ({ index, hasError } = this.newMethod3(index, editUserDetail, hasError));
            let parts = editUserDetail.emailId.split("@");
            hasError = this.newMethod4(parts, hasError);
        }
        return hasError;
    }

    newMethod4(parts, hasError) {
        if (parts !== null) {
            if (parts.length === 2) {
                let dot = parts[1].indexOf(".");
                let dotSplits = parts[1].split(".");
                let dotCount = dotSplits.length - 1;
                if (dot === -1 || dot < 2 || dotCount > 3) {
                    this.setState({ errorEmailIdMsg: "Please enter a Valid Email." });
                    hasError = 1;
                }
            } else {
                this.setState({ errorEmailIdMsg: "Please enter a Valid Email." });
                hasError = 1;
            }
        }
        return hasError;
    }

    newMethod3(index1, editUserDetail, hasError1) {
        let hasError = hasError1;
        let index = index1;
        for (index = 0; index < editUserDetail.emailId.length; index++) {
            if ((editUserDetail.emailId[index].toLowerCase() ===
                editUserDetail.emailId[index].toUpperCase()) &&
                isNaN(editUserDetail.emailId[index])
                && editUserDetail.emailId[index] !== '_' && editUserDetail.emailId[index] !== '.'
                && editUserDetail.emailId[index] !== '@' && editUserDetail.emailId[index] !== '-') {
                this.setState({ errorEmailIdMsg: "Please enter a Valid Email." });
                hasError = 1;
            }
        }
        return { index, hasError };
    }

    newMethod2(editUserDetail, hasError) {
        if (editUserDetail != null && editUserDetail.fullName === "") {
            this.setState({ errorFullNameMsg: "Please enter First Name and Last Name." });
            hasError = 1;
        } else {
            this.setState({ errorFullNameMsg: null });
        }
        return hasError;
    }

    newMethod1(editUserDetail, hasError) {
        if (editUserDetail != null && editUserDetail.userName === "") {
            this.setState({ errorUserNameMsg: "Please enter an User Name." });
            hasError = 1;

        } else {
            this.setState({ errorUserNameMsg: null });
        }
        return hasError;
    }
  

    render() {
        const { styles, editUserDetail, loggedInUserDetail } = this.props;
        const systemUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.SYSTEM_ROLE_TYPE_ID);
        });
        const companyUserGroupsFilter = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID);
        });
        const companyUserGroups = sortBy(companyUserGroupsFilter, (userGroup) => {
            return userGroup.companyName.toLowerCase();
        });
        const divisionUserGroupsFilter = filter(this.props.userGroups.data, (userGroup) => {
            return this.newMethod13(userGroup);
        });
        const divisionUserGroups = sortBy(divisionUserGroupsFilter, (userGroup) => {
            return userGroup.divisionName.toLowerCase();
        });
        const teamUserGroupsFilter = filter(this.props.userGroups.data, (userGroup) => {
            return this.newMethod14(userGroup);
        });
        const teamUserGroups = sortBy(teamUserGroupsFilter, (userGroup) => {
            return userGroup.teamName.toLowerCase();
        });
        let fontFamilyName = styles.globalFontFamily;
        let errorKeys = ['errorUserNameMsg'];
        const error = this.newMethod15(errorKeys);
        const success =
            this.newMethod16();
        const loggedInUserStatus = editUserDetail.id === loggedInUserDetail.id ? true : false;
        let userStatusDropdown = null;

    if (loggedInUserDetail.roleTypeId === 1) {
        // Display the User Status dropdown only for System Administrators (roleTypeId === 1)
        userStatusDropdown = (
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="userStatus" className="group-detail-label">User Status</label>
                <div className="user-detail-field">
                    <FormControl>
                        <Select
                            id="userStatus-select"
                            className="select-box"
                            style={{ fontFamily: fontFamilyName }}
                            value={editUserDetail.active === null ? 'deactivate' : editUserDetail.active ? 'activate' : 'deactivate'}
                            onChange={(event) => {
                                this.onChangeEditUserStatus(event);
                            }}
                            tabIndex={1}
                            disabled={loggedInUserStatus}
                        >
                            <MenuItem value="activate">Activate</MenuItem>
                            <MenuItem value="deactivate">Deactivate</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </CardText>
        );
    }
        return (
            <div id="edit-user" className='user-group-management'>
                <Card className='group-card'>
                    <SectionHeader name="Edit/Move User" color={styles.sectionTileColour} styles={styles} />
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'black' }}>
                            Note: To move the user to different group, select the new User group/Company/Division/Team in the drop downs.
                        </div>
                    </CardText>
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="id" className="group-detail-label">User Name<p className="usr-mandat">*</p></label>
                        <div className="user-detail-field">
                            <TextField
                                className="group-field"
                                id="userId"
                                type="text"
                                fullWidth={true}
                                disabled={true}
                                value={editUserDetail.userName === null ? "" : editUserDetail.userName}
                                onChange={(event, newValue) => {
                                    this.onChangeEditUserName(event);
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type a user name"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>
                    {userStatusDropdown}
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="full" className="group-detail-label">Name<p className="usr-mandat">*</p></label>
                        <div className="user-detail-field">
                            <TextField
                                className="group-field"
                                id="fullName"
                                type="text"
                                fullWidth={true}
                                disabled={!loggedInUserStatus}
                                value={editUserDetail === null ? "" : editUserDetail.fullName}
                                onChange={(event, newValue) => {
                                    this.onChangeEditFullName(event);
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type First Name and Last Name"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>
                    {this.state.errorFullNameMsg != null &&
                        <div className="user-validation">* {this.state.errorFullNameMsg}</div>}
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="email" className="group-detail-label">Email<p className="usr-mandat">*</p></label>
                        <div className="user-detail-field">
                            <TextField
                                className="group-field"
                                id="emailId"
                                type="text"
                                fullWidth={true}
                                disabled={!loggedInUserStatus}
                                value={editUserDetail === null ? "" : editUserDetail.emailId}
                                onChange={(event, newValue) => {
                                    this.onChangeEditEmailId(event);
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type a Valid Email"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>                    
                    {this.state.errorEmailIdMsg != null &&
                        <div className="user-validation">* {this.state.errorEmailIdMsg}</div>}
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">User Level<p className="usr-mandat">*</p></label>
                        <div className="user-detail-field">
                            <FormControl>
                                {this.newMethod17(fontFamilyName, editUserDetail, loggedInUserDetail)}
                            </FormControl>
                        </div>
                    </CardText>
                    {this.state.errorRoleMsg != null &&
                        <div className="user-validation">* {this.state.errorRoleMsg}</div>}
                    {this.newMethod20(editUserDetail, fontFamilyName, systemUserGroups)}
                    {this.newMethod21(editUserDetail, fontFamilyName, loggedInUserStatus, companyUserGroups)}
                    {editUserDetail.roleTypeId >= UrlConstants.COMPANY_ROLE_TYPE_ID &&
                        this.state.errorCompanyMsg != null &&
                        <div className="user-validation">* {this.state.errorCompanyMsg}</div>}
                    {this.newMethod22(editUserDetail, fontFamilyName, loggedInUserStatus, divisionUserGroups)}
                    {editUserDetail.roleTypeId >= UrlConstants.DIVISION_ROLE_TYPE_ID &&
                        this.state.errorDivisionMsg != null &&
                        <div className="user-validation">* {this.state.errorDivisionMsg}</div>}
                    {this.newMethod23(editUserDetail, fontFamilyName, loggedInUserStatus, teamUserGroups)
                    }{editUserDetail.roleTypeId >= UrlConstants.TEAM_ROLE_TYPE_ID
                        && this.state.errorTeamMsg != null &&
                        <div className="user-validation">* {this.state.errorTeamMsg}</div>}
                    <CardText>
                        {error}
                        {success}
                    </CardText>
                    <CardText>
                        <Button id="update" className="action" variant="outlined"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onUpdateUserRecord}
                        >Update<SpinnerContainer />
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                         <Button className="action" variant="outlined" id="back"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onBackToUserList}
                        >Back
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                         <Button className="action hide-button" variant="outlined" id="save"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                        >Resend Invite
                        </Button>
                    </CardText>
                </Card>

            </div>
        );
    }

    newMethod23(editUserDetail, fontFamilyName, loggedInUserStatus, teamUserGroups) {
        return editUserDetail.roleTypeId >= UrlConstants.TEAM_ROLE_TYPE_ID
            && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="team" className="group-detail-label">Team<p className="usr-mandat">*</p></label>
                <div className="user-detail-field">
                    <FormControl>
                        <Select id="team-select" className="select-box"
                            style={{ fontFamily: fontFamilyName }}
                            value={this.props.editTeam !== null ? this.props.editTeam : ""}
                            onChange={(event) => { this.onTeamSelectionChange(event); } }
                            tabIndex={1}
                            disabled={loggedInUserStatus}>
                            {teamUserGroups.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                                <MenuItem key={option.id}
                                    value={option.id}>
                                    {option.teamName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </CardText>;
    }

    newMethod22(editUserDetail, fontFamilyName, loggedInUserStatus, divisionUserGroups) {
        return editUserDetail.roleTypeId >= UrlConstants.DIVISION_ROLE_TYPE_ID &&
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="division" className="group-detail-label">Division<p className="usr-mandat">*</p></label>
                <div className="user-detail-field">
                    <FormControl>
                        <Select id="division-select" className="select-box"
                            style={{ fontFamily: fontFamilyName }}
                            value={this.props.editDivision !== null ? this.props.editDivision : ""}
                            onChange={(event) => { this.onDivisionSelectionChange(event); } }
                            tabIndex={1} disabled={loggedInUserStatus}>
                            {divisionUserGroups.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                                <MenuItem key={option.id}
                                    value={option.id}>
                                    {option.divisionName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </CardText>;
    }

    newMethod21(editUserDetail, fontFamilyName, loggedInUserStatus, companyUserGroups) {
        return editUserDetail.roleTypeId >= UrlConstants.COMPANY_ROLE_TYPE_ID &&
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="company" className="group-detail-label">Company<p className="usr-mandat">*</p></label>
                <div className="user-detail-field">
                    <FormControl>
                        <Select id="company-select" className="select-box"
                            style={{ fontFamily: fontFamilyName }}
                            value={this.props.editCompany !== null ? this.props.editCompany : ""}
                            onChange={(event) => { this.onCompanySelectionChange(event); } }
                            tabIndex={1} disabled={loggedInUserStatus}>
                            {companyUserGroups.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                                <MenuItem key={option.id}
                                    value={option.id}>
                                    {option.companyName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </CardText>;
    }

    newMethod20(editUserDetail, fontFamilyName, systemUserGroups) {
        return editUserDetail.roleTypeId === UrlConstants.SYSTEM_ROLE_TYPE_ID &&
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="system" className="group-detail-label">System<p className="usr-mandat">*</p></label>
                <div className="user-detail-field">
                    <FormControl>
                        <Select id="system-select" className="select-box" disabled={true}
                            style={{ fontFamily: fontFamilyName }}
                            value={editUserDetail.userGroupId !== null ? editUserDetail.userGroupId : ""}
                            tabIndex={1}>
                            {systemUserGroups.map((option) => (option.label === "[Blank]" ||
                                option.label === "[blank]") ? "" :
                                <MenuItem key={option.id}
                                    value={option.id}>
                                    {option.systemName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </CardText>;
    }

    newMethod17(fontFamilyName, editUserDetail, loggedInUserDetail) {
        return <Select id="user-group-select" className="select-box"
            style={{ fontFamily: fontFamilyName }}
            value={editUserDetail !== null ? editUserDetail.roleTypeId : ""}
            onChange={(event) => { this.handleUserGroupInEditUser(event); } }
            tabIndex={1} disabled={editUserDetail.roleTypeId === 1 ||
                editUserDetail.id === loggedInUserDetail.id ? true : false}>
            {editUserDetail.roleTypeId === UrlConstants.SYSTEM_ROLE_TYPE_ID &&
                <MenuItem value="1">System Administrator</MenuItem>}
            {this.newMethod18(loggedInUserDetail, editUserDetail)}
            {this.newMethod19(loggedInUserDetail, editUserDetail)}
            {((loggedInUserDetail.roleTypeId === UrlConstants.TEAM_ROLE_TYPE_ID &&
                editUserDetail.id === loggedInUserDetail.id) ||
                (loggedInUserDetail.roleTypeId < UrlConstants.TEAM_ROLE_TYPE_ID)) &&
                <MenuItem value="4">Team Administrator</MenuItem>}
            <MenuItem value="5">Team Member</MenuItem>
        </Select>;
    }

    newMethod19(loggedInUserDetail, editUserDetail) {
        return ((loggedInUserDetail.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID &&
            editUserDetail.id === loggedInUserDetail.id)
            ||
            (loggedInUserDetail.roleTypeId < UrlConstants.DIVISION_ROLE_TYPE_ID)) &&
            <MenuItem value="3">Division Administrator</MenuItem>;
    }

    newMethod18(loggedInUserDetail, editUserDetail) {
        return ((loggedInUserDetail.roleTypeId < UrlConstants.COMPANY_ROLE_TYPE_ID)
            || (loggedInUserDetail.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID &&
                editUserDetail.id === loggedInUserDetail.id)) &&
            <MenuItem value="2">Company Administrator</MenuItem>;
    }

    newMethod16() {
        return this.props.editUserDetail != null && this.props.editUserDetail.successMsg !== null ?
            <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'black', fontWeight: 'bold' }}>
                {this.props.editUserDetail.successMsg}</div> : null;
    }

    newMethod15(errorKeys) {
        return (this.state['errorUserNameMsg'] !== null ||
            this.props.editUserDetail.errorMsg !== null) ?
            <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red' }}>
                {errorKeys.map((key) => (this.state[key] !== null) ?
                    <div className="note-important">
                        <p>*{this.state[key]}</p>
                    </div> : null
                )}
                {this.newMethod31()}
            </div>
            : null;
    }

    newMethod31() {
        return this.props.editUserDetail.errorMsg !== null ?
            <div className="note-important">
                <p>*{this.props.editUserDetail.errorMsg}</p>
            </div> : null;
    }

    newMethod14(userGroup) {
        return userGroup.roleTypeId === UrlConstants.TEAM_ROLE_TYPE_ID &&
            userGroup.parentUserGroupId === this.props.editDivision;
    }

    newMethod13(userGroup) {
        return userGroup.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID &&
            userGroup.parentUserGroupId === this.props.editCompany;
    }
}

EditUser.propTypes = {
    styles: PropTypes.object.isRequired,
    onChangeEditUserName: PropTypes.func,
    onChangeEditUserStatus: PropTypes.func,
    onChangeEditFullName: PropTypes.func,
    onChangeEditEmailId: PropTypes.func,
    handleUserGroupInEditUser: PropTypes.func,

};

export default EditUser;