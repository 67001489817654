const AnalyticsConstant = {
    HOME_PAGE: 'enter property address',
    QUESTION_PAGE: 'review property details',
    EMAIL: 'email report',
    GO_TO_QUOTE: 'get a quote',
    DOWNLOAD: 'download a quote',
    PROFILE_ID: '12400',
    AGREE: 'terms of use',
    REBUILD_SUMMARY: 'rebuild cost summary',
    SITE_EXPERIENCE_MOBILE: 'mob',
    SITE_EXPERIENCE_TABLET: 'tab',
    SITE_EXPERIENCE_DESKTOP: 'desktop'
}

export default AnalyticsConstant;