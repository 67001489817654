import React from 'react';
import SectionHeader from './SectionHeader';
import { Card } from 'material-ui';
import PropTypes from 'prop-types';
import Disclaimer from './Disclaimer';

const CCEDisclaimerSection = ({ state, contentAfter, styles, contentBefore, productId, showDisclaimerAtBottom, companyDisclaimer, divisionDisclaimer }) => {
    let sectionHeaderCCE = showDisclaimerAtBottom
        ? <SectionHeader name="Disclaimer" styles={styles} color={styles.sectionTileColour} />
        : <SectionHeader name="" styles={styles} color={styles.sectionTileColour} />;

    const divisionStyle = {
        fontSize: '1rem',
        fontFamily: styles.globalFontFamily,
        paddingTop: companyDisclaimer !== null ? '1em' : '0em',
    };

    return (
        <Card style={{ paddingBottom: '1em', marginTop: '15px' }}>
            {sectionHeaderCCE}
            { (companyDisclaimer !== null || divisionDisclaimer !== null) && <div id="group-disclaimer" className="disclaimer" style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '1em 2em 0em 2em',
                fontFamily: styles.globalFontFamily,
                color: '#000000'
            }}>
                { companyDisclaimer !== null && <div style={{fontSize: '1rem', fontFamily: styles.globalFontFamily}} dangerouslySetInnerHTML={{
                    __html: companyDisclaimer}}/> }
                { divisionDisclaimer !== null && <div style={divisionStyle} dangerouslySetInnerHTML={{__html: divisionDisclaimer}}/> }
            </div>
            }
            <Disclaimer state={state} contentAfter={contentAfter} contentBefore={contentBefore} productId={productId} styles={styles} />
        </Card>
    );
};

CCEDisclaimerSection.propTypes = {
    state: PropTypes.string,
    contentBefore: PropTypes.string,
    contentAfter: PropTypes.string,
    styles: PropTypes.object,
    productId: PropTypes.string,
};

export default CCEDisclaimerSection;