import React from 'react';
import {primaryButtonStyle} from '../style/styles';
import {Dialog, FlatButton, GridList, GridTile} from 'material-ui';
import PropTypes from 'prop-types';

const RefreshWarning = ({handleClose, refreshDialogOpen}) => {
    return (
        <Dialog
            title={"We have just updated our database of information.\nPlease restart your estimate to ensure you are using the latest information."}
            titleStyle={{whiteSpace: 'pre-line', color: '#000000'}}
            className="dialog error-dialog"
            contentClassName="dialog-content"
            bodyClassName="dialog-body"
            modal={true}
            open={refreshDialogOpen}>
            <GridList cols={10} cellHeight={'auto'}
                      style={{
                          paddingTop: '1em',
                          paddingBottom: '1em',
                      }}>
                <GridTile cols={10}>
                    <FlatButton
                        className="small secondary search-again"
                        label="RESTART"
                        onClick={handleClose}
                        {...primaryButtonStyle}
                    />
                </GridTile>
            </GridList>
        </Dialog>
    );
};

RefreshWarning.propTypes = {
    handleClose: PropTypes.func.isRequired,
    refreshDialogOpen: PropTypes.bool.isRequired
};

export default RefreshWarning;