import apiBaseUrl from '../apiBaseUrl';

const addUserApi = (productId, profileId, userDetail, claudtoken) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/addUser`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                userId: userDetail.userId,
                userGroupId: userDetail.userGroupId,
                userEmailId: userDetail.useremailId,
                userFullName: userDetail.userfullname,
                isActive: true,
                roleTypeId: userDetail.roleTypeId,
                accessToken: claudtoken.apigee_token,
            }),
        }
    );

};

export default addUserApi;