import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {openDebugDialog, updateDebugToken} from '../actions/actionCreator';

import DebugReportActionButton from '../components/DebugReportActionButton';
import {closeDebugDialog, downloadReport} from '../actions/calculationActionCreator';

const mapStateToProps = (state) => {
    return {
        profileId: state.profileId,
        productId: state.productId,
        propertyId: state.addressChosen.propertyId,
        questions: state.questions,
        dialogOpen: state.debugReport.dialogOpen,
        token: state.debugReport.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateDebugToken,
        openDebugDialog,
        downloadReport,
        closeDebugDialog,
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps) => {
    return {
        dialogOpen: stateProps.dialogOpen,
        token: stateProps.token,
        textChange: (text) => dispatchProps.updateDebugToken(text),
        onPress: () => dispatchProps.openDebugDialog(),
        downloadReport: () => dispatchProps.downloadReport(stateProps.productId, stateProps.profileId, stateProps.propertyId, stateProps.questions),
        cancel: () => dispatchProps.closeDebugDialog(),
    };
};


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DebugReportActionButton);
