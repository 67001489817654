import React from 'react';
import {Card} from 'material-ui';
import DebugReportActionButtonContainer from '../containers/DebugReportActionButtonContainer';
import SectionHeader from './SectionHeader';
import ResultList from './ResultList';
import WhatNextContainer from '../containers/WhatNextContainer';
import ContentInsuranceContainer from '../containers/ContentInsuranceContainer';
import PropTypes from 'prop-types';
import WhatNextContainerIAG from '../containers/WhatNextContainerIAG';
import UrlConstants from '../constants/UrlConstants';
import UserDetailContainer from '../containers/UserDetailContainer';
import ChartViewPage from "./charts/ChartViewPage";

class Result extends React.Component {
    componentDidMount() {
        if (this.props.result.manual) {
            this.container.scrollIntoView();
        }
    }

    render() {
        const { result, debug, display, callToActionText, rebuildCostHeader, rebuildCostNote, rebuildCostFooterNote, rebuildBreakupSectionHeader, contactUsUrl,
        showNote, callToActionSectionTemplate, ctaButton1Url, downloadPdfReportBodyText, profileId} = this.props;
        const { userName: userNameDisplay, email: emailDisplay, downloadReport, contentInsurance, displayContactUs, displayCTAButton1,
        includeEmailAddress, restrictDownloadReport} = display;

        function getSortedVariables() {
            if (!result.data) {
                return [];
            }
            const filteredVariables = result.data.variables.filter(variable => {
                const title = variable.title.toLowerCase();
                return title !== 'estimated rebuild cost in 12 months' && title !== 'estimated rebuild cost in 18 months';
            });

            return filteredVariables;
        }
       
        function getEscalationFactorVariables() {
            if (!result.data) {
                return [];
            }
            const filteredVariables = result.data.variables.filter(variable => {
                const title = variable.title.toLowerCase();
                return title === 'estimated rebuild cost in 12 months' || title === 'estimated rebuild cost in 18 months';
            });
            return filteredVariables;
        }

        function getBreakupVariables() {
            if (result.data && result.data.totalsBreakup) {
                return result.data.totalsBreakup;
            }
            return [];
        }

        function getSortedBreakupVariablesInM2() {
            if (result.data && result.data.breakupVariablesInM2) {
                return result.data.breakupVariablesInM2;
            }
            return [];
        }

        function getSortedDeclaredValue() {
            if (result.data && result.data.declaredValue) {
                return result.data.declaredValue;
            }
            return [];
        }

        function getSortedAllowancesForCostInflation() {
            if (result.data && result.data.allowancesForCostInflation) {
                return result.data.allowancesForCostInflation;
            }
            return [];
        }

        const sortedVariables = getSortedVariables();
        const breakupVariables = getBreakupVariables();
        const breakupVariablesInM2 = getSortedBreakupVariablesInM2();
        const declaredValue = getSortedDeclaredValue();
        const allowancesForCostInflation = getSortedAllowancesForCostInflation();
        const exceedsMaxEstimate = this.hasExceedsMaxEstimate(result);

        const userDetailsPDF = this.buildUserDetailsSectionForMandPDF(includeEmailAddress, restrictDownloadReport);
        let rebuildSectionHeader = this.buildRebuildSectionHeader(rebuildCostHeader);
        const rebuildBreakUpHeader = rebuildBreakupSectionHeader ? rebuildBreakupSectionHeader : "Included features";

        let whatNext;
        let downloadButton;
        let contentEstimate;
        if (debug) {
            downloadButton = <DebugReportActionButtonContainer/>;
        } else if (result.data && result.data.variables.length > 0) {
            if (userNameDisplay || emailDisplay || downloadReport ||
                (callToActionText !== null && callToActionText !== '' && (showNote === 'yes' || showNote === null)) ||
                (displayContactUs && contactUsUrl !== null && contactUsUrl !== '') ||
                (displayCTAButton1 && ctaButton1Url != null && ctaButton1Url !== '')) {
                if (callToActionSectionTemplate === 'Default') {
                    whatNext = <WhatNextContainer />;
                } else {
                    whatNext = <WhatNextContainerIAG
                        displayCTAButton1={displayCTAButton1}
                        ctaButton1Url={ctaButton1Url}
                        displayContactUs={displayContactUs}
                        downloadPdfReportBodyText={downloadPdfReportBodyText}
                    />;
                }
            }
            if (contentInsurance) {
                contentEstimate = <ContentInsuranceContainer
                productId={this.props.productId}
                profileId={this.props.profileId}
                addressChosen={this.props.addressChosen.suggestion}
                onLetUsKnowDialogOpen={this.props.onLetUsKnowDialogOpen}
                />;
            }
        }
        return (
            <div ref={(div) => this.container = div}>
                {debug &&
                <Card className="card">
                    <SectionHeader name="Debug Report"
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    {downloadButton}
                </Card>
                }
                {!debug && !exceedsMaxEstimate && (
  <>
    <Card className="card">
      <SectionHeader
        name={rebuildSectionHeader}
        color={this.props.styles.sectionTileColour}
        styles={this.props.styles}
      />
      {rebuildCostNote && (
        <div
          tabIndex={0}
          className="estimate-section-notes"
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: '2em 2em',
            fontFamily: this.props.styles.globalFontFamily,
          }}
          dangerouslySetInnerHTML={{ __html: rebuildCostNote }}
        />
      )}
      <ResultList variables={sortedVariables} styles={this.props.styles} />
      {rebuildCostFooterNote && (
        <div
          tabIndex={0}
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: '2em 2em',
            fontFamily: this.props.styles.globalFontFamily,
          }}
          dangerouslySetInnerHTML={{ __html: rebuildCostFooterNote }}
        />
      )}
    </Card>
    {profileId !== '118' && (<Card className="card">
      <SectionHeader
        name="Estimated Future Cost to Rebuild Your Home"
        color={this.props.styles.sectionTileColour}
        styles={this.props.styles}
      />
      {rebuildCostNote && (
        <div
          tabIndex={0}
          className="estimate-section-notes"
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: '2em 2em',
            fontFamily: this.props.styles.globalFontFamily,
          }}
          dangerouslySetInnerHTML={{ __html: rebuildCostNote }}
        />
      )}
      <ResultList variables={getEscalationFactorVariables()} styles={this.props.styles} />
      {rebuildCostFooterNote && (
        <div
          tabIndex={0}
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            padding: '2em 2em',
            fontFamily: this.props.styles.globalFontFamily,
          }}
          dangerouslySetInnerHTML={{ __html: rebuildCostFooterNote }}
        />
      )}
        <div
            tabIndex={0}
            style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '2em 2em',
                fontFamily: this.props.styles.globalFontFamily,
            }}
        >
            <ChartViewPage futureCostVariables={getEscalationFactorVariables()} currentCostVariables={sortedVariables} />
        </div>
      <div
        tabIndex={0}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'flex-end',
          padding: '2em 2em',
          fontFamily: this.props.styles.globalFontFamily,
        }}
      >
        <p><em>The Cordell Sum Sure Estimate is an estimate of the cost to rebuild the improvements on your property as at today; but your sum insured needs to be adequate for your whole policy period- and potentially beyond - if a rebuild is required. This estimate seeks to provide an indication of the potential impact of price increases on rebuild costs at a future point in time.</em></p>
    <br></br>
        <p><b>DISCLAIMER</b><br></br>The estimated future cost to rebuild your home is an estimate of the Cordell Sum Sure Estimate in 12 or 18 months’ time (Future Estimate).
 The Future Estimate relies on the output of a statistical model which uses past Cordell Construction Cost Index data and does not take into account future market conditions or other economic factors. The Future Estimate must not be relied upon as an accurate prediction of factors which may affect the Cordell Sum Sure Estimate, or construed as advice. The Future Estimate is current only at the date of supply.
 To the full extent permitted by law, CoreLogic excludes all liability for any loss or damage howsoever arising suffered by the recipient, whether as a result of the recipient's reliance on the accuracy of the Future Estimate or otherwise arising in connection with the Future Estimate.</p>
      </div>
    </Card>)}
  </>
)}

                {!debug && !exceedsMaxEstimate && breakupVariables.length > 0 &&
                <Card className="card">
                    <SectionHeader name={rebuildBreakUpHeader}
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    <ResultList variables={breakupVariables} styles={this.props.styles}/>
                </Card>
                }
                {!debug && !exceedsMaxEstimate && breakupVariablesInM2.length > 0 &&
                <Card className="card">
                    <SectionHeader name="Area"
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    <ResultList variables={breakupVariablesInM2} styles={this.props.styles} currencySymbol={false}/>
                </Card>
                }
                {!debug && declaredValue.length > 0 &&
                    <Card className="card">
                        <SectionHeader name="Estimated declared rebuild cost"
                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                        <ResultList variables={declaredValue} styles={this.props.styles}/>
                    </Card>
                }
                {!debug && allowancesForCostInflation.length > 0 &&
                    <Card className="card">
                        <SectionHeader name="Allowances for Cost Inflation"
                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                        <ResultList variables={allowancesForCostInflation} styles={this.props.styles}/>
                    </Card>
                }

                {!debug && exceedsMaxEstimate &&
                <Card className="card">
                    <SectionHeader name={rebuildSectionHeader}
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    <div style={{padding: '1em 2em', backgroundColor: '#DCE6F2', color: '#f04730', fontWeight: 'bold'}}>
                        <p> {result.exceedLimitMessage}</p>
                    </div>
                </Card>
                }

                {!debug && !exceedsMaxEstimate && contentInsurance && contentEstimate}
                 {!debug && !exceedsMaxEstimate && userDetailsPDF}
                {!debug && !exceedsMaxEstimate && whatNext}
            </div>
        );
    }

    buildRebuildSectionHeader(rebuildCostHeader) {
        let rebuildSectionHeader;
        if (this.props.productId !== UrlConstants.PRODUCT_ID_7 && this.props.productId !== UrlConstants.PRODUCT_ID_8) {
            rebuildSectionHeader = rebuildCostHeader ? rebuildCostHeader : "Estimated cost to rebuild your property";
        } else {
            rebuildSectionHeader = rebuildCostHeader ? rebuildCostHeader : "Estimated cost to rebuild your home as at today";
        }
        return rebuildSectionHeader;
    }
    buildUserDetailsSectionForMandPDF(includeEmailAddress, restrictDownloadReport) {
        let userDetailsSectionPDF;
        if (includeEmailAddress  && restrictDownloadReport ) {
             userDetailsSectionPDF = <UserDetailContainer userCapturedDetail={this.props.userCapturedDetail} />;
        }
        return userDetailsSectionPDF;
    }
    hasExceedsMaxEstimate(result) {
        return result.exceedLimitMessage !== null && result.exceedLimitMessage !== "" &&
            (this.props.productId === UrlConstants.PRODUCT_ID_7 || this.props.productId === UrlConstants.PRODUCT_ID_8);
    }
}

Result.propTypes = {
    result: PropTypes.object.isRequired,
    debug: PropTypes.bool.isRequired,
    sectionTileColour: PropTypes.string,
    display: PropTypes.object.isRequired,
    callToActionText: PropTypes.string,
    styles: PropTypes.object,
    rebuildCostNote: PropTypes.string,
    rebuildCostHeader: PropTypes.string,
    contactUsUrl: PropTypes.string,
    ctaButton1Url: PropTypes.string,
};

export default Result;
