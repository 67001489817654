import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    cceAddressPicked, fetchSuggestions, onPolicyNumberPicked,
    onAssignedToChange, onLastValuationChange, onUnderwriterChange, onInsuredChange, onRiskIdChange, onPropertyIdChange, onPostcodeChange, showPostcodeMessage
} from '../actions/actionCreator';
import { updateCurrentTab } from '../actions/searchActionCreator';
import RiskDetail from '../components/RiskDetail';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

const mapStateToProps = (state, defaultProps) => {
    return {
        searchText: defaultProps.searchText,
        suggestions: state.suggestions,
        value: defaultProps.value,
        results: defaultProps.results,
        onChange: defaultProps.onChange,
        handleChange: defaultProps.handleChange,
        handleProperty: defaultProps.handleProperty,
        onNewRequest: defaultProps.onNewRequest,
        styles: state.profile.styles,
        productId: defaultProps.productId,
        profileId: defaultProps.profileId,
        fontFamilyName: defaultProps.fontFamilyName,
        riskDetail: state.riskDetail,
        onPolicyNumberChange: defaultProps.onPolicyNumberChange,
        images: state.profile.images,
        onPolicyNumberPicked: state.onPolicyNumberPicked,
        onInsuredChange: state.onInsuredChange,
        onLastValuationChange: state.onLastValuationChange,
        onUnderwriterChange: state.onUnderwriterChange,
        onAssignedToChange: state.onAssignedToChange,
        cceImages: state.cceImages,
        riskId: state.riskId,
        isRiskIdTriggered: state.isRiskIdTriggered,
        isEditRisk: state.isEditRisk,
        userList : state.userList ,
        loggedInUserDetail: state.loggedInUserDetail,
        isEditEstimate: state.isEditEstimate,
        isEmailDownload: state.isEmailDownload,
        addressChosen: state.addressChosen,
        errorPropertyMsg: state.errorPropertyMsg,
        currentTab: state.currentTab,
        postcode: state.postcode,
        isPropertyIdTriggered: state.isPropertyIdTriggered,
        result: state.result,
        postcodeErrorMsg: state.postcodeErrorMsg,
        isPostcodeLocked: state.isPostcodeLocked,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        cceAddressPicked,
        fetchSuggestions,
        onPolicyNumberPicked,
        onInsuredChange,
        onLastValuationChange,
        onUnderwriterChange,
        onAssignedToChange,
        onRiskIdChange,
        updateCurrentTab,
        onPropertyIdChange,
        onPostcodeChange,
        showPostcodeMessage,
        trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onAddressPicked: dispatchProps.cceAddressPicked,
        onPolicyNumberPicked: dispatchProps.onPolicyNumberPicked,
        onInsuredChange: dispatchProps.onInsuredChange,
        onLastValuationChange: dispatchProps.onLastValuationChange,
        onUnderwriterChange: dispatchProps.onUnderwriterChange,
        onAssignedToChange: dispatchProps.onAssignedToChange,
        fetchAddressApi: dispatchProps.fetchAddressApi,
        onRiskIdChange: dispatchProps.onRiskIdChange,
        onPropertyIdChange: dispatchProps.onPropertyIdChange,
        onPostcodeChange: dispatchProps.onPostcodeChange,
        showPostcodeMessage: dispatchProps.showPostcodeMessage,
        onSearchTermChanged: (term) => {
            dispatchProps.fetchSuggestions(stateProps.productId, term);
        },
        updateCurrentTab: dispatchProps.updateCurrentTab,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Risk Detail Page', ownProps.location.pathname, stateProps.sessionTrackId, "RiskDetailPage", stateProps.partner);
                dispatchProps.trackSegmentPageView("Risk Detail Page", pageDetails);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RiskDetail);