import React from 'react';
import PropTypes from 'prop-types';
import { Search, Form } from 'semantic-ui-react';

const SearchRisk = ({ styles, productId, profileId, name, fontFamilyName, onChangeSearchText, searchText, pageName }) => {

    return (
       <Form.Field style={{ fontFamily: fontFamilyName }}>
           { pageName !== null && pageName !== undefined  && (pageName === "estimateMgmt" || pageName === "userMgmt")
                    ?
            <Search
                className= {getClassName(searchText)}
                placeholder="   Contains text..."
                value={searchText}
                onSearchChange={onChangeSearchText.bind()}
                id={name}
                input=   {{ icon: 'search', iconPosition: 'left'}}            
                showNoResults={false}
            /> :
            
            <Search
                className="search-risk"
                fluid
                input={{ fluid: true }}
                placeholder="Contains text..."
                value={searchText}
                onSearchChange={onChangeSearchText.bind()}
                id={name}
                icon={{ className: "search" }}
                showNoResults={false}
            />
            }
        </Form.Field>
    );
};

SearchRisk.propTypes = {
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
};

export default SearchRisk;
function getClassName(searchText) {
    return searchText === null || searchText === "" ? "search-risk search-placeholder" : "search-risk";
}

