import apiBaseUrl from '../apiBaseUrl';

const updateUserGroupLogoApi = (productId, profileId, id, file) => {

    var formData = new FormData();
    formData.append("logo", file);
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/updateUserGroupLogo/${id}`; 
    return fetch(url,
        {            
            method: 'POST', 
            body: formData,
        }
    );
};

export default updateUserGroupLogoApi;