import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';
import AddUserGroupContainer from '../containers/AddUserGroupContainer';
import FilterGroupContainer from '../containers/FilterGroupContainer';
import FilterUserContainer from '../containers/FilterUserContainer';
import EditUserContainer from '../containers/EditUserContainer';
import AddUserContainer from '../containers/AddUserContainer';
import ImportRisksContainer from '../containers/ImportRisksContainer';
import ReportsContainer from '../containers/ReportsContainer';


const CCEAdminNavigation = ({ styles, productId, profileId, adminTabValue, handleAdminChange, widthChange, isFilterEnabled, isAddGroupEnabled, isUserGroupOptionSystem, isUsageReport, 
    onTabChange, isFilterUserEnabled, onTabUserChange, onTabImportRisksChange, onTabReportsChange, isAddUserEnabled, isEditUserEnabled,
 location}) => {


    const handleTabChange = (event, newValue) => {
        handleAdminChange(newValue);
    };
    const isMobile = widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
    return (
        <div className="navigation">
            <Tabs
                className="navigation-tabs"
                orientation="vertical"
                aria-label="Vertical tabs"
                value={adminTabValue}
                onChange={handleTabChange}
                style={{
                    opacity: styles.headerOpacity,
                    background: `linear-gradient(${styles.headerBackgroundTopColor}, ${styles.headerBackgroundBottomColor})`,
                }}>
                {!isMobile && <Tab className='nav-tab  nav-table-left' label="User Management" id='tab-0' disabled={false} onClick={onTabUserChange} />}
                {!isMobile && <Tab className='nav-tab  nav-table-left' label="Group Management" id='tab-1' disabled={false} onClick={onTabChange} />}
                {!isMobile && <Tab className='nav-tab  nav-table-left' label="Import / Export" id='tab-2' disabled={false} onClick={onTabImportRisksChange} />}
                {!isMobile && <Tab className='nav-tab  nav-table-left' label="Reports" id='tab-2' disabled={false} onClick={onTabReportsChange} />}

                {isMobile && <Tab className='nav-tab' label="User" id='tab-0' disabled={false} />}
                {isMobile && <Tab className='nav-tab' label="Group" id='tab-1' disabled={false} />}
                {isMobile && <Tab className='nav-tab' label="Import/Export" id='tab-2' disabled={false}  onClick={onTabImportRisksChange}/>}
                {isMobile && <Tab className='nav-tab' label="Reports" id='tab-3' disabled={false}  onClick={onTabReportsChange}/>}
            </Tabs>
            <div className="fortab foradmin" style={{ margin: '0px' }} >
                <TabPanel value={adminTabValue} index={0}>
                 {isAddUserEnabled && <AddUserContainer  location ={location}/>}
                    {isFilterUserEnabled && <FilterUserContainer 
                            productId={productId}
                            profileId={profileId} location ={location}/>}
                    {isEditUserEnabled && <EditUserContainer  location ={location}/>}
                </TabPanel>
                <TabPanel value={adminTabValue} index={1}>
                    {isAddGroupEnabled && <AddUserGroupContainer  location ={location}/>}
                    {isFilterEnabled && <FilterGroupContainer  location ={location}/>}
                </TabPanel>             
                <TabPanel value={adminTabValue} index={2}>                  
                    {isUserGroupOptionSystem && <ImportRisksContainer  location ={location}/>}                    
                </TabPanel>
                <TabPanel value={adminTabValue} index={3}>                  
                    {isUsageReport && <ReportsContainer  location ={location}/>}                    
                </TabPanel>
                <TabPanel value={adminTabValue} india index={4}>
                </TabPanel>
            </div>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={`tab-${index}`}
            className="tab-panel"
        >
            {value === index && children}
        </div>
    );
}

CCEAdminNavigation.propTypes = {
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
};

export default CCEAdminNavigation;