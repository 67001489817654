import ContactUsButton from '../components/ContactUsButton';
import {contactUsAction} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        display: {
            displayContactUs: state.profile.display.displayContactUs,
        },
        styles: state.profile.styles,
        text: {
            contactUsHeader: state.profile.text.contactUsHeader,
            contactUsButtonText: state.profile.text.contactUsButtonText,
            contactUsButtonLabel: state.profile.text.contactUsButtonLabel,
            callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate,
        },
        contactUsUrl: state.profile.contactUsUrl,
        profileId: state.profileId,
        partnerId: state.partnerId,
        widthChange: state.widthChange,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onContactUs: (contactUsUrl, profileId, partnerId) => dispatch(contactUsAction(contactUsUrl, profileId, partnerId))
    };
};

const mergeProps = (stateProps, dispatchProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        onContactUs: () => {
            dispatchProps.onContactUs(stateProps.contactUsUrl, stateProps.profileId, stateProps.partnerId);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContactUsButton);
