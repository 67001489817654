import React from 'react';
import PropTypes from 'prop-types';

const displayMessage = (name, version) => {
    const index = version.indexOf('.');
    const majorVersion = Number(version.substr(0, index));
    if (name === 'ie' && majorVersion < 11) {
        return 'THIS PAGE IS BEST VIEWED IN CHROME.';
    } else {
        return '';
    }
};

const BrowserWarning = ({name, version}) => {
    const message = displayMessage(name, version);

    if (!message) {
        return null;
    }

    return (
        <div style={{color: 'orange'}}>
            {message && <h2>{message}</h2>}
        </div>
    );
};

BrowserWarning.propTypes = {
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
};

export default BrowserWarning;