import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { checkRefreshToken } from './actions/loginActionCreator';
import reducer from './reducers/reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import {createTracker} from 'redux-segment';


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['claudtoken', 'tokenContents', 'isLogout', 'code', 'tokenStatus', 'homePath',
        'loggedInUserDetail', 'userSearchParams', 'userPagination', 'userSortParams', 'groupSearchParams',
        'pagination', 'groupSortParams', 'searchParams', 'grpPagination'],
};

const tracker = createTracker();
const persistedReducer = persistReducer(persistConfig, reducer);
const middleware = [thunkMiddleware];
if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger());
}

let store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(
        applyMiddleware(...middleware, tracker),
    ),
);

export default function indexStore() {
    const persistor = persistStore(store, null, () => {
        store.dispatch(checkRefreshToken());
    });
    return { store, persistor };
}
