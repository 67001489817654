import {connect} from 'react-redux';
import CCEResult from '../components/CCEResult';
import {bindActionCreators} from 'redux';
import {fetchProfile} from '../actions/actionCreator';

export const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        result: state.result,
        debug: state.debug,
        disclaimerText: state.profile.text.disclaimerCalculator,
        display: {
            userName: state.profile.display.userName,
            email: state.profile.display.email,
            downloadReport: state.profile.display.downloadReport,
        },
        callToActionText: state.profile.text.callToAction,
        styles: state.profile.styles,
        rebuildCostHeader: state.profile.text.rebuildCostHeader,
        rebuildBreakupSectionHeader: state.profile.text.rebuildBreakupSectionHeader,
        rebuildCostNote: state.profile.text.rebuildCostNote,
        rebuildCostFooterNote: state.profile.text.rebuildCostFooterNote,
        showNote: state.showNote,
        onLetUsKnowDialogOpen: defaultProps.onLetUsKnowDialogOpen,
        callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate === null ?
            "Default" : state.profile.text.callToActionSectionTemplate,
        downloadPdfReportBodyText: state.profile.text.downloadPdfReportBodyText,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
          fetchProfile,
    }, dispatch);
};

const RebuildCost = connect(mapStateToProps, mapDispatchToProps)(CCEResult);
export default RebuildCost;