import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const CCEDeleteDialog = ({ styles, deleteRisk, handleRiskDetailDeleteDialog, handleDeleteRisk, isRiskOrEstimate, handleEstimateDetailDeleteDialog, handleDeleteEstimate, deleteEstimate }) => {
    return (isRiskOrEstimate === "Risk" ?
        <Dialog 
            className="dialog terms-dialog"
            open={deleteRisk.riskDialog}
            maxWidth={'sm'}
            onClose={() => handleRiskDetailDeleteDialog(false)}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    Are you sure you want to delete the entire Risk with ID-{deleteRisk.riskId} <br></br>
                                    {deleteRisk.address} <br></br><br></br>
                                    <b style={{ color: 'black' }}>Please note this will delete all the associated estimates</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Yes, Delete" onPress={() => handleDeleteRisk(deleteRisk.riskId, deleteRisk.address)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => handleRiskDetailDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
        : <Dialog
            className="dialog terms-dialog"
            open={deleteEstimate.estimateDialog}
            maxWidth={'sm'}
            onClose={() => handleEstimateDetailDeleteDialog(false)}
        >
            <DialogContent>
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    Are you sure you want to delete the estimate created on-{deleteEstimate.createdDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Yes, Delete" onPress={() => handleDeleteEstimate(deleteEstimate.estimateId, deleteEstimate.estimatePrice)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => handleEstimateDetailDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>)
};

CCEDeleteDialog.propTypes = {
    deleteRisk: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    handleDeleteRisk: PropTypes.func.isRequired,
    handleRiskDetailDeleteDialog: PropTypes.func.isRequired,
};

export default CCEDeleteDialog;