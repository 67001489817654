import React from "react";

class CookiesConsentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: !this.getCookie(),
    };
  }

  dismissHandler = () => {
    const { productId, profileId} = this.props;
    this.setCookie("true", 30);
    this.setState({ show: false });
    this.props.saveCookieConsentDetails(productId, profileId, 'Yes');
  };

  getCookieName = () => {
    const { productId, profileId } = this.props;
    return `cookieConsentDismissed_${productId}_${profileId}`;
  };
  
  getCookie = () => {
    const cookieName = this.getCookieName();
    const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  };
  
  setCookie = (value, days) => {
    const cookieName = this.getCookieName();
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${cookieName}=${value};expires=${expires.toUTCString()};path=/`;
  };

  render() {
    const { show } = this.state;
    return (
      show && (
        <div
          className="cookie-consent"
          style={{
            backgroundColor: "white",
            color: "#4D5057",
            fontFamily: "Arial",
            fontSize: "1rem",
            lineHeight: "1.5",
            padding: "18px 26px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            margin: "0 auto",
            position: "fixed",
            bottom: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            borderRadius: "0px",
          }}
        >
          <p style={{ margin: 0, marginBottom: "1rem", textAlign: "left" }}>
            <p style={{ fontSize: "1.5rem", color: "#4D5057" }}>
              We use cookies to enhance your browsing experience.
            </p>
            We use cookies to improve and customise your browsing experience,
            provide tailored content and advertising, and for analytics and
            metrics regarding visitor usage. We may also share this information
            with third parties for the above purposes. By using our website, we
            assume you're ok with cookies being used as described in our{" "}
            <a
              href="https://www.corelogic.com.au/legals/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#4D5057", textDecoration: "none" }}
            >
              <strong style={{ textDecoration: "underline" }}>
                Privacy Policy
              </strong>
            </a>
          </p>
          <button
            onClick={this.dismissHandler}
            style={{
              backgroundColor: "rgb(35,52,71)",
              fontFamily: "Open Sans",
              color: "#fff",
              padding: "0.1rem 0.5rem",
              border: "none",
              borderRadius: "0px",
              cursor: "pointer",
              fontSize: "1.5em",
              marginLeft: "3rem",
              width: "30%",
            }}
          >
            Dismiss
          </button>
        </div>
      )
    );
  }
}

export default CookiesConsentBox;
