import React from 'react';
import {Dialog, GridList, GridTile, TextField} from 'material-ui';
import CustomFlatButtonContainer from "../containers/CustomFlatButtonContainer";

class LetUsKnowDialog extends React.Component {
    constructor(props) {
        super(props);
        this.name ='';
        this.email = '';
        this.mobile = '';
        this.state = {
            disableButton: false,
        };
    }
    onButtonClick = (address, productId, profileId, propertyId, handleLetUsKnow) => {
       handleLetUsKnow({open : false, productId, profileId}, propertyId, address, this.name, this.email, this.mobile, this.props.partnerId)
    };

    render() {
        const {open, address, productId, profileId, propertyId, handleClose, handleLetUsKnow, styles} = this.props;
        return (
            <Dialog
                title="Let us know"
                className="dialog let-us-know"
                contentClassName="dialog-content"
                bodyClassName="dialog-body"
                modal={true}
                style={{fontFamily: styles.globalFontFamily}}
                open={open}
                titleStyle={{fontFamily: styles.globalFontFamily, color: '#000000'}}
            >
                <GridList cols={2} cellHeight={'auto'}
                          style={{
                              marginTop: '1em',
                              marginBottom: '10em',
                          }}>
                    <GridTile cols={2}  style={{textAlign: 'left', fontSize: '0.75rem', color: '#000000', fontFamily: styles.globalFontFamily}}>
                        *Below fields are optional. To report anonymously, please click on Report button.
                    </GridTile>

                    <GridTile cols={2}>
                        <TextField
                            autoFocus
                            id="name"
                            label="Name"
                            placeholder="Name"
                            onBlur={(evt) => {this.name = evt.target.value}}
                            fullWidth
                            style={{fontFamily: styles.globalFontFamily}}
                        />
                    </GridTile>

                    <GridTile cols={2}>
                        <TextField
                            id="email"
                            label="Email Address"
                            type="email"
                            onBlur={(evt) => {this.email = evt.target.value}}
                            placeholder="Email address"
                            fullWidth
                            style={{fontFamily: styles.globalFontFamily}}
                        />
                    </GridTile>

                    <GridTile cols={2}>
                        <TextField
                            id="mobile"
                            onBlur={(evt) => {this.mobile = evt.target.value}}
                            label="Mobile Number"
                            placeholder="Mobile number"
                            fullWidth
                            style={{fontFamily: styles.globalFontFamily}}
                        />
                    </GridTile>


                    <GridTile cols={2}>
                        <CustomFlatButtonContainer
                            className="small secondary search-again"
                            label="CANCEL"
                            onPress={handleClose}
                            style={{
                                marginRight: '2.5em',
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                                color: '#000000'
                            }}
                            type="secondary"
                        />
                        <CustomFlatButtonContainer
                            className="small secondary search-again"
                            ref="Report"
                            id="reportButton"
                            display = {this.state.disableButton}
                            style={{
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                                border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
                            }}
                            label="Report"
                            onPress={() => {
                                this.setState({disableButton: true});
                                document.getElementById("reportButton").setAttribute("disabled", true);
                                this.onButtonClick(address, productId, profileId, propertyId, handleLetUsKnow);
                            }}
                            type="primary"
                        />
                    </GridTile>
                </GridList>
            </Dialog>
        );
    }
}

export default LetUsKnowDialog;
