import React from 'react';
import { TextField } from 'material-ui';
import { inputStyle, titleStyle } from '../style/styles';
import { isEmpty } from 'lodash';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';

const Email = ({ report, widthChange, recipient, onEmailChange, onEmailReport, emailText, message, 
    styles, callToActionSectionTemplate, profile, autoMailMessage }) => {

    const { email: emailId } = recipient.data;
    let successMessage = null;
    let autoSuccessMessage = null;
    if (report.sentReport) {
      successMessage = <div className="success">{message}</div>;
      autoSuccessMessage = <div className="success">{autoMailMessage}</div>
    }
    const error = recipient.error ? recipient.errorMsg : getError(report);
    const emailError = error ? <div className="error" style={{ marginTop: '0em', textAlign: 'left' }}> {error}</div> : null;
    const isIAG = (callToActionSectionTemplate === 'IAG1' || callToActionSectionTemplate === 'IAG2'
        || callToActionSectionTemplate === 'IAG3') ? true : false;
    let isMobile = (widthChange === null) ? window.innerWidth <= UrlConstants.MOBILE_INNER_WIDTH : widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
    let labelText = profile.display.includeEmailAddress? "Send Report to another email" : "Email report";
    
    const button = <div className="send-email-button">
        <CustomFlatButtonContainer
            className="input what-next-button"
            label={labelText}
            onPress={onEmailReport}
            display={emailId === null || report.sentReport}
            style={{
                fontFamily: styles.globalFontFamily,
                borderRadius: styles.buttonRadius,
                border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
            }}
            type="primary"
            keyboardFocus={false}
        />
    </div>
    return (
        <div className="email-report-container">{autoSuccessMessage}
            <div className="email-report-label">
                <div style={{
                    fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold'
                }} dangerouslySetInnerHTML={{ __html: emailText }} />
            </div>
            <div className="email-report-form">
                <div className={isIAG ? "email-field-iag" : "email-field"}>
                 {<TextField
                        className="required-field"
                        fullWidth={true}
                        id="email"
                        type="email"
                        floatingLabelStyle={{ ...titleStyle, fontFamily: styles.globalFontFamily }}
                        floatingLabelText="Email Address"
                        floatingLabelFixed={true}
                        value={emailId || ''}
                        inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                        underlineShow={false}
                        onChange={(event, newValue) => {
                            onEmailChange(isEmpty(newValue) ? null : newValue);
                        }}
                    />}
                </div>
                {emailError}
                {callToActionSectionTemplate === 'IAG3' && !isMobile ? "" : button}
            </div>
            {successMessage}
        </div>
    );
};

Email.propTypes = {
    report: PropTypes.object.isRequired,
    recipient: PropTypes.object.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    onEmailReport: PropTypes.func.isRequired,
    emailText: PropTypes.string,
    message: PropTypes.string,
    styles: PropTypes.object,
    userNameDisplay: PropTypes.bool
};

export default Email;

function getError(report) {
    return report.error ? report.error : null;
}
