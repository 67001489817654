import CCEWhatNext from '../components/CCEWhatNext';
import { connect } from 'react-redux';
import { trackCallToAction } from '../actions/actionCreator';

export const mapStateToProps = (state) => {
    return {
        display: {
            downloadReport: state.profile.display.downloadReport,
            displayContactUs: state.profile.display.displayContactUs,
            userName: state.profile.display.userName,
            email: state.profile.display.email,
        },
        text: {
            whatNextSectionTitle: state.profile.text.whatNextSectionTitle,
            whatNextSectionText: state.profile.text.whatNextSectionText,
            callToAction: state.profile.text.callToAction,
            email: state.profile.text.email,
            userName: state.profile.text.userName,
            downloadPdfReport: state.profile.text.downloadPdfReport,

        },
        result: state.result.data,
        contactUsUrl: state.profile.contactUsUrl,
        callToActionText: state.profile.text.callToAction,
        downloadPdfReportText: state.profile.text.downloadPdfReport,
        profileId: state.profileId,
        styles: state.profile.styles,
        returnToQuote: state.returnToQuote,
        address: state.addressChosen.suggestion,
        showNote: state.showNote,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        callToActionClicked: (event) => dispatch(trackCallToAction(event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CCEWhatNext);
