import apiBaseUrl from '../apiBaseUrl';

const getRiskByIdApi = (productId, profileId, id) => {
    let riskId = parseInt(id);
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getRiskDetailById/${riskId}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getRiskByIdApi;