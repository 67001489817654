import apiBaseUrl from '../apiBaseUrl';

const usageReportingApi = (productId, profileId, category, companyName, divisionName, teamName, startDate, endDate) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/usagereporting`;
    let actualCompanyName = companyName.split('+');
    return fetch(url,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Content-Disposition": "attachment; filename=UsageReport.csv"
              },
            body: JSON.stringify({
                "category": category,
                "companyName": actualCompanyName[1],
                "divisionName": divisionName,
                "teamName": teamName,
                "startDate": startDate,
                "endDate": endDate
            }),
            responseType: "blob"
        }
    );
};

export default usageReportingApi;