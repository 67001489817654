class UrlUtil {
    static addParams = ({baseUrl, token, debug, partner, returnUrl, quoteId, showNote}) => {
        let url = baseUrl;
        let urlJoin = '?';
        if (token) {
            url += `${urlJoin}token=${token}`;
            urlJoin = '&';
        }
        if (partner) {
            url += `${urlJoin}partner=${partner}`;
            urlJoin = '&';
        }
        if (debug) {
            url += `${urlJoin}debug=${debug}`;
            urlJoin = '&';
        }
        if (returnUrl) {
            url += `${urlJoin}returnUrl=${returnUrl}`;
            urlJoin = '&';
        }
        if (quoteId) {
            url += `${urlJoin}quoteId=${quoteId}`;
            urlJoin = '&';
        }
        if (showNote) {
            url += `${urlJoin}showNote=${showNote}`;
        }
        return url;
    };
}

export default UrlUtil;