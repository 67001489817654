import React from "react";

const AuTermsOfUse = (<div style={{ width: '100%', overflowX: 'hidden', color: '#000000' }}>

    <div>
        <ol start="1">
            <li> <h2 tabIndex={1}>Introduction</h2>
                <ol type={'a'}>

                    <li>
                        Please read these Terms carefully. These Terms govern your access to and use
                        of
                        the Website and the
                        Output.
                    </li>
                    <li>By using the Website you agree to be bound by these Terms. This agreement
                        takes
                        effect from the
                        point you first access the Website. If you do not accept any of these Terms,
                        then you must cease
                        using the Website.
                    </li>
                    <li>We may change or update these Terms from time to time by posting a notice of
                        such updates on the
                        Website. You are required to accept any changes or updates in order to
                        continue
                        to access and use
                        the Website. If you do not accept any change or update to these Terms then
                        you
                        must cease using the
                        Website
                    </li>
                    <li>You may not transfer any of your rights or obligations under these Terms to
                        any
                        person.
                        The validity, interpretation and enforcement of these Terms shall be
                        governed by
                        the laws of New
                        South Wales.
                    </li>

                </ol>
            </li>
        </ol>
    </div>


    <div>
        <ol start="2">
            <li> <h2 tabIndex={1}>Output</h2></li>
            <ol type={'a'}>
                <li>The Output will have those features described on the Website and we will use
                    commercially reasonable
                    efforts to ensure that the Website is accessible in accordance with these
                    Terms. You will only use
                    the Website and the Output for your own personal use (and not for any
                    commercial gain or benefit).
                </li>
                <li>The Sum Sure calculator takes the answers you input (or confirm, as
                    applicable)
                    and analyses them
                    against construction industry data collated by us to generate an estimated
                    reconstruction cost of
                    the improvements on your property (the Estimate). Use of the Sum Sure
                    calculator
                    and the Estimate
                    may not be appropriate for you and you will need to assess the suitability
                    of
                    this Estimate given
                    your knowledge of your property. You specifically acknowledge:
                    <ol type={'i'}>
                        <li>The Estimate is not intended to replace a professional valuation
                            through
                            a person
                            appropriately qualified to undertake a valuation of improvement
                            replacement costs (such as a
                            valuer or quantity surveyor).
                        </li>
                        <li>Where any details on your property or improvements are pre-populated
                            by
                            us, it is your
                            responsibility to check that the pre-populated information is
                            accurate
                            and correct. If you
                            do not amend it, we will assume that you have diligently checked
                            that
                            pre-populated
                            information and agree that it is accurate and correct.
                        </li>
                        <li>No physical inspection or reference to building features beyond what
                            you
                            describe to us (or
                            have accepted as pre-populated) have been used in generating the
                            Estimate. Except where
                            expressly identified, no local laws (including local planning
                            requirements) have been taken
                            into account in generating the Estimate.
                        </li>
                        <li>Construction costs vary regularly from time to time and by region –
                            construction industry
                            data used in the Sum Sure calculator is updated regularly (as it
                            becomes
                            available) but may
                            not always be up to date where construction costs are volatile, and
                            likewise the Estimate
                            may become out of date in a relatively short period of time. Also,
                            for
                            certain regions,
                            there may be less construction data available or a lower quality of
                            information, and this
                            may adversely affect the Estimate.
                        </li>
                        <li>Any descriptions about the information requested for the Sum Sure
                            calculator are simply
                            included to assist you in completing the required information.
                        </li>
                        <li>The Sum Sure calculator does not make any allowances for any
                            particular
                            insurance policy
                            conditions or tolerances that may be contained in any insurance
                            policy
                            that you have or may
                            purchase in the future.
                        </li>
                        <li>We do not accept any responsibility for the answers you provide or
                            the
                            Third Party
                            Information that the Sum Sure calculator receives, and as such, we
                            will
                            not be liable for
                            the accuracy of, your reliance of or use of the Sum Sure calculator.
                        </li>
                        <li>A map showing property boundaries is indicative only and may not be
                            complete or accurate. Any
                            produced map is not intended or designed to replace the certificate
                            of
                            title or land survey
                            information. If you need a certificate of title or land survey
                            information then you can order these
                            separately from us or a Land Titles Provider.
                        </li>
                        <li>Where a BAL Estimate is supplied, the following additional limitations and restrictions apply:
                            <ol type={'a'}>
                                <li>the BAL Estimate is designed for use solely as an input to the Cordell Sum Sure calculator in generating an Estimate for a subject property; </li>
                                <li>the BAL Estimate is generated using available data, without physical inspection of the subject allotment, vegetation classes impacting on the site or classified vegetation effective slope, using a data driven simplified methodology which is subject to limitations;   </li>
                                <li>the BAL Estimate must not be construed or relied upon as a Bushfire Attack Level (BAL) rating as determined in accordance with Australian Standard AS 3959:2018 Construction of buildings in bushfire-prone areas (and amendments or replacements) or used in establishing construction requirements for a site, and is not intended to replace a professional assessment of bushfire risk for a subject property;  </li>
                                <li>the BAL Estimate may not be available for all properties within the CoreLogic Database, and is current only at the date of publication or supply. </li>
                            </ol>
                        </li>
                    </ol>

                </li>

            </ol>
        </ol>

    </div>
    <div>
        <ol start="3">
            <li>
                <h2 tabIndex={1}>Website (requirements, access and modifications)</h2>

                <ol type={'a'}>
                    <li>We will endeavour to establish, maintain and enforce security measures
                        within the Website to a standard of good industry practice.
                    </li>
                    <li>We will endeavour to make the Website available at all times. However, you
                        acknowledge that the
                        Website is provided over the internet and mobile networks and so the quality
                        and
                        availability of the
                        Website is not guaranteed.
                    </li>
                    <li>We will not be obliged to provide access to the Website or any Output to the
                        extent that we are
                        prohibited from doing so by law or any agreements with any third party.
                    </li>
                    <li>The Sum Sure calculator, the Output and their features are dynamic in
                        nature.
                        There is no guarantee
                        that they will stay the same. We may:
                        <ol type={'i'}>
                            <li>change, refine or otherwise modify the features and functionality of
                                the
                                Website, Sum Sure
                                calculator or the Output; or
                            </li>
                            <li>cease, discontinue or temporarily suspend the availability of the
                                Website, Sum Sure
                                calculator or Output.
                            </li>
                        </ol>
                    </li>

                    <li>You permit us to check that the Website and the Output are being used in
                        accordance with these
                        Terms.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="4">
            <li>
                <h2 tabIndex={1}>Charges and payment</h2>
                <ol type={'a'}>
                    <li>We will not charge you for accessing the Website, but your internet or
                        mobile
                        network provider may
                        charge you for connection services when you download and/or use the Website.
                    </li>
                    <li>Mobile devices, communications links and systems compatibility (and all
                        costs
                        associated with such
                        items) are your sole responsibility. You agree that we are not responsible
                        for
                        any of those charges.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="5">
            <li>
                <h2 tabIndex={1}>Copyright and other restrictions</h2>
                <ol type={'a'}>
                    <li>The contents of the Website, Sum Sure calculator and Output are protected by
                        copyright and other
                        intellectual property rights, and no part of the Website (or the Sum Sure
                        calculator, or any Output)
                        may be reproduced or adapted in whole or in part without our written
                        consent.
                        Our ability to grant
                        consent is subject to us getting consent from our Third Party Suppliers. You
                        will not assert any
                        ownership or other rights in respect of any of the Website, Sum Sure
                        calculator,
                        the Output or any
                        documentation, information or materials contained on or made available
                        through
                        the Website (together
                        the <b>Information</b>).
                    </li>
                    <li>The Website and Information are made available for your own lawful internal
                        use
                        within Australia and
                        you agree not to further disseminate Information supplied (whether for
                        commercial gain or otherwise)
                        and in particular not to publish Information by any means (including in
                        writing
                        or electronic
                        dissemination) without our prior written consent. Our ability to grant
                        consent
                        is subject to us
                        getting consent from our Third Party Suppliers.
                    </li>
                    <li>You agree not to use the Website or Information in creating any mailing or
                        address list of persons
                        or properties, to use the Website or Information for Direct Marketing
                        activities, to sell the
                        Website or Information to others, or to use or offer to use the Website or
                        Information in any way
                        that may encroach on the privacy of others or otherwise breach any Privacy
                        Laws.
                    </li>
                    <li>In accessing the Website, you will not (and will not allow or encourage any
                        other person to):
                        <ol type={'i'}>
                            <li>decompile, disassemble or otherwise reverse engineer all or any
                                portion
                                of the Information
                                or the Website, including any source code, object code, algorithms,
                                methods or techniques
                                used or embodied therein;
                            </li>
                            <li>data mine, scrape, crawl, create links back to, harvest or use any
                                process or processes that
                                send automated queries to the Website;
                            </li>
                            <li>modify or create any derivative works based upon any Information or
                                make
                                copies of the
                                Website or any Information (except for the sole purpose of back-up
                                or
                                disaster recovery);
                            </li>
                            <li>remove or alter any copyright, trademark, logo or other proprietary
                                notice or label
                                appearing on or in the Website or any Information; or
                            </li>
                            <li>incorporate the Website or any Information into any other materials,
                                products or services.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="6">
            <li>
                <h2 tabIndex={1}> Customer Materials</h2>
                <ol type={'a'}>
                    <li>You grant us and each of our partners, affiliates, parent companies, related
                        entities, successors,
                        and assigns a worldwide, royalty-free, perpetual, irrevocable, assignable,
                        non-exclusive right and
                        licence to use, convert, reproduce, reformat, store, back-up, distribute,
                        sell,
                        transmit, perform,
                        display (publicly or otherwise), adapt, make derivative works of and
                        otherwise
                        commercialise and
                        exploit (with no obligation to account for profits) any Customer Materials
                        either directly or in
                        combination with other information or parties. You acknowledge that this
                        clause
                        6.a is also for the
                        benefit of our Third Party Supplier and may be enforced by it.
                    </li>
                    <li>You must not provide Customer Materials that you know:
                        <ol type={'i'}>
                            <li>you are not authorised to provide;</li>
                            <li>are incorrect; or</li>
                            <li>have been obtained in breach of any law or obligation of
                                confidentiality.
                            </li>
                        </ol>
                    </li>
                    <li>Subject to your compliance with clause 6.b above, we accept that if we and/or
                        any
                        of the
                        above mentioned persons choose to exploit the above mentioned right and
                        licence so
                        granted by you,
                        such commercialisation and exploitation is at our and/or the relevant
                        licensee's
                        own risk and as
                        such and notwithstanding anything contained in these Terms, you have no
                        liability for any loss, cost
                        or expenses that we and/or any relevant licensee/Third Party Supplier may
                        incur
                        as a result of such
                        commercialisation or exploitation.
                    </li>
                    <li>You confirm to us and our Third Party Supplier that:</li>
                    <ol type={'i'}>
                        <li>you acknowledge that we sometimes use customer materials supplied by
                            another customer in our
                            products, including the Output and in this regard, we do not
                            endorse,
                            support, represent or
                            guarantee the truthfulness, accuracy and reliability of the customer
                            materials from another
                            customer contained within the Website
                        </li>
                    </ol>

                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="7">
            <li>
                <h2 tabIndex={1}>Creative Commons Licence</h2>
                <ol type={'a'}>

                    <li>We are a party to a worldwide, royalty-free, non-exclusive, Creative Commons
                        Custom Licence under which we receive certain materials used in the Website.
                    </li>
                    <li>Such material is licensed to us and you under various Creative Commons licenses details of which can be found at <a tabIndex={0} target="_blank"
                        href="https://www.corelogic.com.au/ThirdPartyRestrictions"
                        rel="noopener noreferrer"
                    >https://www.corelogic.com.au/ThirdPartyRestrictions</a> (Creative
                        Commons Licence). Such materials are not our property and are
                        credited to the original author as shown in the metadata of the relevant
                        material.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="8">
            <li>
                <h2 tabIndex={1}>Confidentiality</h2>
                <ol type={'a'}>
                    <li>No Confidential Information may be disclosed by you to any person or entity
                        except:
                    </li>
                    <ol type={'i'}>
                        <li>to the extent you are required to do so by law; or</li>
                        <li>to the extent you are required to do so in connection with legal
                            proceedings relating to
                            these Terms.
                        </li>
                    </ol>
                    <li>You must not use Confidential Information except for the purpose specified
                        in
                        clause 8.a.
                    </li>

                    <li>You will take any action that is necessary to prevent or remedy any breach
                        of
                        your confidentiality
                        obligations or other unauthorised disclosure of Confidential Information.
                    </li>

                    <li>You will not remove, alter, obscure or otherwise modify any trademark,
                        copyright
                        or other
                        proprietary notice or legal disclaimer placed on or contained within the
                        Confidential Information.
                    </li>

                    <li>You acknowledge that due to the unique nature of the Confidential
                        Information,
                        any breach by you of
                        your obligations under these clauses 8.a to 8.d (inclusive) would result in
                        irreparable harm to us and
                        our Third Party Suppliers for which there is no adequate remedy; and
                        therefore,
                        upon any such breach
                        or threat thereof, we and our Third Party Suppliers will be entitled to
                        injunctive and other
                        appropriate equitable relief (without the necessity of proving damages, or
                        posting bond or other
                        security), in addition to whatever remedies we and our Third Party Suppliers
                        may
                        have at law.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="9">
            <li>
                <h2 tabIndex={1}>Privacy</h2>
                <ol type={'a'}>
                    <li>We are bound by Privacy Laws and have developed a Privacy Policy, available
                        on
                        our Website.
                    </li>

                    <li>You acknowledge that information transmitted over the internet is inherently
                        insecure. To the extent
                        that the Website or Information contains Personal Information, You must:
                        <ol type={'i'}>
                            <li>comply with all applicable Privacy Laws;</li>
                            <li>only use Personal Information for the purpose for which it was
                                disclosed;
                            </li>
                            <li>notify CoreLogic immediately (by email to privacy@corelogic.com.au)
                                of
                                any privacy
                                complaints or events which may cause any applicable Privacy Law to
                                be
                                breached.
                            </li>
                        </ol>
                    </li>
                    <li>We will:
                        <ol type={'i'}>
                            <li>comply with all applicable Privacy Laws and ensure that its
                                employees,
                                agents and
                                contractors do so;
                            </li>
                            <li>subject to its rights under this Licence, only use Personal
                                Information
                                that You disclose to
                                Us for the purpose for which it was disclosed.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="10">
            <li>
                <h2 tabIndex={1}>Limited warranties</h2>
                <ol type={'a'}>
                    <li>You may be entitled to certain guarantees under the Competition and Consumer
                        Act
                        2010 (Cth).
                    </li>

                    <li>Except for any guarantees which cannot be excluded by law pursuant to the
                        Competition and Consumer
                        Act 2010 (Cth), you acknowledge that we provide no warranties or guarantees
                        in
                        respect of the
                        Website other than those given in clause 2.a of these Terms and all other
                        warranties are excluded.
                    </li>

                    <li>We (and out Third Party Suppliers) expressly disclaim all warranties that:
                        <ol type={'i'}>
                            <li>access to the Website will always be immediate or uninterrupted;
                            </li>
                            <li>the Website or Output will meet your requirements or will be
                                compatible with or suitable for use with your systems, browsers
                                or applications;</li>
                            <li>the Website will be error-free or that errors or defects in the
                                Website
                                will be corrected,
                                however we will, where practically possible, endeavour to correct
                                errors
                                within a reasonable
                                time;
                            </li>
                            <li>any Information will be accurate, reliable or correct;</li>
                            <li>the statistical methods on which the Sum Sure calculator is based
                                use
                                appropriate or
                                accurate assumptions, are fit for your particular purpose or are
                                otherwise suitable for your
                                use; and
                            </li>
                            <li>the performance of the Sum Sure calculator will not be affected by
                                data
                                entry errors,
                                including incorrect entries, double entries or delayed entries, or
                                incorrect or untimely
                                data supplied by Third Party Suppliers.
                            </li>
                        </ol>
                    </li>

                    <li>You acknowledge and agree that to the extent that the Website contains any
                        Third
                        Party Information,
                        we provide no warranties, representations or guarantees in respect of such
                        Third
                        Party Information
                        (including any warranty or guarantee that the Third Party Information will
                        be
                        complete, accurate,
                        free of errors, omissions and defects, up to date, not misleading or fit for
                        purpose), except to the
                        extent that any such warranty or guarantee implied by law cannot be
                        excluded.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="11">
            <li>
                <h2 tabIndex={1}>Liability and your indemnity</h2>
                <ol type={'a'}>
                    <li>If any Output does not comply with the relevant requirements in these Terms,
                        we
                        will (within a
                        reasonable time period) either:
                        <ol type={'i'}>
                            <li>re-supply the Output to you; or</li>
                            <li>refund to you any charges you have paid and any direct and
                                identifiable
                                costs you have
                                incurred (other than those excluded by clause 11.b) in respect of the
                                Output, and
                            </li>
                        </ol>
                        this shall be your sole remedy in respect of any defect in the Output, your
                        use of the Website or
                        any breach of these Terms by us, or our negligence (or that of our Third
                        Party Suppliers), except
                        to the extent we are unable to exclude its liability by law (in which case,
                        our
                        liability will be limited to the amounts referred to in clause 11.a(ii) above.

                    </li>

                    <li>In no circumstances will any party (including when it comes to
                        us, our Third Party Suppliers) be liable for:
                        <ol type={'i'}>
                            <li>any special, consequential, indirect, incidental, punitive or
                                exemplary
                                damages of any kind;
                                or
                            </li>
                            <li>damages for any loss of profits or revenue, loss resulting from
                                interruption of business or
                                loss of use or data, arising out of or relating to the Website or
                                the
                                Output, however
                                caused, even if <b>we</b> or <b>our</b> Third Party Suppliers (as applicable) had
                                been
                                advised of or
                                should have known of the possibility of such loss,
                            </li>
                        </ol>
                        <p>
                            except in relation to unlawful use of our (or our Third Party
                            Suppliers')
                            intellectual
                            property or Confidential Information by you or any intellectual property
                            infringement claims
                            from third parties around your Customer Materials.
                        </p>
                    </li>

                    <li>You agree to reimburse us for any loss, liability, cost, fee or damage that
                        we
                        (and/or our Third
                        Party Suppliers) and each of their related entities or partners (or any of
                        their
                        employees,
                        directors or officers) incur as a direct result of:
                        <ol type={'i'}>
                            <li>your failure to comply with these Terms, or
                                any law;
                            </li>                            <li>any content you submit through the Website.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="12">
            <li>
                <h2 tabIndex={1}>Suspension and termination</h2>
                <ol type={'a'}>
                    <li>We may terminate (as applicable) your right to access or use the Website at
                        any
                        time if you breach
                        any material term or condition in these Terms or if you abuse the Website,
                        the
                        Sum Sure calculator
                        or the Output. Examples include:
                        <ol type={'i'}>
                            <li>any unlawful or unauthorised use of our (or our Third Party
                                Suppliers')
                                intellectual
                                property;
                                or
                            </li>
                            <li>in the case of any intellectual property infringement claim from a
                                third
                                party around your
                                Customer Materials.
                            </li>
                        </ol>
                    </li>
                    <li>In the event of termination under clause 12.a above, despite such termination,
                        all
                        copyright, data
                        privacy and use of information obligations will remain in effect after
                        termination in respect of all
                        information, copies and compilations retained by you.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="13">
            <li>
                <h2 tabIndex={1}>Notices</h2>
                <ol type={'a'}>
                    <li>Any queries you have regarding the Website should be addressed to your
                        insurance
                        supplier through
                        whom you accessed the link for this Website.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="14">
            <li>
                <h2 tabIndex={1}>Third party benefit</h2>

                <ol type={'a'}>

                    <li>These Terms are not only expressed for our benefit but also for the benefit
                        of
                        our Third Party
                        Suppliers (as it relates to matters that affect them).
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <ol start="15">
            <li>
                <h2 tabIndex={1}>Disclaimers</h2>
                <ol type={'a'}>
                    <li> You acknowledge and agree that the Output is made available subject to the disclaimers,
                        limitations and descriptions set out in the CoreLogic Disclaimers, and that any direct or indirect use by you of the Output
                        is subject to the disclaimers, limitations and descriptions set out in the CoreLogic Disclaimers.</li>
                    <li>For the purposes of applying the CoreLogic Disclaimers to Terms, any reference to “Product Data”
                        in the CoreLogic Disclaimers is deleted and replaced with “Output Data”.</li>
                </ol>
            </li>
        </ol>
    </div>

    <div>
        <ol start="16">
            <li>
                <h2 tabIndex={1}>Definitions</h2>
                <p>In these Terms, unless the context otherwise requires:</p>

                <b>CoreLogic Disclaimers</b>
                &nbsp;means (as applicable) any copyright or other proprietary notice, legal disclaimer, or government disclaimer
                located at <a href="http://corelogic.com.au/about-us/disclaimer.html">http://corelogic.com.au/about-us/disclaimer.html</a> &nbsp;
                or provided by CoreLogic to the Customer from time to time.&nbsp;<br /><br />

                <b>Confidential Information</b>
                &nbsp;means all confidential, non-public or proprietary information, regardless of
                how
                the information is
                stored, which is delivered to you before, on or after being bound by these
                Terms, relating to the
                Website, the Sum Sure calculator or the Output including such information
                which
                is (i) by its nature
                is confidential; (ii) is identified by either Party as confidential; or
                (iii)
                the other party knows,
                or ought to know, is confidential, but does not include the Excluded
                Information.&nbsp;<br /><br />

                <b>Creative Commons Licence</b>
                &nbsp;has the meaning given to it in clause 7.b.&nbsp;<br /><br />
                <b>Customer Materials</b>
                &nbsp;means any data, information, content, photographs, metadata and/or other
                materials you make
                available to us (or otherwise obtained by us from you (such as from your use
                of
                the Website) or that
                we possess of yours) directly or indirectly, from time to time.&nbsp;<br /><br />

                <b>Direct Marketing</b>
                &nbsp;means the use and/or disclosure of Personal Information to identify, target,
                segment or filter and
                then directly communicate to an identified individual or group of
                individuals to
                market or promote
                goods or services to (whether or not addressed to that individual by name)
                by
                means, normally
                supported by a database, which uses one or more advertising media and
                includes,
                telemarketing,
                email, SMS, targeted online advertising (including Facebook and Google Ads),
                postal mail and list
                brokering.&nbsp;<br /><br />
                <b>Estimate</b>
                &nbsp;has the meaning given to it in clause 2.b.&nbsp;<br /><br />
                <b>Excluded Information</b>
                &nbsp;means information to the extent which:
                <ol type={'i'}>
                    <li>it is in or becomes part of the public domain other than through breach
                        of these Terms or an
                        obligation of confidence owed to any person;
                    </li>
                    <li>you can prove, by contemporaneous written documentation, was already
                        known to you at the
                        time of disclosure by us (unless such knowledge arose from disclosure of
                        information in
                        breach of an obligation of confidentiality); or
                    </li>
                    <li>it was independently developed by the you without reference to the
                        Confidential Information
                        disclosed by us; or
                    </li>
                    <li>you acquire from a source other than us where such source is entitled to
                        disclose it.
                    </li>
                </ol>
                <b>Information</b>
                &nbsp;has the meaning given to it in clause 5.a.&nbsp;<br /><br />
                <b>Land Titles Provider</b>
                &nbsp;means providers of government Torrens Title Register information for land
                and
                property in each State
                and Territory.
                &nbsp;<br /><br />
                <b>Personal Information</b>
                &nbsp;means 'personal information' as that term is defined in the relevant and
                applicable Privacy Laws,
                and which a Party discloses to or receives from the other Party in
                connection
                with this Agreement
                (including information forming part of a database).
                &nbsp;<br /><br />
                <b>Privacy Laws</b>
                &nbsp;means any relevant or applicable privacy or data protection Laws relating to
                the
                collection, use,
                processing, disclosure, storage or granting of access to the Personal
                Information which may be
                amended and in force from time to time and may include the Privacy Act 1988
                (Cth) and the Australian
                Privacy Principles which form part of the Act, Do Not Call Register Act 2006
                (Cth) and the Spam Act
                2003 (Cth); as well as any other State or Territory acts and regulations
                which
                regulate the use of
                Personal Information in each respective State or Territory any other binding
                requirement under an
                Australian industry code or policy relating to the handling of Personal
                Information.
                &nbsp;<br /><br />
                <b>Output</b>
                &nbsp;means the output from the Sum Sure calculator or any other output made
                available
                to you through the
                Website (and includes any Output Data contained within them).
                &nbsp;<br /><br />
                <b>Output Data</b>
                &nbsp;means any data or property/geospatial information contained within or
                provided
                with the Output or
                through the Website.
                &nbsp;<br /><br />
                <b>Sum Sure calculator</b>
                &nbsp;means the Cordell Sum Sure calculator made available through the Website,
                and
                which generates the
                Output.
                &nbsp;<br /><br />
                <b>Terms</b>
                &nbsp;means these terms and conditions (as amended from time to time in accordance
                with clause 3) and any
                terms referred to in these terms and conditions.
                &nbsp;<br /><br />
                <b>Third Party Information</b>
                &nbsp;means any Output Data, other data, information, reports and other
                documentation
                or materials sourced
                from a Third Party Supplier.
                &nbsp;<br /><br />
                <b>Third Party Supplier</b>
                &nbsp;means any third party supplier who directly or indirectly supplies us with
                data or information, where such data or information is then contained within or
                provided with or through the Website.
                &nbsp;<br /><br />
                <b>We</b> or <b>our</b> or similar derivatives,
                &nbsp;means RP Data Pty Ltd trading as CoreLogic Asia Pacific.&nbsp;<br /><br />
                <b>Website</b>
                &nbsp;means the URL through which the Sum Sure calculator is available to you in accordance with this Agreement.
                &nbsp;
            </li>
        </ol>
    </div>


    <h2 tabIndex={1}>Currency</h2>
    <p>Version 4 of these Cordell Sum Sure Terms of Use are current as and from 01 May 2023.</p>
    <p>© 2023 RP Data Pty Ltd trading as CoreLogic Asia Pacific. CoreLogic and its licensors are the sole and exclusive owners of all rights,
        title and interest (including intellectual property rights) subsisting in the CoreLogic Materials contained in this publication.
        All rights reserved. </p>

</div>);

const NzTermsOfUse = (<div style={{ width: '100%', overflowX: 'hidden', color: '#000000' }}>
    <div>
        <h2 tabIndex={0}>Introduction</h2>
        <ol>
            <li>Please read these Terms carefully. These Terms govern your access to and use
                of the
                Website and the Output.
            </li>
            <li>By using the Website you agree to be bound by these Terms. This agreement
                takes
                effect from the point you first access the Website. If you do not accept any
                of these
                Terms, then you must cease using the Website.
            </li>
            <li>We may change or update these Terms from time to time by posting a notice of
                such
                updates on the Website. You are required to accept any changes or updates in
                order
                to continue to access and use the Website. If you do not accept any change
                or update
                to these Terms then you must cease using the Website.
            </li>
            <li>You may not transfer any of your rights or obligations under these Terms to
                any
                person.
            </li>
            <li>The validity, interpretation and enforcement of these Terms shall be
                governed by
                the laws of New Zealand.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Definitions</h2>
        <ol start="6">
            <li>
                In these Terms, unless the context otherwise requires:&nbsp;

                <p><b>Confidential Information </b>means all confidential, non-public or
                    proprietary
                    information,
                    regardless of how the information is stored, which is delivered to you
                    before,
                    on or after being bound by these Terms, relating to the Website, the Sum
                    Sure
                    calculator or the Output.</p>

                <p><b>Creative Commons Licence</b> has the meaning given to it in clause 25.</p>

                <p><b>Customer Materials </b>means any
                    data, information, content, photographs, metadata and/or other
                    materials you make available to us (or otherwise obtained by us from you
                    (such
                    as from your use of the Website) or
                    that we possess of yours) directly or indirectly, from time to time.</p>

                <p><b>Direct Marketing Activities </b>means the use and/or disclosure of Personal
                    Information to identify, target, segment or filter and then directly communicate to an
                    identified individual or group of individuals to market or promote goods or services to
                    (whether or not addressed to that individual by name) by means, normally supported
                    by a database, which uses one or more advertising media and includes,
                    telemarketing, email, SMS, targeted online advertising (including Facebook and
                    Google Ads), postal mail and list brokering.</p>

                <p><b>Estimate </b>has the meaning given to it in clause 8.</p>

                <p><b>Excluded Information </b>means Confidential Information which:</p>
                <ol type="a">
                    <li>is in or becomes part of the public domain
                        other than through breach of these Terms or an obligation of
                        confidence owed
                        to
                        us;
                    </li>

                    <li>you can prove, by contemporaneous written documentation, was
                        already known to you at the time of disclosure by us (unless such
                        knowledge
                        arose from disclosure of information in breach of an obligation of
                        confidentiality); or
                    </li>

                    <li>you acquire from a source other than us where such source is entitled to disclose it.</li>
                </ol>


                <p><b>Information </b>has the meaning given to it in clause 17.</p>

                <p><b>LINZ </b>means Land Information New Zealand.</p>

                <p><b>Personal Information </b>means 'personal information' as that term is defined in the
                    Privacy Act 2020.</p>

                <p><b>Output</b> means the output from the Sum Sure calculator or any other
                    output
                    made
                    available to
                    you through the Website (and includes any Output Data contained within
                    them).</p>


                <p><b>Output Data </b>means any data or property/geospatial information
                    contained within or provided with the Output or through the Website.</p>

                <p><b>Sum Sure calculator</b> means the Cordell Sum Sure calculator made
                    available through the Website, and which generates the Output.</p>

                <p><b>Terms</b> means these terms and conditions (as
                    amended from time to time in accordance with clause 3) and any terms
                    referred to in these terms and conditions.</p>

                <p><b>Third Party Information </b>means any Output Data,
                    other data, information, reports and other documentation or materials
                    sourced from a Third Party Supplier.</p>

                <p><b>Third Party Supplier</b> means any third party supplier who
                    directly or indirectly supplies us with data or information, where such data
                    or information is then contained within or provided with or through the Website.</p>

                <p><b>we</b> or <b>our</b> or similar derivatives, means CoreLogic NZ Limited.</p>

                <p><b>Website </b>means www.sumsure.co.nz or other website through which the Sum Sure
                    calculator is available.</p>
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Output </h2>


        <ol start="7">
            <li>
                The Output will have those features
                described on the Website and we will use commercially reasonable efforts to
                ensure that the Website is accessible in accordance with these Terms. You
                will
                only use the Website and the Output for your own personal use (and not for
                any
                commercial gain or benefit).
            </li>

            <li> The Sum Sure calculator takes the answers you
                input (or confirm, as applicable) and analyses them against construction
                industry data collated by us to generate an estimated rebuild cost of
                your property (the Estimate). Use of the Sum Sure
                calculator and the Estimate may not be appropriate for you and you will need
                to
                assess this given your knowledge of your property. You specifically
                acknowledge:
            </li>

            <ol type="a">
                <li>The Estimate is an indicative guide only and is not intended to
                    replace a professional valuation of improvement or replacement costs through an appropriately
                    qualified person (such as a valuer or quantity surveyor).
                </li>

                <li>Where any details on your property or
                    improvements are pre-populated by us, it is your responsibility to check
                    that
                    the pre-populated information is accurate and correct. If you do not
                    amend
                    it, we
                    will assume that
                    you have diligently checked that pre-populated information and agree
                    that it
                    is
                    accurate and correct.
                </li>
                <li>The Estimate is generated without the physical inspection of the subject property and
                    without taking into account or making any allowance for:
                </li>

                <ol type="i">
                    <li>beyond what you describe to us (or have accepted as pre-populated), property
                        features (including state of repair, improvements or renovations)
                    </li>

                    <li>local laws (including local planning requirements), except where expressly
                        identified;
                    </li>

                    <li>variations in construction costs due to matters such as the quality and
                        complexity of a replacement or repair item;
                    </li>

                    <li>the location of the property and market conditions in that location or wider
                        construction industry;
                    </li>

                    <li>fluctuations in availability of materials, or construction pricing or costing
                        information, and the delivery and lead time;
                    </li>

                    <li>statutory consents, including resource and building;</li>

                    <li>any engineering or other design requirements associated with the improvement
                        or replacement; or
                    </li>

                    <li>costs associated with content removal costs, landscaping costs, temporary
                        rental costs and the costs of rectifying damage caused during any demolition and
                        construction phases to the land or improvements to the property.
                    </li>
                </ol>

                <li>Construction costs vary regularly from
                    time to time and by region - construction industry data used in the Sum
                    Sure
                    calculator is updated regularly (as it becomes
                    available) but may not always be up to date where construction costs are
                    volatile, and likewise the Estimate may become out of date in a
                    relatively
                    short period of time. Also, for certain
                    regions, there may be less construction data available or a lower
                    quality of
                    information, and this may adversely affect the Estimate.
                </li>

                <li>You accept that any descriptions on the information
                    sought for the Sum Sure calculator are simply included
                    to assist you in completing the required information, and you have made the appropriate judgement call on whether you
                    have properly categorised the features of the improvements on your property.
                </li>

                <li>The Sum Sure calculator
                    does not make any allowances for any particular insurance policy
                    conditions
                    or
                    tolerances that may be contained in any insurance policy that you have
                    or
                    may
                    purchase in the future.
                </li>

                <li>
                    For properties in earth quake or flood zones, specific engineering advice should
                    be sought for a reliable estimate of any costs associated with the property.
                </li>

                <li>We do not accept any responsibility
                    for the answers you provide or the Third Party Information that the Sum
                    Sure
                    calculator receives, and as such, we will not be
                    liable for the accuracy of, your reliance of or use of the Sum Sure
                    calculator.
                </li>

                <li>While CoreLogic uses commercially reasonable efforts to ensure that the
                    Estimate is current, CoreLogic does not warrant the accuracy, currency or
                    completeness of the Estimate and to the fullest extent permitted by law excludes all
                    loss or damage howsoever arising (including through negligence) in connection with
                    the Estimate.
                </li>
            </ol>

            <li>A map showing property boundaries is
                indicative only and may not be complete or accurate.
                Any produced map is not intended or designed to replace the certificate of
                title or land survey information. If you
                need a certificate of title or land survey information then you can order
                these
                separately from us or LINZ.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}> Website (requirements, access and modifications)</h2>
        <ol start="10">
            <li>
                The software used in or by the Website
                may be updated from time to time and we will notify you where updates will
                materially affect your continued use of the Website.
            </li>

            <li>
                We will endeavour to make the Website available
                at all times. However, you acknowledge
                that the Website is provided over the internet and mobile networks and so
                the
                quality and availability of the Website is not guaranteed.
            </li>

            <li>
                We will not be obliged to provide
                access to the Website or any Output to the extent that we are prohibited
                from
                doing so by law or any agreements with any third party.
            </li>

            <li>
                The Sum Sure
                calculator, the Output and their features are dynamic in nature. There is no
                guarantee that they will stay the
                same. We may:
            </li>
            <ol type="a">

                <li>change, refine or otherwise modify the
                    features and functionality of the Website, Sum Sure calculator
                    or the Output; or
                </li>

                <li>cease, discontinue or temporarily suspend
                    the availability of the Website, Sum Sure calculator
                    or Output,<br />
                    by posting a notice of such update on the
                    Website. We will endeavour to give you
                    14 days' notice of such update but may give less notice where, in our reasonable
                    opinion, more urgent change is required.
                </li>
            </ol>

            <li>
                You permit us to check that the
                Website and the Output are being used in accordance with these Terms.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Charges and payment</h2>
        <ol start="15">

            <li>
                We will not charge you for accessing the
                Website, but your internet or mobile network provider may charge you for
                connection services when you download and/or use the Website.
            </li>

            <li>
                Mobile devices, communications links
                and systems compatibility (and all costs associated with such items) are
                your
                sole responsibility. You agree that we are
                not responsible for any of those charges.
            </li>
        </ol>
    </div>

    <div>
        <h2 tabIndex={0}>Copyright and other restrictions</h2>
        <ol start="17">


            <li>The contents of the Website, Sum Sure calculator and Output are protected by
                copyright and other
                intellectual property rights, and no part of the Website (or the Sum Sure
                calculator, or any Output) may be reproduced or
                adapted in whole or in part without our written consent. Our ability to
                grant
                consent is subject to us getting consent from our Third Party Suppliers. You
                will
                not assert any ownership or other
                rights in respect of any of the Website, Sum Sure
                calculator, the Output or any documentation, information or materials
                contained
                on or made available through the Website (together the Information).
            </li>

            <li>The Website and Information are made
                available for your own lawful internal use within New Zealand and you agree
                not
                to further disseminate Information supplied (whether for commercial gain or
                otherwise) and in particular not to publish Information by any means
                (including
                in writing or electronic dissemination) without our prior written consent.
                Our
                ability to grant consent is subject to us getting consent from our Third
                Party
                Suppliers.
            </li>

            <li>You agree not to use the Website or Information for Direct Marketing Activities or
                creating any mailing or address list of persons or properties; to sell the Website or
                Information to others; or to use or offer to use the Website or Information in any way
                that may encroach on the privacy of others or otherwise breach the Privacy Act
                2020.
            </li>

            <li>In accessing the Website, you will not (and
                will not allow or encourage any other person to):
            </li>
            <ol type="a">
                <li>decompile,
                    disassemble or otherwise reverse engineer all or any portion of the
                    Information
                    or the Website, including any source code, object code, algorithms,
                    methods or
                    techniques used or embodied therein;
                </li>

                <li>data
                    mine, scrape, crawl, create links back to, harvest or use any process or
                    processes that send automated queries to the Website;
                </li>

                <li>modify
                    or create any derivative works based upon any Information or make copies
                    of the
                    Website or any Information (except for the sole purpose of
                    back-up or disaster recovery);
                </li>

                <li>remove
                    or alter any copyright, trademark, logo or other proprietary notice or
                    label
                    appearing on or in the Website or any Information; or
                </li>

                <li>incorporate the Website or any Information
                    into any other materials, products or services.
                </li>
            </ol>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Customer Materials</h2>
        <ol start="21">
            <li>You grant us and each of our partners,
                affiliates, parent companies, related entities, successors, and assigns a
                worldwide, royalty-free, perpetual, irrevocable, assignable, non-exclusive
                right and licence to use, convert, reproduce, reformat, store, back-up,
                distribute,
                sell, transmit, perform, display (publicly or otherwise), adapt, make
                derivative works of and otherwise commercialise and exploit (with no
                obligation
                to account for profits) any Customer Materials either directly or in
                combination with other information or parties. You acknowledge that this
                clause 21
                is also for the benefit of our Third Party Supplier and may be
                enforced by it.
            </li>

            <li>
                Provided that you have not provided
                Customer Materials that you know:
            </li>
            <ol type="a">
                <li> you are not authorised to provide;</li>

                <li> are incorrect; or</li>

                <li> have been obtained in breach of any
                    law or obligation of confidentiality,
                </li>
            </ol>

            <p>we accept that if we and/or any of the
                abovementioned persons choose to exploit the abovementioned right and
                licence
                so granted by you, such commercialisation and exploitation is at our and/or
                the
                relevant licensee's own risk and as such and notwithstanding anything
                contained
                in these Terms, you have no liability for any loss, cost or expenses that we
                and/or any relevant licensee/Third Party Supplier may incur as a result of
                such
                commercialisation or exploitation. </p>

            <li>
                You confirm to us and our Third Party
                Supplier that you acknowledge that we sometimes use customer
                materials supplied by another customer in our products, including the Output
                and in this regard, we do not endorse, support, represent or guarantee the
                truthfulness, accuracy and reliability of the customer materials from
                another
                customer contained within the Website.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Creative Commons Licence </h2>
        <ol start="24">
            <li>We are a party to a
                worldwide, royalty-free, non-exclusive, Creative Commons Custom Licence
                under
                which we receive certain materials used in the Website.
            </li>

            <li>Such material is
                licensed under a Creative Commons Attribution 3.0 New Zealand Licence
                (<strong>Creative Commons Licence</strong>)
                details of which can be found at
                https://creativecommons.org/licenses/by/3.0/nz/legalcode. Such materials are not our property and are
                credited to the original author as shown in the metadata of the relevant
                material.
            </li>

            <li>We do not assert or
                imply any connection with sponsorship or endorsement by the original author
                of
                the
                use of such material.
            </li>

            <li>Such material
                available to you on the terms of the Creative Commons Licence and these
                Terms in
                no way imply that we have the right to sub-license the material to you or
                any
                third party.
            </li>

            <li>We do not impose
                any terms on the use of such material by you or any third party that alter
                or
                restrict the terms of the Creative Commons Licence or any rights granted
                under
                it or have the effect or intent of restricting the ability to exercise any
                such
                rights.
            </li>

            <li>We have not imposed
                any digital rights management technology on such material, any adaption of
                it
                or the material as incorporated with other separate works,
                that alter or restrict the terms of the Creative Commons Licence or any
                rights granted under it or has the effect or intent of restricting the
                ability
                to
                exercise those rights.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Confidentiality </h2>

        <ol start="30">
            <li>
                No Confidential Information may be
                disclosed by you to any person or entity except:
            </li>

            <ol type="a">
                <li>to the extent you are required to do
                    so by law; or
                </li>

                <li>to the extent you are required to do so
                    in connection with legal proceedings relating to these Terms.
                </li>
            </ol>
            <li>You must not use Confidential Information except for the purpose
                specified in
                clause 7.
            </li>

            <li>Clauses 30 and 31 do not apply to Excluded Information.
            </li>

            <li>You will take any action that is
                necessary to prevent or remedy any breach of your confidentiality
                obligations
                or other unauthorised disclosure of Confidential Information.
            </li>

            <li>You will not remove, alter, obscure or
                otherwise modify any trademark, copyright or other proprietary notice or
                legal
                disclaimer placed on or contained within the Confidential Information.
            </li>

            <li>You acknowledge that due to the unique
                nature of the Confidential Information, any breach by you of your
                obligations
                under these clauses 30 to 34 (both inclusive) would result in irreparable
                harm
                to us
                and our Third
                Party Suppliers for which there is no adequate remedy; and therefore, upon
                any
                such breach or threat thereof, we and our Third Party Suppliers will be
                entitled to injunctive and other appropriate equitable relief (without the
                necessity
                of proving damages, or posting bond or other security), in addition to
                whatever
                remedies we and our Third Party Suppliers may have at law.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Privacy</h2>
        <ol start="36">
            <li>
                The Privacy Act 2020 governs the collection, use, storage and disclosure of
                personal information. You are responsible for ensuring information is used within the
                requirements of that Act.
            </li>
            <li>
                You acknowledge that information
                transmitted over the internet is inherently insecure. We will not
                disclose your Personal Information to any third party unless authorised by
                you
                or your representative(s), including the authorisation provided in these
                Terms,
                or where compelled to do so by law. We confirm that we will act in
                accordance with the Privacy Act 2020.
            </li>
            <li>
                For further details on privacy, please
                read our privacy policy, which is available on the Website or
                CoreLogic’s website at www.corelogic.co.nz.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Limited warranties </h2>
        <ol start="39">
            <li>Except where you are using the Website in
                "trade" (as defined in the Consumer Guarantees Act 1993 (CGA)), nothing in
                these
                Terms is intended to have the effect of limiting or reducing your rights
                against
                us
                under the CGA. As a consumer, you are
                entitled to certain guarantees under the CGA.
                If we breach any of these guarantees, your rights of redress are set out
                in the CGA.
            </li>

            <li>Where you are using the Website in "trade" (as
                defined in the CGA), you agree that the provisions of the
                CGA will not apply to these Terms.
            </li>
            <li>Except for any guarantees which apply
                pursuant to clause 39, you specifically acknowledge that we provide no
                warranties or
                guarantees in respect of the Website other than those given in clause 7 of
                these
                Terms.
            </li>
            <li>In this regard, we (and our Third
                Party Suppliers) expressly disclaim all warranties that:
            </li>
            <ol type="a">
                <li>access to the Website will always be
                    immediate or uninterrupted;
                </li>
                <li>the Website or any material accessed
                    through the Website will be free from infection, viruses or destructive
                    code
                    and shall not be liable to you on any basis for any damage or loss
                    suffered
                    by
                    you as a result of the Website or any material accessed through the
                    Website
                    infecting or damaging your mobile device, computer equipment, software
                    or
                    any
                    other electronic device;
                </li>

                <li>the Website or Output will meet your
                    requirements;
                </li>

                <li>the Website will be error-free or that
                    errors or defects in the Website will be corrected, however we will,
                    where
                    practically possible,
                    endeavour to correct errors within a reasonable time;
                </li>

                <li>any Information will be accurate,
                    reliable or secure;
                </li>

                <li>the statistical methods on which the Sum Sure calculator is based use
                    appropriate or
                    accurate
                    assumptions, are fit for your particular purpose or are otherwise
                    suitable
                    for
                    your use; and
                </li>

                <li>the performance of the Sum Sure calculator will not be affected by data
                    entry
                    errors,
                    including incorrect entries, double entries or delayed entries, or
                    incorrect
                    or
                    untimely data supplied by Third Party Suppliers.
                </li>
            </ol>

            <li>You acknowledge and agree that to the
                extent that the Website contains any Third Party Information, we provide no
                warranties, representations or guarantees in respect of such Third Party
                Information (including any warranty or guarantee that the Third Party
                Information will be complete, accurate, free of errors, omissions and
                defects,
                up to date, not misleading or fit for purpose), except to the extent that
                any
                such warranty or guarantee implied by law cannot be excluded.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Liability and your indemnity</h2>
        <ol start="44">
            <li>If any Output does not comply with the
                relevant requirements in these Terms, we will (within a reasonable time
                period)
                either:
            </li>
            <ol type="a">
                <li>re-supply the Output to you; or</li>

                <li>refund to you any charges you have paid and any direct and
                    identifiable costs you have incurred (and outside of those excluded by
                    clause
                    45)in respect of the Output.
                </li>

                <p>This shall be your sole remedy in
                    respect of any defect in the Output, your use of the Website or any
                    breach
                    of
                    these Terms by us, or our negligence (or that of our Third Party
                    Suppliers),
                    except to the extent we are unable to exclude its liability by law (in
                    which
                    case, our liability will be limited to the amounts referred to in
                    clause 43(b) above.</p>
            </ol>
            <li>Without detracting from clause 44 , in no circumstances will any party
                (including
                when it comes to us, our Third Party Suppliers) be liable for:
            </li>
            <ol type="a">
                <li>any special, consequential, indirect,
                    incidental, punitive or exemplary damages of any kind; or
                </li>

                <li>damages for any loss of profits or
                    revenue, loss resulting from interruption of business or loss of use or
                    data,
                    arising out of or relating to the Website or the Output, however caused,
                    even
                    if we or our Third Party Suppliers (as applicable) had been advised of
                    or
                    should have known of the possibility of such loss,
                </li>
                <p>except in relation to unlawful use of our (or our Third Party Suppliers')
                    intellectual property or Confidential Information by you or any
                    intellectual
                    property infringement claims from third parties around your Customer
                    Materials.
                </p>
            </ol>
            <li>You agree to reimburse us for any
                loss, liability, cost, fee or damage that we (and/or our Third Party
                Suppliers)
                and each of their related entities or partners (or any of their employees,
                directors or officers) incur as a direct result of:
            </li>
            <ol type="a">
                <li>your failure to comply with these
                    Terms, any relevant Third Party Suppliers' terms of use or any law;
                </li>

                <li>your negligence; or</li>

                <li>any content you submit through the Website or your reliance on the
                    content submitted by any Third Party Supplier.
                </li>
            </ol>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Suspension and termination</h2>

        <ol start="47">
            <li>We may terminate (as applicable) your
                right to access or use the Website at any time if you breach any material
                term
                or condition in these Terms or if you abuse the Website, the Sum Sure
                calculator
                or
                the Output.
                Examples include:
            </li>
            <ol type="a">
                <li>any unlawful or unauthorised use of our
                    (or our Third Party Supplier's) intellectual property; or
                </li>

                <li>in the case of any intellectual property infringement claim from a
                    third party around your Customer Materials.
                </li>
            </ol>

            <li>In the event of termination under
                clause 47 above, despite such termination, all copyright,
                data privacy and use of information obligations will remain in effect after
                termination in respect of all information, copies and compilations retained
                by
                you.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Notices </h2>
        <ol start="49">
            <li>Any queries you have regarding the
                Website should be provided in writing to us via CoreLogic
                Customer Support Team Manager, PO Box 4072, Wellington, or email
                info@corelogic.co.nz .
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Third party benefit </h2>
        <ol start="50">
            <li>These Terms are not only expressed for
                our benefit but also for the benefit of our Third Party Suppliers (as it
                relates to matters that affect them).</li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={0}>Disclaimer</h2>
        <p>
            The Cordell Sum Sure calculator takes the answers you input (or confirm, as
            applicable) and analyses them against the construction industry data collated by us
            to generate an estimated reconstruction cost of the improvements on your property.
            Use of the Cordell Sum Sure calculator and any estimated reconstruction cost
            provided by it may not be appropriate for you and you will need to assess this given
            your knowledge of your property. The estimated reconstruction cost is provided
            subject to the full set of terms and conditions accepted by you prior to your use of
            the calculator, available here.
        </p>
    </div>
    &nbsp;
    <div>
        <h2 tabIndex={0}>Currency</h2>
        <p>
            Version 3 of these Cordell Sum Sure Terms of Use are current as and from 1 July
            2018.
        </p>
        <p>© 2019 CoreLogic NZ Limited. All rights reserved.</p>
    </div>
</div>);
const CceAuTermsOfUse = (<div style={{ width: '100%', overflowX: 'hidden', color: '#000000' }}>
    <div>
        <h2>Introduction</h2>
        <ol start="1">
            <li>Please read these Terms carefully. These Terms govern your access to and use
                of
                the Website and the
                Output.
            </li>
            <li>By using the Website you agree to be bound by these Terms. This agreement
                takes
                effect from the
                point you first access the Website. If you do not accept any of these Terms,
                then you must cease
                using the Website.
            </li>
            <li>We may change or update these Terms from time to time by posting a notice of
                such updates on the
                Website. You are required to accept any changes or updates in order to
                continue
                to access and use
                the Website. If you do not accept any change or update to these Terms then
                you
                must cease using the
                Website
            </li>
            <li>You may not transfer any of your rights or obligations under these Terms to
                any
                person.
            </li>
            <li>The validity, interpretation and enforcement of these Terms shall be
                governed by
                the laws of New
                South Wales.
            </li>
        </ol>

    </div>
    <div>
        <h2>Definitions</h2>
        <p>In these Terms, unless the context otherwise requires:</p>

        <b>Confidential Information</b>
        &nbsp;means all confidential, non-public or proprietary information, regardless of
        how
        the information is
        stored, which is delivered to you before, on or after being bound by these
        Terms, relating to the
        Website, the Commercial Estimator or the Output including such information
        which
        is (i) by its nature
        is confidential; (ii) is identified by either Party as confidential; or
        (iii)
        the other party knows,
        or ought to know, is confidential, but does not include the Excluded
        Information.&nbsp;<br /><br />

        <b>Creative Commons Licence</b>
        &nbsp;has the meaning given to it in clause 24.&nbsp;<br /><br />
        <b>Customer Materials</b>
        &nbsp;means any data, information, content, photographs, metadata and/or other
        materials you make
        available to us (or otherwise obtained by us from you (such as from your use
        of
        the Website) or that
        we possess of yours) directly or indirectly, from time to time.&nbsp;<br /><br />

        <b>Direct Marketing</b>
        &nbsp;means the use and/or disclosure of Personal Information to identify, target,
        segment or filter and
        then directly communicate to an identified individual or group of
        individuals to
        market or promote
        goods or services to (whether or not addressed to that individual by name)
        by
        means, normally
        supported by a database, which uses one or more advertising media and
        includes,
        telemarketing,
        email, SMS, targeted online advertising (including Facebook and Google Ads),
        postal mail and list
        brokering.&nbsp;<br /><br />
        <b>Estimate</b>
        &nbsp;has the meaning given to it in clause 7.&nbsp;<br /><br />
        <b>Excluded Information</b>
        &nbsp;means information to the extent which:
        <ol type={'a'}>
            <li>it is in or becomes part of the public domain other than through breach
                of these Terms or an
                obligation of confidence owed to any person;
            </li>
            <li>you can prove, by contemporaneous written documentation, was already
                known to you at the
                time of disclosure by us (unless such knowledge arose from disclosure of
                information in
                breach of an obligation of confidentiality); or
            </li>
            <li>it was independently developed by the you without reference to the
                Confidential Information
                disclosed by us; or
            </li>
            <li>you acquire from a source other than us where such source is entitled to
                disclose it.
            </li>
        </ol>
        <b>Information</b>
        &nbsp;has the meaning given to it in clause 15.&nbsp;<br /><br />
        <b>Land Titles Provider</b>
        &nbsp;means providers of government Torrens Title Register information for land
        and
        property in each State
        and Territory.
        &nbsp;<br /><br />
        <b>Personal Information</b>
        &nbsp;means 'personal information' as that term is defined in the relevant and
        applicable Privacy Laws,
        and which a Party discloses to or receives from the other Party in
        connection
        with this Agreement
        (including information forming part of a database).
        &nbsp;<br /><br />
        <b>Privacy Laws</b>
        &nbsp;means any relevant or applicable privacy or data protection Laws relating to
        the
        collection, use,
        processing, disclosure, storage or granting of access to the Personal
        Information which may be
        amended and in force from time to time and may include the Privacy Act 1988
        (Cth) and the Australian
        Privacy Principles which form part of the Act, Do Not Call Register Act 2006
        (Cth) and the Spam Act
        2003 (Cth); as well as any other State or Territory acts and regulations
        which
        regulate the use of
        Personal Information in each respective State or Territory any other binding
        requirement under an
        Australian industry code or policy relating to the handling of Personal
        Information.
        &nbsp;<br /><br />
        <b>Output</b>
        &nbsp;means the output from the Commercial Estimator or any other output made
        available
        to you through the
        Website (and includes any Output Data contained within them).
        &nbsp;<br /><br />
        <b>Output Data</b>
        &nbsp;means any data or property/geospatial information contained within or
        provided
        with the Output or
        through the Website.
        &nbsp;<br /><br />
        <b>Commercial Estimator</b>
        &nbsp;means the Cordell Commercial Estimator made available through the Website,
        and
        which generates the
        Output.
        &nbsp;<br /><br />
        <b>Terms</b>
        &nbsp;means these terms and conditions (as amended from time to time in accordance
        with clause 3) and any
        terms referred to in these terms and conditions.
        &nbsp;<br /><br />
        <b>Third Party Information</b>
        &nbsp;means any Output Data, other data, information, reports and other
        documentation
        or materials sourced
        from a Third Party Supplier.
        &nbsp;<br /><br />
        <b>Third Party Supplier</b>
        &nbsp;means any third party supplier who directly or indirectly supplies us with
        data or information, where such data or information is then contained within or
        provided with or through the Website.
        &nbsp;<br /><br />
        <b>We</b> or <b>our</b> or similar derivatives,
        &nbsp;means RP Data Pty Ltd trading as CoreLogic Asia Pacific.&nbsp;<br /><br />
        <b>Website</b>
        &nbsp;means the URL through which the Commercial Estimator is available to you in accordance with this Agreement.
        &nbsp;

    </div>
    <div>
        &nbsp;
        <h2>Output</h2>
        <ol start="6">
            <li>The Output will have those features described on the Website and we will use
                commercially reasonable
                efforts to ensure that the Website is accessible in accordance with these
                Terms. You will only use
                the Website and the Output for your own personal use (and not for any
                commercial gain or benefit).
            </li>
            <li>The Commercial Estimator takes the answers you input (or confirm, as
                applicable)
                and analyses them
                against construction industry data collated by us to generate an estimated
                reconstruction cost of
                the improvements on your property (the Estimate). Use of the Commercial
                Estimator
                and the Estimate
                may not be appropriate for you and you will need to assess the suitability
                of
                this Estimate given
                your knowledge of your property. You specifically acknowledge:
                <ol type={'a'}>
                    <li>The Estimate is not intended to replace a professional valuation
                        through
                        a person
                        appropriately qualified to undertake a valuation of improvement
                        replacement costs (such as a
                        valuer or quantity surveyor).
                    </li>
                    <li>Where any details on your property or improvements are pre-populated
                        by
                        us, it is your
                        responsibility to check that the pre-populated information is
                        accurate
                        and correct. If you
                        do not amend it, we will assume that you have diligently checked
                        that
                        pre-populated
                        information and agree that it is accurate and correct.
                    </li>
                    <li>No physical inspection or reference to building features beyond what
                        you
                        describe to us (or
                        have accepted as pre-populated) have been used in generating the
                        Estimate. Except where
                        expressly identified, no local laws (including local planning
                        requirements) have been taken
                        into account in generating the Estimate.
                    </li>
                    <li>Construction costs vary regularly from time to time and by region –
                        construction industry
                        data used in the Commercial Estimator is updated regularly (as it
                        becomes
                        available) but may
                        not always be up to date where construction costs are volatile, and
                        likewise the Estimate
                        may become out of date in a relatively short period of time. Also,
                        for
                        certain regions,
                        there may be less construction data available or a lower quality of
                        information, and this
                        may adversely affect the Estimate.
                    </li>
                    <li>Any descriptions about the information requested for the Commercial
                        Estimator are simply
                        included to assist you in completing the required information.
                    </li>
                    <li>The Commercial Estimator does not make any allowances for any
                        particular
                        insurance policy
                        conditions or tolerances that may be contained in any insurance
                        policy
                        that you have or may
                        purchase in the future.
                    </li>
                    <li>We do not accept any responsibility for the answers you provide or
                        the
                        Third Party
                        Information that the Commercial Estimator receives, and as such, we
                        will
                        not be liable for
                        the accuracy of, your reliance of or use of the Commercial Estimator.
                    </li>
                    <li>A map showing property boundaries is indicative only and may not be
                        complete or accurate. Any
                        produced map is not intended or designed to replace the certificate
                        of
                        title or land survey
                        information. If you need a certificate of title or land survey
                        information then you can order these
                        separately from us or a Land Titles Provider.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2>Website (requirements, access and modifications)</h2>
        <ol start="8">
            <li>We will endeavour to establish, maintain and enforce security measures
                within the Website to a standard of good industry practice.
            </li>
            <li>We will endeavour to make the Website available at all times. However, you
                acknowledge that the
                Website is provided over the internet and mobile networks and so the quality
                and
                availability of the
                Website is not guaranteed.
            </li>
            <li>We will not be obliged to provide access to the Website or any Output to the
                extent that we are
                prohibited from doing so by law or any agreements with any third party.
            </li>
            <li>The Commercial Estimator, the Output and their features are dynamic in
                nature.
                There is no guarantee
                that they will stay the same. We may:
                <ol type={'a'}>
                    <li>change, refine or otherwise modify the features and functionality of
                        the
                        Website, Commercial
                        Estimator or the Output; or
                    </li>
                    <li>cease, discontinue or temporarily suspend the availability of the
                        Website, Commercial
                        Estimator or Output.
                    </li>
                </ol>
            </li>
            <li>You permit us to check that the Website and the Output are being used in
                accordance with these
                Terms.
            </li>
        </ol>
    </div>
    <div>
        <h2>Charges and payment</h2>
        <ol start="13">
            <li>We will not charge you for accessing the Website, but your internet or
                mobile
                network provider may
                charge you for connection services when you download and/or use the Website.
            </li>
            <li>Mobile devices, communications links and systems compatibility (and all
                costs
                associated with such
                items) are your sole responsibility. You agree that we are not responsible
                for
                any of those charges.
            </li>
        </ol>
    </div>
    <div>
        <h2>Copyright and other restrictions</h2>
        <ol start="15">
            <li>The contents of the Website, Commercial Estimator and Output are protected by
                copyright and other
                intellectual property rights, and no part of the Website (or the Commercial
                Estimator, or any Output)
                may be reproduced or adapted in whole or in part without our written
                consent.
                Our ability to grant
                consent is subject to us getting consent from our Third Party Suppliers. You
                will not assert any
                ownership or other rights in respect of any of the Website, Commercial
                Estimator,
                the Output or any
                documentation, information or materials contained on or made available
                through
                the Website (together
                the <b>Information</b>).
            </li>
            <li>The Website and Information are made available for your own lawful internal
                use
                within Australia and
                you agree not to further disseminate Information supplied (whether for
                commercial gain or otherwise)
                and in particular not to publish Information by any means (including in
                writing
                or electronic
                dissemination) without our prior written consent. Our ability to grant
                consent
                is subject to us
                getting consent from our Third Party Suppliers.
            </li>
            <li>You agree not to use the Website or Information in creating any mailing or
                address list of persons
                or properties, to use the Website or Information for Direct Marketing
                activities, to sell the
                Website or Information to others, or to use or offer to use the Website or
                Information in any way
                that may encroach on the privacy of others or otherwise breach any Privacy
                Laws.
            </li>
            <li>In accessing the Website, you will not (and will not allow or encourage any
                other person to):
                <ol type={'a'}>
                    <li>decompile, disassemble or otherwise reverse engineer all or any
                        portion
                        of the Information
                        or the Website, including any source code, object code, algorithms,
                        methods or techniques
                        used or embodied therein;
                    </li>
                    <li>data mine, scrape, crawl, create links back to, harvest or use any
                        process or processes that
                        send automated queries to the Website;
                    </li>
                    <li>modify or create any derivative works based upon any Information or
                        make
                        copies of the
                        Website or any Information (except for the sole purpose of back-up
                        or
                        disaster recovery);
                    </li>
                    <li>remove or alter any copyright, trademark, logo or other proprietary
                        notice or label
                        appearing on or in the Website or any Information; or
                    </li>
                    <li>incorporate the Website or any Information into any other materials,
                        products or services.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2>Customer Materials</h2>
        <ol start="19">
            <li>You grant us and each of our partners, affiliates, parent companies, related
                entities, successors,
                and assigns a worldwide, royalty-free, perpetual, irrevocable, assignable,
                non-exclusive right and
                licence to use, convert, reproduce, reformat, store, back-up, distribute,
                sell,
                transmit, perform,
                display (publicly or otherwise), adapt, make derivative works of and
                otherwise
                commercialise and
                exploit (with no obligation to account for profits) any Customer Materials
                either directly or in
                combination with other information or parties. You acknowledge that this
                clause
                19 is also for the
                benefit of our Third Party Supplier and may be enforced by it.
            </li>
            <li>You must not provide Customer Materials that you know:
                <ol type={'a'}>
                    <li>you are not authorised to provide;</li>
                    <li>are incorrect; or</li>
                    <li>have been obtained in breach of any law or obligation of
                        confidentiality.
                    </li>
                </ol>
            </li>
            <li>Subject to your compliance with clause 20 above, we accept that if we and/or
                any
                of the
                above mentioned persons choose to exploit the above mentioned right and
                licence so
                granted by you,
                such commercialisation and exploitation is at our and/or the relevant
                licensee's
                own risk and as
                such and notwithstanding anything contained in these Terms, you have no
                liability for any loss, cost
                or expenses that we and/or any relevant licensee/Third Party Supplier may
                incur
                as a result of such
                commercialisation or exploitation.
            </li>
            <li>You confirm to us and our Third Party Supplier that:</li>
            <ol type={'a'}>
                <li>you acknowledge that we sometimes use customer materials supplied by
                    another customer in our
                    products, including the Output and in this regard, we do not
                    endorse,
                    support, represent or
                    guarantee the truthfulness, accuracy and reliability of the customer
                    materials from another
                    customer contained within the Website
                </li>
            </ol>

        </ol>
    </div>
    <div>
        <h2>Creative Commons Licence</h2>
        <ol start="23">

            <li>We are a party to a worldwide, royalty-free, non-exclusive, Creative Commons
                Custom Licence under which we receive certain materials used in the Website.
            </li>
            <li>Such material is licensed to us and you under various Creative Commons licenses details of which can be found at <a tabIndex={0} target="_blank"
                href="https://www.corelogic.com.au/ThirdPartyRestrictions"
                rel="noopener noreferrer"
            >https://www.corelogic.com.au/ThirdPartyRestrictions</a> (Creative
                Commons Licence). Such materials are not our property and are
                credited to the original author as shown in the metadata of the relevant
                material.
            </li>
        </ol>
    </div>
    <div>
        <h2>Confidentiality</h2>
        <ol start="25">
            <li>No Confidential Information may be disclosed by you to any person or entity
                except:
            </li>
            <ol type={'a'}>
                <li>to the extent you are required to do so by law; or</li>
                <li>to the extent you are required to do so in connection with legal
                    proceedings relating to
                    these Terms.
                </li>
            </ol>
            <li>You must not use Confidential Information except for the purpose specified
                in
                clause 6.
            </li>

            <li>You will take any action that is necessary to prevent or remedy any breach
                of
                your confidentiality
                obligations or other unauthorised disclosure of Confidential Information.
            </li>

            <li>You will not remove, alter, obscure or otherwise modify any trademark,
                copyright
                or other
                proprietary notice or legal disclaimer placed on or contained within the
                Confidential Information.
            </li>

            <li>You acknowledge that due to the unique nature of the Confidential
                Information,
                any breach by you of
                your obligations under these clauses 25 to 28 (inclusive) would result in
                irreparable harm to us and
                our Third Party Suppliers for which there is no adequate remedy; and
                therefore,
                upon any such breach
                or threat thereof, we and our Third Party Suppliers will be entitled to
                injunctive and other
                appropriate equitable relief (without the necessity of proving damages, or
                posting bond or other
                security), in addition to whatever remedies we and our Third Party Suppliers
                may
                have at law.
            </li>
        </ol>
    </div>
    <div>
        <h2>Privacy</h2>
        <ol start="30">
            <li>We are bound by Privacy Laws and have developed a Privacy Policy, available
                on
                our Website.
            </li>

            <li>You acknowledge that information transmitted over the internet is inherently
                insecure. To the extent
                that the Website or Information contains Personal Information, You must:
                <ol type={'a'}>
                    <li>comply with all applicable Privacy Laws;</li>
                    <li>only use Personal Information for the purpose for which it was
                        disclosed;
                    </li>
                    <li>notify CoreLogic immediately (by email to privacy@corelogic.com.au)
                        of
                        any privacy
                        complaints or events which may cause any applicable Privacy Law to
                        be
                        breached.
                    </li>
                </ol>
            </li>
            <li>We will:
                <ol type={'a'}>
                    <li>comply with all applicable Privacy Laws and ensure that its
                        employees,
                        agents and
                        contractors do so;
                    </li>
                    <li>subject to its rights under this Licence, only use Personal
                        Information
                        that You disclose to
                        Us for the purpose for which it was disclosed.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2>Limited warranties</h2>
        <ol start="33">
            <li>You may be entitled to certain guarantees under the Competition and Consumer
                Act
                2010 (Cth).
            </li>

            <li>Except for any guarantees which cannot be excluded by law pursuant to the
                Competition and Consumer
                Act 2010 (Cth), you acknowledge that we provide no warranties or guarantees
                in
                respect of the
                Website other than those given in clause 6 of these Terms and all other
                warranties are excluded.
            </li>

            <li>We (and out Third Party Suppliers) expressly disclaim all warranties that:
                <ol type={'a'}>
                    <li>access to the Website will always be immediate or uninterrupted;
                    </li>
                    <li>the Website or Output will meet your requirements or will be
                        compatible with or suitable for use with your systems, browsers
                        or applications;</li>
                    <li>the Website will be error-free or that errors or defects in the
                        Website
                        will be corrected,
                        however we will, where practically possible, endeavour to correct
                        errors
                        within a reasonable
                        time;
                    </li>
                    <li>any Information will be accurate, reliable or correct;</li>
                    <li>the statistical methods on which the Commercial Estimator is based
                        use
                        appropriate or
                        accurate assumptions, are fit for your particular purpose or are
                        otherwise suitable for your
                        use; and
                    </li>
                    <li>the performance of the Commercial Estimator will not be affected by
                        data
                        entry errors,
                        including incorrect entries, double entries or delayed entries, or
                        incorrect or untimely
                        data supplied by Third Party Suppliers.
                    </li>
                </ol>
            </li>
        </ol>
        <ol start="36">
            <li>You acknowledge and agree that to the extent that the Website contains any
                Third
                Party Information,
                we provide no warranties, representations or guarantees in respect of such
                Third
                Party Information
                (including any warranty or guarantee that the Third Party Information will
                be
                complete, accurate,
                free of errors, omissions and defects, up to date, not misleading or fit for
                purpose), except to the
                extent that any such warranty or guarantee implied by law cannot be
                excluded.
            </li>
        </ol>
    </div>
    <div>
        <h2>Liability and your indemnity</h2>
        <ol start="37">
            <li>If any Output does not comply with the relevant requirements in these Terms,
                we
                will (within a
                reasonable time period) either:
                <ol type={'a'}>
                    <li>re-supply the Output to you; or</li>
                    <li>refund to you any charges you have paid and any direct and
                        identifiable
                        costs you have
                        incurred (other than those excluded by clause 38) in respect of the
                        Output, and
                    </li>
                </ol>
                this shall be your sole remedy in respect of any defect in the Output, your
                use of the Website or
                any breach of these Terms by us, or our negligence (or that of our Third
                Party Suppliers), except
                to the extent we are unable to exclude its liability by law (in which case,
                our
                liability will be limited to the amounts referred to in clause 37(b) above.

            </li>

            <li>In no circumstances will any party (including when it comes to
                us, our Third Party Suppliers) be liable for:
                <ol type={'a'}>
                    <li>any special, consequential, indirect, incidental, punitive or
                        exemplary
                        damages of any kind;
                        or
                    </li>
                    <li>damages for any loss of profits or revenue, loss resulting from
                        interruption of business or
                        loss of use or data, arising out of or relating to the Website or
                        the
                        Output, however
                        caused, even if <b>we</b> or <b>our</b> Third Party Suppliers (as applicable) had
                        been
                        advised of or
                        should have known of the possibility of such loss,
                    </li>
                </ol>
                <p>
                    except in relation to unlawful use of our (or our Third Party
                    Suppliers')
                    intellectual
                    property or Confidential Information by you or any intellectual property
                    infringement claims
                    from third parties around your Customer Materials.
                </p>
            </li>

            <li>You agree to reimburse us for any loss, liability, cost, fee or damage that
                we
                (and/or our Third
                Party Suppliers) and each of their related entities or partners (or any of
                their
                employees,
                directors or officers) incur as a direct result of:
                <ol type={'a'}>
                    <li>your failure to comply with these Terms, or
                        any law;
                    </li>                            <li>any content you submit through the Website.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2>Suspension and termination</h2>
        <ol start="40">
            <li>We may terminate (as applicable) your right to access or use the Website at
                any
                time if you breach
                any material term or condition in these Terms or if you abuse the Website,
                the
                Commercial Estimator
                or the Output. Examples include:
                <ol type={'a'}>
                    <li>any unlawful or unauthorised use of our (or our Third Party
                        Suppliers')
                        intellectual
                        property;
                        or
                    </li>
                    <li>in the case of any intellectual property infringement claim from a
                        third
                        party around your
                        Customer Materials.
                    </li>
                </ol>
            </li>
            <li>In the event of termination under clause 40 above, despite such termination,
                all
                copyright, data
                privacy and use of information obligations will remain in effect after
                termination in respect of all
                information, copies and compilations retained by you.
            </li>
        </ol>
    </div>
    <div>
        <h2>Notices</h2>
        <ol start="42">
            <li>Any queries you have regarding the Website should be addressed to your
                insurance
                supplier through
                whom you accessed the link for this Website.
            </li>
        </ol>
    </div>
    <div>
        <h2>Third party benefit</h2>

        <ol start="43">

            <li>These Terms are not only expressed for our benefit but also for the benefit
                of
                our Third Party
                Suppliers (as it relates to matters that affect them).
            </li>
        </ol>
    </div>
    <div>
        <h2>CoreLogic Disclaimer</h2>
        <p>The Cordell Commercial Estimator takes the answers you input (or confirm, as
            applicable) and analyses them
            against construction industry data collated by CoreLogic to generate an
            estimated
            rebuild cost of the
            improvements on your property. Use of the Cordell Commercial Estimator and any
            estimated reconstruction cost provided by it may not be appropriate for you and you will need to assess
            the
            suitability of this
            estimate given your knowledge of your property. A number of qualifications apply
            to
            the estimated
            rebuild cost, which are set out in these Cordell Commercial Terms of Use.</p>
    </div>
    &nbsp;
    <div>
        <h2>Australian State Disclaimers</h2>
        <p>Where Output Data (or any reports supplied by CoreLogic) have been compiled with
            data
            supplied under
            licence from an Australian State government supplier, the following State
            disclaimers apply in
            conjunction with CoreLogic Disclaimers:</p>
    </div>
    &nbsp;
    <div>
        <h2>Queensland Data</h2>
        <p>Based on or contains data provided by the State of Queensland (Department of
            Natural
            Resources and Mines)
            2019. In consideration of the State permitting use of this data you acknowledge
            and
            agree that the State
            gives no warranty in relation to the data (including accuracy, reliability,
            completeness, currency or
            suitability) and accepts no liability (including without limitation, liability
            in
            negligence) for any
            loss, damage or costs (including consequential damage) relating to any use of
            the
            data. Data must not be
            used for direct marketing or be used in breach of the privacy laws.</p>
    </div>
    &nbsp;
    <div>
        <h2>South Australian Data</h2>
        <p>© 2019 Copyright in this information belongs to the South Australian Government
            and
            the South Australian
            Government does not accept any responsibility for the accuracy or completeness
            of
            the information or its
            suitability for purpose.</p>
    </div>
    &nbsp;
    <div>
        <h2>New South Wales Data</h2>
        <p>Contains property sales information provided under licence from the Land and
            Property
            Information
            ("LPI"). RP Data Pty Ltd trading as CoreLogic is authorised as a Property Sales
            Information provider by
            the LPI.</p>
    </div>
    &nbsp;
    <div>
        <h2>Victorian Data</h2>
        <p>The State of Victoria owns the copyright in the Property Sales Data and
            reproduction
            of that data in any
            way without the consent of the State of Victoria will constitute a breach of the
            Copyright Act 1968
            (Cth). The State of Victoria does not warrant the accuracy or completeness of
            the
            Property Sales Data
            and any person using or relying upon such information does so on the basis that
            the
            State of Victoria
            accepts no responsibility or liability whatsoever for any errors, faults,
            defects or
            omissions in the
            information supplied.</p>
    </div>
    &nbsp;
    <div>
        <h2>Western Australian Data</h2>
        <p>Based on information provided by and with the permission of the Western
            Australian
            Land Information
            Authority (2019) trading as Landgate.</p>
    </div>
    &nbsp;
    <div>
        <h2>Australian Capital Territory Data</h2>
        <p>The Territory Data is the property of the Australian Capital Territory. No part
            of it
            may in any form or
            by any means (electronic, mechanical, microcopying, photocopying, recording or
            otherwise) be reproduced,
            stored in a retrieval system or transmitted without prior written permission.
            Enquiries should be
            directed to: Director, Customer Services ACT Planning and Land Authority GPO Box
            1908 Canberra ACT
            2601.</p>
    </div>

    <h2>Tasmanian Data</h2>
    <p>This product incorporates data that is copyright owned by the Crown in Right of
        Tasmania. The data has
        been used in the product with the permission of the Crown in Right of Tasmania.
        The
        Crown in Right of
        Tasmania and its employees and agents:</p>

    <ol type={'a'}>
        <li>give no warranty regarding the data's accuracy, completeness, currency
            or
            suitability for any
            particular purpose; and
        </li>
        <li>do not accept liability howsoever arising, including but not limited to
            negligence for any loss
            resulting from the use of or reliance upon the data.
        </li>
    </ol>

    <p>Base data from the LIST © State of Tasmania https://www.thelist.tas.gov.au</p>

    <h2>Currency</h2>
    <p>Version 2 of these Cordell Commercial Terms of Use are current as and from 1 March
        2019.</p>
    <p>© 2019 RP Data Pty Ltd trading as CoreLogic Asia Pacific. All rights
        reserved.</p>

</div>);

const CceNzTermsOfUse = (<div style={{ width: '100%', overflowX: 'hidden', color: '#000000' }}>
    <div>
        <h2 class="cce-terms-h2">Introduction</h2>
        <ol class="cce-nz-terms-list">
            <li>Please read these Terms carefully. These Terms govern your access to and use
                of the
                Website and the Output.
            </li>
            <li>By using the Website you agree to be bound by these Terms. This agreement
                takes
                effect from the point you first access the Website. If you do not accept any
                of these
                Terms, then you must cease using the Website.
            </li>
            <li>We may change or update these Terms from time to time by posting a notice of
                such
                updates on the Website. You are required to accept any changes or updates in
                order
                to continue to access and use the Website. If you do not accept any change
                or update
                to these Terms then you must cease using the Website.
            </li>
            <li>You may not transfer any of your rights or obligations under these Terms to
                any
                person.
            </li>
            <li>The validity, interpretation and enforcement of these Terms shall be
                governed by
                the laws of New Zealand.
            </li>
        </ol>
    </div>
    <div>
        <ol start="1" class="main-multi-level">
            <li><b>Definitions and Interpretations</b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li> <div class="multi-level-div">In this agreement unless the context otherwise requires, the following words will bear the meaning set out here under:&nbsp;
                        <p><b>Administrator </b>means the person(s) having the allocated administrator log in for the
                            Customer and having such administration rights as granted from time to time by CoreLogic.</p>

                        <p><b>Agreement </b>means either (as the context permits):</p>

                        <ol class="cus-alpha" type={'a'}>
                            <li>&nbsp;&nbsp;the Application and these terms; or</li>
                            <li>&nbsp;&nbsp;  a Master Licence Agreement and Product Schedule detailing the Subscription Plan</li>
                        </ol>

                        <p><b>Application </b>means the Application to Access CoreLogic Products form signed by the Customer and CoreLogic,
                            and includes, any relevant amendments made from time to time.</p>

                        <p><b>Building Insurance Policy </b>means a Commercial Property insurance policy made available
                            by the Customer.</p>

                        <p><b>Commercial Property </b>means a commercial, industrial, retail or rural
                            property in New Zealand which:</p>
                        <ol class="cus-alpha" type={'a'}>
                            <li>&nbsp;&nbsp;has an insurable dwelling or building on it; and
                            </li>

                            <li>&nbsp;&nbsp;a Cordell Rebuild Estimate is available.

                                <p><b>Construction Data </b>means certain Product Data relating to the construction industry,
                                    including construction
                                    cost and replacement construction costs information.</p>

                                <p><b>Cordell Rebuild Estimate </b>means a statistically derived estimate of the Rebuild Cost
                                    of the improvements on a subject Commercial Property which is generated without the physical inspection
                                    of the subject Commercial Property by a computer driven mathematical Model which utilises:</p>
                            </li>
                            <li>&nbsp;&nbsp; available Construction Data; and
                            </li>
                            <li>&nbsp;&nbsp;data (including Product Data) input or confirmed by the Permitted User in the Cordell
                                Commercial Estimator.
                            </li>
                        </ol>

                        <p><b>Confidential Information </b>means all confidential, non-public or proprietary information,
                            regardless of how the information is stored, which is delivered to the Customer before, on or
                            after the date of this Agreement, relating to the valuation products, product information services
                            or the business, technology or other affairs of CoreLogic, any data (including Product Data),
                            valuation and market share analyses, valuation models and tools, indices, programs or algorithms.</p>

                        <p><b>Customer </b>means the customer recorded in the Application, and as the context permits,
                            includes Enabled Users.</p>


                        <p><b>Customer Materials </b>means any and all data, information, content, photographs
                            and other materials provided or that may be supplied to CoreLogic by the Customer (or otherwise
                            obtained by CoreLogic from the Customer), directly or indirectly (including through an Enabled User),
                            from time to time</p>

                        <p><b>Customer Supplied Personal Information </b>means first name, surname, email
                            and mobile phone data provided by the Customer to CoreLogic as part of the Customer Materials
                            (but for clarity, does not include any data provided to CoreLogic by any other third party).</p>

                        <p><b>Data Profiling </b>means the process of examining data available from an existing information
                            source (e.g. a database or a file) and collecting statistics or informative summaries about that data.</p>

                        <p><b>Effective Date </b>means the date on which the Customer’s logins and Passwords are created
                            (or are able to be created) and the Customer is granted access to the Website.</p>

                        <p><b>Enabled User </b>means those staff members in the Customer’s company
                            or organisation who are authorised to access the Product on CoreLogic’s Website and
                            have been allocated a user name and Password for that purpose.</p>

                        <p><b>End User </b>means the person who is supplied (by the Customer or an Enabled User)
                            a Product or a Product derivative for that person’s own personal (non-commercial)
                            use as part of the Customer’s Internal Business Needs.</p>

                        <p><b>Excluded Information </b>means Confidential Information which: </p>
                        <ol class="cus-alpha" type={'a'}>
                            <li><div class="multi-level-div">is in or becomes part of the public domain other
                                than through breach of this Agreement or an obligation of confidence
                                owed to CoreLogic;</div>
                            </li>
                            <li><div class="multi-level-div">the Customer can prove, by contemporaneous written documentation,
                                was already known to it at the time of disclosure by CoreLogic (unless such knowledge
                                arose from disclosure of information in breach of an obligation of confidentiality); or
                            </div>
                            </li>
                            <li><div class="multi-level-div">the Customer acquires from a source other than CoreLogic where such
                                source is entitled to disclose it.
                            </div>
                            </li>
                        </ol>
                        <p><b>Fair Use</b> means that the use of the CoreLogic Services must be fair, reasonable
                            and not excessive as determined by CoreLogic (acting reasonably) and by reference
                            to the Fees.  In determining this, usage will be considered excessive and unreasonable
                            where it materially exceeds the average and/or estimated use patterns over any historical
                            period in the past.
                        </p>
                        <p><b>Fees</b> means the fee as set out in the Application.</p>
                        <p><b>GST</b> means the tax imposed by the Goods and Services Tax Act 1985.</p>
                        <p><b>Initial Term </b>means (subject to any applicable Trial Period) the
                            initial term as set out in the Application.</p>
                        <p><b>Insolvent </b>means, with respect to an entity, that such entity is or
                            states that it is insolvent, is unable to pay its debts as they come due, is in
                            liquidation, is under administration or has a controller appointed to its property, ceases
                            conducting business in the normal course, is subject to any arrangement to protect itself from
                            creditors or dissolves.</p>
                        <p><b>Internal Business Needs </b>means solely for the internal business needs of the Customer and
                            not to be provided to any other third party unless consented to in writing by CoreLogic (in its discretion).
                            For clarity it is agreed that the following are not for Internal Business Needs:</p>
                        <ol class="cus-alpha" type={'a'}>
                            <li><div class="multi-level-div"> any direct or indirect supply of raw Product Data or Product Data
                                derivatives by the Customer to third parties for on-supply, re-use or resale; and
                            </div>
                            </li>
                            <li><div class="multi-level-div"> any marketing purposes (whether unsolicited or otherwise).
                            </div></li>
                        </ol>
                        <p><b>Law </b>means common law, principles of equity, and laws made by parliament
                            (laws made by parliament include laws (in New Zealand) and regulations and other
                            instruments under them, and consolidations, amendments, re-enactments or replacements
                            of any of them).</p>
                        <p><b>Party </b>means the Customer or CoreLogic and <b>‘Parties’</b> shall mean both of them.</p>
                        <p><b>Password </b>means the individual password and any additional security measures
                            which may be introduced from time to time, allocated to an individual user name which
                            may be changed from time to time.</p>
                        <p><b>Payment Cycle </b>means the payment cycle specified in the Application. </p>
                        <p><b>Payment Date </b>means the 20th day of the month following the date of an invoice from CoreLogic.</p>
                        <p><b>Product Data </b>means any data or results, including any cost information, property information,
                            ownership information, sales information, photographs, valuation or market share analyses,
                            index results or alerts, contained within or provided through the Products via the Website.
                        </p>
                        <p><b>Products </b>means those property information services (including any legal document or
                            report ordering service (if access enabled)), made available by CoreLogic through the Website
                            under this Agreement (and includes the Product Data) and <b>Product</b> means any one of them.
                        </p>
                        <p><b>Questions</b> means the series of questions about the subject Commercial Property asked
                            and displayed to Permitted Users via the Cordell Commercial Estimator requiring the Permitted User’s
                            input as a condition precedent to the Cordell Commercial Estimator producing a Cordell Rebuild Estimate.
                        </p>
                        <p><b>Rebuild Cost</b> means the cost to replace and / or rebuild improvements on the subject Commercial
                            Property.</p>
                        <p><b>Subscription Plan</b> means the subscription plan detailed in the Agreement as signed up for by the
                            Customer.</p>
                        <p><b>Subsequent Term(s) </b>means further rolling terms of 12 months commencing on the anniversary of the
                            expiry of the Initial Term.</p>
                        <p><b>Third Party Data Provider</b> means any third party provider of data that is incorporated in the
                            Product Data.</p>
                        <p><b>Trial Period</b> means the period during which the Customer is granted access to the Products on a trial
                            basis, at no charge, as specified in the Application.
                        </p>
                        <p><b>Website</b> means the website through which the Products are made available.</p>
                        <p><b>Valuer</b> means person who is appropriately qualified to undertake a valuation of the Rebuild Costs
                            (such as a certified practicing valuer or quantity surveyor).</p>
                    </div>
                    </li>
                    <li> <div class="multi-level-div">The headings in this Agreement are for convenience of reference only and will not affect the interpretation hereof.
                        &nbsp;The words 'include' and 'including' when introducing an example, do not limit the meaning of the words to which the
                        example relates or examples of a similar kind.&nbsp;  A reference to a clause is a reference to a clause in this Agreement.
                        &nbsp;Words importing the singular number will include the plural and vice versa, and words importing the masculine gender
                        include all other genders.</div>
                    </li>
                </ol>
            </li>
            <li><b>Terms of the Agreement </b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">The Customer’s use of the Product (including any Product Data) and access to the Website is subject to the
                        terms and conditions set out in the Agreement.</div>
                    </li>
                </ol>
            </li>
            <li><b>Duration of User Agreement</b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">This Agreement will commence on the Effective Date and will continue during
                        the Initial Term and any Subsequent Term(s) (as applicable).
                    </div>
                    </li>
                    <li><div class="multi-level-div">Upon expiry of the Initial Term this Agreement will automatically renew for successive periods equal
                        to the Subsequent Term unless notice of cancellation
                        is given by the Customer not less than 30 days prior to the expiry of the Initial Term.
                    </div>
                    </li>
                    <li><div class="multi-level-div">
                        If this Agreement is terminated by the Customer before expiry of the Initial Term the Customer remains
                        liable for any further Fees that would be due under the Initial Term had it not been terminated.
                    </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            If the Customer’s Subscription Plan includes a Trial Period the Customer is bound by these terms in the same manner as
                            if the Subscription Plan did not include a Trial Period. A Subscription Plan’s Initial Term will be inclusive of any applicable
                            Trial Period. The Customer is entitled to use the Products free of charge for the Trial Period. On expiry of the Trial Period
                            the Customer’s Subscription Plan will automatically continue for the remainder of the Initial Term.
                        </div>
                    </li>
                </ol>
            </li>
            <li><b>Licensing </b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">Subject to the terms of this Agreement and the Customer's performance of its obligations, CoreLogic hereby
                        grants to the Customer a non-exclusive, non-transferable, non-sublicensable limited licence during the Initial
                        Term (including any Trial Period) and any Subsequent Term(s) to access and use the Products and the Product Data
                        forming the relevant Subscription Plan, solely for support of the Customer's Internal Business Needs. It is agreed
                        by the Parties that the following are not actions solely in support of the Customer’s Internal Business Needs:

                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div">the on-supply of Products or Product Data for direct commercial gain (as opposed to incidental gain);
                                and </div></li>
                            <li> <div class="multi-level-div">the bundling of Products or Product Data with other products or services and provision of
                                this to any other third party.
                            </div></li>
                        </ol>
                    </div>
                    </li>
                    <li><div class="multi-level-div"> Where a Customer on-supplies a Product or Product Data to an End User pursuant to its Internal Business Needs
                        in accordance with this clause 4, the Customer is required to advise the End User that they are specifically
                        prohibited from:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div"> using the Product or any Product Data other than for the End User’s personal non-commercial use;
                            </div></li>
                            <li> <div class="multi-level-div">providing the Product or any Product Data to any other party; and
                            </div></li>
                            <li><div class="multi-level-div"> that the End User is otherwise bound by the restrictions and
                                obligations set out in these terms relating to or in connection with the Products.
                            </div></li>
                        </ol>
                    </div>
                    </li>
                    <li><div class="multi-level-div">
                        Except as set forth in clause 4.1, the Customer is not licensed to use the Products
                        and undertakes not to do so.</div>
                    </li>
                    <li><div class="multi-level-div">Except as expressly permitted under this Agreement or to the extent permitted by applicable Law,
                        the Customer, the Enabled Users and any other employees and representatives of the Customer will not,
                        nor will they encourage any person or entity to:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div">
                                decompile, disassemble or otherwise reverse engineer all or any portion of the Products,
                                including any source code, object code, algorithms, methods or techniques used or embodied
                                therein whether by scraping, harvesting or some other means;
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                use a data extraction software or programme of any kind whatsoever or attempt to harvest
                                the Product Data for any purpose whatsoever, including for the purpose of setting up or
                                adding another database;</div>
                            </li>
                            <li><div class="multi-level-div">
                                modify or create any derivative works based upon the Products or the Product Data;</div>
                            </li>
                            <li><div class="multi-level-div">
                                license, sublicense, distribute, resell, disclose, incorporate into any database,
                                commercially exploit or otherwise transfer any Product or Product Data to any third party,
                                or use the Products or the Product Data on behalf of or for the benefit of any third party;
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                remove or alter any copyright, trademark, logo or other proprietary notice or label appearing
                                on or in the Products or the Product Data; and
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                incorporate any portion of the Products or the Product Data into any other materials,
                                products or services that are not intended for the Customer's Internal Business Needs.
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                use, or offer to use, the Products or any Product Data for or in connection with
                                any direct marketing activities, including any telemarketing campaign or telephone
                                survey (excluding the Customer Supplied Personal Information).
                            </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                </ol>
            </li>
            <li><b>Creative Commons Licence  </b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">CoreLogic is a party to a worldwide, royalty-free, non-exclusive, Creative Commons
                        Custom Licence under which it receives some
                        imagery and data <b>(CCL Material)</b> used in the Products and on the Website.</div>
                    </li>
                    <li><div class="multi-level-div"> The CCL Material is licensed under a Creative Commons Attribution 3.0 New Zealand Licence
                        <b> (Creative Commons Licence)</b> details of which can be found at
                        <a tabIndex={1} target="_blank"
                            href="http://creativecommons.org/licenses/by/3.0/nz/legalcode"
                            rel="noopener noreferrer" > http://creativecommons.org/licenses/by/3.0/nz/legalcode</a>
                        . The CCL Material is not the property of
                        CoreLogic and is credited to the original author <b>(Original Author)</b> as shown in the metadata of the
                        CCL Material. </div>
                    </li>
                    <li><div class="multi-level-div">
                        CoreLogic does not assert or imply any connection with sponsorship or endorsement by the Original
                        Author of CoreLogic’s use of the CCL Material. </div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic makes the CCL Material available to the Customer on the terms of the Creative Commons
                        Licence and in no way implies that it has the right to sublicense the CCL Material to the Customer
                        or any third party.</div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic does not impose any terms on the use of the CCL Material by the Customer or
                        any third party that alter or restrict the terms of the Creative Commons Licence or any rights
                        granted under it or has the effect or intent of restricting the ability to exercise any such rights.</div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic has not imposed any digital rights management technology on the CCL Material,
                        any adaption of the CCL Material or the CCL Material as incorporated with other separate works,
                        that alter or restrict the terms of the Creative Commons Licence or any rights granted under it
                        or has the effect or intent of restricting the ability to exercise those rights.</div>
                    </li>
                </ol>
            </li>

            <li><b>Access  </b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">The Customer may access the relevant Products through the Website. On receipt and processing
                        of the Application, CoreLogic will, in consultation with the Customer, provide the Customer with the
                        user names and Passwords for each Enabled User. CoreLogic will also assign one or more Enabled User(s)
                        to be the Administrator(s)
                        and will allocate an administrator login for the Administrator(s).
                    </div>
                    </li>
                    <li> <div class="multi-level-div">The Customer acknowledges and agrees that it:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div">
                                bears sole responsibility for protecting all user names and Passwords;</div> </li>
                            <li><div class="multi-level-div">
                                will not provide such user names and Passwords to any third party; </div></li>
                            <li><div class="multi-level-div">
                                is responsible for all use of the Website, including all ordering of Products made
                                by Enabled Users whether or not the order is made by the Customer, its staff or by
                                someone else using the user name(s) (lawfully or otherwise) and that CoreLogic is
                                entitled to rely upon, and process, any order it receives in writing from a Customer’s or
                                Enabled Users’ login; and
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                will remain fully responsible and liable for any authorised or unauthorised use of any
                                user names and Passwords.
                            </div>
                            </li>
                        </ol> </div>
                    </li>
                    <li><div class="multi-level-div"> The Customer acknowledges and agrees that CoreLogic is entitled to update the Customer’s
                        (and any Enabled Users’) passwords whenever CoreLogic considers it appropriate under its
                        internal security protocols. </div>
                    </li>
                    <li><div class="multi-level-div">The Customer must ensure each Enabled User does not knowingly or recklessly allow any other
                        person to access the Product or Website using their user name and Password. &nbsp;If the Customer
                        knows or has reason to believe that there has been or is about to be fraudulent or other unlawful
                        use of a user name and Password the Customer must immediately notify CoreLogic  by email to
                        <a tabIndex={1} target="_blank"
                            href="mailto:info@corelogic.co.nz" rel="noopener noreferrer" > info@corelogic.co.nz </a>
                        and immediately change the Password of the relevant Enabled User. </div>
                    </li>
                    <li><div class="multi-level-div">The Customer will be responsible for obtaining access to the Products and Website, and for any
                        and all costs and expenses in connection with accessing and using the Products and Website,
                        including Internet service provider fees, telecommunications fees, and the cost of any and all
                        equipment (including hardware and software) used by the Customer in connection with its access
                        and use of the Products as permitted by this Agreement. </div>
                    </li>
                    <li><div class="multi-level-div">The Customer acknowledges that CoreLogic will have no obligation to assist the Customer in
                        using or accessing the Products and Website except as expressly provided in this Agreement.
                        For clarity, CoreLogic will not be liable for any delay, defect, deficiency and/or loss of
                        service in connection with the Products or the Website or any loss caused by or on account of
                        any of the following:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div">
                                equipment, data or services supplied by the Customer or any third party; or </div></li>
                            <li><div class="multi-level-div">
                                any telecommunications organisation equipment or services (including any speeds or
                                capabilities of such equipment or services) or any requirements of the telecommunications
                                authority.</div>
                            </li>
                        </ol>  </div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic will not provide the Customer with any software, and all access to the Products will be through
                        the Website as provided in
                        clause 6.1, except to the extent expressly provided in the Application. </div>
                    </li>
                    <li><div class="multi-level-div">If CoreLogic has good reason to believe that any information the Customer has supplied is not
                        accurate, or if CoreLogic considers that the Customer has breached this Agreement, including unauthorised
                        disclosure or sharing of user name(s) (and Password(s)), it may immediately terminate this Agreement
                        without liability to
                        the Customer and the Customer will cease to be an authorised user of the Website.  </div>
                    </li>
                </ol>
            </li>
            <li><b>Modification of Website, Service or Materials</b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li> <div class="multi-level-div">CoreLogic reserves the right to change or refine the features and functionality of any
                        Product and the Website. </div>
                    </li>
                    <li><div class="multi-level-div">Subject to clause 10 CoreLogic will maintain the Website through which the Products are accessed.
                        CoreLogic reserves the right to perform network, hardware or service maintenance services or upgrades,
                        change, modify, suspend or discontinue any or all parts of the Website or Products at any time.
                        &nbsp;This may require Products to be non-accessible for a period of time. CoreLogic will endeavour to
                        perform such services or upgrades during typically low usage periods but reserves the right to
                        restrict the Customer’s access to parts or all of the Product without notice or liability. </div>
                    </li>
                    <li><div class="multi-level-div">Some of the materials in the Website and the Products are sourced from Third Party Data
                        Providers (whether identified to the Customer or not). &nbsp;CoreLogic may at any time be required to
                        amend or delete any material (or any part of the material) on the Website and the Product that
                        is sourced from a Third Party Data Provider. This means that material which may have previously
                        been available and which the Customer may have accessed, may no longer be available the next time
                        the Customer accesses the Website or the Products. </div>
                    </li>
                </ol>
            </li>
            <li><b>Customer Materials</b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div"> The Customer (either directly or through any Enabled Users) may from time to time provide or
                        otherwise make available to CoreLogic the Customer Materials. &nbsp;The Customer Materials shall be
                        deemed to be non-confidential and CoreLogic shall have no obligation of any kind with respect to
                        such information. The Customer (on behalf of itself and any Enabled Users) hereby grants CoreLogic and
                        its service providers a worldwide, royalty-free, perpetual, irrevocable, non-exclusive right and license
                        to use, reproduce, distribute, transmit, perform, display (publicly or otherwise), adapt, make
                        derivative works of, and otherwise commercialise and exploit, the Customer Materials, excluding
                        the Customer Supplied Personal Information.
                    </div>
                    </li>
                    <li><div class="multi-level-div">Furthermore, CoreLogic shall be free to use any ideas, concepts, know-how or techniques
                        contained in the Customer Materials for any purpose whatsoever, including, but not limited to,
                        developing, manufacturing and marketing products and other items incorporating such information,
                        excluding the Customer Supplied Personal Information. </div>
                    </li>
                    <li><div class="multi-level-div"> The Customer represents and warrants to CoreLogic (and its service providers) that:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div">
                                it has the right to grant the foregoing licences in and to the Customer Materials;
                            </div></li>
                            <li><div class="multi-level-div">
                                the Customer Materials do not and will not infringe upon or misappropriate any rights,
                                including, without limitation, intellectual property rights of any third parties;
                            </div></li>
                            <li><div class="multi-level-div">
                                that such Customer Materials are, and will remain, free of worms, viruses, Trojan Horses,
                                and other disabling code; and
                            </div>
                            </li>
                            <li><div class="multi-level-div">
                                the Customer will not run any network scanning software, spiders, spyware software, robots,
                                open relay software, or any such similar software in relation to the Website or which is
                                designed to extract unauthorised data from the Website.
                            </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                </ol>
            </li>
            <li><b>Fees</b>
                <ol class="multi-level" style={{ marginLeft: '-82px' }}>
                    <li><div class="multi-level-div">The Customer will pay the Fees for the Subscription Plan as set out in the Application. If specifically
                        provided for in the Application the Customer will be liable for payment of the Fees for the part period
                        from the Effective Date until the date of the first Payment Cycle. Otherwise, the Customer will be liable
                        for the Fees from the date of the first invoice. All Fees are payable monthly in
                        advance on or before the relevant Payment Date. All Fees once paid are non-refundable.</div> </li>
                    <li><div class="multi-level-div">
                        The Parties agree that when executed this Agreement amounts to a tax invoice. Fees are exclusive of GST
                        (unless stated otherwise). If provision of the Products under this Agreement is subject to GST, the
                        Customer must pay to the Company an additional amount equal to the relevant Fee multiplied by the applicable GST rate.
                        Such additional amount is payable at the same time as the related Fee. </div>
                    </li>
                    <li><div class="multi-level-div">
                        Following the Initial Term, CoreLogic may vary the Fees on 30 days written notice. If the Customer does
                        not agree to the Fee increase they have a right to terminate the Agreement within 30 days of receipt
                        of the notice of variation. If the Customer does not exercise its termination right the continued
                        use of the Product will constitute binding acceptance of the variation. </div>
                    </li>
                    <li><div class="multi-level-div">
                        The Customer acknowledges and agrees that the Product Charges for Products received from Third Party
                        Data Providers may be increased at any time by CoreLogic
                        giving notice to the Customer no less than 30 days prior to the increases taking effect. </div>
                    </li>
                    <li><div class="multi-level-div">
                        Any Fees or proportion of Fees not paid by the Customer will be subject to interest of 1.5% per month,
                        calculated on a daily basis. </div>
                    </li>
                    <li><div class="multi-level-div">
                        The Fees are charged without reference to the volume of Cordell Rebuild Estimates generated through
                        the Cordell Commercial Estimator, and those Fees are subject to Fair Use.  &nbsp;Where Fair Use is exceeded,
                        CoreLogic may review the Fees payable by the Customer in good faith and adjust them according to the extent
                        that the Cordell Rebuild Estimates exceed Fair Use.  &nbsp;Where agreement cannot be reached CoreLogic may restrict
                        access and use to the Cordell Rebuild Estimates to a level
                        consistent with Fair Use and may charge a fair and reasonable amount either:
                        <ol class="cus-alpha" type={'a'}>
                            <li><div class="multi-level-div">pro rata to Fees; or  </div></li>
                            <li><div class="multi-level-div">what CoreLogic may charge to other third parties for the Cordell
                                Rebuild Estimates generated in excess of Fair Use. </div></li>
                        </ol>
                    </div>
                    </li>
                    <li><div class="multi-level-div">
                        If the Customer has been offered a Trial Period or any other discount and at
                        completion of the Trial Period or discounted period the first or subsequent payments are
                        dishonoured, CoreLogic can charge in full an
                        amount equivalent to the Fees for the Trial Period or discounted period.</div></li>
                    <li><div class="multi-level-div">
                        Any failure to pay shall constitute a breach of the Agreement and CoreLogic may immediately
                        suspend and/or terminate the Customer’s access to the Products.</div> </li>
                    <li><div class="multi-level-div">
                        Where the Customer’s access to the Products is suspended due to the failure to pay,
                        access will be suspended until payment of all outstanding Fees, Product Charges and any interest
                        due is paid. Once payment is received access to the Products may be reinstated but without
                        prejudice of any other rights or remedies of CoreLogic.</div> </li>
                    <li><div class="multi-level-div">
                        In the event of non-payment of any amounts payable by the Customer under this Agreement by the relevant Payment
                        Date, the Customer will pay all of the actual costs of any debt collection incurred
                        by the Company in recovering the Fees and any applicable interest.
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}><b style={{ marginLeft: '-10px' }}>Disclaimer</b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li> <div class="multi-level-div">The Customer expressly acknowledges that:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl">The Cordell Rebuild Estimates are generated by the Cordell Commercial Estimator without the
                                physical inspection of the subject property and without taking into account or making any
                                allowance for:
                                <ol class="cus-alpha" type={'a'} style={{ paddingLeft: '42px' }}>
                                    <li><div class="multi-level-div"> property features (including state of repair, improvements, renovations)
                                        beyond what the Permitted User describes to CoreLogic (or have accepted as pre-populated)
                                        through the Cordell Commercial Estimator.
                                    </div></li>
                                    <li><div class="multi-level-div">
                                        local laws (including local planning requirements), except where expressly identified,
                                    </div></li>
                                    <li><div class="multi-level-div">
                                        variations in Construction Data due to matters such as the quality (other than in a
                                        limited way) and complexity of a replacement or repair item, the location and market
                                        conditions and the delivery and lead time and that any attempt by the Cordell
                                        Commercial Estimator to attempt to arrive at a uniform price is subject to variances
                                        for these factors;</div></li>
                                    <li><div class="multi-level-div">observable risk related issues with may impact the Rebuild Cost of the subject Commercial
                                        Property;
                                    </div>
                                    </li>
                                </ol>
                                <p style={{ marginLeft: '-50px' }}> which, together or separately may affect the Rebuild Cost of the subject Commercial
                                    Property.
                                </p>
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">
                                The Cordell Commercial Estimator in generating Cordell Rebuild Estimates does not make any allowances for
                                any particular insurance policy conditions or tolerances that may be contained in any insurance policy that
                                the Permitted User
                                has with the Customer or may purchase in the future from the Customer.</div>
                            </li>
                            <li><div class="multi-level-div-dbl">
                                A Cordell Rebuild Estimate must not be construed or relied upon as a professional valuation or an accurate representation of
                                the Rebuild Cost of the subject property as determined by a Valuer or Quantity Surveyor.</div>
                            </li>
                            <li>
                                <div class="multi-level-div-dbl">
                                    The Customer understands that the Permitted User’s inputs to the variable data fields and Questions within the Cordell
                                    Commercial Estimator will affect the quantum of
                                    the Cordell Rebuild Estimate produced by the Cordell Commercial Estimator.
                                </div>
                            </li>
                            <li>
                                <div class="multi-level-div-dbl">
                                    Construction Data and other Product Data (used by the Cordell Commercial Estimator to generate the Cordell Rebuild Estimates)
                                    varies and fluctuates regularly from time to time and by region.  &nbsp;Whilst Construction Data used by the Cordell Commercial
                                    Estimator is regularly updated by CoreLogic it may not always be up to date, particularly where construction costs are
                                    volatile. &nbsp;There may be less or a lower quality of information Construction Data available to CoreLogic for certain regions
                                    which may adversely affect the Cordell Rebuild Estimates generated by the Cordell Commercial Estimator.
                                </div>
                            </li>
                            <li>
                                <div class="multi-level-div-dbl">
                                    For properties in earthquake or flood zones, specific engineering advice should be sought for a reliable estimate of any costs
                                    associated with the Commercial Property.</div>
                            </li>
                            <li><div class="multi-level-div-dbl">
                                The Cordell Rebuild Estimate is current only at the date of publication or supply.</div></li>
                            <li><div class="multi-level-div-dbl">
                                Where the Cordell Rebuild Estimate contains allowances for cost inflation due to anticipated planning
                                or rebuilding timeframes, delays or otherwise, such allowances must not be relied upon as a
                                cost plan or an estimate as produced by a Valuer or Quantity Surveyor.</div>
                            </li>
                        </ol>
                    </div>
                    </li>
                    <li> <div class="multi-level-div">CoreLogic excludes all warranties in respect of the matter set out in clause 3.1 above, and
                        excludes all liability for any Loss or Consequential Loss in respect of the Cordell Rebuild Estimates
                        generated by the Cordell Commercial Estimator to the extent that the Cordell Rebuild Estimates relied upon
                        information:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl">supplied by the Permitted User,</div></li>
                            <li><div class="multi-level-div-dbl">supplied by third party suppliers to CoreLogic, and
                            </div>
                            </li>
                        </ol>
                        <p> as such, CoreLogic will not be liable for the accuracy of, reliance of or use of the Cordell Commercial Estimator or its
                            Cordell Rebuild Estimates by a Permitted User.</p>
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}><b style={{ marginLeft: '-10px' }}>Limited Warranty</b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li> <div class="multi-level-div">
                        CoreLogic hereby represents and warrants that it will use commercially reasonable efforts to ensure that
                        the Products are accessible via its Website in accordance with this Agreement.  &nbsp;In the event of any breach of this
                        warranty, the Customer agrees CoreLogic's sole and exclusive obligation (and the Customer's sole and exclusive remedy),
                        is to use commercially reasonable efforts to modify, correct or provide access to the relevant Products or Website.
                    </div>
                    </li>
                    <li> <div class="multi-level-div">
                        To the fullest extent permitted by applicable Law and subject to clause 10.4, the warranty set forth in clause 10.1
                        is in lieu of any other warranties. All other warranties are hereby excluded, and CoreLogic and its service providers
                        hereby expressly disclaim all warranties and associated liabilities.  Without limiting the generality of the foregoing,
                        it is acknowledged and agreed that CoreLogic does not warrant:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li> <div class="multi-level-div-dbl">that the Products or the Product Data will meet the Customer's requirements;
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl"> that the Products or the Product Data will be error-free;</div></li>
                            <li><div class="multi-level-div-dbl">that CoreLogic’s Website through which the Products and Product Data are accessed will function in an
                                uninterrupted manner, be
                                available 24 hours a day, 7 days a week, or be fully secure;</div></li>
                            <li><div class="multi-level-div-dbl">the correctness, accuracy, reliability or otherwise of the Products or the Product Data;
                            </div></li>
                            <li><div class="multi-level-div-dbl">that the statistical methods on which any of the Products are based use appropriate or accurate assumptions,
                                are fit for the Customer's
                                particular purpose or are otherwise suitable for the Customer's use; </div></li>
                            <li><div class="multi-level-div-dbl">that the performance of the Products will not be affected by data entry errors, including incorrect entries,
                                double entries or delayed entries, or incorrect or untimely
                                data supplied by CoreLogic’s Third Party Data Providers; </div></li>
                            <li>
                                <div class="multi-level-div-dbl">
                                    that the material on the Website will be free from infection, viruses or destructive code and
                                    CoreLogic shall not be liable to the Customer for any damage or loss suffered by the Customer as a result of
                                    material on the Website infecting or damaging the Customer
                                    (or any Enabled User’s) computer equipment, software and/or any other electronic device; and
                                </div>
                            </li>
                            <li>
                                <div class="multi-level-div-dbl">
                                    that the material on the Website and the Products, as delivered, (whether or not it is combined with the Customer’s data)
                                    will be capable of being processed on the Customer’s computer equipment and software or that the Customer’s data will be
                                    compatible with the material on the Website and the Product, or
                                    work effectively in combination with the material on the Website and the Product.
                                </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            CoreLogic has no obligations to convert the material on the Website and the Products for use by the Customer
                            with their computer equipment and software (such conversion being entirely at the Customer’s own risk), nor does
                            CoreLogic have any obligation for training the Customer or the Customer’s personnel
                            in the use of the material on the Website and the Product.
                        </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            Where CoreLogic is a supplier (as that term is defined in the Consumer Guarantees Act 1993  &nbsp;(<b>CGA</b>)) of the Products,
                            the Product Data or other goods or services, the Customer confirms that the Products, the Product Data or other goods or
                            services provided by CoreLogic under this Agreement are acquired for the purposes of a business (as that term is defined
                            in the CGA) and the Customer agrees that the CGA does not apply to the Products, the Product Data or
                            other goods or services supplied by CoreLogic under this Agreement.
                        </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            The Customer acknowledges and agrees that nothing in the Product constitutes, or is meant to constitute advice of any kind
                            and the Product
                            is not a substitute for appropriate professional advice.
                        </div>
                    </li>
                    <li><div class="multi-level-div">
                        The Products and the Product Data, including, without limitation, any information, data, prices, and quotations contained
                        therein, are subject to change without notice.
                    </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            Except as otherwise expressly provided herein, the Products and the Product Data are provided to the customer on an 'as is,
                            as available' basis without any representations or warranties of any kind, either express or implied, including, but not
                            limited to, any implied warranties of merchantability, fitness for particular purposes, title,
                            non-infringement, security, availability, accuracy, or otherwise.
                        </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            The Customer acknowledges and agrees that the limitations and exclusions of liability contained in:
                            <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                                <li> <div class="multi-level-div-dbl">clauses 10.1, 10.2, 11.2 and 11.2 (with all references to CoreLogic deemed to be replaced with
                                    references Third Party Data Providers
                                    (whether expressly identified by CoreLogic or not) ; and
                                </div>
                                </li>
                                <li><div class="multi-level-div-dbl">clause 11.3,
                                    <p style={{ marginLeft: '0px' }}>
                                        apply for the benefit of and may be enforced by any Third Party Data Provider (whether expressly identified by CoreLogic
                                        or not) in relation to the material on the Website and the Product  Data sourced by CoreLogic from a Third Party Data
                                        Provider for the purposes of subpart 2 of Part 2 of the Legislation Act 2012 as may be amended from time to time.
                                        &nbsp;For the avoidance of doubt, this clause shall not in any way limit CoreLogic’s right to enforce any of the provisions of
                                        this Agreement in relation to the material sourced
                                        by CoreLogic from a Third Party Data Provider.
                                    </p>
                                </div>
                                </li>
                            </ol>
                        </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Liability </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">CoreLogic shall not be liable (whether in contract, tort (including negligence), equity or on
                        any other basis) for any indirect, consequential, special or other similar loss, damage, liability,
                        or expense sustained by the Customer or any other person, directly or indirectly, from any use whatsoever
                        of the material on the Website and the Product (including Product Data) or otherwise in connection with
                        this Agreement (even if CoreLogic has been advised of, or has knowledge of, the possibility of such loss,
                        damage, liability or expense).  &nbsp;Such loss and/or damage includes, damage to property, injury to persons,
                        loss of profits, business interruption loss, loss of data, any other loss, damage or expense that arises either as a result
                        of the material on the Website or the Product being shown to be wholly or partially incorrect or unsuitable, or arising
                        from non-delivery or delay in delivery of the material on the Website or the Product
                        and loss as a result of any amendment to or deletion of the material on the Website or the Product.
                    </div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic shall not be liable (whether in contract, tort (including negligence), equity or on any other
                        basis) for any loss, damage, liability or expense sustained by any other person, directly or indirectly,
                        from any use whatsoever of the material on the Website or the Product, or otherwise in connection with this
                        Agreement including any loss arising from any modification, amendment or deletion (whether pursuant to
                        clause <b>7</b> of this Agreement or otherwise) made to the material on the Website or the Product or any compilation
                        or derivative work created from or using the material on the Website or the Product in each case whether made
                        by the Customer, or by any other person.
                    </div>
                    </li>
                    <li><div class="multi-level-div"> Without detracting from clause 11.1 CoreLogic’s and any Third Party Data Provider’s total aggregate
                        liability under or in connection with this Agreement, or arising out of any use, reproduction, modification,
                        amendment or deletion of the material on the Website or the Product, or creation of compilations or
                        derivative works of or from the material on the Website or the Product (by you or by any other person),
                        whether that liability arises in tort (including negligence), contract, equity or on any other basis,
                        shall be limited as follows:
                        <ol class="cus-alpha" type={'a'}>
                            <li>&nbsp;&nbsp; CoreLogic’s total aggregate liability shall be limited to $100; and</li>
                            <li>&nbsp;&nbsp;any Third Data Provider’s total aggregate liability shall be limited to $1.00
                                <p>The limitations in this clause 11.3 are also expressed for the benefit of the Third Party
                                    Data Providers for the purposes of the Contract and Commercial Law Act 2017, Part 2,
                                    Subpart 1.</p>
                            </li>
                        </ol>
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Customer Indemnity </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">The Customer shall indemnify and hold harmless CoreLogic and its directors, officers and employees
                        (each an Indemnified Party) from and against any and all losses, claims, liabilities, damages, costs and expenses
                        (including any direct or indirect consequential losses, loss of profit, loss of reputation and all interest, penalties
                        and legal and other professional costs and expenses)  that any Indemnified Party may suffer or incur by reason of or
                        in connection with the provision of the Products to the Customer and/or any breach of this Agreement by the Customer or
                        any wilful, unlawful or negligent act or omission
                        of the Customer or any of its employees, agents or contractors.</div></li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Intellectual Property Rights </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">The Customer acknowledges and agrees that, as between CoreLogic and the Customer,
                        CoreLogic, or one or more of its service providers, is and will remain the sole and exclusive
                        owner of all right, title and interest in and to the Products and the Product Data, including all
                        material published on the Website (including, but not limited to, the textual material, artwork,
                        photographs, computer software, audio and visual elements. and any and all other creations, inventions
                        and intellectual property rights (including any enhancement, suggestion, modification or derivative
                        works of any intellectual property) contained or embodied within the Products and the Product Data.
                    </div>
                    </li>
                    <li> <div class="multi-level-div">The Customer agrees that it acquires no rights in or to the Products or the Product Data provided pursuant to this
                        Agreement except for the limited licence set out in clause 4, and that it will not, and will not permit any other person or
                        entity to, infringe upon, harm or contest the validity of CoreLogic’s, or its service providers', ownership of the Products or
                        the Product Data, or the creations, inventions and intellectual property rights contained or embodied within the Products and
                        the Product Data.
                    </div>
                    </li>
                    <li><div class="multi-level-div">The Customer agrees that it will not make copies (other than a single copy for its own historical
                        reference purposes) or derivative works of the Products and the Product Data.
                    </div>
                    </li>
                    <li> <div class="multi-level-div">The Customer agrees not to remove, cover, overlay, obscure or change any copyright notices,
                        legends, or terms of use which CoreLogic may post on the Website, the Products or the Product Data.
                    </div>
                    </li>
                    <li> <div class="multi-level-div">The name and logo of CoreLogic and its associated brands are registered trademarks.  &nbsp;Where the Customer on–supplies
                        the Product or any Product Data to End Users in any manner whatsoever, including but not limited to
                        printing or emailing the  Product or Product Data, the Customer must display the proprietary device
                        specified at clause 13.7 below.
                    </div>
                    </li>
                    <li><div class="multi-level-div">Where the Products are on-supplied to End Users in the format in which they were supplied as well
                        as on any reproduction  or adaptation of the Product, the Customer shall use or ensure the use of
                        the following proprietary device:
                        <p>© CoreLogic NZ Limited</p>
                    </div>
                    </li>
                    <li><div class="multi-level-div">Subject to clause 13.5 and 13.6 the Customer is not permitted to use or reproduce or allow
                        anyone to use or reproduce
                        the trade mark specified in clause 13.5 above for any reason without CoreLogic’s express written
                        permission, which may be withheld.  &nbsp;The software which operates the Website is proprietary software
                        and the Customer is not permitted to use it except as expressly allowed for under the terms of
                        this Agreement.  &nbsp;Any other use or purported licensing, modification, enhancement or interference
                        is strictly prohibited.
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Usage </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">Without limiting any other term of this Agreement:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl">
                                The Customer will follow all reasonable instructions CoreLogic gives from time to time with
                                regard to the use of the Products and Product Data.
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">The Customer will permit CoreLogic at all reasonable times to check that the Products are
                                being used in accordance with the terms of this Agreement, and for such purposes the Customer
                                shall maintain complete and accurate records of the Customer's use of the Products.
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">The Customer expressly acknowledges that CoreLogic has entered into various licensing
                                agreements for ongoing use of data with various data suppliers and as such CoreLogic has
                                undertaken certain contractual obligations.  &nbsp;To assist CoreLogic in meeting these obligations
                                the Customer agrees to comply with and/or accept responsibility for all of CoreLogic’s
                                obligations expressed or implied in such supplier agreements.
                            </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                    <li>
                        <div class="multi-level-div">
                            The Customer acknowledges and agrees that CoreLogic’s ability to provide the Products and
                            the Product Data is subject to its agreements with third party suppliers:
                            <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                                <li><div class="multi-level-div-dbl">which agreements may expire or terminate; or
                                </div>
                                </li>
                                <li><div class="multi-level-div-dbl">which suppliers may not provide the services, products or data as provided under such agreements,
                                    and that in such circumstances CoreLogic may not be able to, and will have no obligation to,
                                    provide some or all of the Products or the Product Data, either on a timely basis or otherwise.
                                </div>
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li><div class="multi-level-div">
                        If any use is made of the Products or the Product Data by any person or entity other than the Customer and
                        such use is attributed to the act or default of the Customer, then without prejudice to CoreLogic’s other rights
                        and remedies the Customer will immediately be liable to pay to CoreLogic an amount equal to the charges which such
                        person or entity would have been obliged to pay had CoreLogic granted a licence to the
                        unauthorised user at the beginning of the period of the unauthorised use.
                    </div></li>
                    <li><div class="multi-level-div">The Customer will be fully responsible for any act or omission of any of its employees or agents
                        (including Enabled Users).
                    </div></li>
                    <li><div class="multi-level-div">CoreLogic reserves the right to suspend or terminate the Customer's access to the Products:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl">
                                in the event that any Fee is not paid when due;
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">in the event that CoreLogic or its service providers reasonably believe that the Customer
                                has used or disclosed the Products, Product Data or other Confidential Information in a
                                manner not permitted under this Agreement or otherwise has materially breached this Agreement;
                                or
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">an event of force majeure occurs that affects CoreLogic’s ability to provide the Products or the
                                Website.
                            </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Customer Details </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">
                        The Customer is required to provide CoreLogic with complete and accurate registration information
                        and to update the Customer’s information with CoreLogic to CoreLogic’s e-mail or postal address if it
                        changes, including any change in the Customer’s company or organisation name and changes in Enabled
                        Users or ownership.
                    </div>
                    </li>
                    <li><div class="multi-level-div">
                        The Customer, or the Administrator(s), must advise CoreLogic of any Enabled User changes relevant to
                        the use of the Website, including new appointments, changes in roles of staff or resignations.
                        &nbsp;CoreLogic will, for new appointments, assign and advise the Administrator(s) of the unique user name
                        and Password for each new required user name and will be responsible for managing any notified user
                        name changes.
                    </div>
                    </li>
                    <li><div class="multi-level-div">
                        If the Customer fails to notify CoreLogic of any change as required in accordance with clause 16.1 or
                        16.2 above, the Customer is deemed in breach of this Agreement.
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Confidential Information  </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">No Confidential Information may be disclosed by the Customer to any person or entity except:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl"> employees of the Customer requiring the information for the purposes of this Agreement who:

                                <ol class="cus-alpha" type={'a'}>
                                    <li><div class="multi-level-div-dbl">are aware of the confidentiality obligations imposed in this
                                        clause 17.1; and</div></li>
                                    <li><div class="multi-level-div-dbl">have entered into written confidentiality agreements with the Customer which require such
                                        employees to comply with confidentiality
                                        obligations no less restrictive than those set forth in this clause 17;</div></li>
                                </ol>
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">
                                to the extent the Customer is required to do so by applicable Law; or
                            </div>
                            </li>
                            <li><div class="multi-level-div-dbl">
                                to the extent the Customer is required to do so in connection with legal proceedings
                                relating to this Agreement.
                            </div>
                            </li>
                        </ol>
                    </div>
                    </li>
                    <li><div class="multi-level-div">The Customer must not use Confidential Information except for the purpose of exercising its rights or performing its
                        obligations under this Agreement.</div></li>
                    <li><div class="multi-level-div">Clauses 17.1 and 17.2 do not apply to Excluded Information.</div></li>
                    <li><div class="multi-level-div">Notwithstanding clauses 17.1 and 17.2, the Customer may disclose parts of the Product Data, or conclusions
                        or summaries of information based on the Product Data, provided that the Customer has first obtained
                        CoreLogic’s prior written consent for such disclosure and the Customer gives CoreLogic credit as the
                        source for the underlying data where appropriate, in a manner reasonably instructed by CoreLogic.
                    </div>
                    </li>
                    <li><div class="multi-level-div">The Customer will take any action that is necessary to prevent or remedy any breach of the Customer's
                        confidentiality obligations or other unauthorised disclosure of Confidential Information.
                    </div>
                    </li>
                    <li><div class="multi-level-div">The Customer may not make press or other announcements or releases relating to this Agreement and the transactions
                        that are the subject of this Agreement without CoreLogic’s prior written approval as to the form and manner of the
                        announcement or release, unless and to the extent that the announcement or release is required to be made by the Customer
                        by applicable Law or
                        by a stock exchange with which the Customer is listed.
                    </div> </li>
                    <li><div class="multi-level-div">Except as required by clause 17.1, no Party will disclose the terms of this Agreement to any person or entity other
                        than its employees, accountants, auditors, financial
                        advisers or legal advisers on a confidential basis.
                    </div> </li>
                    <li><div class="multi-level-div">The Customer acknowledges that due to the unique nature of the Confidential Information, any breach by
                        the Customer of its obligations under this clause 17 would result in irreparable harm to CoreLogic for
                        which there is no adequate remedy; and therefore, upon any such breach or threat thereof, CoreLogic will
                        be entitled to injunctive and other appropriate equitable relief (without the necessity of proving damages,
                        or posting bond or other security), in addition to whatever remedies Corelogic may seek under all applicable Law.
                    </div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Privacy </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">
                        The Customer must ensure that its use of the Products, including Product Data,
                        complies with all applicable Law, including the Privacy Act 2020
                    </div></li>
                    <li><div class="multi-level-div">CoreLogic is an Agency for the purposes of the Privacy Act 2020, as may be amended from time to time.
                        All personal information the Customer supplies to CoreLogic will be dealt with by CoreLogic in accordance with that Act.
                        &nbsp;CoreLogic may from time to time send the Customer information on selected products and services and invite others to do so.
                        &nbsp;Should the Customer not wish to receive, or for any of its staff to receive, this material, the Customer must email this
                        request to info@corelogic.co.nz.  &nbsp;The Customer is deemed to have given its and that of its’ staff’s permission to CoreLogic
                        to convey all or part of its registration information to independent auditors for the sole purpose of verifying site usage
                        statistics.  As part of the verification process, the Customer may be contacted by these independent auditors and asked to
                        confirm that the Customer has registered with the Website.
                        Accordingly, the Customer may not register any personal details other than its or their own.
                    </div> </li>
                    <li><div class="multi-level-div">The Customer has the right to access its personal profile and if there are errors in the
                        information, correct any of its personal information. </div></li>
                    <li><div class="multi-level-div">CoreLogic reserves the right to deal with Customer Supplied Personal Information for the purposes of meeting its
                        obligations under this Agreement and enhancing the Website and Products made
                        available to the Customer and other customers of CoreLogic.</div></li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Termination  </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">The Customer is entitled to terminate the Agreement upon expiry of the Initial Term by way of written
                        notice given to CoreLogic 30 days’
                        prior to the end of the Initial Term. </div></li>
                    <li><div class="multi-level-div">If the Agreement is terminated during the Initial Term the Customer will remain liable for any further
                        Fees that would have been
                        payable under the Initial Term, had it not be terminated. </div></li>
                    <li><div class="multi-level-div">Subsequent Terms of this Agreement may be terminated by the Customer at any time on 30 days’ notice to CoreLogic.
                        This notice period will take effect from the first day of the month following the date on
                        which the termination notice was received by CoreLogic.</div></li>
                </ol>

            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Notices </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">All notices hereunder will be in writing addressed to the Parties at the respective addresses shown
                        in the Application or
                        as updated by notice in writing to the other party.</div>  </li>
                    <li><div class="multi-level-div">Notice will be deemed given:
                        <ol class="multi-level" style={{ marginLeft: '-40px' }}>
                            <li><div class="multi-level-div-dbl">in the case of hand-delivered mail upon delivery or  &nbsp;alternatively in the case of
                                ordinary mail on the fourth day after the date of posting; and</div></li>
                            <li><div class="multi-level-div-dbl">in the case of email transmission upon notification of a delivery
                                receipt.</div></li>
                        </ol>
                    </div>
                    </li>
                    <li><div class="multi-level-div">A Party may change its address for service of notices under this clause by giving written
                        notification of the new address to the other Party.</div> </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Miscellaneous </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">The Customer warrants that it has not relied on any representation made by CoreLogic which has not been
                        expressly stated in this Agreement or upon the descriptions or allusions or specifications contained
                        in any document including any catalogue or other material produced or made available by CoreLogic.
                    </div></li>
                    <li><div class="multi-level-div">CoreLogic will not be liable for any failure to perform or delay in performing its obligations if the failure or
                        delay results from circumstances beyond the control of CoreLogic (whether happening in the New Zealand or elsewhere,
                        including acts of God, refusal of licence, refusal or revocation of any telecommunications organisation's consent in
                        respect of data communication equipment, government act, fire, explosion, accident, strike, industrial dispute, civil
                        commotion or impossibility of obtaining material and/or data).</div></li>
                    <li><div class="multi-level-div">If the whole or any part of a provision of this Agreement is void, unenforceable or illegal in a jurisdiction it is
                        severed for that jurisdiction.  The remainder of this Agreement has full force and effect and the validity or
                        enforceability of that provision in any other jurisdiction is not affected.
                        This clause has no effect if the severance alters the basic nature of this Agreement or is contrary to public policy.</div></li>
                    <li><div class="multi-level-div">Nothing contained or implied in this Agreement constitutes one Party the partner, agent, or legal representative of the other
                        Party for any purpose or creates any partnership, agency or trust,
                        and neither Party has any authority to bind the other Party in any way.</div></li>
                    <li><div class="multi-level-div">The rights and remedies provided in this Agreement are in addition to other rights and remedies given by
                        applicable Law independently of this Agreement.</div></li>
                    <li><div class="multi-level-div">This Agreement (together with the Application and any relevant amendments) shall constitute the entire agreement of the
                        Parties about its subject matter and supersedes all previous agreements,
                        understandings and negotiations on that subject matter.</div></li>
                    <li><div class="multi-level-div">The Customer agrees that CoreLogic may send the Customer commercial electronic messages marketing CoreLogic’s
                        goods and services and new products.  &nbsp;The Customer also agrees that CoreLogic may send electronic messages containing
                        updated information in relation to the services,
                        news and various other information of related interest.</div></li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Variation to this Agreement </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">CoreLogic may vary the provisions of this Agreement at any time on 30 days’ notice. If the Customer
                        does not agree to the variation they have the right to terminate the Agreement within 30 days of receipt of the
                        notice of variation. If the Customer does not exercise its termination right the continued use of the products
                        will constitute binding acceptance of the variation. </div></li>
                    <li><div class="multi-level-div">No right under this Agreement will be deemed to be waived except by notice in writing signed by the Party
                        to be bound.  </div></li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Assignment </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">The Customer may not assign, transfer, novate, subcontract or otherwise dispose of any benefits, rights,
                        liabilities or obligations under this Agreement or any part of this Agreement without the prior written
                        consent of CoreLogic.</div>
                    </li>
                    <li><div class="multi-level-div">CoreLogic is permitted to assign its interests, and novate its obligations (without recourse by the Customer) under this
                        Agreement by notice in writing to the Customer.</div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>Governing Law </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">This Agreement is deemed to have been made in New Zealand and is governed by New Zealand Law.
                        Any dispute relating to this Agreement shall be referred to the New Zealand courts and the New Zealand courts
                        shall have jurisdiction to hear and determine such dispute.</div>
                    </li>
                </ol>
            </li>
            <li style={{ marginLeft: '10px' }}> <b style={{ marginLeft: '-10px' }}>General </b>
                <ol class="multi-level" style={{ marginLeft: '-91px' }}>
                    <li><div class="multi-level-div">All provisions of this Agreement intended to survive termination or expiry of this Agreement shall so
                        survive and this includes clause 8, 11, 12, 13, 17 and 18.</div>
                    </li>
                    <li><div class="multi-level-div">If one or more of the provisions of this Agreement will be invalid, illegal or unenforceable, the validity,
                        existence, legality and enforceability of the remaining provisions will not in any way be
                        affected, prejudiced or impaired.</div></li>
                    <li><div class="multi-level-div">This Agreement may be executed in several counterparts (including email PDFs), all of which when signed and
                        taken together constitute a single agreement between the Parties. The Parties consent to entry into this Agreement by
                        electronic means pursuant to the Electronic Transactions Act 2002.</div></li>
                </ol>
            </li>
        </ol>
    </div>
    <div class="cce-terms-h2">
        <h2>Currency</h2>
        <p>
            Version 1 of these Cordell Commercial Estimator Terms of Use are current as and from 1 February
            2021.</p>
        <p>© 2021 CoreLogic NZ Limited. All rights reserved.</p>
    </div>
</div>);

const D2CTermsOfUse = (<div style={{ width: '100%', overflowX: 'hidden', color: '#000000' }}>
    <div>
        <h2 tabIndex={1}>Introduction</h2>
        <ol start="1">
            <li>This Website is operated by RP Data Pty Ltd trading as CoreLogic Asia Pacific (ABN 67 087 759 171) (“CoreLogic”, “Our”, “Us” or “We”).
            </li>
            <li>Please read these Terms carefully. These Terms govern your access to and use of the Website and the Output.
            </li>
            <li>These Terms constitute a legally binding agreement between you and us and govern your use of our Website and the Output.
                By using the Website, including by accessing the Output, you agree to be bound by these Terms. This agreement takes effect from the
                point you first access the Website. If you do not accept any of these Terms,
                then you must cease using the Website and the Output or any information derived as a result of the use of any of those.
            </li>
            <li>We may change or update these Terms from time to time by posting a notice of such updates on the Website.
                You are required to accept any changes or updates in order to continue to access and use the Website.
                If you do not accept any change or update to these Terms then you must cease using the Website.
            </li>
            <li>You may not transfer any of your rights or obligations under these Terms to any person.
                The validity, interpretation and enforcement of these Terms shall be governed by the laws of New South Wales.
            </li>
        </ol>

    </div>

    <div>
        &nbsp;
        <h2 tabIndex={1}>Output</h2>
        <ol start="6">
            <li>The Output will have those features described on the Website and we will use commercially reasonable efforts to ensure that the Website
                is accessible in accordance with these Terms. You will only use the Website and the Output for your own personal use (and not for any commercial gain or benefit) <b>(Permitted Purpose)</b>. You warrant and agree you will not use,
                and will not permit, aide or incite any third party to use the Website or the Output for a Non-Permitted Purpose.
            </li>
            <li>The Sum Sure calculator takes the answers you input (or confirm, as applicable) combined with information collated by CoreLogic about
                the subject property (from insurers and other third party sources) and analyses them against construction industry data and other data collated
                by us, including if applicable the BAL Estimate (where available), to generate an estimated reconstruction cost of the improvements on your property (the <b>Estimate</b>). Use of the Sum Sure calculator and the Estimate may not be appropriate for you and you will need to assess the suitability of this Estimate given your knowledge of your property.
                You specifically acknowledge:
                <ol type={'a'}>
                    <li>The Estimate is not intended to replace a professional valuation
                        through
                        a person
                        appropriately qualified to undertake a valuation of improvement
                        replacement costs (such as a
                        valuer or quantity surveyor).
                    </li>
                    <li>Where any details on your property or improvements are pre-populated
                        by
                        us, it is your
                        responsibility to check that the pre-populated information is
                        accurate
                        and correct. If you
                        do not amend it, we will assume that you have diligently checked
                        that
                        pre-populated
                        information and agree that it is accurate and correct.
                    </li>
                    <li>No physical inspection or reference to building features beyond what
                        you
                        describe to us (or
                        have accepted as pre-populated) have been used in generating the
                        Estimate. Except where
                        expressly identified, no local laws (including local planning
                        requirements) have been taken
                        into account in generating the Estimate.
                    </li>
                    <li>Construction costs vary regularly from time to time and by region –
                        construction industry
                        data used in the Sum Sure calculator is updated regularly (as it
                        becomes
                        available) but may
                        not always be up to date where construction costs are volatile, and
                        likewise the Estimate
                        may become out of date in a relatively short period of time. Also,
                        for
                        certain regions,
                        there may be less construction data available or a lower quality of
                        information, and this
                        may adversely affect the Estimate.
                    </li>
                    <li>Any descriptions about the information requested for the Sum Sure
                        calculator are simply
                        included to assist you in completing the required information.
                    </li>
                    <li>The Sum Sure calculator does not make any allowances for any
                        particular
                        insurance policy
                        conditions or tolerances that may be contained in any insurance
                        policy
                        that you have or may
                        purchase in the future.
                    </li>
                    <li>We do not accept any responsibility for the answers you provide or
                        the
                        Third Party
                        Information that the Sum Sure calculator receives, and as such, we
                        will
                        not be liable for
                        the accuracy of, your reliance of or use of the Sum Sure calculator.
                    </li>
                    <li>A map showing property boundaries is indicative only and may not be
                        complete or accurate. Any
                        produced map is not intended or designed to replace the certificate
                        of
                        title or land survey
                        information. If you need a certificate of title or land survey
                        information then you can order these
                        separately from us or a Land Titles Provider.
                    </li>
               
               
                    <li>Where a BAL Estimate is supplied, the following additional limitations and restrictions apply:</li>
                    <ol type={"i"}>
                        <li>the BAL Estimate is designed for use solely as an input to the Cordell Sum Sure calculator in generating an Estimate for a subject property;</li>
                        <li>the BAL Estimate is generated using available data, without physical inspection of the subject allotment, vegetation classes impacting on the site or classified vegetation effective slope, using a data driven simplified methodology which is subject to limitations; </li>
                        <li>the BAL Estimate must not be construed or relied upon as a Bushfire Attack Level (BAL) rating as determined in accordance with Australian Standard AS 3959:2018 Construction of buildings in bushfire-prone areas (and amendments or replacements) or used in establishing construction requirements for a site, and is not intended to replace a professional assessment of bushfire risk for a subject property;</li>
                        <li>the BAL Estimate may not be available for all properties within the CoreLogic Database, and is current only at the date of publication or supply.</li>
                        </ol>  
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Website (requirements, access and modifications)</h2>
        <ol start="8">
            <li>We will endeavour to establish, maintain and enforce security measures
                within the Website to a standard of good industry practice.
            </li>
            <li>We will endeavour to make the Website available at all times. However, you
                acknowledge that the
                Website is provided over the internet and mobile networks and so the quality
                and
                availability of the
                Website is not guaranteed.
            </li>
            <li>We will not be obliged to provide access to the Website or any Output to the
                extent that we are
                prohibited from doing so by law or any agreements with any third party.
            </li>
            <li>The Sum Sure calculator, the Output and their features are dynamic in
                nature.
                There is no guarantee
                that they will stay the same. We may:
                <ol type={'a'}>
                    <li>change, refine or otherwise modify the features and functionality of
                        the
                        Website, Sum Sure
                        calculator or the Output; or
                    </li>
                    <li>cease, discontinue or temporarily suspend the availability of the
                        Website, Sum Sure
                        calculator or Output.
                    </li>
                </ol>
            </li>
            <li>You permit us to check that the Website and the Output are being used in
                accordance with these
                Terms.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Charges and payment</h2>
        <ol start="13">
            <li>We will not charge you for accessing the Website, but your internet or
                mobile
                network provider may
                charge you for connection services when you download and/or use the Website.
            </li>
            <li>Mobile devices, communications links and systems compatibility (and all
                costs
                associated with such
                items) are your sole responsibility. You agree that we are not responsible
                for
                any of those charges.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Copyright and other restrictions</h2>
        <ol start="15">
            <li>The contents of the Website, Sum Sure calculator and Output are protected by
                copyright and other
                intellectual property rights, and no part of the Website (or the Sum Sure
                calculator, or any Output)
                may be reproduced or adapted in whole or in part without our written
                consent.
                Our ability to grant
                consent is subject to us getting consent from our Third Party Suppliers. You
                will not assert any
                ownership or other rights in respect of any of the Website, Sum Sure
                calculator,
                the Output or any
                documentation, information or materials contained on or made available
                through
                the Website (together
                the <b>Information</b>). The trademarks, service marks and logos (<b>Trade Marks</b>) used in this Website are registered or unregistered
                Trade Marks of CoreLogic or any related parties.
                No Trade Mark may be copied, reproduced, adapted or transmitted in any form or process without the owner’s consent.
            </li>
            <li>The Website and Information are made available for your own lawful internal
                use
                within Australia and
                you agree not to further disseminate Information supplied (whether for
                commercial gain or otherwise)
                and in particular not to publish Information by any means (including in
                writing
                or electronic
                dissemination) without our prior written consent. Our ability to grant
                consent
                is subject to us
                getting consent from our Third Party Suppliers.
            </li>
            <li>You agree not to use the Website or Information in creating any mailing or
                address list of persons
                or properties, to use the Website or Information for Direct Marketing
                activities, to sell the
                Website or Information to others, or to use or offer to use the Website or
                Information in any way
                that may encroach on the privacy of others or otherwise breach any Privacy
                Laws.
            </li>
            <li>In accessing the Website, you will not (and will not allow or encourage any
                other person to):
                <ol type={'a'}>
                    <li>decompile, disassemble or otherwise reverse engineer all or any
                        portion
                        of the Information
                        or the Website, including any source code, object code, algorithms,
                        methods or techniques
                        used or embodied therein;
                    </li>
                    <li>data mine, scrape, crawl, create links back to, harvest or use any
                        process or processes that
                        send automated queries to the Website;
                    </li>
                    <li>modify or create any derivative works based upon any Information or
                        make
                        copies of the
                        Website or any Information (except for the sole purpose of back-up
                        or
                        disaster recovery);
                    </li>
                    <li>remove or alter any copyright, trademark, logo or other proprietary
                        notice or label
                        appearing on or in the Website or any Information; or
                    </li>
                    <li>incorporate the Website or any Information into any other materials,
                        products or services.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}> Customer Materials</h2>
        <ol start="19">
            <li>You grant us and each of our partners, affiliates, parent companies, related
                entities, successors,
                and assigns a worldwide, royalty-free, perpetual, irrevocable, assignable,
                non-exclusive right and
                licence to use, convert, reproduce, reformat, store, back-up, distribute,
                sell,
                transmit, perform,
                display (publicly or otherwise), adapt, make derivative works of and
                otherwise
                commercialise and
                exploit (with no obligation to account for profits) any Customer Materials
                either directly or in
                combination with other information or parties. You acknowledge that this
                clause
                19 is also for the
                benefit of our Third Party Supplier and may be enforced by it.
            </li>
            <li>You must not provide Customer Materials that you know:
                <ol type={"a"}>
                    <li>you are not authorised to provide;</li>
                    <li>are incorrect; or</li>
                    <li>have been obtained in breach of any law or obligation of
                        confidentiality.
                    </li>
                </ol>
            </li>

            <li>Subject to your compliance with clause 20 above, we accept that if we and/or
                any
                of the
                above mentioned persons choose to exploit the above mentioned right and
                licence so
                granted by you,
                such commercialisation and exploitation is at our and/or the relevant
                licensee's
                own risk and as
                such and notwithstanding anything contained in these Terms, you have no
                liability for any loss, cost
                or expenses that we and/or any relevant licensee/Third Party Supplier may
                incur
                as a result of such
                commercialisation or exploitation.
            </li>
            <li>You confirm to us and our Third Party Supplier that:
                you acknowledge that we sometimes use customer materials supplied by
                another customer in our
                products, including the Output and in this regard, we do not
                endorse,
                support, represent or
                guarantee the truthfulness, accuracy and reliability of the customer
                materials from another
                customer contained within the Website
            </li>

        </ol>


    </div>
    <div>
        <h2 tabIndex={1}>Creative Commons Licence</h2>
        <ol start="23">

            <li>We are a party to a worldwide, royalty-free, non-exclusive, Creative Commons
                Custom Licence under which we receive certain materials used in the Website and the Output.
            </li>
            <li>Such material is licensed to us and you under various Creative Commons licenses details of which can be found at <a tabIndex={0} target="_blank"
                href="https://www.corelogic.com.au/ThirdPartyRestrictions"
                rel="noopener noreferrer"
            >https://www.corelogic.com.au/ThirdPartyRestrictions</a> (<b>Creative
                Commons Licence</b>). Such materials are not our property and are
                credited to the original author as shown in the metadata of the relevant
                material.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Confidentiality</h2>
        <ol start="25">
            <li>No Confidential Information may be disclosed by you to any person or entity
                except to the extent you are required to do so:
            </li>
            <ol type={'a'}>
                <li> &nbsp; by law; or</li>
                <li> &nbsp; in connection with legal
                    proceedings relating to
                    these Terms.
                </li>
            </ol>
            <li>You must not use Confidential Information except for the purpose specified
                in
                clause 6.
            </li>

            <li>You will take any action that is necessary to prevent or remedy any breach
                of
                your confidentiality
                obligations or other unauthorised disclosure of Confidential Information.
            </li>

            <li>You will not remove, alter, obscure or otherwise modify any trademark,
                copyright
                or other
                proprietary notice or legal disclaimer placed on or contained within the
                Confidential Information.
            </li>

            <li>You acknowledge that due to the unique nature of the Confidential
                Information,
                any breach by you of
                your obligations under these clauses 25 to 28 (inclusive) would result in
                irreparable harm to us and
                our Third Party Suppliers for which there is no adequate remedy; and
                therefore,
                upon any such breach
                or threat thereof, we and our Third Party Suppliers will be entitled to
                injunctive and other
                appropriate equitable relief (without the necessity of proving damages, or
                posting bond or other
                security), in addition to whatever remedies we and our Third Party Suppliers
                may
                have at law.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Privacy</h2>
        <ol start="30">
            <li>We are bound by Privacy Laws and have developed a Privacy Policy, available
                at <a href="https://www.corelogic.com.au">https://www.corelogic.com.au</a>
            </li>

            <li>You acknowledge that information transmitted over the internet is inherently
                insecure. To the extent
                that the Website or Information contains Personal Information, You must:
                <ol type={'a'}>
                    <li>comply with all applicable Privacy Laws;</li>
                    <li>only use Personal Information for the purpose for which it was
                        disclosed;
                    </li>
                    <li>notify CoreLogic immediately (by email to privacy@corelogic.com.au)
                        of
                        any privacy
                        complaints or events which may cause any applicable Privacy Law to
                        be
                        breached.
                    </li>
                </ol>
            </li>
            <li>We will:
                <ol type={'a'}>
                    <li>comply with all applicable Privacy Laws and ensure that its
                        employees,
                        agents and
                        contractors do so;
                    </li>
                    <li>subject to its rights under this Licence, only use Personal
                        Information
                        that You disclose to
                        Us for the purpose for which it was disclosed.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Cookies</h2>
        <ol start="33">
            <li>When you use our Website, you consent to receiving cookies from us and/or our third party partners.</li>
            <li>Our cookie policy is set out in our Privacy Policy, which is available at <a href="https://www.corelogic.com.au"> https://www.corelogic.com.au.</a></li>
            <li>From time to time, it may be necessary for us to change this cookie policy. We suggest that you check here periodically for any changes.</li>

        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Limited warranties</h2>
        <ol start="36">
            <li>You may be entitled to certain guarantees under the Competition and Consumer
                Act
                2010 (Cth).
            </li>

            <li>Except for any guarantees which cannot be excluded by law pursuant to the
                Competition and Consumer
                Act 2010 (Cth), you acknowledge that we provide no warranties or guarantees
                in
                respect of the
                Website other than those given in clause 6 of these Terms and all other
                warranties are excluded.
            </li>

            <li>We (and out Third Party Suppliers) expressly disclaim all warranties that:
                <ol type={'a'}>
                    <li>access to the Website will always be immediate or uninterrupted;
                    </li>
                    <li>the Website or Output will meet your requirements or will be
                        compatible with or suitable for use with your systems, browsers
                        or applications;</li>
                    <li>the Website will be error-free or that errors or defects in the
                        Website
                        will be corrected,
                        however we will, where practically possible, endeavour to correct
                        errors
                        within a reasonable
                        time;
                    </li>
                    <li>any Information will be accurate, reliable or correct;</li>
                    <li>the statistical methods on which the Sum Sure calculator is based
                        use
                        appropriate or
                        accurate assumptions, are fit for your particular purpose or are
                        otherwise suitable for your
                        use; and
                    </li>
                    <li>the performance of the Sum Sure calculator will not be affected by
                        data
                        entry errors,
                        including incorrect entries, double entries or delayed entries, or
                        incorrect or untimely
                        data supplied by Third Party Suppliers.
                    </li>
                </ol>
            </li>
        </ol>
        <ol start="39">
            <li>You acknowledge and agree that to the extent that the Website contains any
                Third
                Party Information,
                we provide no warranties, representations or guarantees in respect of such
                Third
                Party Information
                (including any warranty or guarantee that the Third Party Information will
                be
                complete, accurate,
                free of errors, omissions and defects, up to date, not misleading or fit for
                purpose), except to the
                extent that any such warranty or guarantee implied by law cannot be
                excluded.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Liability and your indemnity</h2>
        <ol start="40">
            <li>If any Output does not comply with the relevant requirements in these Terms,
                we
                will (within a
                reasonable time period) either:

                re-supply the Output to you.
                <br />

                This shall be your sole remedy in respect of any defect in the Output,
                your use of the Website or any breach of these Terms by us, or our negligence (or that of our Third Party Suppliers),
                except to the extent we are unable to exclude its liability by law (in which case, our liability will be limited to a
                refund of any charges you have paid and any direct and identifiable costs you have incurred (other than those excluded by clause 41)
                in respect of the Output).

            </li>

            <li>In no circumstances will CoreLogic (including our Third Party Suppliers) be liable for any Consequential Loss.</li>


            <li>You agree to reimburse us for any loss, liability, cost, fee or damage that
                we
                (and/or our Third
                Party Suppliers) and each of their related entities or partners (or any of
                their
                employees,
                directors or officers) incur as a direct result of:
                <ol type={'a'}>
                    <li>your failure to comply with these Terms, or
                        any law;
                    </li>                            <li>any content you submit through the Website.
                    </li>
                </ol>
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Suspension and termination</h2>
        <ol start="43">
            <li>We may terminate (as applicable) your right to access or use the Website at
                any
                time if you breach
                any material term or condition in these Terms or if you abuse the Website,
                the
                Sum Sure calculator
                or the Output. Examples include:
                <ol type={'a'}>
                    <li>any unlawful or unauthorised use of our (or our Third Party
                        Suppliers')
                        intellectual
                        property;
                        or
                    </li>
                    <li>any use of the Estimate and/or Cordell Report other than as permitted in clause 6; or
                    </li>
                    <li>in the case of any intellectual property infringement claim from a
                        third
                        party around your
                        Customer Materials.
                    </li>
                </ol>
            </li>
            <li>In the event of termination under clause 43 above, despite such termination,
                all
                copyright, data
                privacy and use of information obligations will remain in effect after
                termination in respect of all
                information, copies and compilations retained by you.
            </li>
        </ol>
    </div>

    <div>
        <h2 tabIndex={1}>Third Party Websites </h2>
        <ol start="45">
            <li>Our Website may link you to other websites on the internet. These other websites are not under our control and you acknowledge
                that we are not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such websites.
                The inclusion of such a link does not imply endorsement of the website by us or any association with its operators.</li>
            <li>Accessing those websites is your decision and to the fullest extent permitted in law we are not responsible or liable in any way for:
                <ol type={'a'}>
                    <li>your use of those linked websites or any loss or damage incurred arising out of or in connection with your use of them; or</li>
                    <li>the content or practices of those websites operated by third parties that are linked to our Website.</li>
                </ol>
            </li>
        </ol>
    </div>

    <div>
        <h2 tabIndex={1}>Notices</h2>
        <ol start="47">
            <li>Any queries you have regarding the Website should be addressed to CoreLogic at Level 6a & 7,
                388 George Street, Sydney, NSW 2000.
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Third party benefit</h2>

        <ol start="48">

            <li>These Terms are not only expressed for our benefit but also for the benefit
                of
                our Third Party
                Suppliers (as it relates to matters that affect them).
            </li>
        </ol>
    </div>
    <div>
        <h2 tabIndex={1}>Disclaimers</h2>
        <ol start="49">
            <li> You acknowledge and agree that the Output is made available subject to the disclaimers,
                limitations and descriptions set out in the CoreLogic Disclaimers, and that any direct or indirect use by you of the Output
                is subject to the disclaimers, limitations and descriptions set out in the CoreLogic Disclaimers.</li>
            <li>For the purposes of applying the CoreLogic Disclaimers to Terms, any reference to:
                <ol type={'a'}>
                    <li>“Product Data” in the CoreLogic Disclaimers is deleted and replaced with “Output Data”;</li>
                    <li>“Cordell Sum Sure Estimate” in the CoreLogic Disclaimers is deleted and replaced with “Estimate and/or Output”;</li>
                </ol>
            </li>
        </ol>
    </div>

    <div>
        <h2 tabIndex={1}>Definitions</h2>
        <p>In these Terms, unless the context otherwise requires:</p>

        <b>BAL Estimate</b>
        &nbsp;means a data driven estimate of a subject property’s potential bushfire risk, which is generated using available data
        including Fire Danger Index, vegetation and elevation data and geospatial datasets. The BAL Estimate is solely designed for use as an
        input to the Sum Sure calculator as an indicator of the potential impact of bushfire risk related construction costs on the Estimate for
        a subject property.

        <b>CoreLogic Disclaimers</b>
        &nbsp;means (as applicable) any copyright or other proprietary notice, legal disclaimer, or government disclaimer
        located at <a href="http://corelogic.com.au/about-us/disclaimer.html">http://corelogic.com.au/about-us/disclaimer.html</a>
        or provided by CoreLogic to the Customer from time to time.&nbsp;<br /><br />

        <b>Confidential Information</b>
        &nbsp;means all confidential, non-public or proprietary information, regardless of
        how
        the information is
        stored, which is delivered to you before, on or after being bound by these
        Terms, relating to the
        Website, the Sum Sure calculator or the Output including such information
        which
        is (i) by its nature
        is confidential; (ii) is identified by either Party as confidential; or
        (iii)
        the other party knows,
        or ought to know, is confidential, but does not include the Excluded
        Information.&nbsp;<br /><br />

        <b>Cordell Report</b>&nbsp;means the Cordell Sum Sure estimated rebuild cost report delivered to your nominated email address.<br /><br />
        <b>CoreLogic means</b>
        &nbsp;RP Data Pty Ltd trading as CoreLogic Asia Pacific (ABN 67 087 759 171).<br /><br />

        <b>Creative Commons Licence</b>
        &nbsp;has the meaning given to it in clause 24.&nbsp;<br /><br />

        <b>Customer Materials</b>
        &nbsp;means any data, information, content, photographs, metadata and/or other
        materials you make
        available to us (or otherwise obtained by us from you (such as from your use
        of
        the Website) or that
        we possess of yours) directly or indirectly, from time to time.&nbsp;<br /><br />

        <b>CoreLogic Services</b>
        &nbsp;means the services provided by CoreLogic to supply the Output and Output Data and access to the Website.<br /><br />

        <b>Consequential Loss </b>&nbsp;means:
        <ol start="a">
            <li>special, consequential, indirect, incidental, punitive or exemplary damages of any kind; or</li>
            <li>damages for any loss of profits or revenue, loss resulting from interruption of business or loss of use or data,
                arising out of or relating to the Website or the Output, however caused, even if we or our Third Party Suppliers (as applicable)
                had been advised of or should have known of the possibility of such loss.
            </li></ol><br /><br />

        <b>Direct Marketing</b>
        &nbsp;means the use and/or disclosure of Personal Information to identify, target,
        segment or filter and
        then directly communicate to an identified individual or group of
        individuals to
        market or promote
        goods or services to (whether or not addressed to that individual by name)
        by
        means, normally
        supported by a database, which uses one or more advertising media and
        includes,
        telemarketing,
        email, SMS, targeted online advertising (including Facebook and Google Ads),
        postal mail and list
        brokering.&nbsp;<br /><br />
        <b>Estimate</b>
        &nbsp;has the meaning given to it in clause 7.&nbsp;<br /><br />
        <b>Excluded Information</b>
        &nbsp;means information to the extent which:
        <ol type={'a'}>
            <li>it is in or becomes part of the public domain other than through breach
                of these Terms or an
                obligation of confidence owed to any person;
            </li>
            <li>you can prove, by contemporaneous written documentation, was already
                known to you at the
                time of disclosure by us (unless such knowledge arose from disclosure of
                information in
                breach of an obligation of confidentiality); or
            </li>
            <li>it was independently developed by the you without reference to the
                Confidential Information
                disclosed by us; or
            </li>
            <li>you acquire from a source other than us where such source is entitled to
                disclose it.
            </li>
        </ol>
        <b>Information</b>
        &nbsp;has the meaning given to it in clause 15.&nbsp;<br /><br />

        <b>Intellectual Property Rights</b> means all industrial and intellectual property rights throughout the world protected or recognised
        at law and includes all current and future registered and unregistered rights relating to:
        <ol start="a">
            <li>trade marks, business names, domain names, copyright works, databases, software, circuit layouts, designs, patents, trade secrets,
                know-how, inventions and discoveries, and all other intellectual property as defined in article 2 of the convention establishing the World
                Intellectual Property Organisation 1967; and</li>
            <li>any application or right to apply for the registration of any of the rights referred to in paragraph (a) above.</li>
        </ol>
        <br /><br />


        <b>Land Titles Provider</b>
        &nbsp;means providers of government Torrens Title Register information for land
        and
        property in each State
        and Territory.
        &nbsp;<br /><br />

        <b>Non-Permitted Purposes </b>means all or any of the following:
        <ol start="a">
            <li>use of the CoreLogic Services in a way that would or may constitute a use or disclosure contrary to the Permitted Purpose;</li>
            <li>use of the CoreLogic Services in any way that would or may breach any Laws (including Privacy Laws or applicable industry codes); </li>
            <li>use of the Output for improper or unlawful purposes;</li>
            <li>use of the CoreLogic Services in any way that would or may constitute a misuse of any person’s confidential information or infringe upon any person’s rights (including Intellectual Property Rights and moral rights);</li>
            <li>use of the CoreLogic Services in any way that would or may damage the reputation or goodwill of CoreLogic, alter the meaning of or misrepresent the CoreLogic Services;</li>
            <li>use of the CoreLogic Services in any way that would or may be defamatory, offensive, injurious, unlawfully threatening or unlawfully harassing; </li>
            <li>use of the Output for the purposes of conducting a business of aggregating either:
                <ol start="i">
                    <li>advertisements of residential or commercial properties located in Australia for sale, rent or lease from multiple agents and others for viewing by consumers in Australia; or</li>
                    <li>comprehensive property information regarding residential properties located in Australia (which may or may not be for sale, rent or lease from agents or others), for display on a single website intended for viewing and use by consumers in Australia at no cost to, and freely available without a login or paywall to, consumers;</li>
                </ol></li>
            <li>removal, alteration or defacing of any registered or unregistered trademark, logo, copyright notices, CoreLogic disclaimers or other proprietary notice appearing on or in the Output;</li>
            <li>adversely affecting or jeopardising the rights of CoreLogic (or its related bodies corporate) to register any unregistered trade marks, whether or not an application for registration has been made;</li>
            <li>actual or attempted interference, damage, alteration, misuse or gain of unauthorised access to the Website (and/or the Output) or interference with the ability of any other user of CoreLogic’s platforms (and/or the Output) to use the Output; or</li>
            <li>sale, assignment or transfer of any right, title or interest (including any Intellectual Property Rights) in the Output to any person.</li>
        </ol>
        <br /><br />

        <b>Permitted Purpose </b>has the meaning given to it in clause 6.<br /><br />

        <b>Personal Information</b>
        &nbsp;means 'personal information' as that term is defined in the relevant and
        applicable Privacy Laws,
        and which a Party discloses to or receives from the other Party in
        connection
        with this Agreement
        (including information forming part of a database).
        &nbsp;<br /><br />
        <b>Privacy Laws</b>
        &nbsp;means any relevant or applicable privacy or data protection Laws relating to
        the
        collection, use,
        processing, disclosure, storage or granting of access to the Personal
        Information which may be
        amended and in force from time to time and may include the Privacy Act 1988
        (Cth) and the Australian
        Privacy Principles which form part of the Act, Do Not Call Register Act 2006
        (Cth) and the Spam Act
        2003 (Cth); as well as any other State or Territory acts and regulations
        which
        regulate the use of
        Personal Information in each respective State or Territory any other binding
        requirement under an
        Australian industry code or policy relating to the handling of Personal
        Information.
        &nbsp;<br /><br />
        <b>Output</b>
        &nbsp;means the output from the Sum Sure calculator or any other output made
        available
        to you through the
        Website (and includes any Output Data contained within them).
        &nbsp;<br /><br />
        <b>Output Data</b>
        &nbsp;means any data or property/geospatial information contained within
        or provided with the Output or through the Website including in the Cordell Report.
        &nbsp;<br /><br />
        <b>Sum Sure calculator</b>
        &nbsp;means the Cordell Sum Sure calculator made available through the Website,
        and
        which generates the
        Output.
        &nbsp;<br /><br />
        <b>Terms</b>
        &nbsp;means these terms and conditions (as amended from time to time in accordance
        with clause 3) and any
        terms referred to in these terms and conditions.
        &nbsp;<br /><br />
        <b>Third Party Information</b>
        &nbsp;means any Output Data, other data, information, reports and other
        documentation
        or materials sourced
        from a Third Party Supplier.
        &nbsp;<br /><br />
        <b>Third Party Supplier</b>
        &nbsp;means any third party supplier who directly or indirectly supplies us with
        data or information, where such data or information is then contained within or
        provided with or through the Website.
        &nbsp;<br /><br />
        <b>We</b> or <b>our</b> or similar derivatives,
        &nbsp;means RP Data Pty Ltd trading as CoreLogic Asia Pacific.&nbsp;<br /><br />
        <b>Website</b>
        &nbsp;means www.sumsure.cordell.com.au through which the Output is made available to you in accordance with these Terms.
        &nbsp;

    </div>
    <div>
        <h2 tabIndex={1}>Currency</h2>
        <p>Version 1.8 of these Cordell Sum Sure Direct to Consumer Terms of Use are current as and from 01 May 2023.  </p>
        <p>© 2023 RP Data Pty Ltd trading as CoreLogic Asia Pacific (<b>CoreLogic</b>). CoreLogic and its licensors are the sole and exclusive
            owners of all rights, title and interest (including intellectual property rights) subsisting in the CoreLogic Materials contained in
            this publication. All rights reserved. </p>
    </div>
</div>);




const TermsOfUse = {
    AU: AuTermsOfUse,
    NZ: NzTermsOfUse,
    CCE_AU: CceAuTermsOfUse,
    CCE_NZ: CceNzTermsOfUse,
    D2C: D2CTermsOfUse,
};


export default TermsOfUse;
