import AnalyticsAttributes from './AnalyticsAttributes';

class UserReportedError extends AnalyticsAttributes {
    constructor(ProductId, ProfileId, PropertyId, SessionId, Status, Description, {Error, Name, Email, Mobile}) {
        super(ProductId, ProfileId, PropertyId, SessionId, Status, Description);
        this.Error = Error;
        this.Name = Name;
        this.Email = Email;
        this.Mobile = Mobile;
    }
}

export default UserReportedError;
