import React from 'react';
import RadioBoxOption from './RadioBoxOption';
import PropTypes from 'prop-types';

const handleNewSelection = (questionId, optionId, selectedOptionId, onChange) => {
    if (optionId !== selectedOptionId) {
        onChange({ 'question': questionId, 'answer': optionId });
    }
};

const RadioBox = ({ question, onChange, styles, productId, profileId }) => {
    const selectedOptionId = Number(question.answer);
    const sortedOptions = question.options.sort((option1, option2) => option1.lineNo - option2.lineNo);
    return (
        <div>
            {
                sortedOptions.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                    <RadioBoxOption
                        tabIndex={0}
                        key={option.id}
                        label={option.label}
                        selected={selectedOptionId === option.id}
                        onClick={() => handleNewSelection(question.id, option.id, selectedOptionId, onChange)}
                        styles={styles}
                        productId={productId}
                        profileId={profileId}
                    />
                )
            }
        </div>
    );
};

RadioBox.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
};

export default RadioBox;