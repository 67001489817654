import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const UserListPreview = ({ productId, profileId, fontFamilyName, searchResult, activeIndex, isMobile, userSortParams, requestSort, onEditUser, handleUserDeleteDialog,
    handleSelectUser }) => {

    let team = null;
    let company = null;
    let division = null;
    let userName = null;
    let fullName = null;
    let emailId = null;
    let role = null;

    const getSortedListClassName = (name) => {
        if (!userSortParams) {
            return;
        }
        if (userSortParams.sortBy === name) {
            if (name === "teamName") {
                team = whenNameEqualsTeamName(team, userSortParams);
            } else if (name === "companyName") {
                company = whenNameEqualsCompanyName(company, userSortParams);
            } else if (name === "divisionName") {
                division = whenNameEqualsDivisionName(division, userSortParams);
            } else if (name === "userName") {
                userName = whenNameEqualsUserName(userName, userSortParams);
            } else if (name === "fullName") {
                fullName = whenNameEqualsFullName(fullName, userSortParams);
            } else if (name === "emailId") {
                emailId = whenNameEqualsEmailId(emailId, userSortParams);
            } else if (name === "roleType") {
                role = whenNameEqualsRoleType(role, userSortParams);
            }
            return returnSortOrder(userSortParams);
        } else {
            return '';
        }
    };
    return (
        <div>
            {isMobile &&
                <Table aria-label="UserList Listing" className="usergroup-preview-mobile"
                    style={{ fontFamily: fontFamilyName }}>
                    <TableBody>
                        {searchResult.data.map((userDetail, index) => (
                            <TableHead  className="table-head" style={{ width: '100%' }}>
                                <TableRow key={userDetail.id}>
                                    <TableCell className="table-cell-th">USER NAME</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.userName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-cell-th">FULL NAME</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.fullName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-cell-th">EMAIL</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.emailId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-cell-th">USER LEVEL</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.role}</TableCell>
                                </TableRow>
                                <TableRow className="row-seperator">
                                    <TableCell className="table-cell-th">COMPANY</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.companyName}</TableCell>
                                </TableRow>
                                <TableRow className="row-seperator">
                                    <TableCell className="table-cell-th">DIVISION</TableCell>
                                    <TableCell className="table-cell-value">{userDetail.divisionName}</TableCell>
                                </TableRow>
                                <TableRow className="row-seperator">
                                    <TableCell className="table-cell-th-last">TEAM</TableCell>
                                    <TableCell className="table-cell-value-last">{userDetail.teamName}</TableCell>
                                </TableRow>
                            </TableHead>
                        ))}
                    </TableBody>
                </Table>
            }
            {!isMobile &&
                <Table aria-label="User Listing" style={{ borderCollapse: 'separate', tableLayout: 'fixed', fontFamily: fontFamilyName }} className="usergroup-preview user-preview">
                    <TableHead className="table-head">
                        <TableRow className="table-row-th">
                            <TableCell className="table-cell-th" style={{width: '10%'}}> 
                                    <button
                                        type="button"
                                        onClick={() => requestSort('userName')}
                                        className={getSortedListClassName('userName')}
                                        style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName }}
                                    >USER NAME{userName}
                                    </button>
                            </TableCell>
                            <TableCell className="table-cell-th" style={{width: '12%'}}> 
                                    <button
                                        type="button"
                                        onClick={() => requestSort('fullName')}
                                        className={getSortedListClassName('fullName')}
                                        style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName  }}
                                    >FULL NAME{fullName}
                                    </button>
                            </TableCell>
                            <TableCell className="table-cell-th email-th" style={{width: '16%'}}> 
                                    <button
                                        type="button"
                                        onClick={() => requestSort('emailId')}
                                        className={getSortedListClassName('emailId')}
                                        style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName  }}
                                    >EMAIL{emailId}
                                    </button>
                            </TableCell>
                            <TableCell className="table-cell-th" style={{width: '13%'}}> 
                                    <button
                                        type="button"
                                        onClick={() => requestSort('roleType')}
                                        className={getSortedListClassName('roleType')}
                                        style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName }}
                                    >USER LEVEL{role}
                                    </button>
                            </TableCell>
                            <TableCell className="table-cell-th" style={{width: '13%'}}>
                                <button
                                    type="button"
                                    onClick={() => requestSort('companyName')}
                                    className={getSortedListClassName('companyName')}
                                    style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName  }}
                                >COMPANY{company}
                                </button>
                            </TableCell>
                            <TableCell className="table-cell-th" style={{width: '13%'}}>
                                <button
                                    type="button"
                                    onClick={() => requestSort('divisionName')}
                                    className={getSortedListClassName('divisionName')}
                                    style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName  }}
                                >DIVISION{division}
                                </button>
                            </TableCell>
                            <TableCell className="table-cell-th" style={{width: '13%'}}>
                                <button
                                    type="button"
                                    onClick={() => requestSort('teamName')}
                                    className={getSortedListClassName('teamName')}
                                    style={{ backgroundColor: 'transparent',fontFamily: fontFamilyName }}
                                >TEAM{team}
                                </button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="user-table-body">
                        {searchResult.data.map((userDetail, index) => (
                            <TableRow key={userDetail.id} style={{
                                backgroundColor: 'white', width: '100%'
                            }}  className={activeIndex === index? "table-row-sel-user" : "table-row-inact-user" }  onClick={() => handleSelectUser(index)}>
                                <TableCell component="th" scope="row" className="table-cell-value-user table-cell-value-user-start">
                                    {userDetail.userName}
                                </TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.fullName}</TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.emailId}</TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.role}</TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.companyName}</TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.divisionName}</TableCell>
                                <TableCell className="table-cell-value-user">{userDetail.teamName}</TableCell>
                                <TableCell className="table-cell-value-user table-cell-value-user-end">
                                    <div className='edit-delete edit-user-btn' style={{ display: 'flex' }}>
                                        <div >
                                            <span className='edit-user-group risk-tbl-btn' 
                                                onClick={() => onEditUser(userDetail.userId)}>Edit</span> 
                                        </div>
                                        {userDetail.roleTypeId > 1 &&
                                            <div>
                                                <Button onClick={() => handleUserDeleteDialog(true, userDetail)} className="delete-user-group" style={{ padding: '0.2rem 0.15rem 0rem 0.15rem' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </Button>
                                            </div>}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </div>
    );

};


UserListPreview.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
};

export default UserListPreview;

function returnSortOrder(userSortParams) {
    return userSortParams.sortOrder === 'ascending' ? 'ascending' : 'descending';
}

function whenNameEqualsRoleType(role, userSortParams) {
    let roleVal = role;
    roleVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return roleVal;
}

function whenNameEqualsEmailId(emailId, userSortParams) {
    let emailIdVal = emailId;
    emailIdVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return emailIdVal;
}

function whenNameEqualsFullName(fullName, userSortParams) {
    let fullNameVal = fullName;
    fullNameVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return fullNameVal;
}

function whenNameEqualsUserName(userName, userSortParams) {
    let userNameVal = userName;
    userNameVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return userNameVal;
}

function whenNameEqualsDivisionName(division, userSortParams) {
    let divisionVal = division;
    divisionVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return divisionVal;
}

function whenNameEqualsCompanyName(company, userSortParams) {
    let companyVal = company;
    companyVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return companyVal;
}

function whenNameEqualsTeamName(team, userSortParams) {
    let teamVal = team;
    teamVal = userSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return teamVal;
}
