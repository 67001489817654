import React from 'react';
import Header from "./Header";
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import FooterContainer from '../containers/FooterContainer';
import history from '../util/history';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import configLocal from '../commercialConfig';
import UrlConstants from '../constants/UrlConstants';
import NotFoundPage from './NotFoundPage';
import SpinnerContainer from '../containers/SpinnerContainer';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            invalidUrl: false, 
        };
    }

    getLoginUrl = () => {
        let restoreUrl = window.localStorage.getItem('restoreUrl') || this.props.homePath;
        if ((restoreUrl === null || restoreUrl === "") && this.props.productId !== null
            && this.props.profileId !== null && typeof (this.props.productId) !== 'undefined'
            && typeof (this.props.profileId) !== 'undefined') {
            restoreUrl = `/products/${this.props.productId}/profiles/${this.props.profileId}`;
        }
        window.history.replaceState(null, null, window.location.pathname);
        return restoreUrl;
    }
    onLoginComplete = () => {
        let url = this.getLoginUrl() + `/manage`;
        history.push(url);
        window.localStorage.removeItem('restoreUrl');
    }

    componentDidUpdate(prevProps) {
        const { loggedIn, tokenStatus, onResetTokenDetails,
            restoreURL, homePath, claudtoken, loggedInUserDetail } = this.props;
 
        if (loggedIn && !prevProps.loggedIn) {
            this.onLoginCompleteWhenIdPresent(loggedInUserDetail);
        }
        if ( tokenStatus !== null && prevProps.tokenStatus !== tokenStatus &&
            (tokenStatus === "TOKEN EXPIRED" || tokenStatus === "TOKEN INVALID")) {
                window.localStorage.setItem('productId', this.props.productId);
                window.localStorage.setItem('profileId', this.props.profileId);
                if (tokenStatus === "TOKEN EXPIRED") {
                    onResetTokenDetails();
                    restoreURL();
                    window.location.replace(`${configLocal.CLAUD_LOGIN_URL}`);
                }  else {
                    window.history.replaceState(null, null, window.location.pathname);
                    history.push(homePath);
                    this.claudLogout();
                }
        }
        else if ( claudtoken && !claudtoken.error
            && claudtoken.error !== prevProps.claudtoken.error
            && !loggedIn && claudtoken != null && claudtoken.message !== null
            && claudtoken.message !== undefined && claudtoken.message.includes("Login Failed")) {
            let restoreUrl = window.localStorage.getItem('restoreUrl') || this.props.homePath;
            if (restoreUrl === null && this.props.productId !== null
                && this.props.profileId !== null && typeof (this.props.productId) !== 'undefined'
                && typeof (this.props.profileId) !== 'undefined') {
                restoreUrl = `/products/${this.props.productId}/profiles/${this.props.profileId}`;
            }
            window.history.replaceState(null, null, window.location.pathname);
            history.push(restoreUrl);
            this.claudLogout();
        }
    }

    onLoginCompleteWhenIdPresent(loggedInUserDetail) {
        if (loggedInUserDetail !== null && loggedInUserDetail.id !== null) {
            this.onLoginComplete();
        }
    }

    claudLogout() {
        var win = window.open(`${configLocal.CLAUD_LOGOUT_URL}`);
        setTimeout(function () {
            win.close();
        }, 900);
    }

    componentWillMount() {
        const { onSaveCode, loggedIn, onFetchClaudToken,
            productId, profileId, tokenStatus, loadLoginPage, loggedInUserDetail } = this.props;
        if (window.location.href.toUpperCase().includes('?CODE')) {
            const code = this.getCode();
            if (code) {
                onSaveCode(code);
                let product = productId || window.localStorage.getItem('productId');
                let profile = profileId || window.localStorage.getItem('profileId');
                onFetchClaudToken(product, profile, code);
                window.localStorage.removeItem('productId');
                window.localStorage.removeItem('profileId');
            }
        } else if ((window.location.href.toUpperCase().includes('/PRODUCTS/'
            + UrlConstants.PRODUCT_ID_10 + '/PROFILES/')
            || window.location.href.toUpperCase().includes('/PRODUCTS/'
                + UrlConstants.PRODUCT_ID_9 + '/PROFILES/'))
            && !window.location.href.toUpperCase().includes('/UNDEFINED')) {
            this.loginForProductAndProfile(productId, profileId, loadLoginPage, loggedIn, tokenStatus, loggedInUserDetail);
        } else if (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_8
            || profileId === UrlConstants.PROFILE_ID_411) {
            let url = `/products/${productId}/profiles/${profileId}`;
            history.push(url);
        } else {
            this.setState({ invalidUrl: true });
        }
    }

    loginForProductAndProfile(productId, profileId, loadLoginPage, loggedIn, tokenStatus, loggedInUserDetail) {
        if (typeof (productId) !== 'undefined' && typeof (profileId) !== 'undefined') {
            loadLoginPage(productId, profileId);
        }
        if (loggedIn && ((tokenStatus === null || tokenStatus === "") ||
            (tokenStatus != null && tokenStatus === "TOKEN NOT EXPIRED"))
            && loggedInUserDetail !== null && loggedInUserDetail.id !== null) {
            this.onLoginComplete();
        }
    }

    getCode() {
        const query = parse(window.location.search.substr(1));
        return query && query.code;
    }

    render() {

        const styles = {
            headerLogoLeftPaddingTop: ".46875rem",
        };

        const loginAction = () => {
            const { restoreURL } = this.props;
            restoreURL(this.props.productId, this.props.profileId);
            window.localStorage.setItem('productId', this.props.productId);
            window.localStorage.setItem('profileId', this.props.profileId);
            if(configLocal.CLAUD_LOGIN_URL.indexOf('http://localhost:3000')) {
                let url = `${configLocal.CLAUD_LOGIN_URL}`;
                window.location.replace(url.replace('http://localhost:3000',`${configLocal.DEV_BASE_URL}`));
             } else {
                window.location.replace(`${configLocal.CLAUD_LOGIN_URL}`);
            }
        };

        const button = <CustomFlatButtonContainer
            className="input"
            id="login-button"
            label="Login"
            type="primary"
            keyboardFocus={false}
            tabIndex={0}
            onPress={()=>loginAction()}
            style={{
                fontFamily: styles.globalFontFamily,
                borderRadius: styles.buttonRadius,
                width: "6rem",
                backgroundColor: "rgb(244, 67, 54)",
                color: "white",
            }}
        />;
        const { invalidUrl } = this.state;
        const {  claudtoken } = this.props;
        const error = (claudtoken !== null && claudtoken.message !== null &&
            !claudtoken.message.includes("Logged out successfully.")) ?
            <span className="user-validation" ><h3><center>{claudtoken.message}
                {claudtoken.message.includes("Failed to connect to the server") &&
                    <div className="auth-label"> Failed to log in: The authentication servers are currently down for maintenance.</div>}
            </center></h3> </span> : null
        return (
            <div>
                {
                    !invalidUrl ?
                        <div className="homepage cce">
                            <SpinnerContainer />
                            <Header debug={false} styles={styles}
                                images={{headerLogoLeftUrl: "https://content.rpdata.com/zap/dev/logo-110.png" }}
                                text={{headerTitle: "| Cordell Commercial Estimator"}}/>
                            <div className='login'> 
                                <div id='title' className='title'>
                                    {error}
                                    <h1>Welcome to Cordell Commercial Estimator</h1>
                                    {button}
                                </div>
                            </div>
                            <FooterContainer />
                        </div>
                        :
                        <NotFoundPage />
                }
            </div>
        );
    }
}
LoginPage.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    onFetchClaudToken: PropTypes.func.isRequired,
    onSaveCode: PropTypes.func.isRequired,
    isLogout: PropTypes.bool.isRequired,
    onResetTokenDetails: PropTypes.func.isRequired,
};



export default LoginPage;
