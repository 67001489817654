import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { inputStyle } from '../style/styles';
import SectionHeader from './SectionHeader';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import UrlConstants from '../constants/UrlConstants';
import { filter, isEmpty ,sortBy } from 'lodash';
import SpinnerContainer from '../containers/SpinnerContainer';
import ExistingUserDialog from './ExistingUserDialog';

class AddUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            isCompany: false,
            isDivision: false,
            isTeam: true,
            errorMsg: null,
            successMsg: null,
            errorUserNameMsg: null,
            errorFullNameMsg: null,
            errorEmailIdMsg: null,
            errorDivisionMsg: null,
            errorCompanyMsg: null,
            errorTeamMsg: null,
            errorUserLevelMsg: null,
            userValidationMsg: null,
            emailValidationMsg: null,
        };
    }

    componentWillMount() {
        const { getUserGroups, profileId, productId, getRoleType, clearData, onLoad } = this.props;
        onLoad();
        getUserGroups(productId, profileId);
        getRoleType(productId, profileId);
        clearData();
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
    }

    handleUserGroup = (event) => {
        const { setUserGroupOption, clearData, isAddUserEnabled } = this.props;
        if (isAddUserEnabled) {
            clearData();
        }
        setUserGroupOption(event.target.value);
        if (event.target.value === 'Company') {
            this.setState({ isCompany: true, isDivision: false, isTeam: false });
        } else if (event.target.value === 'Division') {
            this.setState({ isCompany: false, isDivision: true, isTeam: false });
        } else {
            this.setState({ isCompany: false, isDivision: false, isTeam: true });
        }
        this.setState({ successMsg: null, errorMsg: null });
    }

    onChangeCompany = (event) => {
        const { onChangeCompanyName } = this.props;
        onChangeCompanyName(event.target.value);
    }
    onChangeuserId = (event) => {
        const { onChangeuserId } = this.props;
        onChangeuserId(event.target.value);
    }
    onChangefullName = (event) => {
        const { onChangefullName } = this.props;
        onChangefullName(event.target.value);
    }
    onChangeUserEmail = (event) => {
        const { onChangeUserEmail } = this.props;
        onChangeUserEmail(event.target.value);
    }

    onChangeDivision = (event) => {
        const { onChangeDivisionName } = this.props;
        onChangeDivisionName(event.target.value);
    }
    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    onCompanySelectionChange = (value) => {
        const { updateCompanySelection } = this.props;
        updateCompanySelection(value);

    }
    onDivisionSelectionChange = (value) => {
        const { updateDivisionSelection } = this.props;
        updateDivisionSelection(value);
    }
    onChangeTeam = (value) => {
        const { updateTeamSelection } = this.props;
        updateTeamSelection(value);
    }
    onBackToUser = () => {
        const { onBackToUser } = this.props;
        onBackToUser();
    }

    onAddUserRecord = () => {
        const { productId, profileId, userDetail, addUser, userGroupOption, selectedTeam, selectedCompany, selectedDivision } = this.props;
        let userid = null;
        let useremailid = null;
        let userfullName = null;
        userid = userDetail.userId;
        userfullName = userDetail.userfullname;
        useremailid = userDetail.useremailId;
        let haserror = 0;
        haserror = this.newMethod_8(userid, haserror, userfullName);

        haserror = this.newMethod_10(useremailid, haserror, userDetail);

        haserror = this.newMethod_123(userGroupOption, haserror);

        setUserDetails({haserror, userDetail, userGroupOption, selectedCompany}, selectedDivision, selectedTeam, addUser, productId, profileId);
    };


    newMethod_123(userGroupOption, haserror) {
        if (isEmpty(userGroupOption) || userGroupOption === "select") {
            this.setState({ errorUserLevelMsg: '*Please Select  an User Level' });
            haserror = 1;
        }

        else if (!isEmpty(userGroupOption) || userGroupOption !== "select") {
            this.setState({ errorUserLevelMsg: null });
            if (userGroupOption === "Company") {

                if (this.props.selectedCompany === null) {
                    this.setState({ errorCompanyMsg: '*Please select a  Company' });
                    haserror = 1;
                }
                else {
                    this.setState({ errorCompanyMsg: null });
                }
            }
            else if (userGroupOption === "Division") {
                haserror = this.newMethod_14(haserror);

            }
            else if (userGroupOption === "Team" || userGroupOption === "TeamMember") {
                haserror = this.newMethod_12(haserror);
            }
        }
        else {
            this.setState({ errorUserLevelMsg: null });
        }
        return haserror;
    }

    newMethod_14(haserror) {
        if (this.props.selectedCompany === null || this.props.selectedCompany === "select") {
            this.setState({ errorCompanyMsg: '*Please select a  Company' });
            haserror = 1;
        }
        else {
            this.setState({ errorCompanyMsg: null });
        }
        if (this.props.selectedDivision === null || this.props.selectedDivision === "select") {
            this.setState({ errorDivisionMsg: '*Please select a Division' });
            haserror = 1;
        }
        else {
            this.setState({ errorDivisionMsg: null });
        }
        return haserror;
    }

    newMethod_12(haserror) {
        if (this.props.selectedCompany === null || this.props.selectedCompany === "select") {
            this.setState({ errorCompanyMsg: '*Please select a Company' });
            haserror = 1;
        }
        else {
            this.setState({ errorCompanyMsg: null });
        }

        if (this.props.selectedDivision === null || this.props.selectedDivision === "select") {
            this.setState({ errorDivisionMsg: '*Please select a Division' });
            haserror = 1;
        }
        else {
            this.setState({ errorDivisionMsg: null });
        }
        if (this.props.selectedTeam === null || this.props.selectedTeam.team === "select") {
            this.setState({ errorTeamMsg: '*Please select a Team' });
            haserror = 1;
        }
        else {
            this.setState({ errorTeamMsg: null });
        }
        return haserror;
    }

    newMethod_10(useremailid, haserror, userDetail) {
        if (useremailid === null || useremailid === "" || useremailid === undefined) {
            this.setState({ errorEmailIdMsg: "*Please enter User Email" });
            haserror = 1;
        } else {
            this.setState({ errorEmailIdMsg: null });

            if (userDetail != null && useremailid.trim() !== "") {
                haserror = this.newMethod_13(useremailid, haserror);
            } else {
                this.setState({ errorEmailIdMsg: "*Please enter a Valid Email." });
                haserror = 1;
            }
        }
        return haserror;
    }

    newMethod_13(useremailid, haserror) {
        if (useremailid.length <= 2 || useremailid.indexOf("@") === -1 ||
            useremailid.indexOf(".") === -1 || useremailid.slice(-1) === "."
            || useremailid.indexOf(".@") !== -1 || useremailid.indexOf("@.") !== -1
            || useremailid.indexOf("..") !== -1 || useremailid.indexOf("._.") !== -1
            || useremailid.charAt(0) === "." || useremailid.charAt(0) === "_"
            || useremailid.charAt(0) === "-" || useremailid.trim().indexOf(' ') !== -1) {
            this.setState({ errorEmailIdMsg: "*Please enter a Valid Email." });
            haserror = 1;
        } else {
            haserror = this.newMethod_9(useremailid, haserror);
        }
        return haserror;
    }

    newMethod_9(useremailid, haserror) {
        let index;
        let haserrorValue = haserror;
        for (index = 0; index < useremailid.length; index++) {
            if ((useremailid[index].toLowerCase() ===
                useremailid[index].toUpperCase()) &&
                isNaN(useremailid[index])
                && useremailid[index] !== '_' && useremailid[index] !== '.'
                && useremailid[index] !== '@' && useremailid[index] !== '-') {
                this.setState({ errorEmailIdMsg: "*Please enter a Valid Email." });
                haserrorValue = 1;
            }
        }
        let parts = useremailid.split("@");
        if (parts !== null) {
            if (parts.length === 2) {
                let dot = parts[1].indexOf(".");
                let dotSplits = parts[1].split(".");
                let dotCount = dotSplits.length - 1;
                if (dot === -1 || dot < 2 || dotCount > 3) {
                    this.setState({ errorEmailIdMsg: "*Please enter a Valid Email." });
                    haserrorValue = 1;
                }
            } else {
                this.setState({ errorEmailIdMsg: "*Please enter a Valid Email." });
                haserrorValue = 1;
            }
        }
        return haserrorValue;
    }

    newMethod_8(userid, haserror, userfullName) {
        if (userid === null || userid === "" || userid === undefined) {
            this.setState({ errorUserNameMsg: "*Please enter a User Name" });
            haserror = 1;
        } else {
            this.setState({ errorUserNameMsg: null });
        }

        if (userfullName === null || userfullName === "" || userfullName === undefined) {
            this.setState({ errorFullNameMsg: "*Please enter First Name and Last Name" });
            haserror = 1;
        } else {
            this.setState({ errorFullNameMsg: null });
        }
        return haserror;
    }

    render() {
        const { styles, userGroupOption, userNameFound, productId, profileId, userDetail, loggedInUserDetail, existingUser, existingUserDialog, cancelExistingUser } = this.props;
        const { companyUserGroupsByCompanyname, divisionUserGroupsBydivisionname, teamUserGroupsByteamname } = this.newMethod_3();
        let fontFamilyName = styles.globalFontFamily;
        var { pageHeader, isCompanyDisplay, error, success, buttonText } = this.getVariables(userGroupOption);

        return (
            <div className='user-group-management'>
                <Card className='group-card'>
                    <SectionHeader name={pageHeader} color={styles.sectionTileColour} styles={styles} />


                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">User Name<p className="usr-mandat">*</p></label>
                        <div className="group-detail-field">
                            <TextField
                                className="group-field"
                                id="userId"
                                type="text"
                                fullWidth={true}
                                value={this.props.userDetail.userId === null ? "" : this.props.userDetail.userId}
                                onChange={(event, newValue) => {
                                    this.onChangeuserId(event);
                                }}
                                onBlur={(event) => {
                                    userNameFound(productId, profileId, userDetail.userId, "username");
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type a User Name"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>
                    <div className="user-validation"> {this.state.errorUserNameMsg}</div>
                    <div className="user-validation"> {this.props.userDetail.userValidationMsg}</div>
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">Name<p className="usr-mandat">*</p></label>
                        {this.newMethod_7(fontFamilyName)}
                    </CardText>
                    <div className="user-validation"> {this.state.errorFullNameMsg}</div>



                    {this.newMethod_2(userNameFound, productId, profileId, userDetail, fontFamilyName)}
                    <div className="user-validation"> {this.state.errorEmailIdMsg}</div>
                    <div className="user-validation"> {this.props.userDetail.emailValidationMsg}</div>

                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">User Level<p className="usr-mandat">*</p></label>
                        <div className="group-detail-field">
                            <FormControl>
                                {this.newMethod_33(fontFamilyName, loggedInUserDetail)}
                            </FormControl>

                        </div>
                    </CardText>
                    <div className="user-validation"> {this.state.errorUserLevelMsg}</div>
                    {(isCompanyDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="company" className="group-detail-label">Company<p className="usr-mandat">*</p></label>
                        <FormControl>
                            {this.newMethod_4(fontFamilyName, companyUserGroupsByCompanyname)}
                        </FormControl>
                    </CardText>}
                    <div className="user-validation"> {this.state.errorCompanyMsg}</div>
                    {this.newMethod_5(fontFamilyName, divisionUserGroupsBydivisionname)}
                    <div className="user-validation"> {this.state.errorDivisionMsg}</div>
                    {this.newMethod_6(fontFamilyName, teamUserGroupsByteamname)}
                    <div className="user-validation"> {this.state.errorTeamMsg}</div>
                    <CardText>
                        {error}
                        {success}
                    </CardText>
                    <div><SpinnerContainer /></div>
                    <CardText>
                        <Button id="create" className="action" variant="outlined"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onAddUserRecord}
                        >{buttonText}
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                         <Button className="action" variant="outlined" id="back"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onBackToUser}
                        >Back
                        </Button>
                    </CardText>
                </Card>

                <ExistingUserDialog
                    styles={this.props.styles}
                    existingUser={existingUser}
                    existingUserDialog={existingUserDialog}
                    cancelExistingUser={cancelExistingUser}
                    isUserNameSearch={userDetail.isUserNameSearch}
                />
            </div>
        );
    }

    newMethod_7(fontFamilyName) {
        return <div className="group-detail-field">
            <TextField
                className="group-field"
                id="userfullname"
                type="text"
                fullWidth={true}
                value={this.props.userDetail.userfullname === null ? "" : this.props.userDetail.userfullname}
                onChange={(event, newValue) => {
                    this.onChangefullName(event);
                } }
                floatingLabelFixed={true}
                placeholder="Type First Name and Last Name"
                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }} />
        </div>;
    }

    newMethod_6(fontFamilyName, teamUserGroupsByteamname) {
        return (this.props.userGroupOption === "Team" || (this.props.userGroupOption === "TeamMember")) &&
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="teamName" className="group-detail-label">Team<p className="usr-mandat">*</p></label>
                <FormControl>
                    <Select id="team-select" className="select-box"
                        style={{ fontFamily: fontFamilyName }}
                        value={this.props.selectedTeam !== null ? this.props.selectedTeam.team : "select"}
                        tabIndex={1}
                        onChange={(event) => {
                            this.onChangeTeam({ team: event.target.value });
                        } }
                    >
                        <MenuItem value="select">Select Team</MenuItem>
                        {teamUserGroupsByteamname.map((option) => <MenuItem key={option.id}
                            value={option.id}>
                            {option.teamName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </CardText>;
    }

    newMethod_5(fontFamilyName, divisionUserGroupsBydivisionname) {
        return (this.props.userGroupOption === "Team" || (this.props.userGroupOption === "Division") || (this.props.userGroupOption === "TeamMember")) &&
            <CardText className="group-detail-form" style={{ display: 'flex' }}>
                <label id="division" className="group-detail-label">Division<p className="usr-mandat">*</p></label>
                <FormControl>
                    <Select id="division-select" className="select-box"
                        style={{ fontFamily: fontFamilyName }}
                        value={this.props.selectedDivision !== null ? this.props.selectedDivision.division : "select"}
                        tabIndex={1}
                        onChange={(event) => {
                            this.onDivisionSelectionChange({ division: event.target.value });
                        } }
                    >
                        <MenuItem value="select">Select Division</MenuItem>
                        {this.props.selectedCompany !== null && divisionUserGroupsBydivisionname.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                            <MenuItem key={option.id}
                                value={option.id}>
                                {option.divisionName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </CardText>;
    }

    newMethod_4(fontFamilyName, companyUserGroupsByCompanyname) {
        return <Select id="company-select" className="select-box"
            style={{ fontFamily: fontFamilyName }}
            value={this.props.selectedCompany !== null
                ? this.props.selectedCompany.company : "select"}
            tabIndex={1}
            onChange={(event) => {
                this.onCompanySelectionChange({ company: event.target.value });
            } }
        >
            <MenuItem value="select">Select Company</MenuItem>
            {companyUserGroupsByCompanyname.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                <MenuItem
                    key={option.id}
                    value={option.id}>
                    {option.companyName}</MenuItem>
            )}
        </Select>;
    }

    newMethod_33(fontFamilyName, loggedInUserDetail) {
        return <Select id="user-group-select" className="select-box"
            style={{ fontFamily: fontFamilyName }}
            value={this.props.userGroupOption}
            onChange={this.handleUserGroup}
            tabIndex={1}>
            <MenuItem value="select">Select User Level</MenuItem>
            {loggedInUserDetail.roleTypeId < UrlConstants.COMPANY_ROLE_TYPE_ID &&
                <MenuItem value="Company">Company Administrator</MenuItem>}
            {loggedInUserDetail.roleTypeId < UrlConstants.DIVISION_ROLE_TYPE_ID &&
                <MenuItem value="Division">Division Administrator</MenuItem>}
            {loggedInUserDetail.roleTypeId < UrlConstants.TEAM_ROLE_TYPE_ID &&
                <MenuItem value="Team">Team Administrator</MenuItem>}
            <MenuItem value="TeamMember">Team Member</MenuItem>"
        </Select>;
    }

    newMethod_2(userNameFound, productId, profileId, userDetail, fontFamilyName) {
        return <CardText className="group-detail-form" style={{ display: 'flex' }}>
            <label id="userGroup" className="group-detail-label">Email<p className="usr-mandat">*</p></label>
            <div className="group-detail-field">
                <TextField
                    className="group-field"
                    id="useremailId"
                    type="text"
                    fullWidth={true}
                    value={this.props.userDetail.useremailId === null ? "" : this.props.userDetail.useremailId}
                    onChange={(event, newValue) => {
                        this.onChangeUserEmail(event);
                    } }
                    onBlur={(event) => {
                        userNameFound(productId, profileId, userDetail.useremailId, "email");
                    } }
                    floatingLabelFixed={true}
                    placeholder="Type a Valid Email"
                    inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }} />
            </div>
        </CardText>;
    }

    getVariables(userGroupOption) {
        const success = (this.state.successMsg !== null || this.props.userDetail.successMsg !== null) ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'green', fontWeight: 'bold' }}> {this.props.userDetail.successMsg}</div> : null;
        const pageHeader = "Add User";
        const buttonText = "Create";
        const error = this.props.userDetail.errorMsg !== null ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red', fontWeight: 'bold' }}>{this.props.userDetail.errorMsg}</div> : null;

        let isCompanyDisplay = false;
        if (userGroupOption === "Team" || userGroupOption === "Company" || userGroupOption === "Division" || userGroupOption === "TeamMember") {
            isCompanyDisplay = true;
        }
        return { pageHeader, isCompanyDisplay, error, success, buttonText };
    }

    newMethod_3() {
        const companyUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID);
        });
        const companyUserGroupsByCompanyname = sortBy(companyUserGroups, (userGroup) => {
            return userGroup.companyName.toLowerCase();
        });
        const divisionUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID && this.props.selectedCompany != null &&
                userGroup.parentUserGroupId === this.props.selectedCompany.company);
        });
        const divisionUserGroupsBydivisionname = sortBy(divisionUserGroups, (userGroup) => {
            return userGroup.divisionName.toLowerCase();
        });
        const teamUserGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.TEAM_ROLE_TYPE_ID && this.props.selectedDivision != null &&
                userGroup.parentUserGroupId === this.props.selectedDivision.division);
        });
        const teamUserGroupsByteamname = sortBy(teamUserGroups, (userGroup) => {
            return userGroup.teamName.toLowerCase();
        });
        return { companyUserGroupsByCompanyname, divisionUserGroupsBydivisionname, teamUserGroupsByteamname };
    }
}

AddUser.propTypes = {
    styles: PropTypes.object.isRequired,
    setUserGroupOption: PropTypes.func,
    onChangeCompanyName: PropTypes.func,
    onChangeTeamName: PropTypes.func,
    onChangeuserId: PropTypes.func,
    onChangefullName: PropTypes.func,
    onChangeDivisionName: PropTypes.func,
    onChangeUserEmail: PropTypes.func,
    onChangeTeam: PropTypes.func,
    userNameFound: PropTypes.func,
};

export default AddUser;

function setUserDetails({haserror, userDetail, userGroupOption, selectedCompany}, selectedDivision, selectedTeam, addUser, productId, profileId) {
    if (haserror === 0 && userDetail != null) {
        if (userGroupOption === "Company" || userDetail.roleTypeId === 2 || userDetail.roleTypeId === "2") {
            userDetail.userGroupId = selectedCompany.company;
            userDetail.roleTypeId = UrlConstants.COMPANY_ROLE_TYPE_ID;
        } else if (userGroupOption === "Division" || userDetail.roleTypeId === 3 || userDetail.roleTypeId === "3") {
            userDetail.userGroupId = selectedDivision.division;
            userDetail.roleTypeId = UrlConstants.DIVISION_ROLE_TYPE_ID;
        } else if (userGroupOption === "Team" || userDetail.roleTypeId === 4 || userDetail.roleTypeId === "4") {
            userDetail.userGroupId = selectedTeam.team;
            userDetail.roleTypeId = UrlConstants.TEAM_ROLE_TYPE_ID;
        } else if (userGroupOption === "TeamMember" || userDetail.roleTypeId === 5 || userDetail.roleTypeId === "5") {
            userDetail.userGroupId = selectedTeam.team;
            userDetail.roleTypeId = UrlConstants.TEAM_MEMBER_ROLE_TYPE_ID;
        }
        addUser(productId, profileId, userDetail);
    }
}
