import apiBaseUrl from '../apiBaseUrl';

const searchEstimateApi = ({productId, profileId, searchBy, searchText, fromDate, toDate}, searchDateOption, sortParams, pageNumber,userId, pageSize) => {
    let pageNo = parseInt(pageNumber) - 1;
    let id =parseInt(userId);
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getRiskDetail?pageNo=${pageNo}&id=${id}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                searchBy: searchBy,
                searchText: searchText,
                fromDate: fromDate,
                toDate: toDate,
                searchDateOption: searchDateOption,
                sortBy: sortParams.dateChange === null ? "descending" : sortParams.dateChange,
                sortDirection: sortParams.sortOrder === null ? "lastUpdatedDate" : sortParams.sortOrder,
                pageSize: pageSize
            }),
        }
    );
};

export default searchEstimateApi;