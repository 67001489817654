import apiBaseUrl from '../apiBaseUrl';

export const getQuestions = ({productId, profileId, propertyId, address}, answers, 
    currentDataPartition, partnerId, 
    userCapturedDetail, debug = false, contentInsurance = false) => {
    let emailAddress;
    let fullName;
    if (userCapturedDetail !== null && userCapturedDetail !== undefined) {
        emailAddress = userCapturedDetail.emailAddress; 
        fullName = userCapturedDetail.fullName;
    } else {
        emailAddress = null;
        fullName = null; 
    }
    
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'address': address,
    };
    if (propertyId) {
        headers.propertyId = propertyId;
    }
    if (currentDataPartition) {
        headers.currentDataPartition = currentDataPartition;
    }
    partnerId = partnerId === null || partnerId === undefined ? 0 : partnerId;
    headers.debug = debug;

    headers.contentInsurance = contentInsurance;

    return fetch(
        `${apiBaseUrl()}products/${productId}/profiles/${profileId}/questions`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({answers, partnerId: partnerId, 
                emailAddress : emailAddress, 
                fullName : fullName }),
        }
    );
};