import apiBaseUrl from '../apiBaseUrl';

const deleteUserApi = (productId, profileId, id) => {

    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/deleteUser/${id}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'DELETE',
            headers,
        }
    );
};

export default deleteUserApi;