import React from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui';
import PropTypes from 'prop-types';


const RadioButtonCustom = ({ question, onChange, defaultAnswer, styles }) => {
    const { answerTextColour, answerSelectedTextColour, answerFontSize, globalFontFamily } = styles;

    const handleChange = (event, newSelection) => {
        onChange({ 'question': question.id, 'answer': newSelection });
    };

    const sortedOptions = question.options.sort((option1, option2) => option1.lineNo - option2.lineNo);
    let classes = '';
    let labelClass = 'radio-label';

    return (
        <div>
            <RadioButtonGroup
                tabIndex={0}
                name={question.text}
                className='radio-group'
                id={question.id.toString()}
                onChange={handleChange} defaultSelected={defaultAnswer}>
                {
                    (sortedOptions).map((option) => {
                        if (isNaN(option.label)) {
                            classes = option.label.length < 5 ? 'radio-button-custom medium' :
                                newFunction1(option);
                            labelClass = 'radio-label medium';
                        } else {
                            classes = 'radio-button-custom smaller';
                            labelClass = 'radio-label small';
                        }
                        let radioClass = defaultAnswer === option.id ? classes + " selected" : classes;
                        return <RadioButton className={radioClass}
                            key={option.id}
                            tabIndex={0}
                            value={option.id}
                            id={option.id}
                            label={<label className={labelClass}
                                style={{
                                    border: defaultAnswer === option.id ? '2px solid' : '1px solid',
                                    color: defaultAnswer === option.id ? answerSelectedTextColour : answerTextColour,
                                    backgroundColor: defaultAnswer === option.id ? styles.radioButtonSelectedColor : '',
                                    fontSize: answerFontSize,
                                    fontFamily: globalFontFamily,
                                }}
                                htmlFor={option.id} >{option.label}</label>}

                            labelStyle={{
                                position: 'absolute',
                                display: 'flex',
                            }}
                            iconStyle={{ opacity: '0' }}
                            disableTouchRipple={false}
                            disableFocusRipple={false}
                        />;
                    })
                }
            </RadioButtonGroup>
        </div>
    );
};

RadioButtonCustom.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultAnswer: PropTypes.number,
    styles: PropTypes.object.isRequired,
};

export default RadioButtonCustom;

function newFunction1(option) {
    return option.label.length > 12 && option.label.length < 19 ? 'radio-button-custom large' :
        newFunction2(option);
}
function newFunction2(option) {
    return option.label.length >= 19 && option.label.length <= 22 ? 'radio-button-custom larger' :
        newFunction3(option);
}

function newFunction3(option) {
    return option.label.length > 22 ? 'radio-button-custom largest' : 'radio-button-custom medium1';
}

