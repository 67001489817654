import AnalyticsAttributes from './AnalyticsAttributes';

class DefaultEstimate extends AnalyticsAttributes {
    constructor(ProductId, ProfileId, PropertyId, SessionId, Status, Description, {Estimate, TimeTakenToCalculate, PartnerId}) {
        super(ProductId, ProfileId, PropertyId, SessionId, Status, Description, PartnerId);
        this.Estimate = Estimate;
        this.DefaultCalculationTime = TimeTakenToCalculate;
        this.DefaultCalculation= 1;
    }
}

export default DefaultEstimate;