import apiBaseUrl from '../apiBaseUrl';

const debugReportApi = (productId, profileId, propertyId, answers, token, buildingList) => {
    const url = `${apiBaseUrl()}admin/products/${productId}/profiles/${profileId}/debug`;
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    if (propertyId) {
        headers['propertyId'] = propertyId;
    }
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({answers: answers, buildingList: buildingList}),
        }
    );
};

export default debugReportApi;