import Email from '../components/Email';
import {changeEmail, emailReport} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate,
        report: state.report,
        recipient: state.recipient,
        emailText: state.profile.text.email,
        message: state.message,
        autoMailMessage: state.autoMailMessage,
        styles: state.profile.styles,
        widthChange: state.widthChange,
        profile: state.profile, 
    };
};

export const mapDispatchToProps = (dispatch, emailReportAction = emailReport) => {
    return {
        onEmailChange: (email) => dispatch(changeEmail(email)),
        onEmailReport: () => dispatch(emailReportAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
