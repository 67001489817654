import React from 'react';
import { TextField } from 'material-ui';
import { inputStyle } from '../style/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import SpinnerContainer from '../containers/SpinnerContainer';

class EmailMessageDialog extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            addressErrorMsg: null,
            subjectErrorMsg: null,
        };
    }

    handleSendReport = () => {
    const { recipient, sendEmailReport } = this.props;
        if ((recipient.data.email === null || recipient.data.email === '')) {
            this.setState({ addressErrorMsg: "Enter a valid email address" });
        }else if((recipient.data.email !== null && recipient.data.email !== '')){
            this.setState({ addressErrorMsg: null });
        }
        if ((recipient.data.subject === null || recipient.data.subject === '')) {
            this.setState({ subjectErrorMsg: "Subject field cannot be left blank" });
        }else if((recipient.data.subject !== null && recipient.data.subject !== '')){
                     this.setState({ subjectErrorMsg: null });
                 }
         if ((recipient.data.subject !== null && recipient.data.subject !== '') && (recipient.data.email !== null && recipient.data.email !== '')){
            sendEmailReport();
         }

    }
    render() {
        const { recipient, onEmailChange, styles, onEmailSubjectChange, onEmailMessageChange, hideEmailMessageDialog } = this.props
        const { email: emailId, subject: subjectText, message: messageText } = recipient.data;
        const emailError = this.state.addressErrorMsg ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red' }}> *{this.state.addressErrorMsg}</div> : null;
        const subjectError = this.state.subjectErrorMsg ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red' }}> *{this.state.subjectErrorMsg}</div> : null;

        return (
                            <div className='email-message-dialog' align="left" style={{ display: 'inline-grid', width: '100%', padding: '10px', backgroundColor: '#f9f9f9' }}>
                                    <div className="email-report-label" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                                        <label style={{
                                            fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold',
                                        }}>To: {emailError}</label>

                                    </div>
                                    <TextField
                                        className="email"
                                        fullWidth={true}
                                        id="email"
                                        type="email"
                                        value={emailId || ''}
                                        variant="outlined"
                                        placeholder="Enter an email address"
                                        inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                                        underlineShow={false}
                                        onChange={(event, newValue) => {
                                            onEmailChange(isEmpty(newValue) ? null : newValue);
                                        }}
                                        style={{ paddingLeft: '1px !important' }}
                                    />
                                    <div className="email-subject-label" style={{ paddingBottom: '10px' }}>
                                        <label style={{
                                            fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold',
                                        }}>Subject: {subjectError}</label>

                                    </div>
                                    <TextField
                                        className="email-subject"
                                        fullWidth={true}
                                        id="subject"
                                        type="email"
                                        value={subjectText || ''}
                                        variant="outlined"
                                        placeholder="Enter email subject"
                                        inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                                        underlineShow={false}
                                        onChange={(event, newValue) => {
                                            onEmailSubjectChange(isEmpty(newValue) ? null : newValue);
                                        }}
                                        style={{ paddingLeft: '1px !important' }}
                                    />
                                    <div className="email-message-label" style={{ paddingBottom: '10px' }}>
                                        <label style={{
                                            fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold',
                                        }}>Message: </label>
                                    </div>
                                        <TextField
                                            className="email-message"
                                            fullWidth={true}
                                            type="text"
                                            id="message"
                                            multiline={true}
                                            rows={2}
                                            variant="outlined"
                                            value={messageText || ''}
                                            placeholder="Type a message to include with the email"
                                            inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                                            underlineShow={false}
                                            onChange={(event, newValue) => {
                                                onEmailMessageChange(isEmpty(newValue) ? null : newValue);
                                            }}
                                            style={{ paddingLeft: '1px !important' }}
                                        />
                                    <Button className="send" variant="outlined"
                                        style={{ backgroundColor: '#C3C3C3', textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', width: '30%' }}
                                        rounded="true"
                                        size="small"
                                        tabIndex={0}
                                        onKeyPressCapture={event => {
                                            if (event.key === 'Enter') {
                                                this.handleSendReport().call();
                                            }
                                        }}
                                        onClick={this.handleSendReport}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false}
                                    >
                                        Send Now
                                      <SpinnerContainer />
                                    </Button>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button className="cancel" variant="outlined"
                                            style={{ backgroundColor: '#C3C3C3', textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', width: '30%' }}
                                            rounded="true"
                                            size="small"
                                            tabIndex={0}
                                            onKeyPressCapture={event => {
                                                if (event.key === 'Enter') {
                                                    hideEmailMessageDialog().call();
                                                }
                                            }}
                                            onClick={hideEmailMessageDialog}
                                            disableTouchRipple={true}
                                            disableFocusRipple={false}
                                        >
                                            Cancel
                                                                  <SpinnerContainer />
                                        </Button>
                        </div>
        );
    }

}

EmailMessageDialog.propTypes = {
    recipient: PropTypes.object.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    emailText: PropTypes.string,
    message: PropTypes.string,
    styles: PropTypes.object,
    userNameDisplay: PropTypes.bool,
    onEmailMessageChange: PropTypes.func.isRequired,
    onEmailSubjectChange: PropTypes.func.isRequired
};

export default EmailMessageDialog;