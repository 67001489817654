import {connect} from 'react-redux';
import ContentInsurance from '../components/ContentInsurance';

export const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        styles: state.profile.styles,
        result: state.result.content,
        profileId: state.profileId,
        productId: state.productId,
        propertyId: state.addressChosen.propertyId,
        addressChosen: state.addressChosen.suggestion,
        onLetUsKnowDialogOpen: defaultProps.onLetUsKnowDialogOpen,
    }
};

export default connect(mapStateToProps) (ContentInsurance);