import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import {callToActionButton1Text, callToActionButton1Title} from '../style/styles';

const CallToActionButton1 = ({styles, text, ctaButton1Url, onCalltoActionButton}) => {

    let buttonLabel, buttonHeader, buttonHelpText, callToActionSectionTemplate;
    buttonLabel = text.ctaButton1Label;
    buttonHeader = text.ctaButton1Header;
    buttonHelpText = text.ctaButton1Text;
    callToActionSectionTemplate = text.callToActionSectionTemplate;

    const button = <CustomFlatButtonContainer
        className="input what-next-button"
        id="callToAction-us-button"
        label={buttonLabel}
        type="primary"
        keyboardFocus={false}
        onPress={()=>onCalltoActionButton(ctaButton1Url)}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
        }}
    />;

    return (
        <div className="mainDiv">
            <div className="input what-next-button">
                <div className="contactUs-header" style={{...callToActionButton1Title, fontFamily: styles.globalFontFamily}}>
                    <strong>{buttonHeader}</strong>
                </div>
                <div className="contactUs-header" style={{...callToActionButton1Text, fontFamily: styles.globalFontFamily}}>
                    <div dangerouslySetInnerHTML={{__html: buttonHelpText}}/>
                </div>
                <div style={{
                    paddingBottom: '1rem'
                }}>
                     {(callToActionSectionTemplate !== 'IAG3') && button}
                </div>
            </div>
        </div>
    );
};

CallToActionButton1.propTypes = {
    styles: PropTypes.object.isRequired,
    text: PropTypes.object.isRequired,
    ctaButton1Url: PropTypes.string,
    onCalltoActionButton: PropTypes.func.isRequired,
};

export default CallToActionButton1;