import React from 'react';
import PropTypes from 'prop-types';

const PropertyImage = ({aerialImage, propertyImage}) => {
    return (aerialImage || propertyImage) ?
        <div className="property-image-container">
            {aerialImage && <img src={'data:image/png;base64,' + aerialImage} className="aerial-image" alt="Aerial view of the property"/>}
            {propertyImage && <img src={propertyImage} className="property-image" alt="Street view of the property"/>}
        </div>
        : null;
};

PropertyImage.propTypes = {
    aerialImage: PropTypes.string,
    propertyImage: PropTypes.string,
};

export default PropertyImage;