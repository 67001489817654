import React from 'react';
import UserGroupPreview from './UserGroupPreview';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import SearchRisk from './SearchRisk';
import { Pagination } from 'semantic-ui-react';
import UrlConstants from '../constants/UrlConstants';
import history from '../util/history';
import UserGroupDeleteDialog from './UserGroupDeleteDialog';
import SpinnerContainer from '../containers/SpinnerContainer';

class FilterGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            isCompany: false,
            isDivision: false,
            isTeam: true,
            activeIndex: null
        };
    }
    handleSelectGroup = (index) => { 
        this.setState({ activeIndex: index }); 
    };

    componentWillMount() {
        const { onLoad } = this.props;
        onLoad();
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
        const { searchUserGroupResults, isAdminUserRole, productId, profileId, userGrpId } = this.props;
        searchUserGroupResults();
        isAdminUserRole(productId, profileId, userGrpId, "groupPage");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { searchUserGroupResults, groupSearchParams, groupSortParams, grpPagination, deleteGroup } = this.props;
        if (groupSearchParams.searchBy !== prevProps.groupSearchParams.searchBy ||
            (((groupSearchParams.searchText != null && groupSearchParams.searchText.length > 2)
                || (groupSearchParams.searchText == null || groupSearchParams.searchText === "")) &&
                groupSearchParams.searchText !== prevProps.groupSearchParams.searchText)
            || groupSortParams.sortBy !== prevProps.groupSortParams.sortBy
            || groupSortParams.sortOrder !== prevProps.groupSortParams.sortOrder
            || grpPagination.activePage !== prevProps.grpPagination.activePage
            || (deleteGroup.open !== prevProps.deleteGroup.open && deleteGroup.refresh !== true)) {
            searchUserGroupResults();
        }

    }

    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    onChangeSearchText = (e, { value }) => {
        const { setGroupSearchText } = this.props;
        setGroupSearchText(value);
    };

    handleChange = (event) => {
        const { setGroupSearchBy } = this.props;
        setGroupSearchBy(event.target.value);
    };

    onPageChange = (event, data) => {
        const { updateGroupActivePageNo } = this.props;
        updateGroupActivePageNo(data.activePage);
    }
    onEditUserGroup = (userGroupId) => {
        const { getUserGroupDetail, productId, profileId } = this.props;
        getUserGroupDetail(productId, profileId, userGroupId);
        let url = `/products/${productId}/profiles/${profileId}/admin`;
        history.push(url);
    };
    requestSort = (sortBy) => {
        const { sortUserGroups, groupSortParams } = this.props;
        let sortOrder = 'descending';
        if (groupSortParams && groupSortParams.sortBy === sortBy && groupSortParams.sortOrder === 'descending') {
            sortOrder = 'ascending';
        }
        sortUserGroups(sortBy, sortOrder);
    };

    handleUserGroupDeleteDialog = (open, userGroupDetail) => {
        const { userGroupDeleteDialog } = this.props
        userGroupDeleteDialog(open, userGroupDetail);
    }

    handleUserGroupDelete = (userGroupId) => {
        const { productId, profileId, deleteUserGroup } = this.props;
        deleteUserGroup(productId, profileId, userGroupId);
    }

    

    render() {
        const { deleteError, profileId, productId, styles, onAddGroup, userGroupSearchResult, deleteGroup, isAdminUser, loggedInUserDetail } = this.props;

        let fontFamilyName = styles.globalFontFamily;
        return (
            <div className='cce-search'>
                <Card className='filter-card user-filter-card' style={{ marginLeft: '1px', marginTop: '-10px' }}>

                    <div className="action-nav">
                        <div className="nav-previous" style={{ alignSelf: 'flex-start' }}>
                            <div className="action-usergroup-header">Group Management:</div>
                        </div>
                        <div className='nav-next nav-user-next' style={{ alignSelf: 'flex-end' }}>
                            <Button className="button add" variant="outlined"
                                style={{ fontFamily: fontFamilyName }}
                                rounded="true"
                                size="small"
                                tabIndex={1}
                                onClick={onAddGroup}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                disabled={!isAdminUser}
                            >+ Add Group</Button>
                        </div>
                    </div>
                    <div className="action-nav">
                        <div className="nav-previous" style={{ alignSelf: 'flex-start', width: '100%' }}>
                            <CardText className='est-filter-card-text' style={{ flexDirection: 'column' }}>
                                <div className='fields  est-fields flex' style={{ padding: '0.3rem 0rem' }}>
                                    <FormControl className='search-select dropdown-filter' style={{}}>
                                        <Select disableUnderline className="select-box est-select-box user-select-box"
                                            style={{ fontFamily: fontFamilyName, width: '12rem' }}
                                            value={this.props.groupSearchParams.searchBy}
                                            onChange={this.handleChange}
                                            tabIndex={1}>
                                            <MenuItem value="companyName">Show All Company</MenuItem>
                                            <MenuItem value="divisionName">Show All Division</MenuItem>
                                            <MenuItem value="teamName">Show All Team</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className='est-search user-search'>
                                        <SearchRisk
                                            name="addressInput"
                                            productId={productId}
                                            profileId={profileId}
                                            styles={styles}
                                            searchText={this.props.groupSearchParams.searchText}
                                            fontFamilyName={fontFamilyName}
                                            onChangeSearchText={this.onChangeSearchText}
                                            pageName="userMgmt"
                                        /><SpinnerContainer />
                                    </div>
                                </div>
                                <div className="action-nav">
                                    <div className="nav-previous user-page-no" style={{
                                        alignSelf: 'flex-start', fontFamily: fontFamilyName
                                    }}>
                                        <span>Displaying {userGroupSearchResult.currentPageSize} of {userGroupSearchResult.totalElements} Groups</span>
                                    </div>
                                </div>
                            </CardText>
                        </div>
                    </div>


                </Card>
                <div className='cce-risk-preview-card' style={{ fontFamily: fontFamilyName }}>
                <div className="pagination">
                    <Pagination className="pagination"
                        activePage={this.props.grpPagination.activePage}
                        totalPages={this.props.grpPagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                    />
                </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }} className='risk-data'>
                    <div className='usergroup-preview-div'>
                        <UserGroupPreview
                            productId={productId}
                            profileId={profileId}
                            fontFamilyName={fontFamilyName}
                            searchResult={userGroupSearchResult}
                            isMobile={this.state.widthChange <= UrlConstants.MOBILE_INNER_WIDTH}
                            onEditUserGroup={this.onEditUserGroup}
                            requestSort={this.requestSort}
                            groupSortParams={this.props.groupSortParams}
                            handleUserGroupDeleteDialog={this.handleUserGroupDeleteDialog}
                            loggedInUserDetail={loggedInUserDetail}
                            handleSelectGroup={this.handleSelectGroup}
                            activeIndex={this.state.activeIndex}
                        />
                    </div>
                </div>

                <div className='cce-risk-preview-card' style={{ fontFamily: fontFamilyName }}>
                    <div className="pagination">
                    <Pagination className="pagination"
                        activePage={this.props.grpPagination.activePage}
                        totalPages={this.props.grpPagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                    />
                </div>
                </div>

                <UserGroupDeleteDialog
                    styles={this.props.styles}
                    deleteGroup={deleteGroup}
                    handleUserGroupDeleteDialog={this.handleUserGroupDeleteDialog}
                    handleUserGroupDelete={this.handleUserGroupDelete}
                    loggedInUserDetail={loggedInUserDetail}
                    deleteError= {deleteError}
                />
            </div>
        );
    }
}

FilterGroup.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    searchUserGroupResults: PropTypes.func.isRequired
};

export default FilterGroup;