import { connect } from 'react-redux';
import LoginPage from "../components/LoginPage";
import {
    fetchToken, saveCode, resetTokenDetails, restoreURL, loadLoginPage
} from '../actions/loginActionCreator';

const mapStateToProps = (state, defaultProps) => {
    const claudtoken = state.claudtoken;
    return {
        productId: (state.productId == null || typeof (state.productId) === 'undefined') ?
            getProductIdLoginPage(defaultProps)
            : state.productId,
        profileId: (state.profileId == null || typeof (state.profileId) === 'undefined') ?
            getProfileIdLoginPage(defaultProps)
            : state.profileId,
        isLogout: state.isLogout,
        loginLoading: state.loginLoading,
        loggedIn: !!claudtoken.access_token && claudtoken.validToken,
        loggedInUserDetail : state.loggedInUserDetail,
        tokenStatus: state.tokenStatus,
        homePath: state.homePath,
        claudtoken : state.claudtoken,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onFetchClaudToken: (productId, profileId, code) => dispatch(fetchToken(productId, profileId, code)),
        onSaveCode: (code) => dispatch(saveCode(code)),
        restoreURL: () => dispatch(restoreURL()),
        onResetTokenDetails: () => {
            dispatch(resetTokenDetails());
        },
        loadLoginPage: (productId, profileId) => dispatch(loadLoginPage(productId, profileId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

function getProfileIdLoginPage(defaultProps) {
    return (defaultProps.profileId == null || typeof (defaultProps.profileId) === 'undefined') ?
        defaultProps.match.params.profileId : defaultProps.profileId;
}

function getProductIdLoginPage(defaultProps) {
    return (defaultProps.productId == null || typeof (defaultProps.productId) === 'undefined') ?
        defaultProps.match.params.productId : defaultProps.productId;
}
