import { connect } from 'react-redux';
import AddUserGroup from '../components/AddUserGroup';
import { bindActionCreators } from 'redux';
import {
    setUserGroupOption, onChangeCompanyName, onChangeDivisionName, onChangeTeamName,
    onChangeCompanyDisclaimer, addUserGroup, getUserGroupsAction, getRoleTypeAction,
    updateCompanySelection, updateDivisionSelection, clearData, getUserGroupRole ,onBackToUserGroup ,
    deleteUserGroupLogo, downloadImage, updateUserGroupLogo, updateLogo, updateGroupPolicySelection
} from '../actions/searchActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        userGroupOption: state.userGroupOption,
        companyName: state.companyName,
        teamName: state.teamName,
        divisionName: state.divisionName,
        disclaimer: state.disclaimer,
        roleId: state.roleId,
        isActive: state.isActive,
        productId: state.productId,
        profileId: state.profileId,
        userGroups: state.userGroups,
        roleType: state.roleType,
        selectedDivision: state.selectedDivision,
        selectedCompany: state.selectedCompany,
        isEditUserGroup: state.isEditUserGroup,
        userGroupDetail: state.userGroupDetail,
        loggedInUserDetail: state.loggedInUserDetail,
        loggedInUserGroupRole: state.loggedInUserGroupRole
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setUserGroupOption,
        onChangeCompanyName,
        onChangeCompanyDisclaimer,
        onChangeTeamName,
        onChangeDivisionName,
        addUserGroup,
        getUserGroupsAction,
        getRoleTypeAction,
        updateDivisionSelection,
        updateCompanySelection,
        clearData,
        getUserGroupRole,
        onBackToUserGroup,
        deleteUserGroupLogo,
        downloadImage,
        updateUserGroupLogo,
        updateLogo,
        updateGroupPolicySelection,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        setUserGroupOption: dispatchProps.setUserGroupOption,
        onChangeCompanyName: dispatchProps.onChangeCompanyName,
        onChangeCompanyDisclaimer: dispatchProps.onChangeCompanyDisclaimer,
        onChangeTeamName: dispatchProps.onChangeTeamName,
        onChangeDivisionName: dispatchProps.onChangeDivisionName,
        addUserGroup: dispatchProps.addUserGroup,
        getUserGroups: dispatchProps.getUserGroupsAction,
        getRoleType: dispatchProps.getRoleTypeAction,
        updateDivisionSelection: dispatchProps.updateDivisionSelection,
        updateCompanySelection: dispatchProps.updateCompanySelection,
        clearData: dispatchProps.clearData,
        getUserGroupRole: dispatchProps.getUserGroupRole,
        onBackToUserGroup: dispatchProps.onBackToUserGroup,
        deleteUserGroupLogo: dispatchProps.deleteUserGroupLogo,
        downloadImage: dispatchProps.downloadImage,
        updateUserGroupLogo: dispatchProps.updateUserGroupLogo,
        updateLogo: dispatchProps.updateLogo,
        updateGroupPolicySelection:dispatchProps.updateGroupPolicySelection,
        onLoad: () => {
          const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'AddEdit UserGroup Page', ownProps.location.pathname, stateProps.sessionTrackId, "AddEditUserGroupPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("AddEdit UserGroup Page", pageDetails);
           },

    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddUserGroup);