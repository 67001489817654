import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const UserGroupPreview = ({ productId, profileId, fontFamilyName, searchResult, activeIndex, isMobile, onEditUserGroup, requestSort, groupSortParams, loggedInUserDetail,
    handleUserGroupDeleteDialog, handleSelectGroup }) => {
    let team = null;
    let company = null;
    let division = null;
    let group = null;
    const getClassName = (name) => {
        if (!groupSortParams) {
            return;
        }
        if (groupSortParams.sortBy === name) {
            if (name === "role") {
                group = groupWhenNameEqualsRole(group, groupSortParams);
            } else if (name === "companyName") {
                company = companyWhenNameEqualsCompanyName(company, groupSortParams);
            } else if (name === "divisionName") {
                division = divisionWhenNameEqualsDivisionName(division, groupSortParams);
            } else if (name === "teamName") {
                team = teamWhenNAmeEqualsTeamNAme(team, groupSortParams);
            }
            return groupSortParams.sortOrder === 'ascending' ? 'ascending' : 'descending';
        } else {
            return '';
        }
    };
    return (
        <div>
            {isMobile &&
                <Table aria-label="UserGroup Listing" className="usergroup-preview-mobile"
                    style={{ fontFamily: fontFamilyName }}>
                    <TableBody>
                        {searchResult.data.map((userGroupDetail, index) => (
                            <TableHead>
                                <TableRow key={userGroupDetail.id}>
                                    <TableCell className="table-cell-th">GROUP</TableCell>
                                    <TableCell className="table-cell-value">{userGroupDetail.role}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-cell-th">COMPANY</TableCell>
                                    <TableCell className="table-cell-value">{userGroupDetail.companyName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="table-cell-th">DIVISION</TableCell>
                                    <TableCell className="table-cell-value">{userGroupDetail.divisionName}</TableCell>
                                </TableRow>
                                <TableRow className="row-seperator">
                                    <TableCell className="table-cell-th">TEAM</TableCell>
                                    <TableCell className="table-cell-value">{userGroupDetail.teamName}</TableCell>
                                </TableRow>
                            </TableHead>
                        ))}
                    </TableBody>
                </Table>
            }
            {!isMobile &&
                <Table aria-label="UserGroup Listing" style={{ borderCollapse: 'separate', tableLayout: 'fixed', fontFamily: fontFamilyName  }} className="usergroup-preview user-preview">
                    <TableHead className="table-head">
                        <TableRow className="table-row-th">
                            <TableCell className="table-cell-th">
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => requestSort('role')}
                                        className={getClassName('role')}
                                        style={{ backgroundColor: 'transparent', fontFamily: fontFamilyName }}
                                    >GROUP{group}
                                    </button>
                                </div>
                            </TableCell>
                            <TableCell className="table-cell-th">
                                <button
                                    type="button"
                                    onClick={() => requestSort('companyName')}
                                    className={getClassName('companyName')}
                                    style={{ backgroundColor: 'transparent' }}
                                >COMPANY{company}
                                </button>
                            </TableCell>
                            <TableCell className="table-cell-th">
                                <button
                                    type="button"
                                    onClick={() => requestSort('divisionName')}
                                    className={getClassName('divisionName')}
                                    style={{ backgroundColor: 'transparent' }}
                                >DIVISION
                                        {division}
                                </button>
                            </TableCell>
                            <TableCell className="table-cell-th">
                                <button
                                    type="button"
                                    onClick={() => requestSort('teamName')}
                                    className={getClassName('teamName')}
                                    style={{ backgroundColor: 'transparent' }}
                                >TEAM{team}
                                </button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="user-table-body">
                        {loggedInUserDetail.roleTypeId !== 5 && searchResult.data.map((userGroupDetail, index) => (
                            <TableRow key={userGroupDetail.id} style={{
                                backgroundColor: 'white', width: '100%'
                            }}  className={activeIndex === index? "table-row-sel-user" : "table-row-inact-user" }  onClick={() => handleSelectGroup(index)}>
                                <TableCell component="th" scope="row" className="table-cell-value-user table-cell-value-user-start">
                                    {userGroupDetail.role}
                                </TableCell>
                                <TableCell className="table-cell-value-user">{userGroupDetail.companyName}</TableCell>
                                <TableCell className="table-cell-value-user">{userGroupDetail.divisionName}</TableCell>
                                <TableCell className="table-cell-value-user">{userGroupDetail.teamName}</TableCell>
                                <TableCell className="table-cell-value-user table-cell-value-user-end">
                                    <div className='edit-delete edit-user-btn' style={{ display: 'flex' }}>
                                    <div >
                                            <span className='edit-user-group risk-tbl-btn' 
                                                onClick={() => onEditUserGroup(userGroupDetail.id)}>Edit</span> 
                                        </div>
                                    
                                    {userGroupDetail.roleTypeId > 1 &&
                                        <div>
                                        <Button onClick={() => handleUserGroupDeleteDialog(true, userGroupDetail)} className="delete-user-group" style={{ padding: '0.2rem 0.15rem 0rem 0.15rem' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </Button>
                                    </div>
                                    }
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </div>
    );

};


UserGroupPreview.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
};

export default UserGroupPreview;

function teamWhenNAmeEqualsTeamNAme(team, groupSortParams) {
    let teamVal = team;
    teamVal = groupSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return teamVal;
}

function divisionWhenNameEqualsDivisionName(division, groupSortParams) {
    let divisionVal = division;
    divisionVal = groupSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return divisionVal;
}

function companyWhenNameEqualsCompanyName(company, groupSortParams) {
    let companyVal = company;
    companyVal = groupSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return companyVal;
}

function groupWhenNameEqualsRole(group, groupSortParams) {
    let groupVal = group;
    groupVal = groupSortParams.sortOrder === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    return groupVal;
}
