import ServerError from './ServerError';
import history from '../util/history';
import searchEstimateApi from '../api/searchEstimateApi'
import getCCEEstimateDetailsApi from '../api/getCCEEstimateDetailsApi';
import repriceEstimateApi from '../api/repriceEstimateApi';
import deleteRiskApi from '../api/deleteRiskApi';
import deleteEstimateApi from '../api/deleteEstimateApi';
import getCCEEstimateDetailsByEstimateApi from '../api/getCCEEstimateDetailsByEstimateApi';
import { groupByDependantFlag } from '../components/Section';
import getRiskByIdApi from '../api/getRiskByIdApi';
import addUserGroupApi from '../api/addUserGroupApi';
import getUserGroupsApi from '../api/getUserGroupsApi';
import getRoleTypeApi from '../api/getRoleTypeApi';
import filterUserGroupsApi from '../api/filterUserGroupsApi';
import getUserGroupDetailApi from '../api/getUserGroupDetailApi';
import filterUsersApi from '../api/filterUsersApi';
import deleteUserGroupApi from '../api/deleteUserGroupApi';
import deleteUserGroupLogoApi from '../api/deleteUserGroupLogoApi';
import deleteUserApi from '../api/deleteUserApi';
import getUserDetailApi from '../api/getUserDetailApi';
import addUserApi from '../api/addUserApi'
import { debounce, findIndex } from 'lodash';
import editUserApi from '../api/editUserApi';
import userNameFoundApi from '../api/userNameFoundApi';
import getUserListByUserIdApi from '../api/getUserListByUserIdApi';
import isAdminUserApi from '../api/isAdminUserApi';
import getUserGroupRoleApi from '../api/getUserGroupRoleApi';
import getUserAccountApi from '../api/getUserAccountApi';
import repriceExportApi from '../api/repriceExportApi';
import exportEstimateDetailsApi from '../api/exportEstimateDetailsApi';
import repriceExportStatusApi from '../api/repriceExportStatusApi'; 
import updateUserGroupLogoApi from '../api/updateUserGroupLogoApi';
import { validateMandatoryFields } from './calculationActionCreator';
import {trackSegmentEvent} from "../actions/segmentActionsCreator";
import {SegmentTrackingEventTypes} from "../constants/SegmentTrackingEventTypes";
import AnalyticsEvent from '../util/AnalyticsEvent';

const DEBOUNCE_WAIT_LOAD = 500;

const handleJSONResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

export const serverError = (error) => {
    if (error.status === 503) {
        history.push('/unavailable');
        return { type: 'SERVER_UNAVAILABLE', error: error.message };
    } else if (error.status === 404) {
        history.push('/not_found');
        return { type: 'SERVER_ERROR', errorCode: error.type, error: error.message };
    } else {
        return { type: 'SERVER_ERROR', errorCode: error.type, error: error.message };
    }
};

export const getServerErrorMessage = (type, error) => {
    if (error.status === 404 && error != null && error.message !== null) {
        if (error.message.includes('still have jobs assigned. please re-assign jobs before deleting.')) {
            if (type === "User") {
                return {
                    type: 'DELETE_ERROR', errorCode:
                        "User could not be deleted as the user still have estimate assigned. Please re-assign estimates before deleting."
                }

            } else {
                return {
                    type: 'DELETE_ERROR', errorCode:
                        "User Group could not be deleted as the group contains user that still have estimate assigned. Please re-assign estimates before deleting."
                }

            }
        }
    } else {
        return { type: 'DELETE_ERROR', errorCode: type + " could not be deleted." };

    }
};

export const updateSearchResult = (searchResults) => {
    return { type: 'UPDATE_SEARCH_RESULT', searchResults };
};

export const searchEstimateResults = ({productId, profileId, searchBy, searchText}, fromDate, toDate, searchDateOption, sortParams,
    pageNumber, pageSize) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: 'ATTEMPT_REPRICE_OR_EMAIL_ESTIMATE' });
        const event = new AnalyticsEvent("Search Estimates", "Search estimates by filter criteria", "Estimate Management Page", "");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.SEARCH_ESTIMATE, event));
        searchEstimateApi({productId, profileId, searchBy, searchText, fromDate, toDate}, searchDateOption, sortParams, pageNumber, state.loggedInUserDetail.id, pageSize)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
            dispatch({ type: 'REPRICED_ESTIMATE' });
                return dispatch(updateSearchResult(body));
            })
            .catch((error) => dispatch({ type: 'OFF_SEARCH_LOADING_ON_ERROR'}));
    }
};

export const setSearchBy = (value) => {
    return (dispatch) => {
        dispatch({ type: 'SEARCH_BY', searchBy: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const setPostcode = (value) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_POSTCODE', postcode: value });
    };
};

export const setPerPageRecords = (value) => {
    return (dispatch) => {
        dispatch({ type: 'PER_PAGE_RECORDS', pageSize: value });
    };
};

export const setSearchText = (value) => {
    return (dispatch) => {
        dispatch({ type: 'SEARCH_TEXT', searchText: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onAddGroup = () => {
    return (dispatch) => {
        const event = new AnalyticsEvent("Add User Group", "Click Add User Group Button", "AddEdit User Group Page", "Add User Group");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADD_USER_GROUP, event));
        dispatch({ type: 'ADD_GRP_ENABLED', isAddGrp: true, isEditUserGroup: false, userGroupOption: "Team" });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const setGroupSearchBy = (value) => {
    return (dispatch) => {
        dispatch({ type: 'GROUP_MNG_SEARCH_BY', searchBy: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const setGroupSearchText = (value) => {
    return (dispatch) => {
        dispatch({ type: 'GROUP_MNG_SEARCH_TEXT', searchText: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const fromDateChange = (fromDate) => {
    return (dispatch) => {
        if(fromDate != null) { 
            let month = '' + (fromDate.getMonth() + 1);
            let day = '' + fromDate.getDate();
            let year = fromDate.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            fromDate = [year, month, day].join('-');
            dispatch({ type: 'FROM_DATE', fromDate: fromDate });
        } else {
             dispatch({ type: 'FROM_DATE', fromDate: null });
        }
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const toDateChange = (toDate) => {
    return (dispatch) => {
        if( toDate != null) {
            let month = '' + (toDate.getMonth() + 1);
            let day = '' + toDate.getDate();
            let year = toDate.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            toDate = [year, month, day].join('-');
            dispatch({ type: 'TO_DATE', toDate: toDate });
        } else {
             dispatch({ type: 'TO_DATE', toDate: null });
        }
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const setSearchDateOption = (searchDateOption) => {
    return (dispatch) => {
        dispatch({ type: 'SEARCH_DATE_OPTION', searchDateOption: searchDateOption });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onClearFilter = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_FILTER' });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onClearGroupFilter = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_GROUP_FILTER' });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};


export const searchEstimateByRiskId = (riskId, productId, profileId, index) => {
    return (dispatch) => getCCEEstimateDetailsApi(riskId, productId, profileId)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            return dispatch(updateEstimatePreview(body, index, riskId));
        }).catch((error) => dispatch(serverError(error)));
};

export const updateEstimatePreview = (estimateResult, index, riskId) => {
    return { type: 'UPDATE_ESTIMATE_PREVIEW', data: estimateResult, activeIndex: index, riskId: riskId };
};

export const sortRiskListByDate = (sortBy) => {
    return (dispatch) => {
        dispatch({ type: 'SORT_BASED_ON_DATE', dateChange: sortBy });
    }
};

export const editRisk = (productId, profileId, riskDetail, riskId) => {
    return (dispatch, getState) => {
        dispatch({ type: 'RISK_EDIT', riskDetail: riskDetail, riskId: riskId });
        const event = new AnalyticsEvent("Risk Edit", "Click edit risk button", "Estimate Management Page", "edit risk");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDIT_RISK, event));
        const { loggedInUserDetail } = getState();
            return repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                    if (!body) {
                        dispatch(editRiskId(productId, profileId, riskDetail, riskId));
                    }
                });
    }
};

export const editRiskId = (productId, profileId, riskDetail, riskId) => {
    let url = `/products/${productId}/profiles/${profileId}/estimate`;
    history.push(url);
    var lastValuation = riskDetail.lastValuation  != null ? riskDetail.lastValuation : null;
     if(lastValuation  != null) {
        lastValuation = new Date(lastValuation);
        if(isNaN(lastValuation.getTime())) {
           lastValuation =riskDetail.lastValuation.trim();
           let month ="";
            switch (lastValuation.substr(3,3)) {
                case 'Jan': month = "01"; break;
                case 'Feb': month = "02"; break;
                case 'Mar': month = "03"; break;
                case 'Apr': month = "04"; break;
                case 'May': month = "05"; break;
                case 'Jun': month = "06"; break;
                case 'Jul': month = "07"; break;
                case 'Aug': month = "08"; break;
                case 'Sep': month = "09"; break;
                case 'Oct': month = "10"; break;
                case 'Nov': month = "11"; break;
                default:  month = "12"; break;

            }
            lastValuation = lastValuation.substr(7,4) + "/" + month  + "/" + lastValuation.substr(0,2);
            lastValuation = lastValuation.trim();
        }
    }
    return { type: 'EDIT_RISK_CLICKED', data: riskDetail, riskId: riskId, lastValuation: lastValuation };
};

export const deleteRisk = (productId, profileId, riskId) => {
    return (dispatch) => deleteRiskApi(productId, profileId, riskId, null)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            return dispatch(deleteRiskDetails());
        }).catch((error) => dispatch(serverError(error)));

};

export const deleteMultipleRisks = (productId, profileId) => {
    return (dispatch, getState) => {
        const {  risks } = getState();
        return deleteRiskApi(productId, profileId, null, risks)
             .then((response) => handleJSONResponse(response))
             .then(() => {
            return dispatch(deleteMultipleRiskDetails());

            }).catch((error) => dispatch(serverError(error)));
    };
};
export const deleteMultipleRiskDetails = () => {
    return (dispatch) => {
        dispatch(removeAllRisk());
        dispatch(deleteRiskDetails());
    }
};
export const deleteRiskDetails = () => {
    return (dispatch) => {
        const event = new AnalyticsEvent("Risk Deleted", "Deleted risk response", "Estimate Management Page", "Deleted risk");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETED_RISK, event));
        dispatch({ type: 'DELETE_RISK', data: false });
    }
};

export const setSortingOrder = (order) => {
    return (dispatch) => {
        dispatch({ type: 'SORTING_ORDER', sortOrder: order });
    }
};

export const updateActivePageNo = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: 'PAGE_NO_UPDATE', pageNo: pageNumber });
    }
};
export const updateGroupActivePageNo = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: 'PAGE_NO_UPDATE_GROUP', pageNo: pageNumber });
    }
};

export const repriceEstimate = (productId, profileId, riskId, estimateId, index) => {
    return (dispatch, getState) => {
        dispatch({ type: 'REPRICE_ESTIMATE', riskId: riskId, estimateId: estimateId, activeIndex: index });
        const { loggedInUserDetail } = getState();
            return repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                    const event = new AnalyticsEvent("Reprice Estimate", "Reprice Estimate response", "Estimate Management Page", "reprice estimate");
                    dispatch(trackSegmentEvent(SegmentTrackingEventTypes.REPRICE_ESTIMATE, event));
                    if (!body) {
                        dispatch(repriceEstimateId(productId, profileId, riskId, estimateId, index));
                    }
                });
    }
};

export const riskDetailDeleteDialog = (close, riskDetail) => {
    return (dispatch, getState) => {
        if (close) {
            dispatch({ type: 'RISK_DELETE', riskDetail: riskDetail });
            const event = new AnalyticsEvent("Risk Delete", "Delete risk request", "Estimate Management Page", "Delete risk");
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETE_RISK, event));
            const { productId, profileId, loggedInUserDetail } = getState();
            repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                    return dispatch({ type: 'RISK_DETAIL_DIALOG', data: !body, riskDetail: riskDetail, riskIds: null });
                });
        } else {
            return dispatch({ type: 'RISK_DETAIL_DIALOG', data: close, riskDetail: riskDetail, riskIds: null });
        }
    }
};

export const multipleRisksDeleteDialog = (close, riskIds) => {
    return (dispatch, getState) => {
        if (close) {
            dispatch({ type: 'RISK_DELETE', riskDetail: null });
            const event = new AnalyticsEvent("Risk Delete", "Delete risk request", "Estimate Management Page", "Delete multiple risks");
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETE_RISK, event));
            const { productId, profileId, loggedInUserDetail, risks } = getState();
            repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                    return dispatch({ type: 'RISK_DETAIL_DIALOG', data: !body, riskDetail: null, riskIds: risks.join(',') });
                });
        } else {
            return dispatch({ type: 'RISK_DETAIL_DIALOG', data: close, riskDetail: null, riskIds: riskIds });
        }
    }
};
export const deleteEstimate = (productId, profileId, estimateId, estimatePrice) => {
    return (dispatch) => deleteEstimateApi(productId, profileId, estimateId, estimatePrice)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            const event = new AnalyticsEvent("Estimate Delete", "Deleted estimate response", "Estimate Management Page", "Delete Estimate");
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETED_ESTIMATE, event));
            return dispatch(deleteEstimateDetails());
        }).catch((error) => dispatch(serverError(error)));

};
export const deleteEstimateDetails = () => {
    return (dispatch) => {
        dispatch({ type: 'DELETE_ESTIMATE', data: false });
    }
};

export const estimateDetailDeleteDialog = (close, estimateDetail) => {
    return (dispatch, getState) => {
        if (close) {
            dispatch({ type: 'ESTIMATE_DELETE', estimateDetail: estimateDetail });
        const event = new AnalyticsEvent("Estimate Delete", "Click Delete Estimate Link", "Estimate Management Page", "Delete Estimate");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETE_ESTIMATE, event));
            const { productId, profileId, loggedInUserDetail } = getState();
            repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
                .then((response) => handleJSONResponse(response))
                .then((body) => {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                    return dispatch({ type: 'ESTIMATE_DETAIL_DIALOG', data: !body, estimateDetail: estimateDetail });
                });
        } else {
            return dispatch({ type: 'ESTIMATE_DETAIL_DIALOG', data: close, estimateDetail: estimateDetail });
        }
    }
};

export const searchEstimateByEstimateId = (estimateId, productId, profileId, riskId, estimatePrice) => {

    return (dispatch, getState) => {
        const { loggedInUserDetail } = getState();
        dispatch({ type: 'EDIT_ESTIMATE', estimateId: estimateId, riskId: riskId, estimatePrice: estimatePrice });
        const event = new AnalyticsEvent("Estimate Edit", "Click Edit Estimate Link", "Estimate Management Page", "Edit Estimate");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDIT_ESTIMATE, event));
        return repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
                if (!body) {
                    dispatch(editEstimateId(estimateId, productId, profileId, riskId, estimatePrice));
                }
            });
    };
};

export const fetchRiskDetailById = (estimateId, productId, profileId, riskId, estimatePrice, isEditEstimate) => {
    return (dispatch) => getRiskByIdApi(productId, profileId, riskId)
        .then((response) => handleJSONResponse(response))
        .then((riskDetail) => {
            return dispatch(searchEstimateById(estimateId, productId, profileId, riskDetail, estimatePrice, isEditEstimate));
        }).catch((error) => dispatch(serverError(error)));
};

export const searchEstimateById = (estimateId, productId, profileId, riskDetail, estimatePrice, isEditEstimate) => {
    return (dispatch, getState) => {
        return getCCEEstimateDetailsByEstimateApi(estimateId, productId, profileId, estimatePrice, isEditEstimate)
            .then((response) => handleJSONResponse(response))
            .then((result) => {
                dispatch(updateQuestionResponse(result, riskDetail, estimateId, estimatePrice));
                if (isEditEstimate) {
                    const { questions } = getState();
                    return dispatch(validateMandatoryFields(productId, questions));
                }
            }).catch((error) => dispatch(serverError(error)));
    };
};

export const updateQuestionResponse = (result, body, estimateId, estimatePrice) => {
    const buildingGroups = [];
    result.buildingDetails.forEach(buildingDetail => {
        const group = groupByDependantFlag(buildingDetail.questions);
        buildingGroups.push(group);
    });
    if(buildingGroups.length > 0) {
        let indemnityQuesDoesNotExists = false;
        const lastBuildingGrp = buildingGroups[buildingGroups.length-1];
        lastBuildingGrp.forEach(group => {
            group.questions.forEach(question => {
                if (question.id === 303544 && question.answer === "25942") {
                    indemnityQuesDoesNotExists = true;
                }
            });
        });
        if(indemnityQuesDoesNotExists) {
            const indexYearBuilt = findIndex(result.questions.questions, (question) => {
                return question.id === 303545;
            });
            const indexYearOfBuilding = findIndex(result.questions.questions, (question) => {
                return question.id === 303546;
            });
            if (indexYearBuilt > 0) {
                result.questions.questions[indexYearBuilt] = { ...result.questions.questions[indexYearBuilt], answer: null };
            }
            if (indexYearOfBuilding > 0) {
                result.questions.questions[indexYearOfBuilding] = { ...result.questions.questions[indexYearOfBuilding], answer: null };
            }
        }
    }
    return {
        type: 'UPDATE_QUESTIONS', questions: result.questions.questions, estimateId: estimateId, buildingGrp: buildingGroups, address: result.address,
        propertyId: result.propertyId, risk: body, estimatePrice: estimatePrice, cceImages: result.cceImages, calculationResult: result.calculationResponse
    };
};

export const estimatePreviewDialog = (value, riskId, productId, profileId, index) => {
    return (dispatch) => {
        dispatch(searchEstimateByRiskId(riskId, productId, profileId, index))
            .then(() => {
                return dispatch({ type: 'ESTIMATE_PREVIEW_MOBILE', data: value })
            });
    }
};

export const estimatePreviewClose = () => {
    return { type: 'ESTIMATE_PREVIEW_CLOSE' };
};

export const emailDownloadReport = (productId, profileId, riskId, estimateId, estimatePrice) => {
    return (dispatch) => {
        dispatch({ type: 'ATTEMPT_REPRICE_OR_EMAIL_ESTIMATE' });
        dispatch(fetchRiskDetailById(estimateId, productId, profileId, riskId, estimatePrice, false))
            .then(() => {
                dispatch({ type: 'EMAIL_DOWNLOAD_REPORT' });
                return history.push(`/products/${productId}/profiles/${profileId}/estimate`);
            });
    }
};

export const setUserGroupOption = (userGroupOption) => {
    return (dispatch) => {
        dispatch({ type: 'USER_GROUP_OPTION', userGroupOption: userGroupOption });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeCompanyName = (companyName) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_COMPANY_NAME', companyName: companyName });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeCompanyDisclaimer = (disclaimer) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_COMPANY_DISCLAIMER', disclaimer: disclaimer });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeTeamName = (teamName) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_TEAM_NAME', teamName: teamName });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeDivisionName = (divisionName) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_DIVISION_NAME', divisionName: divisionName });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};


export const onChangeuserId = (userId) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_USER_ID', userId: userId });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangefullName = (userfullname) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_USER_FULL_NAME', userfullname: userfullname });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const onChangeUserEmail = (useremailId) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_USER_EMAIL_ID', useremailId: useremailId });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const updateTeamSelection = (team) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_SELECTED_TEAM', team: team });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const addUserGroup = ({productId, profileId, option, teamName, companyName, divisionName}, isActive, footerText, parentUserGroupId, isEditUserGroup, userGroupDetail, selectedFile) => {
    return (dispatch) => {
        dispatch({ type: 'BEFORE_SAVE_USER_GROUP' }); 
        addUserGroupApi({productId, profileId, option, teamName, companyName, divisionName}, isActive, footerText, parentUserGroupId, isEditUserGroup, userGroupDetail, selectedFile)
            .then(() => {
                const event = new AnalyticsEvent("AddEdit UserGroup", "AddEdit User Group response", "AddEdit UserGroup Page", "AddEdit UserGroup");
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADDED_USER_GROUP, event));
                return dispatch(getUserGroupsAction(productId, profileId));
            }).then(() => {
                if (isEditUserGroup) {
                    let id = userGroupDetail.teamId;
                    if(userGroupDetail.userGroupOption === "Company") {
                        id = userGroupDetail.companyId;
                    } else if(userGroupDetail.userGroupOption === "Division") {
                        id = userGroupDetail.divisionId;
                    } else if(userGroupDetail.userGroupOption === "System") {
                        id = userGroupDetail.systemId;
                    }
                    return dispatch(getUserGroupDetail(productId, profileId, id));
                }
            }).catch((error) => dispatch(serverError(error)));
        if (!isEditUserGroup) {
            dispatch({ type: 'CLEAR_DATA' });
        }
        dispatch({ type: 'AFTER_SAVE_USER_GROUP' });
    }
};
export const addUserDetailsApi = (dispatch, productId, profileId, userDetail, claudtoken) => {
    dispatch({ type: 'BEFORE_SAVE_USER_GROUP' });
    return addUserApi(productId, profileId, userDetail, claudtoken)
        .then((response) => handleJSONResponse(response))
        .then(() => {
            dispatch({ type: 'CREATE_USER_STATUS', successMsg: "User Successfully Created", errorMsg: null });
            dispatch({ type: 'CLEAR_DATA_USER' });
            const event = new AnalyticsEvent("AddEdit User", "User added", "AddEdit User Page", "AddEdit User");
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADDED_USER, event));
        }).catch((error) => {
            const errorObj = JSON.parse(JSON.stringify(error));
            const errorMessageObj = JSON.parse(errorObj.message);
            dispatch({ type: 'CREATE_USER_STATUS', successMsg: null, errorMsg: errorMessageObj.message });
        });
};
const debounceAddUserDetailsApi = debounce(addUserDetailsApi, DEBOUNCE_WAIT_LOAD);

export const addUser = (productId, profileId, userDetail) => {
    return (dispatch, getState) => {
        const { claudtoken } = getState();
        return debounceAddUserDetailsApi(dispatch, productId, profileId, userDetail, claudtoken);
    };
};

export const userNameFound = (productId, profileId, data, searchType) => {
    return (dispatch, getState) => {
        const { claudtoken } = getState();
        userNameFoundApi(productId, profileId, data, searchType, claudtoken)
            .then((response) => handleJSONResponse(response))
            .then(response => {
                if (searchType === 'username') {
                    if (response.emailId !== null) {
                        return dispatch({ type: 'UPDATE_USER_DETAILS', userId: response.userName, emailId: response.emailId, fullName: response.fullName, isUserNameSearch: true });
                    } else {
                        return dispatch({ type: 'UPDATE_USER_EXISTS', userValidationMsg: "* User Name already Exists, Please select a different User Name" });
                    }
                }
                if (searchType === 'email') {
                    if (response.userName !== null) {
                        return dispatch({ type: 'UPDATE_USER_DETAILS', userId: response.userName, emailId: response.emailId, fullName: response.fullName, isUserNameSearch: false });
                    } else {
                        return dispatch({ type: 'UPDATE_EMAIL_EXISTS', emailValidationMsg: "* Email already Exists, Please select a different Email" });
                    }
                }
            })
    }
}
export const getUserGroupsAction = (productId, profileId) => {
    return (dispatch, getState) => {
        const state = getState();
        getUserGroupsApi(productId, profileId, state.loggedInUserDetail.userGroupId)
            .then((response) => handleJSONResponse(response))
            .then((response) => {
                return dispatch(updateUserGroups(response));
            });
    }
};

export const updateUserGroups = (userGroupsResponse) => {
    return { type: 'UPDATE_USER_GROUPS', data: userGroupsResponse };
};

export const getRoleTypeAction = (productId, profileId) => {
    return (dispatch) => getRoleTypeApi(productId, profileId)
        .then((response) => handleJSONResponse(response))
        .then((response) => {
            return dispatch(updateRoleType(response));
        });
};

export const updateRoleType = (roleTypeResponse) => {
    return { type: 'UPDATE_ROLE_TYPES', data: roleTypeResponse };
};

export const updateCompanySelection = (company) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_SELECTED_COMPANY', company: company });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const updateDivisionSelection = (division) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_SELECTED_DIVISION', division: division });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const clearData = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_DATA' });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const updateGroupSearchResult = (userGroupSearchResult) => {
    return { type: 'UPDATE_GROUP_SEARCH_RESULT', userGroupSearchResult };
};
export const CloseUserGroupLoadingOnError = () => {
    return { type: 'OFF_GROUP_LOADING_ON_ERROR' };
};
export const filterGroupsApi = (dispatch, {productId, profileId}, userGroupId, searchBy, searchText, sortParams, pageNumber) => {
    dispatch({ type: 'BEFORE_REFRESH_USER_GROUP_LIST' });
    return filterUserGroupsApi(productId, profileId, userGroupId, searchBy, searchText, sortParams, pageNumber)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.SEARCH_USER_GROUP,
            new AnalyticsEvent("Search UserGroup", "Listed User Groups", "Filter UserGroup Page", "Filter UserGroup")));
            dispatch(updateGroupSearchResult(body));
        }
        ).catch((error) => dispatch(CloseUserGroupLoadingOnError()));
};

const debounceFilterGroupsApi = debounce(filterGroupsApi, DEBOUNCE_WAIT_LOAD);

export const searchUserGroupResults = (productId, profileId, userGroupId, searchBy, searchText, sortParams, pageNumber) => {
    return (dispatch) => {
        return debounceFilterGroupsApi(dispatch, {productId, profileId}, userGroupId, searchBy, searchText, sortParams, pageNumber);
    };
};

export const getUserGroupDetail = (productId, profileId, userGroupId) => {
    return (dispatch, getState) => {
        const { loggedInUserDetail, loggedInUserGroupRole } = getState();
        dispatch({ type: 'EDIT_USER_GROUP_CLICKED' });
        const event = new AnalyticsEvent("Edi User Group", "Click Edit User Group Button", "AddEdit User Group Page", "Edit User Group");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDIT_USER_GROUP, event));
        getUserGroupDetailApi(productId, profileId, userGroupId)
            .then((response) => handleJSONResponse(response))
            .then((response) => { 
                if (loggedInUserGroupRole != null && loggedInUserGroupRole.toLowerCase() === 'company' && response.companyId === loggedInUserDetail.userGroupId &&
                    loggedInUserDetail.roleTypeId === 2 && loggedInUserGroupRole.toLowerCase() === response.userGroup.toLowerCase()) {
                    dispatch(updateGroupDisclaimer(response.footerText,
                        loggedInUserDetail.divisionDisclaimer));
                }
                else if (loggedInUserGroupRole != null && loggedInUserGroupRole.toLowerCase() === 'division' && response.divisionId === loggedInUserDetail.userGroupId &&
                    loggedInUserDetail.roleTypeId === 3 && loggedInUserGroupRole.toLowerCase() === response.userGroup.toLowerCase()) {
                    dispatch(updateGroupDisclaimer(loggedInUserDetail.companyDisclaimer,
                        response.footerText));
                }
                return dispatch(updateUserGroupDetail(response));
            }).catch((error) => dispatch(serverError(error)));
    }
};

export const updateUserGroupDetail = (userGroupDetail) => {
    return { type: 'UPDATE_USER_GROUP_DETAIL', data: userGroupDetail };
};

export const updateGroupLogo = (companyLogo, divisionLogo) => {
    return { type: 'UPDATE_LOGGED_IN_LOGO', companyLogo: companyLogo, divisionLogo: divisionLogo };
};

export const updateGroupDisclaimer = (companyDisclaimer, divisionDisclaimer) => {
    return { type: 'UPDATE_LOGGED_IN_DISCLAIMER', companyDisclaimer: companyDisclaimer, divisionDisclaimer: divisionDisclaimer };
};

export const setUserSearchBy = (value) => {
    return (dispatch) => {
        dispatch({ type: 'USER_MNG_SEARCH_BY', searchBy: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const setUserSearchText = (value) => {
    return (dispatch) => {
        dispatch({ type: 'USER_MNG_SEARCH_TEXT', searchText: value });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onClearUserFilter = () => {
    return (dispatch) => {
        dispatch({ type: 'CLEAR_USER_FILTER' });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onAddUser = () => {
    return (dispatch) => {
        const event = new AnalyticsEvent("Add User", "Click Add User Button", "AddEdit User Page", "Add User");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADD_USER, event));
        dispatch({ type: 'ADD_USER_ENABLED', isAddUser: true });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};


export const updateUserActivePageNo = (pageNumber) => {
    return (dispatch) => {
        dispatch({ type: 'PAGE_NO_UPDATE_USER', pageNo: pageNumber });
    }
};
export const sortUserGroups = (sortBy, sortOrder) => {
    return (dispatch) => {
        dispatch({ type: 'SORT_BASED_COLUMN_SELECTION', sortBy: sortBy, sortOrder: sortOrder });
    }
};

export const sortUsers = (sortBy, sortOrder) => {
    return (dispatch) => {
        dispatch({ type: 'USER_SORT_BASED_COLUMN_SELECTION', sortBy: sortBy, sortOrder: sortOrder });
    }
};

export const updateUserSearchResult = (userSearchResult) => {
    return { type: 'UPDATE_USER_SEARCH_RESULT', userSearchResult };
};
export const CloseUserLoadingOnError = () => {
    return { type: 'OFF_USER_LOADING_ON_ERROR' };
};
export const filterUserApi = (dispatch, {productId, profileId}, userId, searchBy, searchText, sortParams, pageNumber) => {
    dispatch({ type: 'BEFORE_REFRESH_USER_LIST' });
    return filterUsersApi(productId, profileId, userId, searchBy, searchText, sortParams, pageNumber)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            const event = new AnalyticsEvent("Search User", "Listed Users", "Filter User Page", "Filter User");
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.SEARCH_USER, event));
            return dispatch(updateUserSearchResult(body));
        }).catch((error) => dispatch(CloseUserLoadingOnError()));
};
const debounceFilterUserApi = debounce(filterUserApi, DEBOUNCE_WAIT_LOAD);
export const searchUserResults = (productId, profileId, userId, searchBy, searchText, sortParams, pageNumber) => {
    return (dispatch) => {
        return debounceFilterUserApi(dispatch, {productId, profileId}, userId, searchBy, searchText, sortParams, pageNumber);
    }
};

export const userGroupDeleteDialog = (open, deleteUserGroupDetail) => {
    return (dispatch) => {
        const event = new AnalyticsEvent("User Group Delete", "Clicked User Group button", "Filter User Page", "Delete user group");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETE_USER_GROUP, event));
        return dispatch({type: 'USER_GROUP_DELETE_DIALOG', data: open, refresh: true, deleteUserGroupDetail: deleteUserGroupDetail });
    };
};
export const userDeleteDialog = (open, deleteUserDetail) => {
    return (dispatch, getState) => {
        const { productId, profileId, claudtoken } = getState();
            if (deleteUserDetail !== null) {
                getUserAccountApi(productId, profileId, deleteUserDetail.userId, claudtoken)
                    .then((response) => handleJSONResponse(response))
                    .then((response) => {
                    const event = new AnalyticsEvent("User Delete", "Clicked User button", "Filter User Page", "Delete user");
                    dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETE_USER, event));
                        return dispatch({ type: 'USER_DELETE_DIALOG', data: open, refresh: true, deleteUserDetail: deleteUserDetail, hasMultipleAccount: response });
                    });
            } else {
                return dispatch({ type: 'USER_DELETE_DIALOG', data: open, refresh: true, deleteUserDetail: deleteUserDetail, hasMultipleAccount: false });
            }
        }
};

export const deleteUserGroup = (productId, profileId, userGroupId) => {
    return (dispatch) => {
        dispatch({ type: 'DELETE_ERROR', errorCode: "" });
        deleteUserGroupApi(productId, profileId, userGroupId)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                const event = new AnalyticsEvent("User Group Deleted", "Deleted user group", "Filter UserGroup Page", "Deleted user group");
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETED_USER_GROUP, event));
                return dispatch(deletedUserGroup());
            })
            .catch((error) => { 
                dispatch(getServerErrorMessage("UserGroup", error));
            });
    }
};
export const deleteUserrecord = (productId, profileId, id) => {
    return (dispatch) => {
        dispatch({ type: 'DELETE_ERROR', errorCode: "" });
        deleteUserApi(productId, profileId, id)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                const event = new AnalyticsEvent("User Deleted", "Deleted user", "Filter User Page", "Deleted user");
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DELETED_USER, event));
                return dispatch(deletedUser());
            })
            .catch((error) => {
                dispatch(getServerErrorMessage("User", error));
            });
    }
};

export const deletedUserGroup = () => {
    return (dispatch) => {
        dispatch({ type: 'DELETED_USER_GROUP' });
    }
};
export const deletedUser = () => {
    return (dispatch) => {
        dispatch({ type: 'DELETED_USER' });
    }
};
export const fetchedUserDetails = (userDetail) => {
    return (dispatch) => {
        dispatch({ type: 'FETCHED_USER_DETAIL', data: userDetail });
    }
}
export const getUserDetail = (productId, profileId, userId) => {
    return (dispatch) => {
        dispatch({ type: 'EDIT_USER_DETAIL_CLICKED', editUserId: userId });
        const event = new AnalyticsEvent("Edi User", "Click Edit User Button", "AddEdit User Page", "Edit User");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDIT_USER, event));
        getUserDetailApi(productId, profileId, userId)
            .then((response) => handleJSONResponse(response))
            .then((response) => {
                return dispatch(fetchedUserDetails(response));
            });
    }
};

export const isAdminUserRole = (productId, profileId, userGroupId, from) => {
    return (dispatch) => {
        isAdminUserApi(productId, profileId, userGroupId, from)
            .then((response) => handleJSONResponse(response))
            .then((response) => {
                dispatch({ type: 'IS_ADMIN_USER', data: response });
            });
    }
};

export const getUserGroupRole = (productId, profileId, userGroupId) => {
    return (dispatch) => 
        getUserGroupRoleApi(productId, profileId, userGroupId)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
               return dispatch({ type: 'USER_GROUP_ROLE', data: body });
            }).catch((error) => dispatch(serverError(error)));
    
};

export const onChangeEditUserName = (editUserName) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_USER_NAME', editUserName: editUserName });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeEditUserStatus = (editUserStatus) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_USER_STATUS', editUserStatus: editUserStatus === 'deactivate' ? false :true });
    };
};
export const onChangeEditFullName = (editFullName) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_FULL_NAME', editFullName: editFullName });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const onChangeEditEmailId = (editEmailId) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_EMAIL_ID', editEmailId: editEmailId });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const onChangeEditUserRoleType = (editRoleTypeId) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_USER_GROUP', editRoleTypeId: editRoleTypeId });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeEditCompanySelection = (editCompany) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_COMPANY', editCompany: editCompany });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeEditDivisionSelection = (editDivision) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_DIVISION', editDivision: editDivision });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onChangeEditTeamSelection = (editTeam) => {
    return (dispatch) => {
        dispatch({ type: 'ON_CHANGE_EDIT_TEAM', editTeam: editTeam });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};
export const onBackToUserList = () => {
    return (dispatch) => {
        dispatch({ type: 'USER_BACK_BUTTON_CLICKED' });
    }
};
export const onBackToUser = () => {
    return (dispatch) => {
        dispatch({ type: 'USER_BACK_BUTTON' });
    }
};
export const updateUser = (productId, profileId, editUserDetail) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: 'BEFORE_SAVE_USER_GROUP' });
        dispatch({ type: 'RESET_UPDATE_STATUS' });
        let accessToken = "";
        if (editUserDetail.id === state.loggedInUserDetail.id) {
            accessToken = state.claudtoken.apigee_token;
        }
        editUserApi(productId, profileId, editUserDetail, accessToken)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                return dispatch(fetchedUserDetails(body));
            })
            .then(() => {
                const event = new AnalyticsEvent("User Updated", "Updated user details", "AddEdit User Page", "Update User");
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDITED_USER, event));
                dispatch(getUserGroupsAction(productId, profileId));
                return dispatch({ type: 'UPDATE_USER_STATUS', successMsg: "User details updated successfully.", errorMsg: null });

            }).catch((error) => {
                const erorObj = JSON.parse(JSON.stringify(error));
                const erorMessageObj = JSON.parse(erorObj.message);
                dispatch({ type: 'UPDATE_USER_STATUS', successMsg: null, errorMsg: erorMessageObj.message });
            });
        dispatch({ type: 'AFTER_SAVE_USER_GROUP' });
    }
};
export const getUserList = (productId, profileId, userId) => {
    return (dispatch) => {
        dispatch({ type: 'BEFORE_REFRESH_USER_LIST' });
        return getUserListByUserIdApi(productId, profileId, userId)
            .then((response) => handleJSONResponse(response))
            .then((userList) => {
                return dispatch({ type: 'FETCHED_USER_LIST', userList });
            }).catch((error) => {
                dispatch(CloseUserLoadingOnError());
                dispatch(serverError(error));
            });
    }
};
export const updateAssignedtoForNewRisk = (loggedInUserId) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_ASSIGNED_NEW_RISK', loggedInUserId });
    };
};
export const onBackToUserGroup = () => {
    return (dispatch) => {
        dispatch({ type: 'USER_GROUP_BACK_BUTTON' });
    }
};
export const resetAddEditEstimate = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET_ADD_EDIT_ESTIMATE' });
    }
};
export const resetRiskDetails = () => {
    return (dispatch) => {
        dispatch({ type: 'NEW_RISK' });
    }
};
export const existingUserDialog = () => {
    return (dispatch) => {
        dispatch({ type: 'EXISTING_USER_DIALOG' });
    }
};
export const cancelExistingUser = () => {
    return (dispatch) => {
        dispatch({ type: 'CANCEL_EXISTING_USER' });
    }
};
export const updateCurrentTab = (currentTab) => {
    return (dispatch) => {
        dispatch({ type: 'SET_CURRENT_TAB',currentTab });
    }
};

export const addRisk = (riskId) => {
    return (dispatch) => {
        dispatch({ type: 'ADDED_RISK', data: riskId });
    }
};

export const removeRisk = (riskId) => {
    return (dispatch) => {
        dispatch({ type: 'RISK_REMOVED', data: riskId }); 
    }
};
export const removeAllRisk = () => {
    return (dispatch) => {
        dispatch({ type: 'RISK_ALL_REMOVED' });
    }
};
export const repriceExport = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_REPRICE_AND_EXPORT' });
        const event = new AnalyticsEvent("Reprice and Export", "Click Reprice and Export button", "EstimateManagement Page", "Reprice and Export");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.REPRICE_EXPORT, event));
        const { productId, profileId, risks, loggedInUserDetail, selectedAllRisk, searchParams, sortParams, totalRecords, excludedRisks } = getState();
        return repriceExportStatusApi(productId, profileId, loggedInUserDetail.companyGroupId)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                if (!body) {
                    dispatch({ type: 'ATTEMPT_REPRICE_AND_EXPORT' });
                    return repriceExportApi({productId, profileId, risks, userId : loggedInUserDetail.id, emailId : loggedInUserDetail.emailId}, selectedAllRisk, searchParams, sortParams, totalRecords,
                        excludedRisks, loggedInUserDetail.companyGroupId)
                        .then((response) => handleJSONResponse(response))
                        .then((repriceBody) => {
                            dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: repriceBody, repriceInProgress: false });
                        }).catch((error) => dispatch(serverError(error)));
                } else {
                    dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: true });
                }
            });
    }
};
export const repriceExportDialog = (proceedRepriceExport) => {
    return (dispatch, getState) => {
        dispatch({ type: 'REPRICE_AND_EXPORT_DIALOG_CLOSE' });

        const { riskId, estimateID, estimatePrice, profileId, productId, action, activeIndex, riskDetail, estimateDetail } = getState();
        if (proceedRepriceExport && action === 'EDIT_ESTIMATE' ) {
            dispatch(editEstimateId(estimateID, productId, profileId, riskId, estimatePrice));
        } else if (proceedRepriceExport && action === 'REPRICE_ESTIMATE' ) {
            dispatch(repriceEstimateId(productId, profileId, riskId, estimateID, activeIndex))
        } else if (proceedRepriceExport && action === 'RISK_DELETE' ) {
            return dispatch({ type: 'RISK_DETAIL_DIALOG', data: true, riskDetail: riskDetail });
        } else if (proceedRepriceExport && action === 'ESTIMATE_DELETE' ) {
            return dispatch({ type: 'ESTIMATE_DETAIL_DIALOG', data: true, estimateDetail: estimateDetail });
        } else if (proceedRepriceExport && action === 'RISK_EDIT' ) {
            return dispatch(editRiskId(productId, profileId, riskDetail, riskId));
        } else {
            dispatch({ type: 'CANCELLED_REPRICE_EXPORT_DIALOG' });
        }
    }
 };
export const exportEstimateDetails = (productId, profileId, risks) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_EXPORT' });
        const event = new AnalyticsEvent("Export Estimates", "Click Export button", "EstimateManagement Page", "Export");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EXPORT, event));
        const { loggedInUserDetail, selectedAllRisk, searchParams, sortParams, totalRecords, excludedRisks } = getState();
        return exportEstimateDetailsApi({productId, profileId, risks, userId : loggedInUserDetail.id},
             loggedInUserDetail.emailId, selectedAllRisk, searchParams, sortParams, totalRecords, excludedRisks) 
             .then((response) => handleJSONResponse(response))
             .then(() => {
                dispatch({ type: 'EXPORT_ESTIMATE'});
            }).catch((error) => dispatch(serverError(error)));
    };
};
export const resetExport = () => {
    return (dispatch) => {
        dispatch({ type: 'RESET_EXPORT_ESTIMATE' });
    }
};

export const exportDialog = () => {
    return (dispatch) => {
        dispatch({ type: 'EXPORT_DIALOG_CLOSE' });
    }
};

export const selectedAllRiskIds = () => {
    return (dispatch) => {
        dispatch({ type: 'SELECTED_ALL_RISK' });
    }
};

export const getRepriceExportStatus = (productId, profileId, companyGroupId) => {
    return (dispatch) => repriceExportStatusApi(productId, profileId, companyGroupId)
        .then((response) => handleJSONResponse(response))
        .then((body) => {
            return dispatch({ type: 'REPRICE_EXPORT_STATUS', isRepriceExport: false, repriceInProgress: body });
        });
}; 

export const deleteUserGroupLogo = (productId, profileId, userGroupId) => {
    return (dispatch, getState) => deleteUserGroupLogoApi(productId, profileId, userGroupId)
        .then((response) => handleJSONResponse(response))
        .then((body) => { 
            dispatch({type: 'UPDATE_USER_GROUP_LOGO', logo : null});
            const { loggedInUserDetail, loggedInUserGroupRole } = getState();
            if (loggedInUserGroupRole === 'COMPANY' && userGroupId === loggedInUserDetail.userGroupId) {
               return dispatch(updateGroupLogo(null, loggedInUserDetail.divisionLogo));
            }
            if (loggedInUserGroupRole === 'DIVISION' && userGroupId === loggedInUserDetail.userGroupId) {
               return dispatch(updateGroupLogo(loggedInUserDetail.companyLogo, null));
            }
        }).catch((error) => dispatch(serverError(error)));
};
export const downloadImage = (url, logoName) => { 
    return (dispatch)  => { return   fetch(url, {
            method: "GET",
            headers: {}
        }).then(response => { 
            response.arrayBuffer().then(function (buffer) { 
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(new Blob([buffer]));
                link.setAttribute("download", logoName); 
                document.body.appendChild(link);
                link.click();
            }); 
        });
    }
};
 
export const updateUserGroupLogo = (productId, profileId, userGroupId, file) => {
    return (dispatch, getState) => updateUserGroupLogoApi(productId, profileId, userGroupId, file)
    .then((response) => {
        return response.text();
    }).then((body) => {
            dispatch(updateLogo(body));
            const { loggedInUserDetail, loggedInUserGroupRole } = getState();
            if (loggedInUserGroupRole === 'COMPANY' && userGroupId === loggedInUserDetail.userGroupId) {
               return dispatch(updateGroupLogo(body, loggedInUserDetail.divisionLogo));
            }
            if (loggedInUserGroupRole === 'DIVISION' && userGroupId === loggedInUserDetail.userGroupId) {
               return dispatch(updateGroupLogo(loggedInUserDetail.companyLogo, body));
            }
        });
};
export const updateLogo = (logo) => {
    return (dispatch) => {
        dispatch({type: 'UPDATE_USER_GROUP_LOGO', logo : logo});
    }
};

export const updateGroupPolicySelection = (policy) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_GROUP_POLICY', policy: policy });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const editEstimateId = (estimateId, productId, profileId, riskId, estimatePrice) => {
    return (dispatch) => {
        dispatch({ type: 'EDIT_ESTIMATE_CLICKED', riskId: riskId });
        const event = new AnalyticsEvent("Estimate Edit", "Edit estimate request", "AddEdit Estimate", "Edit estimate");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EDIT_ESTIMATE, event));
        dispatch(fetchRiskDetailById(estimateId, productId, profileId, riskId, estimatePrice, true));
        let url = `/products/${productId}/profiles/${profileId}/estimate`;
        history.push(url);
    }
};

export const repriceEstimateId = (productId, profileId, riskId, estimateId, index) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_REPRICE_OR_EMAIL_ESTIMATE' });
        const { loggedInUserDetail } = getState();
        return repriceEstimateApi(productId, profileId, riskId, estimateId, loggedInUserDetail.id)
            .then(() => {
                dispatch(searchEstimateByRiskId(riskId, productId, profileId, index));
                dispatch({ type: 'REPRICED_ESTIMATE' });
                dispatch({ type: 'REPRICED_ESTIMATE_FLAG', data: true });
            }).catch((error) => dispatch(serverError(error)));
    }
};