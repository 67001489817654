import UserDetail from '../components/UserDetail';
import {userFullNameChange, userEmailAddressChange} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state, defaultProps) => { 
    return { 
        userCapturedDetail: state.userCapturedDetail,
        styles: state.profile.styles,
        profileId: state.profileId,
        productId: state.productId,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onUserFullNameChange: (fullName) => dispatch(userFullNameChange(fullName)),
        onUserEmailAddressChange: (emailAddress) => dispatch(userEmailAddressChange(emailAddress)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);