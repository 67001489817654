import React from 'react';
import PropTypes from 'prop-types';
import RiskPreview from './RiskPreview';
import { Pagination } from 'semantic-ui-react';
import CCEDeleteDialog from './CCEDeleteDialog';
import EstimatePreviewMobileDialog from './EstimatePreviewMobileDialog';
import RepriceExportDialog from './RepriceExportDialog';
import ExportDialog from './ExportDialog';
import UrlConstants from '../constants/UrlConstants';
import SpinnerContainer from '../containers/SpinnerContainer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'; 

class RiskList extends React.Component {
    csvLink = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            riskId: null,
            cceEstimateLogDetail: [],
            activeIndex: null,
            activeEstimateIndex: null,
            activePopUpIndex: null,
            activeSelectIndex: null,
            expanded: false,
            popupExpanded: false,
            activeDetailButton: null, 
        };
    }

    state = { popupMenu: false };
    handleMenuClose = event => {
        if (!this.state.popupMenu) { 
        this.setState({ activePopUpIndex: -1 });
        } else {            
            this.setState({ popupMenu: false });
        }
    }
    componentWillMount() {
        const { getUserList, loggedInUserDetail, searchEstimateResults, validateProfile, setValidateProfile, debug, setDebug,
            searchEstimateByRiskId, searchResult, activeIndex, onLoad } = this.props;
        validateProfile();
        setValidateProfile();
        document.addEventListener('click', this.handleMenuClose, true);
        if (debug === true) {
            setDebug()
        }          
        if(loggedInUserDetail != null && loggedInUserDetail.id != null) {
            searchEstimateResults();
            if (searchResult.data.length > 0 && activeIndex !== null && searchResult.data[activeIndex] !== null 
                && searchResult.data[activeIndex] !== undefined) {
                searchEstimateByRiskId(searchResult.data[activeIndex].riskId, activeIndex);
            }
            getUserList(loggedInUserDetail.id);
        }
        onLoad();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getUserList, loggedInUserDetail, searchEstimateResults, searchParams, sortParams, pagination, deleteRisk, deleteEstimate, searchEstimateByRiskId,
            searchResult, activeIndex, pageSize, isEstimateRepriced, exportStatus } = this.props;
        if (searchParams.searchBy !== prevProps.searchParams.searchBy || searchParams.searchText !== prevProps.searchParams.searchText
            || searchParams.fromDate !== prevProps.searchParams.fromDate || searchParams.toDate !== prevProps.searchParams.toDate
            || searchParams.searchDateOption !== prevProps.searchParams.searchDateOption
            || pageSize !== prevProps.pageSize
            || sortParams.dateChange !== prevProps.sortParams.dateChange
            || sortParams.sortOrder !== prevProps.sortParams.sortOrder
            || pagination.activePage !== prevProps.pagination.activePage
            || deleteRisk.riskDialog !== prevProps.deleteRisk.riskDialog
            || deleteEstimate.estimateDialog !== prevProps.deleteEstimate.estimateDialog
            || isEstimateRepriced !== prevProps.isEstimateRepriced
            || (this.state.expanded && (searchResult.estimateResult.length !== prevProps.searchResult.estimateResult.length))) {
            searchEstimateResults(); 
            if (searchResult.data.length > 0 && activeIndex !== null && searchResult.data[activeIndex] !== null 
                && searchResult.data[activeIndex] !== undefined) {
                searchEstimateByRiskId(searchResult.data[activeIndex].riskId, activeIndex);
            } 
        } else if(loggedInUserDetail.id !== prevProps.loggedInUserDetail.id) {
            searchEstimateResults(); 
            getUserList(loggedInUserDetail.id);
        } 
        if (exportStatus !== prevProps.exportStatus) {
            const { resetExport } = this.props;           
            resetExport(); 
        }
    }
    handleEstimatePreview = (value, index) => {
        const { searchEstimateByRiskId } = this.props;
        searchEstimateByRiskId(value, index); 
    };
    handleEstimatePreviewDesktop = (event, value, index, activeSelectIndex) => {
        const { searchEstimateByRiskId } = this.props;
        event.stopPropagation();
        this.setState({ activeSelectIndex: index });   
        let expand =   this.state.activeSelectIndex < 0 || this.state.activeSelectIndex === null
        || index === activeSelectIndex  || this.state.expanded === false ?
             !this.state.expanded : this.state.expanded;
        this.setState({ expanded: expand });
        if (!this.state.expanded) {
            searchEstimateByRiskId(value, index);
        }
        this.setState({ activeEstimateIndex: -1 });
        this.setState({ activePopUpIndex: -1 });
        this.setState({ popupMenu: false });
        this.setState({ popupExpanded: false });
        this.setState({ activeDetailButton: true }); 
    };

    handleActiveEstimate = ( index) => {
        this.setState({ activeEstimateIndex: index });
    };

    handlePopUpEstimate = ( index) => {
        if(this.state.activeEstimateIndex < 0
            || this.state.activeEstimateIndex === index){             
        this.setState({ popupExpanded: !this.state.popupExpanded }); 
        }  else {
            this.setState({ popupExpanded: true }); 
        }
        this.setState({ popupMenu: true });
        this.setState({ activePopUpIndex: index });
    };
    handleActivateDetailButton = ( activeSwitchButtonStatus) => {         
        this.setState({ activeDetailButton: activeSwitchButtonStatus }); 
    };

    handleSelectRisk = (index) => {
        if(this.state.activeSelectIndex !== index) {            
        this.setState({ expanded: false}); 
        } 
        this.setState({ activeSelectIndex: index });
        this.setState({ activeEstimateIndex: -1 });
        this.setState({ activePopUpIndex: -1 });
        this.setState({ popupMenu: false });
        this.setState({ popupExpanded: false });
    };

    handleEditRisk = (riskDetail, riskId) => {
        const { getUserList, productId, profileId, editRiskDetail,loggedInUserDetail, activeIndex } = this.props;
        getUserList(loggedInUserDetail.id);
        this.handleEstimatePreview(riskId, activeIndex);
        editRiskDetail(productId, profileId, riskDetail, riskId);
    }

    handleDeleteRisk = (riskId, address) => {
        const { productId, profileId, deleteRiskDetail, deleteMultipleRisks } = this.props;
        this.setState({ activePopUpIndex: -1 });
        if (address === null) {
            deleteMultipleRisks();
        } else {
            deleteRiskDetail(productId, profileId, riskId);
        }
    }
    onPageChange = (event, data) => {
        const { updateActivePageNo } = this.props;
        this.setState({ activeSelectIndex: -1 });
        this.setState({ activeEstimateIndex: -1 });
        this.setState({ activePopUpIndex: -1 });
        updateActivePageNo(data.activePage);
    }

    handleRepriceEstimate = (riskId, estimateId, estimatePrice) => {
        const { repriceEstimateDetail, activeIndex } = this.props;
        if (estimatePrice !== "In Progress") {
            repriceEstimateDetail(riskId, estimateId, activeIndex);
        }
        this.setState({ activePopUpIndex: -1 });
        this.setState({ popupMenu: false });
    }

    handleRepriceExport = () => {
        const { repriceExport } = this.props;
        this.setState({ expanded: false, popupMenu: false });
        repriceExport();
    }

    handleEmailDownloadReport = (riskId, estimateId, estimatePrice) => {
        const { emailDownloadReport } = this.props;
        if (estimatePrice !== "In Progress") {
            emailDownloadReport(riskId, estimateId, estimatePrice);
        }
        this.setState({ activePopUpIndex: -1 });
        this.setState({ popupMenu: false });
    }

    handleEstimatePreviewDialog = (value, riskId, index) => {
        const { estimatePreviewDialog } = this.props
        estimatePreviewDialog(value, riskId, index);
    }

    handleDialogClose = () => {
        const { estimatePreviewClose } = this.props;
        estimatePreviewClose();
    }

    handleRiskDetailDeleteDialog = (value, riskDetail) => {
        const { riskDetailDeleteDialog } = this.props
        riskDetailDeleteDialog(value, riskDetail)
    }

    handleDeleteEstimate = (estimateId, estimatePrice) => {
        const { productId, profileId, deleteEstimateDetail } = this.props;
        this.setState({ activePopUpIndex: -1 });
        deleteEstimateDetail(productId, profileId, estimateId, estimatePrice);
    }

    handleEstimateDetailDeleteDialog = (value, estimateDetail) => {
        const { estimateDetailDeleteDialog } = this.props
        estimateDetailDeleteDialog(value, estimateDetail)
    }

    editEstimate = (riskId, estimateId, estimatePrice) => {
        const { productId, profileId, searchEstimateByEstimate } = this.props;
        searchEstimateByEstimate(estimateId, productId, profileId, riskId, estimatePrice);
    };

    handleCheckBoxChange = (id, checkedStatus ) => {
        const { risks, addRisk, removeRisk } = this.props  
        if(checkedStatus) {
            if(!risks.includes(id)) {
                addRisk(id);
            } 
        } else {
            removeRisk(id); 
        }  
    };

    handleSelectAllCheckBoxChange = (checkedStatus) => {
        const { removeAllRisk, selectedAllRiskIds } = this.props 
        if (checkedStatus) { 
            selectedAllRiskIds(); 
        } else { 
            removeAllRisk(); 
        } 
    }
    handleCancelExport = () => {
        const {  removeAllRisk } = this.props 
        removeAllRisk();  
    }
    handleDownLoad = () => {   
     const {  exportEstimateDetails } = this.props
      exportEstimateDetails(this.props.risks);
    }

    handleDelete = (value) => {
        const { multipleRisksDeleteDialog } = this.props
        multipleRisksDeleteDialog(value, this.props.risks)
    }

    render() {
        const { productId, profileId, styles, searchResult, widthChange, loggedInUserDetail } = this.props;
        let fontFamilyName = styles.globalFontFamily;
        const theme = createTheme({
            backgroundColor: '#fff', 
          });
          const cancelTheme = createTheme({
              backgroundColor: 'transparent', 
            });
        return (
            <div className='cce-risk-preview-card' style={{ fontFamily: fontFamilyName }}>
                <div className="pagination">
                    <Pagination className="pagination"
                        activePage={this.props.pagination.activePage}
                        totalPages={this.props.pagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                    />
                </div>
                <div style={{width: '100%'}}>
                    <div className='risk-preview-div risk-select-all'>
                        <FormControlLabel
                            value="Select All" style={{ fontFamily: fontFamilyName }}
                            control={<Checkbox className="chbx-select-all" color="default" 
                            onClick ={
                                (event) => this.handleSelectAllCheckBoxChange(!event.target.checked)
                            } 
                            indeterminate={(this.props.selectedAllRisk) && 
                                (this.props.excludedRisks.length  > 0  || 
                                this.props.risks.length  > 0) ? true : false}
                                inputProps={{ 'aria-label': 'Select All' }} />}
                            label="Select All"  
                            checked ={this.props.selectedAllRisk && this.props.excludedRisks.length === 0 ?
                                true :  false }
                        />
                    </div>
                </div>
                {(this.props.risks.length > 0 || this.props.selectedAllRisk) && <div className="risk-footer-div">
                    <div className='risk-footer'>
                    <Button className="button reprice-ex-btn" variant="outlined"
                                        style={{ fontFamily: fontFamilyName }}
                                        rounded="true"
                                        size="small"
                                        tabIndex={1}
                                        onClick={() => this.handleRepriceExport()}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false} 
                                    >Reprice & Export</Button>
                        <ThemeProvider theme={theme}>
                            <Button
                                variant="contained" className="export-btn"
                                startIcon={<GetAppIcon />}
                                onClick={this.handleDownLoad}
                            >
                                Export
                            </Button></ThemeProvider>
                        {loggedInUserDetail.roleTypeId <= 4 && <ThemeProvider theme={theme}>
                            <Button
                                variant="contained" className="export-btn" onClick={
                                    () => this.handleDelete(true)
                                }>
                                Delete
                            </Button></ThemeProvider>}
                        <ThemeProvider theme={cancelTheme}>
                            <Button variant="contained" className="export-btn"
                             onClick ={
                                () => this.handleCancelExport()
                            } >
                                Cancel
                            </Button></ThemeProvider>

                    </div>
                </div>}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                <SpinnerContainer/>
                    <div className='risk-preview-div'>
                        <RiskPreview
                            productId={productId}
                            profileId={profileId}
                            fontFamilyName={fontFamilyName}
                            searchResult={searchResult}
                            handleEstimatePreview={this.handleEstimatePreview}
                            handleEditRisk={this.handleEditRisk}
                            handleRiskDetailDeleteDialog={this.handleRiskDetailDeleteDialog}
                            activeIndex={this.props.activeIndex}
                            handleEstimatePreviewDialog={this.handleEstimatePreviewDialog}
                            userList={this.props.userList}
                            handleRepriceEstimate= {this.handleRepriceEstimate}
                            handleEmailDownloadReport= {this.handleEmailDownloadReport}
                            handleEstimateDetailDeleteDialog= {this.handleEstimateDetailDeleteDialog}
                            editEstimate= {this.editEstimate}
                            isMobile={widthChange <= UrlConstants.MOBILE_INNER_WIDTH}
                            handleActiveEstimate = {this.handleActiveEstimate}
                            activeEstimateIndex = {this.state.activeEstimateIndex}
                            handlePopUpEstimate = {this.handlePopUpEstimate}
                            activePopUpIndex = {this.state.activePopUpIndex}
                            handleSelectRisk= {this.handleSelectRisk}
                            activeSelectIndex= {this.state.activeSelectIndex}
                            expanded= {this.state.expanded}
                            handleEstimatePreviewDesktop= {this.handleEstimatePreviewDesktop}
                            popupExpanded={this.state.popupExpanded}
                            activeDetailButton={this.state.activeDetailButton}
                            handleActivateDetailButton={this.handleActivateDetailButton}
                            handleCheckBoxChange={this.handleCheckBoxChange} 
                            risks ={this.props.risks}
                            excludedRisks={this.props.excludedRisks}
                            selectAll={this.props.selectedAllRisk}
                        />
                    </div>
                </div>

                <div className="pagination" style={{marginTop: '0.5em'}}>
                    <Pagination className="pagination"
                        activePage={this.props.pagination.activePage}
                        totalPages={this.props.pagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null} />
                </div>
                <EstimatePreviewMobileDialog
                    productId={productId}
                    profileId={profileId}
                    fontFamilyName={fontFamilyName}
                    searchResult={searchResult}
                    editEstimate={this.editEstimate}
                    handleRepriceEstimate={this.handleRepriceEstimate}
                    handleEmailDownloadReport={this.handleEmailDownloadReport}
                    handleDialogClose={this.handleDialogClose}
                    handleEstimateDetailDeleteDialog={this.handleEstimateDetailDeleteDialog}
                />
                <CCEDeleteDialog
                    styles={this.props.styles}
                    deleteRisk={this.props.deleteRisk}
                    deleteEstimate={this.props.deleteEstimate}
                    handleRiskDetailDeleteDialog={this.handleRiskDetailDeleteDialog}
                    handleDeleteRisk={this.handleDeleteRisk}
                    isRiskOrEstimate={this.props.isRiskOrEstimate}
                    handleEstimateDetailDeleteDialog={this.handleEstimateDetailDeleteDialog}
                    handleDeleteEstimate={this.handleDeleteEstimate}
                />
                <RepriceExportDialog
                    styles={this.props.styles}
                    isRepriceExport={this.props.isRepriceExport}
                    repriceExportDialog={this.props.repriceExportDialog}
                    repriceInProgress={(this.props.repriceInProgress && !this.props.isRepriceExport) || (this.props.repriceInProgress && this.state.expanded)}
                    action={this.props.action}
                />
                <ExportDialog
                    styles={this.props.styles}
                    isExport={this.props.isExport}
                    exportDialog={this.props.exportDialog}
                />
            </div>
        );
    }
}

RiskList.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
    searchEstimateResults: PropTypes.func.isRequired,
    handleEstimatePreview: PropTypes.func.isRequired,
};

export default RiskList;