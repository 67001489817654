import React from 'react';
import { Route, Router, Switch } from 'react-router';
import LandingPageContainer from '../containers/LandingPageContainer';
import QuestionsContainer from '../containers/QuestionsPageContainer';
import ViewEmail from './ViewEmail';
import NotFoundPageContainer from '../containers/NotFoundPageContainer';
import UnavailableContainer from '../containers/UnavailableContainer';
import AccessDeniedPage from './AccessDeniedPage';
import TermsOfUsePageContainer from "../containers/TermsOfUsePageContainer";
import history from "../util/history";
import 'whatwg-fetch';
import Promise from 'promise-polyfill';
import Utils from "../util/Utils";
import DomainContants from "../constants/DomainConstants";
import CCESearchContainer from '../containers/CCESearchContainer';
import CCEHomePageContainer from '../containers/CCEHomePageContainer';
import LoginPageContainer from '../containers/LoginPageContainer';

// To add to window
if (!window.Promise) {
    window.Promise = Promise;
}

class App extends React.Component {

    constructor(props) {
        super(props);
        this.routes = (
            <Router history={history}>
                <Switch>
                    <Route exact path="/products/:productId/profiles/:profileId" component={LandingPageContainer} />
                    <Route exact path="/products/:productId/profiles/:profileId/estimate" component={CCEHomePageContainer} />
                    <Route exact path="/products/:productId/profiles/:profileId/manage" component={CCESearchContainer} />
                    <Route exact path="/products/:productId/profiles/:profileId/admin" component={CCEHomePageContainer} />
                    <Route exact path="/products/:productId/profiles/:profileId/questions"
                        component={QuestionsContainer} />
                    <Route exact path="/products/:productId/profiles/:profileId/access_not_available"
                        component={AccessDeniedPage} />
                    <Route exact path="/viewEmail/products/:productId/profiles/:clientId" component={ViewEmail} />
                    <Route exact path="/viewEmail/products/:productId/profiles/:clientId/broker/:brokerId"
                        component={ViewEmail} />
                    <Route exact path="/viewEmail/products/:productId/profiles/:clientId/message/:message" component={ViewEmail} />
                    <Route exact path="/viewEmail/products/:productId/profiles/:clientId/message/:message/broker/:brokerId"
                        component={ViewEmail} />
                    <Route exact path="/products/:productId/terms_of_use" component={TermsOfUsePageContainer} />
                    <Route exact path="/not_found" component={NotFoundPageContainer} /> 
                    <Route exact path="/unavailable" component={UnavailableContainer} />
                    <Route path="/" component={LoginPageContainer} />
                    <Route path="*" component={NotFoundPageContainer} />
                </Switch>
            </Router>
        );
        this.getCookies();
    }
    getCookies = () => {
        let name = document.cookie.split(';').join(',').split(',');
        let cookieName = [];
        let cookieValue = [];
        name.forEach((currentValue, index) => {
            cookieName[index] = name[index].substring(0, name[index].indexOf('=')).trim();
        })
        name.forEach((currentValue, index) => {
            cookieValue[index] = name[index].substring(name[index].indexOf('=') + 1, name[index].length).trim();
        })
        this.setCookies(cookieName, cookieValue);
    }

    setCookies = (name, value) => {
        name.forEach((currentVal, index) => {
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.DEV};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.DEV};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_UAT};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_UAT};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_SUBDOMAIN};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_SUBDOMAIN};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_PRD};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.AU_PRD};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_UAT};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_UAT};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_SUBDOMAIN};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_SUBDOMAIN};httpOnly`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_PRD};secure`
            document.cookie = `${currentVal} = ${value[index]};domain=${DomainContants.NZ_PRD};httpOnly`
        })
    }

    render() {
        const { styles } = this.props;
        const profileSpecificStyles = Utils.buildProfileSpecificCss(styles);

        return (
            <div>
                {this.routes}
                {styles && <style dangerouslySetInnerHTML={{ __html: profileSpecificStyles }} />}
            </div>
        );
    }
}

export default App;