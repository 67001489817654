import App from '../components/App';
import {connect} from 'react-redux';

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        addressChosen: state.addressChosen,
        styles: state.profile.styles,
    };
};

export default connect(mapStateToProps)(App);