import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const HelpInformationDialog = ({ open, question, styles, onHelpInformationDialogClose }) => {

    let content = question.helpText ? question.helpText : "";
    if (content) {
        content = content.replace(/{styles.questionLabelFontColour}/g, styles.questionLabelFontColour).replace(/{styles.questionLabelFontSize}/g, styles.questionLabelFontSize).replace(/{styles.questionHelpFontColour}/g, styles.questionHelpFontColour).replace(/{styles.questionHelpFontSize}/g, styles.questionHelpFontSize);
    }

    return (
        <Dialog
            className="dialog terms-dialog"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            onClose={onHelpInformationDialogClose}
            style={{ right: '-63px', left: '-48px' }}>
            <DialogContent>
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td tabIndex={0} style={{ textAlign: 'left', marginBottom: 0, fontSize: '20px', fontWeight: 'bold' }}>
                                    {question.text}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div tabIndex={0} className="helpContent" dangerouslySetInnerHTML={{ __html: content }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Close" onPress={onHelpInformationDialogClose}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
};

HelpInformationDialog.prototypes = {
    open: PropTypes.bool.isRequired,
    question: PropTypes.object.isRequired,
};

export default HelpInformationDialog;