import apiBaseUrl from '../apiBaseUrl';

export const sendSupportEmailApi = (productId, profileId, propertyId, address, name, email, mobile) => {
   //todo: handle broker

    const url =`${apiBaseUrl()}products/${productId}/profiles/${profileId}/letusknow`;
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
    };

    let body = {
        "productId": productId,
        "profileId": profileId,
        "propertyId": propertyId,
        "address": address,
        "name": name,
        "email": email,
        "mobile": mobile,
    };
    let response = fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        }
    );

    return response;
 };
