import ServerError from './ServerError';
import history from '../util/history';
import tokenApi from '../api/tokenApi';
import refreshTokenApi from '../api/refreshTokenApi';
import getUserDetailByUserGuidApi from '../api/getUserDetailByUserGuidApi';
import apigeeTokenApi from '../api/apigeeTokenApi';
import { debounce } from 'lodash';


const DEBOUNCE_WAIT_LOGIN_LOAD = 500;
const handleAdminJSONResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {

        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

export const cceServerError = (responseError) => {
    if (responseError.status === 404) {
        history.push('/not_found');
        return { type: 'SERVER_ERROR', errorCode: responseError.type, error: responseError.message };
    } else if (responseError.status === 503) {
        history.push('/unavailable');
        return { type: 'SERVER_UNAVAILABLE', error: responseError.message };
    } else {
        return { type: 'SERVER_ERROR', errorCode: responseError.type, error: responseError.message };
    }
};
export function saveCode(code) {
    return (dispatch) => {
        dispatch({ type: 'ATTEMPT_SAVE_CODE', data: code });
    }
}

let refreshTimer;
const setRefreshTimer = (dispatch, tokenContents) => {
    if (tokenContents != null && tokenContents.exp != null) {
        const expiryTime = new Date(tokenContents.exp * 1000);
        const currentTime = new Date();
        const timeout = Math.abs(expiryTime - currentTime);
        refreshTimer = setTimeout(() => dispatch(doRefreshToken()), timeout);
    }
};

export const fetchTokenApi = (dispatch, productId, profileId, code,  getState) => {

    code = (code || '').trim();
    clearTimeout(refreshTimer);
    dispatch({ type: 'ATTEMPT_FETCH_TOKEN', data: code });
    const state = getState();
    if (productId != null && profileId != null && state.profileId == null
        && state.productId == null &&
        typeof (productId) !== 'undefined' && typeof (profileId) !== 'undefined') {
        dispatch({ type: 'UPDATE_PROFILE_PRODUCT_ID', productId, profileId });
    }
    return tokenApi(productId, profileId, code)
        .then((response) => handleAdminJSONResponse(response))
        .then((response) => {
            if (response.access_token !== null) {
                let tokenContents = JSON.parse(atob(response.access_token.split('.')[1]));
                return dispatch(getUserDetailByUserGuid(productId, profileId, tokenContents.sub, response)); 
            }
        }).then((response) => { 
            if (response !== null && response !== undefined) {
                if (response.access_token === null && response.statusCode === "503") {
                    dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
                    dispatch({ type: 'FAIL_FETCH_TOKEN', data: "Service Unavailable" });
                    dispatch(logout('Failed to connect to the server.'));
                } else {
                    let tokenContents = JSON.parse(atob(response.access_token.split('.')[1]));
                    dispatch({ type: 'SUCCEED_FETCH_TOKEN', data: { response, tokenContents } });
                    setRefreshTimer(dispatch, tokenContents);
                }
            } else {
                dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
                dispatch({ type: 'FAIL_FETCH_TOKEN', data: "Service Unavailable" });
                dispatch(logout('Login Failed. Failed to connect to the server.'));
            }
        })
        .catch((error) => {
            dispatch({ type: 'FAIL_FETCH_TOKEN', data: error });
            dispatch(logout('Login Failed. Unable to validate user.'));

        });
}

const debounceFetchToken = debounce(fetchTokenApi, DEBOUNCE_WAIT_LOGIN_LOAD);

export const fetchToken = (productId, profileId, code) => {
    return (dispatch, getState) => { 
        return debounceFetchToken(dispatch, productId, profileId, code, getState);
    };
};


export const fetchApigeeToken = (productId, profileId, accessToken) => {
    return async (dispatch) => {
        return apigeeTokenApi(productId, profileId, accessToken)
            .then((response) => {
                return response.text();
            })
            .then((apigeeAccessToken) => {
                if(apigeeAccessToken !== null && apigeeAccessToken !== undefined && apigeeAccessToken.includes("STATUSCODE")) {  
                    dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
                    dispatch({ type: 'FAIL_FETCH_TOKEN', data: "Service Unavailable" });
                    let failToken = "Failed to connect to the server.";
                   dispatch(logout(failToken)); 
                } else {
                    dispatch({ type: 'UPDATE_APIGEE_TOKEN', apigeeAccessToken });
                }
            })
            .catch((error) => {
                dispatch({ type: 'FAIL_APIGEE_TOKEN', error });
            });
    };
}


export const getUserDetailByUserGuid = (productId, profileId, cluserguid, claudResponse) => {
    return (dispatch) => getUserDetailByUserGuidApi(productId, profileId, cluserguid)
        .then((response) => handleAdminJSONResponse(response))
        .then((body) => {
            return dispatch(updateLoggedInUserDetail(body));
        }).then(() => {
            return claudResponse;
        }).catch((error) => dispatch(cceServerError(error)));
};

export const updateLoggedInUserDetail = (userDetail) => {
    return (dispatch, getState) => { 
        if (userDetail!== null && userDetail.id !== null) {
            dispatch({ type: 'FETCH_LOGGED_IN_USER', data: userDetail });
        } else {
            dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
            dispatch(logout('Login failed as the username provided does not exist.'));
        }
    }
};


export function logout(message) {
    return (dispatch) => {
        clearTimeout(refreshTimer);
        dispatch({ type: 'CLEAR_USER_FILTER' });
        dispatch({ type: 'CLEAR_FILTER' });
        dispatch({ type: 'CLEAR_GROUP_FILTER' });

        dispatch({ type: 'ATTEMPT_LOGOUT', data: message, });
    }
}
export function resetTokenDetails() {
    return async (dispatch) => {
        dispatch({ type: 'RESET_TOKEN_DETAILS' });
    };
}

export const doRefreshToken = () => {
    clearTimeout(refreshTimer);
    return async (dispatch, getState) => {
        const state = getState();
        dispatch({ type: 'TOKEN_EXPIRED', data: "" });
        let refreshToken = state.claudtoken.refresh_token;
        if (refreshToken) {
            dispatch({ type: 'ATTEMPT_REFRESH_TOKEN', data: { refresh_token: refreshToken } });
            return refreshTokenApi(state.productId, state.profileId, refreshToken)
                .then((response) => handleAdminJSONResponse(response))
                .then((response) => {
                    if (response.access_token != null) { 
                        let tokenContents = JSON.parse(atob(response.access_token.split('.')[1]));
                        return dispatch(getUserDetailByUserGuid(state.productId, state.profileId, tokenContents.sub, response));
                    } else {
                        if (response.statusCode === "503") {
                            dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
                            dispatch({ type: 'FAIL_FETCH_TOKEN', data: "Service Unavailable" });
                            return dispatch(logout('Failed to connect to the server.'));
                        } else {
                            dispatch({ type: 'FAIL_FETCH_TOKEN' });
                            return dispatch(logout('Login Failed. Unable to validate user.'));
                        }
                    }
                }).then((response) => { 
                    getTokenStatus(response, dispatch, state);
                }).catch((error) => {
                    dispatch({ type: 'FAIL_FETCH_TOKEN', error });
                    dispatch(logout('Login Failed. Unable to validate user.'));
                });
        }
    };
}
function getTokenStatus(response, dispatch, state) {
    if (response !== null && response !== undefined) {
        if (response.statusCode === "503") {
            dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN INVALID" });
            dispatch({ type: 'FAIL_FETCH_TOKEN', data: "Service Unavailable" });
            dispatch(logout('Failed to connect to the server.'));
        } else {
            let tokenContents = JSON.parse(atob(response.access_token.split('.')[1]));
            dispatch({ type: 'SUCCEED_FETCH_TOKEN', data: { response, tokenContents } });
            dispatch(fetchApigeeToken(state.productId, state.profileId, response.access_token));
            setRefreshTimer(dispatch, tokenContents);
        }
    } else {
        dispatch({ type: 'FAIL_FETCH_TOKEN', data: "No response" });
        dispatch(logout('Login Failed. Unable to validate user.'));
    }
}

export function checkRefreshToken() {
    return async (dispatch, getState) => {
        const state = getState();
        const tokenContents = state.tokenContents;
        if (tokenContents != null && tokenContents.exp != null) {
            const expiryTime = new Date(tokenContents.exp * 1000);
            const currentTime = new Date();
            if (expiryTime > currentTime) {
                dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN NOT EXPIRED" });
                setRefreshTimer(dispatch, tokenContents);
            } else {
                dispatch({ type: 'TOKEN_EXPIRED', data: "TOKEN EXPIRED" });
                dispatch(logout('Session Expired. Please login again.'));
            }
        }
    };
}

export function restoreURL(productId, profileId) {

    let newPathname = "";
    if (productId != null && profileId != null && typeof (productId) !== 'undefined'
        && typeof (profileId) !== 'undefined') {
        newPathname = `/products/${productId}/profiles/${profileId}/`;
    } else {
        let baseURL = window.location.origin;
        window.localStorage.removeItem('restoreUrl');
        baseURL = window.location.href.includes('/#') ? baseURL + '/#' : baseURL;
        let pathArray = window.location.href.split(baseURL).pop().split('/');
        let i = 0;
        for (i; i < pathArray.length; i++) {
            if (pathArray[i].toLowerCase() === "products".toLowerCase() && newPathname === "") {
                newPathname = "/";
                newPathname += pathArray[i];
            } else if (newPathname && !pathArray[i].includes('admin') && !pathArray[i].includes('manage')
                && !pathArray[i].includes('estimate')) {
                newPathname += "/" + pathArray[i];
            }
        }
        newPathname = newPathname === "" ? window.location.href.split(baseURL).pop() : newPathname;
    }
    window.localStorage.setItem('restoreUrl', newPathname);
    return (dispatch) => {
        dispatch({ type: 'UPDATE_HOMEPATHNAME', data: newPathname });
    }


}

export const loadLoginPage = (productId, profileId) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_PROFILE_PRODUCT_ID', productId, profileId });
    };
}