import apiBaseUrl from '../apiBaseUrl';

const isAdminUserApi = (productId, profileId, userGroupId, from) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/isAdminUser/${userGroupId}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    headers.from = from;
    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default isAdminUserApi;