class AccessDeniedError {
    constructor(message, status, productId, profileId, token) {
        this.message = message;
        this.status = status;
        this.productId = productId;
        this.profileId = profileId;
        this.token = token;
    }
}

export default AccessDeniedError;