import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {updateErrors} from '../actions/questionsActionCreator';
import Question from "../components/Question";

const mapStateToProps = (state) => {
    return {
        productId: state.productId,
        profileId: state.profileId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateErrors
    }, dispatch);
};

const QuestionContainer = connect(mapStateToProps, mapDispatchToProps)(Question);
export default QuestionContainer;