import apiBaseUrl from '../apiBaseUrl';

const getUserGroupDetailApi = (productId, profileId, userGroupId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getUserGroupDetail/${userGroupId}`;
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Cache-control": "no-store",
        "Expires": 0,
        "Pragma": "no-cache",
    };

    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getUserGroupDetailApi;