import { connect } from 'react-redux';
import AddUser from '../components/AddUser';
import { bindActionCreators } from 'redux';
import {
    setUserGroupOption, onChangeCompanyName, onChangeDivisionName, onChangeTeamName,
    addUser, getUserGroupsAction, getRoleTypeAction,updateTeamSelection, userNameFound, existingUserDialog, cancelExistingUser,
    updateCompanySelection, updateDivisionSelection, clearData, onChangeuserId, onChangefullName, onChangeUserEmail,onBackToUser
} from '../actions/searchActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        userGroupOption: state.userGroupOption,
        companyName: state.companyName,
        teamName: state.teamName,
        divisionName: state.divisionName,
        roleId: state.roleId,
        isActive: state.isActive,
        productId: state.productId,
        profileId: state.profileId,
        userGroups: state.userGroups,
        roleType: state.roleType,
        selectedDivision: state.selectedDivision,
        selectedCompany: state.selectedCompany,
        isEditUserGroup: state.isEditUserGroup,
        userDetail: state.userDetail,
        isAddUserEnabled: state.isAddUserEnabled,
        selectedTeam: state.selectedTeam,
        editUserDetail: state.editUserDetail,
        loggedInUserDetail: state.loggedInUserDetail,
        existingUser: state.existingUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setUserGroupOption,
        onChangeCompanyName,
        onChangeTeamName,
        onChangeDivisionName,
        getUserGroupsAction,
        getRoleTypeAction,
        updateDivisionSelection,
        updateCompanySelection,
        clearData,
        onChangeuserId,
        onChangefullName,
        onChangeUserEmail,
        addUser,
        updateTeamSelection,
        onBackToUser,
        userNameFound,
        existingUserDialog,
        cancelExistingUser,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        setUserGroupOption: dispatchProps.setUserGroupOption,
        onChangeCompanyName: dispatchProps.onChangeCompanyName,
        onChangeTeamName: dispatchProps.onChangeTeamName,
        onChangeDivisionName: dispatchProps.onChangeDivisionName,
        getUserGroups: dispatchProps.getUserGroupsAction,
        getRoleType: dispatchProps.getRoleTypeAction,
        updateDivisionSelection: dispatchProps.updateDivisionSelection,
        updateCompanySelection: dispatchProps.updateCompanySelection,
        clearData: dispatchProps.clearData,
        onChangeuserId: dispatchProps.onChangeuserId,
        onChangefullName: dispatchProps.onChangefullName,
        onChangeUserEmail: dispatchProps.onChangeUserEmail,
        addUser: dispatchProps.addUser,
        updateTeamSelection: dispatchProps.updateTeamSelection,
        onBackToUser: dispatchProps.onBackToUser,
        userNameFound: dispatchProps.userNameFound,
        existingUserDialog: dispatchProps.existingUserDialog,
        cancelExistingUser: dispatchProps.cancelExistingUser,
        onLoad: () => {
        const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'AddEdit User Page', ownProps.location.pathname, stateProps.sessionTrackId, "AddEditUserPage", stateProps.partner);
           dispatchProps.trackSegmentPageView("AddEdit User Page", pageDetails);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddUser);