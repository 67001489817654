import apiBaseUrl from '../apiBaseUrl';

const repriceEstimateApi = (productId, profileId, riskId, estimateId, userId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/risk/${riskId}/repriceEstimate/${estimateId}`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ userId: userId }),
        }
    );
};

export default repriceEstimateApi;