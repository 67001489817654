import React from 'react';
import { isEmpty } from 'lodash';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import RiskDetailContainer from '../containers/RiskDetailContainer';
import BuildingDetailsPageContainer from "../containers/BuildingDetailsPageContainer";
import ExternalFeaturesPageContainer from "../containers/ExternalFeaturesPageContainer";
import RuralStructuresPageContainer from "../containers/RuralStructuresPageContainer";
import ImageManagementContainer from "../containers/ImageManagementContainer";
import CCERebuildCostPageContainer from "../containers/CCERebuildCostPageContainer";
import SpecialItemsContainer from "../containers/SpecialItemsContainer";
import UrlConstants from '../constants/UrlConstants';
import ToggleBoxContainer from '../containers/ToggleBoxContainer';
import SpinnerContainer from '../containers/SpinnerContainer';

const CCENavigation = ({ styles, productId, profileId, autofillAddress, searchText, value, results, suggestions, onChange, onNewRequest, tabValue,
    handleChange, addressChosen, questions, result, changeImageDescription, imageDescriptionDetail, updateImagesDescription, cceImages, widthChange,
    isEditRisk, updateRiskDetail, cancelEdit, isEditEstimate, isIncompleteEstimate, saveInCompleteEstimate, isEmailDownload, userList, postcode, errors,
    handleProperty,errorPropertyMsg, depdencyErrors, statePostCode, postcodeErrorMsg, answerUpdated, estimatePrice, location}) => {


    const handleTabChange = (event, newValue) => {
        if (newValue === UrlConstants.REBUILD_COST_PANEL && result.data && result.data.estimateId !== null) {
            updateImagesDescription(productId, profileId, imageDescriptionDetail, result.data.estimateId);
        }
        handleChange(newValue);
    };

    const handleUpdateRiskAndSaveInCompleteEstimate = () => {
        if (isEditRisk) {
            updateRiskDetail();
        } else if (isIncompleteEstimate && (result.data === null || result.data.variables.length === 0)) {
            updateRiskDetail();
            saveInCompleteEstimate();
        }
        cancelEdit();
    };
    const isMobile = widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
    const buildingDisabled = getBuildingDisabledValue(addressChosen, statePostCode, postcodeErrorMsg, errorPropertyMsg, isEditRisk, isEmailDownload, isEditEstimate);
    const saveDisabled = getConstantSaveDisabled(isEditRisk, isIncompleteEstimate, estimatePrice, isEditEstimate, answerUpdated);

    const isCurrentTabError = (questionList, tabErrors ) => {
        let matched = false;
        tabErrors.forEach(error => {
            questionList.data.forEach(question => {
                if ((question.section.name.toLowerCase().indexOf('Rural Structures'.toLowerCase()) >= 0) && question.visible && question.id === error.questionId) {
                    matched = true;
                }
            });
        });
        return matched;
    }

    return (
        <div className="navigation">
            <Tabs
                className="navigation-tabs"
                orientation="vertical"
                aria-label="Vertical tabs"
                value={tabValue}
                onChange={handleTabChange}
                style={{
                    opacity: styles.headerOpacity,
                    background: `linear-gradient(${styles.headerBackgroundTopColor}, ${styles.headerBackgroundBottomColor})`,
                }}>
                {newFunction1(isMobile, isEditEstimate, isEmailDownload)}
                {newFunction2(isMobile, buildingDisabled)}
                {newFunction3(isMobile, buildingDisabled)}
                {newFunction4({isMobile, questions, isEmailDownload}, errors, isCurrentTabError, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction5(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction6(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction7(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction8(isMobile, questions, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}

                {newFunction9(isMobile, isEditEstimate, isEmailDownload)}
                {newFunction10(isMobile, buildingDisabled)}
                {newFunction11(isMobile, buildingDisabled)}
                {newFunction12(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction13(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction14(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction15(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}
                {newFunction16(isMobile, questions, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg)}

                {newFunction17(saveDisabled, handleUpdateRiskAndSaveInCompleteEstimate)}
                {newFunction18(saveDisabled, handleUpdateRiskAndSaveInCompleteEstimate)}
                <Button className='nav-tab action' disabled={isEditRisk || isEditEstimate || isEmailDownload ? false : true} tabIndex={0} onClick={cancelEdit}>Cancel</Button>
            </Tabs>
            <div className="fortab">
                <TabPanel value={tabValue} index={0}>
                    <RiskDetailContainer
                        name="riskDetail"
                        productId={productId}
                        profileId={profileId}
                        searchText={searchText}
                        value={value}
                        results={results}
                        suggestions={suggestions}
                        onChange={onChange}
                        onNewRequest={onNewRequest}
                        showSearchIcon={!autofillAddress}
                        cceImages={cceImages}
                        userList={userList}
                        handleChange={handleChange}
                        isEditEstimate={isEditEstimate}
                        isEmailDownload={isEmailDownload}
                        addressChosen={addressChosen}
                        isEditRisk={isEditRisk}
                        handleProperty={handleProperty}
                        errorPropertyMsg={errorPropertyMsg}
                        location={location}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <BuildingDetailsPageContainer  location={location} productId={productId} profileId={profileId} handleChange={handleChange} isEmailDownload={isEmailDownload}
                    isCurrentTabError={isCurrentTabError} questions={questions} postcode={postcode}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <RuralStructuresPageContainer  location={location} productId={productId} profileId={profileId} handleChange={handleChange} isEmailDownload={isEmailDownload}
                    isCurrentTabError={isCurrentTabError} questions={questions} />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <ExternalFeaturesPageContainer location={location} productId={productId} profileId={profileId} handleChange={handleChange} isEmailDownload={isEmailDownload} questions={questions} />
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <SpecialItemsContainer location={location} productId={productId} profileId={profileId} styles={styles} questions={questions} handleChange={handleChange} isEmailDownload={isEmailDownload} />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                    <ImageManagementContainer location={location}
                        changeImageDescription={changeImageDescription}
                        widthChange={widthChange}
                        productId={productId}
                        profileId={profileId}
                        handleChange={handleChange}
                        isEditEstimate={isEditEstimate}
                        isEmailDownload={isEmailDownload} questions={questions}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                    <CCERebuildCostPageContainer location={location}
                        handleChange={handleChange}
                        isEmailDownload={isEmailDownload} questions={questions} />
                </TabPanel>
            </div>
                {!isMobile && <ToggleBoxContainer />}

        </div>
    );
}

function newFunction18(saveDisabled, handleUpdateRiskAndSaveInCompleteEstimate) {
    return !saveDisabled && <Button className='nav-tab action' onClick={handleUpdateRiskAndSaveInCompleteEstimate} tabIndex={0}>Save <SpinnerContainer /></Button>;
}

function newFunction17(saveDisabled, handleUpdateRiskAndSaveInCompleteEstimate) {
    return saveDisabled && <Button className='nav-tab action' disabled={true} tabIndex={0}
        onClick={handleUpdateRiskAndSaveInCompleteEstimate}>Save <SpinnerContainer /></Button>;
}

function newFunction16(isMobile, questions, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return isMobile && <Tab className='nav-tab' label="Estimate" id='tab-6' disabled={isEmpty(questions.data) ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction15(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return isMobile && <Tab className='nav-tab' label="Images" id='tab-5' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction14(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return isMobile && <Tab className='nav-tab' label="Special" id='tab-4' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction13(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return isMobile && <Tab className='nav-tab' label="External" id='tab-3' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction12(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return isMobile && <Tab className='nav-tab' label="Rural" id='tab-2' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction11(isMobile, buildingDisabled) {
    return isMobile && !buildingDisabled && <Tab className='nav-tab' label="Building" id='tab-1' />;
}

function newFunction10(isMobile, buildingDisabled) {
    return isMobile && buildingDisabled && <Tab className='nav-tab' label="Building" id='tab-1'
        disabled={true} />;
}

function newFunction9(isMobile, isEditEstimate, isEmailDownload) {
    return isMobile && <Tab className='nav-tab' label="Risk" id='tab-0' disabled={isEditEstimate || isEmailDownload ? true : false} />;
}

function newFunction8(isMobile, questions, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="Rebuild Cost" id='tab-6' disabled={isEmpty(questions.data) ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction7(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="Image Management" id='tab-5' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction6(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="Special Items" id='tab-4' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction5(isMobile, questions, isEmailDownload, errors, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="External Features" id='tab-3' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction4({isMobile, questions, isEmailDownload}, errors, isCurrentTabError, depdencyErrors, postcodeErrorMsg, errorPropertyMsg) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="Rural Structures" id='tab-2' disabled={isEmpty(questions.data) || isEmailDownload ||
        (errors !== null && errors !== undefined && errors.length > 0 && !isCurrentTabError(questions, errors)) || (depdencyErrors !== null
            && depdencyErrors !== undefined && depdencyErrors.length > 0) || postcodeErrorMsg !== null || errorPropertyMsg !== null ? true : false} />;
}

function newFunction3(isMobile, buildingDisabled) {
    return !isMobile && !buildingDisabled && <Tab className='nav-tab nav-table-left' label="Building Details" id='tab-1' />;
}

function newFunction2(isMobile, buildingDisabled) {
    return !isMobile && buildingDisabled && <Tab className='nav-tab nav-table-left' label="Building Details" id='tab-1'
        disabled={true} />;
}

function newFunction1(isMobile, isEditEstimate, isEmailDownload) {
    return !isMobile && <Tab className='nav-tab nav-table-left' label="Risk Details" id='tab-0' disabled={isEditEstimate || isEmailDownload ? true : false} />;
}

function getConstantSaveDisabled(isEditRisk, isIncompleteEstimate, estimatePrice, isEditEstimate, answerUpdated) {
    return isEditRisk || (isIncompleteEstimate && (estimatePrice === "In Progress" || estimatePrice === null)) ||
        (isEditEstimate && !answerUpdated && estimatePrice !== "In Progress" && estimatePrice !== null) ? false : true;
}

function getBuildingDisabledValue(addressChosen, statePostCode, postcodeErrorMsg, errorPropertyMsg, isEditRisk, isEmailDownload, isEditEstimate) {
    return (isEmpty(addressChosen) || addressChosen.suggestion === "" || statePostCode === null || postcodeErrorMsg !== null ||
        errorPropertyMsg !== null || isEditRisk || isEmailDownload) && !isEditEstimate ? true : false;
}

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={`tab-${index}`}
            className="tab-panel"
        >
            {value === index && children}
        </div>
    );
}

CCENavigation.propTypes = {
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    updateImagesDescription: PropTypes.func,
    saveInCompleteEstimate: PropTypes.func
};

export default CCENavigation;