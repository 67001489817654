class Utils {
    static buildProfileSpecificCss = (styles) => {
        if (!styles) return '';
        const {asteriskColour, buttonBorderWidth, buttonBorderColor, enableAntiAliasing} = styles;
        let css = '' +
            '.questions .question .required:after {\n' +
            '    content: \' *\';\n' +
            `    color: ${asteriskColour};\n` +
            '}\n' +
            'button.secondary {\n' +
            `    border: ${buttonBorderWidth} solid rgba(0, 0, 0, 0) !important;\n` +
            '}\n' +
            'button.secondary:hover {\n' +
            `    border: ${buttonBorderWidth} solid ${buttonBorderColor} !important;\n` +
            '}\n'
        ;
        if (enableAntiAliasing) {
            css = css +  '' +
            '* {\n' +
            '    -webkit-font-smoothing: antialiased;\n' +
            '    -moz-osx-font-smoothing: grayscale;\n' +
            '}\n';
        }
        return css;
    };
}

export default Utils;