import apiBaseUrl from '../apiBaseUrl';

const cookieConsentApi = (productId, profileId, isCookieConsentBoxDismissed) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/cookiedetails?isCookieConsentBoxDismissed=${isCookieConsentBoxDismissed}`;
    return fetch(url,
        {
            method: 'POST',
        }
    );
};

export default cookieConsentApi;