import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import SearchRisk from './SearchRisk';
import { Pagination } from 'semantic-ui-react';
import UserListPreview from './UserListPreview';
import UrlConstants from '../constants/UrlConstants';
import UserDeleteDialog from './UserDeleteDialog';
import SpinnerContainer from '../containers/SpinnerContainer';

class FilterUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            activeIndex: null,
        };
    }

    handleSelectUser = (index) => { 
        this.setState({ activeIndex: index }); 
    };
    componentWillMount() {
        const { onLoad } = this.props;
        onLoad();
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
        const { searchUserResults, isAdminUserRole, productId, profileId, userGroupId } = this.props;
        searchUserResults();
        isAdminUserRole(productId, profileId, userGroupId, "userPage");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { searchUserResults, userSearchParams, userSortParams, userPagination, deleteUser } = this.props;
        if (userSearchParams.searchBy !== prevProps.userSearchParams.searchBy ||
            (((userSearchParams.searchText != null && userSearchParams.searchText.length > 2)
                || (userSearchParams.searchText == null || userSearchParams.searchText === "")) &&
                userSearchParams.searchText !== prevProps.userSearchParams.searchText)
            || userSortParams.sortBy !== prevProps.userSortParams.sortBy
            || userSortParams.sortOrder !== prevProps.userSortParams.sortOrder
            || userPagination.activePage !== prevProps.userPagination.activePage
            || (deleteUser.open !== prevProps.deleteUser.open && deleteUser.refresh !== true)) {
            searchUserResults();
        }

    }

    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    onChangeSearchText = (e, { value }) => {
        const { setUserSearchText } = this.props;
        setUserSearchText(value);
    };

    handleChange = (event) => {
        const { setUserSearchBy } = this.props;
        setUserSearchBy(event.target.value);
    };

    onPageChange = (event, data) => {
        const { updateUserActivePageNo } = this.props;
        updateUserActivePageNo(data.activePage);
    }

    requestSort = (sortBy) => {
        const { userSortParams, sortUsers } = this.props;
        let sortOrder = 'descending';
        if (userSortParams && userSortParams.sortBy === sortBy && userSortParams.sortOrder === 'descending') {
            sortOrder = 'ascending';
        }
        sortUsers(sortBy, sortOrder);
    };

    onEditUser = (userId) => {
        const { profileId, productId, getUserDetail } = this.props
        getUserDetail(productId, profileId, userId);
    }
    handleUserDeleteDialog = (open, userDetail) => {
        const { userDeleteDialog } = this.props
        userDeleteDialog(open, userDetail);
    }
    handleUserDelete = (id) => {
        const { productId, profileId, deleteUserrecord } = this.props;
        deleteUserrecord(productId, profileId, id);
    }

    render() {
        const { deleteError, profileId, productId, styles, onAddUser, userSearchResult, deleteUser, isAdminUser, loggedInUserDetail } = this.props;
        let fontFamilyName = styles.globalFontFamily;
        return (
            <div className='cce-search'>
                <Card className='filter-card user-filter-card' style={{ marginLeft: '1px', marginTop: '-10px' }}>

                    <div className="action-nav">
                        <div className="nav-previous" style={{ alignSelf: 'flex-start' }}>
                            <div className="action-user-header">User Management:</div>
                        </div>
                        <div className="nav-next nav-user-next" style={{ alignSelf: 'flex-end' }}>
                                    <Button className="button add" variant="outlined"
                                        style={{ fontFamily: fontFamilyName }}
                                        rounded="true"
                                        size="small"
                                        tabIndex={1}
                                        onClick={onAddUser}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false}
                                        disabled={!isAdminUser}
                                    >+ Add User</Button>
                        </div>
                    </div>

                    <div className="action-nav">
                        <div className="nav-previous" style={{ alignSelf: 'flex-start', width: '100%' }}>
                            <CardText className='est-filter-card-text' style={{ flexDirection: 'column' }}>  
                                <div className='fields  est-fields flex' style={{padding: '0.3rem 0rem'}}>
                                    <FormControl className='search-select dropdown-filter' style={{  }}>
                                        <Select disableUnderline className="select-box est-select-box user-select-box"
                                            style={{ fontFamily: fontFamilyName, width: '12rem' }}
                                            value={this.props.userSearchParams.searchBy}
                                            onChange={this.handleChange}
                                            tabIndex={1}>
                                            <MenuItem value="userName">Show All User Name</MenuItem>
                                            <MenuItem value="fullName">Show All Full Name</MenuItem>
                                            <MenuItem value="emailId">Show All Email</MenuItem>
                                            <MenuItem value="companyName">Show All Company</MenuItem>
                                            <MenuItem value="divisionName">Show All Division</MenuItem>
                                            <MenuItem value="teamName">Show All Team</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div className='est-search user-search'>
                                        <SearchRisk
                                            name="addressInput"
                                            productId={productId}
                                            profileId={profileId}
                                            styles={styles}
                                            searchText={this.props.userSearchParams.searchText}
                                            fontFamilyName={fontFamilyName}
                                            onChangeSearchText={this.onChangeSearchText}
                                            pageName="userMgmt"
                                        /><SpinnerContainer />
                                    </div>
                                </div>
                                <div className="action-nav">
                                    <div className="nav-previous user-page-no" style={{
                                        alignSelf: 'flex-start', fontFamily: fontFamilyName
                                    }}>
                                        <span>Displaying {userSearchResult.currentPageSize} of {userSearchResult.totalElements} Users</span>
                                    </div>
                                </div>
                            </CardText>
                        </div>
                    </div>
                </Card>
                <div className='cce-risk-preview-card' style={{ fontFamily: fontFamilyName }}>
                <div className="pagination">
                    <Pagination className="pagination"
                        activePage={this.props.userPagination.activePage}
                        totalPages={this.props.userPagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                    />
                </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }} className='risk-data'>
                    <div className='usergroup-preview-div'>
                        <UserListPreview
                            productId={productId}
                            profileId={profileId}
                            fontFamilyName={fontFamilyName}
                            searchResult={userSearchResult}
                            isMobile={this.state.widthChange <= UrlConstants.MOBILE_INNER_WIDTH}
                            requestSort={this.requestSort}
                            userSortParams={this.props.userSortParams}
                            onEditUser={this.onEditUser}
                            handleUserDeleteDialog={this.handleUserDeleteDialog}
                            handleSelectUser={this.handleSelectUser}
                            activeIndex={this.state.activeIndex}
                        />
                    </div>
                </div>
                <div className='cce-risk-preview-card' style={{ fontFamily: fontFamilyName }}>
                <div className="pagination">
                    <Pagination className="pagination"
                        activePage={this.props.userPagination.activePage}
                        totalPages={this.props.userPagination.totalPages}
                        onPageChange={this.onPageChange}
                        ellipsisItem={null}
                    />
                    <UserDeleteDialog
                        styles={this.props.styles}
                        deleteUser={deleteUser}
                        handleUserDeleteDialog={this.handleUserDeleteDialog}
                        handleUserDelete={this.handleUserDelete}
                        loggedInUserDetail={loggedInUserDetail}
                        deleteError={deleteError}
                    />

                </div>
                </div>
            </div>
        );
    }
}

FilterUser.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    searchUserResults: PropTypes.func.isRequired
};

export default FilterUser;