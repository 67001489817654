import React from 'react';
import Header from "./Header";
import UrlConstants from "../constants/UrlConstants";
import {printTermsAndConditions} from "../util/PrintUtil";
import TermsOfUse from "../constants/TermsOfUse";


const TermsOfUsePage = ({productId}) => {
    const styles = {
        headerLogoLeftPaddingTop: ".46875rem",
    };

    let cceHomepageStyle =  "homepage" ; 
    if (productId === UrlConstants.PRODUCT_ID_10) {
        cceHomepageStyle = "homepage cce";
    }
    function getContent() {
        if (productId === UrlConstants.PRODUCT_ID_7) {
            return (<div id="auTermAndCondition">{TermsOfUse.AU}</div>);
        }
        if (productId === UrlConstants.PRODUCT_ID_9) {
                    return (<div id="cceAuTermAndCondition">{TermsOfUse.CCE_AU}</div>);
        }
        if (productId === UrlConstants.PRODUCT_ID_10) {
                    return (<div id="cceNzTermAndCondition">{TermsOfUse.CCE_NZ}</div>);
        }
        return (<div id="nzTermAndCondition">{TermsOfUse.NZ}</div>);
    }

    function onPrint() {
        let sourceElement = null;
        if (productId === UrlConstants.PRODUCT_ID_8) {
            sourceElement = 'nzTermAndCondition';
        } else if (productId === UrlConstants.PRODUCT_ID_7) {
            sourceElement = 'auTermAndCondition';
        }else if (productId === UrlConstants.PRODUCT_ID_10) {
            sourceElement = 'cceNzTermAndCondition';
        }else if (productId === UrlConstants.PRODUCT_ID_9) {
            sourceElement = 'cceAuTermAndCondition';
        }
        printTermsAndConditions(sourceElement);
        return true;
    }

    return (
        <div className={cceHomepageStyle}>
            
            {productId === UrlConstants.PRODUCT_ID_10 ?
            <Header debug={false} styles={styles}
                                images={{headerLogoLeftUrl: "https://content.rpdata.com/zap/dev/logo-110.png" }}
                                text={{headerTitle: "| Cordell Commercial Estimator"}}/> :
            <Header debug={false} styles={styles} images={{headerLogoLeftUrl: "https://content.rpdata.com/zap/dev/logo-110.png", headerLogoRightUrl: "https://content.rpdata.com/zap/dev/200x64_CordellSumSure_26pxFont_White.png"}} text={{headerTitle: null}}/>}
            <div className="home-page-container">
                <div style={{
                    flexShrink: 0,
                    maxWidth: '64rem',
                    textAlign: 'justify',
                    backgroundColor: 'white',
                    color: 'rgba(74, 74, 74, 0.6)',
                    padding: '1rem 2rem',
                    marginBottom: '16px',
                }}>
                    <table style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{textAlign: 'left'}}>
                                <h1 style={{marginBottom: '1rem', color: 'black'}}>Terms of Use</h1>
                            </td>
                            <td style={{textAlign: 'right'}}>
                                <span style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}} onClick={onPrint}>Print</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    { getContent() }
                </div>
            </div>
        </div>);
}

export default TermsOfUsePage;
