import apiBaseUrl from '../apiBaseUrl';

const viewEmailApi = (pathName) => {
    let strippedPath = pathName.substring(1);
    const url = `${apiBaseUrl()}` + strippedPath;
    return fetch(url,
        {
            method: 'GET'
        }
    );
};

export default viewEmailApi;