import React from 'react';
import { FlatButton } from 'material-ui';
import { primaryButtonStyle, secondaryButtonStyle } from '../style/styles';
import PropTypes from 'prop-types';

class CustomFlatButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }

    render() {
        const { onPress, label, type, profileStyles, className, style, id, display } = this.props;
        const { primaryButtonFontColour, primaryButtonFontSize, primaryButtonColour, buttonTextCase } = profileStyles;
        const { secondaryButtonFontColour, secondaryButtonFontSize, secondaryButtonColour } = profileStyles;
        if (type === 'primary') {
            return (
                <FlatButton
                    tabIndex={0}
                    onKeyPressCapture={event => {
                        if (event.key === 'Enter') {
                            onPress.call();
                        }
                    }}
                    id={id}
                    className={className}
                    onClick={onPress}
                    label={label}
                    hoverColor={primaryButtonColour}
                    backgroundColor={primaryButtonColour}
                    labelStyle={{
                        ...primaryButtonStyle.labelStyle,
                        fontSize: primaryButtonFontSize,
                        color: primaryButtonFontColour,
                        textTransform: buttonTextCase ? buttonTextCase : "uppercase"
                    }}
                    style={{
                        ...primaryButtonStyle.style,
                        ...style,
                        maxWidth: '30rem',
                    }}
                    onMouseEnter={() => {
                        this.setState({ hover: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ hover: false });
                    }}
                    primary={true}
                    disableTouchRipple={true}
                    disableFocusRipple={false}
                    disabled={display}
                />
            );
        } else if (type === 'secondary') {
            return (
                <FlatButton
                    tabIndex={0}
                    onKeyPressCapture={e => {
                        if (e.key === 'Enter') {
                            onPress.call();
                        }
                    }}
                    className={'secondary ' + className}
                    onClick={onPress}
                    label={label}
                    hoverColor={secondaryButtonColour}
                    backgroundColor={secondaryButtonColour}
                    labelStyle={{
                        ...secondaryButtonStyle.labelStyle,
                        fontSize: secondaryButtonFontSize,
                        color: secondaryButtonFontColour,
                        textTransform: buttonTextCase ? buttonTextCase : "uppercase"
                    }}
                    style={{
                        ...secondaryButtonStyle.style,
                        ...style,
                        maxWidth: '30rem',
                    }}
                    onMouseEnter={() => {
                        this.setState({ hover: true });
                    }}
                    onMouseLeave={() => {
                        this.setState({ hover: false });
                    }}
                    disableTouchRipple={true}
                    disableFocusRipple={false}
                />
            );
        }
    }
}

CustomFlatButton.propTypes = {
    onPress: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    profileStyles: PropTypes.object.isRequired,
    className: PropTypes.string,
    style: PropTypes.object.isRequired,
    id: PropTypes.string,
    display: PropTypes.bool,
    keyboardFocus: PropTypes.bool,
    onKeyPressCapture: PropTypes.func.isRequired,
};

export default CustomFlatButton;