import CCEEmail from '../components/CCEEmail';
import {changeEmail, emailReport, downloadPdfReport, changeEmailSubject, changeEmailMessage, sendNowEmail, cancelEmail} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        report: state.report,
        recipient: state.recipient,
        emailText: state.profile.text.email,
        message: state.message,
        styles: state.profile.styles,
        pdfReport: state.pdfReport,
        result: state.result,
        display: state.profile.display,
        downloadPdfReportText: state.profile.text.downloadPdfReport,
    };
};

export const mapDispatchToProps = (dispatch, emailReportAction = emailReport, downloadReportAction = downloadPdfReport) => {
    return {
        onEmailChange: (email) => dispatch(changeEmail(email)),
        onEmailReport: () => dispatch(emailReportAction()),
        onDownloadPdfReport: () => dispatch(downloadReportAction()),
        onEmailSubjectChange: (subject) => dispatch(changeEmailSubject(subject)),
        onEmailMessageChange: (message) => dispatch(changeEmailMessage(message)),
        onSendNowEmail: () => dispatch(sendNowEmail()),
        onCancelEmail: () => dispatch(cancelEmail()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CCEEmail);
