import { connect } from 'react-redux';
import ImportRisks from '../components/ImportRisks';
import { bindActionCreators } from 'redux';
import { selectTableExport, uploadExportTable } from '../actions/questionsActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import { trackSegmentPageView } from "../actions/segmentActionsCreator";

const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        loggedInUserDetail: state.loggedInUserDetail,
        uploadExportTableType: state.uploadExportTableType,
        productId: state.productId,
        profileId: state.profileId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        trackSegmentPageView: trackSegmentPageView,
        selectTableExport: selectTableExport,
        uploadExportTable: uploadExportTable,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Import/Export Page', ownProps.location.pathname, stateProps.sessionTrackId, "Import/ExportPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Import/Export Page", pageDetails);
        },
        selectTableExport: dispatchProps.selectTableExport,
        uploadExportTable: dispatchProps.uploadExportTable,
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImportRisks);