import React from 'react';
import UrlConstants from '../constants/UrlConstants';
import history from '../util/history';
import HomePageContainer from '../containers/HomePageContainer';
import LoginPageContainer from '../containers/LoginPageContainer';

class LandingPage extends React.Component {

    componentWillMount() {
        if (!this.validateUrl()) {
            history.push("/not_found");
        }
    }

    validateUrl = () => {
        const { productId, requestUrl } = this.props;
        return (!((requestUrl.includes(UrlConstants.COM_AU) && (productId === UrlConstants.PRODUCT_ID_8 || productId === UrlConstants.PRODUCT_ID_10)) ||
            (requestUrl.includes(UrlConstants.CO_NZ) && (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_9))));
    };

    render() {
        const { productId, profileId } = this.props;

        let homePage = ((productId === UrlConstants.PRODUCT_ID_9 || productId === UrlConstants.PRODUCT_ID_10) &&
            (profileId !== UrlConstants.PROFILE_ID_411)) ? <LoginPageContainer location={this.props.location} match={this.props.match} /> :
            <HomePageContainer location={this.props.location} match={this.props.match} />;

        return (
            <div>
                {homePage}
            </div>
        );
    }
}

export default LandingPage;