import React from 'react';
import { filter, groupBy, sortBy, isEmpty } from 'lodash';
import Section from './Section';
import SpinnerContainer from '../containers/SpinnerContainer';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import UrlConstants from '../constants/UrlConstants';
import { Button } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';


class SpecialItems extends React.Component {

    componentWillMount() {
        const {  updateCurrentTab, updateRiskDetail, currentTab} = this.props;
         if(currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail(); 
        }
        updateCurrentTab(UrlConstants.SPECIAL_ITEMS);
    }
    render() {
        const { styles, text, handleChange, questions, isEmailDownload } = this.props;
        const visibleQuestions = filter(this.props.questions.data, (question) => {
            return (question.section.name.toLowerCase().indexOf('Special Items'.toLowerCase()) >= 0) && question.visible;
        });
        const questionsSorted = sortBy(visibleQuestions, (question) => {
            return question.section.lineNo;
        });
        const groupedByQuestions = groupBy(questionsSorted, (param) => {
            return param.section.name;
        });
        const handleNext = (event, newValue) => {
            handleChange(UrlConstants.IMAGE_MANAGEMENT);
        };
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.EXTERNAL_FEATURES);
        }

        let specialItems;
        const errorMsgStyle = { fontFamily: styles.globalFontFamily };
        return (
            <div>
                <div className="questions" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: styles.globalFontFamily,
                }}>
                    <SpinnerContainer />
                    <div className="cce-questions-wrapper">
                        {
                            Object.keys(groupedByQuestions).map((section, i) => {
                                specialItems = <Section key={i} styles={styles} text={text} section={{
                                    name: section,
                                    questions: groupedByQuestions[section],
                                    sectionTileColour: styles.sectionTileColour,
                                }} onHelpInformationDialogOpen={this.props.openHelpInformationDialog} />;
                                return null;
                            })
                        }
                        {specialItems}
                        {this.props.errors !== null && this.props.errors !== undefined
                            && this.props.errors.length > 0 &&
                            <FormHelperText autofocus style={errorMsgStyle} className="cce-estimate-error">Please fill in all mandatory fields marked with an asterisk (*)
              with valid values</FormHelperText>}
                        <div className="action-nav">
                            <div className="nav-previous"><Button className="action_previous ext-features" variant="outlined" id="tab-2"
                                tabIndex={0}
                                rounded="true"
                                size="large"
                                disabled={isEmpty(questions.data) || isEmailDownload ||
                                    (this.props.errors !== null && this.props.errors !== undefined
                                        && this.props.errors.length > 0) ? true : false}
                                disableFocusRipple={false}
                                disableTouchRipple={true}
                                onClick={handlePrevious}
                            > &larr; External Features
                        </Button></div>
                            <div className="nav-next"><Button className="action_next_special ext-features" variant="outlined" id="tab-4"
                                rounded="true"
                                size="large"
                                tabIndex={0}
                                disabled={(this.props.errors !== null && this.props.errors !== undefined
                                    && this.props.errors.length > 0) ? true : false}
                                disableTouchRipple={true}
                                disableFocusRipple={false}
                                onClick={handleNext}
                            > Image Management &rarr;
                        </Button></div>
                        </div>
                    </div>
                    <HelpInformationDialogContainer />
                    <LetUsKnowDialogContainer />
                </div>
            </div>
        );
    }
}

SpecialItems.propTypes = {
    displayRefreshWarning: PropTypes.bool.isRequired,
    questions: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    debug: PropTypes.bool,
    styles: PropTypes.object,
    text: PropTypes.object,
    openHelpInformationDialog: PropTypes.func,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,

};

export default SpecialItems;