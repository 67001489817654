import React from 'react';
import PropTypes from 'prop-types';

const ResultLine = ({variable, totalLineNo, styles, currencySymbol, cceProduct = false, isIndemnity =  false }) => {
    const textClass = (type) => {
        if (variable.title.startsWith('TOTAL')) {
            return `variable variable-total-${type}`;
        } else if (variable.subtotal) {
            if((type === "result" && (variable.title === "Total estimated declared value"
            || variable.title === "Total Allowance for Cost Inflation"
            || variable.title === "Total" || variable.title === "Total (including GST)" || variable.title === "Total - Indemnity Value"))
            ){
                return `variable variable-subtotal-price-${type}`;
            } else {
                if((type === "title" && (variable.title === "Total" || variable.title === "Total - Indemnity Value" || variable.title.startsWith("Total (including GST)")))){
                                return `variable variable-subtotal-title-total`;
                             }else {
                    return `variable variable-subtotal-${type}`;
                }
                }

        } else {
            return `variable variable-${type}`;
        }
    };
    if (variable.title.startsWith("Total Weeks")){
        currencySymbol = "";
    }

    const rowClass = (variableNew, totalLineNoNew) => {
      if (totalLineNoNew === variableNew.lineNo) {
          return 'variable-total-container';
      } else if (totalLineNoNew < variableNew.lineNo && totalLineNoNew !== 0) {
          return 'variable-breakdown-container';
      } else {
          return 'variable-container';
      }
    };

    const IsIndemnityEmpty = isIndemnity && variable.price === 0  ? true : false;
    return (
        <div id={variable.title} className={rowClass(variable, totalLineNo)}>
            <span className={textClass('title')} style={{fontFamily: styles.globalFontFamily, color: styles.questionLabelFontColour}}>
                {variable.title === "Total - Indemnity Value" ? "Total" : variable.title}</span>
            {IsIndemnityEmpty   &&
            <span className={textClass('result')} style={{fontFamily: styles.globalFontFamily, color: styles.questionLabelFontColour, textAlign : 'center',paddingRight: '30px'}}>-</span>
            }
            {!IsIndemnityEmpty &&
            <span className={textClass('result')} style={{fontFamily: styles.globalFontFamily, color: styles.questionLabelFontColour}}>{currencySymbol ? "$" : ""}
             
            {(cceProduct) ? Number(Math.floor(variable.price)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0}) : Number(variable.price).toLocaleString()}</span>
            }
        </div>
    );
};

ResultLine.propTypes = {
    variable: PropTypes.object.isRequired,
    totalLineNo: PropTypes.number.isRequired,
    styles: PropTypes.object,
};

export default ResultLine;
