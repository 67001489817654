import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const RepriceExportDialog = ({ styles, isRepriceExport, repriceExportDialog, repriceInProgress, action }) => {

    let message = repriceInProgress ? 'The "Reprice and Export" process is running. You will see updated repriced estimate once the action has completed.'
        : 'The "Reprice and Export" process has commenced for the selected estimates. You will receive an email notification once the action has completed.';
    return (
        <Dialog
            className="dialog terms-dialog"
            open={isRepriceExport || repriceInProgress}
            maxWidth={'sm'}
            onClose={() => repriceExportDialog()}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="reprice-export-dialog">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {message} <br></br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                {(!repriceInProgress || (repriceInProgress && action === 'REPRICE_EXPORT')) && <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="OK" onPress={() => repriceExportDialog(false)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>}
                {(repriceInProgress && action !== 'REPRICE_EXPORT') && <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Proceed" onPress={() => repriceExportDialog(true)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => repriceExportDialog(false)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>}
            </DialogActions>
        </Dialog>)
};

RepriceExportDialog.propTypes = {
    styles: PropTypes.object.isRequired,
    handleReturnToEstimate: PropTypes.func.isRequired,
};

export default RepriceExportDialog;