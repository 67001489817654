import React from 'react';
import {CircularProgress} from 'material-ui';
import PropTypes from 'prop-types';

const Spinner = ({loading}) => {
    return (loading) ?
        <div className="spinner" style={{zIndex: 9999}}>
            <CircularProgress size={80} thickness={6} />
        </div>
        : null;
};

Spinner.propTypes = {
    loading: PropTypes.bool.isRequired,
};

export default Spinner;