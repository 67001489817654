import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EstimatePreview from './EstimatePreview';
import DialogContentText from '@material-ui/core/DialogContentText';
import {isEmpty} from 'lodash';

const EstimatePreviewMobileDialog = ({ productId, profileId, fontFamilyName, searchResult, handleRepriceEstimate, handleEstimateDetailDeleteDialog,
    editEstimate, handleDialogClose, handleEmailDownloadReport }) => {
    return (
        <Dialog 
            className="dialog preview"
            style={{ overflowY: 'auto' }}
            open={searchResult.openPreviewDialog}
            maxWidth={'sm'}
            onClose={() => handleDialogClose()}
        >
            <DialogContent >
                {isEmpty(searchResult.estimateResult) &&
                    <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: fontFamilyName }}>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        <b style={{ color: 'black' }}>Please note there is no estimate associated with this risk</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContentText>}

                {!isEmpty(searchResult.estimateResult) && <EstimatePreview
                    productId={productId}
                    profileId={profileId}
                    fontFamilyName={fontFamilyName}
                    searchResult={searchResult}
                    handleRepriceEstimate={handleRepriceEstimate}
                    handleEmailDownloadReport={handleEmailDownloadReport}
                    handleEstimateDetailDeleteDialog={handleEstimateDetailDeleteDialog}
                    editEstimate={editEstimate}
                />}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', overflow: 'hidden' }}>
                        <CustomFlatButtonContainer className="terms" label="Close" onPress={() => handleDialogClose()}
                            type="primary" style={{ minWidth: '4em', fontFamily: fontFamilyName, borderRadius: '2rem' }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>)
};

EstimatePreviewMobileDialog.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
    searchResult: PropTypes.object.isRequired,
    handleRepriceEstimate: PropTypes.func.isRequired,
    handleEstimateDetailDeleteDialog: PropTypes.func.isRequired,
};

export default EstimatePreviewMobileDialog;