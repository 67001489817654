import UserFirstLastName from '../components/UserFirstLastName';
import {firstNameChange, lastNameChange} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    const {data: recipient} = state.recipient;
    return {
        recipient,
        userNameText: state.profile.text.userName,
        styles: state.profile.styles,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onFirstNameChange: (firstName) => dispatch(firstNameChange(firstName)),
        onLastNameChange: (lastName) => dispatch(lastNameChange(lastName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFirstLastName);