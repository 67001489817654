import React from 'react';
import QuestionGroup from './QuestionGroup';
import SectionHeader from './SectionHeader';
import {Card, CardText} from 'material-ui/Card';
import {sortBy} from 'lodash';
import PropTypes from 'prop-types';
import SpecialQuestionGroup from './SpecialQuestionGroup';

export const groupByDependantFlag = (questions) => {
    const groups = [];
    let index;
    for (index = 0; index < questions.length; index++) {
        const question = questions[index];
        if (groups.length === 0) {
            groups.push({dependant: question.dependant, questions: [question]});
        } else {
            const previousGroup = groups[groups.length - 1];
            if (previousGroup.dependant === question.dependant) {
                previousGroup.questions.push(question);
            } else {
                groups.push({dependant: question.dependant, questions: [question]});
            }
        }
    }
    return groups;
};

const Section = ({section, styles, onHelpInformationDialogOpen, text}) => {
    const questionsSorted = sortBy(section.questions, (question) => {
        return question.lineNo;
    });
    const groups = groupByDependantFlag(questionsSorted);
    return (
        <Card className="card" containerStyle={{paddingBottom: 0, fontFamily: styles.globalFontFamily}}>
            <SectionHeader name={section.name} color={section.sectionTileColour} styles={styles}/>
            <CardText style={{padding: '0'}}>
                    {
                        groups.map((group, index) => {
                            if (section.name.toLowerCase().indexOf('Special Items'.toLowerCase()) >= 0 ) {
                                return <SpecialQuestionGroup key={index} questions={group.questions}
                                         dependant={group.dependant} styles={styles}/>;
                            } else {
                                return <QuestionGroup key={index} questions={group.questions}
                                          dependant={group.dependant} styles={styles}
                                          onHelpInformationDialogOpen={onHelpInformationDialogOpen} text={text}/>;
                            }
                        })
                    }
            </CardText>
        </Card>
    );
};

Section.propTypes = {
    section: PropTypes.object.isRequired,
    styles: PropTypes.object,
    text: PropTypes.object,
};

export default Section;