export default {
    profile: {
        display: {
            userName: false,
            email: true,
            downloadReport: false,
            acceptPropertyId: false,
            attributesCheck: false,
            showInIframe: false,
            contentInsurance: false,
            questionsPageDisclaimerShowAtBottom: true,
            returnToQuote: false,
            displayContactUs: false,
            goToQuoteOpenInNewTab: false,
            returnToQuoteCloseWindow: false,
            displayCTAButton1: false,
            includeAddressInReturnUrl: false,
            includeEmailAddress: false,
        },
        images: {
            headerLogoLeftUrl: null,
            headerLogoRightUrl: null,
            homePageBackgroundImageUrl: null,
            questionPageBackgroundImageUrl: null,
            mobileHeaderLogoImageRightUrl: null,
            mobileHeaderLogoImageLeftUrl: null,
        },
        styles: {
            headerTitleAlignment: null,
            headerBackgroundTopColor: null,
            headerBackgroundBottomColor: null,
            headerHeight: null,
            headerOpacity: null,
            sectionTileColour: null,
            searchAgainButtonColour: null,
            headerTitleFontColour: null,
            headerTitleFontSize: null,
            questionLabelFontSize: null,
            questionLabelFontColour: null,
            questionHelpFontSize: null,
            questionHelpFontColour: null,
            answerTextColour: null,
            answerSelectedTextColour: null,
            answerFontSize: null,
            primaryButtonColour: null,
            primaryButtonFontColour: null,
            primaryButtonFontSize: null,
            secondaryButtonColour: null,
            secondaryButtonFontColour: null,
            secondaryButtonFontSize: null,
            callToActionFontColour: null,
            callToActionFontSize: null,
            callToActionBackgroundColour: null,
            globalFontFamily: null,
            sectionTextColour: null,
            homePageHeaderFontSize: null,
            homePageHeaderFontColour: null,
            aboveAddressBarFontSize: null,
            aboveAddressBarFontColour: null,
            belowAddressBarFontSize: null,
            belowAddressBarFontColour: null,
            headerTitleFontFamily: null,
            headerTitleLeftPadding: null,
            headerTitleRightPadding: null,
            questionPageTitleFontSize: null,
            questionPageTitleFontColour: null,
            headerTitleVerticalAlignment: null,
            footerBackgroundColour: null,
            footerFontColour: null,
            footerFontSize: null,
            searchAgainButtonFontColour: null,
            homePageBackgroundFocus: null,
            questionPageBackgroundFocus: null,
            helpLinkFontColor: null,
            helpLinkFontSize: null,
            landingPageNotesFontSize: null,
            landingPageNotesFontColor: null,
            landingPageNotesAlignment: null,
            landingPageNotesBackgroundColor: null,
            buttonBorderWidth: null,
            buttonBorderColor: null,
            buttonRadius: null,
            sectionHeadingTextCase: null,
            sectionHeaderBorderWidth: null,
            sectionHeaderBorderColor: null,
            asteriskColour: null,
            homePageBackgroundColour: null,
            questionPageBackgroundColour: null,
            headerTitleFontWeight: null,
            questionPageTitleFontWeight: null,
            questionPageTitleTextCase: null,
            footerFontSizeLandingPage: null,
            footerFontColourLandingPage: null,
            buttonTextCase: null,
            enableAntiAliasing: null,
            sectionHeadingFontSize: null,
            sectionHeadingFontWeight: null,
            headerLogoLeftPaddingTop: null,
            headerLogoLeftPaddingLeft: null,
            headerLogoRightPaddingTop: null,
            headerLogoRightPaddingRight: null,
            headerLogoLeftWidth: null,
            headerLogoLeftHeight: null,
            headerLogoRightWidth: null,
            headerLogoRightHeight: null,
            landingPageFooterBannerBackgroundColor: null,
            landingPageFooterBannerTransparency: null,
            landingPageFooterDisclaimerMaxWidth: null,
            landingPageFooterDisclaimerAlignment: null,
            landingPageSearchBoxBackgroundColor: null,
            landingPageSearchBoxBackgroundTransparency: null,
            landingPageSearchBoxWidth: null,
            landingPageSearchBoxHeight: null,
            questionPageSearchBoxWidth: null,
            questionPageSearchBoxHeight: null,
            radioButtonDefaultColor: null,
            radioButtonSelectedColor: null,
            radioButtonDefaultTextColor: null,
            radioButtonSelectedTextColor: null,
            questionPageAddressFontSize: null,
            questionPageAddressBackgroundColor: null
        },
        text: {
            callToAction: null,
            userName: null,
            email: null,
            disclaimer: null,
            disclaimerCalculator1: null,
            disclaimerCalculator2: null,
            aboveAddressBar: null,
            belowAddressBar: null,
            homePageHeader: null,
            downloadPdfReport: null,
            pdfDisclaimer1: null,
            pdfDisclaimer2: null,
            questionPageTitle: null,
            headerTitle: null,
            whatNextSectionTitle: null,
            goToQuoteText: null,
            goToQuoteHeader: null,
            goToQuoteButtonLabel: null,
            returnToQuoteText: null,
            returnToQuoteHeader: null,
            returnToQuoteButtonLabel: null,
            landingPageNotesText: null,
            rebuildCostHeader: null,
            rebuildCostNote: null,
            rebuildCostFooterNote: null,
            customHtmlText: null,
            rebuildBreakupSectionHeader: null,
            contactUsHeader: null,
            contactUsButtonText: null,
            contactUsButtonLabel: null,
            whatNextSectionText: null,
            callToActionSectionTemplate: null,
            calltoActionText1Header: null,
            calltoActionText1: null,
            calltoActionText2Header: null,
            calltoActionText2: null,
            ctaButton1Header: null,
            ctaButton1Text: null,
            ctaButton1Label: null,
            downloadPdfReportBodyText: null,
        },
        profileAccessControl: {
            accessMode: null,
            accessToken: null,
        },
        segmentTrackingCode: null,
        returnUrls: null,
        hashingPrivateKey: null,
        contactUsUrl: null,
        bypassTc: false,
        logoLeftUrl: null,
        logoRightUrl: null,
        ctaButton1Url: null,
    },
    suggestions: {
        loading: false,
        data: null,
        error: null,
    },
    questions: {
        loading: false,
        data: null,
        error: null,
    },
    addressChosen: {},
    result: {
        manual: false,
        loading: false,
        data: null,
        content: null,
        error: null,
        exceedLimitMessage: null,
    },
    report: {
        sentReport: false,
        loading: false,
        error: null,
    },
    pdfReport: {
        loading: false,
    },
    recipient: {
        data: { firstName: '', lastName: '', email: null, subject: null, message: null },
        error: false,
        errorMsg: null,
    },
    displayRefreshWarning: false,
    productId: null,
    profileId: null,
    debug: false,
    aerialImage: null,
    propertyImage: null,
    debugReport: {
        dialogOpen: false,
        token: null,
    },
    footer: {
        termsAndConditionsOpen: false,
        disclaimerOpen: false,
    },
    segment: {
        sessionTrackId: null,
    },
    maintenanceMode: false,
    message: '',
    currentDataPartition: '',
    autoMailMessage: '',
    helper: {
        question: {},
        helpInformationOpen: false,
    },
    accessDenied: false,
    segmentAnalytics: null,
    returnToQuote: {
        returnUrl: null,
        quoteId: null,
    },
    showResult: false,
    addressChosenTime: null,
    calculateButtonClicked: false,
    defaultCalculation: false,
    isError: null,
    errorType: null,
    errorText: null,
    errors: [],
    letusknow: {
        dialogOpen: false,
        propertyId: null,
        address: null,
        loading: false,
    },
    partnerId: null,
    contactUs: {
        loaded: false,
        error: null,
    },
    showNote: null,
    riskDetail: {
        policyNumber: null,
        insured: null,
        underwriter: null,
        lastValuation: null,
        assignedTo: null,
        address: null,
        propertyId: null,
        postcode: null,
    },
    imageUploads: {
        files: null,
    },
    cceImages: {
        data: null,
    },
    counter: false,
    imageDetail: {
        imageDescriptionDetail: [],
        errorMessagee: null,
        imageDesc: null,
    },
    noImageFile: null,
    widthChange: null,
    open: false,
    estimateID: null,
    buildingGrp: [],
    riskId: null,
    defaultGrp: null,
    expanded: false,
    expandedIndex: 0,
    isBuildingLoading: false,
    isRiskIdTriggered: false,
    searchResult: {
        data: [],
        error: null,
        estimateResult: [],
        isOpen: false,
        riskId: null,
        isLoading: false,
        openPreviewDialog: false,
    },
    searchParams: {
        pageSize: 10,
        searchBy: "address",
        searchText: null,
        fromDate: null,
        toDate: null,
        searchDateOption: "createdDate",
    },
    isEditRisk: false,
    sortParams: {
        dateChange: "createdDate",
        sortOrder: "descending",
    },
    pagination: {
        activePage: 1,
        totalPages: 5,
    },
    activeIndex: null,
    isEditEstimate: false,
    deleteRisk: {
        riskDialog: false,
        riskId: 0,
        address: null,
    },
    isRiskOrEstimate: null,
    deleteEstimate: {
        estimateDialog: false,
        estimateId: null,
        createdDate: null,
        estimatePrice: null,
    },
    isSendEmailNow: false,
    isCancelEmail: false,
    isIncompleteEstimate: true,
    inCompleteEstimate: {
        loading: false,
    },
    estimatePrice: null,
    isEmailDownload: false,
    userGroupOption: "select",
    companyName: null,
    teamName: null,
    divisionName: null,
    disclaimer: null,
    roleId: null,
    isActive: true,
    parentUserGroupId: null,
    logo: null,
    updatedBy: null,
    userGroups: {
        data: [],
    },
    roleType: {
        data: [],
    },
    selectedCompany: "select",
    selectedDivision: "select",
    selectedTeam: {
        team: "select",
    },
    isAddUserGroup: {
        loading: false,
    },
    userGroupSearchResult: {
        data: [],
        totalElements: 0,
        currentPageSize:0

    },
    groupSearchParams: {
        searchBy: "companyName",
        searchText: null,
    },
    isAddGroupEnabled: false,
    isFilterEnabled: true,
    isUserGroupOptionSystem: false,
    isUsageReport: false,
    groupSortParams: {
        sortBy: "role",
        sortOrder: "descending",
    },
    userGroupId: -1,
    isEditUserGroup: false,
    userGroupDetail: {
        userGroupOption: 'Team',
        selectedCompanyName: null,
        companyId: null,
        divisionId: null,
        selectedDivisionName: null,
        teamMemberName: null,
        teamMemberId: null,
        teamName: null,
        teamId: null,
        systemId: null,
        logo: null,
        groupSharePolicy: null,
    },
    userCapturedDetail : {
        fullName : null,
        emailAddress: null,
        error: null,
    },
    userDetail: {
        userId: null,
        userGroupId: 1,
        useremailId: null,
        userfullname: null,
        isActive: true,
        roleTypeId: null,
        successMsg: null,
        userValidationMsg: null,
        isUserNameSearch: false,
        emailValidationMsg: null,
        errorMsg: null
    },
    userSearchParams: {
        searchBy: "userName",
        searchText: null,
    },
    userPagination: {
        activePage: 1,
        totalPages: 5,
    },
    grpPagination: {
        activePage: 1,
        totalPages: 5,
    },
    isAddUserEnabled: false,
    isFilterUserEnabled: true,
    userActiveIndex: null,
    userSearchResult: {
        data: [],
        totalElements: 0,
        currentPageSize:0

    },
    userList: {
        data: []
    },
    userId: -1,
    userSortParams: {
        sortBy: "userName",
        sortOrder: "descending",
    },
    sortConfig: {
        key: null,
        direction: 'ascending',
    },
    answers: [],
    deleteGroup: {
        open: false,
        deleteUserGroupDetail: {
            id: 0,
            role: null,
            teamName: null,
            divisionName: null,
            companyName: null,
        },
        refresh: false,
    },
    deleteUser: {
        open: false,
        deleteUserDetail: {
            userId: 0,
            role: null,
            userName: null,
        },
        refresh: false,
        hasMultipleAccount: false,
    },
    isUserGroupListing: {
        loading: false,
    },
    isUserListing: {
        loading: false,
    },
    isEditUserEnabled: false,
    editTeam: 0,
    editCompany: 0,
    editDivision: 0,
    editUserDetail: {
        id: null,
        userName: null,
        userGroupId: null,
        updatedBy: null,
        fullName: null,
        emailId: null,
        active: null,
        roleTypeId: null,
        errorMsg: null,
        successMsg: null
    },
    editUserId: null,
    claudtoken: {
        access_token: null,
        refresh_token: null,
        expires_in: null,
        validToken: false,
        error: false,
        apigee_token: null,
        message: null,
    },
    tokenContents: {},
    isLogout: false,
    code: null,
    tokenStatus: "",
    homePath: "",
    loggedInUserDetail: {
        id: null,
        userName: "select",
        userGroupId: null,
        companyGroupId: null,
        updatedBy: null,
        fullName: null,
        emailId: null,
        active: null,
        userGuid: null,
        clAppAcctUserGuid: null,
        roleTypeId: null,
        companyLogo: null,
        divisionLogo: null,
        companyDisclaimer: null,
        divisionDisclaimer: null,
    },
    isAdminUser: false,
    loggedInUserGroupRole: null,
    postcode: null,
    errorPropertyMsg: null,
    pageSize: 10,
    totalRecords: null,
    existingUser: false,
    depdencyErrors: [],
    isEstimateRepriced: false,
    isFirstEditEstimate: true,
    currentTab: null,
    isPropertyIdTriggered: false,
    postcodeErrorMsg: null,
    isPostcodeLocked: false,
    risks: [],
    answerUpdated: false,
    returnToEstimate: false,
    returnToEstimateDialog: false,
    isCancelEstimate: false,
    isRepriceExport: false,
    activeRiskId: null,
    exportStatus: false,
    isExport: false,
    selectedAllRisk: false,
    repriceInProgress: false,
    excludedRisks: [],
    deleteError: null,
    action: null,
    estimateDetail: null,
    uploadExportTableType: null,
    updatedFinalMap: null,
    chartImage: null,
    selectedReportCategoryType: null,
    selectedCompanyUsage: null,
    selectedDivisionUsage: null,
    selectedTeamUsage: null,
    usageStartDate: null,
    usageEndDate: null,

}
