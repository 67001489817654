import Footer from '../components/Footer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setDisclaimerOpen, setTermsAndConditionsOpen} from '../actions/actionCreator';


export const mapStateToProps = (state) => {
    return {
        TermsOfUseDialogOpen: state.footer.termsAndConditionsOpen,
        disclaimerDialogOpen: state.footer.disclaimerOpen,
        state: state.addressChosen.state,
        styles: state.profile.styles,
        productId: state.productId,
        profileId: state.profileId,
        disclaimerContentBefore: state.profile.text.disclaimerCalculator1,
        disclaimerContentAfter: state.profile.text.disclaimerCalculator2,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onTermsAndConditionsOpen: () => dispatch(setTermsAndConditionsOpen(true)),
        onTermsAndConditionsClose: () => dispatch(setTermsAndConditionsOpen(false)),
        onDisclaimerOpen: () => dispatch(setDisclaimerOpen(true)),
        onDisclaimerClose: () => dispatch(setDisclaimerOpen(false)),
    }, dispatch
    );
};

export const mergeProps = (stateProps, dispatchProps) => {
    return {
        termsAndConditions: {
            dialogOpen: stateProps.TermsOfUseDialogOpen,
            onOpen: dispatchProps.onTermsAndConditionsOpen,
            onClose: dispatchProps.onTermsAndConditionsClose,

        },
        disclaimer: {
            dialogOpen: stateProps.disclaimerDialogOpen,
            onOpen: dispatchProps.onDisclaimerOpen,
            onClose: dispatchProps.onDisclaimerClose,
            disclaimerTextDialog: stateProps.disclaimerTextDialog,
            disclaimerContentBefore: stateProps.disclaimerContentBefore,
            disclaimerContentAfter: stateProps.disclaimerContentAfter,
        },
        state: stateProps.state,
        styles: stateProps.styles,
        productId: stateProps.productId,
        profileId: stateProps.profileId,
    };
};

const FooterContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Footer);

export default FooterContainer;