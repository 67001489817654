import React from "react";

class InformationConsent extends React.Component {


    render() {
        return (
            <div className="consent" style={{fontSize:'12px'}}>
                <label>
                    <p>By submitting my personal information, I confirm:</p>
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                        <li>I have read and agree to the <strong>Sum Sure Terms of Use</strong> and <a href="https://www.corelogic.com.au/legals/privacy-policy" target="_blank" rel="noopener noreferrer"> CoreLogic’s Privacy Policy </a> and
                            I agree to any personal information being used as described in CoreLogic’s Privacy Policy; and</li><br />
                        <li> I consent to my personal information being disclosed to and used by {""}
                            <span class="tooltip"><u>CoreLogic’s</u><span class="tooltiptext"><strong>CoreLogic</strong> means :
                                <ol style={{ listStyleType: 'lower-alpha' }}>
                                    <li>RP Data Pty Ltd trading as CoreLogic Asia Pacific (ABN 67 087 759 171) and its Australian subsidiaries and related bodies corporate, including Valuation Exchange Pty Ltd, Cordell Information Pty Ltd, OnTheHouse.com.au Pty Ltd , Residex Pty Ltd (CoreLogic Australia); and/or</li><br />
                                    <li>CoreLogic NZ Limited and its New Zealand subsidiaries and related bodies corporate (CoreLogic NZ).</li>
                                </ol></span></span> {""}
                            <span class="tooltip"><strong>Commercial Partners</strong><span class="tooltiptext"><strong>Commercial Partners</strong> means insurance companies, insurance brokers, and other providers in or to the insurance industry who have a contractual relationship with CoreLogic from to time.</span></span>
                            {""} for the purposes of (and I consent to receiving from them) updates about products & services, marketing and special offers, and I understand that if I do not agree to my personal information being so used, I will not be able to use the {""}
                            <span class="tooltip"><strong>Sum Sure Services</strong><span class="tooltiptext"><strong>Sum Sure Services</strong> means the services available on this website including access to and provision of the Sum Sure Estimate for the nominated property.</span></span>.
                            You can opt-out at any time. See our <a href="https://www.corelogic.com.au/legals/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> to find out more.</li>
                    </ol>
                </label>
            </div>
        );
    }
}

export default InformationConsent;
