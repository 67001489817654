import React from 'react';
import PropTypes from 'prop-types';
import { groupBy, forEach } from 'lodash';
import SpecialQuestionNumberGroup from "./SpecialQuestionNumberGroup";

const SpecialQuestionGroup = ({questions, dependant, styles}) => {

    const numbers = [0,1,2,3,4,5,6,7,8,9];
    const groupedQuestions = groupBy(questions, (question) => {
        let groupNumber = -1;
        forEach(numbers, (number) => {
            if (question.text.includes(number)) {
                groupNumber = number;
                return false;
            }
        });
        if (groupNumber !== -1) {
            return groupNumber;
        }
        return 0;
    });

    return (
        <div style={{padding: '1rem 2rem'}} className={dependant ? 'dependant-questions' : ''}>
            {
                Object.keys(groupedQuestions).map((number, i) => {
                    return <SpecialQuestionNumberGroup key={i} questions={groupedQuestions[number]} styles={styles} />;
                })
            }
        </div>
    );
};

SpecialQuestionGroup.propTypes = {
    questions: PropTypes.array.isRequired,
    dependant: PropTypes.bool.isRequired,
    styles: PropTypes.object,
};
export default SpecialQuestionGroup;