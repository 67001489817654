import React from 'react';
import { filter, groupBy, isEmpty, sortBy } from 'lodash';
import Section from './Section';
import ResultContainer from '../containers/ResultContainer';
import SpinnerContainer from '../containers/SpinnerContainer';
import HelpInformationDialogContainer from '../containers/HelpInformationDialogContainer';
import Header from '../components/Header';
import CalculateResult from './CalculateResult';
import RefreshWarning from './RefreshWarning';
import FooterContainer from '../containers/FooterContainer';
import DisclaimerSection from './DisclaimerSection';
import ConfirmationMessage from './ConfirmationMessage';
import PropTypes from 'prop-types';
import LetUsKnowDialogContainer from "../containers/LetUsKnowDialogContainer";
import history from '../util/history';
import UrlUtil from "../util/UrlUtil";
import QuestionsPageBanner from "./QuestionsPageBanner";
import SectionHeader from "./SectionHeader";
import { Card } from "material-ui";
import UrlConstants from '../constants/UrlConstants';
import UserDetailContainer from '../containers/UserDetailContainer';
import CookiesConsentBoxContainer from '../containers/CookiesConsentBoxContainer';

class QuestionsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        const { onLoad, onFetch, productId, profileId, addressChosen, propertyImages, debug, contentInsurance, partnerId } = this.props;
        if (isEmpty(addressChosen)) {
            history.push(this.buildHomeUrl(productId, profileId));
        } else {
            onLoad();
            onFetch({productId, profileId}, addressChosen.propertyId, addressChosen.suggestion, [], debug, contentInsurance, partnerId);
            if (addressChosen.propertyId) {
                propertyImages(productId, addressChosen.propertyId);
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { productId: currentProductId, profileId: currentProfileId } = this.props;
        const { productId, profileId } = nextProps;
        if (currentProductId !== productId || currentProfileId !== profileId) {
            history.push(this.buildHomeUrl(productId, profileId));
        }
    }

    handleWindowSizeChange = () => {
        const { handleBrowserWidthChanges } = this.props;
        handleBrowserWidthChanges(window.innerWidth);
    };

    scrollToFirstError = () => {
        const { errors } = this.props;
        if (errors && errors.length > 0) {
            let errorIds = errors.map(error => error.questionId);
            let elements = document.getElementsByClassName("questionLayout");
            let firstElement = [...elements].filter(element => errorIds.includes(parseInt(element.id.split("-")[1], 10)))[0];
            if (firstElement) {
                firstElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                })
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.refreshHiddenQuestions);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.refreshHiddenQuestions);
    }

    refreshHiddenQuestions = () => {
        const activeElementId = document.activeElement ? document.activeElement.id : null;
        if (activeElementId && document.activeElement.value && ((activeElementId === "302188" || activeElementId === "302436")
            || (activeElementId === "302178" || activeElementId === "302426"))) {
            document.getElementById(activeElementId).blur();
        }
    };

    buildHomeUrl = (productId, profileId) => {
        const { token, partner, debug, quoteId, returnUrl, showNote } = this.props;
        let url = `/products/${productId}/profiles/${profileId}`;
        return UrlUtil.addParams({ baseUrl: url, token, partner, debug, quoteId, returnUrl, showNote });
    };

    handleClose = () => {
        const { productId: currentProductId, profileId: currentProfileId, onSearchAgain } = this.props;
        onSearchAgain();
        history.push(this.buildHomeUrl(currentProductId, currentProfileId));
    };

    render() {
        const { images, styles, text, logoLeftUrl, logoRightUrl,profile } = this.props;
        const filterQuestions = filter(this.props.questions.data, (question) => {
            return question.visible;
        });
        const questionsSortedBySection = sortBy(filterQuestions, (question) => {
            return question.section.lineNo;
        });
        const groupedQuestions = groupBy(questionsSortedBySection, (param) => {
            return param.section.name;
        });

        let result;
        let questionSection;
        let additionalInformation;
        let disclaimerSection;
        let disclaimerSectionTop;
        let userDetails;
        ({ userDetails, disclaimerSection, disclaimerSectionTop} =
        this.displayUserAndDisclaimerSections(profile, userDetails, disclaimerSection, disclaimerSectionTop, text, styles));
        if (this.props.debug) {
            result = <ResultContainer
                productId={this.props.productId}
                profileId={this.props.profileId}
                propertyId={this.props.addressChosen.propertyId}
                addressChosen={this.props.addressChosen.suggestion}
                onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
            />;
        } else if (this.props.result.data && this.props.result.data.variables.length > 0) {

            if (this.props.attributesCheck && !this.props.showResult) {
                result =
                    <Card className="card">
                        <SectionHeader name={this.props.rebuildCostSectionHeader}
                            color={this.props.styles.sectionTileColour} styles={this.props.styles} />
                        <ConfirmationMessage styles={this.props.styles}
                            showResultSection={() => this.props.showResultAfterConfirmation(true)} /></Card>;

            } else {

                ({ disclaimerSectionTop, disclaimerSection, result } = this.newMethod_1(disclaimerSectionTop, disclaimerSection, text, styles, result));

            }
        } else {
            result = <CalculateResult
                result={this.props.result} styles={styles} handleClose={this.handleClose}
                openDialog={true} attributesCheck={this.props.attributesCheck}
                showResult={this.props.showResult}
                productId={this.props.productId}
                profileId={this.props.profileId}
                propertyId={this.props.addressChosen.propertyId}
                address={this.props.addressChosen.suggestion}
                onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog}
                scrollToFirstError={this.scrollToFirstError}
                partnerId={this.props.partner}
            />;
        }
        let isProfileEligibleForCookiesConsentBox = false;
        if (this.props.productId === '7' && (this.props.profileId === '141' || this.props.profileId === '142')) {
            isProfileEligibleForCookiesConsentBox = true;
        }
        return (
            <div>
            <div className="questions" style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: styles.globalFontFamily,
            }}>
                <SpinnerContainer />

                <Header debug={this.props.debug} images={images} styles={styles} text={text} logoLeftUrl={logoLeftUrl}
                    logoRightUrl={logoRightUrl} />


                {
                    (this.state.productId !== UrlConstants.PRODUCT_ID_9 && this.state.productId !== UrlConstants.PRODUCT_ID_10)
                    &&
                    this.props.displayRefreshWarning &&
                    <RefreshWarning handleClose={this.handleClose} refreshDialogOpen={true}/>
                }


                <QuestionsPageBanner images={images} styles={styles} aerialImage={this.props.aerialImage}
                    propertyImage={this.props.propertyImage}
                    suggestion={this.props.addressChosen.suggestion}
                    text={text}
                    handleClose={this.handleClose}
                />

                <div className="questions-wrapper">
                    {disclaimerSectionTop}
                    {
                        Object.keys(groupedQuestions).map((section, i) => {
                            questionSection = <Section key={i} styles={styles} section={{
                                name: section,
                                questions: groupedQuestions[section],
                                sectionTileColour: styles.sectionTileColour,
                            }} onHelpInformationDialogOpen={this.props.openHelpInformationDialog} text={text} />;
                            if (section === "Additional Information") {
                                additionalInformation = questionSection;
                                return null;
                            } else {
                                return questionSection;
                            }
                        })
                    }
                    <div>{userDetails}</div>
                    <div>
                        {result}
                    </div>
                    {additionalInformation}
                    {disclaimerSection}
                    <FooterContainer />
                </div>
                <HelpInformationDialogContainer />
                <LetUsKnowDialogContainer />
            </div>
           <div style={{ display: 'flex', justifyContent: 'center' }}>{isProfileEligibleForCookiesConsentBox && <CookiesConsentBoxContainer productId={this.props.productId} profileId={this.props.profileId}/>}</div> 
    </div>
        );
    }

    newMethod_1(disclaimerSectionTop, disclaimerSection, text, styles, result1) {
        let result = result1;
        if (this.props.showDisclaimerAtBottom === true) {
            disclaimerSectionTop = "";
            disclaimerSection =
                <DisclaimerSection state={this.props.addressChosen.state}
                    contentBefore={text.disclaimerCalculator1}
                    contentAfter={text.disclaimerCalculator2} styles={styles}
                    productId={this.props.productId}
                    showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
        }
        result = <ResultContainer
            productId={this.props.productId}
            profileId={this.props.profileId}
            propertyId={this.props.addressChosen.propertyId}
            addressChosen={this.props.addressChosen.suggestion}
            onLetUsKnowDialogOpen={this.props.openLetUsKnowDialog} />;
        return { disclaimerSectionTop, disclaimerSection, result };
    }

    displayUserAndDisclaimerSections(profile, userDetails, disclaimerSection, disclaimerSectionTop, text, styles) {
        if (profile.display.includeEmailAddress  && !profile.display.restrictDownloadReport ) {
            userDetails = <UserDetailContainer userCapturedDetail={this.props.userCapturedDetail} />;
        }
        if (this.props.showDisclaimerAtBottom === false) {
            disclaimerSection = "";
            disclaimerSectionTop =
                <DisclaimerSection state={this.props.addressChosen.state}
                    contentBefore={text.disclaimerCalculator1}
                    contentAfter={text.disclaimerCalculator2} styles={styles}
                    productId={this.props.productId}
                    showDisclaimerAtBottom={this.props.showDisclaimerAtBottom} />;
        }
        return { userDetails, disclaimerSection, disclaimerSectionTop };
    }
}

QuestionsPage.propTypes = {
    displayRefreshWarning: PropTypes.bool.isRequired,
    addressChosen: PropTypes.object.isRequired,
    questions: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    aerialImage: PropTypes.string,
    propertyImage: PropTypes.string,
    debug: PropTypes.bool,
    images: PropTypes.object.isRequired,
    styles: PropTypes.object,
    text: PropTypes.object,
    onSearchAgain: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
    propertyImages: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    openHelpInformationDialog: PropTypes.func,
    token: PropTypes.string,
    attributesCheck: PropTypes.bool.isRequired,
    showResultAfterConfirmation: PropTypes.func.isRequired,
    showDisclaimerAtBottom: PropTypes.bool.isRequired,
    stateProductId: PropTypes.string,
    partner: PropTypes.string,
    logoLeftUrl: PropTypes.string,
    logoRightUrl: PropTypes.string,
};

export default QuestionsPage;