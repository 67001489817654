import CalculateActionButton from '../components/CalculateActionButton';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {performCalculation} from '../actions/calculationActionCreator';

export const mapStateToProps = (state) => {
    return {
        profileId: state.profileId,
        productId: state.productId,
        propertyId: state.addressChosen.propertyId,
        addressChosen: state.addressChosen.suggestion,
        questions: state.questions,
        styles: state.profile.styles,
        attributesCheck: state.profile.display.attributesCheck,
        showResult: state.showResult,
        contentInsurance: state.profile.display.contentInsurance,
        errors: state.errors,
        partnerId: state.partnerId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({performCalculation}, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    let title = stateProps.productId === '7' && (stateProps.profileId === '141' || stateProps.profileId === '142') ? 'Submit':'Calculate';
    return {
        title: title,
        onPress: () => {
            let obj1 = {
                productId : stateProps.productId,
                profileId : stateProps.profileId,
                propertyId : stateProps.propertyId,
                address : stateProps.addressChosen,
                questions : stateProps.questions
            };
            dispatchProps.performCalculation(obj1, stateProps.contentInsurance, ownProps.partnerId);
        },
        styles: stateProps.styles,
        attributesCheck: stateProps.attributesCheck,
        showResult: stateProps.showResult,
        scrollToFirstError: ownProps.scrollToFirstError,
        errors: stateProps.errors,
        partnerId: stateProps.partnerId,
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CalculateActionButton);
