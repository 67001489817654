import emailReportApi from '../api/emailReportApi';
import downloadReportApi from '../api/downloadReportApi';
import { getProfile } from '../api/fetchProfile';
import viewEmailApi from '../api/viewEmailApi';
import { getPropertyImages } from '../api/getPropertyImages';
import apiBaseUrl from '../apiBaseUrl';
import ServerError from './ServerError';
import AccessDeniedError from './AccessDeniedError';
import history from '../util/history';
import DataRefreshError from './DataRefreshError';
import { trackSegmentEvent } from './segmentActionsCreator';
import { SegmentTrackingEventTypes } from '../constants/SegmentTrackingEventTypes';
import { debounce, filter, findIndex } from 'lodash';
import AnalyticsError from '../util/AnalyticsError';
import AnalyticsReport from '../util/AnalyticsReport';
import AnalyticsEvent from '../util/AnalyticsEvent';
import { saveAs } from 'file-saver';
import AnalyticsConstant from '../constants/AnalyticsConstant';
import UrlConstants from '../constants/UrlConstants';
import DomainConstants from "../constants/DomainConstants";
import AnalyticsCustomScript from "../util/AnalyticsCustomScript";
import WestpacGTagScript from "../util/WestpacGTagScript";
import WestpacGTagDLStart from "../util/WestpacGTagDLStart";
import WestpacGenericGTagDLStart from "../util/WestpacGenericGTagDLStart";
import { getCCEImages } from '../api/getCCEImages';
import { removeCCEImages } from '../api/removeCCEImages';
import updateDescriptionApi from '../api/updateDescriptionApi';
import { getNoImageFileUrl } from '../api/getNoImageFileUrl';
import { validateAnswerApi } from '../api/validateAnswerApi';
import cookieConsentApi from '../api/cookieConsentApi';

const DEBOUNCE_WAIT = 500;
const excludedSections = ['introduction', 'additional information', 'special items'];

const handleJSONResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        return response.text().then((error) => {
            throw new ServerError(error, response.status);
        });
    }
};

export const serverError = (error) => {
    if (error.status === 503) {
        history.push('/unavailable');
        return { type: 'SERVER_UNAVAILABLE', error: error.message };
    } else if (error.status === 404) {
        history.push('/not_found');
        return { type: 'SERVER_ERROR', errorCode: error.type, error: error.message };
    } else {
        return { type: 'SERVER_ERROR', errorCode: error.type, error: error.message };
    }
};

export const accessDeniedError = (error, productId, profileId) => {
    if (error.status === 502) {
        let url = `/products/${productId}/profiles/${profileId}/access_not_available`;
        history.push(url);
        return { type: 'ACCESS_UNAVAILABLE', errorCode: error.type, error: error.message };
    } else {
        return serverError(error);
    }
};

export const receiveImages = (aerialImage, propertyImage) => {
    return { type: 'RECEIVE_IMAGES', aerialImage, propertyImage };
};

export const receiveCCEImages = (images) => {
    return { type: 'RECEIVE_CCE_IMAGES', images };
};
export const fetchPropertyImages = (productId, propertyId) => {
    return (dispatch) => getPropertyImages(productId, propertyId)
        .then((response) => response.json())
        .then((responseJson) => {
            return dispatch(receiveImages(responseJson.aerialImage, responseJson.propertyImage));
        });
};

export const receiveNoImageFile = (noImageFile) => {
    return { type: 'RECEIVE_NO_IMAGE_FILE', noImageFile };
};

export const fetchNoImageFileUrl = (productId, profileId) => {
    return (dispatch) => getNoImageFileUrl(productId, profileId)
        .then((response) => handleJSONResponse(response))
        .then((responseJson) => {
            return dispatch(receiveNoImageFile(responseJson.noImageFile));
        });
};

export const fetchCCEImages = (productId, profileId, estimateId) => {
    return (dispatch) => getCCEImages(productId, profileId, estimateId)
        .then((response) => handleJSONResponse(response))
        .then((responseJson) => {
            return dispatch(receiveCCEImages(responseJson.images));
        });
};

export const removeImages = (productId, profileId, id, estimateId) => {
    return (dispatch) => removeCCEImages(productId, profileId, estimateId, id)
        .then((response) => {
            return dispatch(fetchCCEImages(productId, profileId, estimateId));
        });
};

const toImages = (imageDescription) => {
    return { id: imageDescription.id, description: imageDescription.description };
};

export const updateImageDescription = (productId, profileId, imagesDescription, estimateId) => {
    const descriptions = imagesDescription ? imagesDescription.map((imageDescription) => toImages(imageDescription)) : [];
    return (dispatch) => updateDescriptionApi(productId, profileId, descriptions)
        .then((response) => {
            return dispatch(fetchCCEImages(productId, profileId, estimateId));
        });
};

export const onImageDescPicked = (imageDescription, cceImages) => {
    const id = imageDescription.imageId;
    const desc = imageDescription.imageDescription;
    const estimateId = imageDescription.cceImage.estimateId;
    if (imageDescription.cceImage.imageDescription !== String(imageDescription.imageDescription)) {
        const index = findIndex(cceImages.data, (selectedDesc) => {
            return selectedDesc.id === id;
        });
        cceImages.data[index] = { ...cceImages.data[index], isInvalid: false, imageDescription: desc };
        return (dispatch, getState) => {
            dispatch({ type: 'IMAGE_DESCRIPTION_CHANGED', cceImages: cceImages.data });
            const { productId, profileId } = getState();
            return fetchCCEImages(productId, profileId, estimateId);
        };
    }
};

export const addressChosen = (addressChosenValue) => {
    return (dispatch) => {
        const event = new AnalyticsEvent("Address Chosen", "New address search request", "Home Page", addressChosenValue);
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADDRESS_CHOSEN, event));
        dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: new Date().getTime() });
        dispatch({ type: 'ADDRESS_CHOSEN', addressChosen: addressChosenValue });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const cceAddressPicked = (cceAddressChosenValue) => {
    return (dispatch) => {
        const event = new AnalyticsEvent("CCE Address Chosen", "New address search request", "Home Page", cceAddressChosenValue);
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.ADDRESS_CHOSEN, event));
        dispatch({ type: 'UPDATE_ANALYTICS_TIME_TRACK', time: new Date().getTime() });
        dispatch({ type: 'CCE_ADDRESS_CHOSEN', cceAddressChosen: cceAddressChosenValue });
    };
};

const attemptFetchSuggestions = () => {
    return { type: 'ATTEMPT_FETCH_SUGGESTIONS' };
};
const attemptFetchResetSuggestions = () => {
    return { type: 'ATTEMPT_FETCH_SUGGESTIONS_RESET' };
};

const succeedFetchSuggestions = (data) => {
    return { type: 'SUCCEED_FETCH_SUGGESTIONS', data: data };
};

export const updateProfile = (productId, profileId, profile, token, partnerId) => {
    if (profile.profileAccessControl.accessMode !== null && profile.profileAccessControl.accessMode === 'restricted') {
        if (token === undefined || token !== profile.profileAccessControl.accessToken) {
            throw new AccessDeniedError("Access Unavailable", 502, productId, profileId);
        }
    }
    if (typeof partnerId === 'undefined') {
        partnerId = null;
    }
    return { type: 'PROFILE_UPDATED', productId, profileId, profile, partnerId };
};

export const validationError = (errors) => {
    return { type: 'VALIDATION_ERROR', errors };
};

const toAnswer = (question) => {
    return { question: question.id, answer: question.answer, visible: question.visible };
};

const toRiskAnswer = (question, state) => {
    if (question.text === "Policy Number:") {
        return { question: question.id, answer: state.riskDetail.policyNumber === null ? "" : state.riskDetail.policyNumber, visible: question.visible };
    }
    else if (question.text === "Insured:") {
        return { question: question.id, answer: state.riskDetail.insured === null ? "" : state.riskDetail.insured, visible: question.visible };
    }
    else if (question.text === "Underwriter:") {
        return { question: question.id, answer: state.riskDetail.underwriter === null ? "" : state.riskDetail.underwriter, visible: question.visible };
    }
    else if (question.text === "Assigned to:") {
    let assignedTo = "";
        if (state.userList != null && state.riskDetail.assignedTo !== null) {
                        const userDetail = filter(state.userList.data, (user) => {
                            return (user.id === state.riskDetail.assignedTo);
                        });
                        if (userDetail != null && userDetail.length > 0) {
                            assignedTo = userDetail[0].fullName;
                        }
                    }
        return { question: question.id, answer: assignedTo, visible: question.visible };
    }
    else if (question.text === "Last Valued Date:") {
        var lastValuationDate =  new Date(state.riskDetail.lastValuation);
        lastValuationDate = new Date(lastValuationDate.getTime() + Math.abs(lastValuationDate.getTimezoneOffset() * 60000));
        lastValuationDate = new Date(lastValuationDate).toUTCString();
        lastValuationDate = lastValuationDate.split(' ').slice(0, 4).join(' ');
        lastValuationDate = lastValuationDate.substring(4,lastValuationDate.length);
        if (lastValuationDate.indexOf("Date") >= 0 ||
            lastValuationDate.includes("Date")) {
            lastValuationDate = new Date(state.riskDetail.lastValuation);
            if (isNaN(lastValuationDate.getTime())) {
                lastValuationDate = state.riskDetail.lastValuation.trim();
                let month = "";
                switch (lastValuationDate.substr(5, 2)) {
                    case '01': month = "Jan"; break;
                    case '02': month = "Feb"; break;
                    case '03': month = "Mar"; break;
                    case '04': month = "Apr"; break;
                    case '05': month = "May"; break;
                    case '06': month = "Jun"; break;
                    case '07': month = "Jul"; break;
                    case '08': month = "Aug"; break;
                    case '09': month = "Sep"; break;
                    case '10': month = "Oct"; break;
                    case '11': month = "Nov"; break;
                    default: month = "Dec"; break;

                }
                lastValuationDate = lastValuationDate.substr(8, 2) + " " + month + " " + lastValuationDate.substr(0, 4);
                lastValuationDate = lastValuationDate.trim();
            }
        }
        return { question: question.id, answer: state.riskDetail.lastValuation === null ? "" : lastValuationDate, visible: question.visible };
    }
    else {
        return { question: question.id, answer: question.answer, visible: question.visible };
    }
};

export const fetchSuggestionsApi = (dispatch, productId, term) => {
    if (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_8 || productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) {
        dispatch(attemptFetchResetSuggestions());
    } else {
        dispatch(attemptFetchSuggestions());
    }
    return fetch(`${apiBaseUrl()}products/${productId}/suggestions?searchText=` + term, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => dispatch(succeedFetchSuggestions(data)));
};

const debounceFetchSuggestionsApi = debounce(fetchSuggestionsApi, DEBOUNCE_WAIT);

export const fetchSuggestions = (productId, term) => {
    return (dispatch) => {
        return debounceFetchSuggestionsApi(dispatch, productId, term);
    };
};

export const fetchProfile = (productId, profileId, token, broker) => {
    return (dispatch) =>
        getProfile(productId, profileId, broker)
            .then((response) => handleJSONResponse(response))
            .then((body) => {
                const profile = {
                    ...body,
                };
                if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || broker)) {
                    window.pageDetails.pageName = AnalyticsConstant.HOME_PAGE;
                    window.pageDetails.siteBrand = broker === undefined || broker === null ? 'wbg' : 'wbc';
                    siteExperience();
                    AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
                    WestpacGTagScript();
                    if (broker === undefined || broker === null) {
                        WestpacGenericGTagDLStart();
                    } else {
                        WestpacGTagDLStart();
                    }
                }
                dispatch(updateProfile(productId, profileId, profile, token, broker));
            }).catch((error) => dispatch(accessDeniedError(error, productId, profileId)));
};

export const setDebug = (debug) => {
    return { type: 'SET_DEBUG_MODE', debug };
};

export const setShowNote = (showNote) => {
    return { type: 'SET_SHOW_NOTE', showNote };
};

export const updateDebugToken = (data) => {
    return { type: 'UPDATE_DEBUG_TOKEN', data };
};

export const openDebugDialog = () => {
    return { type: 'OPEN_DEBUG_DIALOG' };
};

export const setTermsAndConditionsOpen = (open) => {
    return { type: 'OPEN_TERMS_AND_CONDITIONS_DIALOG', open };
};

export const setDisclaimerOpen = (open) => {
    return { type: 'OPEN_DISCLAIMER_DIALOG', open };
};

export const changeEmail = (email) => {
    return { type: 'CHANGE_EMAIL', data: email };
};

export const firstNameChange = (firstName) => {
    return { type: 'CHANGE_FIRST_NAME', data: firstName };
};

export const lastNameChange = (lastName) => {
    return { type: 'CHANGE_LAST_NAME', data: lastName };
};

export const toBuildingList = (buildingGrp) => {
    const buildingList = [];
    if (buildingGrp !== undefined) {
        buildingGrp.forEach(grp => {
            const buildingAnswers = []
            grp.forEach(group => {
                group.questions.forEach(question => buildingAnswers.push(toAnswer(question)));
            });
            buildingList.push(buildingAnswers);
        });
    }
    return buildingList;
};

export const updateRecipient = (autoMail, recipient, userCapturedDetail) => {
    if (autoMail &&
    userCapturedDetail != null && userCapturedDetail.emailAddress != null && userCapturedDetail.emailAddress !== "") {
        return  {   firstName: recipient.firstName,  lastName: recipient.lastName,   email: userCapturedDetail.emailAddress,
        subject: recipient.subject, message: recipient.message, error: recipient.error, errorMsg: recipient.errorMsg };
        } else {
        return  {   firstName: recipient.firstName,  lastName: recipient.lastName,   email: recipient.email,
        subject: recipient.subject, message: recipient.message, error: recipient.error, errorMsg: recipient.errorMsg};
        }
};

export const validateIfEmailIdMandatory = (autoMail, isEmail, email) => {
   return ((autoMail === undefined  || autoMail === null) || (autoMail !== null && autoMail !== true))
    && isEmail && (email === null || email === '');
};

export const validateIfEmailIdValid = (autoMail, isEmail, email) => {
   return  ((autoMail === undefined  || autoMail === null) || (autoMail !== null && autoMail !== true))
   && isEmail && !emailValidate(email);
};

function emailValidate(email) {
    let isEmailValid = true;
    if (email === null || email === undefined || email.trim() === "") {
        isEmailValid = false;
    } else {     
        isEmailValid = validateSpecialChars(email, isEmailValid);
    }
    return isEmailValid;
}

function validateSpecialChars(email, isEmailValid) {
    var regexExpression = /^\w$/;
    if (email.length <= 2 || email.indexOf("@") === -1 ||
        email.indexOf(".") === -1 || email.slice(-1) === "."
        || email.indexOf(".@") !== -1 || email.indexOf("@.") !== -1
        || email.indexOf("-@") !== -1 || email.indexOf("@-") !== -1
        || email.indexOf(".-") !== -1 || email.indexOf("-.") !== -1
        || email.indexOf("..") !== -1
        || !regexExpression.test(email.charAt(0)) || email.trim().indexOf(' ') !== -1) {
            isEmailValid = false;
    } else {
        isEmailValid = checkForIndexes(email, isEmailValid);
    }
    return isEmailValid;
}

function checkForIndexes(email, isEmailValid) {
    let index;
    let isEmailValidFlag = isEmailValid;
    for (index = 0; index < email.length; index++) {
        if ((email[index].toLowerCase() ===
        email[index].toUpperCase()) &&
            isNaN(email[index])
            && email[index] !== '_' && email[index] !== '.'
            && email[index] !== '@' && email[index] !== '-') {
                return false;
        }
    }
    let parts = email.split("@");
    if (parts !== null) {
        if (parts.length === 2) {
            let dot = parts[1].indexOf(".");
            let lastDot = parts[1].lastIndexOf(".");
            let afterlastDotString = parts[1].substring(lastDot+1);
            if (dot === -1 || dot < 1) {
                isEmailValidFlag = false;
            } else {
                var regExp = /^\w{2,3}$/;
                return regExp.test(afterlastDotString);
            }          
        } else {
            isEmailValidFlag = false;
        }
    }
    return isEmailValidFlag;
}

export const validateEmailIdIsUnique = (autoMail, userEmail, email) => {
   return  (autoMail !== true) && (userEmail === email);
};

export const emailReport = (autoMail) => {
    return (dispatch, getState) => {
        dispatch({ type: 'ATTEMPT_EMAIL_REPORT' });
        // capturing an image of line-chart used for display the "Estimated Rebuild Cost" details
        let chartImage = document.getElementById("line-chart") != null ?
            document.getElementById("line-chart").toDataURL("image/png") : null;
        dispatch(updateChartImage(chartImage));

        const state = getState();
        const { data: recipient } = state.recipient;
        const displayUserName = state.profile.display.userName;
        const { currentDataPartition } = state;
        const buildingGrp = state.buildingGrp;
        const buildingList = toBuildingList(buildingGrp);
        const recipientDetails = updateRecipient(autoMail, recipient, state.userCapturedDetail);
        if (UrlConstants.PROFILE_ID_WESTPAC.includes(state.profileId || state.partnerId)) {
            window.pageDetails.pageName = AnalyticsConstant.EMAIL;
            siteExperience();
            AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
        }
        if (displayUserName && (recipientDetails.firstName === '' || recipientDetails.lastName === '')) {
            dispatch({
                type: 'RECIPIENT_VALIDATION_ERROR',
                data: 'Please enter your name in the required fields before we email the report to you.'
            });
            return;
        }
        if (validateIfEmailIdMandatory(autoMail, state.profile.display.email, recipientDetails.email)) {
            dispatch({ type: 'RECIPIENT_VALIDATION_ERROR', data: 'Email address must not be empty' });
            return;
        }
        if ((recipientDetails.subject === null || recipientDetails.subject === '')) {
                    dispatch({ type: 'RECIPIENT_VALIDATION_ERROR', data: 'Email subject must not be empty' });
                    return;
                }
        if (validateIfEmailIdValid(autoMail, state.profile.display.email, recipientDetails.email)) {
            dispatch({ type: 'RECIPIENT_VALIDATION_ERROR', data: 'Email address is not valid' });
            return;
        }
        if (validateEmailIdIsUnique(autoMail, state.userCapturedDetail.emailAddress, recipientDetails.email)) {
            dispatch({ type: 'RECIPIENT_VALIDATION_ERROR', data: 'User email address and entered email address must not be same' });
            return;
         }
        let answers = [];
        state.questions.data.forEach(q => {
            if (q.section && excludedSections.includes(q.section.name.toLowerCase())) {
                //do nothing
            } else if (q.section.name === 'Risk Details') {
                answers.push(toRiskAnswer(q, state))
            } else {
                answers.push(toAnswer(q));
            }
        });
        return emailReportApi({
            productId: state.productId,
            profileId: state.profileId,
            propertyId: state.addressChosen.propertyId,
            body: {
                recipient: recipientDetails,
                estimation: {
                    estimateId: state.result.data.estimateId,
                    variables: state.result.data.variables,
                    breakupVariablesInM2: state.result.data.breakupVariablesInM2,
                    totalsBreakup: state.result.data.totalsBreakup,
                    specialItems: state.result.data.specialItems,
                    declaredValue: state.result.data.declaredValue,
                    allowancesForCostInflation: state.result.data.allowancesForCostInflation,
                    indemnityValue: state.result.data.indemnityValue,
                },
                riskDetailsResponse: {
                    riskId: state.riskId,
                    propertyId: state.riskDetail.propertyId,
                    postcode: state.riskDetail.postcode,
                },
                loggedInUserDetail: {
                    companyLogo: state.loggedInUserDetail.companyLogo,
                    divisionLogo: state.loggedInUserDetail.divisionLogo,
                    companyDisclaimer: state.loggedInUserDetail.companyDisclaimer,
                    divisionDisclaimer: state.loggedInUserDetail.divisionDisclaimer,
                },
                property: {
                    propertyId: state.addressChosen.propertyId,
                    address: state.addressChosen.suggestion,
                    state: state.addressChosen.state,
                },
                pdfDisclaimer1: state.profile.text.pdfDisclaimer1,
                pdfDisclaimer2: state.profile.text.pdfDisclaimer2,
                estimateSectionHeader: state.profile.text.rebuildCostHeader,
                answers,
                buildingList,
                chartImage: state.chartImage,
            },
            currentDataPartition,
            brokerId: state.partnerId,
        }).then((response) => {
            if (response.ok) {
                if((state.productId !== UrlConstants.PRODUCT_ID_10) && (state.productId !== UrlConstants.PRODUCT_ID_9)) {
                    if (currentDataPartition !== response.headers.get('currentDataPartition')) {
                        throw new DataRefreshError();
                    }
                }
                if (state.userCapturedDetail != null && state.userCapturedDetail.emailAddress != null && state.userCapturedDetail.emailAddress !== "") {
                    dispatch({ type: 'SUCCESS_EMAIL_REPORT_CAPTURE', autoMail : autoMail });
                } else {
                    dispatch({ type: 'SUCCESS_EMAIL_REPORT' });
                }
                let obj1 = {
                    action: "EMAIL",
                    estimateId: state.result.data.estimateId,
                    PartnerId: state.partnerId
                };
                const event = new AnalyticsReport(state.productId, state.profileId, state.propertyId, "", "SUCCESS", "Rebuild cost report successfully sent",
                    obj1);
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EMAIL_REBUILD_COST_REPORT, event));
            } else {
                return response.text().then((error) => {
                    throw new ServerError(error, response.status);
                });
            }
        }).catch((error) => {
            let obj8 = {
                ProductId: state.productId,
                ProfileId: state.profileId,
                PropertyId: state.propertyId
            }
            const event = new AnalyticsError(obj8, "", "FAILURE",
                "Failed to email rebuild cost report", {
                    'EstimateId': state.result.data.estimateId,
                    'Error': error
                }, state.partnerId);
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.EMAIL_REBUILD_COST_REPORT, event));
            if (error instanceof DataRefreshError) {
                dispatch({ type: 'UPDATE_REFRESH_WARNING', data: true });
                dispatch({ type: 'LOADING_COMPLETED' });
            } else {
                if (state.profile.display.email && error.status === 400) {
                    dispatch({ type: 'EMAIL_SEND_ERROR', error: error.message })
                } else {
                    dispatch(serverError(error));
                }
            }
        });
    };
};


export const downloadPdf = (fileUrl, fileName) => {
    fetch(fileUrl)
        .then(response => response.blob())
        .then((fileBlob) => {
            saveAs(fileBlob, fileName);
        });
};
export const isCustomerProfile = async (profile) => {
    return profile !== null && profile.display.includeEmailAddress && profile.display.restrictDownloadReport;
};

export const downloadPdfReport = () => {
    return async (dispatch, getState) => {
        // capturing an image of line-chart used for display the "Estimated Rebuild Cost" details
        let chartImage = document.getElementById("line-chart") != null ?
            document.getElementById("line-chart").toDataURL("image/png") : null;
        dispatch(updateChartImage(chartImage));

        const state = getState();
        const  userCapturedDetail = state.userCapturedDetail;
        const custProfile = await isCustomerProfile(state.profile);
        const { data: recipient } = state.recipient;
        const buildingGrp = state.buildingGrp;
        const buildingList = toBuildingList(buildingGrp);
        let fullName = '';
        let emailAddress = '';
        if (custProfile) {
            if (userCapturedDetail !== null && (userCapturedDetail.fullName === null || userCapturedDetail.fullName === ''
            || userCapturedDetail.fullName === undefined)) {
                dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Full Name must not be empty' });
                return;
            }
            if (userCapturedDetail !== null && (userCapturedDetail.emailAddress === null || userCapturedDetail.emailAddress === ''
             || userCapturedDetail.emailAddress === undefined)) {
                 dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Email address must not be empty' });
                 return;
            }
            if (userCapturedDetail !== null && (userCapturedDetail.emailAddress !== null && userCapturedDetail.emailAddress !== ''
            && userCapturedDetail.emailAddress !== undefined &&
            !userCapturedDetail.emailAddress.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/))) {
                dispatch({ type: 'USER_CAPTURE_VALIDATION_ERROR', data: 'Email address is not valid' });
                return;
            }
            fullName = userCapturedDetail.fullName;
            emailAddress = userCapturedDetail.emailAddress;
        }
        dispatch({ type: 'ATTEMPT_PDF_REPORT' });
        if (UrlConstants.PROFILE_ID_WESTPAC.includes(state.profileId || state.partnerId)) {
            window.pageDetails.pageName = AnalyticsConstant.DOWNLOAD;
            siteExperience();
            AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
        }

        if (window.screen.width >= 320 && window.screen.width <= 480) {
            if (navigator.userAgent.match(/iPhone/i)) {
                state.profile.display.showInIframe = true;
            } else {
                state.profile.display.showInIframe = false;
            }
        }
        if (navigator.userAgent.match(/iPad/i)) {
            state.profile.display.showInIframe = true;
        }
        let answers = [];

        state.questions.data.forEach(ques => {
            if (ques.section && excludedSections.includes(ques.section.name.toLowerCase())) {
                //do nothing
            }
            else if (ques.section.name === 'Risk Details') {
                answers.push(toRiskAnswer(ques, state))
            }
            else {
                answers.push(toAnswer(ques));
            }
        });
        const currentDataPartition = state.currentDataPartition;
        return downloadReportApi({
            productId: state.productId,
            profileId: state.profileId,
            propertyId: state.addressChosen.propertyId,
            body: {
                recipient,
                estimateSectionHeader: state.profile.text.rebuildCostHeader,
                estimation: {
                    estimateId: state.result.data.estimateId,
                    variables: state.result.data.variables,
                    specialItems: state.result.data.specialItems,
                    declaredValue: state.result.data.declaredValue,
                    allowancesForCostInflation: state.result.data.allowancesForCostInflation,
                    breakupVariablesInM2: state.result.data.breakupVariablesInM2,
                    totalsBreakup: state.result.data.totalsBreakup,
                    indemnityValue: state.result.data.indemnityValue,
                    fullName, emailAddress,
                },
                riskDetailsResponse: {
                    riskId: state.riskId,
                    propertyId: state.riskDetail.propertyId,
                    postcode: state.riskDetail.postcode,
                },
                loggedInUserDetail: {
                    companyLogo: state.loggedInUserDetail.companyLogo,
                    divisionLogo: state.loggedInUserDetail.divisionLogo,
                    companyDisclaimer: state.loggedInUserDetail.companyDisclaimer,
                    divisionDisclaimer: state.loggedInUserDetail.divisionDisclaimer,
                },
                property: {
                    propertyId: state.addressChosen.propertyId,
                    address: state.addressChosen.suggestion,
                    state: state.addressChosen.state,
                },
                answers,
                buildingList,
                chartImage: state.chartImage,
            },
            currentDataPartition,
            brokerId: state.partnerId,
        })
            .then((response) => {
                if (response.ok) {
                    if((state.productId !== UrlConstants.PRODUCT_ID_10) && (state.productId !== UrlConstants.PRODUCT_ID_9)) {
                    if (currentDataPartition !== response.headers.get('currentDataPartition')) {
                        let obj7 = {
                            ProductId: state.productId,
                            ProfileId: state.profileId,
                            PropertyId: state.propertyId
                        }
                        const event = new AnalyticsError(obj7, "", "FAILURE",
                            "Failed to download rebuild cost report due to data refresh", {
                                'EstimateId': state.result.data.estimateId,
                                'Error': { error: 'Data refresh error' }
                            }, state.partnerId);
                        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DOWNLOAD_REBUILD_COST_REPORT, event));
                        throw new DataRefreshError();
                    }}
                    
                }
                return response;
            })
            .then((response) => handleJSONResponse(response))
            .then((responseJson) => {
                let obj2 = {
                    action: "DOWNLOAD",
                    estimateId: state.result.data.estimateId,
                    PartnerId: state.partnerId
                };
                const event = new AnalyticsReport(state.productId, state.profileId, state.propertyId, "", "SUCCESS", "Rebuild cost report successfully downloaded",
                    obj2);
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DOWNLOAD_REBUILD_COST_REPORT, event));
                if (state.profile.display.showInIframe) {
                    downloadPdf(responseJson.url, responseJson.fileName);
                } else {
                    window.open(responseJson.url);
                }
                dispatch({ type: 'SUCCESS_PDF_REPORT' });
                dispatch(emailReportIfCustomProfile(custProfile));
            }).catch((error) => {
                let obj6 = {
                    ProductId: state.productId,
                    ProfileId: state.profileId,
                    PropertyId: state.propertyId
                }
                const event = new AnalyticsError(obj6, "", "FAILURE",
                    "Failed to download rebuild cost report", {
                        'EstimateId': state.result.data.estimateId,
                        'Error': error
                    }, state.partnerId);
                dispatch(trackSegmentEvent(SegmentTrackingEventTypes.DOWNLOAD_REBUILD_COST_REPORT, event));

                if (error instanceof DataRefreshError) {
                    dispatch({ type: 'UPDATE_REFRESH_WARNING', data: true });
                    dispatch({ type: 'SUCCESS_PDF_REPORT' });
                } else {
                    dispatch({ type: 'SUCCESS_PDF_REPORT' });
                    dispatch(serverError(error));
                }
            });
    };
};

export const emailReportIfCustomProfile = (custProfile) => {
    return async (dispatch) => {
             if (custProfile) {
                return dispatch(emailReport(true));
            }
        }
};
export const searchAgain = () => {
    return (dispatch) => {
        const event = new AnalyticsEvent("Search Again", "New address search request", "Question Page", "Search Again");
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.CLICK_SEARCH_AGAIN, event));
        dispatch({ type: 'SEARCH_AGAIN' });
    };
};

export const displayRefreshChange = (data) => {
    return { type: 'UPDATE_REFRESH_WARNING', data: data };
};

export const getEmailTemplate = (pathName) => {
    return viewEmailApi(pathName);
};

export const validateProfileAccessControls = (mode) => {
    return { type: 'SET_VALIDATE_MODE', mode };
};

export const showResultSection = (showResult) => {
    return { type: 'SHOW_RESULT_SECTION', showResult };
};

export const trackCallToAction = (callToActionEvent) => {
    return (dispatch) => {
        if (callToActionEvent && callToActionEvent.target.tagName === 'A') {
            const event = new AnalyticsEvent("Call to Action", "Navigate away from Sumsure", "Questions Page", callToActionEvent.target.href);
            dispatch(trackSegmentEvent(SegmentTrackingEventTypes.CALL_TO_ACTION, event));
        }
    };
};

export const contactUsAction = (contactUsUrl, profileId, partnerId) => {
    return (dispatch) => {
        window.open(contactUsUrl, "_blank");
        let eventName = SegmentTrackingEventTypes.CONTACT_US;
        if (UrlConstants.PROFILE_ID_WESTPAC.includes(profileId || partnerId)) {
            eventName = SegmentTrackingEventTypes.GET_A_QUOTE;
            window.pageDetails.pageName = AnalyticsConstant.GO_TO_QUOTE;
            siteExperience();
            AnalyticsCustomScript('../WbcScriptSetPageDetail.js');
        }
        const event = new AnalyticsEvent(eventName, "Navigate to Contact Us URL", "Question Page", contactUsUrl);
        dispatch(trackSegmentEvent(eventName, event));
        dispatch({ type: 'SUCCEED_CONTACT_US', loaded: true });
    };
};

export const callToActionButton = (ctaButton1Url) => {
    return (dispatch) => {
        window.open(ctaButton1Url, "_blank");
        const event = new AnalyticsEvent("Click to Call To Action Us button 1", "Navigate to Call to action button URL", "Question Page", ctaButton1Url);
        dispatch(trackSegmentEvent(SegmentTrackingEventTypes.CALL_TO_ACTION_BUTTON, event));
        dispatch({ type: 'SUCCEED_CALL_TO_ACTION_BUTTON', loaded: true });
    };
};

export const siteExperience = () => {

    if (window.screen.width >= 320 && window.screen.width <= 480) {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_MOBILE;
    }

    else if (window.screen.width >= 482 && window.screen.width <= 1024) {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_TABLET;
    }

    else {
        window.pageDetails.siteExperience = AnalyticsConstant.SITE_EXPERIENCE_DESKTOP;
    }
    window.pageDetails.pageURL = window.location.href;
    window.pageDetails.siteDomain = window.location.hostname;
    window.pageDetails.siteEnv = window.location.hostname.includes(DomainConstants.AU_PRD) ? "prod" : "test";
    window.pageDetails.ordinal = new Date().getTime();
    window.pageDetails.eventKey = `${window.pageDetails.siteBrand}:${window.pageDetails.siteExperience}_${window.pageDetails.pageType}_${window.pageDetails.pageName}`;
};

export const onPolicyNumberPicked = (policyNumberPicked) => {
    return (dispatch) => {
        dispatch({ type: 'POLICY_NUMBER_PICKED', policyNumber: policyNumberPicked });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};


export const onInsuredChange = (insuredChange) => {
    return (dispatch) => {
        dispatch({ type: 'INSURED_PICKED', insured: insuredChange });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onUnderwriterChange = (underwriterChange) => {
    return (dispatch) => {
        dispatch({ type: 'UNDERWRITER_PICKED', underwriter: underwriterChange });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onAssignedToChange = (assignedToChange) => {
    return (dispatch) => {
        dispatch({ type: 'ASSIGNED_TO_PICKED', assignedTo: assignedToChange });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onLastValuationChange = (lastValuationChange) => {
    return (dispatch) => {
        var lastValuationDate = new Date(lastValuationChange.getTime() + Math.abs(lastValuationChange.getTimezoneOffset() * 60000));
        dispatch({ type: 'LAST_VALUATION_PICKED', lastValuation: lastValuationDate });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onBrowserWidthChange = (widthChange) => {
    return (dispatch) => {
        dispatch({ type: 'WIDTH_CHANGE', widthChange: widthChange });
        dispatch({ type: 'VALIDATION_ERROR', errors: [] });
    };
};

export const onRiskIdChange = () => {
    return (dispatch) => {
        dispatch({ type: 'RISKID_MESSAGE_SHOW', data: true });
        dispatch({ type: 'RISKID_MESSAGE_SHOW_ERROR', errors: [] });
    };
};

export const onMessageHide = () => {
    return (dispatch) => {
        dispatch({ type: 'RISKID_MESSAGE_HIDE' });
    };
};
export const changeEmailMessage = (message) => {
    return { type: 'CHANGE_EMAIL_MESSAGE', data: message };
};
export const changeEmailSubject = (subject) => {
    return { type: 'CHANGE_EMAIL_SUBJECT', data: subject };
};
export const sendNowEmail = (message) => {
    return { type: 'SEND_EMAIL_NOW' };
};
export const cancelEmail = (subject) => {
    return { type: 'CANCEL_EMAIL' };
};
export const onPropertyIdChange = () => {
    return (dispatch) => {
        dispatch({ type: 'PROPERTY_ID_MESSAGE_SHOW', data: true });
    };
};
export const onPostcodeChange = (productId, address, postCode) => {
    return (dispatch, getState) => {
        let errorMessage = null;
        dispatch({ type: 'SET_POSTCODE', postcode: postCode });
        validateAnswerApi(productId, 'POSTCODE', postCode)
            .then((response) => {
                if (response.status !== 200) {
                    errorMessage = "Please enter a valid postcode";
                }
                dispatch({ type: 'POSTCODE_CHANGED', address: address, propertyId: null, error: errorMessage });
            }).catch((error) => {
                errorMessage = "Please enter a valid postcode";
                dispatch({ type: 'POSTCODE_CHANGED', address: address, propertyId: null, error: errorMessage });
            });
    };
};
export const showPostcodeMessage = () => {
    return (dispatch) => {
        dispatch({ type: 'POSTCODE_MESSAGE_SHOW', data: true });
    };
};

export const updatePropertyError = (message) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_PROPERTY_ERROR', message });
    }
};

export const userFullNameChange = (fullName) => {
    return { type: 'CHANGE_USER_FULL_NAME', data: fullName };
};

export const userEmailAddressChange = (emailAddress) => {
    return (dispatch) => {
        return dispatch({ type: 'CHANGE_USER_EMAIL_ID', data: emailAddress });
    };
};

export const saveCookieConsentDetails = (productId, profileId, isCookieConsentBoxDismissed) => {
    return (dispatch) => {
        cookieConsentApi(productId, profileId, isCookieConsentBoxDismissed).then((response) => {
            if (response.status === 200) {
                return;
            }
        }).catch((error) => {
            dispatch(serverError(error));
        });
    }
};

// updating the chart-image in the redux-store only while downloadPdf and email report operations
export const updateChartImage = (chartImage) => {
    return (dispatch) => {
            dispatch({ type: 'UPDATE_CHART_IMAGE', payload: chartImage });
    };
};