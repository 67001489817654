import apiBaseUrl from '../apiBaseUrl';

const deleteRiskApi = (productId, profileId, id, riskIds) => {

    if (id === null) {
        const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/deleteMultipleRisks`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        return fetch(url,
            {
                method: 'POST',
                headers,
                body: JSON.stringify({ riskIds: riskIds }),
            }
        );
    } else {
        let riskId = parseInt(id);
        const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/deleteRiskDetailById/${riskId}`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        return fetch(url,
            {
                method: 'DELETE',
                headers,
            }
        );
    }
};

export default deleteRiskApi;