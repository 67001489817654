import apiBaseUrl from '../apiBaseUrl';
import configLocal from '../commercialConfig';

const tokenApi = (productId, profileId, code) => {
    const baseURL = `${configLocal.BASE_ORIGIN_URL}`;
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/token/${code}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache', 
        'Cache-control': 'no-store',
        'Pragma': 'no-cache',
        'Expires': 0,
    };
    headers.baseURL = baseURL;
    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );

};

export default tokenApi;