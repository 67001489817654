import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FilterGroup from '../components/FilterGroup';
import { searchUserGroupResults, setGroupSearchBy, setGroupSearchText, onClearGroupFilter, onAddGroup,
    updateGroupActivePageNo, getUserGroupDetail, sortUserGroups, userGroupDeleteDialog, deleteUserGroup, isAdminUserRole } from '../actions/searchActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        userGroupOption: state.userGroupOption,
        companyName: state.companyName,
        teamName: state.teamName,
        divisionName: state.divisionName,
        disclaimer: state.disclaimer,
        productId: state.productId,
        profileId: state.profileId,
        text: state.profile.text,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        images: state.profile.images,
        open: state.open,
        groupSearchParams: state.groupSearchParams,
        userGroupSearchResult: state.userGroupSearchResult,
        groupSortParams: state.groupSortParams,
        grpPagination: state.grpPagination,
        activeIndex: state.activeIndex,
        userGroupId: state.userGroupId,
        deleteGroup: state.deleteGroup,
        isAdminUser: state.isAdminUser,
        userGrpId: state.loggedInUserDetail.userGroupId,
        loggedInUserDetail: state.loggedInUserDetail,
        deleteError: state.deleteError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchUserGroupResults,
        setGroupSearchBy,
        setGroupSearchText,
        onClearGroupFilter,
        onAddGroup,
        updateGroupActivePageNo: updateGroupActivePageNo,
        getUserGroupDetail,
        sortUserGroups,
        userGroupDeleteDialog: userGroupDeleteDialog,
        deleteUserGroup: deleteUserGroup,
        isAdminUserRole,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...dispatchProps,
        ...stateProps,
        searchUserGroupResults: () => {
            dispatchProps.searchUserGroupResults(stateProps.productId, stateProps.profileId, stateProps.loggedInUserDetail.userGroupId,
                stateProps.groupSearchParams.searchBy, stateProps.groupSearchParams.searchText,
                stateProps.groupSortParams, stateProps.grpPagination.activePage);
        },
        setGroupSearchBy: dispatchProps.setGroupSearchBy,
        setGroupSearchText: dispatchProps.setGroupSearchText,
        onClearGroupFilter: dispatchProps.onClearGroupFilter,
                onAddGroup: dispatchProps.onAddGroup,
        updateGroupActivePageNo: dispatchProps.updateGroupActivePageNo,
        getUserGroupDetail: dispatchProps.getUserGroupDetail,
        sortUserGroups: dispatchProps.sortUserGroups,
        isAdminUserRole: dispatchProps.isAdminUserRole,
        userGroupDeleteDialog: (open, userGroupDetail) => dispatchProps.userGroupDeleteDialog(open, userGroupDetail),
        deleteUserGroup: (productId, profileId, userGroupId) => {
            dispatchProps.deleteUserGroup(productId, profileId, userGroupId);
        },
        onLoad: () => {
         const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Group List Page', ownProps.location.pathname, stateProps.sessionTrackId, "GroupListPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Group List Page", pageDetails);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FilterGroup);