import apiBaseUrl from '../apiBaseUrl';

const deleteUserGroupLogoApi = (productId, profileId, id) => {

    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/deleteUserGroupLogo/${id}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'DELETE',
            headers,
        }
    );
};

export default deleteUserGroupLogoApi;