import React from 'react';
import Disclaimer from './Disclaimer';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DisclaimerDialog = ({ open, onClose, state, styles, productId, onPrint, contentBefore, contentAfter }) => {

    return (<Dialog
            className="dialog terms-dialog"
            open={open}
            fullWidth={true}
            maxWidth={'md'}
            onClose={onClose}
            style={{right: '-50px',left: '-50px'}}
        >
            <DialogContent>
                <DialogContentText component={'div'} className="dialog-body-text">
                    <table style={{ width: '100%', color: '#000000' }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'left' }}>
                                    <h1 style={{ marginBottom: 0 }}>Disclaimer</h1>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span tabIndex={ 0 } style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                        onClick={() => {
                                            onPrint(productId);
                                            return false;
                                        }} onKeyPress={() => {
                                            onPrint(productId);
                                            return false;
                                        }}>Print</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Disclaimer state={state} contentBefore={contentBefore} contentAfter={contentAfter} styles={styles}
                        productId={productId} />
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="terms-dialog-agree">
            <div style={{ display: 'flex', justifyContent: 'center',padding:'0px' }}>
                <CustomFlatButtonContainer
                    label="Close"
                    type="primary"
                    className="terms"
                    onPress={onClose}
                    style={{
                        marginRight: '2rem',
                        minWidth: '12em',
                        fontFamily: styles.globalFontFamily,
                        borderRadius: styles.buttonRadius,
                    }}
                />
            </div>
        </div>
            </DialogActions>
        </Dialog>
    );
};

DisclaimerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    state: PropTypes.string,
    contentBefore: PropTypes.string,
    contentAfter: PropTypes.string,
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    onPrint: PropTypes.func.isRequired,
};

export default DisclaimerDialog;