import React from 'react';
import browser from 'detect-browser';
import BrowserWarning from './BrowserWarning';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';
import { fetchPropertyAddress } from '../api/fetchPropertyAddress';
import CCENavigation from './CCENavigation';
import CCEAdminNavigation from './CCEAdminNavigation';
import CCEHeader from './CCEHeader';
import FooterContainer from '../containers/FooterContainer';
import history from '../util/history';
import CCELogOutDialog from './CCELogOutDialog';
import ReturnToEstimateDialog from './ReturnToEstimateDialog';
import LoginPageContainer from '../containers/LoginPageContainer';
import configLocal from '../commercialConfig';
import 'url-search-params-polyfill';
import HeadMetaData from '../util/HeadMetaData';

class CCEHomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            property: null,
            searchText: '',
            value: '',
            results: [],
            acceptPropertyId: false,
            propertyNotFound: false,
            tabValue: 0,
            imageDescriptionDetail: [],
            widthChange: window.innerWidth,
            open: false,
            adminTabValue: 0,
            isFilterEnabled: null,
            isFilterUserEnabled: null,
            isEditUserEnabled: null,
            toggleMenu: false,
            postcode: null,
            redirectPageName: '',
        };
    }
    state = { logout: false };

    handleLogout = () => {
        const { answerUpdated } = this.props;
        this.openReturnToEstimateDialogOnMenu();
        this.setState({ redirectPageName: 'LOGOUT' });
        if (!answerUpdated) {
            this.setState({ toggleMenu: false, logout: true });
        }
        this.onLogoutClick();
    };

    onLogoutClick = () => {
        const { location }= this.props;
        const queryParams = new URLSearchParams(location)
        if (queryParams.has('logout')) {
            queryParams.delete('logout')
            history.replace({
                search: queryParams.toString(),
            });
        }
        return false;
    };

    handleNewEstimate = () => {
        const { answerUpdated } = this.props;
        this.openReturnToEstimateDialogOnMenu();
        this.setState({ redirectPageName: UrlConstants.ESTIMATE });
        if (!answerUpdated) {
            this.onNewEstimate();
        }
    };

    onNewEstimate = () => {
        const { requestUrl, addressChosen } = this.props;
        if (requestUrl.includes(UrlConstants.ESTIMATE)) {
            if (addressChosen !== null && addressChosen.propertyId !== null) {
                const { isIncompleteEstimate, result, saveInCompleteEstimate,
                    updateRiskDetail, isEditRisk } = this.props;
                if (isEditRisk) {
                    updateRiskDetail();
                } else if (isIncompleteEstimate && (result.data === null ||
                    result.data.variables.length === 0)) {
                    saveInCompleteEstimate();
                }
            }
            this.resetFieldsForNewEstimate();
        }
    };

    handleToggleMenu = () => {
        this.setState({ toggleMenu: !this.state.toggleMenu  });
    };

    openReturnToEstimateDialogOnMenu = () => {
        const { answerUpdated, isEditEstimate, isEditRisk, showReturnToEstimate } = this.props;
        if ((answerUpdated && isEditEstimate) || (!isEditEstimate && !isEditRisk && answerUpdated)) {
            showReturnToEstimate();
        }
    };

    handleManageNavigation = () => {
        this.openReturnToEstimateDialogOnMenu();
        this.setState({ redirectPageName: UrlConstants.MANAGE });
        const { cancelAnswerUpdate, answerUpdated } = this.props;
        if (!answerUpdated) {
            cancelAnswerUpdate(UrlConstants.MANAGE);
        }
    };

    handleAdminNavigation = () => {
        this.openReturnToEstimateDialogOnMenu();
        this.setState({ redirectPageName: UrlConstants.ADMIN });
        const { cancelAnswerUpdate, answerUpdated } = this.props;
        if (!answerUpdated) {
            cancelAnswerUpdate(UrlConstants.ADMIN);
        }
        this.setState({ toggleMenu: false, open: false });
    };

    onLogoutCancel = () => {
        this.setState({ logout: false });
    };

    onLogoutConfirm = () => {
        this.setState({ logout: false });
        const { onResetTokenDetails, productId, profileId, onLogout, restoreURL, homePath } = this.props;
        restoreURL();
        window.localStorage.setItem('productId', productId);
        window.localStorage.setItem('profileId', profileId);
        onLogout("Logged out successfully.", productId, profileId);
        onResetTokenDetails();
        setTimeout(function () {
            window.location.replace(window.location.origin + '/#' + homePath);
        }, 2000);
        this.onClose();
    };
    onClose() {
        var win = window.open(`${configLocal.CLAUD_LOGOUT_URL}`);
        setTimeout(function () {
            win.close();
        }, 900);
    }

    componentWillMount() {
        const { productId, profileId, isEditEstimate, isEmailDownload, isEditUserGroup, loggedInUserDetail } = this.props;

        HeadMetaData(this.props.productId, this.props.profileId, this.props.partner);
        if (productId === UrlConstants.PRODUCT_ID_7 || productId === UrlConstants.PRODUCT_ID_8 || profileId === UrlConstants.PROFILE_ID_411) {
            let url = `/products/${productId}/profiles/${profileId}`;
            history.push(url);
        } else {
            window.addEventListener('resize', this.handleWindowSizeChange);
            document.addEventListener('click', this.handleMenuClose, true);
            const { validateProfile, setValidateProfile, debug, setDebug, getNoImageFile, getUserList } = this.props;
            validateProfile();
            setValidateProfile();
            this.setDebugAndState({debug, setDebug, loggedInUserDetail}, getUserList, productId, profileId, isEditEstimate, isEmailDownload, isEditUserGroup);
            const {requestUrl, updateAssignedtoForNewRisk, isEditRisk, fetchApigeeToken, claudtokenToken} = this.props;
           if(requestUrl.includes(UrlConstants.ESTIMATE) && !isEditRisk &&  loggedInUserDetail != null
           && loggedInUserDetail.id != null && !isEmailDownload && !isEditEstimate){
                getNoImageFile(productId, profileId);
                updateAssignedtoForNewRisk(loggedInUserDetail.id);
            }
            fetchApigeeToken(productId, profileId, claudtokenToken);
        }
    }

    setDebugAndState({debug, setDebug, loggedInUserDetail}, getUserList, productId, profileId, isEditEstimate, isEmailDownload, isEditUserGroup) {
        if (debug === true) {
            setDebug();
        }
        if (loggedInUserDetail != null && loggedInUserDetail.id != null) {
            getUserList(productId, profileId, loggedInUserDetail.id);
        }
        if (isEditEstimate !== undefined && isEditEstimate === true) {
            this.setState({
                tabValue: 1,
            });
        }
        if (isEmailDownload) {
            this.setState({ tabValue: 6 });
        }
        if (isEditUserGroup) {
            this.setState({ adminTabValue: 1 });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { logoutFlag } = this.props;
        if (logoutFlag !== prevProps.logoutFlag && logoutFlag) {
            this.handleLogout();
        }
        const { acceptPropertyId, propertyId, productId, addressChosen } = this.props;
        this.setPropertyState(acceptPropertyId, prevProps, propertyId, productId, addressChosen);
        const { profileId, partner, validateProfile, setValidateProfile } = this.props;
        if (productId !== prevProps.productId || profileId !== prevProps.profileId || partner !== prevProps.partner) {
            validateProfile();
            setValidateProfile();
        }

        const { segmentTrackingCode, onLoad } = this.props;
        if (segmentTrackingCode && segmentTrackingCode !== prevProps.segmentTrackingCode) {
            onLoad(segmentTrackingCode);
        }

        const { debug, setDebug } = this.props;
        if (debug !== prevProps.debug) {
            setDebug();
        }
        const { tokenContents, getUserDetailByUserGuid, loggedInUserDetail, claudtokenToken } = this.props;
        if ((tokenContents.sub !== prevProps.tokenContents.sub
            || (loggedInUserDetail.userName === null && tokenContents.sub !== null
            )) && claudtokenToken) {
            getUserDetailByUserGuid(productId, profileId, tokenContents.sub);
        }
        const { isEditRisk, isEditEstimate, estimateID} = this.props;
        if (!isEditRisk && !isEditEstimate && estimateID === null) {
            this.handleProperty();
        }
        const { statePostCode, updatePostcodeQuestions } = this.props;
        if (statePostCode !== prevProps.statePostCode) {
            updatePostcodeQuestions();
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    fetchAddress = async (productId, propertyId) => {
        const response = await fetchPropertyAddress(productId, propertyId);
        const property = await response.json();
        this.handlePostcode(property.postcode);
        if (property.isApplicable) {
            if (property.suggestion) {
                this.setState({
                    searchText: property.suggestion,
                    value: property.suggestion,
                    results: property.suggestion,
                    property: property,
                    propertyNotFound: false,
                });
            }
        } else if (!this.state.propertyNotFound) {
            this.setState({
                searchText: "",
                value: "",
                results: [],
                property: null,
                propertyNotFound: true,
            });
        }
    };

    handleMenuClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleMenuClose = event => {
        const { messageHide } = this.props;
        this.setState({ open: false });
        messageHide();
        if(!this.state.logout) {
          this.setState({ toggleMenu: false });
        }
    }

    handleChange = (value) => {
        this.setState({ tabValue: value });
    };

    handleProperty = () => {
        const { addressChosen, isEditRisk, updatePropertyError } = this.props;
        if(!isEditRisk && isEmpty(this.state.value) && !isEmpty(addressChosen)) {
            updatePropertyError('Please enter property address');
        }
        else{
            updatePropertyError(null);
        }
    }

    handleNextChange = (value) => {
        this.setState({ tabValue: this.state.tabValue + 1 })
    }


    handleAdminChange = (value) => {
        this.setState({ adminTabValue: value });
    };

    handleProceed = (property) => {
        const { onAddressPicked } = this.props;
        onAddressPicked(property);
    };

    onChange = (value) => {
        if (this.props.productId === UrlConstants.PRODUCT_ID_10) {
            this.setState({ searchText: value, value: value });
        } else {
            this.setState({ searchText: value.replace(/,/g, ''), value: value.replace(/,/g, '') });
        }
        this.handleProperty();
    };

    handlePostcode = (postcode) => {
        const {onChangePostcode} = this.props;
        onChangePostcode(postcode);
    }

    onNewRequest = (property) => {
        if (property.isApplicable === false) {
            this.handleProceed({
                propertyId: null,
                suggestion: this.state.searchText,
            });
        }
        else {
            this.handleProceed(property);
        }
    };

    changeImageDescription = (imageId, imagedescription) => {
        this.setState({
            imageDescriptionDetail: this.state.imageDescriptionDetail.concat([{ id: imageId, description: imagedescription }]),
        });
    };

    onTabChange = () => {
        const { onTabChange } = this.props
        onTabChange();
    }

    onTabUserChange = () => {
        const { onTabUserChange } = this.props
        onTabUserChange();
    }

    onTabImportRisksChange = () => {
        const { onTabImportRisksChange } = this.props
        onTabImportRisksChange();
    }

    onTabReportsChange = () => {
        const { onTabReportsChange } = this.props
        onTabReportsChange();
    }


    setPropertyState(acceptPropertyId, prevProps, propertyId, productId, addressChosen) {
        if (acceptPropertyId && ((acceptPropertyId !== prevProps.acceptPropertyId && propertyId) || propertyId !== prevProps.propertyId)) {
            this.fetchAddress(productId, propertyId);
        }
        if (addressChosen !== null && addressChosen.propertyId !== null && addressChosen.propertyId !== prevProps.addressChosen.propertyId) {
            const property = (addressChosen.propertyId !== null && addressChosen.propertyId !== undefined) ? this.fetchAddress(productId, addressChosen.propertyId) : "";
            this.setState({ postcode: property.postcode });

        }
    }

    resetFieldsForNewEstimate() {
        this.setState({ tabValue: 0 });
        const { resetAddEditEstimate, resetRiskDetails } = this.props;
        resetAddEditEstimate();
        resetRiskDetails();
        this.setState({
            searchText: "",
            value: "",
            results: [],
            property: null,
            propertyNotFound: false,
        });
    }

    handleReturnToEstimate = () => {
        const { closeReturnToEstimateDialog } = this.props;
        closeReturnToEstimateDialog();
    }

    handleCancelAnswerUpdate = () => {
        const { cancelAnswerUpdate } = this.props;
        cancelAnswerUpdate(this.state.redirectPageName);
        if (this.state.redirectPageName === 'LOGOUT') {
            this.setState({ toggleMenu: false, logout: true });
            this.onLogoutClick();
        } else if (this.state.redirectPageName === UrlConstants.ESTIMATE) {
            this.onNewEstimate();
        } else {
            this.setState({ toggleMenu: false, open: false });
        }
    }

    render() {
        const { images, styles, text, acceptPropertyId, propertyId, productId, logoLeftUrl, debug, logoRightUrl, suggestions,
            profileId, addressChosen, questions, result, updateImagesDescription, isEditRisk, updateRiskDetail, cancelEdit, isEditEstimate,
            isIncompleteEstimate, saveInCompleteEstimate, isEmailDownload, requestUrl, isAddGroupEnabled, isFilterEnabled, isFilterUserEnabled, isAddUserEnabled, isUserGroupOptionSystem,
            isUsageReport, isEditUserEnabled, loggedInUserDetail, statePostCode, postcodeErrorMsg, errorPropertyMsg, answerUpdated, estimatePrice, returnToEstimateDialog } = this.props;
        const autofillAddress = acceptPropertyId && propertyId && !this.state.propertyNotFound;
        const { logout } = this.state;
        let cceHomepageStyle = { fontFamily: styles.globalFontFamily, top: 0, bottom: 0, webkitBackgroundSize: 'cover' }
        if (styles.homePageBackgroundColour) {
            cceHomepageStyle.backgroundColor = styles.homePageBackgroundColour;
        }
        const {
            claudtokenToken, isSessionLoaded
        } = this.props;

        return (
            <div>
                {(!isSessionLoaded || !claudtokenToken) ?
                    <LoginPageContainer location={this.props.location} match={this.props.match} productId={productId} profileId={profileId} /> :
                    <div className="homepage" style={cceHomepageStyle}>
                        <CCEHeader className="cceheader"
                            debug={debug}
                            images={images}
                            styles={styles} text={text}
                            logoRightUrl={logoRightUrl}
                            logoLeftUrl={logoLeftUrl}
                            productId={productId}
                            profileId={profileId}
                            widthChange={this.state.widthChange}
                            handleMenuClick={this.handleMenuClick}
                            handleMenuClose={this.handleMenuClose}
                            open={this.state.open}
                            handleLogout={this.handleLogout}
                            pageName={getPageName(requestUrl)}
                            loggedInUserName={this.getLoggedInUserNAme(loggedInUserDetail)}
                            loggedInEmail={this.getLoggedInEmail(loggedInUserDetail)}
                            toggleMenu ={this.state.toggleMenu}
                            handleToggleMenu ={this.handleToggleMenu}
                            newEstimate={this.getNewEstimate(requestUrl)}
                            handleNewEstimate = {this.handleNewEstimate}
                            handleManageNavigation = {this.handleManageNavigation}
                            handleAdminNavigation = {this.handleAdminNavigation}
                            companyLogo = {this.getComapnyLogo(loggedInUserDetail)}
                            divisionLogo = {this.getDivisionLogo(loggedInUserDetail)}
                        />


                        <BrowserWarning name={this.getName()} version={this.getVersion()} />

                        {requestUrl.includes(UrlConstants.ESTIMATE) && <CCENavigation styles={styles}
                            productId={productId}
                            profileId={profileId}
                            autofillAddress={autofillAddress}
                            tabValue={this.state.tabValue}
                            searchText={this.state.searchText}
                            value={this.state.value}
                            results={this.state.results}
                            suggestions={suggestions}
                            onNewRequest={this.onNewRequest}
                            handleChange={this.handleChange}
                            handleProperty={this.handleProperty}
                            handleNextChange={this.handleNextChange}
                            onChange={this.onChange}
                            addressChosen={addressChosen}
                            questions={questions}
                            result={result}
                            changeImageDescription={this.changeImageDescription}
                            imageDescriptionDetail={this.state.imageDescriptionDetail}
                            updateImagesDescription={updateImagesDescription}
                            widthChange={this.state.widthChange}
                            riskDetailSaveApi={this.riskDetailSaveApi}
                            isEditRisk={isEditRisk}
                            updateRiskDetail={updateRiskDetail}
                            cancelEdit={cancelEdit}
                            isEditEstimate={isEditEstimate}
                            isIncompleteEstimate={isIncompleteEstimate}
                            saveInCompleteEstimate={saveInCompleteEstimate}
                            isEmailDownload={isEmailDownload}
                            postcode={this.state.postcode}
                            errors = {this.props.errors}
                            errorPropertyMsg={errorPropertyMsg}
                            depdencyErrors = {this.props.depdencyErrors}
                            statePostCode={statePostCode}
                            postcodeErrorMsg={postcodeErrorMsg}
                            answerUpdated={answerUpdated}
                            estimatePrice={estimatePrice}
                            location={this.props.location}
                        />}
                        {requestUrl.includes(UrlConstants.ADMIN) && <CCEAdminNavigation
                            styles={styles}
                            productId={productId}
                            profileId={profileId}
                            adminTabValue={this.state.adminTabValue}
                            handleAdminChange={this.handleAdminChange}
                            widthChange={this.state.widthChange}
                            isAddGroupEnabled={isAddGroupEnabled}
                            isUserGroupOptionSystem={isUserGroupOptionSystem}
                            isUsageReport={isUsageReport}
                            isFilterEnabled={isFilterEnabled}
                            onTabChange={this.onTabChange}
                            isFilterUserEnabled={isFilterUserEnabled}
                            isAddUserEnabled={isAddUserEnabled}
                            onTabUserChange={this.onTabUserChange}
                            onTabImportRisksChange={this.onTabImportRisksChange}
                            onTabReportsChange={this.onTabReportsChange}
                            isEditUserEnabled={isEditUserEnabled}
                            location={this.props.location}

                        />}
                        <ReturnToEstimateDialog
                            styles={styles}
                            editEstimateDialog={isEditEstimate && returnToEstimateDialog}
                            handleReturnToEstimate={this.handleReturnToEstimate}
                            handleCancelAnswerUpdate={this.handleCancelAnswerUpdate}
                            newEstimateDialog={!isEditEstimate && !isEditRisk && returnToEstimateDialog}
                            estimateID={this.props.estimateID}
                        />
                        <CCELogOutDialog
                            dialogOpen={logout}
                            styles={styles}
                            onLogoutCancel={this.onLogoutCancel}
                            onLogoutConfirm={this.onLogoutConfirm}
                        />
                        <FooterContainer />
                    </div>}
            </div>
        );
    }

    getName() {
        return browser ? browser.name : 'phantomjs';
    }

    getVersion() {
        return browser ? browser.version : '0.0';
    }

    getDivisionLogo(loggedInUserDetail) {
        return loggedInUserDetail != null && loggedInUserDetail.divisionLogo != null ? loggedInUserDetail.divisionLogo : null;
    }

    getComapnyLogo(loggedInUserDetail) {
        return loggedInUserDetail != null && loggedInUserDetail.companyLogo != null ? loggedInUserDetail.companyLogo : null;
    }

    getNewEstimate(requestUrl) {
        return requestUrl.includes(UrlConstants.ESTIMATE) ? true : false;
    }

    getLoggedInEmail(loggedInUserDetail) {
        return (loggedInUserDetail != null
            && loggedInUserDetail.emailId != null) ?
            loggedInUserDetail.emailId : "";
    }

    getLoggedInUserNAme(loggedInUserDetail) {
        return (loggedInUserDetail != null
            && loggedInUserDetail.fullName != null) ?
            loggedInUserDetail.fullName.toUpperCase() : "";
    }
}

CCEHomePage.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    debug: PropTypes.bool.isRequired,
    suggestions: PropTypes.object.isRequired,
    images: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    text: PropTypes.object,
    onAddressPicked: PropTypes.func.isRequired,
    validateProfile: PropTypes.func.isRequired,
    setDebug: PropTypes.func.isRequired,
    setValidateProfile: PropTypes.func.isRequired,
    onLoad: PropTypes.func.isRequired,
    logoLeftUrl: PropTypes.string,
    logoRightUrl: PropTypes.string,
};

export default CCEHomePage;

function getPageName(requestUrl) {
    return requestUrl.includes(UrlConstants.ESTIMATE) ? "estimate" : "admin";
}
