import apiBaseUrl from '../apiBaseUrl';

const addUserGroupApi = ({productId, profileId, option, teamName, companyName, divisionName}, isActive, footerText, parentUserGroupId, isEditUserGroup, userGroupDetail, 
    file) => {
        var formData = new FormData();
        let groupSharePolicy = userGroupDetail.groupSharePolicy;
        if (option === "Division" && (groupSharePolicy === null || groupSharePolicy === undefined)) {
            groupSharePolicy = 0;
        }

        formData.append("logo", file);
        formData.append('groupRequest', new Blob([JSON.stringify({
            option: option,
            teamName: teamName,
            companyName: companyName,
            divisionName: divisionName,
            isActive: isActive,
            footerText: footerText,
            parentUserGroupId: parentUserGroupId,
            teamId: userGroupDetail.teamId,
            companyId: userGroupDetail.companyId,
            divisionId: userGroupDetail.divisionId,
            groupSharePolicy: groupSharePolicy,
        })], {
                type: "application/json"
            }));
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/addUserGroupRecord`;
    const headers = { 
        'Accept':'application/json',
         'cache': 'false'
    };
    headers.isEditUserGroup = isEditUserGroup;
    return fetch(url,
        {
            method: 'POST', 
            body: formData,
            headers,
        }
    );

};

export default addUserGroupApi;