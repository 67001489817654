import apiBaseUrl from '../apiBaseUrl';

export const validateAnswerApi = (productId, attributeName, answer) => {
    const headers = {
        'Accept': 'text/plain'
    };
    return fetch(`${apiBaseUrl()}products/${productId}/validateAnswer?attribute=${attributeName}&value=${answer}`,
        {
            method: 'GET',
            headers
        });
};