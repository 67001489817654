import React from 'react';
import InputFieldContainer from '../containers/InputFieldContainer';
import PropTypes from 'prop-types';
import { validateTextQuestion } from "../util/QuestionValidator";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import UrlConstants from '../constants/UrlConstants';

class Question extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        const prevAnswer = this.props.question.answer;
        if (!prevAnswer && prevAnswer !== nextProps.question.answer) {
            const error = validateTextQuestion(this.props.question, nextProps.question.answer)
            if (error) {
                this.props.updateErrors(true, error, this.props.question.id);
            } else {
                this.props.updateErrors(false, '', this.props.question.id);
            }
        }
    }

    render() {
        const isIAG = UrlConstants.PROFILE_ID_IAG_AU.includes(this.props.profileId);
        if (this.props.question.htmlText) {
            return this.renderHtmlQuestion();
        } else {
            if (isIAG > 0 && (this.props.question.inputType === "radio" || this.props.question.inputType === "radioimage")) {
                return this.renderRadioInputQuestion();
            }
            else {
                return this.renderInputQuestion();
            }
        }
    }

    renderInputQuestion() {
        const { question, dependant, styles, onHelpInformationDialogOpen, index, sectionName , postcode, productId} = this.props;
        const { questionLabelFontColour, questionLabelFontSize, questionHelpFontSize, questionHelpFontColour } = styles;
        return (
            <div className="question">
                <label htmlFor={question.id.toString()} id={`question-${question.id}`}
                    className={'questionLayout ' + (question.mandatory ? 'required' : 
                    this.newMethod1(question, productId))}
                    style={{
                        fontWeight: dependant ? this.newMethod2(question, productId): 'bold',
                        color: questionLabelFontColour,
                        fontSize: questionLabelFontSize,
                    }}>
                    {question.text}
                </label>
                <div id={"more-info-" + question.id.toString()}>
                    <span tabIndex={0} onClick={() => onHelpInformationDialogOpen(true, question)} className="helpLink"
                        style={{
                            display: question.helpEnabled ? "block" : 'none',
                            color: styles.helpLinkFontColor,
                            fontSize: styles.helpLinkFontSize,
                            width: 'fit-content',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter')
                                onHelpInformationDialogOpen(true, question);
                        }}
                    >More information ></span>
                </div>
                <div className="help-header" id={"help-text-" + question.id.toString()}
                    style={{
                        color: questionHelpFontColour,
                        fontSize: questionHelpFontSize,
                    }}>
                    {question.helpHeader}
                </div>
                <InputFieldContainer question={question} styles={styles} index={index} sectionName={sectionName} postcode={postcode}/>
            </div>
        );
    }

    newMethod2(question, productId) {
        return (question.id === 303545 || question.id === 303546)
            && (productId.includes(UrlConstants.PRODUCT_ID_9) > 0 || productId.includes(UrlConstants.PRODUCT_ID_10) > 0) ?
            'bold' : 'normal';
    }

    newMethod1(question, productId) {
        return (question.id === 303545 || question.id === 303546)
            && (productId.includes(UrlConstants.PRODUCT_ID_9) > 0 || productId.includes(UrlConstants.PRODUCT_ID_10) > 0) ?
            'required' : '';
    }

    renderHtmlQuestion() {
        const { question, styles, onHelpInformationDialogOpen, text } = this.props;
        const { questionLabelFontColour, questionLabelFontSize } = styles;
        const { customHtmlText } = text;
        let htmlText;

        if (question.htmlText.toLowerCase() === "customtext") {
            htmlText = <div id={`question-${question.id}`}
                className='questionLayout'
                dangerouslySetInnerHTML={{ __html: customHtmlText }} />
        } else {
            htmlText = question.helpEnabled === false ? <div id={`question-${question.id}`}
                className='questionLayout'
                dangerouslySetInnerHTML={{ __html: question.htmlText.replace(/href='www./g, "href='https://www.") }} />
                :
                <span tabIndex={0} onClick={() => onHelpInformationDialogOpen(true, question)} className="helpTextLink"
                    style={{
                        display: question.helpEnabled ? "block" : 'none',
                        color: questionLabelFontColour,
                        fontSize: questionLabelFontSize,
                        width: 'fit-content',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }}
                    onKeyPress={event => {
                        if (event.key === 'Enter')
                            onHelpInformationDialogOpen(true, question);
                    }}
                >{question.htmlText}</span>;
        }



        return (
            <div className="question">
                {htmlText}
            </div>

        );
    }


    renderRadioInputQuestion() {
        const { question, dependant, styles, onHelpInformationDialogOpen, index, sectionName } = this.props;
        const { questionLabelFontColour, questionLabelFontSize, questionHelpFontSize, questionHelpFontColour } = styles;
        return (
            <div className="question">
                <FormControl component="fieldset" role="group" aria-labelledby={"help-text-" + question.id.toString()} style={{ width: '100%' }}>
                    <FormLabel style={{
                        fontWeight: dependant ? 'normal' : 'bold',
                        color: questionLabelFontColour,
                        fontSize: questionLabelFontSize,
                    }} component="legend">{question.text}</FormLabel>
                    <div id={"more-info-" + question.id.toString()} >
                        <span tabIndex={0} onClick={() => onHelpInformationDialogOpen(true, question)} className="helpLink"
                            style={{
                                display: question.helpEnabled ? "block" : 'none',
                                color: styles.helpLinkFontColor,
                                fontSize: styles.helpLinkFontSize,
                                width: 'fit-content',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                            onKeyPress={event => {
                                if (event.key === 'Enter')
                                    onHelpInformationDialogOpen(true, question);
                            }}
                        >More information ></span>
                    </div>
                    <div className="help-header" id={"help-text-" + question.id.toString()}
                        style={{
                            color: questionHelpFontColour,
                            fontSize: questionHelpFontSize,
                        }}>
                        {question.helpHeader}
                    </div>
                    <InputFieldContainer question={question} styles={styles} index={index} sectionName={sectionName} />
                </FormControl>
            </div>
        );
    }
}

Question.propTypes = {
    question: PropTypes.shape({
        helpEnabled: PropTypes.bool,
        htmlText: PropTypes.string,
    }).isRequired,
    dependant: PropTypes.bool,
    styles: PropTypes.object,
};

export default Question;