import apiBaseUrl from '../apiBaseUrl';

const getCCEEstimateDetailsByEstimateApi = (estimateId, productId, profileId, estimatePrice, isEditEstimate) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getEstimateDetailByEstimateId/${estimateId}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    headers.isIncompleteEstimate = estimatePrice === "In Progress" ? true : false;
    headers.isEditEstimate = isEditEstimate;
    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getCCEEstimateDetailsByEstimateApi;