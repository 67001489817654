
import React from 'react';
import {Card} from 'material-ui';
import SectionHeader from './SectionHeader';
import ErrorMessage from './ErrorMessage';

const ContentInsurance = ({styles, result,
    productId, profileId, propertyId, addressChosen, onLetUsKnowDialogOpen}) => {
    const error = "Content Insurance service is not available";
    const contentError = result.total === null ? error : null;
    let errorMessage = null;
    if(contentError)
    {
        errorMessage = 
        <ErrorMessage productId={productId} profileId={profileId} propertyId={propertyId} address={addressChosen}
            onLetUsKnowDialogOpen={onLetUsKnowDialogOpen} styles={styles} message={contentError}/>
    }    
    return (
        <Card className="card" containerStyle={{fontFamily: styles.globalFontFamily}}>
            <SectionHeader name="Contents Estimate" color={styles.sectionTileColour} styles={styles}/>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1em 2em',
            }}>
            <div className='error' style={{marginTop: '0em', textAlign:'left'}}>{errorMessage}</div>
                Contents Estimate Total (ex GST): {result ? result.total : ""}
            </div>
        </Card>
    )
};

export default ContentInsurance;