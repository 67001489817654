import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import ConfirmationMessage from './ConfirmationMessage';

const CalculateActionButton = ({title, onPress, styles, attributesCheck, showResult, errors, scrollToFirstError}) => {
    let button = '';

    const handlePress = () => {
        onPress();
        setTimeout(function(){ scrollToFirstError(); }, 100);
    };

    if (attributesCheck && !showResult) {
       button = <ConfirmationMessage styles={styles} showResultSection={onPress}/>;
    }
    else {
       button = <CustomFlatButtonContainer
           label={title}
           onPress={handlePress}
           type="primary"
           keyboardFocus={false}
           style={{
               width: '24rem',
               fontFamily: styles.globalFontFamily,
               borderRadius: styles.buttonRadius || "",
               border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
           }}
       />;
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1em 2em',
        }}>
            {button}
        </div>
    );
};

CalculateActionButton.propTypes = {
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    styles: PropTypes.object,
    attributesCheck: PropTypes.bool.isRequired,
    showResult: PropTypes.bool.isRequired,
};

export default CalculateActionButton;