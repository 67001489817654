import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'material-ui/Card';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { filter } from 'lodash';
import EstimatePreview from './EstimatePreview';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';


const RiskPreview = ({ productId, profileId, fontFamilyName, searchResult, handleEstimatePreview, handleEditRisk, activeIndex, handleRiskDetailDeleteDialog,
    handleEstimatePreviewDialog, userList, handleRepriceEstimate, handleEmailDownloadReport, handleEstimateDetailDeleteDialog,
    editEstimate, isMobile, handleActiveEstimate, activeEstimateIndex, handlePopUpEstimate, activePopUpIndex, handleSelectRisk,
     activeSelectIndex, expanded, handleEstimatePreviewDesktop, popupExpanded, activeDetailButton, handleActivateDetailButton, handleCheckBoxChange,
      risks, excludedRisks, selectAll }) => {
    const formatDate = (date) => {
        if (date !== undefined && date !== "") {
            var myDate = new Date(date);
            var month = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ][myDate.getMonth()];
            var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
            return str.indexOf("undefined") >=0 ? newFunction12(date) : str;
        }
        return date;
    };


    return (


        searchResult.data.map((riskDetail, index) => {
            let assignedTo = riskDetail.assignedTo;
            let  isTrue = false;
            assignedTo = getAssignedToWhenUserListIsNotNull(userList, riskDetail, assignedTo);
            return <div> <div className='risk-preview' style={{ cursor: 'pointer' }}>
                <Card className={newFunction1(activeIndex, index, expanded, activeSelectIndex, searchResult, riskDetail, isMobile)} >
                    <div className='risk'  onClick={() => handleSelectRisk(index)}>                                 
                    {!isMobile && <Table aria-label="Risk Listing" style={{ fontFamily: fontFamilyName }} className="risk-tbl-preview">
                            <TableBody key={riskDetail.riskId} >
                                <TableRow>
                                    <TableCell className="risk-tbl-cell" rowSpan="3" style={{ width: '3%' }} >
                                   <Checkbox id ={riskDetail.riskId} color="default" 
                                    inputProps={{ 'aria-label': 'checkbox to select risk' }} 
                                   onClick ={
                                        (event) => {
                                            isTrue = !event.target.checked; 
                                            handleCheckBoxChange(riskDetail.riskId, isTrue) 
                                        }
                                    }
                                    checked ={  newFunction3(risks, riskDetail, selectAll, excludedRisks) }                                    
                                    /> 
                                    
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell" rowSpan="3" style={{ width: '10%' }} >
                                        <img style={{ display: 'block', width: '113px'}} alt={riskDetail.imageUrl} src={riskDetail.imageUrl} />
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell risk-blk-bold risk-tbl-cell-addr" colSpan="2" style={{ width:'270px'}}>
                                        {riskDetail.address}
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{width : '8%'}}> <div>INSURED: </div></TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold">
                                        <div id='insured'> {riskDetail.insured} </div></TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{width : '9%'}}> DATE UPDATED:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold"><div id='updatedDate'>{newFunction4(riskDetail, formatDate)} </div></TableCell>
                                    <TableCell className="risk-tbl-cell" style={{width : '6.8%'}}>
                                        <span className="risk-tbl-btn" style={{display: 'contents'}} onClick={() => handleEditRisk(riskDetail, riskDetail.riskId)}>Edit Risk Details</span>
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell" style={{width : '8.1%'}}>
                                        <Button onClick={() => handleRiskDetailDeleteDialog(true, riskDetail)} className="delete-risk" style={{ padding: '0.2rem 0.15rem 0rem 0.15rem' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{width : '10%'}}>RISK ID:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold">
                                        <div id='riskID'>{riskDetail.riskId} </div></TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head">UNDERWRITER:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold">{riskDetail.underwriter} </TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head">DATE CREATED:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">
                                        <div id='createdDate'>{newFunction5(riskDetail, formatDate)} </div></TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head">
                                        POLICY NUMBER:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" style={{width : '11.3%'}}>
                                        <span className='policyNumber'>{riskDetail.policyNumber} </span></TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head">ASSIGNED TO:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" style={{width:'17%'}}><div id='assignedTo' className="assignedTo">{assignedTo}</div></TableCell>

                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head"> LAST VALUATION:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold"> <div id='lastValuation'> {
                                        newFunction6(riskDetail, formatDate)} </div></TableCell>
                                    <TableCell className="risk-tbl-cell" colSpan="2"><span className="estimate-btn" onClick={(event) =>
                                       handleEstimatePreviewDesktop(event, riskDetail.riskId, index, activeSelectIndex)
                                        }>
                                         <span className="risk-tbl-btn">Estimates {expanded}</span><span className=
                                        { newFunction7(activeIndex, index, activeSelectIndex, searchResult, expanded, riskDetail)}></span></span>
                                      
                                        <div className="estimate-dialog">
                                            <SettingsOverscanIcon className="estimate-preview-dialog" onClick={() => handleEstimatePreviewDialog(true, riskDetail.riskId, index)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>}
                    {isMobile && <Table aria-label="Risk Listing" style={{ fontFamily: fontFamilyName }} className="risk-tbl-preview">
                            <TableBody key={riskDetail.riskId} >
                                <TableRow>                                    
                                <TableCell className="risk-tbl-cell" rowSpan="4" style={{ width: '3%' }} >
                                   <Checkbox id ={riskDetail.riskId} color="default" onClick ={
                                        (event) => {
                                            isTrue = !event.target.checked; 
                                            handleCheckBoxChange(riskDetail.riskId, isTrue) 
                                        }
                                    } inputProps={{ 'aria-label': 'checkbox to select risk' }} 
                                    checked ={  risks.includes(riskDetail.riskId) ? true :  false }                                    
                                    /> 
                                    
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell" rowSpan="4" style={{ width: '1%', paddingRight: '2px', verticalAlign: 'top' }} >
                                        <img style={{ display: 'block', width: '113px' }} alt={riskDetail.imageUrl} src={riskDetail.imageUrl} />
                                    </TableCell>
                                    <TableCell className="risk-tbl-cell risk-blk-bold risk-tbl-cell-addr" colSpan="2" style={{ width: '270px' }}>
                                        {riskDetail.address}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ width: '10%' }}>RISK ID:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold">
                                        <div id='riskID'>{riskDetail.riskId} </div></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="risk-tbl-cell risk-tbl-cell-head">
                                        POLICY NUMBER:</TableCell>
                                    <TableCell className="risk-tbl-cell risk-tbl-bold" style={{ width: '15%' }}>
                                        <span className='policyNumber'>{riskDetail.policyNumber} </span></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan="2"  className="blnk-mob-no-border"> </TableCell>                                     
                                </TableRow>
                                <TableRow> <TableCell className="risk-tbl-cell" colSpan="4"><span className="estimate-btn" onClick={(event) =>
                                    handleEstimatePreviewDesktop(event, riskDetail.riskId, index, activeSelectIndex)
                                }>
                                    <span style={{ float: 'right' }} ><span className="risk-tbl-btn">Details <span>&</span> Estimates {expanded}</span>
                                        <span className=
                                            {newFunction8(activeIndex, index, activeSelectIndex, expanded, searchResult, riskDetail)}></span></span></span>
 
                                </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>}
                    </div>

                </Card>
                {!isMobile &&   activeIndex === index &&  
                searchResult.estimateResult.length > 0 && activeSelectIndex === index && 
                expanded && searchResult.estimateResult.filter(function(estimate){ return estimate.riskId === riskDetail.riskId }).length > 0 && <div className="estimate-show">
                   <EstimatePreview
                        productId={productId}
                        profileId={profileId}
                        fontFamilyName={fontFamilyName}
                        searchResult={searchResult}
                        handleRepriceEstimate={handleRepriceEstimate}
                        handleEmailDownloadReport={handleEmailDownloadReport}
                        handleEstimateDetailDeleteDialog={handleEstimateDetailDeleteDialog}
                        editEstimate={editEstimate}
                        handleActiveEstimate={handleActiveEstimate}
                        activeEstimateIndex={activeEstimateIndex}
                        handlePopUpEstimate={handlePopUpEstimate}
                        activePopUpIndex={activePopUpIndex}
                        popupExpanded={popupExpanded}
                        userList={userList}
                    />
                    </div>}
                {activeIndex === index && activeSelectIndex === index && 
                isMobile && expanded && <div className="estimate-show">
                        <div className="mob-div-det-conatiner" style={{ fontFamily: fontFamilyName }}>
                                <div className="mob-div-details" style={{ clear: 'both', width: '100%', backgroundColor: '#F3F2F1' }}>
                                    <span className={activeDetailButton ? "estimate-btn estimate-btn-left mob-div-btn-click" :
                                        "estimate-btn estimate-btn-left"} onClick={() => handleActivateDetailButton(true)} >
                                        Details
                            </span>
                                    <span className={activeDetailButton ? "estimate-btn" : "estimate-btn mob-div-btn-click"}
                                        onClick={() => handleActivateDetailButton(false)}>
                                        Estimates
                            </span>
                                </div>
                                <div style={{ clear: 'both' }}>

                                    {activeDetailButton &&
                                        <div>
                                            <Table aria-label="Risk Listing" style={{ fontFamily: fontFamilyName }} className="risk-tbl-preview">
                                                <TableBody key={riskDetail.riskId} >
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ width: '32%', height: '22px' }}>
                                                            <div>INSURED: </div></TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">
                                                            <div id='insured'> {riskDetail.insured} </div></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ height: '22px' }}>UNDERWRITER:</TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">{riskDetail.underwriter} </TableCell>

                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ height: '22px' }}>ASSIGNED TO:</TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">
                                                            <div id='assignedTo'>{assignedTo}</div></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ height: '22px' }}> DATE UPDATED:</TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">
                                                            <div id='updatedDate'>{newFunction9(riskDetail, formatDate)} </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head" style={{ height: '22px' }}>DATE CREATED:</TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="3">
                                                            <div id='createdDate'>{newFunction10(riskDetail, formatDate)} </div></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className="risk-tbl-cell risk-tbl-cell-head"> LAST VALUATION:</TableCell>
                                                        <TableCell className="risk-tbl-cell risk-tbl-bold" colSpan="2"> <div id='lastValuation'> {
                                                            newFunction11(riskDetail, formatDate)} </div></TableCell>
                                                        
                                                        <TableCell style={{ width: '6.8%' }} className="risk-tbl-cell">
                                                            <span style={{ display: 'contents' }} onClick={() => handleEditRisk(riskDetail, riskDetail.riskId)} className="risk-tbl-btn" >
                                                                Edit Risk Details</span>
                                                        </TableCell>
                                                        <TableCell className="risk-tbl-cell" style={{ width: '8.1%' }}>
                                                            <Button onClick={() => handleRiskDetailDeleteDialog(true, riskDetail)} className="delete-risk" style={{ padding: '0.2rem 0.15rem 0rem 0.15rem' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="21" fill="currentColor" width="19" className="bi bi-trash"
                                                                    viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                                        fill-rule="evenodd" />
                                                                </svg>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>

                                    }
                                    {!activeDetailButton && searchResult.estimateResult.length > 0 && 
                searchResult.estimateResult.filter(function (estimate) { return estimate.riskId === riskDetail.riskId }).length > 0 && 
                                        <EstimatePreview
                                            productId={productId}
                                            profileId={profileId}
                                            isMobile={isMobile}
                                            fontFamilyName={fontFamilyName}
                                            searchResult={searchResult}
                                            handleRepriceEstimate={handleRepriceEstimate}
                                            handleEmailDownloadReport={handleEmailDownloadReport}
                                            handleEstimateDetailDeleteDialog={handleEstimateDetailDeleteDialog}
                                            editEstimate={editEstimate}
                                            handlePopUpEstimate={handlePopUpEstimate}
                                            handleActiveEstimate={handleActiveEstimate}
                                            activeEstimateIndex={activeEstimateIndex}
                                            activePopUpIndex={activePopUpIndex}
                                            popupExpanded={popupExpanded}
                                            userList={userList}
                                        />}

                                </div>
                            </div> 
                    </div>}
            </div>
            </div>
        })
    );
};

RiskPreview.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string,
};

export default RiskPreview;

function newFunction12(date) {
    return date.length > 12 ? date.substr(0, 12) : date;
}

function newFunction11(riskDetail, formatDate) {
    return riskDetail.lastValuation !== null && riskDetail.lastValuation !== undefined
        ? formatDate(riskDetail.lastValuation) : riskDetail.lastValuation;
}

function newFunction10(riskDetail, formatDate) {
    return riskDetail.createdDate !== null
        && riskDetail.createdDate !== undefined ? formatDate(riskDetail.createdDate) :
        riskDetail.createdDate;
}

function newFunction9(riskDetail, formatDate) {
    return riskDetail.lastUpdatedDate !== null
        && riskDetail.lastUpdatedDate !== undefined ? formatDate(riskDetail.lastUpdatedDate) :
        riskDetail.lastUpdatedDate;
}

function newFunction8(activeIndex, index, activeSelectIndex, expanded, searchResult, riskDetail) {
    return activeIndex === index && activeSelectIndex === index && expanded &&
        (searchResult.estimateResult.length === 0 || (searchResult.estimateResult.length > 0 &&
            searchResult.estimateResult.filter(function (estimate) { return estimate.riskId === riskDetail.riskId; }).length > 0))

        ? "chevron-arrow chevron-up" : "chevron-arrow chevron-down";
}

function newFunction7(activeIndex, index, activeSelectIndex, searchResult, expanded, riskDetail) {
    return activeIndex === index && activeSelectIndex === index && searchResult.estimateResult.length > 0 &&
        expanded && searchResult.estimateResult.filter(function (estimate) { return estimate.riskId === riskDetail.riskId; }).length > 0
        ? "chevron-arrow chevron-up" : "chevron-arrow chevron-down";
}

function newFunction6(riskDetail, formatDate) {
    return riskDetail.lastValuation !== null && riskDetail.lastValuation !== undefined
        ? formatDate(riskDetail.lastValuation) : riskDetail.lastValuation;
}

function newFunction5(riskDetail, formatDate) {
    return riskDetail.createdDate !== null
        && riskDetail.createdDate !== undefined ? formatDate(riskDetail.createdDate) : riskDetail.createdDate;
}

function newFunction4(riskDetail, formatDate) {
    return riskDetail.lastUpdatedDate !== null
        && riskDetail.lastUpdatedDate !== undefined ? formatDate(riskDetail.lastUpdatedDate) : riskDetail.lastUpdatedDate;
}

function newFunction3(risks, riskDetail, selectAll, excludedRisks) {
    return risks.includes(riskDetail.riskId) ||
        (selectAll === true && !excludedRisks.includes(riskDetail.riskId)) ? true : false;
}

function newFunction1(activeIndex, index, expanded, activeSelectIndex, searchResult, riskDetail, isMobile) {
    return activeIndex === index && expanded && activeSelectIndex === index && searchResult.estimateResult.length > 0 &&
        searchResult.estimateResult.filter(function (estimate) { return estimate.riskId === riskDetail.riskId; }).length > 0
        ? newFunction13(isMobile)
        : newFunction15(activeSelectIndex, index, isMobile, activeIndex, expanded, searchResult);
}

function newFunction15(activeSelectIndex, index, isMobile, activeIndex, expanded, searchResult) {
    return activeSelectIndex === index ?
        newFunction2(isMobile, activeIndex, index, expanded, searchResult)
        : newFunction16(isMobile);
}

function newFunction16(isMobile) {
    return isMobile ? 'search-card search-card-mob' : 'search-card';
}

function newFunction13(isMobile) {
    return isMobile ? 'search-card risk-active search-card-mob' : 'search-card risk-active';
}

function newFunction2(isMobile, activeIndex, index, expanded, searchResult) {
    return isMobile ? newFunction14(activeIndex, index, expanded, searchResult)
        : 'search-card par-risk-active';
}

function newFunction14(activeIndex, index, expanded, searchResult) {
    return activeIndex === index && expanded && searchResult.estimateResult.length === 0 ?
        'search-card risk-active search-card-mob' : 'search-card par-risk-active search-card-mob';
}

function getAssignedToWhenUserListIsNotNull(userList, riskDetail, assignedTo) {
    if (userList != null) {
        const userDetail = filter(userList.data, (userGroup) => {
            return (userGroup.id + '' === riskDetail.assignedTo);
        });
        if (userDetail != null && userDetail.length > 0) {
            assignedTo = userDetail[0].fullName;
        }
    }
    return assignedTo;
}
