import React from 'react';
import {FlatButton, TextField} from 'material-ui';
import {primaryButtonStyle, secondaryButtonStyle} from '../style/styles';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';

const DebugReportActionButton = ({dialogOpen, token, onPress, textChange, cancel, downloadReport}) => {
    const actions = [
        <FlatButton
            label="Cancel"
            {...secondaryButtonStyle}
            style={{
                ...secondaryButtonStyle.style,
                ...{marginRight: '2rem', minWidth: '12em'},
            }}
            onClick={cancel}
        />,
        <FlatButton
            label="Download Report"
            keyboardFocused={true}
            {...primaryButtonStyle}
            onClick={downloadReport}
        />,
    ];
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1em 2em',
        }}>
            <FlatButton
                onClick={onPress}
                label="Download Debug Report"
                {...primaryButtonStyle}
                style={{
                    ...primaryButtonStyle.style,
                    ...{width: '24rem'},
                }}
            />
            <Dialog
                title="Enter authentication token"
                modal={false}
                open={dialogOpen}
                actions={actions}
                onRequestClose={downloadReport}>
                <TextField
                    id="token"
                    fullWidth={true}
                    value={token || ''}
                    onChange={(event, newValue) => textChange(newValue)}
                />
            </Dialog>
        </div>
    );
};

DebugReportActionButton.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    token: PropTypes.string,
    onPress: PropTypes.func.isRequired,
    textChange: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    downloadReport: PropTypes.func.isRequired,
};

export default DebugReportActionButton;