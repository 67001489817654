import apiBaseUrl from '../apiBaseUrl';

const editUserApi = (productId, profileId, editUserDetail, accessToken) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/editUser`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                id: editUserDetail.id,
                userId: editUserDetail.userName,
                userGroupId: editUserDetail.userGroupId,
                isActive: editUserDetail.active,
                userFullName: editUserDetail.fullName.trim().indexOf(' ') !== -1 
                ? editUserDetail.fullName.trim() : editUserDetail.fullName.trim() + " NA"  ,
                userEmailId: editUserDetail.emailId,
                roleTypeId: editUserDetail.roleTypeId, 
                accessToken: accessToken,
            }),
        }
    );

};

export default editUserApi;