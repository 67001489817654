import ReturnUrlButton from '../components/ReturnUrlButton';
import {connect} from 'react-redux';
import {returnToUrl} from '../actions/calculationActionCreator';
import {trackSegmentEvent} from "../actions/segmentActionsCreator";
import {SegmentTrackingEventTypes} from "../constants/SegmentTrackingEventTypes";

export const mapStateToProps = (state, defaultProps) => {
    return {
        styles: state.profile.styles,
        text: state.profile.text,
        profileId: state.profileId,
        productId: state.productId,
        propertyId: state.addressChosen.propertyId,
        rebuildCost: defaultProps.rebuildCost,
        address: defaultProps.address,
        quoteId: state.returnToQuote.quoteId,
        returnUrl: state.returnToQuote.returnUrl,
        hashingPrivateKey: state.profile.hashingPrivateKey,
        goToQuoteOpenInNewTab: state.profile.display.goToQuoteOpenInNewTab,
        returnToQuoteCloseWindow: state.profile.display.returnToQuoteCloseWindow,
        partnerId: state.partnerId,
        includeAddressInReturnUrl: state.profile.display.includeAddressInReturnUrl,
    };
};

export const mapDispatchToProps = (dispatch) => {
    
    return {     
        onReturn: (profileId,rebuildCost,address,estimateId,returnUrl, hashingPrivateKey, goToQuoteOpenInNewTab, returnToQuoteCloseWindow, partnerId, includeAddressInReturnUrl) =>
            returnToUrl(profileId,rebuildCost,address,estimateId,returnUrl, hashingPrivateKey, goToQuoteOpenInNewTab, returnToQuoteCloseWindow, partnerId, includeAddressInReturnUrl),
        trackReturnUrlEvent: (eventType, eventData) => dispatch(trackSegmentEvent(eventType, eventData))
    };
};

const mergeProps = (stateProps, dispatchProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        onReturn: () => {
            let returnQuote = {
                'ProductId': stateProps.productId,
                'ProfileId': stateProps.profileId,
                'PropertyId': stateProps.propertyId,
                'SessionId': stateProps.SessionTrackId,
                'Status': "SUCCESS",
                'Description': "Return to Quote button Clicked",
                'ReturnUrl': stateProps.returnUrl,
                'QuoteId': stateProps.quoteId,
                'Address': stateProps.address,
                'RebuildCost': stateProps.rebuildCost
            };
            dispatchProps.trackReturnUrlEvent(SegmentTrackingEventTypes.RETURN_TO_QUOTE, returnQuote);
            dispatchProps.onReturn(stateProps.profileId, stateProps.rebuildCost, stateProps.address,
                stateProps.quoteId === undefined? null : stateProps.quoteId, stateProps.returnUrl, stateProps.hashingPrivateKey,
                stateProps.goToQuoteOpenInNewTab, stateProps.returnToQuoteCloseWindow, stateProps.partnerId, stateProps.includeAddressInReturnUrl);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReturnUrlButton);
