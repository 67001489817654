import React, {useState} from "react";
import LineChart from "./LineChart";
import PropTypes from "prop-types";

const ChartViewPage = ({futureCostVariables, currentCostVariables}) => {
    let mergedLabels, futureCostLabel = [], filteredVariable = [];
    let currentCostLabel = ["Current Year cost"];
    filteredVariable.push(currentCostVariables[currentCostVariables.length - 1]);

    const mergedVariables = filteredVariable.concat(futureCostVariables[0]); // displaying only 12 months rebuild cost price
    futureCostVariables.forEach(function (item) {
        futureCostLabel.push(item.title);
    })
    mergedLabels = currentCostLabel.concat(futureCostLabel[0]); // displaying only 12 months rebuild cost label

    const [userData] = useState({
        labels: mergedLabels, // x-axis
        datasets: [{
            label: "Graphical Representation Of Estimated Rebuild Cost",
            data: mergedVariables.map((item) => item.price), // y-axis
            backgroundColor: '#eef3fb',
            borderColor: '#336699',
            tension: 0.4,
            borderWidth: 2,
            fill: true,
        },
        ],
    });

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        animations: {
            tension: {
                duration: 2000,
                easing: 'easeInOutSine',
                from: 1,
                to: 0,
                loop: true
            }
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    // text: '---Months---',
                    font: {
                        family: 'Open Sans',
                        size: 20,
                        lineHeight: 1.2,
                    },
                    padding: {top: 20, left: 0, right: 0, bottom: 0}
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    // text: '---Cost in $ (Dollars)---',
                    font: {
                        family: 'Open Sans',
                        size: 20,
                        lineHeight: 1.2
                    },
                    padding: {top: 0, left: 0, right: 0, bottom: 20}
                }
            }
        },
    };

    return (
        <React.Fragment>
            <div>
                <LineChart chartData={userData} options={options}/>
            </div>
        </React.Fragment>
    );
}

ChartViewPage.propTypes = {
    futureCostVariables: PropTypes.array.isRequired,
    currentCostVariables: PropTypes.array.isRequired
};

export default ChartViewPage;