import BuildingDetailsPage from '../components/BuildingDetailsPage';
import { connect } from 'react-redux';
import {
    fetchQuestions,
    setHelpInformationOpen,
    setLetUsKnowDialogOpen,
    addMultipleBuilding,
    onSaveRiskDetailApi,
    removeMultipleBuilding,
    updateExpandedIndex,
    updateRiskInNewEstimate, updateErrors
} from '../actions/questionsActionCreator';
import { bindActionCreators } from 'redux';
import { trackSegmentPageView } from "../actions/segmentActionsCreator";
import AnalyticsPage from '../util/AnalyticsPage';
import {
    validateMandatoryFields 
} from '../actions/calculationActionCreator';
import {updateCurrentTab} from '../actions/searchActionCreator';

const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        questions: state.questions,
        result: state.result,
        displayRefreshWarning: state.displayRefreshWarning,
        addressChosen: state.addressChosen,
        productId: defaultProps.productId,
        profileId: defaultProps.profileId,
        helpContent: state.helper.helpInformationContent,
        helpDialogOpen: state.helper.helpInformationOpen,
        attributesCheck: state.profile.display.attributesCheck,
        showResult: state.showResult,
        debug: defaultProps.debug === 'true',
        styles: state.profile.styles,
        text: state.profile.text,
        partner: defaultProps.partner,
        errors: state.errors,
        handleChange: defaultProps.handleChange,
        buildingGrp: state.buildingGrp,
        riskId: state.riskId,
        riskDetail: state.riskDetail,
        defaultGrp: state.defaultGrp,
        expanded: state.expanded,
        expandedIndex: state.expandedIndex,
        isBuildingLoading: state.isBuildingLoading,
        isEmailDownload: state.isEmailDownload,
        isEditEstimate: state.isEditEstimate,
        postcode: state.postcode,
        depdencyErrors : state.depdencyErrors,
        currentTab: state.currentTab,
        isCurrentTabError: defaultProps.isCurrentTabError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onFetch: fetchQuestions,
        trackSegmentPageView: trackSegmentPageView,
        openHelpInformationDialog: setHelpInformationOpen,
        openLetUsKnowDialog: setLetUsKnowDialogOpen,
        onAddMultipleBuilding: addMultipleBuilding,
        saveRiskDetail: onSaveRiskDetailApi,
        onRemoveMultipleBuilding: removeMultipleBuilding,
        onHandlingPanel: updateExpandedIndex,
        validateMandatoryFields: validateMandatoryFields,
        updateCurrentTab:  updateCurrentTab,
        updateRiskDetail: updateRiskInNewEstimate,
        updateErrors: updateErrors
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        openLetUsKnowDialog: (open, address, propertyId) => {
            dispatchProps.openLetUsKnowDialog(open, address, propertyId);
        },
        openHelpInformationDialog: (open, content) => {
            dispatchProps.openHelpInformationDialog(open, content);
        },
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Building Detials Page', ownProps.location.pathname, stateProps.sessionTrackId, "BuildingDetailsPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Building Details Page", pageDetails);
        },
        partnerId: stateProps.partner,
        updateRiskDetail: () => {
            dispatchProps.updateRiskDetail(stateProps.productId, stateProps.profileId, stateProps.riskId, stateProps.riskDetail);
        },
        updateErrors: (isError, errorMsg1, questionId) => {
            dispatchProps.updateErrors(isError, errorMsg1, questionId);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BuildingDetailsPage);
