import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import TermsOfUse from "../constants/TermsOfUse";
import UrlConstants from "../constants/UrlConstants";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';


const TermsOfUseDialog = ({ actions, open, styles, productId, profileId, onPrint, onAgree, onCancel }) => {
   
    return (
        <Dialog
            className="dialog terms-dialog"
            open={open}
            onClose={onCancel}
            fullWidth={true}
            maxWidth={'md'}
            role="dialog"
            aria-describedby="alert-dialog-slide-description terms-dialog-agree-text"
            style={{ right: '-50px', left: '-50px' }}
        >
            <DialogContent>
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily, fontSize: '14px' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-start'
                        }}>

                            <div style={{ width: '80%', textAlign: 'left' }}>
                                <h1 style={{ marginBottom: 0, color: '#000000', fontFamily: styles.globalFontFamily, marginTtop: '0.65em' }}>Terms of Use</h1>
                            </div>
                            <div style={{ width: '20%', textAlign: 'right', paddingRight: '10px' }}>
                                <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                                    onPrint(productId);
                                    return false;
                                }} onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        onPrint(productId);
                                        return false;
                                    }
                                }} tabIndex={ 0 }>Print</span>
                            </div>
                        </div>
                        {
                            productId === UrlConstants.PRODUCT_ID_8
                                ? <div id="nzTermAndCondition">{TermsOfUse.NZ}</div>
                                : newFunction3(productId, profileId)

                        }
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-around'}}>
                <div className="terms-dialog-agree" id="terms-dialog-agree-text">
                    <span className="terms-dialog-agree-text" style={{ fontFamily: styles.globalFontFamily, color: '#000000'}}>
                        I have read and agree to the Terms of Use
            </span>
                    <div>
                        <CustomFlatButtonContainer
                            label="Cancel"
                            type="secondary"
                            className="terms"
                            onPress={onCancel}
                            style={{
                                marginRight: '1.7rem',
                                marginLeft: '1rem',
                                minWidth: '10em',
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                            }}
                            keyboardFocus={true}
                        />
                        <CustomFlatButtonContainer
                            label="Agree"
                            type="primary"
                            id="agree-btn"
                            className="terms"
                            onPress={onAgree}
                            style={{
                                minWidth: '10em',
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                                border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
                            }}
                            keyboardFocus={true}
                        />
                    </div>
                </div>
            </DialogActions>

        </Dialog>
    );
};

TermsOfUseDialog.propTypes = {
    onAgree: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    styles: PropTypes.object.isRequired,
    onPrint: PropTypes.func.isRequired,
};

export default TermsOfUseDialog;

function newFunction1(productId) {
    return productId === UrlConstants.PRODUCT_ID_7
        ? <div id="auTermAndCondition">{TermsOfUse.AU}</div>
        : newFunction2(productId);
}

function newFunction3(productId, profileId) {
    return (productId === UrlConstants.PRODUCT_ID_7 && (profileId===UrlConstants.PROFILE_ID_141 || profileId === UrlConstants.PROFILE_ID_142))
        ? <div id="D2CTermAndCondition">{TermsOfUse.D2C}</div>
        : newFunction1(productId);
}



function newFunction2(productId) {
    return productId === UrlConstants.PRODUCT_ID_9
        ? <div id="cceAuTermAndCondition">{TermsOfUse.CCE_AU}</div>
        : <div id="cceNzTermAndCondition">{TermsOfUse.CCE_NZ}</div>;
}



