
const DomainConstants = {
    DEV: '.corelogic.asia',
    AU_UAT: 'sumsure-uat.cordell.com.au',
    AU_SUBDOMAIN: '.cordell.com.au',
    AU_PRD: 'sumsure.cordell.com.au',
    NZ_UAT: 'sumsure-uat.corelogic.co.nz',
    NZ_SUBDOMAIN: '.corelogic.co.nz',
    NZ_PRD: 'sumsure.corelogic.co.nz',
}

export default DomainConstants;