import apiBaseUrl from '../apiBaseUrl';

export const getCCEImages = (productId, profileId, estimateId) => {
    const headers = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Pragma': 'no-cache',
        };
    return fetch(`${apiBaseUrl()}products/${productId}/profiles/${profileId}/images?estimateId=${estimateId}`, {headers: headers});
};
