import NotFoundPage from '../components/NotFoundPage';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import {logout} from '../actions/loginActionCreator';

export const mapStateToProps = (state) => {
    return { 
        productId: state.productId, 
        profileId: state.profileId,
   };
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        logout
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
