import React from 'react';
import {FlatButton} from 'material-ui';
import PropTypes from 'prop-types';
import UrlConstants from '../constants/UrlConstants';

const RadioBoxOption = ({label, selected, onClick, styles, productId, profileId}) => {
    let answerSelectedBackgroundColor = null;
    const {
        answerTextColour, answerSelectedTextColour, answerFontSize, globalFontFamily,
        radioButtonDefaultTextColor, radioButtonSelectedTextColor, radioButtonSelectedColor
    } = styles;
    const classes = ['radio-option'];
    let color = null;
    let boxShadowColor = null;

    if (parseInt(label, 10) >= 0) {
        classes.push('smaller');
        color = (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? 'rgb(134, 137, 140)' : getColorWhenRadioButtonDefaultTextColorIsNull(radioButtonDefaultTextColor, answerTextColour);
        boxShadowColor = (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? ('rgb(134, 137, 140) 0 0 0 2px ') : ('0 0 0 1px ' + answerTextColour);
        answerSelectedBackgroundColor = styles.radioButtonDefaultColor;
    }
    if (isNaN(label)) {
        classes.push('large');
        color = (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? 'rgb(134, 137, 140)' : getColorWhenRadioButtonDefaultTextColorIsNull2(radioButtonDefaultTextColor, answerTextColour);
        boxShadowColor = (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? ('rgb(134, 137, 140) 0 0 0 2px ') : ('0 0 0 1px ' + answerTextColour);
        answerSelectedBackgroundColor = styles.radioButtonDefaultColor;
    }
    if (selected) {
        classes.push('selected');
        color = radioButtonDefaultTextColor === null? answerSelectedTextColour: radioButtonSelectedTextColor;
        boxShadowColor = radioButtonSelectedColor === null ? '0 0 0 2px ' + answerSelectedTextColour
        : getboxShadowcolor(productId, styles, radioButtonSelectedColor);
        answerSelectedBackgroundColor = (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? styles.sectionTileColour : styles.radioButtonSelectedColor;
    }

    let keyBoardPressEvent = (event) => {
        if(event.key === 'Enter'){
            onClick.call();
        }
    }

    return <FlatButton
        className={classes.join(' ')}
        label={label}
        labelStyle={{
            ...{
                textTransform: 'none', fontSize: answerFontSize,
                fontFamily: globalFontFamily,
            },
        }}
        onClick={onClick}
        onKeyPress={keyBoardPressEvent}
        style={{
            ...{
                color: color,
                boxShadow: boxShadowColor,
                backgroundColor: answerSelectedBackgroundColor,
            },
        }}
    />;
};

RadioBoxOption.propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
};

export default RadioBoxOption;

function getboxShadowcolor(productId, styles, radioButtonSelectedColor) {
    return (productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9) ? '0 0 0 2px ' + styles.sectionTileColour : '0 0 0 0px ' + radioButtonSelectedColor;
}

function getColorWhenRadioButtonDefaultTextColorIsNull2(radioButtonDefaultTextColor, answerTextColour) {
    return radioButtonDefaultTextColor === null ? answerTextColour : radioButtonDefaultTextColor;
}

function getColorWhenRadioButtonDefaultTextColorIsNull(radioButtonDefaultTextColor, answerTextColour) {
    return radioButtonDefaultTextColor === null ? answerTextColour : radioButtonDefaultTextColor;
}
