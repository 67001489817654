import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AddressNotFoundDialog = ({ dialogOpen, styles, handleProceed, handleClose }) => {
    return (
        <Dialog
            tabIndex={0}
            className="dialog address-not-found-dialog"
            open={dialogOpen}
            fullWidth={true}
            maxWidth={'md'}
            aria-describedby="title dialog-body-text"
            onClose={handleClose}
            style={{
                top: '-20px',
                bottom: '-20px',
                right: '-50px',
                left: '-50px'
            }}
        >
            <DialogTitle className="dialog-body-title">
                <div id="title" style={{
                    fontFamily: styles.globalFontFamily,
                    color: '#000000', fontSize: '22px',
                    lineHeight: '32px',
                    fontWeight: '400'
                }}>We were unable to locate this property.</div></DialogTitle>
            <DialogContent>
                <DialogContentText className="dialog-body-text" id="dialog-body-text" component={'div'} style={{
                    fontFamily: styles.globalFontFamily,
                    color: '#000000',
                    display: 'flex',
                    flex: '1 1',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px',
                    padding: 'inherit',
                }}
                >
                    <p >Unfortunately, we won't be able to fill any data for you.</p>
                    <p >You may choose to proceed anyway.</p>
                    <p >If the property is not suitable for this calculator, it may result in an unsuitable estimation.</p>
                </DialogContentText>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className="address-not-found-dialog-proceed">
                        <CustomFlatButtonContainer
                            tabIndex={0}
                            label="Search again"
                            type="secondary"
                            className="proceed"
                            onPress={handleClose}
                            style={{
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                                margin: '0.5rem 1rem 0.5rem 1rem',
                            }}
                        />
                        <CustomFlatButtonContainer
                            tabIndex={0}
                            label="Proceed anyway"
                            type="primary"
                            className="proceed"
                            onPress={handleProceed}
                            style={{
                                margin: '0.5rem 1rem 0.5rem 1rem',
                                fontFamily: styles.globalFontFamily,
                                borderRadius: styles.buttonRadius,
                                border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
                            }}
                        />
                    </div>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

AddressNotFoundDialog.propTypes = {
    dialogOpen: PropTypes.bool.isRequired,
    styles: PropTypes.object.isRequired,
    handleProceed: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddressNotFoundDialog;
