import React from 'react';
import PropTypes from 'prop-types';

const StateDisclaimer = ({ state }) => {
    let stateDisclaimer = <div className="state-disclaimer"></div>;
    switch (state) {
        case 'sa':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                
                    <p>© 2023 Copyright in this information belongs to the South Australian Government and the South Australian Government does not accept any responsibility for the accuracy or completeness of the information or its suitability for purpose.</p>
                </div>;
            break;
        case 'nsw':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                
                    <p>
                     Contains property sales information provided under licence from the Land and Property Information
                        (“LPI”). RP Data Pty Ltd trading as CoreLogic is authorised as a Property Sales Information
                        provider
                        by the LPI.</p>
                </div>;
            break;
        case 'vic':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                   
                    <p>
                    The State of Victoria owns the copyright in the Property Sales Data and reproduction of that data in any way without the consent of the State of Victoria will constitute a breach of the Copyright Act 1968 (Cth). The State of Victoria does not warrant the accuracy or completeness of the Property Sales Data and any person using or relying upon such information does so on the basis that the State of Victoria accepts no responsibility or liability whatsoever for any errors, faults, defects or omissions in the information supplied.</p>
                </div>;

            break;
        case 'wa':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                    
                    <p>
                    Information contained within this product includes or is derived from the location information data licensed from Western Australian Land Information Authority (WALIA) (2022) trading as Landgate.  Copyright in the location information data remains with WALIA.  WALIA does not warrant the accuracy or completeness of the location information data or its suitability for any particular purpose.
                    </p>
                </div>;
            break;
        case 'act':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                    
                    <p>The Territory Data is the property of the Australian Capital Territory. No part of it may in any
                        form or by any means (electronic, mechanical, microcopying, photocopying, recording or
                        otherwise) be reproduced, stored in a retrieval system or transmitted without prior written
                        permission. Enquiries should be directed to: Director, Customer Services ACT Planning and Land
                        Authority GPO Box 1908 Canberra ACT 2601.</p>
                </div>;
            break;
        case 'tas':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                  
                    <p>
                        This product incorporates data that is copyright owned by the Crown in Right of Tasmania. The data has been used in the product with the permission of the Crown in Right of Tasmania.  The Crown in Right of Tasmania and its employees and agents:
                        <ol style={{ listStyleType: 'lower-alpha' }}>
                            <li>give no warranty regarding the data's accuracy, completeness, currency or suitability for any particular purpose; and</li>
                            <li>
                                do not accept liability howsoever arising, including but not limited to negligence for any loss resulting from the use of or reliance upon the data.</li>
                        </ol>
                        Base data from the LIST © State of Tasmania &nbsp;<a href="https://www.thelist.tas.gov.au/app/content/home">http://www.thelist.tas.gov.au </a>
                       </p>
                </div>;
            break;
        case 'qld':
            stateDisclaimer =
                <div style={{ fontSize: '1rem' }} className="state-disclaimer">
                    
                   
                    <p>Based on or contains data provided by the State of Queensland (Department of Natural Resources) 2022. In consideration of the State permitting use of this data you acknowledge and agree that the State gives no warranty in relation to the data (including accuracy, reliability, completeness, currency or suitability) and accepts no liability (including without limitation, liability in negligence) for any loss, damage or costs (including consequential damage) relating to any use of the data. Data must not be used for direct marketing or be used in breach of the privacy laws; more information at &nbsp;
                        <a href="https://www.propertydatacodeofconduct.com.au/ttsvr/n/qvas-195">www.propertydatacodeofconduct.com.au.</a> </p>
                </div>;
            break;
        default:
            break;
    }
    return stateDisclaimer;
};

StateDisclaimer.propTypes = {
    state: PropTypes.string,
};

export default StateDisclaimer;