import CallToActionButton1 from '../components/CallToActionButton1';
import {callToActionButton} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        display: {
            displayCTAButton1: state.profile.display.displayCTAButton1,
        },
        styles: state.profile.styles,
        text: {
            ctaButton1Header: state.profile.text.ctaButton1Header,
            ctaButton1Text: state.profile.text.ctaButton1Text,
            ctaButton1Label: state.profile.text.ctaButton1Label,
            callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate,
        },
        ctaButton1Url: state.profile.ctaButton1Url,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onCalltoActionButton: (ctaButton1Url) => dispatch(callToActionButton(ctaButton1Url))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallToActionButton1);
