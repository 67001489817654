import apiBaseUrl from '../apiBaseUrl';

const emailReportApi = ({productId, profileId, propertyId, body, currentDataPartition, brokerId}) => {
    const url = brokerId === null || brokerId === undefined ? `${apiBaseUrl()}products/${productId}/profiles/${profileId}/report` :
        `${apiBaseUrl()}products/${productId}/profiles/${profileId}/report?brokerId=${brokerId}`;
    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
    };
    if (propertyId) {
        headers['propertyId'] = propertyId;
    }
    headers['baseUrl'] = window.location.href;
    if (currentDataPartition) {
        headers.currentDataPartition = currentDataPartition;
    }
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        }
    );
};

export default emailReportApi;