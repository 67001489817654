import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import {contactUsText, contactUsTitle} from '../style/styles';
import UrlConstants from '../constants/UrlConstants';
import {isEmpty} from 'lodash';

const ContactUsButton = ({styles, text, contactUsUrl, onContactUs, widthChange}) => {

    let buttonLabel, buttonHeader, buttonHelpText, callToActionSectionTemplate;
    buttonLabel = text.contactUsButtonLabel;
    buttonHeader = text.contactUsHeader;
    buttonHelpText = text.contactUsButtonText;
    callToActionSectionTemplate = text.callToActionSectionTemplate;
    let isMobile = (widthChange === null) ? window.innerWidth <= UrlConstants.MOBILE_INNER_WIDTH : widthChange <= UrlConstants.MOBILE_INNER_WIDTH;

    const button = <CustomFlatButtonContainer
        className="input what-next-button"
        id="contact-us-button"
        label={buttonLabel}
        type="primary"
        keyboardFocus={false}
        onPress={()=>onContactUs()}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
        }}
    />;

    return (
        <div className="mainDiv">
            <div className="input what-next-button">
                <div className="contactUs-header" style={{...contactUsTitle, fontFamily: styles.globalFontFamily}}>
                    <strong>{buttonHeader}</strong>
                </div>
                <div className="contactUs-header" style={{...contactUsText, fontFamily: styles.globalFontFamily}}>
                    <div dangerouslySetInnerHTML={{__html: buttonHelpText}}/>
                </div>
                <div style={{
                    paddingBottom: '1rem'
                }}>
                    {(callToActionSectionTemplate !== 'IAG3') && button}
                    {(isMobile && callToActionSectionTemplate === 'IAG3') && (buttonLabel !== null && !isEmpty(buttonLabel) && buttonLabel !== "Null" && buttonLabel !== "null") && button}
                </div>
            </div>
        </div>
    );
};

ContactUsButton.propTypes = {
    styles: PropTypes.object.isRequired,
    text: PropTypes.object.isRequired,
    contactUsUrl: PropTypes.string,
    onContactUs: PropTypes.func.isRequired,
};

export default ContactUsButton;