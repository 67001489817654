import {EventTypes} from 'redux-segment';
import analytics from "react-segment";

export const initializeAnalytics = (key) => {
    analytics.default.load(key);
    return {type: 'INITIALIZE_SEGMENT_ANALYTICS', analytics};
};

export const trackSegmentPageView = (event, pageDetails) => {
    let localPageDetails = {
        ...pageDetails,
        path: pageDetails.trackingPath,
        name: pageDetails.title,
    };
    const metaInfo = {
        analytics: {
            eventType: EventTypes.page,
            eventPayload: {
                event: event,
                properties: localPageDetails,
            },
        },
    };
    return {type: 'TRACK_SEGMENT_PAGE_VIEW', meta: metaInfo};
};

export const trackSegmentEvent = (eventName, eventDetails) => {
    const metaInfo = {
        analytics: {
            eventType: EventTypes.track,
            eventPayload: {
                event: eventName,
                properties: eventDetails,
            },
        },
    };
    return {type: 'TRACK_SEGMENT_EVENT', meta: metaInfo};
};

export const sessionTracker = (time) => {
    return {type: 'SET_SESSION_TRACKER', data: time};
};

export const initTrackSegmentEvent = () => {
     return {type: 'INIT_TRACK_SEGMENT_EVENT'};
 };
 
 export const updateSegmentEvent = (eventName, eventDetails) => {
     return {type: 'UPDATE_SEGMENT_EVENT',details: {eventName,eventDetails}};
 };
 
 export const clearSegmentEventMap = () => {
     return {type: 'CLEAR_SEGMENT_EVENT_MAP'};
 };