import React from 'react';
import { Card } from 'material-ui';
import { isEmpty, findIndex } from 'lodash';
import SectionHeader from './SectionHeader';
import PropTypes from 'prop-types';
import ImageUploads from './ImageUploads';
import ImageRow from './ImageRow';
import SpinnerContainer from '../containers/SpinnerContainer';
import UrlConstants from '../constants/UrlConstants';
import ImagePanelContainer from '../containers/ImagePanelContainer';
import { Button } from '@material-ui/core';


class ImageManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        const { calculateEstimate, result, debug, updateCurrentTab, updateRiskDetail, currentTab, isEditEstimate, questions, postcode } = this.props;
        if (!debug && (result.data === null || result.data.estimateId === null)) {
            const index = findIndex(questions.data, (question) => {
                return question.id === 302860;
            });
            if (index >= 0) {
                questions.data[index] = { ...questions.data[index], answer: postcode, visible: false, isInvalid: false, answerSource: "grid", helpEnabled: true };
            }
            calculateEstimate();
        }
        if(!isEditEstimate && currentTab === UrlConstants.RISK_DETAILS) {
            updateRiskDetail();
        }
        updateCurrentTab(UrlConstants.IMAGE_MANAGEMENT);
    }

    msg = (innerHTML, className) => {
        const el = document.getElementById('msg');
        el.innerHTML = innerHTML;
        el.className = className;
        setTimeout(() => { el.innerHTML = el.innerHTML.replace(el.innerHTML, '') }, 5000);
    };

    render() {
        const { productId, profileId, result, styles, cceImages, counter, fetchImages,
            onRemoveImages, changeImageDescription, onImageDescChange, imageDetail, updateImagesDescription, widthChange, handleChange,
            isEmailDownload, questions } = this.props;
        let estimateId = !result.data ? null : result.data.estimateId;
        let isMobile = widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
        let imageRow;
        if (cceImages.data != null) {
            imageRow = <ImageRow cceImages={cceImages} styles={styles} onRemoveImages={onRemoveImages} productId={productId}
                profileId={profileId} changeImageDescription={changeImageDescription} onImageDescChange={onImageDescChange}
                imageDetail={imageDetail} msg={this.msg} isMobile={isMobile} />;
        }


        let imageUploadPage = <ImageUploads productId={productId}
            profileId={profileId}
            estimateId={estimateId}
            counter={counter}
            styles={styles}
            cceImages={cceImages}
            fetchImages={fetchImages}
            updateImagesDescription={updateImagesDescription}
        />;
        const handleNext = (event, newValue) => {
            handleChange(UrlConstants.REBUILD_COST);
        };
        const handlePrevious = (event, newValue) => {
            handleChange(UrlConstants.SPECIAL_ITEMS);
        }


        return (
            <div className="image-management">
                <SpinnerContainer />
                <Card className="images-card" containerStyle={{ paddingBottom: 0, fontFamily: styles.globalFontFamily }}>
                    <SectionHeader name="Image Management" color={styles.sectionTileColour} styles={styles} />
                    <div className="image-upload">
                        {isMobile &&
                            (<div className="image-row" style={{ padding: '20px 10%' }}>
                                <ImagePanelContainer />
                            </div>)}
                        {imageUploadPage}
                        <div style={{ textAlign: 'center' }}><span style={{ color: 'red' }}>{this.state.errorMessage}</span></div>

                    </div>
                    <div className="image-row">
                        {imageRow}
                    </div>

                </Card>
                <div className="action-nav">
                <div className="nav-previous image-previous"><Button className="action_previous" variant="outlined" id="tab-2"
                    rounded="true"
                    size="large"
                    tabIndex={0}
                    disableTouchRipple={true}
                    disableFocusRipple={false}
                    onClick={handlePrevious}
                > &larr; Special Items
                        </Button></div>
                <div className="nav-next" style={{ marginRight: '0px' }}><Button className="action_risk" variant="outlined" id="tab-5"
                    rounded="true"
                    size="large"
                    tabIndex={0}
                    disabled={isEmpty(questions.data) || isEmailDownload ? true : false}
                    disableTouchRipple={true}
                    disableFocusRipple={false}
                    onClick={handleNext}
                > Rebuild Cost &rarr;
                        </Button></div>
                </div>
            </div>
        );
    }
}

ImageManagement.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    styles: PropTypes.object.isRequired,
    fetchImages: PropTypes.func.isRequired,
    removeImages: PropTypes.func,
    onImageDescChange: PropTypes.func,
    updateImagesDescription: PropTypes.func,
};

export default ImageManagement;
