import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const UserDeleteDialog = ({ styles, deleteUser, handleUserDeleteDialog, handleUserDelete, loggedInUserDetail ,deleteError}) => {

    let { message, message1, message2, message3, isSelf } = getVariables(deleteUser, loggedInUserDetail);

    return (
        <Dialog
            className="dialog terms-dialog"
            open={deleteUser.open}
            maxWidth={'sm'}
            onClose={() => handleUserDeleteDialog(false)}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="user-delete-row">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                   <div>
                                   {!deleteError && <div><span style={{ color: 'black' }}>{message}<span style={{ fontStyle: 'italic' }}>{message1}</span>{message2}</span><br></br><br></br>
                                    {message3}</div>}
                                    {deleteError && <div><span style={{ color: 'black' }}>{deleteError}</span></div>}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    { !isSelf && !deleteError &&
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Yes, Delete" onPress={() => handleUserDelete(deleteUser.deleteUserDetail.userId)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    }
                    {!deleteError && <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => handleUserDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>}
                    {deleteError && <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="OK" onPress={() => handleUserDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>}
                </div>
            </DialogActions>
        </Dialog>)
};

UserDeleteDialog.propTypes = {
    deleteUser: PropTypes.object,
    styles: PropTypes.object.isRequired,
    handleUserDelete: PropTypes.func.isRequired,
    handleUserDeleteDialog: PropTypes.func.isRequired,
};

export default UserDeleteDialog;

function getVariables(deleteUser, loggedInUserDetail) {
    let deleteUserName = (deleteUser.deleteUserDetail === null || deleteUser.deleteUserDetail === undefined) ? '' : deleteUser.deleteUserDetail.userName;

    let deleteUserRole = (deleteUser.deleteUserDetail === null || deleteUser.deleteUserDetail === undefined) ? '' : deleteUser.deleteUserDetail.role;
    let isSelf = deleteUserName === loggedInUserDetail.userName ? true : false;
    let message = isSelf ? 'You can not delete own (' + deleteUserName + ') record' : 'Are you sure you want to delete ' + deleteUserName;
    let message1 = isSelf ? '' : ' - ' + deleteUserRole + " level user ";
    let message2 = isSelf ? '' : 'from Cordell Commercial Estimator?';
    let message3 = isSelf ? '' : newFunction1(deleteUser);
    return { message, message1, message2, message3, isSelf };
}
function newFunction1(deleteUser) {
    return !deleteUser.hasMultipleAccount ? '' : "Please note the user is associated with other applications using Corelogic Single-Sign-on";
}

