import React from 'react';
import UserFirstLastNameContainer from '../containers/UserFirstLastNameContainer';
import EmailContainer from '../containers/EmailContainer';
import { Card } from 'material-ui';
import PropTypes from 'prop-types';
import SectionHeader from './SectionHeader';
import DownloadPdfReportContainer from '../containers/DownloadPdfReportContainer';
import ReturnUrlButtonContainer from '../containers/ReturnUrlButtonContainer';
import { callToActionTitleStyle, callToActionTextStyle, downloadButtonStyle } from '../style/styles';
import ContactUsButtonContainer from '../containers/ContactUsButtonContainer';
import CallToActionButton1Container from '../containers/CallToActionButton1Container';
import { isEmpty } from 'lodash';
import UrlConstants from '../constants/UrlConstants';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';

const WhatNextIAG = ({ display, report, onEmailReport, recipient, downloadPdfReportText, text, styles, returnToQuote, address, profileId, result, callToActionClicked,
    contactUsUrl, showNote, ctaButton1Url, displayCTAButton1, displayContactUs, onContactUs, onCalltoActionButton, widthChange, partnerId, returnUrls }) => {

    const { userName: userNameDisplay, email: emailDisplay, downloadReport } = display;
    const { whatNextSectionTitle: whatNextSection, callToAction: callToActionText, whatNextSectionText, callToActionText1Header, callToActionText1,
        callToActionText2Header, callToActionText2, callToActionSectionTemplate, downloadPdfReportBodyText } = text;
    let { buttonLabel, ctaButtonLabel, displayReturnToQuote, rebuildCost, isMobile } = getVariables(result, returnToQuote, text, widthChange);
    const { email: emailId } = recipient.data;
    const returnURL =  getReturnURL(returnToQuote, returnUrls);
    const button = <CustomFlatButtonContainer
        className="input what-next-button"
        id="contact-us-button"
        label={buttonLabel}
        type="primary"
        keyboardFocus={false}
        onPress={() => onContactUs(contactUsUrl, profileId, partnerId)}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: getBorder(styles)
        }}
    />;
    const button1 = <CustomFlatButtonContainer
        className="input what-next-button"
        id="callToAction-us-button"
        label={ctaButtonLabel}
        type="primary"
        keyboardFocus={false}
        onPress={() => onCalltoActionButton(ctaButton1Url)}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: getBorderButton1(styles)
        }}
    />;

    const emailButton = <CustomFlatButtonContainer
        className="input what-next-button"
        label="Email report"
        onPress={onEmailReport}
        display={displayForEmailButton(emailId, report)}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: borderForEmailButton(styles)
        }}
        type="primary"
        keyboardFocus={false}
    />

    return (
        <Card className="card" containerStyle={{ fontFamily: styles.globalFontFamily }}>
            <SectionHeader name={whatNextSection} color={styles.sectionTileColour} styles={styles} />

            {callToActionSectionTemplate !== 'IAG3' ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1em 2em',
                }}>
                    {newFunction8(callToActionSectionTemplate, displayReturnToQuote, rebuildCost, address, styles, callToActionText1Header, callToActionText1, callToActionText2Header, callToActionText2)
                    }
                    {newFunction5(whatNextSectionText, styles)
                    }

                    {newFunction12(userNameDisplay)}
                    {displayEmailSection(emailDisplay)}
                    {
                        newFunction10(downloadReport, downloadPdfReportText, styles)
                    }
                    {displayPDFButton(downloadReport)}
                    {newFunction15(returnToQuote)}

                    {
                        displayReturntoQuote(callToActionText, showNote, callToActionClicked, styles, returnURL)
                    }
                </div>
                : <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1em 2em',
                }}>
                    {newFunction7(callToActionSectionTemplate, displayContactUs, contactUsUrl, displayCTAButton1, ctaButton1Url)
                    }
                    {newFunction6(callToActionSectionTemplate, isMobile, buttonLabel, button, ctaButtonLabel, button1)
                    }
                    {newFunction5(whatNextSectionText, styles)
                    }

                    {userNameDisplay && <UserFirstLastNameContainer />}
                    {newFunction4(emailDisplay, downloadReport, downloadPdfReportText, styles, downloadPdfReportBodyText)}
                    {newFunction3(isMobile, emailButton, emailDisplay, downloadReport)}

                    {newFunction2(returnToQuote)}

                    {
                        displayReturntoQuote(callToActionText, showNote, callToActionClicked, styles, returnURL)
                    }
                </div>
            }
        </Card>
    );
};

WhatNextIAG.propTypes = {
    display: PropTypes.object.isRequired,
    downloadPdfReportText: PropTypes.string,
    text: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    callToActionClicked: PropTypes.func.isRequired,
    contactUsUrl: PropTypes.string,
    ctaButton1Url: PropTypes.string,
    onCalltoActionButton: PropTypes.func,
    onContactUs: PropTypes.func
};

export default WhatNextIAG;

function newFunction15(returnToQuote) {
    return returnToQuote && <div className="download-report" />;
}

function displayPDFButton(downloadReport) {
    return downloadReport && <DownloadPdfReportContainer />;
}

function displayEmailSection(emailDisplay) {
    return emailDisplay && <EmailContainer />;
}

function newFunction12(userNameDisplay) {
    return userNameDisplay && <UserFirstLastNameContainer />;
}

function newFunction10(downloadReport, downloadPdfReportText, styles) {
    return downloadReport && downloadPdfReportText
        ? <div className="download-report" style={{
            fontFamily: styles.globalFontFamily, fontSize: '1rem', padding: '0.6rem 0rem', fontWeight: 'bold'
        }} dangerouslySetInnerHTML={{ __html: downloadPdfReportText }} />
        : <div style={{ height: '1rem' }} />;
}

function newFunction8(callToActionSectionTemplate, displayReturnToQuote, rebuildCost, address, styles, callToActionText1Header, callToActionText1, callToActionText2Header, callToActionText2) {
    return (callToActionSectionTemplate === 'IAG1' || callToActionSectionTemplate === 'IAG2') &&
        <div className="what-Next-box">
            {callToActionSectionTemplate === 'IAG1' && displayReturnToQuote &&
                <div>
                    <ReturnUrlButtonContainer rebuildCost={rebuildCost} address={address} />
                </div>}
            {callToActionSectionTemplate === 'IAG2' &&
                <div>
                    <div style={{ ...callToActionTitleStyle, fontFamily: styles.globalFontFamily, fontWeight: 'bold' }}
                        dangerouslySetInnerHTML={{ __html: callToActionText1Header }}>
                    </div>
                    <div style={{ ...callToActionTextStyle, fontFamily: styles.globalFontFamily }}
                        dangerouslySetInnerHTML={{ __html: callToActionText1 }}></div>
                </div>}
            {newFunction16(callToActionSectionTemplate, displayReturnToQuote, callToActionText1Header, callToActionText1)}
            <div>
                <div style={{ ...callToActionTitleStyle, fontFamily: styles.globalFontFamily, fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{ __html: callToActionText2Header }}>
                </div>
                <div style={{ ...callToActionTextStyle, fontFamily: styles.globalFontFamily }}
                    dangerouslySetInnerHTML={{ __html: callToActionText2 }}></div>
            </div>
        </div>;
}

function newFunction16(callToActionSectionTemplate, displayReturnToQuote, callToActionText1Header, callToActionText1) {
    return ((callToActionSectionTemplate === 'IAG1' && displayReturnToQuote) ||
        (callToActionSectionTemplate === 'IAG2' && (callToActionText1Header && callToActionText1))) &&
        <div style={{ width: '5rem' }}></div>;
}

function newFunction7(callToActionSectionTemplate, displayContactUs, contactUsUrl, displayCTAButton1, ctaButton1Url) {
    return (callToActionSectionTemplate === 'IAG3') &&
        <div className="what-Next-box">
            {callToActionSectionTemplate === 'IAG3' && displayContactUs &&
                <div className="what-Next-section">
                    <ContactUsButtonContainer contactUsUrl={contactUsUrl} />
                </div>}
            {callToActionSectionTemplate === 'IAG3' && displayContactUs && <div style={{ width: '5rem' }}></div>}
            {callToActionSectionTemplate === 'IAG3' && displayCTAButton1 &&

                <div className="what-Next-section">
                    <CallToActionButton1Container ctaButton1Url={ctaButton1Url} />
                </div>}
        </div>;
}

function newFunction6(callToActionSectionTemplate, isMobile, buttonLabel, button, ctaButtonLabel, button1) {
    return (callToActionSectionTemplate === 'IAG3') &&
        <div className="what-Next-box">
            {!isMobile && <div className="what-Next-section">
                {(buttonLabel !== null && !isEmpty(buttonLabel) && buttonLabel !== "Null" && buttonLabel !== "null") && button}
            </div>}
            {!isMobile && <div style={{ width: '5rem' }}></div>}
            <div className="what-Next-section">
                {(ctaButtonLabel !== null && !isEmpty(ctaButtonLabel) && ctaButtonLabel !== "Null" && ctaButtonLabel !== "null") && button1}
            </div>
        </div>;
}

function newFunction5(whatNextSectionText, styles) {
    return whatNextSectionText &&
        <div className="what-next-section-text" style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            fontFamily: styles.globalFontFamily,
        }} dangerouslySetInnerHTML={{ __html: whatNextSectionText }} />;
}

function newFunction4(emailDisplay, downloadReport, downloadPdfReportText, styles, downloadPdfReportBodyText) {
    return <div className="what-Next-box">
        <div className="what-Next-section" style={{ marginRight: '0rem' }}>
            {emailDisplay && <EmailContainer />}
        </div>
        {emailDisplay && <div style={{ width: '5rem' }}></div>}
        <div className="what-Next-text what-Next-section">
            {downloadReport && downloadPdfReportText
                ? <div className="download-report" style={{
                    fontFamily: styles.globalFontFamily, fontSize: '1rem', margin: '35px 0px 16px 0px', fontWeight: 'bold'
                }} dangerouslySetInnerHTML={{ __html: downloadPdfReportText }} />
                : <div style={{ height: '1rem' }} />}

            {downloadReport && downloadPdfReportBodyText
                ? <div style={{ ...downloadButtonStyle, fontSize: '1rem', fontFamily: styles.globalFontFamily, marginBottom: '0.7rem' }}>
                    <div dangerouslySetInnerHTML={{ __html: downloadPdfReportBodyText }} />
                </div>
                : <div style={{ height: '1rem' }} />}
            <div className="what-Next-section" style={{ marginRight: '0rem' }}>
                {emailDisplay && <div className="what-next-button-space"></div>}
                {downloadReport && <DownloadPdfReportContainer />}
            </div>
        </div>
    </div>;
}

function newFunction3(isMobile, emailButton, emailDisplay, downloadReport) {
    return <div className="what-Next-box">
        <div className="what-Next-section" style={{ marginRight: '0rem' }}>
            {!isMobile && emailButton}
        </div>
        <div style={{ width: '5rem' }}></div>
        {/*<div className="what-Next-section" style={{ marginRight: '0rem' }}>*/}
        {/*    {emailDisplay && <div className="what-next-button-space"></div>}*/}
        {/*    {downloadReport && <DownloadPdfReportContainer />}*/}
        {/*</div>*/}

    </div>;
}

function newFunction2(returnToQuote) {
    return returnToQuote && <div className="download-report" />;
}

function displayReturntoQuote(callToActionText, showNote, callToActionClicked, styles, returnURL) {
    return (callToActionText !== null && callToActionText !== '' && (showNote !== 'no'))
        && <div onClick={(e) => callToActionClicked(e)} className="call-to-action"
            style={{
                padding: '1em',
                margin: '1em 0',
                backgroundColor: styles.callToActionBackgroundColour,
                fontSize: styles.callToActionFontSize,
                color: styles.callToActionFontColour,
            }} dangerouslySetInnerHTML={{ __html: callToActionText.replace('{`returnData.returnUrl`}',returnURL) }} />;
}

function borderForEmailButton(styles) {
    return styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : "";
}

function displayForEmailButton(emailId, report) {
    return emailId === null || report.sentReport;
}

function getBorderButton1(styles) {
    return styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : "";
}

function getBorder(styles) {
    return styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : "";
}

function getVariables(result, returnToQuote, text, widthChange) {
    let rebuildCost = result ? result.variables[result.variables.length - 1].price : null;
    let returnUrlData = returnToQuote != null ? returnToQuote.returnUrl : null;
    let displayReturnToQuote = returnUrlData != null ? true : false;
    let buttonLabel = text.contactUsButtonLabel;
    let ctaButtonLabel = text.ctaButton1Label;
    let isMobile = (widthChange === null) ? window.innerWidth <= UrlConstants.MOBILE_INNER_WIDTH : widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
    return { buttonLabel, ctaButtonLabel, displayReturnToQuote, rebuildCost, isMobile };
}

function getReturnURL(returnToQuote, defaultURL) {
    let returnURL = returnToQuote != null && returnToQuote !== undefined ? returnToQuote.returnUrl : "";
    return  (returnURL === "" ? defaultURL : returnURL);
}
