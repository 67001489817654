import { connect } from 'react-redux';
import EditUser from '../components/EditUser';
import { bindActionCreators } from 'redux';
import {
    onBackToUserList, onChangeEditUserName, onChangeEditUserStatus, onChangeEditFullName, onChangeEditEmailId,
    onChangeEditUserRoleType, getUserGroupsAction, onChangeEditCompanySelection, onChangeEditDivisionSelection,
    onChangeEditTeamSelection, updateUser
} from '../actions/searchActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        productId: state.productId,
        profileId: state.profileId,
        widthChange: state.widthChange,
        userGroups: state.userGroups,
        editDivision: state.editDivision,
        editCompany: state.editCompany,
        editTeam: state.editTeam,
        editUserDetail: state.editUserDetail,
        loggedInUserDetail: state.loggedInUserDetail,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onChangeEditUserName,
        onChangeEditUserStatus,
        onChangeEditFullName,
        onChangeEditEmailId,
        onChangeEditUserRoleType,
        getUserGroupsAction,
        onChangeEditCompanySelection,
        onChangeEditDivisionSelection,
        onChangeEditTeamSelection,
        onBackToUserList,
        updateUser,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onChangeEditUserName: dispatchProps.onChangeEditUserName,
        onChangeEditUserStatus: dispatchProps.onChangeEditUserStatus,
        onChangeEditFullName: dispatchProps.onChangeEditFullName,
        onChangeEditEmailId: dispatchProps.onChangeEditEmailId,
        onChangeEditUserRoleType: dispatchProps.onChangeEditUserRoleType,
        getUserGroups: dispatchProps.getUserGroupsAction,
        onChangeEditCompanySelection: dispatchProps.onChangeEditCompanySelection,
        onChangeEditDivisionSelection: dispatchProps.onChangeEditDivisionSelection,
        onChangeEditTeamSelection: dispatchProps.onChangeEditTeamSelection,
        onBackToUserList: dispatchProps.onBackToUserList,
        onUpdateUser: dispatchProps.updateUser,
        onLoad: () => {
          const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'AddEdit User Page', ownProps.location.pathname, stateProps.sessionTrackId, "AddEditUserPage", stateProps.partner);
           dispatchProps.trackSegmentPageView("AddEdit User Page", pageDetails);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditUser);