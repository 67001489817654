import React from 'react';
import {Card} from 'material-ui';
import DebugReportActionButtonContainer from '../containers/DebugReportActionButtonContainer';
import SectionHeader from './SectionHeader';
import CCEResultList from './CCEResultList';
import CCEWhatNextContainer from '../containers/CCEWhatNextContainer';
import PropTypes from 'prop-types';

class CCEResult extends React.Component {
    componentDidMount() {
        if (this.props.result.manual) {
            this.container.scrollIntoView();
        }
    }

    render() {
        const { result, debug, display, rebuildCostHeader, rebuildCostNote, rebuildCostFooterNote } = this.props;
        const { email: emailDisplay, downloadReport } = display;

        function getSortedVariables() {
            if (!result.data) {
                return [];
            }
            return result.data.variables;
        }

        function getSortedSpecialItems() {
            if (result.data && result.data.specialItems) {
                return result.data.specialItems;
            }
            return [];
        }

        function getSortedDeclaredValue() {
            if (result.data && result.data.declaredValue) {
                return result.data.declaredValue;
            }
            return [];
        }

        function getSortedIndemnityValue() {
                    if (result.data && result.data.indemnityValue) {
                        return result.data.indemnityValue;
                    }
                    return [];
                }

        function getSortedAllowancesForCostInflation() {
            if (result.data && result.data.allowancesForCostInflation) {
                return result.data.allowancesForCostInflation;
            }
            return [];
        }

        const sortedVariables = getSortedVariables();
        const declaredValue = getSortedDeclaredValue();
        const allowancesForCostInflation = getSortedAllowancesForCostInflation();
        const specialItems = getSortedSpecialItems();
        const indemnityValue = getSortedIndemnityValue();

        let rebuildSectionHeader = rebuildCostHeader ? rebuildCostHeader : "Estimated cost to rebuild your property";

        let whatNext;
        let downloadButton;

        if (debug) {
            downloadButton = <DebugReportActionButtonContainer/>;
        } else if (result.data && result.data.variables.length > 0) {
            if ( emailDisplay || downloadReport ){
                  whatNext = <CCEWhatNextContainer />;
            }
        }
        return (
            <div ref={(div) => this.container = div}>
                {debug &&
                <Card className="card">
                    <SectionHeader name="Debug Report"
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    {downloadButton}
                </Card>
                }
                {!debug && specialItems.length > 0 &&
                    <Card className="card">
                        <SectionHeader name="Special Items"
                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                        <CCEResultList variables={specialItems} styles={this.props.styles}/>
                    </Card>
                }
                {!debug && declaredValue.length > 0 &&
                    <Card className="card">
                        <SectionHeader name="Estimated declared rebuild cost"
                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                        <CCEResultList variables={declaredValue} styles={this.props.styles}/>
                    </Card>
                }
                {!debug && allowancesForCostInflation.length > 0 &&
                    <Card className="card">
                        <SectionHeader name="Allowances for Cost Inflation"
                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                        <CCEResultList variables={allowancesForCostInflation} styles={this.props.styles}/>
                    </Card>
                }
               {!debug &&
                <Card className="card">
                    <SectionHeader name={rebuildSectionHeader}
                                   color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                    {rebuildCostNote && <div className="estimate-section-notes" style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        padding: '2em 2em',
                        fontFamily: this.props.styles.globalFontFamily,
                    }} dangerouslySetInnerHTML={{__html: rebuildCostNote}}/>}
                    <CCEResultList variables={sortedVariables} styles={this.props.styles}/>
                    {rebuildCostFooterNote &&
                    <div style={{
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        padding: '2em 2em',
                        fontFamily: this.props.styles.globalFontFamily,
                    }} dangerouslySetInnerHTML={{__html: rebuildCostFooterNote}}/>
                    }
                </Card>
                }
                {!debug && indemnityValue.length > 0 &&
                                    <Card className="card">
                                        <SectionHeader name="Indemnity Value"
                                            color={this.props.styles.sectionTileColour} styles={this.props.styles}/>
                                        <CCEResultList variables={indemnityValue} styles={this.props.styles} isIndemnity={true}/>
                                    </Card>
                                }
                {whatNext}
            </div>
        );
    }
}

CCEResult.propTypes = {
    result: PropTypes.object.isRequired,
    debug: PropTypes.bool.isRequired,
    sectionTileColour: PropTypes.string,
    display: PropTypes.object.isRequired,
    callToActionText: PropTypes.string,
    styles: PropTypes.object,
    rebuildCostNote: PropTypes.string,
    rebuildCostHeader: PropTypes.string,
    contactUsUrl: PropTypes.string,
    ctaButton1Url: PropTypes.string,
};

export default CCEResult;