import apiBaseUrl from '../apiBaseUrl';

const repriceExportApi = ({productId, profileId, risks, userId, emailId}, selectedAllRisk, searchParams, sortParams, totalRecords, excludedRisks, companyGroupId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/repriceExport`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({ userId: userId, emailId: emailId, risks: risks, selectedAllRisk: selectedAllRisk, searchBy: searchParams.searchBy,
                searchText: searchParams.searchText, fromDate: searchParams.fromDate, toDate: searchParams.toDate, searchDateOption: searchParams.searchDateOption,
                sortBy: sortParams.dateChange === null ? "descending" : sortParams.dateChange,
                sortDirection: sortParams.sortOrder === null ? "lastUpdatedDate" : sortParams.sortOrder, pageNo: 0, pageSize: totalRecords,
                excludedRisks: excludedRisks, companyGroupId: companyGroupId }),
        }
    );
};

export default repriceExportApi;