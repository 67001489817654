import React from 'react';
import PropTypes from 'prop-types';
import { inputStyle, titleStyle } from '../style/styles';
import { TextField, Card } from 'material-ui';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class ImageRow extends React.Component {

    constructor(props) {
            super(props);
            this.state = {
                imageDescriptionDetail: [],
            };
        }

render () {
    const {cceImages, styles, onRemoveImages, productId, profileId, changeImageDescription,
    onImageDescChange, msg, isMobile} = this.props;

    return (
    <div className="image-row-field">
        {
            cceImages.data.map((cceImage, index) => {
            let imageId = "image-desc-"+index;
        return <div key={index} className={index === 0 ? "row-border each-row" : "each-row-border each-row"}>
                {isMobile && (
                <div>
                     <div style={{ display: 'flex', padding: '2px'}}>
                                             <div style={{ width: '35%'}} ></div>
                                             <img alt={cceImage.imageName} src={cceImage.imageUrl}/>
                                             <div style={{ width: '35%'}} ></div>
                                        </div>
                      <div className="for-label" style={{marginTop: '0.5em'}}>
                      <div style={{ width: '15%'}} ></div>
                      <label id="desc" style={{fontSize: '15px',marginTop: '1.5em', marginLeft: '0.5em'}}>Description: </label>
                      <div style={{ width: '15%'}} ></div>
                      </div>
                                     <div className="text-field" style={{width: '100%',display: 'flex',padding: '5px', height: '52px'}}>
                                     <TextField
                                         className="required-field"
                                         fullWidth={true}
                                         id={imageId}
                                         type="text"
                                         floatingLabelFixed={true}
                                         floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                                         underlineShow={false}
                                         value={cceImage.imageDescription}
                                         inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                                         onChange={(event, newValue) => {
                                            const tempValue = newValue.substring(0, 255);
                                             onImageDescChange({
                                                 cceImage: cceImage, imageId: cceImage.id, imageDescription: tempValue
                                             }, cceImages);
                                         }}
                                         onBlur={(event) => {
                                             changeImageDescription(cceImage.id, event.target.value);
                                          }}
                                         />
                                             <IconButton className="icon-button" aria-label="delete"
                                                                                     onClick={() => {onRemoveImages(productId, profileId, cceImage.id, cceImage.estimateId);
                                                                                                     msg(`${cceImage.imageName} Deleted Successfully`,'');}}>
                                                                                 <DeleteIcon />
                                                                                 </IconButton>
                                         </div>

                                     </div>
                )}
                 {!isMobile && (
        <Card className="card">

                <div style={{height: '90px', width: '35%'}}>
                <img id="img-desplay"
                    alt={cceImage.imageName}
                    src={cceImage.imageUrl}/>
                </div>

                <label id="desc" style={{marginTop: '2.2em', marginRight: '0.5em'}}>Description: </label>
                <TextField
                    className="required-field"
                    fullWidth={true}
                    id={imageId}
                    type="text"
                    floatingLabelFixed={true}
                    floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                    underlineShow={false}
                    value={cceImage.imageDescription}
                    inputStyle={{ ...inputStyle, fontFamily: styles.globalFontFamily }}
                    onChange={(event, newVal) => {
                        const tempValue = newVal.substring(0, 255);
                        onImageDescChange({
                            cceImage: cceImage, imageId: cceImage.id, imageDescription: tempValue
                        }, cceImages);
                    }}
                    onBlur={(e) => {
                        changeImageDescription(cceImage.id, e.target.value);
                     }}
                    />
                <IconButton className="icon-button" aria-label="delete"
                    onClick={() => {onRemoveImages(productId, profileId, cceImage.id, cceImage.estimateId);
                                    msg(`${cceImage.imageName} Deleted Successfully`,'');}}>
                <DeleteIcon />
                </IconButton>

        </Card>
         )}
         </div>;
     })
     }
     </div>
    );
   }
}

ImageRow.propTypes = {
    downloadPdfReportText: PropTypes.string,
    onRemoveImages: PropTypes.func,
    changeImageDescription: PropTypes.func,
    onImageDescChange: PropTypes.func.isRequired,
    msg: PropTypes.func,
};

export default ImageRow;
