import apiBaseUrl from '../apiBaseUrl';

const saveInCompleteEstimateApi = ({productId, profileId, propertyId}, address, answers, riskId, buildingList, estimateID, userId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/saveIncompleteEstimate`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return fetch(url,
        {
            method: 'POST',
            headers,
            body: JSON.stringify({
                answers: answers,
                buildingList: buildingList,
                riskId: riskId,
                propertyId: propertyId,
                address: address,
                estimateId: estimateID,
                userId: userId,
            }),
        }
    );


};

export default saveInCompleteEstimateApi;