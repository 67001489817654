import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const UserGroupDeleteDialog = ({ styles, deleteGroup, handleUserGroupDeleteDialog, handleUserGroupDelete, loggedInUserDetail, deleteError }) => {

    let { message, isSelf, childGroups } = getMessageIsSelfAndChildGroups(deleteGroup, loggedInUserDetail);


    return (
        <Dialog
            className="dialog terms-dialog"
            open={deleteGroup.open}
            maxWidth={'sm'}
            onClose={() => handleUserGroupDeleteDialog(false)}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="userGroup-delete-row">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                <span style={{ color: 'black' }}>  {!deleteError && <div>{message} <br />
                                {!isSelf &&<span>{childGroups}</span>}</div>}
                                   {deleteError} <br></br></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    {!isSelf && !deleteError &&
                        <div style={{ display: 'flex' }}>
                            <CustomFlatButtonContainer className="terms" label="Yes, Delete" onPress={() => handleUserGroupDelete(deleteGroup.deleteUserGroupDetail.id)}
                                type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                                keyboardFocus={true} />
                        </div>
                    }
                    {!deleteError && <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Cancel" onPress={() => handleUserGroupDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>}
                    {deleteError && <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="OK" onPress={() => handleUserGroupDeleteDialog(false, null)}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>}
                </div>
            </DialogActions>
        </Dialog>)
};

UserGroupDeleteDialog.propTypes = {
    deleteGroup: PropTypes.object,
    styles: PropTypes.object.isRequired,
    handleUserGroupDelete: PropTypes.func.isRequired,
    handleUserGroupDeleteDialog: PropTypes.func.isRequired,
};

export default UserGroupDeleteDialog;

function getMessageIsSelfAndChildGroups(deleteGroup, loggedInUserDetail) {
    let { deleteId, deleteGroupName, deleteName } = getVariables(deleteGroup);
    let isSelf = deleteId === loggedInUserDetail.userGroupId ? true : false;
    let message = isSelf ? 'You can not delete own (' + deleteGroupName + ') record' : 'Are you sure you want to delete ' + deleteName + ' from Cordell Commercial Estimator?';
    let childGroups = deleteGroup.deleteUserGroupDetail === null ? '' :
        getChildGroups(deleteGroup);
    return { message, isSelf, childGroups };
}
function getChildGroups(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Team' ? 'All users associated to the group will also be deleted.' :
        getRole(deleteGroup);
}

function getRole(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Division' ? 'All team groups and users associated to the group will also be deleted.' :
        getRole2(deleteGroup);
}

function getRole2(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Company' ? 'All division, team groups and users associated to the group will also be deleted.' :
        'All company, division, team groups and users associated to the group will also be deleted.';
}

function getVariables(deleteGroup) {
    let deleteGroupName = deleteGroup.deleteUserGroupDetail === null ? '' : getGroupName(deleteGroup);

    let deleteName = deleteGroup.deleteUserGroupDetail === null ? '' : deleteGroupName + ' ' + deleteGroup.deleteUserGroupDetail.role;
    let deleteId = deleteGroup.deleteUserGroupDetail === null ? '' : deleteGroup.deleteUserGroupDetail.id;
    return { deleteId, deleteGroupName, deleteName };
}

function getGroupName(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Team' ?
        deleteGroup.deleteUserGroupDetail.teamName : whenRoleEqualsDivision(deleteGroup);
}

function whenRoleEqualsDivision(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Division' ? deleteGroup.deleteUserGroupDetail.divisionName :
        whenRoleEqualsCompany(deleteGroup);
}

function whenRoleEqualsCompany(deleteGroup) {
    return deleteGroup.deleteUserGroupDetail.role === 'Company' ? deleteGroup.deleteUserGroupDetail.companyName : '';
}

