import { connect } from 'react-redux';
import RiskList from '../components/RiskList';
import { bindActionCreators } from 'redux';
import {
    searchEstimateResults, searchEstimateByRiskId, editRisk, updateActivePageNo,
    deleteRisk, riskDetailDeleteDialog, repriceEstimate, estimateDetailDeleteDialog, deleteEstimate, searchEstimateByEstimateId, selectedAllRiskIds,
    estimatePreviewDialog, estimatePreviewClose, emailDownloadReport, getUserList,addRisk,
     removeRisk, removeAllRisk, repriceExport, repriceExportDialog, exportEstimateDetails, resetExport, exportDialog, getRepriceExportStatus,
     deleteMultipleRisks, multipleRisksDeleteDialog
} from '../actions/searchActionCreator';
import {
    fetchProfile,
    setDebug,
    validateProfileAccessControls
} from '../actions/actionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import {trackSegmentPageView} from "../actions/segmentActionsCreator";

export const mapStateToProps = (state, defaultProps) => {
    return {
        ...defaultProps,
        productId: state.productId == null ? getProductId(defaultProps)
            : state.productId,
        profileId: state.profileId == null ?
            getProfileId(defaultProps)
            : state.profileId,
        searchResult: state.searchResult,
        styles: state.profile.styles,
        searchParams: state.searchParams,
        isEditRisk: state.isEditRisk,
        sortParams: state.sortParams,
        pagination: state.pagination,
        activeIndex: state.activeIndex,
        deleteRisk: state.deleteRisk,
        isRiskOrEstimate: state.isRiskOrEstimate,
        deleteEstimate: state.deleteEstimate,
        isEditEstimate: state.isEditEstimate,
        userId: state.userId,
        userList: state.userList,
        loggedInUserDetail: state.loggedInUserDetail,
        pageSize: state.pageSize,
        widthChange: defaultProps.widthChange,
        isEstimateRepriced: state.isEstimateRepriced,
        risks: state.risks,
        isRepriceExport: state.isRepriceExport,
        activeRiskId: state.activeRiskId,
        exportStatus: state.exportStatus,
        isExport: state.isExport,
        repriceInProgress: state.repriceInProgress,
        excludedRisks: state.excludedRisks,
        selectedAllRisk: state.selectedAllRisk,
        action: state.action,
    };
};

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchEstimateResults,
        searchEstimateByRiskId: searchEstimateByRiskId,
        editRiskDetail: editRisk,
        deleteRiskDetail: deleteRisk,
        updateActivePageNo: updateActivePageNo,
        repriceEstimateDetail: repriceEstimate,
        riskDetailDeleteDialog: riskDetailDeleteDialog,
        estimateDetailDeleteDialog: estimateDetailDeleteDialog,
        deleteEstimateDetail: deleteEstimate,
        searchEstimateByEstimateId,
        estimatePreviewDialog: estimatePreviewDialog,
        estimatePreviewClose: estimatePreviewClose,
        emailDownloadReport: emailDownloadReport,
        fetchProfile: fetchProfile,
        setDebug:  setDebug,
        validateProfileAccessControls: validateProfileAccessControls,
        getUserList: getUserList,
        addRisk:addRisk,
        removeRisk:removeRisk,
        removeAllRisk:removeAllRisk,
        repriceExport:repriceExport,
        repriceExportDialog:repriceExportDialog,
        exportEstimateDetails:exportEstimateDetails,
        resetExport:resetExport,
        exportDialog:exportDialog,
        selectedAllRiskIds:selectedAllRiskIds,
        getRepriceExportStatus: getRepriceExportStatus,
        deleteMultipleRisks: deleteMultipleRisks,
        multipleRisksDeleteDialog: multipleRisksDeleteDialog,
        trackSegmentPageView: trackSegmentPageView,
    }, dispatch);
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...dispatchProps,
        ...stateProps,
        searchEstimateResults: () => {
            dispatchProps.searchEstimateResults({productId : stateProps.productId, profileId : stateProps.profileId, searchBy : stateProps.searchParams.searchBy, searchText : stateProps.searchParams.searchText}
                , stateProps.searchParams.fromDate, stateProps.searchParams.toDate, stateProps.searchParams.searchDateOption, stateProps.sortParams,
                stateProps.pagination.activePage, stateProps.pageSize);
        },
        searchEstimateByRiskId: (riskId, index) => dispatchProps.searchEstimateByRiskId(riskId, stateProps.productId, stateProps.profileId, index),
        editRiskDetail: (productId, profileId, riskDetail, riskId) => {
            dispatchProps.editRiskDetail(productId, profileId, riskDetail, riskId);
        },
        deleteRiskDetail: (productId, profileId, riskId) => {
            dispatchProps.deleteRiskDetail(productId, profileId, riskId);
        },
        updateActivePageNo: (pageNo) => dispatchProps.updateActivePageNo(pageNo),
        repriceEstimateDetail: (riskId, estimateId, index) => {
            dispatchProps.repriceEstimateDetail(stateProps.productId, stateProps.profileId, riskId, estimateId, index);
        },
        riskDetailDeleteDialog: (closeValue, riskDetail) => dispatchProps.riskDetailDeleteDialog(closeValue, riskDetail),
        estimateDetailDeleteDialog: (value, estimateDetail) => dispatchProps.estimateDetailDeleteDialog(value, estimateDetail),
        deleteEstimateDetail: (productId, profileId, estimateId, estimatePrice) => dispatchProps.deleteEstimateDetail(productId, profileId, estimateId, estimatePrice),
        searchEstimateByEstimate: dispatchProps.searchEstimateByEstimateId,
        estimatePreviewDialog: (value, riskId, index) => dispatchProps.estimatePreviewDialog(value, riskId, stateProps.productId, stateProps.profileId, index),
        estimatePreviewClose: () => dispatchProps.estimatePreviewClose(),
        emailDownloadReport: (riskId, estimateId, estimatePrice) => dispatchProps.emailDownloadReport(stateProps.productId, stateProps.profileId, riskId, estimateId, estimatePrice),
        validateProfile: () => {
            dispatchProps.fetchProfile(stateProps.productId, stateProps.profileId, stateProps.token, stateProps.partner);
        },
        setDebug: () => {
            dispatchProps.setDebug(stateProps.debug);
        },
        setValidateProfile: () => {
            dispatchProps.validateProfileAccessControls(stateProps.mode);
        },
        getUserList: (id) =>   {dispatchProps.getUserList(stateProps.productId, stateProps.profileId, id); },

        addRisk: (id) => {
            dispatchProps.addRisk(id);
        },
        removeRisk: (id) => {
            dispatchProps.removeRisk(id);
        },
        removeAllRisk:()=>{
            dispatchProps.removeAllRisk();
        },
        repriceExport:() => {
            dispatchProps.repriceExport();
        },
        repriceExportDialog:(proceedRepriceExport) => {
            dispatchProps.repriceExportDialog(proceedRepriceExport);
        },
        exportEstimateDetails:(risks)=>{
            dispatchProps.exportEstimateDetails(stateProps.productId, stateProps.profileId, risks);
        },
        deleteMultipleRisks:(risks)=>{
            dispatchProps.deleteMultipleRisks(stateProps.productId, stateProps.profileId, risks);
        },
        multipleRisksDeleteDialog: (closeValue, riskIds) => dispatchProps.multipleRisksDeleteDialog(closeValue, riskIds),
        resetExport:()=>{
            dispatchProps.resetExport();
        },
        exportDialog:() =>{
            dispatchProps.exportDialog();
        },
        selectedAllRiskIds:() => {
            dispatchProps.selectedAllRiskIds();
        },
        getRepriceExportStatus:() => {
            dispatchProps.getRepriceExportStatus(stateProps.productId, stateProps.profileId, stateProps.loggedInUserDetail.companyGroupId);
        },
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Estimate Management Page', ownProps.location.pathname, stateProps.sessionTrackId, "EstimateManagementPage", stateProps.partner);
               dispatchProps.trackSegmentPageView("Estimate Management Page", pageDetails);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RiskList);
function getProfileId(defaultProps) {
    return defaultProps.profileId == null ?
        defaultProps.match.params.profileId : defaultProps.profileId;
}

function getProductId(defaultProps) {
    return defaultProps.productId == null ?
        defaultProps.match.params.productId : defaultProps.productId;
}

