import AnalyticsAttributes from "./AnalyticsAttributes";

class AnalyticsReport extends AnalyticsAttributes {
    constructor(productId, profileId, propertyId, sessionId, status, description, {action, estimateId, PartnerId}) {
        super(productId, profileId, propertyId, sessionId, status, description, PartnerId);
        this.Action = action;
        this.EstimateId = estimateId;
    }
}

export default AnalyticsReport;