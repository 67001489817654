import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ReturnToEstimateDialog = ({ styles, editEstimateDialog, handleReturnToEstimate, handleCancelAnswerUpdate, newEstimateDialog, estimateID }) => {

    let message = newEstimateDialog && estimateID === null ? 'You may click the save button to save an incomplete estimate or complete the estimate to save the changes.'
        : 'You must complete the estimate to save the changes.';

    return (
        <Dialog
            className="dialog terms-dialog"
            open={editEstimateDialog || newEstimateDialog}
            maxWidth={'sm'}
            onClose={() => handleReturnToEstimate()}
        >
            <DialogContent >
                <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily }}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr className="return-to-estimate-dialog">
                                <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    <span style={{ color: 'black' }}>
                                        This estimate contains unsaved changes. Are you sure you want to proceed without saving the changes?
                                    </span><br></br><br></br>
                                    {message} Click the rebuild cost tab to complete the estimate and save the changes. <br></br>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <div className="terms-dialog-agree">
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Return to estimate" onPress={() => handleReturnToEstimate()}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <CustomFlatButtonContainer className="terms" label="Proceed without saving" onPress={() => handleCancelAnswerUpdate()}
                            type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                            keyboardFocus={true} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>)
};

ReturnToEstimateDialog.propTypes = {
    styles: PropTypes.object.isRequired,
    handleReturnToEstimate: PropTypes.func.isRequired,
};

export default ReturnToEstimateDialog;