import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { inputStyle } from '../style/styles';
import SectionHeader from './SectionHeader';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import UrlConstants from '../constants/UrlConstants';
import { filter, sortBy } from 'lodash';
import SpinnerContainer from '../containers/SpinnerContainer';

class AddUserGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            isCompany: false,
            isDivision: false,
            isTeam: true,
            errorMsg: null,
            successMsg: null,
            errorCompanyMsg: null,
            errorDivisionMsg: null,
            selectedFile: null, 
        };
        this.fileInput = React.createRef();
    }

    componentWillMount() {
        const { getUserGroups, profileId, productId, getRoleType, clearData, loggedInUserDetail, getUserGroupRole, onLoad } = this.props;
        onLoad();
        getUserGroups(productId, profileId);
        getRoleType(productId, profileId);
        clearData();
        getUserGroupRole(productId, profileId, loggedInUserDetail.userGroupId);
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
    }

    handleUserGroup = (event) => {
        const { setUserGroupOption, clearData, isEditUserGroup } = this.props;
        if (!isEditUserGroup) {
            clearData();
        }
        setUserGroupOption(event.target.value);
        if (event.target.value === 'Company') {
            this.setState({ isCompany: true, isDivision: false, isTeam: false });
        } else if (event.target.value === 'Division') {
            this.setState({ isCompany: false, isDivision: true, isTeam: false });
        } else {
            this.setState({ isCompany: false, isDivision: false, isTeam: true });
        }
        this.setState({ successMsg: null, errorMsg: null });
    }

    handleFileInput = (event) => { 
        this.setState({selectedFile: event.target.files[0]});
        const {updateUserGroupLogo, productId, profileId, userGroupDetail, isEditUserGroup, updateLogo} = this.props;
        let id = userGroupDetail.companyId; 
        if(event.target.files[0].size > 1000000 || event.target.files[0].type === "" 
        || event.target.files[0].type === null || 
            !"image/jpg,image/gif,image/bmp,image/png,image/tif,image/jpeg,image/tiff".includes(event.target.files[0].type)) {
            this.setState({ selectedFile: null,
                errorMsg: "Please upload a file under 1MB in the following format - jpg, gif, bmp, png, tif." });
            updateLogo(null);
        } else {
            this.setState({ errorMsg: null });
             if(userGroupDetail.userGroupOption === "Division") {
                id = userGroupDetail.divisionId;
            } 
            if(isEditUserGroup) {
                updateUserGroupLogo(productId, profileId, id, event.target.files[0]);
            } else {
                updateLogo(event.target.files[0].name);
            }
        }
    }
    onChangeCompany = (event) => {
        const { onChangeCompanyName } = this.props;
        onChangeCompanyName(event.target.value);
    }

    onChangeTeam = (event) => {
        const { onChangeTeamName } = this.props;
        onChangeTeamName(event.target.value);
    }

    onChangeDivision = (event) => {
        const { onChangeDivisionName } = this.props;
        onChangeDivisionName(event.target.value);
    }
    onChangeCompanyDisc = (event) => {
        const { onChangeCompanyDisclaimer } = this.props;
        onChangeCompanyDisclaimer(event.target.value);
    }
    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    onCompanySelectionChange = (value) => {
        const { updateCompanySelection } = this.props;
        updateCompanySelection(value);

    }
    onGroupPolicySelectionChange = (value) => {
        const { updateGroupPolicySelection } = this.props;
        updateGroupPolicySelection(value);

    }
    onDivisionSelectionChange = (value) => {
        const { updateDivisionSelection } = this.props;
        updateDivisionSelection(value);
    }
    onBackToUserGroup = () => {
        const { onBackToUserGroup } = this.props;
        onBackToUserGroup();
    }

    removeLogo = () => {
        const { deleteUserGroupLogo,productId, profileId, userGroupDetail } = this.props;
        let id = userGroupDetail.companyId;
        if(userGroupDetail.userGroupOption === "Division") {
            id = userGroupDetail.divisionId;
        }
        deleteUserGroupLogo(productId, profileId, id);
    }

    downloadLogo = () => {
        const { userGroupDetail, downloadImage } = this.props;
        let logoName = userGroupDetail.logo;
        if (logoName != null && logoName.includes("/")) {
            logoName = logoName.substr(logoName.lastIndexOf('/') + 1);
        }
        downloadImage(userGroupDetail.logo, logoName);
    }

    onAddOrUpdateUserGroupRecord = () => {
        const { addUserGroup, userGroupOption, companyName, teamName, divisionName, disclaimer, isActive, productId, profileId, selectedDivision,
            selectedCompany, isEditUserGroup, userGroupDetail, loggedInUserDetail } = this.props;
        let footerText = null;
        let parentUserGroupId = null;
        if (userGroupOption === "Team") {
            parentUserGroupId = this.stateWhenUserGroupOptionIsTeam({parentUserGroupId, selectedDivision, teamName, addUserGroup, productId, profileId, userGroupOption, companyName}, divisionName, isActive, footerText, isEditUserGroup, userGroupDetail);
        } else if (userGroupOption === "Company") {
            ({ parentUserGroupId, footerText } = this.stateWhenUserGroupOptionIsCompany({parentUserGroupId, loggedInUserDetail, footerText, disclaimer, companyName, addUserGroup, productId, profileId}, userGroupOption, teamName, divisionName, isActive, isEditUserGroup, userGroupDetail));
        } else {
            parentUserGroupId = selectedCompany !== null ? selectedCompany.company : null;
            footerText = disclaimer;
            if (parentUserGroupId === null) {
                this.setState({ errorMsg: "Please enter Company." });
            } else if (divisionName === null || divisionName === "") {
                this.setState({ errorMsg: "Please enter Division Name." });
            } else if (footerText === null || footerText === "" || footerText.trim() === "") {
                this.setState({ errorMsg: "Please enter Division Disclaimer." });
            } else {
                this.setState({ errorMsg: null });
                addUserGroup({productId, profileId, option : userGroupOption, teamName, companyName, divisionName}, isActive, footerText, parentUserGroupId, isEditUserGroup, userGroupDetail, this.state.selectedFile);
                this.setState({ successMsg: "Division saved successfully." });
            }
        }
    };

    stateWhenUserGroupOptionIsCompany({parentUserGroupId, loggedInUserDetail, footerText, disclaimer, companyName, addUserGroup, productId, profileId}, userGroupOption, teamName, divisionName, isActive, isEditUserGroup, userGroupDetail) {
        parentUserGroupId = loggedInUserDetail !== null ? loggedInUserDetail.userGroupId : parentUserGroupId;
        let footerTextVal = footerText;
        footerTextVal = disclaimer;
        if (companyName === null || companyName === "") {
            this.setState({ errorMsg: "Please enter Company Name." });
        } else if (footerTextVal === null || footerTextVal === "" || footerTextVal.trim() === "") {
            this.setState({ errorMsg: "Please enter Company Disclaimer." });
        } else {
            this.setState({ errorMsg: null });
            addUserGroup({productId, profileId, option : userGroupOption, teamName, companyName, divisionName}, isActive, footerTextVal, parentUserGroupId, isEditUserGroup, userGroupDetail, this.state.selectedFile);
            this.setState({ successMsg: "Company saved successfully." });
        }
        return { parentUserGroupId, footerTextVal };
    }

    stateWhenUserGroupOptionIsTeam({parentUserGroupId, selectedDivision, teamName, addUserGroup, productId, profileId, userGroupOption, companyName}, divisionName, isActive, footerText, isEditUserGroup, userGroupDetail) {
        let parentUserGroupIdVal = parentUserGroupId;
        parentUserGroupIdVal = selectedDivision !== null ? selectedDivision.division : null;
        if (parentUserGroupIdVal === null) {
            this.setState({ errorMsg: "Please enter Company or Division." });
        } else if (teamName === null || teamName === "") {
            this.setState({ errorMsg: "Please enter Team." });
        } else {
            this.setState({ errorMsg: null });
            addUserGroup({productId, profileId, option : userGroupOption, teamName, companyName, divisionName}, isActive, footerText, parentUserGroupIdVal, isEditUserGroup, userGroupDetail, null);
            this.setState({ successMsg: "Team saved successfully." });
        }
        return parentUserGroupIdVal;
    }

    render() {
        const { styles, isEditUserGroup, userGroupOption, loggedInUserGroupRole, loggedInUserDetail, userGroupDetail } = this.props;
        const { companyUserGroups, divisionUserGroups } = this.newMethod_4();
        let fontFamilyName = styles.globalFontFamily;
        const { pageHeader, error, success, buttonText } = this.newMethod_1(isEditUserGroup);
        let isTeamDisplay = false;
        let isCompanyDisplay = false;
        let isDivisionDisplay = false;
        let logoName = this.props.userGroupDetail.logo;
        logoName = newFunction22(logoName);
        let disclaimerLabel = "Company Disclaimer";
        ({ isTeamDisplay, isCompanyDisplay, isDivisionDisplay, disclaimerLabel } = this.newMethod_2(isEditUserGroup, userGroupOption, isTeamDisplay, isCompanyDisplay, isDivisionDisplay, disclaimerLabel));
        let { companyCheck, divisionCheck } = newFunction2(loggedInUserDetail, userGroupDetail, isEditUserGroup);
        let isSystemAdmin = false;
        let isCompanyAdmin = false;
        let isDivisionAdmin = false;
        ({ isSystemAdmin, isCompanyAdmin, isDivisionAdmin } = newFunction3(loggedInUserGroupRole, isSystemAdmin, isCompanyAdmin, isDivisionAdmin));
        return (
            <div className='user-group-management'>
                <Card className='group-card'>
                    <SectionHeader name={pageHeader} color={styles.sectionTileColour} styles={styles} />
                    {newFunction4(isEditUserGroup, userGroupOption, loggedInUserDetail)}
                    <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="userGroup" className="group-detail-label">Group Level </label>
                        <div className="group-detail-field">
                            <FormControl>
                                {this.newMethod_3(fontFamilyName, isEditUserGroup, isSystemAdmin, companyCheck, isCompanyAdmin, divisionCheck, isDivisionAdmin)}
                            </FormControl>

                        </div>
                    </CardText>
                    {isDivisionDisplay && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="group-share" className="group-detail-label">Group Share Policy</label>
                        <FormControl>
                            {this.newMethod_44(fontFamilyName)}
                        </FormControl>
                    </CardText>}
                    {this.newMethod_5(isTeamDisplay, isDivisionDisplay, fontFamilyName, isEditUserGroup, companyUserGroups)}
                    <div className="user-validation"> {this.state.errorCompanyMsg}</div>
                    {(isTeamDisplay) && this.newMethod_6(fontFamilyName, isEditUserGroup, loggedInUserDetail, divisionUserGroups)}
                    <div className="user-validation"> {this.state.errorDivisionMsg}</div>
                    {(isTeamDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="team" className="group-detail-label">Team </label>
                        {this.newMethod_66(fontFamilyName)}
                    </CardText>}
                    {(isCompanyDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="companyName" className="group-detail-label">Company </label>
                        <div className="group-detail-field">
                            <TextField
                                className="group-field"
                                id="company-name"
                                type="text"
                                fullWidth={true}
                                value={this.getValue()}
                                onChange={(event, newValue) => {
                                    this.onChangeCompany(event);
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type a new company name"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>}

                    {(isDivisionDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="divisionName" className="group-detail-label">Division </label>
                        <div className="group-detail-field">
                            <TextField
                                className="group-field"
                                id="division-name"
                                type="text"
                                fullWidth={true}
                                value={this.props.divisionName === null ? "" : this.props.divisionName}
                                onChange={(event, newValue) => {
                                    this.onChangeDivision(event);
                                }}
                                floatingLabelFixed={true}
                                placeholder="Type a new division name"
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>}
                    {(isCompanyDisplay || isDivisionDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="logo" className="group-detail-label">Logo </label>
                        <div className="group-detail-field">
                            <input  onChange={this.handleFileInput}
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span" onClick={e =>
                                    this.fileInput.current && this.fileInput.current.click()}>
                                    Upload Logo
                          </Button>
                            </label>
                            {logoName &&  <div className="div-upload">
                                <label className="logoLabel">{logoName} </label>
                                {logoName.includes("uploaded") && <Button className="delete-logo" onClick={this.removeLogo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                              </svg>
                                </Button>}
                                <Button className="download-logo" onClick ={this.downloadLogo}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                </Button>  </div>}

                        </div>
                    </CardText>}


                    {(isCompanyDisplay || isDivisionDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
                        <label id="disclaimer" className="group-detail-label">{disclaimerLabel} </label>
                        <div className="group-detail-field multiline-input">
                            <TextField
                                className="group-field multiInput"
                                id="company-disclaimer"
                                type="text"
                                fullWidth={true}
                                value={this.props.disclaimer === null ? "" : this.props.disclaimer}
                                onChange={(event, newValue) => {
                                    this.onChangeCompanyDisc(event);
                                }}
                                multiline
                                rows={6}
                                floatingLabelFixed={true}
                                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }}
                            />
                        </div>
                    </CardText>}
                    <CardText>
                        {error}
                        {this.state.errorMsg === null ? success : ""}
                    </CardText>
                    <CardText>
                        <Button id="create" className="action" variant="outlined"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onAddOrUpdateUserGroupRecord}
                        >{buttonText} <SpinnerContainer />
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                         <Button className="action" variant="outlined" id="back"
                            rounded="true"
                            size="small"
                            tabIndex={0}
                            disableTouchRipple={true}
                            disableFocusRipple={false}
                            onClick={this.onBackToUserGroup}
                        >Back
                        </Button>
                    </CardText>
                </Card>

            </div>
        );
    }

    getValue() {
        return this.props.companyName === null ? "" : this.props.companyName;
    }

    newMethod_66(fontFamilyName) {
        return <div className="group-detail-field">
            <TextField
                className="group-field"
                id="teamName"
                type="text"
                fullWidth={true}
                value={this.props.teamName === null ? "" : this.props.teamName}
                onChange={(event, newValue) => {
                    this.onChangeTeam(event);
                } }
                floatingLabelFixed={true}
                placeholder="Type a new team name"
                inputStyle={{ ...inputStyle, fontFamily: fontFamilyName }} />
        </div>;
    }

    newMethod_6(fontFamilyName, isEditUserGroup, loggedInUserDetail, divisionUserGroups) {
        return <CardText className="group-detail-form" style={{ display: 'flex' }}>
            <label id="division" className="group-detail-label">Division </label>
            <FormControl>
                <Select id="division-select" className="select-box"
                    style={{ fontFamily: fontFamilyName }}
                    value={this.props.selectedDivision !== null ? this.props.selectedDivision.division : ""}
                    tabIndex={1}
                    disabled={isEditUserGroup && loggedInUserDetail.roleTypeId >= UrlConstants.DIVISION_ROLE_TYPE_ID ? true : false}
                    onChange={(event) => {
                        this.onDivisionSelectionChange({ division: event.target.value });
                    } }
                >
                    {this.props.selectedCompany !== null && divisionUserGroups.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                        <MenuItem key={option.id}
                            value={option.id}>
                            {option.divisionName}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </CardText>;
    }

    newMethod_5(isTeamDisplay, isDivisionDisplay, fontFamilyName, isEditUserGroup, companyUserGroups) {
        return (isTeamDisplay || isDivisionDisplay) && <CardText className="group-detail-form" style={{ display: 'flex' }}>
            <label id="company" className="group-detail-label">Company</label>
            <FormControl>
                <Select id="company-select" className="select-box"
                    style={{ fontFamily: fontFamilyName }}
                    value={this.props.selectedCompany !== null
                        ? this.props.selectedCompany.company : ""}
                    tabIndex={1}
                    disabled={isEditUserGroup ? true : false}
                    onChange={(event) => {
                        this.onCompanySelectionChange({ company: event.target.value });
                    } }
                >
                    {companyUserGroups.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                        <MenuItem key={option.id}
                            value={option.id}>
                            {option.companyName}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </CardText>;
    }

    newMethod_44(fontFamilyName) {
        return <Select id="group-share-select" className="select-box"
            style={{ fontFamily: fontFamilyName }}
            value={this.props.userGroupDetail.groupSharePolicy !== null && this.props.userGroupDetail.groupSharePolicy !== ""
                ? this.props.userGroupDetail.groupSharePolicy : "0"}
            tabIndex={1}
            onChange={(event) => {
                this.onGroupPolicySelectionChange(event.target.value);
            } }>
            <MenuItem key="0"
                value="0">Share estimates with admins only</MenuItem>
            <MenuItem key="1"
                value="1">Share estimates within Division</MenuItem>
            <MenuItem key="2"
                value="2">Share estimates within Team</MenuItem>
        </Select>;
    }

    newMethod_3(fontFamilyName, isEditUserGroup, isSystemAdmin, companyCheck, isCompanyAdmin, divisionCheck, isDivisionAdmin) {
        return <Select id="user-group-select" className="select-box"
            style={{ fontFamily: fontFamilyName }}
            value={this.props.userGroupOption}
            onChange={this.handleUserGroup}
            disabled={isEditUserGroup ? true : false}
            tabIndex={1}>
            {(isSystemAdmin || (isEditUserGroup && companyCheck)) &&
                <MenuItem value="Company">Company</MenuItem>}
            {(isCompanyAdmin || isSystemAdmin || divisionCheck) &&
                <MenuItem value="Division">Division</MenuItem>}
            {(isDivisionAdmin || isCompanyAdmin || isSystemAdmin || isEditUserGroup) &&
                <MenuItem value="Team">Team</MenuItem>}
        </Select>;
    }

    newMethod_2(isEditUserGroup, userGroupOption, isTeamDisplay, isCompanyDisplay, isDivisionDisplay, disclaimerLabel) {
        if (isEditUserGroup) {
            if (userGroupOption === "Team") {
                isTeamDisplay = true;
            }
            if (userGroupOption === "Company") {
                isCompanyDisplay = true;
            }
            if (userGroupOption === "Division") {
                isDivisionDisplay = true;
                disclaimerLabel = "Division Disclaimer";
            }
        } else {
            if (this.state.isTeam) {
                isTeamDisplay = true;
            }
            if (this.state.isDivision) {
                isDivisionDisplay = true;
                disclaimerLabel = "Division Disclaimer";
            }
            if (this.state.isCompany) {
                isCompanyDisplay = true;
            }
        }
        return { isTeamDisplay, isCompanyDisplay, isDivisionDisplay, disclaimerLabel };
    }

    newMethod_1(isEditUserGroup) {
        const error = this.state.errorMsg !== null ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'red' }}> *{this.state.errorMsg}</div> : null;
        const success = this.state.successMsg !== null ? <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'black', fontWeight: 'bold' }}> {this.state.successMsg}</div> : null;
        const pageHeader = isEditUserGroup ? "Edit Group" : "Add Group";
        const buttonText = isEditUserGroup ? "Update" : "Create";
        return { pageHeader, error, success, buttonText };
    }

    newMethod_4() {
        const companyGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID);
        });
        const companyUserGroups = sortBy(companyGroups, (company) => {
            return company.companyName.toLowerCase();
        });
        const divisionGroups = filter(this.props.userGroups.data, (userGroup) => {
            return (userGroup.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID &&
                ((this.props.selectedCompany != null && userGroup.parentUserGroupId === this.props.selectedCompany.company)
                    || (this.props.selectedCompany === null)));
        });

        const divisionUserGroups = sortBy(divisionGroups, (division) => {
            return division.divisionName.toLowerCase();
        });
        return { companyUserGroups, divisionUserGroups };
    }
}

AddUserGroup.propTypes = {
    styles: PropTypes.object.isRequired,
    setUserGroupOption: PropTypes.func,
    onChangeCompanyName: PropTypes.func,
    onChangeCompanyDisclaimer: PropTypes.func,
    onChangeTeamName: PropTypes.func,
    onChangeDivisionName: PropTypes.func,

};

export default AddUserGroup;

function newFunction4(isEditUserGroup, userGroupOption, loggedInUserDetail) {
    return isEditUserGroup && (userGroupOption === "Team") &&
        (loggedInUserDetail.roleTypeId === UrlConstants.SYSTEM_ROLE_TYPE_ID || loggedInUserDetail.roleTypeId ===
            UrlConstants.COMPANY_ROLE_TYPE_ID) &&
        <CardText className="group-detail-form" style={{ display: 'flex' }}>
            <div className="error" style={{ marginTop: '0em', textAlign: 'left', color: 'black' }}>
                Note: To move the Team, select the new Division in the drop downs.
            </div>
        </CardText>;
}

function newFunction3(loggedInUserGroupRole, isSystemAdmin, isCompanyAdmin, isDivisionAdmin) {
    if (loggedInUserGroupRole === "SYSTEM") {
        isSystemAdmin = true;
    } else if (loggedInUserGroupRole === "COMPANY") {
        isCompanyAdmin = true;
    } else if (loggedInUserGroupRole === "DIVISION") {
        isDivisionAdmin = true;
    }
    return { isSystemAdmin, isCompanyAdmin, isDivisionAdmin };
}

function newFunction2(loggedInUserDetail, userGroupDetail, isEditUserGroup) {
    let companyCheck = (loggedInUserDetail.roleTypeId < UrlConstants.COMPANY_ROLE_TYPE_ID
        || (loggedInUserDetail.roleTypeId === UrlConstants.COMPANY_ROLE_TYPE_ID &&
            (userGroupDetail.companyId === loggedInUserDetail.userGroupId || userGroupDetail.divisionId === loggedInUserDetail.userGroupId))) ? true : false;
    let divisionCheck = isEditUserGroup && (loggedInUserDetail.roleTypeId < UrlConstants.DIVISION_ROLE_TYPE_ID
        || (loggedInUserDetail.roleTypeId === UrlConstants.DIVISION_ROLE_TYPE_ID &&
            userGroupDetail.divisionId === loggedInUserDetail.userGroupId)) ? true : false;
    return { companyCheck, divisionCheck };
}

function newFunction22(logoName) {
    if (logoName != null && logoName.includes("/")) {
        logoName = logoName.substr(logoName.lastIndexOf('/') + 1);
        logoName = logoName + " is uploaded";
    }
    return logoName;
}
