import WhatNextIAG from '../components/WhatNextIAG';
import { connect } from 'react-redux';
import { trackCallToAction, contactUsAction, changeEmail, emailReport, callToActionButton } from '../actions/actionCreator';


export const mapStateToProps = (state) => {
    return {
        report: state.report,
        recipient: state.recipient,
        display: {
            userName: state.profile.display.userName,
            email: state.profile.display.email,
            downloadReport: state.profile.display.downloadReport,
            displayContactUs: state.profile.display.displayContactUs,
            displayCTAButton1: state.profile.display.displayCTAButton1,

        },
        text: {
            callToAction: state.profile.text.callToAction,
            userName: state.profile.text.userName,
            email: state.profile.text.email,
            downloadPdfReport: state.profile.text.downloadPdfReport,
            whatNextSectionTitle: state.profile.text.whatNextSectionTitle,
            whatNextSectionText: state.profile.text.whatNextSectionText,
            callToActionSectionTemplate: state.profile.text.callToActionSectionTemplate,
            callToActionText1Header: state.profile.text.calltoActionText1Header,
            callToActionText1: state.profile.text.calltoActionText1,
            callToActionText2Header: state.profile.text.calltoActionText2Header,
            callToActionText2: state.profile.text.calltoActionText2,
            ctaButton1Header: state.profile.text.ctaButton1Header,
            ctaButton1Label: state.profile.text.ctaButton1Label,
            ctaButton1Text: state.profile.text.ctaButton1Text,
            downloadPdfReportBodyText: state.profile.text.downloadPdfReportBodyText,
            contactUsButtonLabel: state.profile.text.contactUsButtonLabel

        },
        styles: state.profile.styles,
        callToActionText: state.profile.text.callToAction,
        downloadPdfReportText: state.profile.text.downloadPdfReport,
        returnToQuote: state.returnToQuote,
        address: state.addressChosen.suggestion,
        profileId: state.profileId,
        result: state.result.data,
        contactUsUrl: state.profile.contactUsUrl,
        showNote: state.showNote,
        ctaButton1Url: state.profile.ctaButton1Url,
        partnerId: state.partnerId,
        widthChange: state.widthChange,
        returnUrls: state.profile.returnUrls,
    };
};

export const mapDispatchToProps = (dispatch, emailReportAction = emailReport) => {
    return {
        callToActionClicked: (event) => dispatch(trackCallToAction(event)),
        onCalltoActionButton: (ctaButton1Url) => dispatch(callToActionButton(ctaButton1Url)),
        onContactUs: (contactUsUrl, profileId, partnerId) => dispatch(contactUsAction(contactUsUrl, profileId, partnerId)),
        onEmailChange: (email) => dispatch(changeEmail(email)),
        onEmailReport: () => dispatch(emailReportAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatNextIAG);
