class AnalyticsPage {
   constructor(ProductId, ProfileId, Title, Url, SessionTrackId, trackingPath, partnerId){
       this.ProductId = ProductId;
       this.ProfileId = ProfileId;
       this.Title = Title;
       this.Url = Url;
       this.SessionTrackId = SessionTrackId;
       this.trackingPath = trackingPath;
       this.PartnerId = partnerId;
   }

}

export default AnalyticsPage;