import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import {returnToQuoteText, returnToQuoteTitle} from '../style/styles';

const ReturnUrlButton = ({styles, text, quoteId, onReturn}) => {

    let buttonLabel, buttonHeader, buttonHelpText;
    if (quoteId){
        buttonLabel = text.returnToQuoteButtonLabel;
        buttonHeader = text.returnToQuoteHeader;
        buttonHelpText = text.returnToQuoteText;
    } else {
        buttonLabel = text.goToQuoteButtonLabel;
        buttonHeader = text.goToQuoteHeader;
        buttonHelpText = text.goToQuoteText;
    }

    const button = <CustomFlatButtonContainer
        className="input what-next-button"
        id="return-url-button"
        label={buttonLabel}
        type="primary"
        keyboardFocus={false}
        onPress={onReturn}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
        }}
    />;

    return (
        <div className="mainDiv">
            <div className="returnToQuote-Header" style={{...returnToQuoteTitle, fontFamily: styles.globalFontFamily}}>
                <strong>{buttonHeader}</strong>
            </div>
            <div style={{...returnToQuoteText, fontFamily: styles.globalFontFamily}} 
            dangerouslySetInnerHTML={{ __html: buttonHelpText }}></div>
            <div style={{
                paddingBottom: '1em'
            }}>
                {button}
            </div>
        </div>
    );
};

ReturnUrlButton.propTypes = {
    styles: PropTypes.object.isRequired,
};

export default ReturnUrlButton;