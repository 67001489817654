export const themeStyle = {
    palette: {
        borderColor: '#bebebe',
        textColor: '#4a4a4a',
        primary1Color: '#233447',
        alternateTextColor: '#ffffff',
    },
    radioButton: {
        borderColor: '#4990e2',
    },
    fontFamily: 'Open Sans',
};

export const primaryButtonStyle = {
    hoverColor: '#4990e2',
    backgroundColor: '#4990e2',
    style: {
        padding: '0 1rem',
        height: 'auto',
        maxWidth: '24rem',
    },
    labelStyle: {
        color: 'white',
        width: 'auto',
        fontSize: '16px',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
    },
    disableTouchRipple: true,
    disableFocusRipple: true,
};

export const secondaryButtonStyle = {
    hoverColor: '#4990e2',
    backgroundColor: '#ffffff',
    style: {
        padding: '0 1rem',
        height: 'auto',
        maxWidth: '24rem',
    },
    labelStyle: {
        width: 'auto',
        fontSize: '16px',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
    },
    disableTouchRipple: true,
    disableFocusRipple: true,
};

export const textStyle = {
    color: '#424242',
};

export const textOnImage = {
    color: '#ffffff',
};

export const inputStyle = {
    color: '#424242',
    border: '1px solid #bebebe',
    height: '40px',
    borderRadius: '2px',
    fontSize: '1rem',
};

export const titleStyle = {
    color: 'rgba(0, 0, 0, 0.3)',
    top: '20px',
    lineHeight: '0px',
    fontSize: '1rem',
};

export const validationErrorStyle = {
    lineHeight: '24px',
    width: '400px',
    borderColor: 'red',
    fontSize: '0.875rem',
};

export const moreInformationLinkColor = "#4a90e2";

export const returnToQuoteText = {
    fontSize: '1rem',
    top: '20px',
    paddingBottom: '0.6em',
};

export const returnToQuoteTitle = {
    top: '20px',
    fontSize: '16px',
    paddingBottom: '0.6em',
};

export const contactUsTitle = {
    top: '20px',
    fontSize: '16px',
    paddingBottom: '0.6em',
};

export const contactUsText = {
    color: 'rgb(151, 151, 151)',
    fontSize: '16px',
    top: '20px',
    paddingBottom: '0.6em',
};

export const callToActionTitleStyle = {
    top: '1rem',
    paddingBottom: '0.6em',
};

export const callToActionTextStyle = {
    fontSize: '1rem',
    top: '20px',
    paddingBottom: '0.6em',
};

export const callToActionButton1Title = {
    top: '20px',
    fontSize: '16px',
    paddingBottom: '0.6em',
};

export const callToActionButton1Text = {
    color: 'rgb(151, 151, 151)',
    fontSize: '16px',
    top: '20px',
    paddingBottom: '0.6em',
};

export const downloadButtonStyle = {
    color: 'rgb(151, 151, 151)',
    fontSize: '16px',
    top: '20px',
    paddingBottom: '0.6em',
};