import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import { Button } from '@material-ui/core';
import SearchRisk from './SearchRisk';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import history from '../util/history';
import CCEHeader from './CCEHeader';
import FooterContainer from '../containers/FooterContainer';
import RiskListContainer from '../containers/RiskListContainer';
import CCELogOutDialog from './CCELogOutDialog';
import LoginPageContainer from '../containers/LoginPageContainer';
import configLocal from '../commercialConfig';
import 'url-search-params-polyfill';
import UrlConstants from '../constants/UrlConstants';

class CCESearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            widthChange: window.innerWidth,
            open: false,
            toggleMenu: false,
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.addEventListener('click', this.handleMenuClose, true);
        const { resetAddEditEstimate } = this.props;
        resetAddEditEstimate();
    }

    state = { logout: false };

    handleToggleMenu = () => {
        this.setState({ toggleMenu: !this.state.toggleMenu });
    };

    handleLogout = () => {
        this.setState({ toggleMenu: false });
        this.setState({ logout: true });
        const { location } = this.props;
        const queryParams = new URLSearchParams(location)
        if (queryParams.has('logout')) {
            queryParams.delete('logout')
            history.replace({
                search: queryParams.toString(),
            });
        }
        return false;
    };

    onLogoutSearchCancel = () => {
        this.setState({ logout: false });
    };

    onLogoutSearchConfirm = () => {
        this.setState({ logout: false });
        const { onResetTokenDetails, productId, profileId, onLogout, restoreURL, homePath } = this.props;
        restoreURL();
        window.localStorage.setItem('productId', productId);
        window.localStorage.setItem('profileId', profileId);
        onLogout("Logged out successfully.", productId, profileId);
        onResetTokenDetails();
        setTimeout(function () {
            window.location.replace(window.location.origin + '/#' + homePath);
        }, 2000);
        this.onClose();
    };
    onClose() {
        var win = window.open(`${configLocal.CLAUD_LOGOUT_URL}`);
        setTimeout(function () {
            win.close();
        }, 900);
    }


    handleMenuClose = event => {
        this.setState({ open: false });
        if (!this.state.logout) {
            this.setState({ toggleMenu: false });
        }
    }

    handleWindowSizeChange = () => {
        this.setState({ widthChange: window.innerWidth });
    };

    handleMenuClick = () => {
        this.setState({ open: !this.state.open });
    };

    handleChange = (event) => {
        const { setSearchBy } = this.props;
        setSearchBy(event.target.value);
    };

    handleSortAndRiskListByDate = e => {
        const { setSortingOrder, sortRiskListByDate } = this.props;
        let sValue = e.target.value;
        setSortingOrder(sValue.substr(0, sValue.lastIndexOf('_')).split(' ')[0], this.props.searchResult);
        sortRiskListByDate(sValue.substr(sValue.lastIndexOf('_') + 1).split(' ')[0], this.props.searchResult);
    }

    onChangeSearchText = (e, { value }) => {
        const { setSearchText } = this.props;
        setSearchText(value);
    };

    handleDateChange = (event) => {
        const { setSearchDateOption } = this.props;
        setSearchDateOption(event.target.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const { logoutFlag } = this.props;
        if (logoutFlag !== prevProps.logoutFlag && logoutFlag) {
            this.handleLogout();
        }
        const { segmentTrackingCode, onLoad } = this.props;
        if (segmentTrackingCode && segmentTrackingCode !== prevProps.segmentTrackingCode) {
            onLoad(segmentTrackingCode);
        }
    }
    render() {
        const { profileId, productId, styles, debug, logoRightUrl, logoLeftUrl, images, text, fromDateChange, toDateChange, sortParams, loggedInUserDetail, handleNextEstimate, pageSize, totalRecords, setPerPageRecords, recordsOnThePage, } = this.props;
        let fontFamilyName = styles.globalFontFamily;
        const { logout } = this.state;
        const isMobile = this.state.widthChange <= UrlConstants.MOBILE_INNER_WIDTH;
        const {
            claudtokenToken, isSessionLoaded,
        } = this.props;

        return (
            <div>
                {(!isSessionLoaded || !claudtokenToken) ?
                    <LoginPageContainer location={this.props.location} match={this.props.match}
                        productId={productId} profileId={profileId} /> :
                    <div className='homepage'>
                        <CCEHeader className="cceheader"
                            debug={debug}
                            styles={styles}
                            text={text}
                            logoRightUrl={logoRightUrl}
                            logoLeftUrl={logoLeftUrl}
                            productId={productId}
                            profileId={profileId}
                            widthChange={this.state.widthChange}
                            handleMenuClick={this.handleMenuClick}
                            handleMenuClose={this.handleMenuClose}
                            images={images}
                            open={this.state.open}
                            handleLogout={this.handleLogout}
                            pageName="manage"
                            loggedInUserName={newFunction1(loggedInUserDetail)}
                            loggedInEmail={newFunction2(loggedInUserDetail)}
                            toggleMenu={this.state.toggleMenu}
                            handleToggleMenu={this.handleToggleMenu}
                            companyLogo = {newFunction3(loggedInUserDetail)}
                            divisionLogo = {newFunction4(loggedInUserDetail)}
                            newEstimate={false} />

                        <div className='cce-search'>
                            <Card className='filter-card'>
                                <div className="action-nav">
                                    <div className="nav-previous" style={{ alignSelf: 'flex-start',width:'100%' }}>
                                        <div className="action-risk-header">Risk Records:</div>
                                    </div>
                                    <div className="nav-next" style={{ alignSelf: 'flex-end' }}>
                                        <Button className="action-estimate" variant="outlined" id="tab-1"
                                            rounded="true"
                                            tabIndex={0}
                                            onClick={handleNextEstimate}
                                            disableTouchRipple={true}
                                            disableFocusRipple={false}
                                        >New Estimate
                                        </Button>
                                    </div>
                                </div>
                                <div className="action-nav action-nav-filter">
                                    <div className="nav-previous nav-previos-add" style={{ alignSelf: 'flex-start' }}>
                                        <CardText className='est-filter-card-text'>
                                            <div className='fields address est-fields'
                                                style={{ paddingLeft: '10px', height: 'auto', float: 'left', paddingRight: '0px' }}>
                                                <FormControl className='search-select search-select-add' style={{ height: '100%', paddingRight: '5px', width: '20%' }}>
                                                    <Select id="filter" className="select-box est-select-box est-add-box" disableUnderline
                                                        style={{ fontFamily: fontFamilyName, marginRight: '0rem', width: '13rem' }}
                                                        value={this.props.searchParams.searchBy}
                                                        onChange={this.handleChange}
                                                        tabIndex={1}>
                                                        <MenuItem value="address">Show All Addresses</MenuItem>
                                                        <MenuItem value="riskId">Show All RiskId</MenuItem>
                                                        <MenuItem value="policyNumber">Show All Policy Number</MenuItem>
                                                        <MenuItem value="insured">Show All Insured</MenuItem>
                                                        <MenuItem value="underWriter">Show All Underwriter</MenuItem>
                                                        <MenuItem value="assignedTo">Show All Assigned To</MenuItem>
                                                        <MenuItem value="companyName">Show All Company</MenuItem>
                                                        <MenuItem value="divisionName">Show All Division</MenuItem>
                                                        <MenuItem value="teamName">Show All Team</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='est-search' style={{ width: '90%' }}>
                                                <SearchRisk
                                                    name="addressInput"
                                                    productId={productId}
                                                    profileId={profileId}
                                                    searchText={this.props.searchParams.searchText}
                                                    fontFamilyName={fontFamilyName}
                                                    onChangeSearchText={this.onChangeSearchText}
                                                    pageName="estimateMgmt"
                                                />
                                            </div>
                                        </CardText>
                                    </div>

                                    {isMobile &&
                                        <div class="nav-next nav-next-sort nav-next-mob-sort" style={{ margin: '0 auto', display: 'flex', height: '100%' }}>
                                            <div className="est-filter-card-text est-silter-card-right" style={{ paddingRight: '0px', height: '100%', marginRight: '0rem' }}>
                                                <div className='fields est-fields est-fields-date' style={{ height: '100%', paddingRight: '0px', marginRight: '0rem' }}>
                                                    <div className='est-date'>
                                                        <FormControl>
                                                            <Select id="date-filter" className="select-box est-select-box est-dt-select-box"
                                                                style={{ fontFamily: fontFamilyName, padding: '0.2rem 0.5rem 0rem 1rem' }} disableUnderline
                                                                value={this.props.searchParams.searchDateOption}
                                                                onChange={this.handleDateChange}
                                                                tabIndex={1}>
                                                                <MenuItem value="createdDate">Show Date Created</MenuItem>
                                                                <MenuItem value="lastUpdatedDate">Show Date Updated</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="cce-search-datePicker" style={{ fontFamily: fontFamilyName }} >
                                                        <div class="ui fluid">
                                                            <div class="ui fluid icon input" style={{ right: '0.35rem' }}>
                                                                <DatePicker className="date"
                                                                    style={{ fontFamily: fontFamilyName }}
                                                                    placeholderText="From Any Date"
                                                                    selected={this.newMethod1()}
                                                                    onChange={fromDate => fromDateChange(fromDate)}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    useShortMonthInDropdown
                                                                    yearDropdownItemNumber={10}
                                                                    icon={{ className: "search" }}
                                                                    scrollableYearDropdown
                                                                />
                                                                <i id="from-date-icon" aria-hidden="true" class="icon calendar alternate outline" style={{ left: '7rem' }}></i>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cce-search-datePicker right-datepicker" style={{ fontFamily: fontFamilyName }} >
                                                        <div class="ui fluid icon input">
                                                            <DatePicker className="date date-picker"
                                                                placeholderText="To Any Date"
                                                                style={{ fontFamily: fontFamilyName }}
                                                                selected={this.newMethod4()}
                                                                onChange={toDate => toDateChange(toDate)}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                yearDropdownItemNumber={10}
                                                                scrollableYearDropdown />
                                                            <i id="to-date-icon" aria-hidden="true" class="icon calendar alternate outline" style={{ left: '7rem' }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                              <div class="fields est-sort est-fields est-sort-mob" style={{ height: '100%', marginBottom: '0rem', paddingLeft: '0.25rem' }} >
                                                <FormControl>
                                                    <Select id="order" className="select-box est-select-box est-select-order"
                                                        style={{ fontFamily: fontFamilyName, padding: '0.2rem 0.5rem 0rem 1rem', right: '2rem' }}
                                                        disableUnderline
                                                        value={sortParams.sortOrder + '_' + sortParams.dateChange}
                                                        onChange={this.handleSortAndRiskListByDate}
                                                        tabIndex={1}>
                                                        <MenuItem value="ascending_createdDate">Sort By Date Created Ascending</MenuItem>
                                                        <MenuItem value="descending_createdDate">Sort By Date Created Descending</MenuItem>
                                                        <MenuItem value="ascending_lastUpdatedDate">Sort By Date Updated Ascending</MenuItem>
                                                        <MenuItem value="descending_lastUpdatedDate">Sort By Date Updated Descending</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                    }
                                    {!isMobile && <div class="nav-next nav-next-sort" style={{ margin: '0 auto', display: 'flex', padding: '0px 0px 0.5px 0px', height: '100%' }}>
                                        <div className="est-filter-card-text est-silter-card-right" style={{ paddingRight: '0px', height: '100%', marginRight: '0rem' }}>

                                            <div className='fields est-fields' style={{ height: '100%', paddingRight: '0px', marginRight: '0rem',display: 'inherit' }}>
                                                <div className='est-date'>
                                                    <FormControl>
                                                        <Select id="date-filter" className="select-box est-select-box desk-est-select-box"
                                                            style={{ fontFamily: fontFamilyName, padding: '0.5rem 0.5rem 0rem 1rem' }} disableUnderline
                                                            value={this.props.searchParams.searchDateOption}
                                                            onChange={this.handleDateChange}
                                                            tabIndex={1}>
                                                            <MenuItem value="createdDate">Show Date Created</MenuItem>
                                                            <MenuItem value="lastUpdatedDate">Show Date Updated</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="cce-search-datePicker" style={{ fontFamily: fontFamilyName }} >
                                                    <div class="ui fluid ">
                                                        <div class="ui fluid icon input">
                                                            <DatePicker className="date"
                                                                style={{ fontFamily: fontFamilyName }}
                                                                placeholderText="From Any Date"
                                                                selected={this.newMethod2()}
                                                                onChange={fromDate => fromDateChange(fromDate)}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                useShortMonthInDropdown
                                                                yearDropdownItemNumber={10}
                                                                icon={{ className: "search" }}
                                                                scrollableYearDropdown
                                                            />
                                                            <i id="from-date-icon" aria-hidden="true" class="icon calendar alternate outline"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cce-search-datePicker" style={{ fontFamily: fontFamilyName }} >
                                                    <div class="ui fluid icon input">
                                                        <DatePicker className="date date-picker"
                                                            style={{ fontFamily: fontFamilyName }}
                                                            placeholderText="To Any Date"
                                                            selected={this.newMethod3()}
                                                            onChange={toDate => toDateChange(toDate)}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            useShortMonthInDropdown
                                                            yearDropdownItemNumber={10}
                                                            scrollableYearDropdown />
                                                        <i id="to-date-icon" aria-hidden="true" class="icon calendar alternate outline"></i>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div className="est-filter-card-text est-silter-card-right" style={{ paddingRight: '0px', height: '100%', marginRight: '0rem' }}>

                                            <div className='fields est-fields' style={{ height: '100%', paddingRight: '0px', marginRight: '0rem', marginLeft: '-0.5rem' }}>
                                                <div class="fields est-sort est-fields" style={{ height: '100%', marginBottom: '0rem', paddingLeft: '0.25rem' }} >
                                                    <FormControl>
                                                        <Select id="order" className="select-box est-select-box est-select-order"
                                                            style={{ fontFamily: fontFamilyName, padding: '0.2rem 0.5rem 0rem 1rem' }}
                                                            disableUnderline
                                                            value={sortParams.sortOrder + '_' + sortParams.dateChange}
                                                            onChange={this.handleSortAndRiskListByDate}
                                                            tabIndex={1}>
                                                            <MenuItem value="ascending_createdDate">Sort By Date Created Ascending</MenuItem>
                                                            <MenuItem value="descending_createdDate">Sort By Date Created Descending</MenuItem>
                                                            <MenuItem value="ascending_lastUpdatedDate">Sort By Date Updated Ascending</MenuItem>
                                                            <MenuItem value="descending_lastUpdatedDate">Sort By Date Updated Descending</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div></div></div>
                                    </div>
                                       }
                                </div>
                                <div className="action-nav action-nav-page">
                                    <div className="nav-previous nav-previos-page" style={{ alignSelf: 'flex-start', width: '100%' }}>
                                        <CardText className='est-filter-card-text' style={{ paddingLeft: '10px', paddingRight: '25px', width: '100%' }}>
                                            <div className='fields address pageno' style={{ paddingLeft: '10px', paddingRight: '25px', display: 'grid', width: '100%' }}>
                                                <FormControl className="per-page" style={{float:'left'}}>
                                                    <Select id="per-page" className="select-box est-select-box"
                                                        style={{ fontFamily: fontFamilyName, width: '13rem' }} disableUnderline
                                                        onChange={(event) => {
                                                            setPerPageRecords(event.target.value)
                                                        }}
                                                        value={pageSize}
                                                        tabIndex={1}>
                                                        <MenuItem value="10">Show 10 per page</MenuItem>
                                                        <MenuItem value="20">Show 20 per page</MenuItem>
                                                        <MenuItem value="50">Show 50 per page</MenuItem>
                                                        <MenuItem value={totalRecords}>Show All</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div style={{ clear:'both',
                                                    paddingLeft: '3px', paddingRight: '25px', paddingBottom: '5px', fontSize: '13px',
                                                    fontFamily: fontFamilyName, fontWeight: '600', marginTop: '0.5rem'
                                                }}>
                                                    <span>Showing {recordsOnThePage} of {totalRecords}</span>
                                                </div>
                                            </div>

                                        </CardText>
                                    </div>
                                </div>
                            </Card>
                            <RiskListContainer
                                productId={productId}
                                profileId={profileId}
                                fontFamilyName={fontFamilyName}
                                widthChange={this.state.widthChange}
                                location={this.props.location}
                            />
                        </div>
                        <CCELogOutDialog
                            dialogOpen={logout}
                            styles={styles}
                            onLogoutCancel={this.onLogoutSearchCancel}
                            onLogoutConfirm={this.onLogoutSearchConfirm}
                        />
                        <FooterContainer />
                    </div>}
            </div>
        );
    }

    newMethod4() {
        return this.props.searchParams.toDate !== null ? new Date(this.props.searchParams.toDate) : null;
    }

    newMethod3() {
        return this.props.searchParams.toDate !== null ? new Date(this.props.searchParams.toDate) : null;
    }

    newMethod2() {
        return this.props.searchParams.fromDate !== null ? new Date(this.props.searchParams.fromDate) : null;
    }

    newMethod1() {
        return this.props.searchParams.fromDate !== null ? new Date(this.props.searchParams.fromDate) : null;
    }
}

CCESearch.propTypes = {
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    styles: PropTypes.object.isRequired,
    text: PropTypes.object,
    logoLeftUrl: PropTypes.string,
    logoRightUrl: PropTypes.string,
};

export default CCESearch;

function newFunction4(loggedInUserDetail) {
    return loggedInUserDetail != null && loggedInUserDetail.divisionLogo != null ? loggedInUserDetail.divisionLogo : null;
}

function newFunction3(loggedInUserDetail) {
    return loggedInUserDetail != null && loggedInUserDetail.companyLogo != null ? loggedInUserDetail.companyLogo : null;
}

function newFunction2(loggedInUserDetail) {
    return (loggedInUserDetail != null
        && loggedInUserDetail.emailId != null) ?
        loggedInUserDetail.emailId : "";
}

function newFunction1(loggedInUserDetail) {
    return (loggedInUserDetail != null
        && loggedInUserDetail.fullName != null) ?
        loggedInUserDetail.fullName.toUpperCase() : "";
}
