import React from 'react';
import {CardHeader} from 'material-ui/Card';
import PropTypes from 'prop-types';

const SectionHeader = ({name, color, styles}) => {
    const rebuildCostSectionHeader = ["Estimated declared rebuild cost", "Allowances for Cost Inflation", "Estimated cost to rebuild your property",
        "Indemnity Value", "Reports", "Disclaimer"];
    return <CardHeader className="card-header"
                       title={name}
                       style={{
                           padding: 0,
                           backgroundColor: color,
                           fontFamily: styles.globalFontFamily,
                           minHeight: '3.12rem',
                           textTransform: styles.sectionHeadingTextCase ? styles.sectionHeadingTextCase : 'uppercase',
                           border: styles.sectionHeaderBorderWidth ? "solid " + styles.sectionHeaderBorderWidth  : "",
                           borderColor: styles.sectionHeaderBorderColor ? styles.sectionHeaderBorderColor : "",
                           position: !rebuildCostSectionHeader.includes(name) ? 'sticky' : 'static',
                           top: 0,
                           zIndex: 1
                       }}
                       titleStyle={{
                           padding: '1em',
                           marginRight: 0,
                           fontSize: styles.sectionHeadingFontSize || '1.125rem',
                           fontWeight: styles.sectionHeadingFontWeight
                       }}
                       titleColor={styles.sectionTextColour}/>;
};

SectionHeader.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    styles: PropTypes.object,
};

export default SectionHeader;