import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const CCELogOutDialog = ({ styles, dialogOpen, onLogoutCancel, onLogoutConfirm }) => {
    return <Dialog
        className="dialog terms-dialog"
        open={dialogOpen}
        maxWidth={'sm'}
        onClose={onLogoutCancel}
    >
        <DialogContent >
            <DialogContentText component={'div'} id="alert-dialog-slide-description"
                style={{ fontFamily: styles.globalFontFamily }}>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td tabIndex={0} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                Are you sure want to log out? <br></br>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <div className="terms-dialog-agree">
                <div style={{ display: 'flex' }}>
                    <CustomFlatButtonContainer className="terms" label="Yes, Logout"
                        onPress={onLogoutConfirm}
                        type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                        keyboardFocus={true} />
                </div>
                <div style={{ display: 'flex' }}>
                    <CustomFlatButtonContainer className="terms" label="Cancel" onPress={onLogoutCancel}
                        type="primary" style={{ marginRight: '2rem', minWidth: '12em', fontFamily: styles.globalFontFamily }}
                        keyboardFocus={true} />
                </div>
            </div>
        </DialogActions>
    </Dialog>
};

CCELogOutDialog.propTypes = {
    dialogOpen: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    onLogoutCancel: PropTypes.func.isRequired,
    onLogoutConfirm: PropTypes.func.isRequired,
};

export default CCELogOutDialog;