import DownloadPdfReport from '../components/DownloadPdfReport';
import {downloadPdfReport} from '../actions/actionCreator';
import {connect} from 'react-redux';

export const mapStateToProps = (state) => {
    return {
        pdfReport: state.pdfReport,
        styles: state.profile.styles,
        result: state.result,
        report: state.report,
        autoMailMessage: state.autoMailMessage,
        profile: state.profile,
    };
};

export const mapDispatchToProps = (dispatch, downloadReportAction = downloadPdfReport) => {
    return {
        onDownloadPdfReport: () => dispatch(downloadReportAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPdfReport);
