import React from 'react';
import { inputStyle, validationErrorStyle } from '../style/styles';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import UrlConstants from '../constants/UrlConstants';

const SelectBox = ({ question, onChange, styles, errorMsg, clearError, profileId }) => {
    const { answerTextColour, answerSelectedTextColour, answerFontSize, globalFontFamily } = styles;
    const value = parseInt(question.answer, 10);
    const sortedOptions = question.options.sort((option1, option2) => {
        return option1.lineNo - option2.lineNo;
    });

    const isIAG = UrlConstants.PROFILE_ID_IAG_AU.includes(profileId);
    const selectStyle = {
        ...inputStyle,
        fontSize: answerFontSize,
        paddingLeft: '10px',
        paddingRight: "10px",
        color: answerSelectedTextColour,
        bottom: '5px',
        fontFamily: globalFontFamily,
        marginTop: '5px',
        minWwidth: '24em !important',
        flexDirection: 'unset',
    };
    const selectErrorStyle = {
        ...selectStyle,
        borderColor: 'red'
    };

    const menuStyle = {
        fontFamily: globalFontFamily,
        color: answerTextColour,
        boxSizing: 'border-box',
        lineHeight: 'inherit',
        fontSize: '15px',
        fontWeight: 'inherit',
        height: 'auto',
        paddingLeft: '24px',
        paddingRight: '24px',
        whiteSpace: 'pre-wrap',
    };

    const errorStyle = {
        ...validationErrorStyle,
        fontFamily: globalFontFamily,
        color: 'red',
        margin: '-5px 1px'
    };

    const formStyle = {
        marginBottom: '10px'
    };

    return isIAG ? <div id="select"><FormControl className="select-box" component="select" id={question.id.toString()} value={value}
        aria-describedby={"more-info-" + question.id.toString() + " help-text-" + question.id.toString() + " error-" + question.id.toString()}
        style={selectStyle} onChange={(event) => {
        clearError(question.id);
        onChange({ question: question.id, answer: event.target.value });
    }}  >
        {newFunction1(value, menuStyle)}
        {sortedOptions.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
            <MenuItem className="select-box" component="option"
                style={menuStyle}
                disabled= {true}
                key={option.id}
                value={option.id}>
                {option.label}
            </MenuItem>
        )}
    </FormControl> {errorMsg && <div id={"error-" + question.id.toString()} style={errorStyle}>{errorMsg}</div>}</div> : <FormControl style={formStyle}>
            <Select id={question.id.toString()}
                disabled= {question.id.toString() === "303548"}
                className="select-box"
                value={value}
                onChange={(event) => {
                    clearError(question.id);
                    onChange({ question: question.id, answer: event.target.value });
                }}
                style={getStyle(errorMsg, selectErrorStyle, selectStyle)}
            >
                {sortedOptions.map((option) => (option.label === "[Blank]" || option.label === "[blank]") ? "" :
                    <MenuItem
                        style={menuStyle}
                        key={option.id}
                        value={option.id}>
                        {option.label}
                    </MenuItem>
                )}
            </Select>
            {errorMsg && <FormHelperText style={errorStyle}>{errorMsg}</FormHelperText>}
        </FormControl>
};
SelectBox.propTypes = {
    question: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
    errorMsg: PropTypes.string,
    clearError: PropTypes.func.isRequired,
};

export default SelectBox;

function getStyle(errorMsg, selectErrorStyle, selectStyle) {
    return errorMsg ? selectErrorStyle : selectStyle;
}

function newFunction1(value, menuStyle) {
    return isNaN(value) ? <MenuItem className="select-box" component="option" style={menuStyle} key="" value=""
        disabled={true}></MenuItem> : "";
}
