import React from 'react';
import StateDisclaimer from './StateDisclaimer';
import PropTypes from 'prop-types';
import UrlConstants from "../constants/UrlConstants";

const Disclaimer = ({state, contentBefore, contentAfter, styles, productId}) => {
    return (
        productId === UrlConstants.PRODUCT_ID_7 ?
            <div id="auDisclaimer" className="disclaimer" style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '1em 2em',
                fontFamily: styles.globalFontFamily,
                color: '#000000'
            }}>
                <div style={{fontSize: '1rem', fontFamily: styles.globalFontFamily}} dangerouslySetInnerHTML={{
                    __html: contentBefore ? contentBefore :
                        '<B>DISCLAIMER:</B>The Cordell Sum Sure calculator takes the answers you input (or confirm, as applicable) combined with information collated by CoreLogic about the subject property (from insurers and other third party ' +
                        'sources) and analyses them against construction industry data and other data collated by CoreLogic to generate an estimated rebuild cost of the improvements on your property.  Use of the Cordell Sum  ' +
                        'Sure calculator and any estimated rebuild cost provided by it may not be appropriate for you and you will need to assess the suitability of this estimate given your knowledge of your property. ' +
                        'Use of the Cordell Sum Sure calculator and any estimated rebuild cost provided by it may not be ' +
                        'qualifications apply to the estimated rebuild cost, ' +
                        'which are set out in the full set of terms and ' +
                        'conditions accepted by you prior to your use of the Sum Sure Calculator located  ' +
                        'here',
                }}/>
                <StateDisclaimer state={state}/>
                <div style={{fontSize: '1rem'}} dangerouslySetInnerHTML={{__html: contentAfter}}/>
            </div>

            :

            <div id="nzDisclaimer" className="disclaimer" style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '1em 2em',
                fontFamily: styles.globalFontFamily,
                color: '#000000'
            }}>
                <div style={{fontSize: '1rem', fontFamily: styles.globalFontFamily}} dangerouslySetInnerHTML={{
                    __html: contentBefore ? contentBefore :
                        '<B>DISCLAIMER:</B>The property data below comes from a variety of third party sources so ' +
                        'it is important that you verify and update where necessary. It is your responsibility to ensure ' +
                        'the information is accurate and correct. The calculator analyses the inputs against construction ' +
                        'industry data to come up with an estimated reconstruction cost that you may choose to use for home ' +
                        'insurance purposes. If you are unsure about what is required of any of the fields, then click on the ' +
                        'question mark for more information, provided against each question.',
                }}/>
                <div style={{fontSize: '1rem'}} dangerouslySetInnerHTML={{__html: contentAfter}}/>
            </div>

    );
};

Disclaimer.propTypes = {
    state: PropTypes.string,
    contentBefore: PropTypes.string,
    contentAfter: PropTypes.string,
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
};

export default Disclaimer;