import apiBaseUrl from '../apiBaseUrl';

const getUserAccountApi = (productId, profileId, id, claudtoken) => {

    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getUserAccount/${id}`;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    headers.accessToken = claudtoken.apigee_token;
    return fetch(url,
        {
            method: 'GET',
            headers,
        }
    );
};

export default getUserAccountApi;