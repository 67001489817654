import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import SpinnerContainer from '../containers/SpinnerContainer';
import EmailMessageDialog from './EmailMessageDialog';

class CCEEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenEmailMessageDialog: false,
        };
    }
    openEmailMessageDialog = () => {
        const { recipient } = this.props;
        recipient.data.email = null;
        recipient.data.subject = null;
        recipient.data.message = null;
        this.setState({
            isOpenEmailMessageDialog: true,
        });
    }

    hideEmailMessageDialog = () => {
        const { recipient } = this.props;
        recipient.data.email = null;
        recipient.data.subject = null;
        recipient.data.message = null;
        this.setState({
            isOpenEmailMessageDialog: false,
        });
    }

    sendEmailReport = () => {
        const { onEmailReport } = this.props;
        this.setState({
            isOpenEmailMessageDialog: false,
        });
        onEmailReport();
    }
    render() {
        const { report, recipient, onEmailChange, onEmailReport, emailText, message, styles, display, onDownloadPdfReport, result, onEmailSubjectChange, onEmailMessageChange, downloadPdfReportText } = this.props

        const { downloadReport: downloadPdfDisplay, email: emailDisplay } = display;
        const successMessage = report.sentReport ? <div className="success">{message}</div> : null;
        const error = this.getErrorConstant(recipient, report);
        const emailError = error ? <div className="error" style={{ marginTop: '0em', textAlign: 'left' }}> {error}</div> : null;
        const pdfError = result.error
            ? <div className="error" style={{ fontFamily: styles.globalFontFamily, textAlign: 'left' }}>
                {result.error}
            </div>
            : null;
        return (
            <div className="email-report-container">
                <div className="email-report-form" style={{paddingBottom: '10px'}}>
                    <div className="email-field">
                        <div className="send-email-button">
                            <div align="left" style={{ display: 'inline-grid', width: '100%' }}>
                                {
                                    downloadPdfDisplay && downloadPdfReportText
                                        ? <div className="download-report" style={{
                                            fontFamily: styles.globalFontFamily, fontSize: '1rem', padding: '0.6rem 0rem', fontWeight: 'bold'
                                        }} dangerouslySetInnerHTML={{ __html: downloadPdfReportText }} />
                                        : <div style={{ height: '1rem' }} />
                                }
                                {downloadPdfDisplay && <Button className="download-pdf" variant="outlined"
                                    style={{ backgroundColor: '#C3C3C3', textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', width: '30%', fontFamily: 'inherit' }}
                                    rounded="true"
                                    size="small"
                                    tabIndex={1}
                                    onKeyPressCapture={event => {
                                        if (event.key === 'Enter') {
                                            onDownloadPdfReport().call();
                                        }
                                    }}
                                    onClick={onDownloadPdfReport}
                                    disableTouchRipple={true}
                                    disableFocusRipple={false}
                                >
                                    Download PDF</Button>}
                                {emailDisplay && <div className="email-section">
                                    <div className="email-header-label" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                                        <div style={{
                                            fontFamily: styles.globalFontFamily, fontSize: '1rem', fontWeight: 'bold',
                                        }} dangerouslySetInnerHTML={{ __html: emailText }} />
                                    </div>
                                    <Button className="send-email" variant="outlined"
                                        style={{ backgroundColor: '#C3C3C3', textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', width: '30%', fontFamily: 'inherit' }}
                                        rounded="true"
                                        size="small"
                                        tabIndex={0}
                                        onKeyPressCapture={event => {
                                            if (event.key === 'Enter') {
                                                this.openEmailMessageDialog().call();
                                            }
                                        }}
                                        onClick={this.openEmailMessageDialog}
                                        disableTouchRipple={true}
                                        disableFocusRipple={false}
                                    >
                                        Email Report
                                        <SpinnerContainer />
                                    </Button>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {pdfError}
                <div></div>

                {successMessage}
                {emailError}
                {this.state.isOpenEmailMessageDialog && <EmailMessageDialog
                    onEmailChange={onEmailChange}
                    recipient={recipient}
                    emailText={emailText}
                    display={display}
                    styles={styles}
                    onEmailSubjectChange={onEmailSubjectChange}
                    onEmailMessageChange={onEmailMessageChange}
                    onEmailReport={onEmailReport}
                    hideEmailMessageDialog={this.hideEmailMessageDialog}
                    sendEmailReport={this.sendEmailReport}
                />}
            </div>
        );
    }


    getErrorConstant(recipient, report) {
        if (recipient.error) {
            return recipient.errorMsg;
        }
        return report.error ? report.error : null;
    }
}

CCEEmail.propTypes = {
    report: PropTypes.object.isRequired,
    recipient: PropTypes.object.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    onEmailReport: PropTypes.func.isRequired,
    emailText: PropTypes.string,
    message: PropTypes.string,
    styles: PropTypes.object,
    userNameDisplay: PropTypes.bool,
    onEmailMessageChange: PropTypes.func.isRequired,
    onEmailSubjectChange: PropTypes.func.isRequired
};

export default CCEEmail;