import printJS from 'print-js';

export const printTermsAndConditions = (elementId) => {
    if(  elementId === 'cceNzTermAndCondition') {
        let style = " ol.multi-level { counter-reset: item; }  .multi-level li{ display: block; padding-top: 5px;  padding-bottom: 5px; } ";
        style = style + " ol.multi-level li p { padding-bottom: 1;}  .multi-level li:before {  content: counters(item, \".\") \" \"; counter-increment: item;  padding-right:5px; float:left;  }";
        style = style + ".multi-level-div { padding-right:10px; padding-left:42px;   } .multi-level-div-dbl {  padding-right:10px; padding-left:50px; }";
        style = style + "ol.main-multi-level { counter-reset: item; margin-left: -14px; }  ol.main-multi-level > li { counter-increment: item;  padding-left: 27px; }";      
        style = style + "  ol.main-multi-level ol.main-multi-level > li {  display: block; }  ol.main-multi-level ol.main-multi-level > li:before { content: counters(item, \".\") \". \";  padding-right:5px; float:left; }";       
        style = style + " ol.cus-alpha {  counter-reset: list;  margin-left:-40px; }  ol.cus-alpha > li {  list-style: none;  }";
        style = style + " ol.cus-alpha > li:before {  content: \"(\" counter(list, lower-alpha) \") \"; counter-increment: list; padding-right:5px; float:left; }"
        style = style + " .cce-nz-terms-list >li {  padding-left: 12px; }     .cce-terms-h2 { padding-left: 10px; }";
        printJS({ printable: elementId, type: 'html', scanStyles: false, header: 'Terms of Use', 
        documentTitle: 'Terms of Use', style: style });
    } else {        
    printJS({ printable: elementId, type: 'html', scanStyles: false, header: 'Terms of Use', documentTitle: 'Terms of Use'});
        }
    return true;
};

export const printDisclaimer = (elementId) => {
    printJS({ printable: elementId, type: 'html', scanStyles: false, header: 'Disclaimer',  documentTitle: 'Disclaimer'});
    return true;
};
