import {connect} from 'react-redux';
import ImageManagement from "../components/ImageManagement";
import {bindActionCreators} from 'redux';
import {fetchCCEImages,
        updateImageDescription,
        removeImages,
        onImageDescPicked
} from '../actions/actionCreator';
import {performCalculation} from '../actions/calculationActionCreator';
import {updateCurrentTab} from '../actions/searchActionCreator';
import { updateRiskInNewEstimate} from '../actions/questionsActionCreator';

export const mapStateToProps = (state, defaultProps) => {
    return {
        styles: state.profile.styles,
        debug: state.debug,
        profileId: state.profileId,
        productId: state.productId,
        partnerId: state.partnerId,
        addressChosen: state.addressChosen,
        propertyId: state.addressChosen.propertyId,
        questions: state.questions,
        result: state.result,
        imageUploads: state.imageUploads,
        cceImages: state.cceImages,
        counter: state.counter,
        error: state.imageDetail.error,
        changeImageDescription: defaultProps.changeImageDescription,
        imageDetail: state.imageDetail,
        contentInsurance: state.profile.display.contentInsurance,
        estimateID: state.estimateID,
        widthChange: window.innerWidth,
        riskId: state.riskId,
        buildingGrp: state.buildingGrp,
        estimatePrice: state.estimatePrice,
        handleChange: defaultProps.handleChange,
        isEmailDownload: state.isEmailDownload,
        riskDetail: state.riskDetail,
        currentTab: state.currentTab,
        isEditEstimate: state.isEditEstimate,
        postcode: state.postcode,
   };
};

const mapDispatchToProps = (dispatch) => {
     return bindActionCreators({ fetchCCEImages, removeImages, onImageDescPicked, updateImageDescription,
         performCalculation,
        updateCurrentTab,
        updateRiskDetail: updateRiskInNewEstimate,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        calculateEstimate: () => {
            let obj1 = {
                productId : stateProps.productId,
                profileId : stateProps.profileId,
                propertyId : stateProps.propertyId,
                address : stateProps.addressChosen.suggestion,
                questions : stateProps.questions
            };
            dispatchProps.performCalculation(obj1,
                stateProps.contentInsurance, stateProps.partnerId, stateProps.estimateID, stateProps.riskId, stateProps.buildingGrp, stateProps.estimatePrice);
        },
         fetchImages: () =>{
                dispatchProps.fetchCCEImages(stateProps.productId, stateProps.profileId, stateProps.result.data.estimateId);
         },
        partnerId: stateProps.partner,
        onRemoveImages: dispatchProps.removeImages,
        onImageDescChange: dispatchProps.onImageDescPicked,
        updateImagesDescription: dispatchProps.updateImageDescription,
        updateCurrentTab: dispatchProps.updateCurrentTab,
        updateRiskDetail: () => {
            dispatchProps.updateRiskDetail(stateProps.productId, stateProps.profileId, stateProps.riskId, stateProps.riskDetail);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ImageManagement);