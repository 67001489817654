import React from 'react';
import AppBar from 'material-ui/AppBar';
import PropTypes from 'prop-types';

const Header = ({debug, images, styles, text, logoLeftUrl, logoRightUrl}) => {

    const {headerLogoLeftUrl, headerLogoRightUrl, mobileHeaderLogoImageRightUrl, mobileHeaderLogoImageLeftUrl} = images;
    const {headerTitle} = text;
    const debugModeTextAlign = debug ? 'right': '';
    const iconRightStyle = {
        paddingRight: styles.headerLogoRightPaddingRight != null ? styles.headerLogoRightPaddingRight : "0px !important",
        paddingTop: styles.headerLogoRightPaddingTop != null ? styles.headerLogoRightPaddingTop : "0px !important",
        height: styles.headerLogoRightHeight != null ? styles.headerLogoRightHeight : "0px !important",
        width: styles.headerLogoRightWidth != null ? styles.headerLogoRightWidth : "0px !important",
        maxWidth: '210px',
        textAlign: 'right',
        marginTop: '0px !important'
    };

    const iconLeftStyle = {
        marginTop: '0px !important',
        paddingBottom: '0.2rem',
        paddingLeft: styles.headerLogoLeftPaddingLeft != null ? styles.headerLogoLeftPaddingLeft : "0px !important",
        paddingTop: styles.headerLogoLeftPaddingTop != null ? styles.headerLogoLeftPaddingTop : "0px !important",
        height: styles.headerLogoLeftHeight != null ? styles.headerLogoLeftHeight : "0px !important",
        width: styles.headerLogoLeftWidth != null ? styles.headerLogoLeftWidth : "0px !important",
        maxWidth: '210px',
        textAlign: 'left',
        marginLeft: '0px !important'
    };

    let renderLeftElement = () => {
        let logo = null;
        if(mobileHeaderLogoImageLeftUrl || headerLogoLeftUrl) {
            logo = <picture>
                <source media='(max-width: 480px)' style={{width: 'auto', height: 'auto'}} className="logo" srcSet={mobileHeaderLogoImageLeftUrl}/>
                <img src={headerLogoLeftUrl} alt='Logo of the Company' className="logo" style={{width: 'auto', height: 'auto'}}/>
            </picture>
        }
        if (logo && logoLeftUrl) {
            logo = <a href={logoLeftUrl} target="_blank" rel="noopener noreferrer">{logo}</a>;
        }
        return logo;
    };

    let renderRightElement = () => {
        let logo = null;

        if(mobileHeaderLogoImageRightUrl || headerLogoRightUrl) {
            logo = <picture>
                <source media='(max-width: 480px)' style={{width: '84px', height: 'auto', paddingRight: '0.1rem !important'}}  srcSet={mobileHeaderLogoImageRightUrl}/>
                <img src={headerLogoRightUrl} alt='Right Logo' className="logo-right" style={{width: 'auto', height: 'auto'}}/>
            </picture>
        }
        if (logo && logoRightUrl) {
            logo = <a href={logoRightUrl} target="_blank" rel="noopener noreferrer">{logo}</a>;
        }
        return logo;
    };

    return (
        <div className="header" style={{marginTop: '0px !important', marginBottom: '0px !important'}}>
            <AppBar
                className={debug ? 'responsive-header app-header-debug' : 'responsive-header'}
                title={<span>{headerTitle}</span>}
                titleStyle={{
                    display: 'stretch',
                    justifyContent: styles.headerTitleAlignment,
                    fontSize: styles.headerTitleFontSize,
                    color: styles.headerTitleFontColour,
                    fontFamily: styles.headerTitleFontFamily,
                    paddingLeft: styles.headerTitleLeftPadding,
                    paddingRight: styles.headerTitleRightPadding,
                    lineHeight: styles.headerTitleVerticalAlignment,
                }}
                iconElementLeft={renderLeftElement()}
                iconStyleLeft={iconLeftStyle}
                iconElementRight={renderRightElement()}
                iconStyleRight={iconRightStyle}
                iconClassNameRight='icon-right'
                style={{
                    verticalAlign: 'middle',
                    opacity: styles.headerOpacity,
                    background: `linear-gradient(${styles.headerBackgroundTopColor}, ${styles.headerBackgroundBottomColor})`,
                    height: styles.headerHeight,
                    marginTop: '0px !important',
                    textAlign: debugModeTextAlign,
                }}
            />
        </div>
    );
};

Header.propTypes = {
    debug: PropTypes.bool.isRequired,
    images: PropTypes.object.isRequired,
    styles: PropTypes.object.isRequired,
    text: PropTypes.object,
};

export default Header;