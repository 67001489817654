import React from 'react';
import TermsOfUseDialog from './TermsOfUseDialog';
import PropTypes from 'prop-types';

const HomePageTermsOfUseDialog = ({open, onAgree, onCancel, styles, productId, profileId, onPrint, inputRef}) => {
    return (
        <TermsOfUseDialog open={open} onAgree={onAgree} onCancel={onCancel} styles={styles} productId={productId} profileId={profileId} inputRef={inputRef}
                          onPrint={onPrint}/>
    );
};

HomePageTermsOfUseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onAgree: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    profileId: PropTypes.string,
    onPrint: PropTypes.func.isRequired,
};

export default HomePageTermsOfUseDialog;