import { connect } from 'react-redux';
import CCESearch from '../components/CCESearch';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import {
    setSearchBy, setSearchText, fromDateChange,
    toDateChange, setSearchDateOption, onClearFilter,
    sortRiskListByDate, setSortingOrder, resetAddEditEstimate, setPerPageRecords
} from '../actions/searchActionCreator';
import { handleNextEstimate } from '../actions/questionsActionCreator';
import {
    logout, resetTokenDetails, restoreURL, getUserDetailByUserGuid
} from '../actions/loginActionCreator';
import { initializeAnalytics, sessionTracker, trackSegmentPageView } from '../actions/segmentActionsCreator';
import AnalyticsPage from '../util/AnalyticsPage';

const mapStateToProps = (state, defaultProps) => {
    const queryParams = queryString.parse(defaultProps.location.search);
    return {
        productId: state.productId == null ? getProductIdCCESearch(defaultProps)
            : state.productId,
        profileId: state.profileId == null ?
            getProfileIdCCESearch(defaultProps)
            : state.profileId,
        text: state.profile.text,
        debug: queryParams.debug === 'true',
        styles: state.profile.styles,
        logoLeftUrl: state.profile.logoLeftUrl,
        logoRightUrl: state.profile.logoRightUrl,
        images: state.profile.images,
        widthChange: state.widthChange,
        open: state.open,
        searchParams: state.searchParams,
        searchResult: state.searchResult,
        sortParams: state.sortParams,
        claudtokenToken: state.claudtoken.access_token,
        isSessionLoaded: !!state.claudtoken.access_token,
        homePath: state.homePath,
        loggedInUserDetail: state.loggedInUserDetail,
        tokenContents: state.tokenContents,
        logoutFlag : queryParams.logout,
        location: defaultProps.location.search,
        pageSize: state.pageSize,
        totalRecords: state.totalRecords,
        recordsOnThePage: state.recordsOnThePage,
        segmentTrackingCode: state.profile.segmentTrackingCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setSearchBy,
        setSearchText,
        fromDateChange,
        toDateChange,
        setSearchDateOption,
        onClearFilter,
        sortRiskListByDate,
        setSortingOrder,
        logout: logout,
        resetTokenDetails,
        restoreURL,
        getUserDetailByUserGuid,
        handleNextEstimate: handleNextEstimate,
        resetAddEditEstimate,
        setPerPageRecords,
        trackSegmentPageView,
        initializeAnalytics,
        sessionTracker,
    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        setSearchBy: dispatchProps.setSearchBy,
        setSearchText: dispatchProps.setSearchText,
        fromDateChange: dispatchProps.fromDateChange,
        toDateChange: dispatchProps.toDateChange,
        setSearchDateOption: dispatchProps.setSearchDateOption,
        onClearFilter: dispatchProps.onClearFilter,
        sortRiskListByDate: dispatchProps.sortRiskListByDate,
        setSortingOrder: dispatchProps.setSortingOrder,
        onLogout: dispatchProps.logout,
        onResetTokenDetails: () => {
            dispatchProps.resetTokenDetails();
        },
        restoreURL: dispatchProps.restoreURL,
        getUserDetailByUserGuid: dispatchProps.getUserDetailByUserGuid,
        handleNextEstimate: () => {
            dispatchProps.handleNextEstimate(stateProps.productId, stateProps.profileId);
        },
        resetAddEditEstimate: dispatchProps.resetAddEditEstimate,
        setPerPageRecords: dispatchProps.setPerPageRecords,
        onLoad: (segmentTrackingCode) => {
            dispatchProps.initializeAnalytics(segmentTrackingCode);
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Sum Sure', ownProps.location.pathname, stateProps.sessionTrackId, "EstimateManagementPage", stateProps.partner);
            dispatchProps.trackSegmentPageView('CCE EstimateManagement Page', pageDetails);
        },
        startTrackSession: () => {
            dispatchProps.sessionTracker((new Date()).getTime());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CCESearch);

function getProfileIdCCESearch(defaultProps) {
    return defaultProps.profileId == null ?
        defaultProps.match.params.profileId : defaultProps.profileId;
}

function getProductIdCCESearch(defaultProps) {
    return defaultProps.productId == null ?
        defaultProps.match.params.productId : defaultProps.productId;
}
