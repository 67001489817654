import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';
import TermsOfUse from "../constants/TermsOfUse";
import UrlConstants from "../constants/UrlConstants";
import { printTermsAndConditions } from '../util/PrintUtil';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const getElementToPrint = (productId) => {
    let sourceElement = null;
    if (productId === UrlConstants.PRODUCT_ID_8) {
        sourceElement = 'nzTermAndCondition';
    } else if (productId === UrlConstants.PRODUCT_ID_7) {
        sourceElement = 'auTermAndCondition';
    } else if (productId === UrlConstants.PRODUCT_ID_10) {
        sourceElement = 'cceNzTermAndCondition';
    } else if (productId === UrlConstants.PRODUCT_ID_9) {
        sourceElement = 'cceAuTermAndCondition';
    }
    return sourceElement;
};

const termsButtonStyle = (styles, productId) => {
    let buttonClassName = "terms";
    if (productId === UrlConstants.PRODUCT_ID_10 && styles.globalFontFamily === null) {
        buttonClassName = 'terms cceNZTerms';
    }
    return buttonClassName;
};

const cceNzTermsOfUseStyle = (styles) => {
    let sourceElement = null;
    if (styles.globalFontFamily === null) {
        sourceElement = 'cceNzTermAndCondition';
    }
    return sourceElement;
};

const FooterTermsOfUseDialog = ({ open, onClose, styles, productId, profileId }) => {

    return (<Dialog
        className="dialog terms-dialog"
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={'md'}
        style={{ right: '-50px', left: '-50px' }}
    >
        <DialogContent>
            <DialogContentText component={'div'} id="alert-dialog-slide-description" style={{ fontFamily: styles.globalFontFamily, fontSize: '14px' }}>
                <div style={{ width: '100%' }}>
                    <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'flex-start'
                    }}>

                        <div style={{ width: '80%', textAlign: 'left' }}>
                            <h1 style={{ marginBottom: 0, color: '#000000', fontFamily: styles.globalFontFamily, marginTtop: '0.65em' }}>Terms of Use</h1>
                        </div>
                        <div style={{ width: '20%', textAlign: 'right', paddingRight: '10px' }}>
                            <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                                printTermsAndConditions(getElementToPrint(productId));
                                return false;
                            }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        printTermsAndConditions(getElementToPrint(productId));
                                        return false;
                                    }
                                }} tabIndex={0}>Print</span>
                        </div>
                    </div>
                    {
                        productId === UrlConstants.PRODUCT_ID_8
                            ? <div id="nzTermAndCondition">{TermsOfUse.NZ}</div>
                            : (getTermandConditionD2C(productId, profileId, styles)
                            )

                    }
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: "center" }}>
            <div className="terms-dialog-agree">
                <div style={{ display: 'flex', justifyContent: "center" }}>
                    <CustomFlatButtonContainer
                        label="Close"
                        type="primary"
                        className={termsButtonStyle(styles, productId)}
                        onPress={onClose}
                        style={{
                            marginRight: '2rem',
                            minWidth: '12em',
                            fontFamily: styles.globalFontFamily,
                            borderRadius: styles.buttonRadius,
                        }}
                    />
                </div>
            </div>
        </DialogActions>
    </Dialog >
    );
};

FooterTermsOfUseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
};

export default FooterTermsOfUseDialog;

function getTermandConditionAU(productId, styles) {
    return productId === UrlConstants.PRODUCT_ID_7
        ? <div id="auTermAndCondition">{TermsOfUse.AU}</div>
        : getTermandConditionCCE(productId, styles);
}
function getTermandConditionCCE(productId, styles) {
    return productId === UrlConstants.PRODUCT_ID_9
        ? <div id="cceAuTermAndCondition">{TermsOfUse.CCE_AU}</div>
        : <div id="cceNzTermAndCondition" class={cceNzTermsOfUseStyle(styles)}>{TermsOfUse.CCE_NZ}</div>;
}
function getTermandConditionD2C(productId, profileId, styles) {
    return (productId === UrlConstants.PRODUCT_ID_7 && (profileId===UrlConstants.PROFILE_ID_141 || profileId === UrlConstants.PROFILE_ID_142))
        ? <div id="D2CTermAndCondition">{TermsOfUse.D2C}</div>
        : getTermandConditionAU(productId, styles);
}
