import { connect } from 'react-redux';
import Reports from '../components/Reports';
import { bindActionCreators } from 'redux';
import { selectReportCategoryType, selectCompanyUsage, selectDivisionUsage, selectTeamUsage, selectStartDateUsage, selectEndDateUsage, resetUsageValues} from '../actions/questionsActionCreator';
import AnalyticsPage from '../util/AnalyticsPage';
import { trackSegmentPageView } from "../actions/segmentActionsCreator";
import {getUserGroupsAction} from '../actions/searchActionCreator';
import {downloadUsageReport} from '../actions/questionsActionCreator';


const mapStateToProps = (state) => {
    return {
        styles: state.profile.styles,
        widthChange: state.widthChange,
        loggedInUserDetail: state.loggedInUserDetail,
        productId: state.productId,
        profileId: state.profileId,
        selectedReportCategoryType: state.selectedReportCategoryType,
        selectedCompanyUsage: state.selectedCompanyUsage,
        selectedDivisionUsage: state.selectedDivisionUsage,
        selectedTeamUsage: state.selectedTeamUsage,
        userGroups: state.userGroups,
        usageStartDate: state.usageStartDate,
        usageEndDate: state.usageEndDate,

    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        trackSegmentPageView: trackSegmentPageView,
        selectReportCategoryType: selectReportCategoryType,
        selectCompanyUsage: selectCompanyUsage,
        selectDivisionUsage: selectDivisionUsage,
        selectTeamUsage: selectTeamUsage,
        getUserGroupsAction: getUserGroupsAction,
        selectStartDateUsage: selectStartDateUsage,
        selectEndDateUsage: selectEndDateUsage,
        downloadUsageReport: downloadUsageReport,
        resetUsageValues: resetUsageValues,

    }, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        onLoad: () => {
            const pageDetails = new AnalyticsPage(stateProps.productId, stateProps.profileId, 'Usage Reporting Page', ownProps.location.pathname, stateProps.sessionTrackId, "Import/ExportPage", stateProps.partner);
            dispatchProps.trackSegmentPageView("Usage Report Page", pageDetails);
           
        },
        selectReportCategoryType: dispatchProps.selectReportCategoryType,
        selectCompanyUsage: dispatchProps.selectCompanyUsage,
        selectDivisionUsage: dispatchProps.selectDivisionUsage,
        selectTeamUsage: dispatchProps.selectTeamUsage,
        getUserGroups: dispatchProps.getUserGroupsAction,
        selectStartDateUsage: dispatchProps.selectStartDateUsage,
        selectEndDateUsage: dispatchProps.selectEndDateUsage,
        downloadUsageReport: dispatchProps.downloadUsageReport,
        resetUsageValues: dispatchProps.resetUsageValues,


    };
};
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Reports);