import apiBaseUrl from '../apiBaseUrl';

const getUserListByUserIdApi = (productId, profileId, userId) => {
    const url = `${apiBaseUrl()}products/${productId}/profiles/${profileId}/getUsersByUserId/${userId}`;
    const headers = {
        'Accept': 'application/json',
    };

    return fetch(url,
        {
            method: 'POST',
            headers,
        }
    );
};

export default getUserListByUserIdApi;