import React from 'react';
import {TextField, Card} from 'material-ui';
import {inputStyle, titleStyle} from '../style/styles';
import PropTypes from 'prop-types';
import SectionHeader from './SectionHeader';
import InformationConsent from './InformationConsent';

const UserDetail = ({ userCapturedDetail,  onUserFullNameChange, onUserEmailAddressChange, styles, profileId, productId}) => { 
    const isInformationConsentRequired = productId === '7' && (profileId === '141' || profileId === '142');
    const userError = userCapturedDetail.error ? <div className="error" style={{ marginTop: '0em', textAlign: 'left' }}> {userCapturedDetail.error}</div> : null;
    return (
        <Card className="card">
        <SectionHeader name="Please enter your details"
                       color={styles.sectionTileColour} styles={styles}/> 
        <div className="user-name-container user-cap-detail">
            <div className="user-name-field" name="Please enter your details"
                       color={styles.sectionTileColour} > 
                <TextField
                    floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                    floatingLabelText="Full Name"
                    floatingLabelFixed={true}
                    className="user-first-name-field required-field"
                    inputStyle={{...inputStyle, fontFamily: styles.globalFontFamily}}
                    underlineShow={false}
                    value={userCapturedDetail.fullName}
                    onChange={(event, newValue) => {
                        onUserFullNameChange(newValue);
                    }}
                />
                <TextField
                    floatingLabelStyle={{...titleStyle, fontFamily: styles.globalFontFamily}}
                    floatingLabelText="Email Address"
                    floatingLabelFixed={true}
                    className="user-email-address-field required-field"
                    inputStyle={{...inputStyle, fontFamily: styles.globalFontFamily}}
                    underlineShow={false}
                    value={userCapturedDetail.emailAddress}
                    onChange={(event, newValue) => {
                        onUserEmailAddressChange(newValue);
                    }}
                />
                {userError}
            </div>
           {isInformationConsentRequired && <div><InformationConsent/></div>}
        </div>
        
    </Card>
    );
};

UserDetail.propTypes = {
    styles: PropTypes.object,
};

export default UserDetail;