import React from 'react';
import { Card, CardText } from 'material-ui/Card';
import ImageGallery from 'react-image-gallery';
import { isEmpty } from 'lodash';


class ImagePanel extends React.Component {

    toImages = (cceImage) => {
        return { original: cceImage.imageUrl, thumbnail: cceImage.imageUrl};
    };

    componentWillMount() {
        const { productId, profileId, getNoImageFile } = this.props;
        getNoImageFile(productId, profileId);
    }

    render(){
        const {cceImages, noImageFile} = this.props;
        let tileData = !isEmpty(cceImages.data) ? cceImages.data.map((cceImage) => this.toImages(cceImage)) : [];

              let  defaultData = [
                    {
                        original: noImageFile,
                        thumbnail: noImageFile,
                    },
                ]
        return (
            <Card className="image-card">
                <CardText className="image-card-text">
                    <ImageGallery
                        additionalClass="image-preview"
                        items={isEmpty(tileData) ? defaultData : tileData}
                        showPlayButton={false}
                        useBrowserFullscreen={false}
                    />
                </CardText>
            </Card>
        );
    }
}

export default ImagePanel;