import Spinner from '../components/Spinner';
import { connect } from 'react-redux';

export const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        loading: state.result.loading || state.questions.loading || state.report.loading || state.pdfReport.loading || state.letusknow.loading ||
            state.isBuildingLoading || state.searchResult.isLoading || state.inCompleteEstimate.loading || state.isAddUserGroup.loading
            || state.isUserGroupListing.loading || state.isUserListing.loading ||  state.loginLoading
    };
};

export default connect(mapStateToProps)(Spinner);