import React from 'react';
import UrlConstants from '../constants/UrlConstants'; 
 
class Unavailable extends React.Component {
    render () {
    const {productId} = this.props;   
    return (
        <div className="error-page">
            {productId !== UrlConstants.PRODUCT_ID_10 && productId !== UrlConstants.PRODUCT_ID_9 && <div>
                <h2>Our website is temporarily unavailable as we perform some routine maintenance.</h2>
                <h3>Please try again soon.</h3>
            </div>}
            {(productId === UrlConstants.PRODUCT_ID_10 || productId === UrlConstants.PRODUCT_ID_9 ) && <div>
               <center><h2><bold>Temporarily down for maintenance.</bold></h2>
                <h3>We are performing scheduled maintenance. We should be back online shortly.</h3></center> 
            </div>}
        </div>
    );
            }
}

export default Unavailable;