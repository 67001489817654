import React from 'react';

export default accessDeniedPage;

function accessDeniedPage() {
    return (
        <div className="error-page">
            <h2>You are restricted from accessing this website. Please provide access token.</h2>
            <h3>Please contact CoreLogic if you do not have access token.</h3>
        </div>
    );
}