import React from 'react';
import CustomFlatButtonContainer from '../containers/CustomFlatButtonContainer';
import PropTypes from 'prop-types';

const DownloadPdfReport = ({onDownloadPdfReport, styles, result, report, autoMailMessage, profile}) => {
    const errorMessage = result.error
        ? <div className="error" style={{fontFamily: styles.globalFontFamily, textAlign: 'left'}}>
            {result.error}
        </div>
        : null;
    let autoSuccessMessage = null;
    const custProfile = profile !== null && profile.display.includeEmailAddress && profile.display.restrictDownloadReport;
    if (custProfile && report.sentReport) {
     autoSuccessMessage = <div className="success">{autoMailMessage}</div>
    }
    const customFlatButton = <CustomFlatButtonContainer
        className="input what-next-button" id="download-report-button" label="Download report" type="primary"
        keyboardFocus={false} onPress={onDownloadPdfReport}
        style={{
            fontFamily: styles.globalFontFamily,
            borderRadius: styles.buttonRadius,
            border: styles.buttonBorderWidth != null ? styles.buttonBorderWidth + " solid " + styles.buttonBorderColor : ""
        }}
    />;

    return (
        <div className="mainDiv">
            {errorMessage}
            {autoSuccessMessage}
            {customFlatButton}
        </div>

    );
};

DownloadPdfReport.propTypes = {
    onDownloadPdfReport: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
};

export default DownloadPdfReport;