import React from "react";
import {Line} from "react-chartjs-2";
import Chart from "chart.js/auto";

const LineChart = ({chartData, options}) => {
    console.info("--- ChartJs Version ---", Chart.version)
    return (
        <React.Fragment>
            <Line id='line-chart' data={chartData} options={options}/>
        </React.Fragment>
    );
}

export default LineChart;